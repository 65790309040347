import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { SettingsForm } from "../../components/settings/SettingsForm";
import moment from "moment";
import "./setting.css";
import { message } from "../../../common";
import { profileValidator } from "../../../validators/profileValidator";
class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileData: {},
      profileEditData: {},
      loading: false,
      isUpdating: false,
      errors: null,
      changePass: false,
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };
  fetchData = async () => {
    this.setState({
      loading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined,
      undefined
    );
    this.setState({
      loading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        loading: false,
      });
    } else {
      const { data } = response.data;
      const { birth_date = "", phone = null, gender = null } = data || {};
      this.setState({
        profileData: {
          ...data,
          phone: phone || null,
          gender: gender || null,
          birth_date: birth_date ? new Date(birth_date) : null || "",
        },
      });
      console.log("data", response.data);
    }
  };
  handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    if (name === "phone" && isNaN(value)) {
      return;
    }
    this.setState({
      profileData: {
        ...this.state.profileData,
        [name]: value.trimLeft(),
      },
    });
  };

  handleDateChange = (date) => {
    if (date) {
      this.setState({
        profileData: {
          ...this.state.profileData,
          birth_date: date,
        },
      });
    }
  };
  handleGenderChange = (event) => {
    const {
      target: { value, name },
    } = event;

    console.log("here", event);
    this.setState({
      profileData: {
        ...this.state.profileData,
        [name]: value && value === "Male" ? "Male" : "Female",
      },
    });
  };
  updateInfo = async (data) => {
    this.setState({
      isUpdating: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROFILE.service,
      ApiRoutes.UPDATE_PROFILE.url,
      ApiRoutes.UPDATE_PROFILE.method,
      ApiRoutes.UPDATE_PROFILE.authenticate,
      undefined,

      {
        ...data,
        birth_date: moment(data?.birth_date).toISOString(true),
      }
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      // this.fetchData();
      window.location.reload();
      this.setState({
        errors: "",
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { profileData, changePass } = this.state;
    let data = {
      first_name: profileData?.first_name,
      last_name: profileData?.last_name,
      phone: profileData?.phone,
      profile_picture: profileData?.profile_picture,
      gender: profileData?.gender,
      birth_date: profileData?.birth_date,
      password: profileData?.password,
    };
    const { isValid, errors } = profileValidator(data);
    if (isValid) {
      if (changePass) {
        if (!profileData?.cnfirmPassword) {
          this.setState({
            errors: {
              cnfirmPassword: "Please enter password",
            },
          });
          return;
        } else if (profileData?.password !== profileData?.cnfirmPassword) {
          this.setState({
            errors: {
              cnfirmPassword: "Password didn't match",
            },
          });
          return;
        }
      }
      this.updateInfo(data);
    } else {
      this.setState({
        errors: errors,
      });
    }
  };

  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      this.setState({
        profileData: {
          ...this.state.profileData,
          errors: {
            ...this.state.profileData.errors,
            [name]: message.InvalidImages,
          },
        },
      });
      return;
    }
    this.setState({
      profileData: {
        ...this.state.profileData,
        errors: {
          ...this.state.profileData.errors,
          [name]: "",
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = "";
  };
  onDeleteFile = () => {
    this.setState({
      profileData: {
        ...this.state.profileData,
        profile_picture: "",
        errors: {
          ...this.state.profileData.errors,
          profile_picture: "",
        },
      },
    });
  };
  uploadPicture = async (data, name) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      this.setState({
        profileData: { ...this.state.profileData, [name]: path },
      });
    }
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
    });
  };

  onShow = () => {
    this.setState({
      changePass: true,
    });
  };
  onCancel = () => {
    const { profileData } = this.state;
    this.setState({
      changePass: false,
      profileData: {
        ...profileData,
        cnfirmPassword: "",
      },
    });
  };
  render() {
    const { profileData, loading, isUpdating, errors, changePass } = this.state;
    console.log("profileData index.js", profileData);
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <>
        <div className="container-fluid fluid-pd-2">
          <h1 className="h3-head-mob d-md-none">View</h1>
          <Breadcrumbs
            pathname={pathname}
            customBreadCrumb={this.customBreadCrumb}
          />
          <div className="row">
            <div className="col-md-12 d-flex flex-column">
              <SettingsForm
                profileData={profileData}
                loading={loading}
                isUpdating={isUpdating}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                handleDateChange={this.handleDateChange}
                handleGenderChange={this.handleGenderChange}
                errors={errors}
                onFileChange={this.onFileChange}
                onDeleteFile={this.onDeleteFile}
                onShow={this.onShow}
                onCancel={this.onCancel}
                changePass={changePass}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Setting;
