import Validator from "js-object-validation";
import { message } from "../common";

export const contactInfoValidator = (data) => {
  const validations = {
    first_name: {
      required: true,
      alpha: true,
    },
    // last_name: {
    //   required: true,
    //   alpha: true,
    // },
    email: {
      required: true,
      email: true,
    },
    // phone: {
    //   required: true,
    //   numeric: true,
    // },
  };
  // Error messages
  const messages = {
    first_name: {
      required: message.Required.replace(":item", "First Name"),
      alpha: message.InvalidName.replace(":item", "First name"),
    },
    last_name: {
      required: message.Required.replace(":item", "last name"),
      alpha: message.InvalidName.replace(":item", "last name"),
    },
    email: {
      required: message.Required.replace(":item", "Email address"),
      email: message.InvalidEmail,
    },
    // phone: {
    //   required: message.Required.replace(':item', 'Phone Number'),
    //   numeric: message.InvalidPhone,
    // },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
