import React, { useState, useEffect } from "react";
import { InputTextField, SubmitButton } from "../forms";
import { faqsValidator } from "../../../validators";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes, AppRoutes, youTubeVideoRegex } from "../../../config";
import { toast } from "react-toastify";
import { videoTutorial } from "../../../validators/videoTutorial";
import { message } from "../../../common";

export const AddFormComponent = ({ videos, handleAddCancel }) => {
  const [videoName, setVideoName] = useState(
    videos?.videoName ? videos?.videoName : ""
  );
  const [videoDescription, setVideoDesc] = useState(
    videos?.videoDescription ? videos?.videoDescription : ""
  );
  const [videoLink, setVideoLink] = useState(
    videos?.videoLink ? videos?.videoLink : ""
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "videoName") {
      setVideoName(value.trimLeft());
    }
    if (name === "videoDescription") {
      setVideoDesc(value.trimLeft());
    }
    if (name === "videoLink") {
      setVideoLink(value.trimLeft());
    }
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    let payload = {
      videoName: videoName,
      videoDescription: videoDescription,
      videoLink: videoLink,
    };

    let { isValid, errors } = videoTutorial(payload);
    console.log(videoLink);
    if (videoLink && !youTubeVideoRegex.test(videoLink.trim())) {
      isValid = false;
      setErrors({
        ...errors,
        videoLink: message.ValidLink,
      });
      setIsLoading(false);
      return;
    } else {
      setErrors({});
    }
    if (isValid) {
      setErrors({});
      if (videos?._id) {
        updateEntry({ ...payload, _id: videos?._id });
      } else {
        addEntry({ ...payload });
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
      return;
    }
  };

  console.log("errors", errors);

  const addEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.ADD_VIDEO_TUTORIAL_NEW.service,
      ApiRoutes.ADD_VIDEO_TUTORIAL_NEW.url,
      ApiRoutes.ADD_VIDEO_TUTORIAL_NEW.method,
      ApiRoutes.ADD_VIDEO_TUTORIAL_NEW.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  const updateEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.UPDATE_VIDEO_TUTORIAL_NEW.service,
      ApiRoutes.UPDATE_VIDEO_TUTORIAL_NEW.url,
      ApiRoutes.UPDATE_VIDEO_TUTORIAL_NEW.method,
      ApiRoutes.UPDATE_VIDEO_TUTORIAL_NEW.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  useEffect(() => {
    console.log("videos", videos);
    if (videos._id) {
      setIsEditMode(true);
      setVideoName(videos.videoName);
      setVideoDesc(videos.videoDescription);
      setVideoLink(videos.videoLink);
    } else {
      setIsEditMode(false);
    }
  }, []);

  return (
    <form className="product_add">
      <div className="row">
        <div className="row">
          <div className="col-md-6">
            <InputTextField
              type="text"
              id="priority"
              label={"Video Name"}
              placeholder="Enter Video Name"
              className={"form-control-product"}
              name="videoName"
              value={videoName}
              onChange={onChange}
              min="1"
              errorMessage={errors && errors.videoName ? errors.videoName : ""}
            />
          </div>
          <div className="col-md-6">
            <InputTextField
              type="text"
              placeholder="Enter Video Link"
              id="question"
              label={"Video Link"}
              className={"form-control-product"}
              name="videoLink"
              value={videoLink}
              onChange={onChange}
              errorMessage={errors && errors.videoLink ? errors.videoLink : ""}
            />
          </div>
          <div className="col-md-12">
            <InputTextField
              type="text"
              placeholder="Enter Description, upto 100 characters"
              id="question"
              label={"Video Description"}
              className={"form-control-product"}
              name="videoDescription"
              value={videoDescription}
              onChange={onChange}
              maxLength={100}
              errorMessage={
                errors && errors.videoDescription ? errors.videoDescription : ""
              }
            />
          </div>
        </div>

        <div className="col-md-12 " style={{ paddingTop: "30px" }}>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary cancel-btn"
              onClick={handleAddCancel}
            >
              {" "}
              CANCEL
            </button>

            <SubmitButton
              className={"submit-btn mr-0"}
              text={isEditMode ? "UPDATE" : "ADD"}
              isLoading={isLoading}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
