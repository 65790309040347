import React from 'react';
import Chart from 'react-apexcharts';

export const CounterfeitGraphComponent = ({
  totalCompleted,
  totalAcknowldeged,
  totalUnderProcess,
  totalUnderReview,
}) => {
  let options1 = {
    labels: [
      `${totalUnderProcess} Pending`,
      `${totalCompleted} Completed`,
      `${totalAcknowldeged} Acknowledged`,
      `${totalUnderReview} Under Review`,
    ],
    colors: ['#f89c41', '#0f51a1', '#4ab8ff', '#ff5660'],
    legend: {
      position: 'right',
      height: 800,
      markers: {
        width: 10,
        height: 10,
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.58,
        offsetY: -40,
        offsetX: 23,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '24px',
        },
      },
    },
    title: {
      align: 'center',
      offsetY: 330,
    },
    tooltip: {
      fillSeriesColor: false,
      marker: {
        show: false,
      },
      theme: 'light',
    },
  };

  return (
    <Chart
      options={options1}
      series={[
        totalUnderProcess,
        totalCompleted,
        totalAcknowldeged,
        totalUnderReview,
      ]}
      type="donut"
      width={300}
      height={280}
    />
  );
  // <Doughnut data={data} options={options} height={275} />;
};
