/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Skeleton from "react-skeleton-loader";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { message } from "../../../common";
import { productValidator } from "../../../validators";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { AddProductFormComponent } from "../../components/products/AddProductFormComponent";
import { ProductInputLoader } from "../../components/loader/Resusable/ProductInput";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { ImageGallery } from "./ImageGallery";

class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      productNumber: "",
      productDescription: "",
      productImage: "",
      otherImages: [],
      productId: "",
      errors: null,
      isAdding: false,
      isDeletingImage: false,
      isUploading: false,
      uploadPercentage: 0,
      openGallery: false,
      isVisible: false,
      slide: 0,
      totalBatches: 0,
    };
  }
  showSlide = (slide) => {
    this.setState({
      isVisible: !this.state.isVisible,
      slide: slide,
    });
  };
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ productId: id }, () => this.viewProduct());
    }
  };

  viewProduct = async () => {
    const { productId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_PRODUCT.service,
      ApiRoutes.VIEW_PRODUCT.url.replace(":id", productId),
      ApiRoutes.VIEW_PRODUCT.method,
      ApiRoutes.VIEW_PRODUCT.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { product },
      } = response.data;
      const {
        productName = "",
        productNumber = "",
        productDescription = "",
        productImage = "",
        otherImages = [],
        totalBatches = 0,
      } = product;
      this.setState({
        productName,
        productNumber,
        productDescription,
        productImage,
        otherImages,
        totalBatches,
      });
    }
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      [name]: value.trimLeft(),
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files },
    } = event;
    if (
      this.state.otherImages &&
      this.state.otherImages.length + files.length > 9
    ) {
      this.setState({
        errors: {
          productImage: "You can't upload more than 10 images.",
        },
      });
      return;
    }
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (file && !file.type.match("image.*")) {
        this.setState({
          errors: {
            productImage: message.InvalidImages,
          },
        });
        return;
      }
      this.setState({
        errors: {
          productImage: "",
        },
      });
      await this.uploadPicture(file);
    }
    // files.forEach(async file => {
    // });
    event.target.value = "";
  };

  onDeleteImage = async (file, name) => {
    console.log(file);
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to delete all uploaded image?`,
    });
    if (!value) {
      return;
    }
    const myArray = file.split("/");
    let fileName = myArray[3];
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_IMAGE.service,
      ApiRoutes.DELETE_IMAGE.url.replace(":file", fileName),
      ApiRoutes.DELETE_IMAGE.method,
      ApiRoutes.DELETE_IMAGE.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        productImage: "",
        otherImages: [],
      });
    }
  };
  onDeleteFile = async () => {
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to delete all uploaded image?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      productImage: "",
      otherImages: [],
    });
  };
  uploadPicture = async (data) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      if (!this.state.productImage) {
        this.setState({
          productImage: path,
        });
      } else {
        this.setState({
          otherImages: [...this.state.otherImages, path],
        });
      }
    }
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
    });
  };

  addProduct = async (payload) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_PRODUCT.service,
      ApiRoutes.ADD_PRODUCT.url,
      ApiRoutes.ADD_PRODUCT.method,
      ApiRoutes.ADD_PRODUCT.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        this.setState({
          errors: response.validationErrors,
        });
      }
    } else {
      toast.success("Products has been added successfully.");
      this.props.history.push(AppRoutes.MANAGE_PRODUCT);
    }
  };
  updateProduct = async (payload) => {
    const { productId } = this.state;
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PRODUCT.service,
      ApiRoutes.UPDATE_PRODUCT.url.replace(":id", productId),
      ApiRoutes.UPDATE_PRODUCT.method,
      ApiRoutes.UPDATE_PRODUCT.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success("Products has been edited successfully.");
      this.props.history.push(AppRoutes.MANAGE_PRODUCT);
    }
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      productName,
      productNumber,
      productDescription,
      productImage,
      productId,
      otherImages,
    } = this.state;
    let payload = {
      productName: productName.trim(),
      productNumber: productNumber.trim(),
      productDescription: productDescription.trim().replace(/\s{2,}/g, " "),
      // .replace(/\n/g, ' ')
      // .replace(/  +/g, ' '),
      productImage,
    };

    const { isValid, errors } = productValidator(payload);
    if (isValid) {
      if (productId) {
        this.updateProduct({ ...payload, otherImages });
      } else {
        this.addProduct({ ...payload, otherImages });
      }
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };

  handleCancel = () => {
    this.props.history.push(AppRoutes.MANAGE_PRODUCT);
  };

  openGallery = () => {
    const { productImage, otherImages } = this.state;
    if (productImage || (otherImages && otherImages.length)) {
      this.setState({
        openGallery: true,
      });
    }
  };

  closeGallery = () => {
    this.setState({
      openGallery: false,
    });
  };

  deleteImage = async (index) => {
    const { productId } = this.state;
    if (index === 0) {
      this.setState(
        (prevState) => ({
          productImage: this.state.otherImages[0] || "",
          otherImages: prevState.otherImages.filter((_, i) => index !== i),
        }),
        () => {
          if (
            this.state.otherImages &&
            this.state.otherImages.length === 0 &&
            !this.state.productImage
          ) {
            this.closeGallery();
          }
        }
      );
    } else {
      if (productId) {
        this.setState({
          isDeletingImage: true,
          deletingImageIndex: index,
        });
        const response = await new ApiHelper().FetchFromServer(
          ApiRoutes.UPDATE_PRODUCT.service,
          ApiRoutes.UPDATE_PRODUCT.url.replace(":id", productId),
          ApiRoutes.UPDATE_PRODUCT.method,
          ApiRoutes.UPDATE_PRODUCT.authenticate,
          undefined,
          {
            otherImages: this.state.otherImages.filter(
              (_, i) => index - 1 !== i
            ),
          }
        );
        this.setState({
          isDeletingImage: false,
          deletingImageIndex: "",
        });
        if (response && response.isError) {
          toast.error(response.messages[0]);
          return;
        }
        this.setState((prevState) => ({
          otherImages: prevState.otherImages.filter((_, i) => index - 1 !== i),
        }));
      } else {
        this.setState((prevState) => ({
          otherImages: prevState.otherImages.filter((_, i) => index - 1 !== i),
        }));
      }
    }
  };

  markImageAsFeatured = (index) => {
    let temp = [...this.state.otherImages];
    temp[index] = this.state.productImage;
    this.setState({
      productImage: this.state.otherImages[index],
      otherImages: temp,
    });
  };

  customBreadCrumb = () => {
    const { isLoading, productName } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      productName
    );
  };

  render() {
    const {
      productName,
      productNumber,
      productDescription,
      productImage,
      otherImages,
      errors,
      isUploading,
      isAdding,
      isLoading,
      isDeletingImage,
      deletingImageIndex,
      productId,
      uploadPercentage,
      openGallery,
      totalBatches,
    } = this.state;
    const {
      props: {
        location: { pathname, state },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">
          {productId ? `Edit product` : "Add New Product"}
        </h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
          search={state ? state.search : ""}
        />
        <div className="card mb-4 card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6
                  className="font-book"
                  // onClick={() =>
                  //   this.setState({
                  //     openGallery: true,
                  //   })
                  // }
                >
                  {productId ? (
                    isLoading ? (
                      <Skeleton color="#e5e9ef" height={"25px"} />
                    ) : (
                      productName
                    )
                  ) : (
                    "Add New Product"
                  )}
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            {isLoading && productId ? (
              <ProductInputLoader />
            ) : (
              <AddProductFormComponent
                productName={productName}
                productNumber={productNumber}
                productDescription={productDescription}
                productImage={productImage}
                otherImages={otherImages}
                isEditMode={productId}
                errors={errors}
                isAdding={isAdding}
                isUploading={isUploading}
                uploadPercentage={uploadPercentage}
                totalBatches={totalBatches}
                onChange={this.handleChange}
                onFileChange={this.onFileChange}
                onDeleteFile={this.onDeleteImage}
                handleSubmit={this.handleSubmit}
                handleCancel={this.handleCancel}
                openGallery={this.openGallery}
              />
            )}
          </div>
          {
            // <FsLightbox
            //   toggler={openGallery}
            //   sources={[
            //     'https://i.imgur.com/fsyrScY.jpg',
            //     'https://www.youtube.com/watch?v=xshEZzpS4CQ',
            //     'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
            //   ]}
            // />
            // <div>
            //   <FsLightbox
            //     toggler={this.state.isVisible}
            //     slide={this.state.slide}
            //     sources={[
            //       'image1.jpg',
            //       'image2.jpg',
            //       'image3.jpg',
            //       'image4.jpg',
            //       'image5.jpg',
            //       'image6.jpg',
            //     ]}
            //   />
            //   <img onClick={() => this.showSlide(1)} src='image1.jpg' />
            //   <img onClick={() => this.showSlide(2)} src='image2.jpg' />
            //   <img onClick={() => this.showSlide(3)} src='image3.jpg' />
            //   <img onClick={() => this.showSlide(4)} src='image4.jpg' />
            //   <img onClick={() => this.showSlide(5)} src='image5.jpg' />
            //   <img onClick={() => this.showSlide(6)} src='image6.jpg' />
            // </div>
          }
          {/* {openGallery && (
            <SimpleReactLightbox>
              <div className='MyComponent'>
                <SRLWrapper>
                  <a
                    href='link/to/the/full/width/image.jpg'
                    data-attribute='SRL'
                  >
                    <img
                      src='https://api.clarity.codes/assets/files/file_1602245172255.jpg'
                      alt='Umbrella'
                    />
                  </a>
                  <a
                    href='link/to/the/full/width/image_two.jpg'
                    data-attribute='SRL'
                  >
                    <img
                      src='https://api.clarity.codes/assets/files/file_1602245172255.jpg'
                      alt='Whatever'
                    />
                    delete
                  </a>
                </SRLWrapper>
              </div>
            </SimpleReactLightbox>
          )} */}
          <ImageGallery
            open={openGallery}
            images={[productImage, ...otherImages].filter(Boolean)}
            isDeletingImage={isDeletingImage}
            deletingImageIndex={deletingImageIndex}
            deleteImage={this.deleteImage}
            handleClose={this.closeGallery}
            markImageAsFeatured={this.markImageAsFeatured}
          />
        </div>
      </div>
    );
  }
}

export default AddProduct;
