import Validator from "js-object-validation";
import { message } from "../common";

export const editClient = (data) => {
  const validations = {
    first_name: {
      required: true,
      alpha: true,
    },
    email: {
      required: true,
      email: true,
    },
    companyName: {
      required: true,
    },
    website: {
      required: true,
    },
    businessRegNo: {
      required: true,
    },
    TradeMarkCertificateNo: {
      required: true,
    },
    VATNumber: {
      required: true,
    },
    clientId: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    first_name: {
      required: message.Required.replace(":item", "First Name"),
      alpha: message.InvalidName.replace(":item", "First name"),
    },
    email: {
      required: message.Required.replace(":item", "Email address"),
      email: message.InvalidEmail,
    },
    companyName: {
      required: message.Required.replace(":item", "company name"),
    },
    website: {
      required: message.Required.replace(":item", "website name"),
    },
    businessRegNo: {
      required: message.Required.replace(
        ":item",
        "business registration  number"
      ),
    },
    TradeMarkCertificateNo: {
      required: message.Required.replace(
        ":item",
        "trade mark certificate number"
      ),
    },
    VATNumber: {
      required: message.Required.replace(":item", "VAT number"),
    },
    clientId: {
      required: message.Required.replace(":item", "client id"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
