import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes, websiteRegex } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import {
  ContactDetailComponent,
  StoreDetailComponent,
} from "../../../components/clients";
import { message } from "../../../../common";
import { contactInfoValidator } from "../../../../validators/contactInfo";
import "../manage-clients.css";

class ViewClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      clientData: {},
      storeData: {},
      batchData: {},
      assignData: {},
      // Permission module related fields
      permission: [],
      selectedModule: null,
      subModules: [],
      permissionErrors: null,
      deletingModule: "", // to apply remove loader on particular index
      isContactEditable: false,
      // isStoreEditable: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      isLoading: false,
      isUpdating: false,
      updatingSection: "contact",
      isUploading: false,
      uploadPercentage: 0,
      printModal: false,
      deleteBatchError: false,
      errors: null,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        clientId: id,
      },
      () => this.viewClient()
    );
  };

  viewClient = async () => {
    const { clientId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_CLIENT.service,
      ApiRoutes.VIEW_CLIENT.url.replace(":id", clientId),
      ApiRoutes.VIEW_CLIENT.method,
      ApiRoutes.VIEW_CLIENT.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      const {
        first_name = "",
        last_name = "",
        email = "",
        phone = "",
        profile_picture,
        other_info = {},
        store = {},
        permission = [],
      } = data || {};
      const {
        address1 = "",
        address2 = "",
        state = "",
        country = "",
        postCode = "",
      } = other_info || {};
      this.setState({
        clientData: {
          first_name,
          last_name,
          email,
          phone,
          profile_picture,
          address1,
          address2,
          postCode,
          country: {
            label: country,
            value: country,
          },
          state: {
            label: state,
            value: state,
          },
        },
        storeData: {
          ...store,
          insideUSACanada: {
            label: store.insideUSACanada ? "Yes" : "No",
            value: store.insideUSACanada,
          },
          country: {
            label: store.country,
            value: store.country,
          },
          state: {
            label: store.state,
            value: store.state,
          },
        },
        permission,
      });
    }
  };
  updateClientInfo = async () => {
    const { clientId, clientData } = this.state;
    this.setState({
      isUpdating: true,
      updatingSection: "contact",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_INFO.service,
      ApiRoutes.UPDATE_CLIENT_INFO.url.replace(":id", clientId),
      ApiRoutes.UPDATE_CLIENT_INFO.method,
      ApiRoutes.UPDATE_CLIENT_INFO.authenticate,
      undefined,
      {
        ...clientData,
        country:
          clientData.country && clientData.country.value
            ? clientData.country.value
            : "",
        state:
          clientData.state && clientData.state.value
            ? clientData.state.value
            : "",
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isContactEditable: false,
      });
      toast.success(response.messages[0]);
    }
  };
  updateStoreInfo = async () => {
    const { clientId, storeData } = this.state;
    this.setState({
      isUpdating: true,
      updatingSection: "store",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STORE_INFO.service,
      ApiRoutes.UPDATE_STORE_INFO.url.replace(":id", clientId),
      ApiRoutes.UPDATE_STORE_INFO.method,
      ApiRoutes.UPDATE_STORE_INFO.authenticate,
      undefined,
      {
        storeData: {
          ...storeData,
          insideUSACanada: storeData.insideUSACanada
            ? storeData.insideUSACanada.value
            : true,
          country:
            storeData.country && storeData.country.value
              ? storeData.country.value
              : "",
          state:
            storeData.state && storeData.state.value
              ? storeData.state.value
              : "",
        },
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isStoreEditable: false,
      });
      toast.success(response.messages[0]);
    }
  };
  submitStoreDetails = () => {
    const { storeData } = this.state;
    if (
      storeData &&
      storeData.website &&
      !websiteRegex.test(storeData.website)
    ) {
      this.setState({
        storeData: {
          ...this.state.storeData,
          errors: {
            ...this.state.storeData.errors,
            website: message.InvalidWebite,
          },
        },
      });
      return;
    }
    this.updateStoreInfo();
  };
  submitContactInfoDetails = () => {
    const { clientData } = this.state;
    const { isValid, errors } = contactInfoValidator(clientData);
    if (isValid) {
      this.updateClientInfo();
    } else {
      // this.setState({
      //   clientData: {
      //     ...this.state.clientData,
      //     errors,
      //   },
      // });
      this.setState({
        errors,
      });
      return;
    }
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      if (name === "storeLogo") {
        this.setState({
          storeData: {
            ...this.state.storeData,
            errors: {
              ...this.state.storeData.errors,
              [name]: message.InvalidImages,
            },
          },
        });
      } else {
        this.setState({
          clientData: {
            ...this.state.clientData,
            errors: {
              ...this.state.storeData.errors,
              [name]: message.InvalidImages,
            },
          },
        });
      }
      return;
    }
    this.setState({
      clientData: {
        ...this.state.clientData,
        errors: {
          ...this.state.clientData.errors,
          [name]: "",
        },
      },
      storeData: {
        ...this.state.storeData,
        errors: {
          ...this.state.storeData.errors,
          [name]: "",
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = "";
  };
  onDeleteFile = (fieldName) => {
    if (fieldName === "storeLogo") {
      this.setState({
        storeData: {
          ...this.state.storeData,
          [fieldName]: "",
          errors: {
            ...this.state.storeData.errors,
            [fieldName]: "",
          },
        },
      });
    } else {
      this.setState({
        clientData: {
          ...this.state.clientData,
          [fieldName]: "",
          errors: {
            ...this.state.clientData.errors,
            [fieldName]: "",
          },
        },
      });
    }
  };
  uploadPicture = async (data, name) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      if (name === "storeLogo") {
        this.setState({
          storeData: { ...this.state.storeData, [name]: path },
        });
      } else {
        this.setState({
          clientData: { ...this.state.clientData, [name]: path },
        });
      }
    }
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
      updatingSection: name === "storeLogo" ? "store" : "contact",
    });
  };
  handleContactInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    // this.setState({
    //   clientData: {
    //     ...this.state.clientData,
    //     [name]: value,
    //     errors: {
    //       ...this.state.clientData.errors,
    //       [name]: "",
    //     },
    //   },
    // });
    this.setState({
      clientData: {
        ...this.state.clientData,
        [name]: value,
      },
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
  };
  handleStoreInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      storeData: {
        ...this.state.storeData,
        [name]: value,
        errors: {
          ...this.state.storeData.errors,
          [name]: "",
        },
      },
    });
  };

  onSelect = (selectedOption) => {
    this.setState({
      storeData: {
        ...this.state.storeData,
        insideUSACanada: selectedOption,
        country:
          selectedOption.label === this.state.storeData.insideUSACanada.label
            ? this.state.storeData.country
            : "",
        state:
          selectedOption.label === this.state.storeData.insideUSACanada.label
            ? this.state.storeData.state
            : "",
      },
    });
  };

  onSelectOption = (options, name, moduleName) => {
    // TO reset states on country selection
    if (name === "country") {
      console.log(options, name, moduleName);
      this.setState({
        [moduleName]: {
          ...this.state[moduleName],
          [name]: options,
          state: "",
          errors: {
            ...this.state[moduleName].errors,
            state: "",
            country: "",
          },
        },
        // states: [],
        // errors: {
        //   ...this.state.errors,
        //   states: '',
        //   country: '',
        // },
      });
    } else {
      this.setState({
        [moduleName]: {
          ...this.state[moduleName],
          [name]: options,
          errors: {
            ...this.state[moduleName].errors,
            [name]: "",
          },
        },
        // [name]: options,
        // errors: {
        //   ...this.state.errors,
        //   [name]: '',
        // },
      });
    }
  };

  customBreadCrumb = () => {
    const {
      isLoading,
      clientData: { first_name = "", last_name = "" },
    } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      [first_name, last_name].filter(Boolean).join(" ") || "View user"
    );
  };

  handleClose = () => {
    this.setState({
      printModal: false,
    });
  };

  onDelete = async (batchData) => {
    this.setState({
      deleteBatchError: true,
    });
  };

  // To close delete batch error modal
  handleCloseDelete = () => {
    this.setState({
      deleteBatchError: false,
    });
  };

  render() {
    const {
      isLoading,
      isContactEditable,
      isStoreEditable,
      clientId,
      clientData,
      storeData,
      isUpdating,
      updatingSection,
      isUploading,
      uploadPercentage,
      errors,
    } = this.state;

    const {
      props: {
        location: { pathname, state },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
          search={state ? state.search : ""}
        />
        <div className="row">
          <div className="col-md-12 d-flex">
            <ContactDetailComponent
              clientData={clientData}
              isLoading={isLoading}
              isEditable={isContactEditable}
              isUpdating={isUpdating && updatingSection === "contact"}
              onEdit={() =>
                this.setState({
                  isContactEditable: true,
                })
              }
              onCancel={() =>
                this.setState({
                  isContactEditable: false,
                })
              }
              onChange={this.handleContactInfoChange}
              onSubmit={this.submitContactInfoDetails}
              isUploading={isUploading && updatingSection === "contact"}
              uploadPercentage={uploadPercentage}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              // isEditable={true}
              onSelectOption={this.onSelectOption}
              errors={errors}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <StoreDetailComponent
              clientId={clientId}
              storeData={storeData}
              isLoading={isLoading}
              isEditable={isStoreEditable}
              isUpdating={isUpdating && updatingSection === "store"}
              onEdit={() =>
                this.setState({
                  isStoreEditable: true,
                })
              }
              onCancel={() =>
                this.setState({
                  isStoreEditable: false,
                })
              }
              onChange={this.handleStoreInfoChange}
              onSelect={this.onSelect}
              onSubmit={this.submitStoreDetails}
              isUploading={isUploading && updatingSection === "store"}
              uploadPercentage={uploadPercentage}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              permissionBtn={false}
              // isEditable={true}
              onSelectOption={this.onSelectOption}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ViewClient;
