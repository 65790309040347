import React from "react";
import { TableLoader } from "../loader/Resusable/Table";
import editIcon from "../../../assets/img/edit-3.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import { Button } from "react-bootstrap";
import moment from "moment";
import listIcon from "../../../assets/img/list.svg";

export const SubscribedListComponent = ({
  subscribed,
  setFilter,
  sortBy,
  order,
  isLoading,
  viewSubscribed,
}) => {
  return (
    <>
      <div className="card-body card-table">
        <div className="table-responsive fixed-height-table-10-lg">
          {isLoading ? (
            <TableLoader columns={5} rows={10} />
          ) : (
            <table
              className="plansTable transactionTable"
              width="100%"
              cellSpacing="0"
            >
              <thead className="pad-top">
                <tr>
                  {[
                    { label: "Client Name", value: "companyName" },
                    { label: "Plan Name", value: "name" },
                    { label: "Plan Duration", value: "planDuration" },
                    { label: "Date & Time", value: "purchaseDate" },
                  ].map(({ label, value }) => (
                    <th key={value}>
                      {label}{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === value
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter(value, "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === value
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter(value, "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                  ))}
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {subscribed &&
                  subscribed.map((info, index) => (
                    <tr key={index}>
                      <td>{info?.store?.companyName}</td>
                      <td>{info?.subscription?.name}</td>
                      <td>{info.billingCycle}</td>
                      <td>
                        {moment
                          .utc(info.purchaseDate)
                          .format("MM/DD/YYYY HH:mm")}
                      </td>
                      <td>
                        <div>
                          <button
                            className="btn btn-secondary th-btn"
                            onClick={() => viewSubscribed(info)}
                          >
                            {" "}
                            <img src={listIcon} alt="" /> View{" "}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {/* {totalRecords > limit ? (
          <div className='float-right mt-2'>
          <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
           /> 
          </div>
        ) : null}*/}
        </div>
      </div>
    </>
  );
};
