/** @format */

export const ApiRoutes = {
  LOGIN: {
    service: "/auth",
    url: "/login",
    method: "POST",
    authenticate: false,
  },
  GET_PROFILE: {
    service: "/auth",
    url: "/me",
    method: "GET",
    authenticate: true,
  },
  ADD_USER: {
    service: "/users",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  USER_LIST: {
    service: "/users",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_USER_INFO_BY_ID: {
    service: "/users",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  BLOCK_USER: {
    service: "/users",
    url: "/block/:id",
    method: "PATCH",
    authenticate: true,
  },
  UNBLOCK_USER: {
    service: "/users",
    url: "/unblock/:id",
    method: "PATCH",
    authenticate: true,
  },
  SCANS_LIST: {
    service: "/scan",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  FAQS_LIST: {
    service: "/faq",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  PUSHS_LIST: {
    service: "/pushNotifications",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  COUNTERFEIT_LIST: {
    service: "/counterfeit",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_COUNTERFEIT_DETAIL: {
    service: "/counterfeit",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ: {
    service: "/counterfeit",
    url: "/:id/change-assignee",
    method: "PUT",
    authenticate: true,
  },
  UNASSIGN_STAFF_FROM_REQ: {
    service: "/counterfeit",
    url: "/:id/unassign",
    method: "PUT",
    authenticate: true,
  },
  CATEGORIES_LIST: {
    service: "/category",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  ADD_CATEGORY: {
    service: "/category",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  DELETE_CATEGORY: {
    service: "/category",
    url: "/:id",
    method: "delete",
    authenticate: true,
  },
  VIDEOS_LIST: {
    service: "/videos",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  DELETE_VIDEO: {
    service: "/videos",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  ADD_VIDEO: {
    service: "/videos",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_VIDEO: {
    service: "/videos",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  UPDATE_VIDEO_STATUS: {
    service: "/videos",
    url: "/status/:id",
    method: "put",
    authenticate: true,
  },
  ADD_PRODUCT: {
    service: "/product",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  ADD_FAQ: {
    service: "/faq",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  ADD_PUSH: {
    service: "/pushNotifications",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VIEW_PRODUCT: {
    service: "/product",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  PRODUCTS_LIST: {
    service: "/product",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PRODUCT: {
    service: "/product",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  UPDATE_FAQ: {
    service: "/faq",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  UPDATE_PUSH: {
    service: "/pushNotifications",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  DELETE_PRODUCT: {
    service: "/product",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  DELETE_FAQ: {
    service: "/faq",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  DELETE_PUSHS: {
    service: "/pushNotifications",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  PRODUCTS_OPTION_LIST: {
    service: "/product",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  ORIGINS_OPTION_LIST: {
    service: "/origin",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  ADD_ORIGIN: {
    service: "/origin",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VIEW_ORIGIN: {
    service: "/origin",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  ORIGINS_LIST: {
    service: "/origin",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_ORIGIN: {
    service: "/origin",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  DELETE_ORIGIN: {
    service: "/origin",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  ADD_BATCH: {
    service: "/batch",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VIEW_BATCH: {
    service: "/batch",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  BATCHES_LIST: {
    service: "/batch",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_BATCH: {
    service: "/batch",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
  DELETE_BATCH: {
    service: "/batch",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  BATCH_history: {
    service: "/batch",
    url: "/:id/history",
    method: "GET",
    authenticate: true,
  },
  DELETE_BATCH_HISTORY: {
    service: "/batch",
    url: "/",
    method: "PATCH",
    authenticate: true,
  },
  INACTIVE_BATCH: {
    service: "/batch",
    url: "/mark-as-inactive",
    method: "post",
    authenticate: true,
  },
  EXPORT_BATCH: {
    service: "/batch",
    url: "/:id/export",
    method: "GET",
    authenticate: true,
  },
  ASSIGN_BATCH: {
    service: "/batch",
    url: "/:id/assign",
    method: "POST",
    authenticate: true,
  },
  ASSIGN_BATCH_TO_CLIENT: {
    service: "/batch",
    url: "/assign/client",
    method: "POST",
    authenticate: true,
  },
  EDIT_ASSIGNED_BATCH_OF_CLIENT: {
    service: "/batch",
    url: "/assign/client/:batchGroup",
    method: "PUT",
    authenticate: true,
  },
  FETCH_ASSIGNED_BATCH: {
    service: "/batch",
    url: "/assign",
    method: "GET",
    authenticate: true,
  },
  BATCH_OPTION_LIST: {
    service: "/batch",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  UPLOAD_FILES: {
    service: "/upload",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  ADD_CLIENTS: {
    service: "/clients",
    url: "",
    method: "POST",
    authenticate: true,
  },
  VERIFY_CLIENT_INVITATION: {
    service: "/clients",
    url: "/verify-link",
    method: "POST",
    authenticate: false,
  },
  RESEND_INVITATION_TO_CLIENT: {
    service: "/clients",
    url: "/resend-invitation/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_CLIENTS_REQUEST: {
    service: "/clients",
    url: "/requests",
    method: "GET",
    authenticate: true,
  },
  GET_CLIENTS: {
    service: "/clients",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_CLIENT_OPTION_LIST: {
    service: "/clients",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  VIEW_CLIENT: {
    service: "/clients",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  SUBMIT_CLIENT_FORM: {
    service: "/clients",
    url: "/submit/:id",
    method: "PUT",
    authenticate: false,
  },
  UPDATE_CLIENT_INFO: {
    service: "/clients",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_CLIENT_ADDRESS: {
    service: "/clients/address",
    url: "/:id",
    method: "Patch",
    authenticate: true,
  },
  UPDATE_STORE_INFO: {
    service: "/clients",
    url: "/store/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_CLIENT_PERMISSION: {
    service: "/clients",
    url: "/:id/permission",
    method: "GET",
    authenticate: true,
  },
  UPDATE_CLIENT_PERMISSION: {
    service: "/clients",
    url: "/permission/:id",
    method: "PATCH",
    authenticate: true,
  },
  UPDATE_CLIENT_STATUS: {
    service: "/clients",
    url: "/status/:id",
    method: "patch",
    authenticate: true,
  },
  FETCH_CLIENT_ASSIGNED_BATCH: {
    service: "/clients",
    url: "/:id/batch-history",
    method: "GET",
    authenticate: true,
  },
  MARK_CLIENT_BATCH_PRINTED: {
    service: "/batch",
    url: "/:id/mark-as-print",
    method: "POST",
    authenticate: true,
  },
  MARK_INACTIVE_CLIENT_BATCH: {
    service: "/batch",
    url: "/mark-as-inactive",
    method: "POST",
    authenticate: true,
  },
  UNASSIGNED_BATCH_FROM_CLIENT: {
    service: "/batch",
    url: "/unassign/:id",
    method: "PUT",
    authenticate: true,
  },
  UNASSIGNED_ALL_BATCH_FROM_CLIENT: {
    service: "/batch",
    url: "/unassign/bulk",
    method: "PUT",
    authenticate: true,
  },
  BATCH_HISTORY_BY_PRODUCT: {
    service: "/batch",
    url: "/history-by-product",
    method: "GET",
    authenticate: true,
  },
  FETCH_ASIGNED_BATCH_BY_PRODUCT: {
    service: "/batch",
    url: "/assigned-by-product",
    method: "GET",
    authenticate: true,
  },
  ADD_STAFF: {
    service: "/staff",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  VERIFY_STAFF_INVITATION: {
    service: "/staff",
    url: "/verify-link",
    method: "POST",
    authenticate: true,
  },
  GET_STAFF_REQUEST: {
    service: "/staff",
    url: "/requests",
    method: "GET",
    authenticate: true,
  },
  GET_STAFF: {
    service: "/staff",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_STAFF_OPTION_LIST: {
    service: "/staff",
    url: "/option-list",
    method: "GET",
    authenticate: true,
  },
  VIEW_STAFF: {
    service: "/staff",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  SUBMIT_STAFF_FORM: {
    service: "/staff",
    url: "/submit/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_STAFF_INFO: {
    service: "/staff",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_STAFF_STATUS: {
    service: "/staff",
    url: "/status/:id",
    method: "patch",
    authenticate: true,
  },
  RESEND_INVITATION_TO_STAFF: {
    service: "/staff",
    url: "/resend-invitation/:id",
    method: "PUT",
    authenticate: true,
  },
  REQUEST_FOR_MODIFICATION_TO_STAFF: {
    service: "/staff",
    url: "/request-for-modification/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_STAFF_PERMISSION: {
    service: "/staff",
    url: "/:id/permission",
    method: "GET",
    authenticate: true,
  },
  UPDATE_STAFF_PERMISSION: {
    service: "/staff",
    url: "/permission/:id",
    method: "PATCH",
    authenticate: true,
  },
  DASHBOARD_MEMEBR_STATISTICS: {
    service: "/dashboard",
    url: "/member-statistics",
    method: "GET",
    authenticate: true,
  },
  DASHBOARD_SCAN_STATISTICS: {
    service: "/dashboard",
    url: "/scan-statistics",
    method: "GET",
    authenticate: true,
  },
  DASHBOARD_Counterfeits_STATISTICS: {
    service: "/dashboard",
    url: "/counterfeit-statistics",
    method: "GET",
    authenticate: true,
  },
  DASHBOARD_TOP_SCANNED_PRODUCT: {
    service: "/dashboard",
    url: "/top-scanned-products",
    method: "GET",
    authenticate: true,
  },
  DASHBOARD_ACTIVE_USER_LOCATION: {
    service: "/dashboard",
    url: "/active-user-locations",
    method: "GET",
    authenticate: true,
  },
  GET_NOTIFICATIONS: {
    service: "/notifications",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  RESET_NOTIFICATION_COUNT: {
    service: "/notifications",
    url: "/resetCount",
    method: "patch",
    authenticate: true,
  },
  NOTIFICATION_MARK_READ: {
    service: "/notifications",
    url: "/read/:id",
    method: "patch",
    authenticate: true,
  },
  CLEAR_ALL_NOTIFICATIONS: {
    service: "/notifications",
    url: "/clearAll",
    method: "PUT",
    authenticate: true,
  },
  DELETE_NOTIFICATION: {
    service: "/notifications",
    url: "/:id",
    method: "delete",
    authenticate: true,
  },
  DELETE_CLIENT: {
    service: "/clients",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  DELETE_INVITED_CLIENT: {
    service: "/clients/requests",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  CHANGE_COUNTERFEIT_REQUEST_STATUS: {
    service: "/counterfeit",
    url: "/change-request-status/:id",
    method: "put",
    authenticate: true,
  },
  GET_SETTINGS: {
    service: "/settings",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  SAVE_SETTINGS: {
    service: "/settings",
    url: "/",
    method: "put",
    authenticate: true,
  },
  REPORTS_BATCHES_CARDS: {
    service: "/report/batches/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_USERS_CARDS: {
    service: "/report/users/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_PRODUCT_CARDS: {
    service: "/report/product/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_COUNTERFEIT_CARDS: {
    service: "/report/counterfeit/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_SCAN_CARDS: {
    service: "/report/scan/cards",
    url: "/",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_SCAN_TABLE: {
    service: "/report/scan",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_BATCHES_TABLE: {
    service: "/report/batches/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_BATCHES_TABLE: {
    service: "/report/batches",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  EXPORT_REPORTS_COUNTERFEIT_TABLE: {
    service: "/report/counterfeit",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_USERS_TABLE: {
    service: "/report/users/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_USERS_TABLE: {
    service: "/report/users",
    url: "/os-export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_PRODUCT_TABLE: {
    service: "/report/product/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  EXPORT_REPORTS_PRODUCT_TABLE: {
    service: "/report/product",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  REPORTS_COUNTERFEIT_TABLE: {
    service: "/report/counterfeit/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_SCAN_TABLE: {
    service: "/report/scan/table",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_USERS_GRAPH_OS: {
    service: "/report/users/os-graph",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_USERS_GRAPH_LOCATION: {
    service: "/report/users/location-graph",
    url: "/",
    method: "get",
    authenticate: true,
  },
  REPORTS_SCAN_GRAPH_PRODUCTS: {
    service: "/report/scan/products-graph",
    url: "/",
    method: "get",
    authenticate: true,
  },
  CHOOSE_SUBSCRIPTION: {
    service: "/subscriptions/",
    url: "",
    method: "get",
    authenticate: true,
  },
  DELETE_SUBSCRIPTION: {
    service: "/subscriptions/",
    url: ":id",
    method: "delete",
    authenticate: true,
  },
  ADD_SUBSCRIPTION: {
    service: "/subscriptions/",
    url: "",
    method: "POST",
    authenticate: true,
  },
  GET_SUBSCRIPTION_ID: {
    service: "/subscriptions/",
    url: ":id",
    method: "get",
    authenticate: true,
  },
  EDIT_SUBSCRIPTION: {
    service: "/subscriptions/",
    url: ":id",
    method: "Put",
    authenticate: true,
  },
  GET_TRANSACTION: {
    service: "/transactions",
    url: "",
    method: "get",
    authenticate: true,
  },
  GET_TRANSACTION_BY_ID: {
    service: "/transactions",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  GET_INVOICE: {
    service: "/transactions/invoice",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  GET_BILLING: {
    service: "/clientSubscriptions",
    url: "/",
    method: "get",
    authenticate: true,
  },
  GET_BILLING_BY_ID: {
    service: "/clientSubscriptions",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  ADD_DISCOUNT: {
    service: "/subscriptions",
    url: "/discount",
    method: "PATCH",
    authenticate: true,
  },
  GET_DISCOUNT: {
    service: "/settings",
    url: "/discount",
    method: "get",
    authenticate: true,
  },
  GET_BILLING_INVOICE: {
    service: "/clientSubscriptions/exportInvoice",
    url: "/:id",
    method: "get",
    authenticate: true,
  },
  DELETE_IMAGE: {
    service: "/upload",
    url: "/:file",
    method: "Delete",
    authenticate: true,
  },
  GET_ROLE: {
    service: "/role",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  ADD_ROLE: {
    service: "/role",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  EDIT_ROLE: {
    service: "/role",
    url: "/:id",
    method: "PATCH",
    authenticate: true,
  },
  DELETE_ROLE: {
    service: "/role",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_PROFILE: {
    service: "/auth",
    url: "/update-profile",
    method: "PUT",
    authenticate: true,
  },
  GET_VIDEO_TUTORIAL: {
    service: "/videoTutorials",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  ADD_VIDEO_TUTORIAL: {
    service: "/videoTutorials",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_VIDEO_TUTORIAL: {
    service: "/videoTutorials",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_VIDEO_TUTORIAL: {
    service: "/videoTutorials",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_FAQ_CAT: {
    service: "/faqCategory/",
    url: "",
    method: "GET",
    authenticate: true,
  },
  GET_FAQ_CAT_BY_ID: {
    service: "/faqCategory",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  ADD_FAQ_CAT: {
    service: "/faqCategory",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_FAQ_CAT: {
    service: "/faqCategory",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_FAQ_CAT: {
    service: "/faqCategory",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_FAQ_STATUS: {
    service: "/faq",
    url: "/:id",
    method: "PATCH",
    authenticate: true,
  },
  GET_CONTACT: {
    service: "/contact",
    url: "/",
    method: "get",
    authenticate: true,
  },
  ADD_CONTACT: {
    service: "/contact",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_CONTACT: {
    service: "/contact",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_Client_ACCOUNT: {
    service: "/clients",
    url: "/:id",
    method: "Delete",
    authenticate: true,
  },
  ADD_PARTNER: {
    service: "/admin/partners",
    url: "/create-partner",
    method: "POST",
    authenticate: true,
  },
  PARTNER_LIST: {
    service: "/admin/partners",
    url: "/get-all-partners",
    method: "GET",
    authenticate: true,
  },
  DELETE_PARTNER: {
    service: "/admin/partners",
    url: "/delete-partner",
    method: "POST",
    authenticate: true,
  },
  UPDATE_PARTNER: {
    service: "/admin/partners",
    url: "/update-partner",
    method: "POST",
    authenticate: true,
  },

  UPDATE_FAQ_NEW: {
    service: "/admin/faqs",
    url: "/update-faq",
    method: "POST",
    authenticate: true,
  },
  DELETE_FAQ_NEW: {
    service: "/admin/faqs",
    url: "/delete-faq",
    method: "DELETE",
    authenticate: true,
  },
  ADD_FAQ_NEW: {
    service: "/admin/faqs",
    url: "/create-faq",
    method: "POST",
    authenticate: true,
  },
  FAQS_LIST_NEW: {
    service: "/admin/faqs",
    url: "/get-all-faqs",
    method: "GET",
    authenticate: true,
  },
  ABOUT_US_LIST_NEW: {
    service: "/admin/about",
    url: "/get-all-about-us",
    method: "GET",
    authenticate: true,
  },
  ABOUT_US_DETAIL_NEW: {
    service: "/admin/about",
    url: "/get-about-us",
    method: "GET",
    authenticate: true,
  },
  DELETE_ABOUT_US_DETAIL_NEW: {
    service: "/admin/about",
    url: "/delete-about-us",
    method: "POST",
    authenticate: true,
  },
  ADD_ABOUT_US_DETAIL_NEW: {
    service: "/admin/about",
    url: "/create-about-us",
    method: "POST",
    authenticate: true,
  },
  UPDATE_ABOUT_US_DETAIL_NEW: {
    service: "/admin/about",
    url: "/update-about-us",
    method: "POST",
    authenticate: true,
  },
  GET_TERMS_AND_CONDITION_NEW: {
    service: "/admin/settings",
    url: "/get-terms-and-conditions",
    method: "GET",
    authenticate: true,
  },
  UPDATE_TERMS_AND_CONDITION_NEW: {
    service: "/admin/settings",
    url: "/update-terms-and-conditions",
    method: "POST",
    authenticate: true,
  },
  GET_PRIVACY_POLICY_NEW: {
    service: "/admin/settings",
    url: "/get-privacy-policy",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PRIVACY_POLICY_NEW: {
    service: "/admin/settings",
    url: "/update-privacy-policy",
    method: "POST",
    authenticate: true,
  },
  GET_ALL_GENERAL_ENQUIRIES_NEW: {
    service: "/admin/enquiries",
    url: "/get-general-enquiries",
    method: "GET",
    authenticate: true,
  },
  GET_GENERAL_ENQUIRIES_NEW: {
    service: "/admin/enquiries",
    url: "/get-general-enquiry-details",
    method: "GET",
    authenticate: true,
  },
  GET_JOB_APPLICATION_NEW: {
    service: "/admin/enquiries",
    url: "/get-job-application-details",
    method: "GET",
    authenticate: true,
  },
  CHANGE_STATUS_GENERAL_ENQUIRIES_NEW: {
    service: "/admin/enquiries",
    url: "/update-general-enquiry",
    method: "POST",
    authenticate: true,
  },
  CHANGE_STATUS_JOB_APPLICATION_NEW: {
    service: "/admin/enquiries",
    url: "/update-job-application",
    method: "POST",
    authenticate: true,
  },
  GET_CAREERS_ENQUIRIES_NEW: {
    service: "/admin/enquiries",
    url: "/get-job-applications",
    method: "GET",
    authenticate: true,
  },
  GET_CONTACT_WEB_NEW: {
    service: "/admin/contact-us",
    url: "/get-contact-details",
    method: "GET",
    authenticate: true,
  },
  UPDATE_CONTACT_WEB_NEW: {
    service: "/admin/contact-us",
    url: "/update-contact-details",
    method: "POST",
    authenticate: true,
  },
  GET_ALL_SALES_ENQUIRIES_NEW: {
    service: "/admin/enquiries",
    url: "/get-sales-enquiries",
    method: "GET",
    authenticate: true,
  },
  GET_SALES_ENQUIRIES_NEW: {
    service: "/admin/enquiries",
    url: "/get-sales-enquiry-details",
    method: "GET",
    authenticate: true,
  },
  CHANGE_STATUS_SALES_ENQUIRIES_NEW: {
    service: "/admin/enquiries",
    url: "/update-sales-enquiry",
    method: "POST",
    authenticate: true,
  },
  GET_VIDEO_TUTORIAL_NEW: {
    service: "/admin/tutorials",
    url: "/get-video-tutorials",
    method: "GET",
    authenticate: true,
  },
  DELETE_VIDEO_TUTORIAL_NEW: {
    service: "/admin/tutorials",
    url: "/delete-video-tutorial",
    method: "DELETE",
    authenticate: true,
  },
  ADD_VIDEO_TUTORIAL_NEW: {
    service: "/admin/tutorials",
    url: "/add-video-tutorial",
    method: "POST",
    authenticate: true,
  },
  UPDATE_VIDEO_TUTORIAL_NEW: {
    service: "/admin/tutorials",
    url: "/update-video-tutorial",
    method: "POST",
    authenticate: true,
  },
  GET_ALL_MODULE_LISTING_NEW: {
    service: "/admin/account-management",
    url: "/get-modules",
    method: "GET",
    authenticate: true,
  },
  DELETE_MODULE_NEW: {
    service: "/admin/account-management",
    url: "/delete-module",
    method: "DELETE",
    authenticate: true,
  },
  ADD_MODULE_NEW: {
    service: "/admin/account-management",
    url: "/add-module",
    method: "POST",
    authenticate: true,
  },
  UPDATE_MODULE_NEW: {
    service: "/admin/account-management",
    url: "/update-module",
    method: "POST",
    authenticate: true,
  },
  GET_ALL_TOPIC_LISTING_NEW: {
    service: "/admin/account-management",
    url: "/get-topics",
    method: "GET",
    authenticate: true,
  },
  DELETE_TOPIC_NEW: {
    service: "/admin/account-management",
    url: "/delete-topic",
    method: "DELETE",
    authenticate: true,
  },
  ADD_TOPIC_NEW: {
    service: "/admin/account-management",
    url: "/add-topic",
    method: "POST",
    authenticate: true,
  },
  UPDATE_TOPIC_NEW: {
    service: "/admin/account-management",
    url: "/update-topic",
    method: "POST",
    authenticate: true,
  },
  GET_JOB_TITLES: {
    service: "/admin/careers",
    url: "/get-job-titles",
    method: "GET",
    authenticate: true,
  },
  ADD_JOB_TITLE: {
    service: "/admin/careers",
    url: "/add-job-title",
    method: "POST",
    authenticate: true,
  },
  UPDATE_JOB_TITLE: {
    service: "/admin/careers",
    url: "/update-job-title",
    method: "POST",
    authenticate: true,
  },
  DELETE_JOB_TITLE: {
    service: "/admin/careers",
    url: "/delete-job-title",
    method: "DELETE",
    authenticate: true,
  },
  GET_JOBS: {
    service: "/admin/careers",
    url: "/get-jobs",
    method: "GET",
    authenticate: true,
  },
  ADD_JOB: {
    service: "/admin/careers",
    url: "/add-job",
    method: "POST",
    authenticate: true,
  },
  UPDATE_JOB: {
    service: "/admin/careers",
    url: "/update-job",
    method: "POST",
    authenticate: true,
  },
  DELETE_JOB: {
    service: "/admin/careers",
    url: "/delete-job",
    method: "DELETE",
    authenticate: true,
  },
  SCAN_RULES_LIST: {
    service: "/scanRules",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  DELETE_SCAN_RULE: {
    service: "/scanRules",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_SCAN_RULE: {
    service: "/scanRules",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },

  ADD_SCAN_RULE: {
    service: "/scanRules",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  UPDATE_SCAN_RULE: {
    service: "/scanRules",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  Alerts: {
    service: "/alerts",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_Alert_DETAILS: {
    service: "/alerts/details?batchUnitId",
    url: "=:id",
    method: "GET",
    authenticate: true,
  },
  COUNTERFEIT: {
    service: "/confirmedCounterfeit",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  ADD_COUNTERFEIT: {
    service: "/confirmedCounterfeit",
    url: "",
    method: "POST",
    authenticate: true,
  },
  DELETE_COUNTERFEIT: {
    service: "/confirmedCounterfeit",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_COUNTERFEIT: {
    service: "/confirmedCounterfeit",
    url: "/:id",
    method: "put",
    authenticate: true,
  },
};
