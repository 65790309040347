import React, { useState, useEffect } from "react";
import { InputTextField, SubmitButton } from "../../components/forms";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes, AppRoutes } from "../../../config";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import vector from "../../../assets/img/Vector.svg";
import { partnersValidator } from "../../../validators/partners";
// import defaultPartnerImg from "../../../assets/img/default_partner_img.png";
import { getCountries, getStates } from "country-state-picker";
import { ColorRing } from "react-loader-spinner";
import { groupItemStyles } from "@atlaskit/button/dist/cjs/components/ButtonGroup";
import defaultPartnerImg from "../../../assets/img/clarity_logo.svg";
export const AddLandingPageComponent = ({
  partner,
  handleAddCancel,
  allOptions,
  userDetail,
  partners,
}) => {
  console.log(partner, ":::partners");
  const countryList = getCountries();
  const [PartnerName, setPartnerName] = useState(partner?.name || "");
  const [PartnerEmail, setPartnerEmail] = useState(partner?.email || "");
  const [Country, setCountry] = useState(partner?.country || "");
  const [State, setState] = useState(partner?.city || "");
  const [Profile, setProfile] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [categoryId, setCatId] = useState();
  const [selectedImage, setSelectImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [logoUpload, setLogoUpload] = useState(false);
  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "PartnerName") {
      setPartnerName(value.trimLeft());
    }
    if (name === "PartnerEmail") {
      setPartnerEmail(value.trimLeft());
    }
    if (name === "country") {
      setCountry(value.trimLeft());
      getStateByCountry(value);
    }
    if (name === "state") {
      setState(value.trimLeft());
    }
    // if (name == "profile") {
    //   setProfile(value);
    // }
  };

  const getStateByCountry = (value) => {
    console.log(value);
    const selectedCountry = countryList?.filter(
      (country) => country?.name === value
    );
    const data = getStates(selectedCountry?.[0]?.code);
    console.log(data, "::data");
    setStateList(data);
  };

  const onFileChange = (event) => {
    validateForm(event) && setProfile(event.target.files[0]);
  };

  const validateForm = (event) => {
    setLogoUpload(true);
    const fileInput = event.target;
    let errorMessage = "";

    // Check if a file is selected
    if (!fileInput.value) {
      errorMessage = "Please select an image.";
      return false;
    }

    const fileSize = fileInput.files[0].size; // in bytes
    const maxSize = 200 * 1024; // 200 KB in bytes
    const minSize = 5 * 1024;

    const maxImageSize = {
      width: 2500, //1315,
      height: 2350, //1434
    };

    const minImageSize = {
      width: 500,
      height: 600,
    };

    // Check against minimum size
    if (fileSize < minSize) {
      errorMessage = "Image size is below the minimum limit (5 KB).";
      // return false;
    }

    // Check against maximum size
    if (fileSize > maxSize) {
      errorMessage = "Image size exceeds the maximum limit (200 KB).";
      // return false;
    }

    if (fileInput.files[0]) {
      // Use FileReader to read the image
      const reader = new FileReader();
      reader.onload = (e) => {
        getImageDimensions(e.target.result)
          .then((dimensions) => {
            console.log(dimensions);
            if (dimensions?.width > maxImageSize?.width) {
              errorMessage = "Image width size should be below 2500.";
            }
            if (dimensions?.width < minImageSize?.width) {
              errorMessage = "Image width size should be greater then 500.";
            }
            if (dimensions?.height > maxImageSize?.height) {
              errorMessage = "Image height size should be below 2350.";
            }
            if (dimensions?.height < minImageSize?.height) {
              errorMessage = "Image hieght size should be greater then 600.";
            }

            // Set the image and its dimensions in state
            // setImage(fileInput.files[0]);
            // setImageDimensions(dimensions);
          })
          .catch((error) => {
            console.error(error);
          });
      };
      // Read the image as a data URL
      reader.readAsDataURL(fileInput.files[0]);
    }
    // Clear any previous error messages
    if (errorMessage?.length) {
      toast.error(errorMessage);
      setLogoUpload(false);

      return false;
    } else {
      return true;
    }

    // Continue with form submission or other logic
    // For example, you can handle the file upload here or call another function.

    // return true;
  };

  // Function to get image dimensions
  const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (event) => {
    const formData = new FormData();
    setIsLoading(true);
    event.preventDefault();
    let payload = {};
    if (userDetail === "Web") {
      payload = {
        name: PartnerName,
        email: PartnerEmail,
        country: Country,
        state: State,
        logo: Profile,
      };
      formData.append("name", PartnerName);
      formData.append("email", PartnerEmail);
      formData.append("country", Country);
      formData.append("city", State);
      Profile && formData.append("logo", Profile);
    }
    const { isValid, errors } = partnersValidator(payload);

    let filterData = "";
    // if (userDetail !== "App") {
    //   filterData = partners?.filter(
    //     (x) =>
    //       parseInt(x.priority) === parseInt(priority) &&
    //       x.categoryName === categoryId.label
    //   );
    // }
    if (isValid) {
      setErrors({});
      if (filterData.length === 0) {
        if (partner._id) {
          formData.append("id", partner._id);

          updateEntry(formData);
        } else {
          addEntry(formData);
        }
      } else {
        setIsLoading(false);
        // toast.error("Priority already exist under this category");
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
      return;
    }
  };

  const addEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.ADD_PARTNER.service,
      ApiRoutes.ADD_PARTNER.url,
      ApiRoutes.ADD_PARTNER.method,
      ApiRoutes.ADD_PARTNER.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  const updateEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.UPDATE_PARTNER.service,
      ApiRoutes.UPDATE_PARTNER.url,
      ApiRoutes.UPDATE_PARTNER.method,
      ApiRoutes.UPDATE_PARTNER.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  useEffect(() => {
    if (partner?._id) {
      setIsEditMode(true);
      setPartnerName(partner?.name);
      setPartnerEmail(partner?.email);
      setCountry(partner?.country);
      setState(partner?.city);
      getStateByCountry(partner?.country);
      // setProfile(partner?.logo);
      setSelectImage(partner?.logo);
    } else {
      setIsEditMode(false);
    }
  }, []);

  const onSelect = (label) => {
    if (label) {
      setCatId(label);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <form className="product_add proAdd">
      <div className="row">
        <div className="row">
          <div className="col-md-6">
            <label className={`form-control-label_About`}>Partner Name</label>
            <InputTextField
              type="text"
              id="PartnerName"
              placeholder="Enter Name"
              // label={"Partner Name"}
              className={"form-control-product"}
              name="PartnerName"
              value={PartnerName}
              onChange={onChange}
              errorMessage={errors && errors?.name ? errors?.name : ""}
            />
          </div>
          {userDetail !== "App" && (
            <div className="col-md-6">
              <label className={`form-control-label_About`}>
                Partner Email
              </label>
              <InputTextField
                type="text"
                id="PartnerEmail"
                placeholder="Enter Email"
                // label={"Partner Email"}
                className={"form-control-product"}
                name="PartnerEmail"
                value={PartnerEmail}
                onChange={onChange}
                errorMessage={errors && errors?.email ? errors?.email : ""}
              />
            </div>
          )}
          <div className="col-md-6 right-arrow-fix">
            <div className="form-group">
              <label className={`form-control-label_About `}>Country</label>
              <select
                className="form-control form-control-input"
                name="country"
                id="country"
                defaultValue={Country}
                onChange={(e) => onChange(e)}
              >
                <option selected="selected">Select Country</option>
                {countryList?.map((country) => {
                  return (
                    <>
                      <option value={country?.name}>{country?.name}</option>
                    </>
                  );
                })}
              </select>
              <div className={"text-danger"}>
                {errors && errors?.country ? errors?.country : ""}
              </div>
            </div>
          </div>
          <div className="col-md-6 right-arrow-fix">
            <div className="form-group">
              <label className={`form-control-label_About `}>State</label>
              <select
                className="form-control form-control-input"
                name="state"
                id="state"
                defaultValue={State}
                value={State}
                onChange={(e) => onChange(e)}
              >
                <option selected="selected">Select State</option>
                {stateList?.map((state) => {
                  return (
                    <>
                      <option value={state}>{state}</option>
                    </>
                  );
                })}
              </select>
              <div className={"text-danger"}>
                {errors && errors?.state ? errors?.state : ""}
              </div>
            </div>
          </div>
          <div className="col-md-12" style={{ paddingTop: "25px" }}>
            <label
              htmlFor="product_description"
              className="form-control-label_About"
            >
              Logo
            </label>
            {isEditMode ? (
              <>
                <div className="uploading-section-edit">
                  <div
                    className="uploaded-img"
                    style={{ position: "relative" }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        display: `${selectedImage ? "block" : "none"}`,
                        filter: logoUpload ? "grayscale(0.8)" : "",
                        opacity: logoUpload ? 0.8 : 1,
                      }}
                      src={selectedImage && selectedImage}
                    />
                    <div
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        backgroundColor: "transparent",
                        display: logoUpload ? "block" : "none",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <ColorRing
                          visible={true}
                          height="100"
                          width="100"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <p>{selectedImageName}</p>
                    </div>
                    <div className="vector-file-upload-edit">
                      <div className="text-vector">
                        <span
                          style={{
                            fontSize: "13px",
                            lineHeight: "10px",
                            fontWeight: 700,
                            color: "#0e50a0",
                          }}
                        >
                          {" "}
                          Change Image{" "}
                        </span>
                      </div>
                      <input
                        type="file"
                        name="profile"
                        id="profile"
                        style={{
                          position: "absolute",
                          height: "40px",
                          width: "140px",
                          top: 0,
                          left: 0,
                          cursor: "pointer",
                          display: "block",
                          opacity: 0,
                        }}
                        accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                        onChange={(e) => {
                          if (validateForm(e)) {
                            setLogoUpload(true);
                            const file = e.target.files[0];
                            setTimeout(() => {
                              setProfile(file);
                              setSelectImage(URL.createObjectURL(file));
                              setSelectedImageName(file?.name);
                              setLogoUpload(false);
                            }, 2000);
                          }
                        }}
                        className="imgUpload img_up "
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="uploading-section">
                  <div className="vector-file-upload">
                    <img src={vector} />
                    <div className="text-vector-main">
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: 700,
                          lineHeight: "10px",
                        }}
                      >
                        {" "}
                        <u>Upload</u> or Drag & Drop Logo{" "}
                      </span>
                      <div
                        style={{
                          fontSize: "10px",
                          marginTop: "8px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      >
                        Square 2500 x 2350 pixels (200kb Max) Formats supported
                        PNG, Jpeg, JPG
                      </div>
                    </div>
                    <input
                      type="file"
                      name="profile"
                      id="profile"
                      style={{
                        position: "absolute",
                        height: "95px",
                        width: "275px",
                        top: 0,
                        left: 0,
                        cursor: "pointer",
                        display: "block",
                        opacity: 0,
                      }}
                      onDragOver={handleDragOver}
                      onDrop={(e) => {
                        if (validateForm(e)) {
                          setSelectImage("");
                          const file = e.target.files[0];
                          setTimeout(() => {
                            setProfile(file);
                            setSelectImage(URL.createObjectURL(file));
                            setSelectedImageName(file?.name);
                            setLogoUpload(false);
                          }, 2000);
                        }
                      }}
                      accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                      onChange={(e) => {
                        if (validateForm(e)) {
                          setSelectImage("");
                          const file = e.target.files[0];
                          setTimeout(() => {
                            setProfile(file);
                            setSelectImage(URL.createObjectURL(file));
                            setSelectedImageName(file?.name);
                            setLogoUpload(false);
                          }, 2000);
                        }
                      }}
                      className="imgUpload img_up "
                    />
                  </div>
                  <div
                    className="image_preview_container"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: -20,
                        fontSize: "12px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "120px",
                      }}
                    >
                      {selectedImageName ? selectedImageName : "*Sample Image"}
                    </div>
                    <div className="uploaded-img">
                      {selectedImage ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            display: `${selectedImage ? "block" : "none"}`,
                          }}
                          src={selectedImage && selectedImage}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              filter: logoUpload ? "grayscale(0.8)" : "",
                              opacity: logoUpload ? 0.8 : 1,
                              display: `${
                                defaultPartnerImg ? "block" : "none"
                              }`,
                            }}
                            src={defaultPartnerImg && defaultPartnerImg}
                          />
                          <div
                            style={{
                              borderRadius: "10px",
                              overflow: "hidden",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              backgroundColor: "transparent",
                              display: logoUpload ? "block" : "none",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              <ColorRing
                                visible={true}
                                height="100"
                                width="100"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={[
                                  "#fff",
                                  "#fff",
                                  "#fff",
                                  "#fff",
                                  "#fff",
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className={"text-danger"}></div>
          </div>
        </div>

        <div className="col-md-12 footer-formCZ">
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary cancel-btn"
              onClick={handleAddCancel}
            >
              {" "}
              Cancel
            </button>

            <SubmitButton
              className={"submit-btn"}
              text={isEditMode ? "Update" : "Add"}
              isLoading={isLoading}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
