/** @format */

import React, { Component } from 'react';
import whiteLogo from '../../../assets/img/logo-white.webp';

class UserDataDeletion extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="container container-fluid-invitaion1">
        <div className="row login-row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header center-header">
                <img className="center-logo" src={whiteLogo} alt="" />
              </div>
              <div className={'client-invitation-form'}>
                <h2 style={{ textAlign: 'center', marginTop: '15px' }}>
                  Steps to Delete the Mobile Application Users Accounts
                </h2>
                <p>
                  <b>Step 1. Open Mobile Application</b>
                </p>
                <p>
                  <b>Step 2. Log in to the Mobile Application</b>
                </p>
                <p>
                  <b>Step 3. Go to Policy Screen</b>
                </p>
                <p>
                  After logging in, at the home screen -&gt; click on the
                  profile icon presented at the header of the screen or from the
                  bottom navigation -&gt; Side menu will be open -&gt; Click on
                  Policy -&gt; Click on Privacy Policy -&gt; At the bottom of
                  the Privacy Policy screen copy the email address mentioned
                  under the contact us heading
                </p>
                <p>
                  <b>Step 4. Raise a request via email</b>
                </p>
                <p>
                  User needs to drop an email to the same email id which is
                  mentioned under the contact us heading in the privacy policy
                  link but the sender's email id must be the same as the email
                  id which is associated with the users account and the user
                  needs to mention the below details to raise a request to
                  delete their account:
                </p>
                <p>Email Id associated with the Mobile Application</p>
                <p>Phone Number associated with the Mobile Application</p>
                <p>Reason for Deleting the account</p>
                <p>
                  <b>Note: </b>After receiving the request from the users, the
                  admin will proceed with their request within 24 hours and the
                  user's account data will be deleted within 48hrs to 72hrs, and
                  all related data to the user will be deleted from our server.
                  And the user will get a confirmation email to the associated
                  email id once their data and account will be deleted from our
                  server.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDataDeletion;
