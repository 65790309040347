import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import { AppConfig } from "../../../config";
import { InputTextField, SubmitButton } from "../forms";
import uploadProductImage from "../../../assets/img/ph_product.jpg";
import calenderIcon from "../../../assets/img/calenderIcon.svg";
import "react-datepicker/dist/react-datepicker.css";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

export const BatchFormComponent = ({
  clientName,
  clientList,
  batchNumber,
  productId,
  originId,
  state,
  expiryDate,
  profile_picture,
  itemCount,
  productOptions,
  originOptions,
  stateOptions,
  isEditMode,
  errors,
  isAdding,
  onChange,
  onDateChange,
  onSelect,
  handleCancel,
  handleSubmit,
  loadProductOptions,
  loadOriginOptions,
  loadClientOptions,
  clientNameA,
}) => {
  return (
    <form className="product_add" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-3 mob-show">
          <div className="img-col-product-view">
            <img className="product-img-view" src={uploadProductImage} alt="" />
          </div>
        </div>

        <div className="col-md-9">
          <div className="row">
            <div className="col-md-4">
              <InputTextField
                id="batch_number"
                label={"Batch Number"}
                placeholder="Enter Batch Number"
                className={"form-control-product"}
                name="batchNumber"
                value={batchNumber}
                onChange={onChange}
                errorMessage={
                  errors && errors.batchNumber ? errors.batchNumber : ""
                }
                maxLength={15}
              />
            </div>
            {isEditMode ? (
              <div className="col-md-4">
                <InputTextField
                  id="clientName"
                  label={"Client"}
                  className={"form-control-user"}
                  name="clientName"
                  value={clientNameA}
                  // onChange={onChange}
                  disabled
                  maxLength={30}
                  // placeholder={"Will be autfilled"}
                />
              </div>
            ) : (
              <div className="col-md-4">
                <div className="form-group">
                  <label className={`form-control-label`}>Client</label>
                  <AsyncSelect
                    // disable={isEditMode}
                    loadOptions={loadClientOptions}
                    placeholder={"Select Client"}
                    maxMenuHeight={200}
                    styles={customStyles}
                    defaultOptions={clientList}
                    value={clientName}
                    onChange={(selectedOption) =>
                      onSelect(selectedOption, "clientName")
                    }
                    noOptionsMessage={() => "No Option"}
                  />
                  <div className={"text-danger"}>
                    {errors && errors.productId ? errors.productId : ""}
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>Product</label>
                <AsyncSelect
                  loadOptions={loadProductOptions}
                  placeholder={"Select Product"}
                  maxMenuHeight={200}
                  styles={customStyles}
                  defaultOptions={productOptions}
                  value={productId}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "productId")
                  }
                  noOptionsMessage={() =>
                    "No product found related to your search"
                  }
                  // isDisabled={isEditMode}
                />
                <div className={"text-danger"}>
                  {errors && errors.productId ? errors.productId : ""}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <InputTextField
                id="product_name"
                label={"Unit Count"}
                placeholder="Enter Unit Count"
                type={"text"}
                className={"form-control-product"}
                name="itemCount"
                value={itemCount}
                onChange={onChange}
                errorMessage={
                  errors && errors.itemCount ? errors.itemCount : ""
                }
              />
            </div>

            <div className="col-md-4">
              <div className="form-group datePicker">
                <label className={`form-control-label`}>Expiry Date</label>
                <DatePicker
                  className={"form-control-input"}
                  placeholderText={`DD/MM/YYYY`}
                  selected={expiryDate}
                  minDate={new Date()}
                  onChange={onDateChange}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <div className="icon">
                  <img src={calenderIcon} alt="" />
                </div>
                <div className={"text-danger"} style={{ height: "18px" }}>
                  {errors && errors.expiryDate ? errors.expiryDate : ""}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>Country</label>
                <AsyncSelect
                  loadOptions={loadOriginOptions}
                  placeholder={"Select Country"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={originOptions}
                  value={originId}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "originId")
                  }
                  noOptionsMessage={() =>
                    "No origin found related to your search"
                  }
                />
                <div className={"text-danger"}>
                  {errors && errors.originId ? errors.originId : ""}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>State</label>
                <Select
                  placeholder={"Select State"}
                  options={stateOptions}
                  styles={customStyles}
                  value={state}
                  maxMenuHeight={200}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "state")
                  }
                  noOptionsMessage={() => {
                    return originId && originId.value
                      ? "No states found related to your search"
                      : "First select an origin";
                  }}
                />
                <div className={"text-danger"}>
                  {errors && errors.state ? errors.state : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 mob-hide">
          <div className="img-col-product-view addbatchImage">
            {productId.productImage ? (
              <img
                className=""
                // src={
                //   productId
                //     ? `${AppConfig.FILES_ENDPOINT}${productId.productImage}`
                //     : ""
                // }
                src={
                  productId.productImage
                    ? productId.productImage.startsWith("https://")
                      ? productId.productImage
                      : `${AppConfig.FILES_ENDPOINT}${productId.productImage}`
                    : ""
                }
                alt=""
              />
            ) : (
              <p>Product image will appear here</p>
            )}
          </div>
        </div>

        <div className="col-md-12 footer-form">
          <button
            type="button"
            className="btn btn-primary cancel-btn"
            onClick={handleCancel}
          >
            {" "}
            CANCEL
          </button>

          <SubmitButton
            className={"submit-btn"}
            text={isEditMode ? "UPDATE" : "ADD"}
            isLoading={isAdding}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </form>
  );
};
