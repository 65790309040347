import React, { Component } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import Skeleton from "react-skeleton-loader";
import { ApiRoutes, AppRoutes, DB_ACCPTABLE_FORMAT } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { batchValidator } from "../../../validators";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { BatchFormComponent } from "../../components/batch";
import { BatchInputLoader } from "../../components/loader/Resusable/BatchInput";
import { message } from "../../../common";
import "./batches.css";

class AddBatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batchNumber: "",
      productId: "",
      originId: "",
      state: "",
      expiryDate: null,
      itemCount: "",
      batchId: "",
      productOptions: [],
      originOptions: [],
      stateOptions: [],
      clientList: [],
      clientName: { label: "PHL", value: "PHL" },
      errors: null,
      isAdding: false,
      clientId: "",
      clientNameA: "PHL",
    };
  }

  componentDidMount = () => {
    this.fetchProductsOption();
    this.fetchOriginsOption();
    this.fetchClientList();
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ batchId: id }, () => this.viewBatch());
      this.fetchClientList();
    }
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      let { data } = response.data;
      data = response.data.data.filter((item) => {
        return item.label.trim() !== "";
      });
      let result = data.find((item) => item.value === this.state.clientId);
      this.state.clientId
        ? this.setState({
            clientName: result,
          })
        : this.setState({
            clientList: [
              // {
              //   label: "All",
              //   value: "all",
              // },
              {
                label: "PHL",
                value: "PHL",
              },
              ...data,
            ],
          });

      if (this.state.clientId) {
        let result = await data.find(
          (item) => item.value === this.state.clientId
        );
        this.fetchProductsOption(this.state.clientId);
        this.setState({
          clientNameA: result?.label ?? "PHL",
        });
      }
    }
  };
  fetchProductsOption = async (option) => {
    const { clientName } = this.state;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PRODUCTS_OPTION_LIST.service,
      ApiRoutes.PRODUCTS_OPTION_LIST.url,
      ApiRoutes.PRODUCTS_OPTION_LIST.method,
      ApiRoutes.PRODUCTS_OPTION_LIST.authenticate,
      { clientName: option ?? clientName.value },
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      this.setState({
        productOptions: data,
      });
      return data;
    }
  };
  loadProductOptions = async (inputValue, callback) => {
    // new ApiHelper().cancelRequest("cancel");
    // const result = await this.fetchProductsOption(inputValue);
    // callback(result);

    const result = this.state.productOptions.filter((p) =>
      p.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(result);
  };
  loadClientOptions = async (inputValue, callback) => {
    //new ApiHelper().cancelRequest("cancel");
    //const result = await this.fetchProductsOption(inputValue);
    const result = this.state.clientList.filter((p) =>
      p.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(result);
  };
  fetchOriginsOption = async (search = "") => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ORIGINS_OPTION_LIST.service,
      ApiRoutes.ORIGINS_OPTION_LIST.url,
      ApiRoutes.ORIGINS_OPTION_LIST.method,
      ApiRoutes.ORIGINS_OPTION_LIST.authenticate,
      { search },
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      this.setState({
        originOptions: data,
      });
      return data;
    }
  };
  loadOriginOptions = async (inputValue, callback) => {
    new ApiHelper().cancelRequest("cancel");
    const result = await this.fetchOriginsOption(inputValue);
    callback(result);
  };
  onSelect = (options, name) => {
    this.setState({
      [name]: options,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
    if (name == "clientName") {
      this.setState({
        productOptions: [],
        productId: "",
      });
      this.fetchProductsOption(options.value);
    }

    // TO reset states on country selection
    if (name === "originId") {
      this.setState({
        stateOptions: options.states.map((state) => ({
          label: state,
          value: state,
        })),
        state: "",
        errors: {
          ...this.state.errors,
          state: "",
          originId: "",
        },
      });
    }
  };
  viewBatch = async () => {
    const { batchId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_BATCH.service,
      ApiRoutes.VIEW_BATCH.url.replace(":id", batchId),
      ApiRoutes.VIEW_BATCH.method,
      ApiRoutes.VIEW_BATCH.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { batch },
      } = response.data;
      const {
        batchNumber,
        state,
        itemCount,
        productId,
        originId,
        expiryDate,
        createdBy,
      } = batch;
      // if (
      //   productId &&
      //   !productOptions.filter(product => product.value === productId.id).length
      // ) {
      //   this.setState(prevState => ({
      //     productOptions: [
      //       ...prevState.productOptions,
      //       { label: productId.productName, value: productId.id },
      //     ],
      //   }));
      // }
      // if (
      //   originId &&
      //   !originOptions.filter(origin => origin.value === originId.id).length
      // ) {
      //   this.setState(prevState => ({
      //     originOptions: [
      //       ...prevState.originOptions,
      //       { label: originId.originName, value: originId.id },
      //     ],
      //   }));
      // }
      this.setState({
        batchNumber,
        state: state ? { label: state, value: state } : null,
        itemCount,
        productId: {
          label: productId.productName,
          value: productId.id,
          productImage: productId.productImage,
        },
        originId: originId
          ? {
              label: originId.originName,
              value: originId.id,
              states: originId.states,
            }
          : null,
        stateOptions: originId
          ? originId.states.map((state) => ({
              label: state,
              value: state,
            }))
          : [],
        expiryDate: expiryDate ? new Date(expiryDate) : null,
        clientId: createdBy,
      });
    }
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "itemCount" && isNaN(value)) {
      return;
    }
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
  };
  handleDateChange = (date) => {
    this.setState({
      expiryDate: date,
      errors: {
        ...this.state.errors,
        expiryDate: "",
      },
    });
  };

  addBatch = async (payload) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_BATCH.service,
      ApiRoutes.ADD_BATCH.url,
      ApiRoutes.ADD_BATCH.method,
      ApiRoutes.ADD_BATCH.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success("Batch has been added successfully.");
      this.props.history.push(AppRoutes.MANAGE_BATCH);
    }
  };
  updateBatch = async (payload) => {
    const { batchId } = this.state;
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_BATCH.service,
      ApiRoutes.UPDATE_BATCH.url.replace(":id", batchId),
      ApiRoutes.UPDATE_BATCH.method,
      ApiRoutes.UPDATE_BATCH.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.MANAGE_BATCH);
    }
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      batchNumber,
      productId,
      originId,
      state,
      expiryDate,
      itemCount,
      batchId,
      stateOptions,
    } = this.state;
    let payload = {
      batchNumber: batchNumber.trim(),
      productId: productId && productId.value ? productId.value : "",
      originId: originId && originId.value ? originId.value : "",
      // state: state && state.value ? state.value : '',
      expiryDate: expiryDate
        ? moment(expiryDate).format(DB_ACCPTABLE_FORMAT)
        : "",
      itemCount: itemCount ? parseInt(itemCount) : "",
      batchId,
    };
    // To made states required only if selected origins have states
    if ((stateOptions && stateOptions.length) || !payload.originId) {
      payload = {
        ...payload,
        state: state && state.value ? state.value : "",
      };
    }
    let { isValid, errors } = batchValidator(payload);
    // To validate item count
    if (itemCount.toString().length > 10) {
      isValid = false;
      errors = {
        ...errors,
        itemCount: message.InvalidItemCountNumber,
      };
    }
    if (parseInt(itemCount) === 0) {
      isValid = false;
      errors = {
        ...errors,
        itemCount: "Item count must be greater than 0",
      };
    }
    if (!Number.isInteger(Number(itemCount))) {
      isValid = false;
      errors = {
        ...errors,
        itemCount: message.InvalidItemCount,
      };
    }
    if (isValid) {
      payload = {
        ...payload,
        state: state && state.value ? state.value : "",
      };
      if (batchId) {
        this.updateBatch(payload);
      } else {
        this.addBatch(payload);
      }
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };

  handleCancel = () => {
    this.props.history.push(AppRoutes.MANAGE_BATCH);
  };

  customBreadCrumb = () => {
    const { isLoading, batchNumber } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      batchNumber
    );
  };
  render() {
    const {
      clientList,
      clientName,
      batchNumber,
      productId,
      originId,
      state,
      expiryDate,
      itemCount,
      productOptions,
      originOptions,
      stateOptions,
      batchId,
      errors,
      isAdding,
      isLoading,
      clientNameA,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">
          {productId ? `Edit batch` : "Add New Batch"}
        </h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
        />
        <div className="card mb-12 card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book ">
                  {batchId ? (
                    isLoading ? (
                      <Skeleton color="#e5e9ef" height={"25px"} />
                    ) : (
                      batchNumber
                    )
                  ) : (
                    "Add Batch"
                  )}
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            {isLoading && batchId ? (
              <BatchInputLoader />
            ) : (
              <BatchFormComponent
                batchNumber={batchNumber}
                clientList={clientList}
                clientName={clientName}
                productId={productId}
                originId={originId}
                state={state}
                expiryDate={expiryDate}
                itemCount={itemCount}
                productOptions={productOptions}
                originOptions={originOptions}
                stateOptions={stateOptions}
                isEditMode={batchId}
                errors={errors}
                isAdding={isAdding}
                onChange={this.handleChange}
                onDateChange={this.handleDateChange}
                onSelect={this.onSelect}
                handleSubmit={this.handleSubmit}
                handleCancel={this.handleCancel}
                loadProductOptions={this.loadProductOptions}
                loadOriginOptions={this.loadOriginOptions}
                loadClientOptions={this.loadClientOptions}
                clientNameA={clientNameA}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AddBatch;
