import React from "react";
import { TableLoader } from "../../components/loader/Resusable/Table";
import { SubmitButton } from "../forms";
import VideoRow from "./VideoRow";
import noData from "../../../assets/img/no-data.png";

export const ListVideoComponent = ({
  videos,
  name,
  link,
  isPublished,
  errors,
  isFormOpen,
  isAdding,
  isLoading,
  onChange,
  onSubmit,
  onCancel,
  deleteVideo,
  onEdit,
  handleAdd,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-sm">
        {isLoading ? (
          <TableLoader columns={4} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th>Name</th>
                <th>Link</th>
                <th>Uploaded on</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isFormOpen ? (
                <tr className="form-row-table">
                  <td>
                    <input
                      type="text"
                      className={`form-control form-name ${
                        errors &&
                        (errors.name || errors.videoFile || errors.link)
                          ? "has-error"
                          : ""
                      }`}
                      placeholder="Enter Video Name"
                      name="name"
                      value={name}
                      onChange={handleAdd}
                      maxLength={30}
                    />
                    {errors &&
                    (errors.name || errors.videoFile || errors.link) ? (
                      <div className={"text-danger error-text-video"}>
                        {errors.name ? errors.name : ""}
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="Enter Video Link"
                      className={`form-control form-link ${
                        errors &&
                        (errors.name || errors.videoFile || errors.link)
                          ? "has-error"
                          : ""
                      }`}
                      name="link"
                      value={link}
                      onChange={handleAdd}
                    />
                    {errors &&
                    (errors.name || errors.videoFile || errors.link) ? (
                      <div className={"text-danger error-text-video"}>
                        {errors && (errors.videoFile || errors.link)
                          ? errors.videoFile || errors.link
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td></td>
                  <td>
                    <button
                      className="btn btn-secondary can-btn-table"
                      onClick={onCancel}
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                    <SubmitButton
                      className={"save-btn-table add"}
                      text={"Save"}
                      isLoading={isAdding}
                      onSubmit={onSubmit}
                    />
                  </td>
                </tr>
              ) : null}
              {videos && videos.length ? (
                videos.map((e) => (
                  <VideoRow
                    videodata={e}
                    key={e.id}
                    deleteVideo={() => deleteVideo(e.id)}
                    editVideo={() => onEdit(e)}
                    isPublished={e.isPublished}
                    onChange={onChange}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={4} className={"text-center image-no-data-sm"}>
                    <img src={noData} alt="" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
