import Validator from "js-object-validation";
import { message } from "../common";

export const addClientValidator = (data) => {
  const validations = {
    name: {
      required: true,
      // alpha: true,
    },
    // last_name: {
    //   required: true,
    //   // alpha: true,
    // },
    email: {
      required: true,
      email: true,
    },
    companyName: {
      required: true,
    },
    // storeName: {
    //   required: true,
    // },
    // brandName: {
    //   required: true,
    // },
    // businessRegCertificateNo: {
    //   required: true,
    // },
    // address1: {
    //   required: true,
    // },
  };
  // Error messages
  const messages = {
    name: {
      required: message.Required.replace(":item", "first name"),
      alpha: message.InvalidName.replace(":item", "Name"),
    },
    last_name: {
      required: message.Required.replace(":item", "last name"),
    },
    email: {
      required: message.Required.replace(":item", "Email"),
      email: message.InvalidEmail,
    },
    companyName: {
      required: message.Required.replace(":item", "Company name"),
    },
    // storeName: {
    //   required: message.Required.replace(":item", "Store name"),
    // },
    // brandName: {
    //   required: message.Required.replace(":item", "Brand name"),
    // },
    // businessRegCertificateNo: {
    //   required: message.Required.replace(":item", "Business Reg. Certificate"),
    // },
    // address1: {
    //   required: message.Required.replace(":item", "Address Line 1"),
    // },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
