import React, { useState, useEffect } from "react";
import { InputTextField, SubmitButton } from "../forms";
import { faqsValidator } from "../../../validators";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes, AppRoutes } from "../../../config";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import Faqs from "../../containers/faqs";

export const AddFormComponent = ({
  faq,
  handleAddCancel,
  allOptions,
  userDetail,
  faqData,
}) => {
  const [InitialpriorityArray, setInitialPriorityArray] = useState([]);

  useEffect(() => {
    // Create a new array with values from 1 to 50
    const initialPriorityArrayData = Array(50)
      .fill()
      .map((_, index) => index + 1);

    // Update the array based on faqData
    if (faqData?.length) {
      faqData.forEach((faqs) => {
        const index = initialPriorityArrayData.indexOf(faqs.priority);
        if (index > -1) {
          if (faqs.priority !== faq?.priority) {
            initialPriorityArrayData.splice(index, 1);
          }
        }
      });
    }

    // Set the state with the updated array
    setInitialPriorityArray(initialPriorityArrayData);
  }, [faqData, faq?.priority]);

  const [priority, setPriority] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [categoryId, setCatId] = useState();
  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "priority") {
      setPriority(value.trimLeft());
    }
    if (name === "question") {
      setQuestion(value.trimLeft());
    }
    if (name === "answer") {
      setAnswer(value.trimLeft());
    }
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    let payload = {};
    if (userDetail === "App") {
      payload = {
        priority: priority,
        question: question,
        answer: answer,
        users: userDetail,
      };
    } else {
      payload = {
        priority: priority,
        question: question,
        answer: answer,
        ...(categoryId?.value && { categoryId: categoryId.value }),
        users: userDetail,
      };
    }
    const { isValid, errors } = faqsValidator(payload);

    let filterData = "";
    if (userDetail !== "App" && userDetail !== "Web") {
      filterData = faqData?.filter(
        (x) =>
          parseInt(x.priority) === parseInt(priority) &&
          x?.categoryName === categoryId?.label
      );
    }
    if (isValid) {
      setErrors({});
      if (filterData.length === 0) {
        if (faq._id) {
          if (userDetail == "Web") {
            updateEntry({ id: faq?._id, ...payload });
          } else {
            updateEntry({ ...payload });
          }
        } else {
          addEntry({ ...payload });
        }
      } else {
        setIsLoading(false);
        toast.error("Priority already exist under this category");
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
      return;
    }
  };

  const addEntry = async (payload) => {
    let response = undefined;
    if (userDetail == "Web") {
      response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.ADD_FAQ_NEW.service,
        ApiRoutes.ADD_FAQ_NEW.url,
        ApiRoutes.ADD_FAQ_NEW.method,
        ApiRoutes.ADD_FAQ_NEW.authenticate,
        undefined,
        payload
      );
    } else {
      response = await new ApiHelper().FetchFromServer(
        ApiRoutes.ADD_FAQ.service,
        ApiRoutes.ADD_FAQ.url,
        ApiRoutes.ADD_FAQ.method,
        ApiRoutes.ADD_FAQ.authenticate,
        undefined,
        payload
      );
    }

    if (response && response.isError) {
      setIsLoading(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  const updateEntry = async (payload) => {
    let response = undefined;
    if (userDetail == "Web") {
      response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.UPDATE_FAQ_NEW.service,
        ApiRoutes.UPDATE_FAQ_NEW.url.replace(":id", faq._id),
        ApiRoutes.UPDATE_FAQ_NEW.method,
        ApiRoutes.UPDATE_FAQ_NEW.authenticate,
        undefined,
        payload
      );
    } else {
      response = await new ApiHelper().FetchFromServer(
        ApiRoutes.UPDATE_FAQ.service,
        ApiRoutes.UPDATE_FAQ.url.replace(":id", faq._id),
        ApiRoutes.UPDATE_FAQ.method,
        ApiRoutes.UPDATE_FAQ.authenticate,
        undefined,
        payload
      );
    }

    if (response && response.isError) {
      setIsLoading(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  useEffect(() => {
    if (faq._id) {
      setIsEditMode(true);
      setPriority(faq.priority);
      setQuestion(faq.question);
      setAnswer(faq.answer);
      setCatId({ label: faq.categoryName, value: faq.categoryId });
    } else {
      setIsEditMode(false);
    }
  }, []);

  const onSelect = (label) => {
    if (label) {
      setCatId(label);
    }
  };
  const onSelectPrioprity = (label) => {
    setPriority(label);
  };
  return (
    <form className="product_add proAdd">
      <div className="row">
        <div className="row">
          <div className="col-md-12">
            <label className="faqbottomclass">
              Please enter the FAQ's to be listed in the Client Website
            </label>
          </div>
          <div className="col-md-12 right-arrow-fix">
            {userDetail !== "Web" ? (
              <InputTextField
                type="number"
                id="priority"
                label={"Priority"}
                className={"form-control-product"}
                name="priority"
                value={priority}
                onChange={onChange}
                min="1"
                errorMessage={errors && errors.priority ? errors.priority : ""}
              />
            ) : (
              <div className="form-group">
                <label className={`form-control-label_About`}>Priority</label>
                <select
                  className="form-control form-control-input"
                  value={priority}
                  style={{ color: !priority ? "#8894A8" : "" }}
                  onChange={(e) => onSelectPrioprity(e.target.value)}
                >
                  <option
                    value={""}
                    disabled={true}
                    style={{ color: "#8894A8" }}
                  >
                    Please select priority
                  </option>
                  {InitialpriorityArray.length &&
                    InitialpriorityArray?.map((priorities) => {
                      return <option value={priorities}>{priorities}</option>;
                    })}
                </select>

                <div className={"text-danger"}>
                  {errors && errors.priority ? errors.priority : ""}
                </div>
              </div>
            )}
          </div>
          {userDetail !== "App" && userDetail !== "Web" && (
            <div className="col-md-12">
              <div className="form-group">
                <label className={`form-control-label_About`}>Category</label>
                <AsyncSelect
                  // loadOptions={loadOriginOptions}
                  placeholder={"Select"}
                  // styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={allOptions}
                  value={categoryId}
                  onChange={(selectedOption) => onSelect(selectedOption)}
                  noOptionsMessage={() =>
                    "No origin found related to your search"
                  }
                />

                <div className={"text-danger"}>
                  {errors && errors.categoryId ? errors.categoryId : ""}
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12" style={{ paddingTop: "25px" }}>
            <label className={`form-control-label_About`}>Question</label>
            <InputTextField
              placeholder="Enter Question Here"
              id="question"
              // label={"Question"}
              className={"form-control-product"}
              name="question"
              value={question}
              onChange={onChange}
              errorMessage={errors && errors.question ? errors.question : ""}
            />
          </div>
          <div className="col-md-12" style={{ paddingTop: "25px" }}>
            <label
              htmlFor="product_description"
              className="form-control-label_Action"
            >
              Answer
            </label>
            <textarea
              placeholder="Enter Answer of the Question Here"
              className="form-control form-control-textarea wt-resize"
              cols="50"
              id="answer"
              aria-describedby="category_nameHelp"
              rows="8"
              name={"answer"}
              value={answer}
              onChange={onChange}
            />
            <div className={"text-danger"}>
              {errors && errors.answer ? errors.answer : ""}
            </div>
          </div>
        </div>

        <div className="col-md-12 footer-formCZ">
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary cancel-btn"
              onClick={handleAddCancel}
            >
              {" "}
              Cancel
            </button>

            <SubmitButton
              className={"submit-btn"}
              text={isEditMode ? "Update" : "Add"}
              isLoading={isLoading}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
