import React, { Component } from 'react';
import { Button, Dropdown, Pagination } from 'react-bootstrap';
import { FilterComponent } from '../../components/filters';
import { SearchFilter } from '../../components/filters/searchFilter';
import { ListComponent } from '../../components/pushNotifications';
import filterarrowIcon from '../../../assets/img/filter-arrow.svg';

function TestOne({
    pushs,
    isFilterApplied,
    isLoading,
    totalRecords,
    limit,
    currentPage,
    order,
    sortBy,
    search,
}) {


    const filtersOption = [
        {
          label: 'Question',
          value: 'question',
        },
        {
          label: 'Answer',
          value: 'answer',
        },
        {
          label: 'Priority',
          value: 'priority',
        }
    ];

    const pagesOption = [
        {
          label: '10 Per page',
          value: 10,
        },
        {
          label: '20 Per page',
          value: 20,
        },
        {
          label: '50 Per page',
          value: 50,
        },
      ];
  
    // const handleChange = event => {
    //     const {
    //       target: { name, value },
    //     } = event;
    //     this.setState({ [name]: value });
    //     event.persist();
    //     this.emitSearch();
    //   };

    return (
        <>
            <div className='container-fluid fluid-pd-2'>
                <h1 className='h3-head-mob d-md-none'>FAQs</h1>
                
                <div className='card card-pagination card-main'>
                    <div className='card-header py-7550 align-items-center justify-content-between'>
                        <div className='row'>
                            <div className='col-md-3 head-col-lg'>
                                <h6 className='font-book '></h6>
                            </div>
                            <div className='col-md-9 filter-col-lg'>
                                <div className="no-arrow dp-right dropdown">
                                <Button className="pushButtonCZ"  >
                                    Add
                                </Button>
                                </div>
                                <FilterComponent
                                options={filtersOption}
                                selectedFilter={sortBy}
                                // onFilterChange={this.setFilter}
                                />
                                <SearchFilter
                                placeholder={'Search by Question and Answer'}
                                search={search}
                                // onChange={handleChange}
                                />
                            </div>
                        </div>
                        </div>
                        <ListComponent
                            pushs={pushs}
                            isFilterApplied={isFilterApplied}
                            isLoading={isLoading}
                            totalRecords={totalRecords}
                            limit={limit}
                            currentPage={currentPage}
                            // onPageChanged={this.onPageChanged}
                            // setFilter={this.setFilter}
                            order={order}
                            sortBy={sortBy}
                            // onEdit={this.onEdit}
                            // onDelete={this.onDelete}
                        />
                </div>


                <div className='footer-pg'>
                {!isLoading ? (
                    <div className='float-left mt-2'>
                    <p className='page-desc-left'>
                        Total: <span className='bold-black'>{totalRecords}</span>
                    </p>
                    </div>
                ) : null}
                <div className='float-right mt-2'>
                    <p className='page-desc ml-2 float-right'>Table View</p>
                    
                    <Dropdown className='no-arrow dp-right ml-2'>
                    <Dropdown.Toggle
                        variant='secondary'
                        id='dropdown-basic'
                        className={'filter-btn'}
                    >
                        {' '}
                        <span className='staff-value'>
                        {
                            (
                            pagesOption.filter(filter => filter.value === limit)[0] ||
                            {}
                            ).label
                        }
                        </span>{' '}
                        <img src={filterarrowIcon} alt='' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='shadow top-open dropdown-menu-right dropdown-staff'>
                        {pagesOption.map(({ label, value }, index) => (
                        <Dropdown.Item
                            as='div'
                            key={index}
                            onClick={() => this.onLimitChange(value)}
                        >
                            {label}
                        </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                    </Dropdown>
                    {!isLoading && totalRecords > limit ? (
                    <div className='float-right'>
                        <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                        />
                    </div>
                    ) : null}
                </div>
                </div>


            </div> 
            
        </>
    );
}

export default TestOne;