import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { SubmitButton } from "../../components/forms";
//import { userValidator } from '../../../validators';
import "./manage-privacy.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { TermLoader } from "../../components/loader/Cms/TermLoader";

class ManagePrivacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      loading: false,
      errors: null,
      userDetail: "",
      pageLoader: false,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        userDetail: this.props.location.pathname.split("/")[2], //[3],
      },
      () => this.handleQueryParams()
    );

    this.handleQueryParams();
  };
  handleQueryParams = async () => {
    const { userDetail } = this.state;
    this.fetchDetails("PrivacyAndPolicy", userDetail);
  };

  componentDidUpdate = ({ location: { search: prevSearch } }, prevState) => {
    // const splitValue = this.props.location.pathname
    //   .split("/")[3]
    //   ?.toLowerCase();
    const splitValue = this.props.location.pathname
      .split("/")[2]
      ?.toLowerCase();
    if (prevState.userDetail !== splitValue) {
      this.setState(
        {
          userDetail: splitValue,
        },
        () => this.handleQueryParams()
      );
    }
  };
  fetchDetails = async (key, type) => {
    const { userDetail } = this.state;
    this.setState({
      pageLoader: true,
    });
    let response = undefined;
    if (type && type?.toLocaleLowerCase() === "web") {
      response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.GET_PRIVACY_POLICY_NEW.service,
        ApiRoutes.GET_PRIVACY_POLICY_NEW.url,
        ApiRoutes.GET_PRIVACY_POLICY_NEW.method,
        ApiRoutes.GET_PRIVACY_POLICY_NEW.authenticate,
        undefined,
        undefined,
        undefined
      );
    } else if (type && type?.toLocaleLowerCase() !== "web") {
      response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_SETTINGS.service,
        ApiRoutes.GET_SETTINGS.url.replace(":id", userDetail + key),
        ApiRoutes.GET_SETTINGS.method,
        ApiRoutes.GET_SETTINGS.authenticate,
        undefined,
        undefined
      );
    }
    this.setState({
      pageLoader: false,
    });
    if (response && response?.isError) {
    } else {
      if (type && type?.toLocaleLowerCase() !== "web") {
        if (response?.data?.data?.product?.length) {
          this.handleChange(
            decodeURIComponent(
              escape(window.atob(response?.data?.data?.product[0]?.value))
            )
          );
        } else {
        }
      } else if (type && type?.toLocaleLowerCase() === "web") {
        if (response?.data?.data?.[0]) {
          this.handleChange(
            decodeURIComponent(
              escape(window.atob(response?.data?.data?.[0]?.value))
            )
          );
          // this.handleChange(response?.data?.data?.[0]?.value);
        }
      }
    }
  };

  saveDetails = async (payload, type) => {
    if (this.state?.userDetail?.toLocaleLowerCase() !== "web") {
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.SAVE_SETTINGS.service,
        ApiRoutes.SAVE_SETTINGS.url,
        ApiRoutes.SAVE_SETTINGS.method,
        ApiRoutes.SAVE_SETTINGS.authenticate,
        undefined,
        payload
      );

      if (response && response?.isError) {
        toast.error(response?.messages[0]);
        this.setState({
          errors: response?.validationErrors,
        });
      } else {
        // toast.success(response.messages[0]);
        this.fetchDetails("", type);
      }
    } else {
      const response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.UPDATE_PRIVACY_POLICY_NEW.service,
        ApiRoutes.UPDATE_PRIVACY_POLICY_NEW.url,
        ApiRoutes.UPDATE_PRIVACY_POLICY_NEW.method,
        ApiRoutes.UPDATE_PRIVACY_POLICY_NEW.authenticate,
        undefined,
        payload,
        undefined
      );

      if (response && response?.isError) {
        toast.error(response?.messages[0]);
        this.setState({
          errors: response?.validationErrors,
        });
      } else {
        // toast.success(response.messages[0]);
        this.fetchDetails("", type);
      }
    }
  };

  handleChange = (value) => {
    this.setState({
      content: value,
      errors: "",
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { userDetail } = this.state;

    if (this.state.content === "" || this.state.content === "<p><br></p>") {
      this.setState({
        errors: "Privacy Policy can't be empty",
      });
      return;
    }
    this.setState({
      loading: true,
    });
    await this.saveDetails(
      {
        key: userDetail + "PrivacyAndPolicy",
        value: window.btoa(unescape(encodeURIComponent(this.state.content))),
        // value: window.btoa(this.state.content),
      },
      userDetail
    );

    this.setState({
      loading: false,
    });

    toast.success("Privacy saved Successfully.");
    this.fetchDetails("PrivacyAndPolicy");
  };

  handleCancel = () => {
    const { userDetail } = this.state;
    this.fetchDetails("PrivacyAndPolicy", userDetail);
  };
  render() {
    const { pageLoader } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2 textareaPrivacyTerms">
        <h1 className="h3-head-mob d-md-none">Privacy Policy</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card mb-4 card-main">
          {pageLoader ? (
            <TermLoader />
          ) : (
            <>
              <div className="card-header  py-7550  align-items-center justify-content-between">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="font-book ">
                      Please enter the Privacy Policy applicable to your site
                      below
                    </h6>
                  </div>
                </div>
              </div>
              <div className="card-body card-table">
                <form className="" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <ReactQuill
                        value={this.state.content}
                        onChange={this.handleChange}
                        placeholder="Type something here..."
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            ["bold", "italic", "underline"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ align: [] }],
                            ["link", "image"],
                            ["clean"],
                            [{ color: [] }],
                          ],
                        }}
                      />
                      <div className={"text-danger"}>
                        {this.state.errors || ""}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 footer-form">
                    <button
                      type="button"
                      className="btn btn-primary cancel-btn"
                      onClick={this.handleCancel}
                      style={{ marginRight: "30px" }}
                    >
                      {" "}
                      CANCEL
                    </button>
                    <SubmitButton
                      className={"submit-btn mr-0"}
                      text="ADD"
                      isLoading={this.state.loading}
                      onSubmit={this.handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ManagePrivacy;
