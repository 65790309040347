import React from "react";
import BatchRow from "./BatchRow";
import { TableLoader } from "../../components/loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";

export const ListComponent = ({
  batches,
  isFilterApplied,
  isLoading,
  onDelete,
  onPrint,
  onView,
  setFilter,
  sortBy,
  order,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-sm">
        {isLoading ? (
          <TableLoader columns={5} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                {[
                  { label: "Batch Number", value: "batchNumber" },
                  { label: "Product Number", value: "productNumber" },
                  { label: "Product Name", value: "productName" },
                  { label: "Available Units", value: "availableUnits" },
                ].map(({ label, value }) => (
                  <th key={value}>
                    {label}{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === value ? "active-ac" : ""
                        }`}
                        onClick={() => setFilter(value, "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === value
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter(value, "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>
                ))}

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {batches && batches.length ? (
                batches.map((batch) => (
                  <BatchRow
                    batchData={batch}
                    key={batch._id}
                    onDelete={() => onDelete(batch._id, batch)}
                    onView={() => onView(batch._id)}
                    onPrint={() => onPrint(batch._id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={8} className={"text-center image-no-data-sm"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
