import React from "react";
import { Col, Spinner } from "react-bootstrap";
import "./Loader.css";

const Loader = () => {
  return (
    <Col sm={12} className={"text-center loader-block"}>
      <Spinner animation='border' variant="primary"/>
    </Col>
  );
};

export default Loader;
