import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../../assets/img/edit-3.svg";
import editIconWhite from "../../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../../assets/img/trash-2-white.svg";
import Switch from "react-switch";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import listIcon from "../../../../assets/img/list.svg";
import reopen from "../../../../assets/img/reopen.svg";
import { ReactComponent as Reopen } from "../../../../assets/img/corner-up-right_new.svg";
import contact from "../../../../assets/img/contact.svg";
import minusCircleRightIcon from "../../../../assets/img/minus-circle-light.svg";
import SweetAlertComponent from "../../../components/sweetAlertComponent";

export const JobTitleRow = ({
  IndividualCareersDetail,
  onEdit,
  onDelete,
  fetchCareersRecords,
  viewUser,
  handleUpdateStatus,
}) => {
  const [careersEdit, setCareersEdit] = useState(IndividualCareersDetail);
  // const careersEdit = IndividualCareersDetail;

  return (
    <tr>
      <td>
        <div className="icon_faqsBox" style={{ paddingLeft: "28px" }}>
          {careersEdit?.jobId}
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
            textTransform: "capitalize",
          }}
        >
          <div>{careersEdit?.jobTitle}</div>
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          {careersEdit?.jobType}
        </div>
      </td>

      <td>{careersEdit?.jobCategory}</td>
      <td className="switchBox">
        <div
          className="d-flex align-items-start justify-content-start"
          style={{
            width: "100%",
          }}
        >
          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={onEdit}
          >
            <span>
              <img src={editIcon} alt="" className={"img-main"} />
              <img src={editIconWhite} alt="" className={"img-hover"} />
            </span>
            Edit
          </Button>
          <Button
            variant="danger"
            className="btn th-btn delete-tb action-block-btn"
            onClick={onDelete}
          >
            <span>
              <img src={trashIcon} alt="" className={"img-main"} />
              <img src={trashIconWhite} alt="" className={"img-hover"} />
            </span>
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};
