import React from "react";
import Skeleton from "react-skeleton-loader";

export const BatchViewLoader = () => (
  <>
    {" "}

    <div className='card-header py-75  align-items-center justify-content-between'>

        
            <div className='row'>
              <div className='col-md-3 head-col-lg'>
                <h6 className='font-book '> <Skeleton color ="#e5e9ef" height={"25px"}/></h6>
              </div>
              <div className='col-md-9 filter-col-lg'>
              <div className='dp-right mr-2'>
                  
                  <Skeleton color ="#e5e9ef" height={"25px"}/>
                
              </div>
              <div className='dp-right mr-2'>
                  
                  <Skeleton color ="#e5e9ef" height={"25px"}/>
                
              </div>
              <div className='dp-right mr-2'>
                  
                  <Skeleton color ="#e5e9ef" height={"25px"}/>
                
              </div>
              </div>
            </div>
          
        </div>

        <div className='card-body card-table'>

    <div className='row'>
              <div className='col-md-9'>
                <div className='row mtblf-5'>
                <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                </div>
                <div className='row mtblf-5'>
                <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                </div>
                <div className='row mtblf-5'>
                <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='img-col-product-view'>
                <Skeleton color ="#e5e9ef" height={"200px"} width={"100%"}/>
                </div>
              </div>
            </div>
            </div>


    
  </>
);
