import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { InputTextField, SubmitButton } from "../../components/forms";
import { contactValidator } from "../../../validators";
import { pushValidator } from "../../../validators";

//import { userValidator } from '../../../validators';
import "./manage-contact.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";

class ManageContactClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      address: "",
      loading: false,
      errors: null,
    };
  }

  componentDidMount = () => {
    this.fetchDetails("email");
    this.fetchDetails("phone");
    this.fetchDetails("address");
  };

  fetchDetails = async (key) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SETTINGS.service,
      ApiRoutes.GET_SETTINGS.url.replace(":id", "contact_" + key),
      ApiRoutes.GET_SETTINGS.method,
      ApiRoutes.GET_SETTINGS.authenticate,
      undefined,
      undefined
    );

    if (response && response.isError) {
    } else {
      if (response.data.data.product.length) {
        console.log("hello");
        this.setState({
          [key]: response.data.data.product[0].value,
        });
      }
    }
  };

  saveDetails = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SAVE_SETTINGS.service,
      ApiRoutes.SAVE_SETTINGS.url,
      ApiRoutes.SAVE_SETTINGS.method,
      ApiRoutes.SAVE_SETTINGS.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
    }
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
  };

  validateEmail = async (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  validatePhone = async (phone) => {
    var re = /^.{8,12}$/;
    return re.test(phone);
  };

  handleSubmit = async (event) => {
    this.setState({
      loading: true,
    });
    event.preventDefault();

    let payload = {
      email: this.state.email.trim(),
      phone: this.state.phone.trim(),
      address: this.state.address.trim(),
    };

    let { isValid, errors } = contactValidator(payload);

    const emails = this.state.email.split(",");
    for (let email of emails) {
      let validemail = await this.validateEmail(email);
      if (!validemail) {
        isValid = false;
        errors.email = "Invalid Email Address";
      }
    }

    const phones = this.state.phone.split(",");
    for (let phone of phones) {
      let validphone = !isNaN(phone);
      validphone = await this.validatePhone(phone);
      console.log(validphone);
      if (!validphone || phone === "") {
        isValid = false;
        errors.phone = "Invalid Phone Number";
      }
    }

    if (isValid) {
      await this.saveDetails({
        key: "contact_email",
        value: this.state.email,
      });
      await this.saveDetails({
        key: "contact_phone",
        value: this.state.phone,
      });
      await this.saveDetails({
        key: "contact_address",
        value: this.state.address,
      });
      this.setState({
        loading: false,
      });

      toast.success("Contact Information saved Successfully.");
    } else {
      this.setState({
        loading: false,
        errors: errors,
      });

      return;
    }

    console.log(this.state);
  };

  render() {
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2 mainContactSet">
        <h1 className="h3-head-mob d-md-none">Contact</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card mb-4 card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book ">
                  Please enter the contact details below and use comma (,) if
                  there is more then one value
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            <form className="" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <InputTextField
                    id="email"
                    label={"Email"}
                    className={"form-control-product"}
                    name="email"
                    onChange={this.handleChange}
                    disabled={false}
                    value={this.state.email}
                    errorMessage={
                      this.state.errors && this.state.errors.email
                        ? this.state.errors.email
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputTextField
                    id="phone"
                    label={"Phone"}
                    className={"form-control-product"}
                    onChange={this.handleChange}
                    disabled={false}
                    name="phone"
                    value={this.state.phone}
                    errorMessage={
                      this.state.errors && this.state.errors.phone
                        ? this.state.errors.phone
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label
                    htmlFor="product_description"
                    className="form-control-label"
                  >
                    Address
                  </label>
                  <textarea
                    className="form-control form-control-textarea wt-resize"
                    cols="50"
                    id="address"
                    onChange={this.handleChange}
                    disabled={false}
                    aria-describedby="category_nameHelp"
                    value={this.state.address}
                    rows="4"
                    name={"address"}
                    maxLength={200}
                    style={{ marginBottom: "35px" }}
                  >
                    {this.state.address}
                  </textarea>
                  <div className={"text-danger"}>
                    {this.state.errors && this.state.errors.address
                      ? this.state.errors.address
                      : ""}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputTextField
                    id="whatsapp"
                    label={"WhatsApp Link"}
                    className={"form-control-product"}
                    onChange={this.handleChange}
                    disabled={false}
                    name="watsapp"
                    // value={this.state.phone}
                    // errorMessage={
                    //     this.state.errors && this.state.errors.phone ? this.state.errors.phone : ''
                    // }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputTextField
                    id="telegram"
                    label={"Telegram Link"}
                    className={"form-control-product"}
                    onChange={this.handleChange}
                    disabled={false}
                    name="telegram"
                    // value={this.state.phone}
                    // errorMessage={
                    //     this.state.errors && this.state.errors.phone ? this.state.errors.phone : ''
                    // }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputTextField
                    id="facebook"
                    label={"Facebook"}
                    className={"form-control-product"}
                    onChange={this.handleChange}
                    disabled={false}
                    name="facebook"
                    // value={this.state.phone}
                    // errorMessage={
                    //     this.state.errors && this.state.errors.phone ? this.state.errors.phone : ''
                    // }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputTextField
                    id="linkedin"
                    label={"Linked In"}
                    className={"form-control-product"}
                    onChange={this.handleChange}
                    disabled={false}
                    name="linkedin"
                    // value={this.state.phone}
                    // errorMessage={
                    //     this.state.errors && this.state.errors.phone ? this.state.errors.phone : ''
                    // }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputTextField
                    id="twitter"
                    label={"Twitter"}
                    className={"form-control-product"}
                    onChange={this.handleChange}
                    disabled={false}
                    name="twitter"
                    // value={this.state.phone}
                    // errorMessage={
                    //     this.state.errors && this.state.errors.phone ? this.state.errors.phone : ''
                    // }
                  />
                </div>
              </div>
              <div className="col-md-12 footer-form mt-0 pt-0 mb-0">
                <div className="d-flex align-items-center justify-content-center contactForm_admin">
                  <button
                    className="btn btn-primary cancel-btn"
                    style={{ height: "45px", width: "190px" }}
                    // onClick={handleCancel}
                  >
                    {" "}
                    CANCEL
                  </button>

                  <SubmitButton
                    className={"submit-btn"}
                    text="Save"
                    isLoading={this.state.loading}
                    onSubmit={this.handleSubmit}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageContactClient;
