/** @format */

import React from "react";
import { Dropdown, ProgressBar } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { InputTextField } from "../forms";
import {
  AppConfig,
  genderOptions,
  customReactSelectStyles,
  ApiRoutes,
} from "../../../config";
import { BatchViewLoader } from "../loader/Resusable/BatchView";
import defaultImg from "../../../assets/img/group-12.png";
import editIcon from "../../../assets/img/edit-3.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import birthDateCalender from "../../../assets/img/birthDateCalender.svg";
import "./staff.css";
import { useState } from "react";
import { ApiHelper } from "../../../helpers";
import { useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
export const InfoDetailComponent2 = ({
  staffData,
  staffId,
  isEditable,
  actionBtn = true,
  isLoading,
  isUpdating,
  isUploading,
  uploadPercentage,
  onEdit,
  onChange,
  onSubmit,
  onSelect,
  onFileChange,
  sortByAction,
  onDateChange,
  onDeleteFile,
  onCancel,
  viewPermission,
  changeStaffStatus,
  portalAccessSwitcher,
  portalAccessDiv,
  dateError,
  onRoleSelect,
  handleGenderChange,
}) => {
  const {
    email = "",
    employee_id = "",
    first_name = "",
    last_name = "",
    gender = "",
    office_location = "",
    office_phone = "",
    phone = "",
    profile_picture = "",
    joining_date = "",
    birth_date = "",
    personal_email = "",
    address1 = "",
    portal_access = "",
    status = "",
    errors = {},
    roleName = "",
  } = staffData;
  console.log("gender", gender);

  const [roleData, setRoleData] = useState();
  const fetchRoleData = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ROLE.service,
      ApiRoutes.GET_ROLE.url,
      ApiRoutes.GET_ROLE.method,
      ApiRoutes.GET_ROLE.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { role },
      } = response.data;
      setRoleData(role);
    }
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  let roles = [];
  roleData &&
    roleData.forEach((element) => {
      roles.push({ value: element.id, label: element.roleName });
    });

  if (staffData.temp_role_id) {
    var a = roles.filter((e) => e.value === staffData.temp_role_id);
    console.log(a[0]);
    onRoleSelect(a ? a[0] : "");
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999,
    }),
  };
  return (
    <>
      <div className="card mb-3 card-main flex-fill viewStaffDetailNew">
        {isLoading ? (
          <BatchViewLoader />
        ) : (
          <>
            {" "}
            <div className="card-header py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-3">
                  <h6 className="font-book ">
                    Staff Details -{" "}
                    {[first_name, last_name].filter(Boolean).join(" ") || "-"}{" "}
                  </h6>
                </div>
                {actionBtn ? (
                  <div className="col-md-9">
                    <div className="side-col dp-right">
                      <button className="btn ic-btn" onClick={onEdit}>
                        {" "}
                        <img src={editIcon} alt="" /> Edit
                      </button>
                    </div>

                    {!isEditable && (
                      <Dropdown className="no-arrow dp-right drp-tp">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                          className={"filter-btn"}
                        >
                          Action <img src={filterarrowIcon} alt="" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                          <Dropdown.Item
                            as="div"
                            onClick={() => {
                              window.open(`mailto:${email}`);
                            }}
                          >
                            Contact User
                          </Dropdown.Item>
                          {status === "Suspended" ? (
                            <Dropdown.Item
                              as="div"
                              onClick={() => changeStaffStatus("Revoke")}
                            >
                              Revoke
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              as="div"
                              onClick={() => changeStaffStatus("Suspended")}
                            >
                              Suspend
                            </Dropdown.Item>
                          )}
                          {status === "Terminated" ? (
                            <Dropdown.Item
                              as="div"
                              onClick={() => changeStaffStatus("Rejoin")}
                            >
                              Rejoin
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              as="div"
                              onClick={() => changeStaffStatus("Terminated")}
                            >
                              Terminate
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}

                    {!isEditable && (
                      <div className="side-col dp-right">
                        <button
                          className="btn action-cl-btn"
                          onClick={() => viewPermission(staffId)}
                        >
                          {" "}
                          Permissions
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="col-md-9">
                    <div className="side-col dp-right">
                      <button className="btn ic-btn" onClick={onEdit}>
                        {" "}
                        <img src={editIcon} alt="" /> Edit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body card-table">
              <form className="user_add mt-2 mb-4">
                <div className="row">
                  <div className="col-md-2 col-img-sf">
                    <div className="text-center">
                      <div className="img-col-staff staff_Profile">
                        <img
                          className="user-img"
                          // src={
                          //   profile_picture
                          //     ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                          //     : defaultImg
                          // }
                          src={
                            profile_picture
                              ? profile_picture.startsWith("https://")
                                ? profile_picture
                                : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                              : defaultImg
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    {isUploading ? (
                      <ProgressBar now={uploadPercentage} className={"mt-2"} />
                    ) : null}
                    {isEditable ? (
                      <div className="file-upload-btn-sf align-items-center justify-content-center d-flex">
                        <label className="file-label">
                          {" "}
                          {profile_picture ? "Change Image" : "Choose Image"}
                          <input
                            className="imgUpload img_up"
                            name="profile_picture"
                            type="file"
                            accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                            onChange={onFileChange}
                          />
                        </label>
                        {/* <span
                          onClick={onDeleteFile}
                          className={"cursor-pointer"}
                        >
                          <img src={trashIcon} alt="" />
                        </span> */}
                      </div>
                    ) : null}
                    <div className={"text-danger"}>
                      {errors && errors.profile_picture
                        ? errors.profile_picture
                        : ""}
                    </div>

                    {/* <div className="st-name-head center">
                      <h4 className="sf_desc">{employee_id}</h4>
                    </div> */}
                    <div style={{ marginTop: "20px" }} className="sideMenuForm">
                      {!isEditable ? (
                        <h4 className="sf_name d-flex alin-items-center justify-content-center">
                          {" "}
                          {first_name || "N/A"}
                          {/* {[first_name, last_name].filter(Boolean).join(" ") ||
                            "-"}{" "} */}
                        </h4>
                      ) : (
                        <InputTextField
                          id=""
                          // label={"Role"}
                          className={"form-control-user"}
                          name="first_name"
                          onChange={onChange}
                          errorMessage={
                            errors && errors.first_name ? errors.first_name : ""
                          }
                          value={first_name}
                        />
                      )}
                      <div>
                        <label for="" className="form-control-label">
                          Role
                        </label>
                        {!isEditable ? (
                          <h4 className="sf_name d-flex alin-items-center justify-content-start mt-0 mb-4">
                            {roleName || "N/A"}
                          </h4>
                        ) : (
                          <div className="form-group">
                            <AsyncSelect
                              placeholder={"-- Select Role -- "}
                              className="form-control p-0"
                              maxMenuHeight={200}
                              styles={customStyles}
                              defaultOptions={roles}
                              value={staffData.role_id}
                              name="role_id"
                              onChange={(selectedOption) => {
                                onRoleSelect(selectedOption, "role_id");
                              }}
                            />
                            {!staffData?.role_id && dateError && (
                              <div className="text-danger">
                                Please enter the role
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="portalAccessBox d-flex flex-column">
                        <label for="" className="form-control-label">
                          Portal Access
                        </label>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            disabled={!isEditable}
                            className={`btn no-field ${
                              portal_access ? "active" : ""
                            }`}
                            onClick={() => portalAccessSwitcher(true)}
                          >
                            Enable
                          </button>
                          <button
                            type="button"
                            disabled={!isEditable}
                            className={`btn no-field ${
                              portal_access ? "" : "active"
                            }`}
                            onClick={() => portalAccessSwitcher(false)}
                          >
                            Disable
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 col-det-sf">
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="form-head">Professional Information</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <InputTextField
                          id="employee_id"
                          label={"Employee ID"}
                          className={"form-control-user disable-write"}
                          name="employee_id"
                          value={employee_id}
                          maxLength={30}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-4">
                        <InputTextField
                          id="email"
                          label={"Email ID"}
                          className={"form-control-user disable-write"}
                          name="email"
                          value={email}
                          readOnly={true}
                          // onChange={onChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <InputTextField
                          id="phone"
                          label={"Phone Number"}
                          className={"form-control-user"}
                          name="office_phone"
                          value={office_phone}
                          readOnly={!isEditable}
                          onChange={onChange}
                          maxLength={14}
                          errorMessage={
                            errors && errors.office_phone
                              ? errors.office_phone
                              : ""
                          }
                        />
                      </div>
                      {/* <div className="col-md-4">
                      <div className="form-group">
                        <label className={`form-control-label`}>Gender</label>
                        <AsyncSelect
                          placeholder={""}
                          defaultOptions={genderOptions}
                          styles={customReactSelectStyles}
                          value={gender}
                          onChange={(selectedOptions) =>
                            onSelect(selectedOptions)
                          }
                        />

                        {!gender && dateError && (
                          <div className="text-danger">
                            Please enter the gender
                          </div>
                        )}
                      </div> */}
                      {/* <InputTextField
                        id='gender'
                        label={'Gender'}
                        className={'form-control-user'}
                        name='gender'
                        value={gender}
                        readOnly={!isEditable}
                      /> */}
                      {/* </div> */}
                      <div className="col-md-4">
                        <label className={`form-control-label`}>
                          Date of Joining
                        </label>
                        <DatePicker
                          className={"form-control-input"}
                          placeholder={`Date of Joining(DD/MM/YYYY)`}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          disabled={true}
                          selected={joining_date}
                        />
                      </div>
                      <div className="col-md-4">
                        <InputTextField
                          id="office_location"
                          label={"Working Location"}
                          className={"form-control-user"}
                          name="office_location"
                          value={office_location}
                          onChange={onChange}
                          readOnly={!isEditable}
                          errorMessage={
                            errors && errors.office_location
                              ? errors.office_location
                              : ""
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <div
                          className="form-group"
                          style={{ marginBottom: "59px" }}
                        >
                          <div className="d-flex flex-column bio_textarea">
                            <label
                              for=""
                              class="form-control-label form-control-user"
                            >
                              Bio
                            </label>
                            {!isEditable ? (
                              <div className="readonlyFeild">
                                <textarea
                                  className="form-control"
                                  name="bio"
                                  value={staffData.bio}
                                  readOnly={true}
                                ></textarea>
                              </div>
                            ) : (
                              <>
                                <textarea
                                  className="form-control"
                                  name="bio"
                                  value={staffData.bio}
                                  onChange={onChange}
                                ></textarea>
                                {!staffData?.bio && dateError && (
                                  <div className="text-danger">
                                    Please enter the bio
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <h4 className="form-head mt-0">Personal Information</h4>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group datePicker viewDetail">
                          <label className={`form-control-label`}>
                            Date of Birth
                          </label>
                          <DatePicker
                            className={"form-control-input"}
                            placeholder={`Date of Birth(DD/MM/YYYY)`}
                            selected={birth_date}
                            maxDate={moment().subtract(18, "y")._d}
                            onChange={onDateChange}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            disabled={!isEditable}
                          />
                          <div className="icon">
                            <img src={birthDateCalender} alt="" />
                          </div>
                        </div>
                        {!birth_date && dateError && (
                          <div className="text-danger">
                            Please enter the birth date
                          </div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <InputTextField
                          id="per_phone"
                          label={"Phone Number"}
                          className={"form-control-user"}
                          name="phone"
                          value={phone}
                          readOnly={!isEditable}
                          maxLength={14}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.phone ? errors.phone : ""
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <InputTextField
                          id="personal_email"
                          label={"E-Mail"}
                          className={"form-control-user"}
                          name="personal_email"
                          value={personal_email}
                          readOnly={!isEditable}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.personal_email
                              ? errors.personal_email
                              : ""
                          }
                        />
                      </div>

                      <div className="col-md-4">
                        <label
                          class="form-control-label"
                          style={{ marginBottom: "14px" }}
                        >
                          Gender
                        </label>
                        <div className="d-flex align-items-center">
                          <label className="container-checkbox">
                            Male
                            <input
                              type="radio"
                              checked={gender === "male"}
                              name="gender"
                              value="Male"
                              onChange={handleGenderChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <label className="container-checkbox">
                            Female
                            <input
                              type="radio"
                              name="gender"
                              value="Female"
                              onChange={handleGenderChange}
                              checked={gender === "female"}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        {!gender && dateError && (
                          <div className="text-danger">
                            Please enter the gender
                          </div>
                        )}
                      </div>

                      <div className="col-md-8">
                        <InputTextField
                          id="address1"
                          label={"Address"}
                          className={"form-control-user"}
                          name="address1"
                          value={address1}
                          readOnly={!isEditable}
                          onChange={onChange}
                        />
                        {!address1 && dateError && (
                          <div className="text-danger">
                            Please enter the address
                          </div>
                        )}
                      </div>
                      {/* <div className="col-md-12 isPermissionDiv">
                      <input
                        id="isPermission"
                        name="isPermission"
                        type="checkbox"
                        disabled={!isEditable}
                        onChange={onChange}
                        className={"form-control-user"}
                        checked={isPermission === "assign" ? true : false}
                      />
                      <label
                        for="isPermission"
                        class="form-control-label form-control-user"
                      >
                        {permissionText}
                      </label>
                    </div> */}
                    </div>
                    {/* {portalAccessDiv && !isEditable && (
                      <div>
                        <div className="row">
                          <div className="col-md-4">
                            <h4 className="form-head">Portal Access</h4>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="input-group-prepend">
                                <label className="switch">
                                  <label className={`form-control-label`}>
                                    Invite Sent
                                  </label>
                                  <span className="slider round slide-yes-no-wrap">
                                    <span
                                      className={`yes-field ${
                                        portal_access ? "active" : ""
                                      }`}
                                      onClick={() => portalAccessSwitcher(true)}
                                    >
                                      Enable
                                    </span>
                                    <span className="separator-field">|</span>
                                    <span
                                      className={`no-field ${
                                        portal_access ? "" : "active"
                                      }`}
                                      onClick={() =>
                                        portalAccessSwitcher(false)
                                      }
                                    >
                                      Disable
                                    </span>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
      {isEditable && (
        <div class="row">
          <div class="col-md-12 d-flex">
            <div class="card card-main flex-fill">
              <div class="row">
                <div class="col-md-12 d-flex">
                  <div class="card card-main flex-fill">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12 footer-form-card">
                          <button
                            type="button"
                            onClick={onCancel}
                            class="btn btn-primary cancel-btn"
                          >
                            CANCEL
                          </button>
                          <button
                            class="btn btn-primary submit-btn"
                            type="submit"
                            onClick={onSubmit}
                            disabled={isUpdating}
                          >
                            {isUpdating ? (
                              <>
                                <i className="fa fa-spinner fa-spin"></i> Save
                              </>
                            ) : (
                              "Save"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
