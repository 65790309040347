/** @format */

import React from "react";
import RequestRow from "./RequestRow";
import { TableLoader } from "../loader/Resusable/Table";
import { AddClientFormComponent } from "./AddClientFormComponent";
import noData from "../../../assets/img/no-data.png";

export const ListRequestComponent = ({
  requests,
  isLoading,
  isFormOpen,
  isUpdating,
  updatingStatus,
  activeId,
  viewHandler,
  onCancel,
  updateStatus,
  resendInvitation,
  onDelete,
  editHandler,
  setFilter,
  sortBy,
  order,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-sm">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table
            className="clientRequestViewtable"
            width="100%"
            cellSpacing="0"
          >
            <thead className="pad-top view-client-request-thead">
              <tr>
                {[
                  { label: "Request ID", value: "applicationNumber" },
                  { label: "Owner Name", value: "name" },
                  { label: "Email", value: "email" },
                  { label: "Company Name", value: "companyName" },
                  { label: "Status", value: "status" },
                ].map(({ label, value }) => (
                  <th key={value}>
                    {label}{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === value ? "active-ac" : ""
                        }`}
                        onClick={() => setFilter(value, "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === value
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter(value, "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>
                ))}
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {isFormOpen ? (
                <AddClientFormComponent onCancel={onCancel} />
              ) : null}
              {requests && requests.length ? (
                requests.map((request, index) => (
                  <RequestRow
                    key={index}
                    requestData={request}
                    isUpdating={isUpdating && activeId === request._id}
                    updatingStatus={updatingStatus}
                    viewUser={() => viewHandler(request._id)}
                    updateStatus={(status, message) =>
                      updateStatus(request._id, status, message)
                    }
                    resendInvitation={() => resendInvitation(request._id)}
                    onDelete={() => onDelete(request._id)}
                    editUser={() => editHandler(request._id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={8} className={"text-center image-no-data-sm"}>
                    <img src={noData} alt="" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
