import Validator from "js-object-validation";
import { message } from "../common";

export const requestClient = (data) => {
  const validations = {
    first_name: {
      required: true,
      alpha: true,
    },
    country: {
      required: true,
    },
    // birth_date: {
    //   required: true,
    // },
  };
  // Error messages
  const messages = {
    first_name: {
      required: message.Required.replace(":item", "First Name"),
      alpha: message.InvalidName.replace(":item", "First name"),
    },
    last_name: {
      required: message.Required.replace(":item", "last name"),
      alpha: message.InvalidName.replace(":item", "last name"),
    },
    email: {
      required: message.Required.replace(":item", "Email address"),
      email: message.InvalidEmail,
    },
    phone: {
      required: message.Required.replace(":item", "Phone Number"),
      numeric: message.InvalidPhone,
    },
    address1: {
      required: message.Required.replace(":item", "address line 1"),
    },
    address2: {
      required: message.Required.replace(":item", "address line 2"),
    },
    country: {
      required: message.Required.replace(":item", "country"),
    },
    state: {
      required: message.Required.replace(":item", "state"),
    },
    postCode: {
      required: message.Required.replace(":item", "post code"),
    },
    gender: {
      required: message.Required.replace(":item", "gender"),
    },
    birth_date: {
      required: message.Required.replace(":item", "birth date"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
