import React from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../config";
import editIcon from "../../../assets/img/edit-3.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import unpublishIcon from "../../../assets/img/unpublish.svg";
import publishIcon from "../../../assets/img/publish.svg";

export const VideoRow = (props) => {
  const { name = "-", link = "-", created_at, id } = props.videodata;
  const { isPublished, onChange } = props;

  return (
    <tr>
      <td>{name}</td>
      <td>{link}</td>
      <td>
        {created_at ? moment(created_at).format(DEFAULT_DATE_FORMAT) : "-"}
      </td>
      <td>
        <div
          className="btn btn-secondary th-btn edit-tb"
          onClick={props.editVideo}
        >
          <img src={editIcon} alt="" /> Edit
        </div>
        {isPublished ? (
          <div
            className="btn btn-secondary th-btn edit-tb"
            onClick={() =>
              onChange({ target: { name: "isPublished", value: false, id } })
            }
          >
            <img src={unpublishIcon} alt="" /> Unpublish
          </div>
        ) : (
          <div
            className="btn btn-secondary th-btn edit-tb"
            onClick={() =>
              onChange({ target: { name: "isPublished", value: true, id } })
            }
          >
            <img src={publishIcon} alt="" /> Publish
          </div>
        )}
        <div
          className="btn btn-secondary th-btn delete-tb"
          onClick={props.deleteVideo}
        >
          <img src={trashIcon} alt="" /> Delete
        </div>
      </td>
    </tr>
  );
};

export default VideoRow;
