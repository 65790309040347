import React, { Component } from 'react';
import { toast } from 'react-toastify';
import {
  setUserData,
  setCheck,
  getCheck,
  clearCheck,
  isLogin,
} from '../../../helpers/userData';
import { ApiRoutes, AppRoutes } from '../../../config';
import { loginValidator } from '../../../validators';
import { ApiHelper } from '../../../helpers';
import LoginFormComponent from '../../components/login';
import Footer from '../../components/partial/Footer';
import mainImg from '../../../assets/img/main.png';
import main2xImg from '../../../assets/img/main@2x.png';
import main3xImg from '../../../assets/img/main@3x.png';
import mainmobImg from '../../../assets/img/main_mob.png';
import mainmob2xImg from '../../../assets/img/main_mob@2x.png';
import mainmob3xImg from '../../../assets/img/main_mob@3x.png';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: null,
      islogged: false,
      isLoading: false,
    };
  }

  componentWillMount() {
    const data = getCheck();
    if (data) {
      const { email, password } = data;
      this.setState({ email, password, islogged: true });
    }
  }

  componentDidMount() {
    document.body.classList.add('bg-gradient-primary');
    const isLoggedInUser = isLogin();
    // Redirect to dashboard if admin already loggedin
    if (isLoggedInUser) {
      this.props.history.push(AppRoutes.DASHBOARD);
    }
  }

  handleChange = event => {
    const {
      target: { type, value, name, checked },
    } = event;
    this.setState({
      [name]: type === 'checkbox' ? checked : value,
      errors: { ...this.state.errors, [name]: '' },
    });
  };

  handleSubmit = async event => {
    event && event.preventDefault();
    const { email, password, islogged } = this.state;
    let data = {
      email,
      password,
    };
    let { isValid, errors } = loginValidator(data);
    if (isValid) {
      this.setState({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.LOGIN.service,
        ApiRoutes.LOGIN.url,
        ApiRoutes.LOGIN.method,
        ApiRoutes.LOGIN.authenticate,
        undefined,
        data,
      );
      this.setState({
        isLoading: false,
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        const { data } = response.data;
        setUserData(data);
        // const { email, password } = data;
        const { email, password } = this.state;
        islogged ? setCheck(email, password) : clearCheck();
        this.props.history.push(AppRoutes.DASHBOARD);
      }
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };

  getCheckbox = () => {
    this.setState({ islogged: !this.state.islogged });
  };

  componentWillUnmount = () => {
    document.body.classList.remove('bg-gradient-primary');
  };
  render() {
    const { email, password, errors, islogged, isLoading } = this.state;
    return (
      <>
        <div className='container'>
          <div className='row login-row justify-content-center'>
            <div className='col-xl-5 col-lg-5 col-md-6'>
              <div className='card o-hidden border-0 shadow-lg my-7'>
                <div className='card-body p-0'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='p-5'>
                        <div className='text-center'>
                          <img
                            src={mainImg}
                            srcSet={`${main2xImg} 2x,
                             ${main3xImg} 3x`}
                            className='main-blue'
                            alt=''
                          />

                          <img
                            src={mainmobImg}
                            srcSet={`${mainmob2xImg} 2x,
                             ${mainmob3xImg}.png 3x`}
                            className='main-blue-mob'
                            alt=''
                          />
                          <h1 className='h4 head-top mbt-3'>Login</h1>
                        </div>
                        <LoginFormComponent
                          email={email}
                          password={password}
                          errors={errors}
                          islogged={islogged}
                          isLoading={isLoading}
                          onChange={this.handleChange}
                          onSubmit={this.handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Login;
