import React from 'react';

function TestCard(props) {
    return (
        <>
            <div className={props.grid}>
                <div className='card card-main p-2 testCustomCard shadow'>
                    <img className='mb-3' src={props.img} />
                    <div className='px-2'>
                        <h3>{props.title}</h3>
                        <p>{props.description}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TestCard;