import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import listIcon from "../../../assets/img/list.svg";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../../../config";
import { sendToOptions } from "../../../config/Constant";

export const PushRow = ({ pushData, onEdit, onDelete, viewUser }) => {
  const {
    title = "",
    subject = "",
    sendTo = "",
    dateScheduled = "",
  } = pushData;

  const sendToLabel = sendToOptions.filter((option) => option.value === sendTo);
  return (
    <tr>
      <td>
        <div style={{ width: "140px", whiteSpace: "break-spaces" }}>
          {title}
        </div>
      </td>
      <td>
        <div style={{ width: "400px", whiteSpace: "break-spaces" }}>
          {subject}
        </div>
      </td>
      <td>{sendToLabel[0].label}</td>
      <td>
        {dateScheduled
          ? [
              dateScheduled.split("T")[1].slice(0, 5),
              moment(dateScheduled.split("T")[0]).format(DEFAULT_DATE_FORMAT),
            ].join(" Hrs | ")
          : ""}
      </td>
      <td style={{ textAlign: "center" }}>
        <button className="btn btn-secondary th-btn" onClick={viewUser}>
          {" "}
          <img src={listIcon} alt="" /> View{" "}
        </button>
        {/* <Button
          className="btn btn-secondary th-btn edit-tb action-block-btn"
          onClick={onEdit}
          disabled={new Date().toISOString() > dateScheduled ? true : false}
        >
          <span>
            <img src={editIcon} alt="" className={"img-main"} />
            <img src={editIconWhite} alt="" className={"img-hover"} />
          </span>
          Edit
        </Button>
        <Button
          variant="danger"
          className="btn th-btn delete-tb action-block-btn"
          onClick={onDelete}
          disabled={new Date().toISOString() > dateScheduled ? true : false}
        >
          <span>
            <img src={trashIcon} alt="" className={"img-main"} />
            <img src={trashIconWhite} alt="" className={"img-hover"} />
          </span>
          Delete
        </Button> */}
      </td>
    </tr>
  );
};
