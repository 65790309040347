/** @format */

import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import * as qs from "query-string";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import debounce from "lodash.debounce";
import { ApiRoutes, SocketEvents } from "../../../config";
import { ApiHelper } from "../../../helpers";
import Pagination from "../../components/pagination";
import {
  ListComponent,
  AddFormComponent,
} from "../../components/video-tutorial";
import { FilterComponent } from "../../components/filters";
import PHLContext from "../../../PHLContext";
import { SearchFilter } from "../../components/filters/searchFilter";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import pageDropdownIcon from "../../../assets/img/tag-bd.svg";
import mobSearchIcon from "../../../assets/img/tag-search-bd.svg";
import "./videotutorial.css";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";

let lat, long;

class VideoTutorial extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      videosData: {},
      faq: {},
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      activeId: "",
      sortBy: "updatedAt",
      order: "desc",
      search: "",
      newRecord: false,
      isFilterApplied: false,
      showAddForm: false,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    let { socket } = this.context;
    if (socket) {
      socket.on(SocketEvents.NEW_SCAN_ADDED, (data) => {
        console.log("event emitted", data);

        let newData = [...this.state.faqs];
        const alreadyExistIndex = newData.findIndex(
          (item) => item.scanId === data[0].scanId
        );
        if (alreadyExistIndex > -1) {
          newData.splice(alreadyExistIndex, 1);
          newData.unshift(...data);
        } else {
          newData.splice(-1, 1);
          newData.unshift(...data);
        }
        this.setState({ faqs: newData });
      });
    }
    this.handleQueryParams();
  };
  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    if (query && query.sort === "location") {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      lat = position.coords.latitude;
      long = position.coords.longitude;
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "createdAt",
        order: query.order
          ? query.order
          : // : query.sort === 'scan_id' || query.sort === 'created_at'
            // ? 'desc'
            "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
      },
      () => this.fetchVideoList()
    );
  };

  // componentDidUpdate = ({ location: { search: prevSearch } }) => {
  //   const {
  //     location: { search },
  //   } = this.props;
  //   if (prevSearch !== search) {
  //     this.handleQueryParams();
  //   }
  // };

  componentDidUpdate = ({ location: { search: prevSearch } }, prevState) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
    const splitValue = this.props.location.pathname.split("/")[2]; //[3];
    if (prevState.userDetail !== splitValue) {
      this.setState({
        userDetail: splitValue,
      });
      setTimeout(() => {
        this.handleQueryParams();
      }, 500);
    }
  };

  fetchVideoList = async () => {
    const { currentPage, sortBy, search, limit, newRecord, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
      lat,
      long,
    };
    this.setState({
      isLoading: newRecord ? false : true,
    });
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }

    // Remove keys with empty or null values
    Object.keys(data).forEach(
      (key) => (data[key] == null || data[key] === "") && delete data[key]
    );
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.GET_VIDEO_TUTORIAL_NEW.service,
      ApiRoutes.GET_VIDEO_TUTORIAL_NEW.url,
      ApiRoutes.GET_VIDEO_TUTORIAL_NEW.method,
      ApiRoutes.GET_VIDEO_TUTORIAL_NEW.authenticate,
      { ...data, order: data?.order == "desc" ? "DESC" : "ASC" },
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      // const {
      //   data: { videos, totalRecords },
      // } = response.data.data;
      // this.setState({ videos, totalRecords });
      const videos = response?.data?.data;
      const totalRecords = videos?.length;
      this.setState({ videos, totalRecords });
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  handleAddCancel = (event) => {
    this.setState({
      videosData: {},
      showAddForm: false,
    });
    if (event) event.preventDefault();
    else this.fetchVideoList();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  componentWillUnmount = () => {
    this.emitSearch.cancel();
  };

  openAddModal = () => {
    this.setState({
      showAddForm: true,
    });
  };

  onEdit = (videos) => {
    this.setState({
      videosData: videos,
      showAddForm: true,
    });
  };
  onDelete = async (id) => {
    const { value } = await SweetAlertComponent({
      title: "",
      text: `Are you sure, you want to delete?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.DELETE_VIDEO_TUTORIAL_NEW.service,
      ApiRoutes.DELETE_VIDEO_TUTORIAL_NEW.url,
      ApiRoutes.DELETE_VIDEO_TUTORIAL_NEW.method,
      ApiRoutes.DELETE_VIDEO_TUTORIAL_NEW.authenticate,
      { _id: id },
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
      id: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      await this.fetchVideoList();
    }
  };

  render() {
    const {
      videos,
      videosData,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      order,
      search,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filtersOption = [
      {
        label: "Video Name",
        value: "videoName",
      },
      {
        label: "Video Description",
        value: "videoDescription",
      },
      {
        label: "Update on",
        value: "updatedAt",
      },
    ];

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Video Tutorials</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
        />

        <div className="card card-pagination card-main">
          <div className="card-header py-7550 align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book ">Video Tutorial Listing</h6>
              </div>
              <div className="col-md-10 mob-filter-data">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn-mob"}
                  >
                    <img src={pageDropdownIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                    {filtersOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.setFilter(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dropdown no-arrow dp-right">
                  <button
                    type="button"
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={mobSearchIcon} alt="" />
                  </button>
                </div>
              </div>

              <div className="col-md-9 filter-col-lg">
                <div className="no-arrow dp-right dropdown">
                  <Button className="pushButtonCZ" onClick={this.openAddModal}>
                    Add Video
                  </Button>
                </div>
                <FilterComponent
                  options={filtersOption}
                  selectedFilter={sortBy}
                  onFilterChange={this.setFilter}
                />
                <SearchFilter
                  placeholder={"Search by video name and description"}
                  search={search}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <ListComponent
            videos={videos}
            videosData={videosData}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            totalRecords={totalRecords}
            limit={limit}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
        <Modal
          show={this.state.showAddForm}
          onHide={this.handleAddCancel.bind(this)}
          size="lg"
          className="proAddCZ videoAddModal"
          centered
        >
          {/* <Modal.Header>
            <Modal.Title>Add FAQ</Modal.Title>
          </Modal.Header> */}
          <Modal.Body>
            <AddFormComponent
              videos={videosData}
              handleAddCancel={this.handleAddCancel}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default VideoTutorial;
