/** @format */

import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { AppConfig, customReactSelectStyles } from "../../../config";
import globeIcon from "../../../assets/img/globe_small.svg";
import location from "../../../assets/img/location.svg";
import poseCode from "../../../assets/img/poseCode.svg";
import userIcon from "../../../assets/img/userIcon.svg";
import mailIcon from "../../../assets/img/mail-1.svg";
import phoneIcon from "../../../assets/img/phoneIcon.svg";
import editIcon from "../../../assets/img/edit-3.svg";
import demoImg2 from "../../../assets/img/group-12.png";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import { BatchViewLoader } from "../loader/Resusable/BatchView";
import { InputTextField } from "../forms";
import trashIcon from "../../../assets/img/trash-btn.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import { ApiHelper } from "../../../helpers";
export const ContactDetailComponent = ({
  clientData,
  isLoading,
  isEditable,
  onEdit,
  onSubmit,
  isUpdating,
  onCancel,
  onChange,
  allCountry,
  onSelectOption,
  statesOption,
  onFileChange,
  onDeleteFile,
  uploadPercentage,
  isUploading,
  genderOptions,
  onSelectGender,
  onDateChange,
  dateError,
  onDeleteImage,
}) => {
  console.log("clientData", clientData);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
  };
  const loadCountryData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  return (
    <div className="card mb-4 card-main flex-fill customEditFeilds">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <>
          <div className="card-header py-7550 align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="font-book ">Owner Details</h6>
                  {!isEditable ? (
                    <Button
                      className="btn btn-secondary th-btn edit-tb action-block-btn  bg-white"
                      onClick={onEdit}
                    >
                      <>
                        <span>
                          <img src={editIcon} alt="" className={"img-main"} />
                          <img
                            src={editIconWhite}
                            alt=""
                            className={"img-hover"}
                          />
                        </span>
                        Edit
                      </>
                    </Button>
                  ) : (
                    <>
                      <div className="float-right">
                        <button
                          className="btn action-cl-btn"
                          onClick={onSubmit}
                          disabled={isUpdating}
                        >
                          {isUpdating ? (
                            <>
                              <i className="fa fa-spinner fa-spin"></i> Save
                            </>
                          ) : (
                            "Save"
                          )}
                        </button>
                        <button
                          className="btn btn-secondary can-btn-table cn-tp"
                          onClick={onCancel}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            <div className="row mtblf-5">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={userIcon} alt="" />
                      <h5>First Name</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pl-0 pb-2">
                          <InputTextField
                            id="first_name"
                            className={"form-control-user"}
                            name="first_name"
                            value={clientData.first_name}
                            maxLength={30}
                            onChange={onChange}
                            errorMessage={
                              clientData.errors && clientData.errors.first_name
                                ? clientData.errors.first_name
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{clientData.first_name || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={userIcon} alt="" />
                      <h5>Last Name</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pl-0 pb-2">
                          <InputTextField
                            id="last_name"
                            className={"form-control-user"}
                            name="last_name"
                            value={clientData.last_name}
                            maxLength={30}
                            onChange={onChange}
                            errorMessage={
                              clientData.errors && clientData.errors.last_name
                                ? clientData.errors.last_name
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{clientData.last_name || "N/A"}</h5>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={userIcon} alt="" />
                      <h5>Gender</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-12 pl-0 pb-2">
                          <div className="form-group">
                            <AsyncSelect
                              placeholder={""}
                              defaultOptions={genderOptions}
                              styles={customReactSelectStyles}
                              value={{
                                label: clientData?.gender,
                                value: clientData?.gender,
                              }}
                              onChange={(selectedOptions) =>
                                onSelectGender(selectedOptions)
                              }
                            />
                            <div className="text-danger">
                              {clientData.errors && clientData.errors.gender
                                ? clientData.errors.gender
                                : ""}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <h5>{clientData?.gender || "N/A"}</h5>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={userIcon} alt="" />
                      <h5>Date of Birth *</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-12 pl-0 pb-2">
                          <DatePicker
                            className={"form-control-input"}
                            placeholder={`Date of Birth(DD/MM/YYYY)`}
                            selected={clientData?.birth_date}
                            maxDate={moment().subtract(18, "y")._d}
                            onChange={onDateChange}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                          {!clientData?.birth_date && dateError && (
                            <div className="text-danger">
                              Please enter the birth date
                            </div>
                          )}
                        </div>
                      ) : (
                        <h5>
                          {clientData?.birth_date
                            ? moment(clientData?.birth_date).format(
                                "DD/MM/YYYY"
                              )
                            : "N/A"}
                        </h5>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={location} alt="" />
                      <h5>Nationality *</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <AsyncSelect
                            loadOptions={loadCountryData}
                            placeholder={"Select"}
                            styles={customStyles}
                            maxMenuHeight={200}
                            defaultOptions={allCountry}
                            value={{
                              label: clientData?.country,
                              value: clientData?.country,
                            }}
                            onChange={(selectedOption) =>
                              onSelectOption(
                                selectedOption,
                                "country",
                                "clientData"
                              )
                            }
                            noOptionsMessage={() =>
                              "No option found related to your search"
                            }
                          />
                          <div className="text-danger">
                            {clientData.errors && clientData.errors.country
                              ? clientData.errors.country
                              : ""}
                          </div>
                        </div>
                      ) : (
                        <h5>{clientData?.country || "N/A"}</h5>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-md-4">
                    <div className="heading-col">
                      <img src={phoneIcon} alt="" />
                      <h5>Phone Number</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pl-0 pb-2">
                          <InputTextField
                            id="phone"
                            className={"form-control-user"}
                            name="phone"
                            value={clientData.phone}
                            maxLength={14}
                            onChange={onChange}
                            errorMessage={
                              clientData.errors && clientData.errors.phone
                                ? clientData.errors.phone
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{clientData.phone || "N/A"}</h5>
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  {/* <div className="col-md-4">
                    <div className="heading-col">
                      <img src={mailIcon} alt="" />
                      <h5>Email</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pl-0 pb-2">
                          <InputTextField
                            id="phone"
                            className={"form-control-user"}
                            name="email"
                            value={clientData.email}
                            maxLength={50}
                            onChange={onChange}
                            errorMessage={
                              clientData.errors && clientData.errors.email
                                ? clientData.errors.email
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{clientData.email || "N/A"}</h5>
                      )}
                      <h5>{clientData.email || "N/A"}</h5>
                    </div>
                  </div> */}

                  {/* <div className="col-md-4">
                    <div className="heading-col">
                      <img src={location} alt="" />
                      <h5>Address Line 1</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pl-0 pb-2">
                          <InputTextField
                            id="phone"
                            className={"form-control-user"}
                            name="address1"
                            value={clientData.address1}
                            maxLength={50}
                            onChange={onChange}
                            errorMessage={
                              clientData.errors && clientData.errors.address1
                                ? clientData.errors.address1
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{clientData.address1 || "N/A"}</h5>
                      )}
                    </div>
                  </div> */}
                  {/* <div className="col-md-4">
                    <div className="heading-col">
                      <img src={location} alt="" />
                      <h5>Address Line 2</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pl-0 pb-2">
                          <InputTextField
                            id="phone"
                            className={"form-control-user"}
                            name="address2"
                            value={clientData.address2}
                            maxLength={50}
                            onChange={onChange}
                            errorMessage={
                              clientData.errors && clientData.errors.address2
                                ? clientData.errors.address2
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{clientData.address2 || "N/A"}</h5>
                      )}
                    </div>
                  </div> */}
                </div>
                {/* <div className="row">
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={location} alt="" />
                      <h5>State</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <AsyncSelect
                            loadOptions={loadStateData}
                            placeholder={"Select"}
                            styles={customStyles}
                            maxMenuHeight={200}
                            defaultOptions={statesOption}
                            value={{
                              label: clientData.state,
                              value: clientData.state,
                            }}
                            onChange={(selectedOption) =>
                              onSelectOption(
                                selectedOption,
                                "state",
                                "clientData"
                              )
                            }
                            noOptionsMessage={() => {
                              return clientData.country &&
                                clientData.country.value
                                ? "No states found"
                                : "First select country";
                            }}
                          />
                          <div className="text-danger">
                            {clientData.errors && clientData.errors.state
                              ? clientData.errors.state
                              : ""}
                          </div>
                        </div>
                      ) : (
                        <h5>{clientData?.state || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={globeIcon} alt="" />
                      <h5>Country</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <AsyncSelect
                            loadOptions={loadCountryData}
                            placeholder={"Select"}
                            styles={customStyles}
                            maxMenuHeight={200}
                            defaultOptions={allCountry}
                            value={{
                              label: clientData.country,
                              value: clientData.country,
                            }}
                            onChange={(selectedOption) =>
                              onSelectOption(
                                selectedOption,
                                "country",
                                "clientData"
                              )
                            }
                            noOptionsMessage={() =>
                              "No option found related to your search"
                            }
                          />
                          <div className="text-danger">
                            {clientData.errors && clientData.errors.country
                              ? clientData.errors.country
                              : ""}
                          </div>
                        </div>
                      ) : (
                        <h5>{clientData?.country || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={poseCode} alt="" />
                      <h5>Post Code</h5>
                    </div>
                    <div className="description-col">
                      {isEditable ? (
                        <div className="col-md-8 pl-0 pb-2">
                          <InputTextField
                            id="phone"
                            className={"form-control-user"}
                            name="postCode"
                            value={clientData.postCode}
                            maxLength={50}
                            onChange={onChange}
                            errorMessage={
                              clientData.errors && clientData.errors.postCode
                                ? clientData.errors.postCode
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{clientData.postCode || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="col-md-3">
                <div className="img_box mt-0">
                  <img
                    src={
                      clientData.profile_picture
                        ? clientData.profile_picture.startsWith("https://")
                          ? clientData.profile_picture
                          : `${AppConfig.FILES_ENDPOINT}${clientData.profile_picture}`
                        : demoImg2
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = demoImg2;
                    }}
                    alt=""
                  />
                  {isUploading ? (
                    <ProgressBar now={uploadPercentage} className={"mt-2"} />
                  ) : null}
                  {isEditable ? (
                    <div className="file-upload-btn ">
                      <label className="file-label">
                        {" "}
                        {clientData.profile_picture
                          ? "Change Image"
                          : "Choose Image"}
                        <input
                          className="imgUpload img_up"
                          name="profile_picture"
                          type="file"
                          accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                          onChange={onFileChange}
                        />
                      </label>
                      <button
                        disabled={!clientData?.profile_picture}
                        onClick={() =>
                          onDeleteImage(
                            clientData?.profile_picture,
                            "profile_picture"
                          )
                        }
                        className={"cursor-pointer dlt-btn"}
                      >
                        <img src={trashIcon} alt="" />
                      </button>

                      <div className={"text-danger"}>
                        {clientData.errors && clientData.errors.profile_picture
                          ? clientData.errors.profile_picture
                          : ""}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
