import Validator from 'js-object-validation';
import { message } from '../common';

export const loginValidator = data => {
  const validations = {
    email: {
      required: true,
      email: true,
    },
    password: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    email: {
      required: message.Required.replace(':item', 'Email address'),
      email: message.InvalidEmail,
    },
    password: {
      required: message.Required.replace(':item', 'Password'),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
