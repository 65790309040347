import React from "react";
import Skeleton from "react-skeleton-loader";
import { CardLoader } from "../Resusable/Card";
// import { DashboardWidgetLoader } from "../Resusable/DashboardWidget";
import { TableLoader } from "../Resusable/Table";
export const DashboardLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>Dashboard</h1>
      <p className='breadcrums-top'>
        <Skeleton color ="#fff"/>
      </p>
      <div className='row'>
        <div className='col-xl-4 col-md-6 mb-4'>
          <CardLoader />
        </div>
        <div className='col-xl-4 col-md-6 mb-4'>
          <CardLoader />
        </div>
        <div className='col-xl-4 col-md-6 mb-4'>
          <CardLoader />
        </div>
      </div>
      {/* <div className='row'>
        <div className='col-xl-4 col-md-6 mb-4'>
          <DashboardWidgetLoader />
        </div>
        <div className='col-xl-4 col-md-6 mb-4'>
          <DashboardWidgetLoader />
        </div>
        <div className='col-xl-4 col-md-6 mb-4'>
          <DashboardWidgetLoader />
        </div>
      </div> */}
      <div className='card mb-4'>
        <div className='card-header py-75  align-items-center justify-content-between'>
          <div className='row'>
            <div className='col-md-6 head-col'>
              <h6 className='m-25 font-book '>
                <Skeleton color ="#e5e9ef"/>
              </h6>
            </div>
            <div className='col-md-6 side-col dropdown'>
              <Skeleton color ="#e5e9ef"/>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive fixed-height-table'>
            <TableLoader columns={4} />
          </div>
        </div>
      </div>
    </div>
  </>
);
