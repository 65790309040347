import React, { useEffect, useContext, useRef } from "react";
import noData from "../../../assets/img/no-data.png";
import {
  useLocation,
  useHistory,
  withRouter,
  matchPath,
  Link,
} from "react-router-dom";
import useState from "react-usestateref";
import { Dropdown } from "react-bootstrap";
import PHLContext from "../../../PHLContext";
import {
  ApiRoutes,
  AppConfig,
  AppRoutes,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../../config";
import { Logout, ApiHelper } from "../../../helpers";
import { routesArray } from "./../../../router/routes";
import avatarImg from "../../../assets/img/avatar.png";
import notificationImg from "../../../assets/img/notification.svg";
import barTop from "../../../assets/img/bar-top.svg";
import downArrow from "../../../assets/img/path-2.svg";
import scanLogo from "../../../assets/img/scanNotify.svg";
import counterfeitLogo from "../../../assets/img/counterfeitNotify.svg";
import userPic from "../../../assets/img/group-12.png";
import moment from "moment";
import { SocketEvents } from "../../../config";
import Shopping from "../../../assets/img/productIcon.svg";
import Group from "../../../assets/img/batchIcon.svg";

const Header = ({ toggleSidebar }) => {
  const history = useHistory();
  const { socket } = useContext(PHLContext);
  const [title, setTitle] = useState("");
  const [notificationCount, setNotificationCount] = useState();
  const [notifications, setNotifications] = useState([]);
  const [show, setShow, showRef] = useState(false);
  const [profileData, setProfileData] = useState();
  let [deleteCount, setDeleteCount, deleteCountRef] = useState(0);
  const ref = useRef(null);

  const route = useLocation();
  useEffect(() => {
    const currentRoute = routesArray.find((rout) =>
      matchPath(route.pathname, rout)
    );
    console.log("currentRoute", currentRoute);
    if (currentRoute) {
      setTitle(currentRoute.name);
    } else {
      setTitle(
        `${route.pathname
          .replace("/", "")
          .toLowerCase()
          .charAt(0)
          .toUpperCase()}${route.pathname.replace("/", "").slice(1)}`
      );
    }
  }, [route.pathname]);
  useEffect(() => {
    async function fetchData() {
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_PROFILE.service,
        ApiRoutes.GET_PROFILE.url,
        ApiRoutes.GET_PROFILE.method,
        ApiRoutes.GET_PROFILE.authenticate,
        undefined,
        undefined
      );
      if (response && response.isError) {
        Logout();
      } else {
        const { data } = response.data;
        const { notification_count = 0 } = data || {};
        setProfileData(data);
        setNotificationCount(notification_count);
      }
    }
    fetchData();
    fetchNotifications();
    if (socket) {
      // socket.emit('online', { userId: '5f4428f30fa9c11ef89bb084' });
      socket.on(SocketEvents.NOTIFICATION, (data) => {
        setNotificationCount((prevCount) => prevCount + 1);
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
      });
    }
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        // onClickOutside && onClickOutside();
        setShow(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const fetchNotifications = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NOTIFICATIONS.service,
      ApiRoutes.GET_NOTIFICATIONS.url,
      ApiRoutes.GET_NOTIFICATIONS.method,
      ApiRoutes.GET_NOTIFICATIONS.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      setNotificationCount(response.data.count);
      setNotifications(data);
    }
  };
  const deleteNotification = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_NOTIFICATION.service,
      ApiRoutes.DELETE_NOTIFICATION.url.replace(":id", id),
      ApiRoutes.DELETE_NOTIFICATION.method,
      ApiRoutes.DELETE_NOTIFICATION.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      fetchNotifications();
      // setShow(true);
    }
  };
  const clearAllNotifications = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.service,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.url,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.method,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      fetchNotifications();
      setNotifications([]);
    }
  };
  const notificationRead = async (notification) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.NOTIFICATION_MARK_READ.service,
      ApiRoutes.NOTIFICATION_MARK_READ.url.replace(":id", notification.id),
      ApiRoutes.NOTIFICATION_MARK_READ.method,
      ApiRoutes.NOTIFICATION_MARK_READ.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      fetchNotifications();
      history.push(notification.redirectionURL);
    }
  };
  const resetNotificationCount = async (notification) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESET_NOTIFICATION_COUNT.service,
      ApiRoutes.RESET_NOTIFICATION_COUNT.url,
      ApiRoutes.RESET_NOTIFICATION_COUNT.method,
      ApiRoutes.RESET_NOTIFICATION_COUNT.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      setNotificationCount(0);
    }
  };

  const getIcon = (type) => {
    var imgReturn = null;
    switch (type) {
      case "product":
        imgReturn = <img className="icon" src={Shopping} alt="" />;
        break;
      case "batch":
        imgReturn = <img className="icon" src={Group} alt="" />;
        break;
      case "counterfeit":
        imgReturn = <img className="icon" src={counterfeitLogo} alt="" />;
        break;
      case "scan":
        imgReturn = <img className="icon" src={scanLogo} alt="" />;
        break;
      default:
        imgReturn = <img className="logo" src={userPic} alt="" />;
        break;
    }
    return imgReturn;
  };
  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb_12 static-top ">
      <button
        id="sidebarToggleTop"
        className="btn-bar btn-link d-md-none mr-3"
        onClick={() => toggleSidebar(true)}
      >
        <img src={barTop} alt="" />
      </button>

      <h1 className="h3-head">{title}</h1>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow mx-175 notificationDropdown">
          <Dropdown
            className={"no-arrow mx-175"}
            ref={ref}
            show={show}
            onClick={(e) => {
              setDeleteCount(
                e.target.name === "dd" || e.target.name === "dd_image"
                  ? 0
                  : deleteCount
              );
              setShow(deleteCountRef.current > 0 ? show : !show);
            }}
            onToggle={async (e) => {}}
          >
            <Dropdown.Toggle
              as={"a"}
              id="alertsDropdown1"
              className={"nav-link"}
              name="dd"
            >
              <img
                src={notificationImg}
                className="Notification"
                alt=""
                name="dd_image"
              />
              {notificationCount}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right shadow">
              <Dropdown.Header className="d-flex">
                <h5>Notification</h5>
                <div onClick={clearAllNotifications} className="clear-all">
                  (Clear All)
                </div>
              </Dropdown.Header>
              <div className="notifyBox">
                {notifications.length === 0 ? (
                  <div
                    className="nonotify"
                    style={{ textAlign: "center", marginTop: "-10px" }}
                  >
                    <div
                      style={{
                        height: "130px",
                        overflow: "hidden",
                        zIndex: "-1",
                      }}
                    >
                      <img
                        style={{ alignItems: "center" }}
                        src={noData}
                        alt=""
                      />
                    </div>
                    <h4>No new notification found!</h4>
                  </div>
                ) : (
                  <div>
                    {notifications.map((notification) => (
                      <div
                        key={notification.id}
                        onClick={() => notificationRead(notification)}
                        className={
                          notification.isRead
                            ? "d-flex align-items-center notification_row cursor-pointer  read"
                            : "d-flex align-items-center notification_row cursor-pointer unread"
                        }
                      >
                        <div className="user_pic">
                          {notification?.notificationImage ? (
                            <img
                              src={
                                notification?.notificationImage
                                  ? notification?.notificationImage.startsWith(
                                      "https://"
                                    )
                                    ? notification?.notificationImage
                                    : `${AppConfig.FILES_ENDPOINT}${notification?.notificationImage}`
                                  : userPic
                              }
                            />
                          ) : (
                            <>{getIcon(notification.notificationType)}</>
                          )}
                        </div>
                        <div style={{ marginLeft: "11px" }}>
                          <p>{notification.title}</p>
                          <h6>
                            {moment(notification.createdAt).format(
                              `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
                            )}
                          </h6>
                        </div>
                        <span
                          className="closeBtn_x"
                          onClick={() => {
                            setDeleteCount(++deleteCount);
                            deleteNotification(notification.id);
                          }}
                        >
                          &#215;
                        </span>
                      </div>
                    ))}

                    {/* <Button variant="" className="viewMore">
                      View More
                    </Button> */}
                  </div>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li>

        <li className="nav-item">
          <Dropdown className={"no-arrow"}>
            <Dropdown.Toggle
              as={"a"}
              id="dropdown-basic"
              className={"nav-link"}
            >
              <img
                className="mr-3 img-profile rounded-square"
                src={
                  profileData?.profile_picture
                    ? profileData?.profile_picture.startsWith("https://")
                      ? profileData?.profile_picture
                      : `${AppConfig.FILES_ENDPOINT}${profileData?.profile_picture}`
                    : avatarImg
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = avatarImg;
                }}
                alt=""
              />
              <span className="d-none d-lg-inline user-name-logout">
                {[profileData?.first_name, profileData?.last_name]
                  .filter(Boolean)
                  .join(" ") || "-"}
              </span>

              <img
                src={downArrow}
                className="arrow-down cursor-pointer"
                alt=""
              />
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right shadow">
              <Link
                to={AppRoutes.SETTING}
                style={{ color: "unset", textDecoration: "none", padding: 0 }}
              >
                <Dropdown.Item as="div">
                  <i className="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </Dropdown.Item>
              </Link>
              <Dropdown.Divider />
              <Dropdown.Item as="div" onClick={Logout}>
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </nav>
  );
};
export default withRouter(Header);
