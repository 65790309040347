import React from "react";
import moment from "moment";
import { AppConfig, DB_ACCPTABLE_FORMAT } from "../../../config";
import dummyclient from "../../../assets/img/noimage.jpeg";
import listIcon from "../../../assets/img/list.svg";
import phoneIcon from "../../../assets/img/phone.svg";

const StaffRow = ({ staffData, viewHandler }) => {
  const {
    first_name = "",
    last_name = "",
    employee_id = "",
    email = "",
    phone = "",
    status = "",
    profile_picture = "",
    joining_date = "",
    roleName = "",
  } = staffData;
  let dateDifference = moment(new Date(), DB_ACCPTABLE_FORMAT).diff(
    moment(joining_date, DB_ACCPTABLE_FORMAT),
    "days"
  );

  const renderStatus = () => {
    switch (status) {
      case "Active":
        return <span className="success-span">Active</span>;
      case "Incomplete":
        return <span className="warning-span">Incomplete</span>;
      case "Suspended":
        return <span className="danger-span">Suspended</span>;
      case "Terminated":
        return <span className="secondary-span">Terminated</span>;
      default:
        return <span className="success-span">Active</span>;
    }
  };

  return (
    <tr>
      <td
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        {profile_picture ? (
          <img
            className="list-view-img-staff"
            src={
              profile_picture
                ? profile_picture.startsWith("https://")
                  ? profile_picture
                  : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                : dummyclient
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = dummyclient;
            }}
            alt=""
          />
        ) : (
          <div
            className="img_placeholder"
            style={{
              fontSize: "14px",
              marginLeft: "0",
              marginRight: "10px",
              width: "45px",
              height: "45px",
            }}
          >
            {first_name ? first_name.substring(0, 1) : ""}
            {last_name ? last_name.substring(0, 1) : ""}
          </div>
        )}
        {[first_name, last_name].filter(Boolean).join(" ") || "N/A"}{" "}
        {dateDifference < 15 ? <span className="new-span">New</span> : null}
      </td>
      <td> {employee_id || "N/A"} </td>
      <td>{roleName || "N/A"}</td>
      <td>
        {" "}
        {email ? (
          <a className="mailto" href={`mailto:${email}`}>
            {email}
          </a>
        ) : (
          "N/A"
        )}{" "}
      </td>
      {/* <td> {phone || "-"} </td> */}
      <td> {renderStatus()} </td>
      <td>
        <button onClick={viewHandler} className="btn btn-secondary th-btn">
          {" "}
          <img src={listIcon} alt="" /> View{" "}
        </button>
        {/* <button type='button' className='btn btn-secondary th-btn'>
          {' '}
          <img src={listIcon} alt='' /> View{' '}
        </button> */}
        <button
          className="btn btn-secondary th-btn"
          disabled={!phone}
          onClick={() => window.open(`tel:${phone}`)}
        >
          {" "}
          <img src={phoneIcon} alt="" /> Contact{" "}
        </button>
      </td>
    </tr>
  );
};

export default StaffRow;
