import React from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../../../config";
import listIcon from "../../../assets/img/list.svg";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import { Button } from "react-bootstrap";

export const ScanRuleRow = ({
  scanData,
  userColumn = true,
  batchViewHandler,
  userViewHandler,
  onPrint,
  onEdit,
  onDelete,
}) => {
  const { alertType, country, createdBy, name, noOfUsers, sendAlert, _id } =
    scanData;

  return (
    <tr>
      <td>{name}</td>
      <td className={"cursor-pointer"} style={{ color: "#0A1F44" }}>
        {noOfUsers}
      </td>
      <td className={"cursor-pointer severity_td"}>
        {alertType == "mild" ? (
          <p className="severity_status mild">{alertType}</p>
        ) : (
          <p className="severity_status potential">{alertType}</p>
        )}
      </td>
      <td className="pname_scan">{sendAlert}</td>
      <td className="ploc_scan">{country}</td>
      <td className={"cursor-pointer"}>
        <div
          className="d-flex align-items-start"
          style={{
            width: "100%",
          }}
        >
          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={() => onEdit(_id)}
          >
            <span>
              <img src={editIcon} alt="" className={"img-main"} />
              <img src={editIconWhite} alt="" className={"img-hover"} />
            </span>
            Edit
          </Button>
          <Button
            variant="danger"
            className="btn th-btn delete-tb action-block-btn"
            onClick={onDelete}
          >
            <span>
              <img src={trashIcon} alt="" className={"img-main"} />
              <img src={trashIconWhite} alt="" className={"img-hover"} />
            </span>
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};
