import React from "react";
import { InputTextField, SubmitButton } from "../forms";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../config";
import { BatchInputLoader } from "../loader/Resusable/BatchInput";
import { ApiHelper } from "../../../helpers";
import ReactQuill from "react-quill";

export const AddTopicFormComponent = ({
  loadOriginOptions,
  handleSubmit,
  onChange,
  errors,
  name,
  planType,
  onSelect,
  price,
  email,
  description,
  users,
  printCodes,
  domainName,
  BrandAndCertificate,
  isEditMode,
  isAdding,
  isLoading,
  clientList,
  clientId,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
  };
  let allModule = [];
  if (clientList) {
    clientList.forEach((element) => {
      allModule.push({ label: element.name, value: element._id });
    });
  }
  const loadClient = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allModule.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };
  const planOptions = [
    { value: "Contact", label: "Contact" },
    { value: "Paid", label: "Paid" },
    { value: "Custom", label: "Custom" },
  ];
  const brandOptions = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];

  return (
    <div style={{ padding: "14px 21px" }}>
      {isLoading ? (
        <BatchInputLoader />
      ) : (
        <form
          className="user_add m-0 addPlanform product_add"
          onSubmit={handleSubmit}
        >
          <div className="second-rows">
            <div className="col-xl-12">
              <div className="form-group">
                <label className={`form-control-label`}>Module</label>
                <AsyncSelect
                  loadOptions={loadClient}
                  placeholder={"Select"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={allModule}
                  value={planType}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "planType")
                  }
                  noOptionsMessage={() =>
                    "No module found related to your search"
                  }
                />
                <div className={"text-danger"}>
                  {errors && errors.moduleId ? errors.moduleId : ""}
                </div>
              </div>
            </div>

            <div className="col-xl-12 input-rightside">
              <InputTextField
                id="topic"
                label={"Topic"}
                className={"form-control-user"}
                name="name"
                value={name}
                onChange={onChange}
                errorMessage={errors && errors.topic ? errors.topic : ""}
                maxLength={30}
                placeholder={"Enter topic here"}
              />
            </div>
          </div>
          <div className="text-area">
            <div className="form-group">
              <label className="text-area-label">Description</label>
              <ReactQuill
                value={description}
                onChange={(value) => {
                  onChange({
                    target: {
                      type: "textarea",
                      value,
                      name: "description",
                      checked: false,
                    },
                  });
                }}
                placeholder="Type something here..."
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{ color: [] }],
                  ],
                }}
              />
              <div className={"text-danger"}>
                {errors && errors.description ? errors.description : ""}
              </div>
            </div>
          </div>

          <div
            className="buttons-container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Link
              to={"/cms/Web/account-management/"}
              className="btn btn-primary cancel-btn"
            >
              CANCEL
            </Link>

            {/* <button className="btn btn-primary submit-btn"> ADD</button> */}

            <SubmitButton
              className={"submit-btn"}
              text={isEditMode ? "UPDATE" : "ADD"}
              isLoading={isAdding}
              onSubmit={handleSubmit}
            />
          </div>
        </form>
      )}
    </div>
  );
};
