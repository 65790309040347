/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import Pagination from "../../components/pagination";
import debounce from "lodash.debounce";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { ListComponent } from "../../components/counterfeit";
import { SearchFilter } from "../../components/filters/searchFilter";
import { FilterComponent } from "../../components/filters";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import searchMobIcon from "../../../assets/img/tag-search-bd.svg";
import filterMobIcon from "../../../assets/img/tag-bd.svg";
import PHLContext from "../../../PHLContext";
import "./counterfeit.css";
import AsyncSelect from "react-select/async";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};
class Counterfeit extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      counterfeits: [],
      staffList: [],
      clientList: [],
      isLoading: false,
      isUpdating: false,
      limit: 10,
      totalRecords: 0,
      currentPage: 1,
      activeId: "",
      search: "",
      sortByStaff: "all",
      sortByClient: "all",
      sortByStatus: "all",
      sortBy: "created_at",
      order: "desc",
      isFilterApplied: false,
      dropdownOpen: false,
      dropdownId: "",
      clientName: { label: "All", value: "all" },
      assignedTo: "all",
      assignedTo: "all",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    let { socket } = this.context;
    if (socket) {
      socket.on(SocketEvents.NEW_COUNTERFEIT_ADDED, (data) => {
        let newData = [...this.state.counterfeits];
        const alreadyExistIndex = newData.findIndex(
          (item) => item._id === data[0]._id
        );
        if (alreadyExistIndex > -1) {
          newData.splice(alreadyExistIndex, 1);
          newData.unshift(...data);
        } else {
          newData.splice(-1, 1);
          newData.unshift(...data);
        }
        console.log("event emitted", data, newData);
        this.setState({ counterfeits: newData });
      });
    }
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    console.log(query);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "created_at",
        order: query.order ? query.order : "desc",
        search: query.search || "",
        sortByStatus: query.status || "all",
        isFilterApplied: query.status || query.search ? true : false,
        assignedTo: query.assignedTo || "all",
        clientName: query.clientName
          ? { label: "Client", value: query.clientName }
          : {
              label: "All",
              value: "all",
            },
        assignedTo: query.assignedTo || "all",
      },
      () => {
        this.fetchActiveStaffList();
        this.fetchClientList();
        this.fetchCounterfeitRecords();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchCounterfeitRecords = async () => {
    const {
      currentPage,
      limit,
      sortByStatus,
      search,
      order,
      sortBy,
      assignedTo,
    } = this.state;
    let skip = limit * (currentPage - 1);
    let clientName = this.state.clientName.value;
    let data = {
      skip,
      limit,
      order,
      sortBy,
      sortByStatus,
      search,
      assignedTo,
      assignedTo,
      clientName,
    };
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.COUNTERFEIT_LIST.service,
      ApiRoutes.COUNTERFEIT_LIST.url,
      ApiRoutes.COUNTERFEIT_LIST.method,
      ApiRoutes.COUNTERFEIT_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { counterfeits, totalRecords },
      } = response.data;
      this.setState({ counterfeits, totalRecords });
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };
  loadClientOptions = async (inputValue, callback) => {
    //new ApiHelper().cancelRequest("cancel");
    //const result = await this.fetchProductsOption(inputValue);
    const result = this.state.clientList.filter((p) =>
      p.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(result);
  };

  setFilter = (sortBy, name) => {
    console.log("sortBy", sortBy);
    console.log("name", name);
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    console.log(params);
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };

  setSortFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  onToggle = (id) => {
    this.setState({
      dropdownOpen: true,
      dropdownId: id,
    });
  };

  viewHandler = (id) => {
    const {
      location: { search },
    } = this.props;
    this.props.history.push({
      pathname: AppRoutes.COUNTERFEIT_VIEW.replace(":id", id),
      state: {
        search,
      },
    });
  };
  fetchActiveStaffList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF_OPTION_LIST.service,
      ApiRoutes.GET_STAFF_OPTION_LIST.url,
      ApiRoutes.GET_STAFF_OPTION_LIST.method,
      ApiRoutes.GET_STAFF_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        staffList: [
          {
            label: "All",
            value: "all",
          },
          ...data,
        ],
      });
    }
  };
  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      let { data } = response.data;
      data = response.data.data.filter((item) => {
        return item.label.trim() !== "";
      });
      const {
        location: { search },
      } = this.props;
      const query = qs.parse(search);
      let result = data.find((item) => item.value === query.clientName);
      query.clientName
        ? this.setState({
            clientName: result,
          })
        : this.setState({
            clientName: {
              label: "All",
              value: "all",
            },
          });
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "PHL",
            value: "PHL",
          },
          ...data,
        ],
      });
    }
  };
  changeRequestRequest = async (status = "Acknowledged", _id) => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.service,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.url.replace(":id", _id),
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.method,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.fetchCounterfeitRecords();
    }
  };

  onSelect = (options, name) => {
    this.setState({
      [name]: options,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (options) {
      params["clientName"] = options.value;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  render() {
    const {
      counterfeits,
      staffList,
      clientList,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      search,
      order,
      sortBy,
      sortByStaff,
      sortByClient,
      sortByStatus,
      dropdownOpen,
      dropdownId,
      assignedTo,
      clientName,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];

    const filtersOptionStatus = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Completed",
        value: "Completed",
      },
      {
        label: "Acknowledged",
        value: "Acknowledged",
      },
      {
        label: "In Process",
        value: "In Process",
      },
      {
        label: "Reopened",
        value: "Reopened",
      },
      {
        label: "Cancelled",
        value: "Cancelled",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Counterfeit</h1>
        <Breadcrumbs pathname={pathname} />
        {/* <p className='breadcrums-top'>
          Manage Clarity{' '}
          <img src={arrowIcon} className='breadcrum-seperator' alt='' />
          Counterfeit
        </p> */}

        <div className="card card-pagination card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3">
                <h6 className="font-book ">Request Status</h6>
              </div>

              <div className="col-md-10 mob-filter-data">
                <div className="dropdown no-arrow dp-right">
                  <button
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    // data-toggle='dropdown'
                    // aria-haspopup='true'
                    // aria-expanded='false'
                    onClick={() => this.onToggle("main")}
                  >
                    <img src={filterMobIcon} alt="" />
                  </button>
                  <div
                    className={`dropdown-menu shadow dropdown-menu-right dropdown-opt-mob mob-drp ${
                      dropdownOpen ? "show" : ""
                    }`}
                    aria-labelledby="statusMenuLink"
                  >
                    <a
                      className="dropdown-item filer-mob-a"
                      onClick={() => this.onToggle("staff")}
                    >
                      Staff <span className="staff-value-mob">All</span>{" "}
                      <img src="img/arrow-right.svg" alt="" />
                    </a>
                    <div
                      className={`submenu dropdown-menu dropdown-menu-right-mob dropdown-staff-mob ${
                        dropdownId === "staff" ? "show" : ""
                      }`}
                    >
                      {staffList.map(({ label, value, order }, index) => (
                        <a
                          className="dropdown-item"
                          onClick={() => this.setFilter(value, "assignedTo")}
                        >
                          {label}
                        </a>
                      ))}
                    </div>
                    <a
                      className="dropdown-item filer-mob-a"
                      onClick={() => this.onToggle("client")}
                    >
                      Client <span className="client-value-mob">All</span>{" "}
                      <img src="img/arrow-right.svg" alt="" />
                    </a>
                    <div
                      className={`submenu dropdown-menu dropdown-menu-right-mob dropdown-client-mob ${
                        dropdownId === "client" ? "show" : ""
                      }`}
                    >
                      {clientList.map(({ label, value, order }, index) => (
                        <a
                          className="dropdown-item"
                          onClick={() => this.setFilter(value, "assignedTo")}
                        >
                          {label}
                        </a>
                      ))}
                    </div>

                    <a
                      className="dropdown-item filer-mob-a"
                      href="#"
                      onClick={() => this.onToggle("status")}
                    >
                      Status{" "}
                      <span className="status-value-mob">
                        {
                          (
                            filtersOptionStatus.filter(
                              (filter) => filter.value === sortByStatus
                            )[0] || {}
                          ).label
                        }
                      </span>{" "}
                      <img src="img/arrow-right.svg" alt="" />
                    </a>
                    <div
                      className={`submenu dropdown-menu dropdown-menu-right-mob dropdown-status-mob ${
                        dropdownId === "status" ? "show" : ""
                      }`}
                    >
                      {filtersOptionStatus.map(
                        ({ label, value, order }, index) => (
                          <a
                            className="dropdown-item"
                            onClick={() => this.setFilter(value, "status")}
                          >
                            {label}
                          </a>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="dropdown no-arrow dp-right">
                  <button
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={searchMobIcon} alt="" />
                  </button>
                </div>
              </div>

              <div className="col-md-9">
                <div className="custom_header_filters">
                  <SearchFilter
                    placeholder={"Search by Scan ID, Batch Num, Product Name…"}
                    search={search}
                    onChange={this.handleChange}
                    onSearch={this.onSearch}
                  />
                  <div
                    className=""
                    style={{
                      zIndex: "111111",
                      maxWidth: "170px",
                      minWidth: "170px",
                      marginRight: "8px",
                    }}
                  >
                    <AsyncSelect
                      loadOptions={this.loadClientOptions}
                      placeholder={"Select Client"}
                      maxMenuHeight={200}
                      styles={customStyles}
                      defaultOptions={clientList}
                      value={clientName}
                      onChange={(selectedOption) =>
                        this.onSelect(selectedOption, "clientName")
                      }
                      noOptionsMessage={() => "No Option"}
                    />
                  </div>
                  <FilterComponent
                    label={"Status"}
                    options={filtersOptionStatus}
                    selectedFilter={sortByStatus}
                    onFilterChange={(selectedValue) =>
                      this.setFilter(selectedValue, "status")
                    }
                  />
                  {/*<FilterComponent
                  label={"Client"}
                  options={clientList}
                  selectedFilter={clientName}
                  onFilterChange={(selectedValue) =>
                    this.setFilter(selectedValue, "clientName")
                  }
                />*/}
                  <FilterComponent
                    label={"Staff"}
                    options={staffList}
                    selectedFilter={assignedTo}
                    onFilterChange={(selectedValue) =>
                      this.setFilter(selectedValue, "assignedTo")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <ListComponent
            counterfeits={counterfeits}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            totalRecords={totalRecords}
            limit={limit}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            viewHandler={this.viewHandler}
            setFilter={this.setSortFilter}
            order={order}
            sortBy={sortBy}
            changeRequestRequest={this.changeRequestRequest}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Counterfeit;
