import React from "react";
import UserRow from "./UserRow";
import { TableLoader } from "../../components/loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";
import "./user.css";

export const ListComponent = ({
  users,
  isFilterApplied,
  isLoading,
  isUpdating,
  activeId,
  viewHandler,
  blockUser,
  unBlockUser,
  setFilter,
  sortBy,
  order,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-sm">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th>
                  Name
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "name" ? "active-ac" : ""
                      }`}
                      onClick={() => setFilter("name", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "name" ? "active-ac" : ""
                      }`}
                      onClick={() => setFilter("name", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Email
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "email" ? "active-ac" : ""
                      }`}
                      onClick={() => setFilter("email", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "email"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("email", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Scan
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "scans" ? "active-ac" : ""
                      }`}
                      onClick={() => setFilter("scans", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "scans"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("scans", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Counterfeits
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "counterfeits"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("counterfeits", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "counterfeits"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("counterfeits", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Created At
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "created_at"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("created_at", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "created_at"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("created_at", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {users && users.length ? (
                users.map((e, index) => (
                  <UserRow
                    key={index}
                    userData={e}
                    isUpdating={isUpdating && activeId === e.id}
                    viewUser={() => viewHandler(e.id)}
                    blockUser={() => blockUser(e.id)}
                    unBlockUser={() => unBlockUser(e.id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={8} className={"text-center image-no-data-sm"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
