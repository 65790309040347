import React from "react";
import Skeleton from "react-skeleton-loader";

export const ViewInputLoader = () => (
  <>
    {" "}
    <form className='user_add'>
            <div className='row'>
              <div className='col-md-3 mob-show'>
                <div className='img-col-user'>
                <Skeleton color ="#e5e9ef" height={"150px"} width={"100px"}/>
                </div>
              
              </div>
              <div className='col-md-9'>
                <div className='row'>
                  <div className='col-md-4 skeleton-bottom' >
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"25px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom '>
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                  <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                </div>
              </div>
              <div className='col-md-3 mob-hide'>
                <div className='img-col-user'>
                <Skeleton color ="#e5e9ef" height={"150px"} width={"100%"}/>
                </div>
               
              </div>

              
            </div>
          </form>
  </>
);
