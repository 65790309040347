import React from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, AppRoutes } from "../../../config";
import { FilterComponent } from "../../components/filters";

const filtersOption = [
  {
    label: "Edit",
    value: "edit",
  },
  {
    label: "Delete",
    value: "delete",
  },
];

export const ListCategoryComponent = ({
  statusText,
  categories,
  deleteCategory,
  history,
  isUpdating,
  activeId,
  setFilter,
  ...rest
}) => {
  const {
    location: { search },
  } = rest;

  return categories.map((catData) => {
    const {
      id,
      cat_name = "-",
      total_videos = 0,
      created_at,
      publishedCount,
      unpublishedCount,
    } = catData;
    return (
      <div
        className="col-md-3 col-vd"
        key={id}
      >
        <div
          className={`cat_head Manage_Video_Category ${
            publishedCount === 0 ? "is_published" : ""
          }`}
        >
          {/** << add ".active" class here according  to condition**/}
          <h4 className="head_name">Category Name</h4>
          <FilterComponent
            options={filtersOption}
            // selectedFilter={sortBy}
            onFilterChange={(event) => {
              if (event === "edit") {
                history.push(`${AppRoutes.MANAGE_VIDEO.replace(":id", id)}`);
              }
              if (event === "delete") {
                deleteCategory(id, cat_name);
              }
            }}
            label={"⋮"}
            showImage={false}
          />
          <div className="d-flex mt-2">
            <h4
              className="head_description cat-desc-head cursor-pointer"
              onClick={() =>
                history.push(`${AppRoutes.MANAGE_VIDEO.replace(":id", id)}`)
              }
            >
              {cat_name}
            </h4>
            {publishedCount === 0 ? (
              <span className="badge_one">Unpublished</span>
            ) : (
              ""
            )}
          </div>

          <div className="cat_desc" onClick={() =>
          history.push(`${AppRoutes.MANAGE_VIDEO.replace(":id", id)}`)
        }>
            <div className="num_head">
              <h4 className="head_name">Total</h4>
              <h4 className="head_description">{total_videos} Videos</h4>

              <h4 className="head_name published">Published</h4>
              <h4 className="head_description">{publishedCount}</h4>
              {/* <Link
                to={{
                  pathname: `${AppRoutes.MANAGE_VIDEO.replace(':id', id)}`,
                  state: {
                    search,
                  },
                }}
                className='btn act-btn'
              >
                {' '}
                <img src={listIcon} alt='' /> View{' '}
              </Link> */}
            </div>
            <div className="date_head">
              <h4 className="head_name rt-mg">Created At</h4>
              <h4 className="head_description rt-mg">
                {created_at
                  ? moment(created_at).format(DEFAULT_DATE_FORMAT)
                  : "-"}
              </h4>
              <h4 className="head_name rt-mg unpublished">Unpublished</h4>
              <h4 className="head_description rt-mg">{unpublishedCount}</h4>
              {/* <button
                className='btn act-btn'
                disabled={total_videos || (isUpdating && activeId === id)}
                onClick={() => deleteCategory(id, cat_name)}
              >
                {' '}
                {isUpdating && activeId === id ? (
                  <i className='fa fa-spinner fa-spin'></i>
                ) : (
                  <img src={deleteIcon} alt='' />
                )}{' '}
                Delete{' '}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  });
};
