/** @format */

import React, { Component } from 'react';
import * as qs from 'query-string';
import { toast } from 'react-toastify';
import { ApiHelper } from '../../../helpers';
import { ApiRoutes, genderOptions } from '../../../config';
import { message } from '../../../common';
import SweetAlertComponent from '../../components/sweetAlertComponent';
import { BatchViewLoader } from '../../components/loader/Resusable/BatchView';
import { InvitationFormComponent } from '../../components/staffs/InvitationFormComponent';
import whiteLogo from '../../../assets/img/logo-white.webp';
import './invitation-staffs.css';
import { addStaffValidator } from '../../../validators';

class StaffInvitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: '',
      staffData: {},
      isVerified: false,
      isSubmitted: false,
      isLoading: true,
      isSubmitting: false,
      errorMessage: '',
    };
  }
  componentDidMount = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    this.verifyInvitationLink(query.user);
  };
  verifyInvitationLink = async (encryptedUserId) => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_STAFF_INVITATION.service,
      ApiRoutes.VERIFY_STAFF_INVITATION.url,
      ApiRoutes.VERIFY_STAFF_INVITATION.method,
      ApiRoutes.VERIFY_STAFF_INVITATION.authenticate,
      undefined,
      { userId: encryptedUserId }
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      this.setState({
        errorMessage: response.messages[0],
      });
    } else {
      const { data } = response.data;
      const {
        id = '',
        first_name = '',
        last_name = '',
        employee_id = '',
        email = '',
        personal_email = '',
        phone = '',
        office_phone = '',
        profile_picture,
        other_info = {},
        birth_date = '',
        office_location = '',
        gender = '',
      } = data || {};
      const {
        address1 = '',
        address2 = '',
        state = '',
        country = '',
        postCode = '',
      } = other_info || {};
      this.setState({
        staffId: id,
        staffData: {
          first_name,
          last_name,
          employee_id,
          email,
          personal_email,
          phone,
          office_phone,
          profile_picture,
          gender: gender ? genderOptions.filter((e) => e.value === gender) : '',
          address1,
          address2,
          state,
          country,
          postCode,
          birth_date: birth_date ? new Date(birth_date) : null,
          office_location,
        },
        isVerified: true,
      });
    }
  };
  submitRequest = async () => {
    const { staffId, staffData } = this.state;
    this.setState({
      isSubmitting: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUBMIT_STAFF_FORM.service,
      ApiRoutes.SUBMIT_STAFF_FORM.url.replace(':id', staffId),
      ApiRoutes.SUBMIT_STAFF_FORM.method,
      ApiRoutes.SUBMIT_STAFF_FORM.authenticate,
      undefined,
      {
        ...staffData,
        gender: staffData.gender ? staffData.gender.value : '',
      }
    );
    this.setState({
      isSubmitting: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isSubmitted: true,
      });
    }
  };
  handleInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      staffData: {
        ...this.state.staffData,
        [name]: value,
        errors: {
          ...this.state.staffData.errors,
          [name]: '',
        },
      },
    });
  };
  onSelect = (selectedOption) => {
    this.setState({
      staffData: {
        ...this.state.staffData,
        gender: selectedOption,
      },
    });
  };
  handleDateChange = (date) => {
    this.setState({
      staffData: {
        ...this.state.staffData,
        birth_date: date,
      },
    });
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match('image.*')) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          errors: {
            ...this.state.staffData.errors,
            [name]: message.InvalidImages,
          },
        },
      });
      return;
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        errors: {
          ...this.state.staffData.errors,
          [name]: '',
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = '';
  };
  onDeleteFile = () => {
    this.setState({
      staffData: {
        ...this.state.staffData,
        profile_picture: '',
        errors: {
          ...this.state.staffData.errors,
          profile_picture: '',
        },
      },
    });
  };
  uploadPicture = async (data, name) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      this.setState({
        staffData: { ...this.state.staffData, [name]: path },
      });
    }
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
    });
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const { staffId, staffData } = this.state;

    const data = {
      name: staffData.first_name,
      email: staffData.email,
      personal_email: staffData.personal_email ? staffData.personal_email : '',
      phone: staffData.phone ? staffData.phone : '',
      office_phone: staffData.office_phone ? staffData.office_phone : '',
    };
    const { isValid, errors } = addStaffValidator(data);
    if (isValid) {
      const { value } = await SweetAlertComponent({
        // title: 'Are you sure?',
        text: 'Are you sure, You want to submit the details.',
      });
      if (!value) {
        return;
      }
      this.submitRequest();
    } else {
      this.setState({
        staffData: {
          ...staffData,
          errors,
        },
      });
      return;
    }
  };
  render() {
    const {
      isLoading,
      isSubmitting,
      isSubmitted,
      isVerified,
      isUploading,
      uploadPercentage,
      staffData,
      errorMessage,
    } = this.state;

    return (
      <div className="container-fluid-invitaion">
        <div className="row login-row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header center-header">
                <img className="center-logo" src={whiteLogo} alt="" />
              </div>
              {isLoading ? (
                <BatchViewLoader />
              ) : isSubmitted ? (
                <div className={'client-invitation-form'}>
                  <div className={'message'}>
                    Your request has been submitted successfully.
                  </div>
                </div>
              ) : isVerified ? (
                <>
                  <div className="card-body card-container-body">
                    <div className="row mt-4">
                      <InvitationFormComponent
                        staffData={staffData}
                        isUploading={isUploading}
                        uploadPercentage={uploadPercentage}
                        onChange={this.handleInfoChange}
                        onDateChange={this.handleDateChange}
                        onSelect={this.onSelect}
                        onSubmit={this.submitDetails}
                        onFileChange={this.onFileChange}
                        onDeleteFile={this.onDeleteFile}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 footer-form">
                    <button
                      className="btn submit-btn"
                      onClick={this.handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <i className="fa fa-spinner fa-spin test-spin"></i>{' '}
                          SUBMIT
                        </>
                      ) : (
                        'SUBMIT'
                      )}
                    </button>
                  </div>
                </>
              ) : (
                <div className={'client-invitation-form'}>
                  <div className={'message'}>{errorMessage}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffInvitation;
