/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { RequestDetails } from "./RequestDetails";
import { UserDetails } from "./UserDetails";
import { ProductDetails } from "./ProductDetails";
import { AssigneeDetails } from "./AssigneeDetails";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { CounterfeitClient } from "../../components/loader/Resusable/CounterfeitClient";
import "./view-counterfeit.css";

class ViewCounterfeit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conterfeitId: "",
      counterfeit: {},
      isLoading: false,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ conterfeitId: id }, () => this.viewCounterfeitRequest());
    }
  };

  viewCounterfeitRequest = async (loading = true) => {
    const { conterfeitId } = this.state;
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_COUNTERFEIT_DETAIL.service,
      ApiRoutes.VIEW_COUNTERFEIT_DETAIL.url.replace(":id", conterfeitId),
      ApiRoutes.VIEW_COUNTERFEIT_DETAIL.method,
      ApiRoutes.VIEW_COUNTERFEIT_DETAIL.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { counterfeit },
      } = response.data;
      this.setState({
        counterfeit,
      });
    }
  };

  changeRequestRequest = async (status = "Acknowledged") => {
    const { conterfeitId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.service,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.url.replace(
        ":id",
        conterfeitId
      ),
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.method,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.viewCounterfeitRequest();
    }
  };

  mapHandler = (lat, long, storeLocation) => {
    const { conterfeitId } = this.state;
    this.props.history.push(
      `${AppRoutes.COUNTERFEIT_MAP_VIEW.replace(
        ":id",
        conterfeitId
      )}?lat=${lat}&long=${long}`,
      { storeLocation }
      //   {
      //   pathname: `${AppRoutes.COUNTERFEIT_MAP_VIEW.replace(
      //     ':id',
      //     conterfeitId,
      //   )}?lat=${lat}&long=${long}`,
      //   state: { name: 'Aayushi' },
      // }
    );
  };

  render() {
    const { isLoading, counterfeit, conterfeitId } = this.state;
    const {
      assignee = {},
      batch = {},
      product = {},
      user: userDetails = {},
      status = "",
      dateOfAction = "",
    } = counterfeit || {};
    const productDetails = product;
    // isManual
    //   ? { productId }
    //   : batchUnitId && batchUnitId.batchId
    //   ? batchUnitId.batchId
    //   : {};
    const {
      props: {
        location: { pathname, state },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs pathname={pathname} search={state ? state.search : ""} />
        <div className="row">
          <div className="col-md-6 d-flex">
            <ProductDetails
              productDetails={productDetails}
              batchDetails={batch}
              isLoading={isLoading}
            />
          </div>
          <div className="col-md-6 d-flex">
            <UserDetails
              userDetails={userDetails}
              isLoading={isLoading}
              mapHandler={this.mapHandler}
            />
          </div>
          <div className="col-md-12 d-flex">
            <RequestDetails
              requestDetails={counterfeit}
              mapHandler={this.mapHandler}
              isLoading={isLoading}
            />
          </div>
          {isLoading ? (
            <CounterfeitClient />
          ) : (
            <AssigneeDetails
              assigneeDetails={assignee}
              status={status}
              dateOfAction={dateOfAction}
              conterfeitId={conterfeitId}
              viewCounterfeitRequest={this.viewCounterfeitRequest}
              changeRequestRequest={this.changeRequestRequest}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ViewCounterfeit;
