import React from "react";
import { TableLoader } from "../../../components/loader/Resusable/Table"; //"../loader/Resusable/Table";
import noData from "../../../../assets/img/no-data.png";
import { SalesRow } from "./salesRow";

export const SalesListComponent = ({
  salesDetail,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
  fetchSalesRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  viewUser,
  handleUpdateStatus,
  onExpand,
}) => {
  console.log(salesDetail, ":::details sales");
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <>
            <table width="100%" cellSpacing="0">
              <thead className="pad-top">
                <tr>
                  <th style={{ Width: "350px" }}>
                    Request ID{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "request_id"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("request_id", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "request_id"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("request_id", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>
                  <th style={{ Width: "420px" }}>
                    Requester name{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "name"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("name", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "name"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("name", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>
                  <th>
                    Subject{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "details"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("details", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "details"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("details", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>
                  <th>
                    Assignee{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "assignee"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("assignee", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "assignee"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("assignee", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>

                  <th>
                    Status{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "status"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("status", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "status"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("status", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {salesDetail && salesDetail.length ? (
                  salesDetail.map((IndividualSalesDetail, index) => (
                    <SalesRow
                      key={index}
                      IndividualSalesDetail={IndividualSalesDetail}
                      fetchSalesRecords={fetchSalesRecords}
                      onEdit={() => onEdit(IndividualSalesDetail)}
                      onDelete={() => onDelete(IndividualSalesDetail._id)}
                      viewUser={() => viewUser(IndividualSalesDetail)}
                      handleUpdateStatus={(status, val) => {
                        console.log("llooooooo");
                        handleUpdateStatus(status, val);
                      }}
                      onExpand={() => onExpand(index)}
                    />
                  ))
                ) : (
                  <tr className="no-tr">
                    <td colSpan={5} className={"text-center image-no-data-xs"}>
                      {isFilterApplied ? (
                        <img src={noData} alt="" />
                      ) : (
                        <img src={noData} alt="" />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
