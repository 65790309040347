import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const MapLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>View</h1>
      <p className='breadcrums-top'>
        <Skeleton color='#fff' />
      </p>
      <div className='card mb-4 card-main'>
        <div className='card-header py-7550 align-items-center justify-content-between'>
          <div className='row'>
            <div className='col-md-12 head-col'>
              <h6 className='font-book'>Map</h6>
            </div>
            <div className='col-md-12'>
              <Skeleton color='#e5e9ef' height={'250px'} width={'100%'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
