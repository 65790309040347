import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import Switch from "react-switch";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import listIcon from "../../../assets/img/list.svg";

import SweetAlertComponent from "../../components/sweetAlertComponent";

export const AboutUsRow = ({
  IndividualAboutusDetail,
  onEdit,
  onDelete,
  fetchAboutUsRecords,
  viewUser,
  onExpand = function () {},
}) => {
  const { isExpand = false } = IndividualAboutusDetail;
  const [aboutUsEdit, setAboutUsEdit] = useState(IndividualAboutusDetail);
  // const aboutUsEdit = IndividualAboutusDetail;
  const changeStatus = async (data) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.UPDATE_ABOUT_US_DETAIL_NEW.service,
      ApiRoutes.UPDATE_ABOUT_US_DETAIL_NEW.url.replace(":id", aboutUsEdit?._id),
      ApiRoutes.UPDATE_ABOUT_US_DETAIL_NEW.method,
      ApiRoutes.UPDATE_ABOUT_US_DETAIL_NEW.authenticate,
      undefined,
      { ...data, id: aboutUsEdit?._id },
      undefined
    );
    console.log("response", response.data.data);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.data?.message);
      // fetchAboutUsRecords();
    }
  };

  // console.log("aboutUsEdit", aboutUsEdit?.status);
  const handleChange = async (event) => {
    // console.log("event", event.target.checked);
    const {
      target: { checked },
    } = event;

    const { value } = await SweetAlertComponent({
      title: "",
      text: `Are you sure, you want to ${
        checked ? "enable" : "disable"
      }  Detail?`,
    });
    if (!value) {
      return;
    }
    setAboutUsEdit({
      ...aboutUsEdit,
      isActive: checked,
    });
    let data = {
      isActive: checked,
    };

    changeStatus(data);
  };

  return (
    <tr>
      <td>
        <div className="icon_faqsBox">
          <img src={aboutUsEdit?.profile ? aboutUsEdit?.profile : ""} alt="" />
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
            color: "#0A1F44",
          }}
        >
          {`${aboutUsEdit?.firstName} ${aboutUsEdit?.lastName}`}
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          {aboutUsEdit?.designation}
        </div>
      </td>
      {aboutUsEdit?.users !== "App" && (
        <td>
          <div
            style={{
              width: "100%",
              // minWidth: "300px",
              maxWidth: "300px",
              whiteSpace: "normal",
              wordBreak: "break-all",
            }}
          >
            {aboutUsEdit?.bio && aboutUsEdit?.bio?.length <= 50 ? (
              aboutUsEdit?.bio
            ) : (
              <>
                {aboutUsEdit?.bio?.length ? (
                  <p
                    className="mb-0"
                    style={{
                      width: "300px",
                      minWidth: "300px",
                      maxWidth: "300px",
                      whiteSpace: "normal",
                    }}
                  >
                    {isExpand
                      ? aboutUsEdit?.bio
                      : aboutUsEdit?.bio?.substr(0, 50)}
                    <span
                      className="bold-desc cursor-pointer"
                      onClick={onExpand}
                    >
                      {!isExpand ? "...Read More" : "Read less"}
                    </span>
                  </p>
                ) : (
                  "N/A"
                )}
              </>
            )}
          </div>
        </td>
      )}

      <td className="switchBox">
        <div
          className="d-flex align-items-start justify-content-end"
          style={{
            width: "100%",
          }}
        >
          {" "}
          <div className="switchHolder">
            <label className="switch">
              <input
                type="checkbox"
                name="isActive"
                onChange={handleChange}
                checked={aboutUsEdit?.isActive}
              />
              <span className="slider round"></span>
            </label>
            <span className="disable">
              {!aboutUsEdit?.isActive ? "Enable" : "Disable"}
            </span>
          </div>
          <button className="btn btn-secondary th-btn" onClick={viewUser}>
            {" "}
            <img src={listIcon} alt="" /> View{" "}
          </button>
          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={onEdit}
          >
            <span>
              <img src={editIcon} alt="" className={"img-main"} />
              <img src={editIconWhite} alt="" className={"img-hover"} />
            </span>
            Edit
          </Button>
          <Button
            variant="danger"
            className="btn th-btn delete-tb action-block-btn"
            onClick={onDelete}
          >
            <span>
              <img src={trashIcon} alt="" className={"img-main"} />
              <img src={trashIconWhite} alt="" className={"img-hover"} />
            </span>
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};
