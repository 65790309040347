import React from 'react';
import Skeleton from 'react-skeleton-loader';
import { BatchViewLoader } from '../Resusable/BatchView';
import { BatchHistoryLoader } from '../Resusable/BatchHistory';
export const ViewBatchLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>View</h1>
      <p className='breadcrums-top'>
        <Skeleton color='#fff' />
      </p>
      <div className='card mb-4 card-main flex-fill'>
        <BatchViewLoader />
      </div>

      <div className='card mb-4 card-main flex-fill'>
        <BatchHistoryLoader />
      </div>
    </div>
  </>
);
