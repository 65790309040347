import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import { PlanListComponent } from "../../components/PlanListComponent/PlanListComponent.jsx";
import { FilterComponent } from "../../components/filters";
import { SearchFilter } from "../../components/filters/searchFilter";
import debounce from "lodash.debounce";
import * as qs from "query-string";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { Dropdown } from "react-bootstrap";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import "./index.css";
class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      deleteError: false,
      sortBy: "createdAt",
      search: "",
      order: "asc",
      planData: [],
      isEditMode: false,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }

  componentDidMount = () => {
    this.handleQueryParams();
  };

  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sortBy || "all",
        order: query.order || "asc",
      },
      () => this.getPlans()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  getPlans = async () => {
    const { currentPage, limit, sortBy, search, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
    };
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHOOSE_SUBSCRIPTION.service,
      ApiRoutes.CHOOSE_SUBSCRIPTION.url,
      ApiRoutes.CHOOSE_SUBSCRIPTION.method,
      ApiRoutes.CHOOSE_SUBSCRIPTION.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        subscription: response.data.data,
      });
      this.setState({
        totalRecords: response.data.count,
      });
    }
  };

  onDelete = async (id) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to delete this plan ?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SUBSCRIPTION.service,
      ApiRoutes.DELETE_SUBSCRIPTION.url.replace(":id", id),
      ApiRoutes.DELETE_SUBSCRIPTION.method,
      ApiRoutes.DELETE_SUBSCRIPTION.authenticate,
      undefined
    );
    this.setState({
      isLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.data?.message);
      this.getPlans();
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sortBy = sortBy;
    }

    if (order) {
      params.order = order;
    }

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  onEdit = async (planData) => {
    if (planData?.counts > 0) {
      toast.error("Plan is used by clients");
    } else {
      this.setState({
        isEditMode: true,
        planData: planData,
      });
      this.props.history.push(AppRoutes.Edit_plan.replace(":id", planData.id));
    }
  };
  render() {
    const {
      sortBy,
      search,
      subscription,
      isLoading,
      order,
      currentPage,
      limit,
      totalRecords,
      planData,
      // activeId,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filterOption = [
      { label: "ALL", value: "all" },
      { label: "Plan Name", value: "name" },
      { label: "Description", value: "description" },
      { label: "Company Name", value: "companyName" },
      { label: "Duration", value: "duration" },
      { label: "Price", value: "price" },
    ];
    return (
      <>
        <div className="container-fluid fluid-pd-2">
          <h1 className="h3-head-mob d-md-none">Plan Listing</h1>
          <Breadcrumbs pathname={pathname} />
          <div className="card card-main card-pagination">
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-3 head-cat-col-lg">
                  <h6 className="font-book">Plan Listing</h6>
                </div>
                <div className="col-md-9 action-col-lg ">
                  <div className="dp-right d-flex align-items-center customWidthSearchbar">
                    <SearchFilter
                      id={"sm-search-filter"}
                      placeholder={
                        "Search by Client Name, Plan Name and Description"
                      }
                      search={search}
                      onChange={this.handleSearch}
                    />
                    <FilterComponent
                      options={filterOption}
                      selectedFilter={sortBy}
                      onFilterChange={this.setFilter}
                    />
                    <Link
                      to={AppRoutes.ADDPLAN}
                      className="btn btn-primary add-btn mr-0"
                    >
                      Add Plan
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <PlanListComponent
              subscription={subscription}
              setFilter={this.setFilter}
              isLoading={isLoading}
              sortBy={sortBy}
              order={order}
              onDelete={this.onDelete}
              onEdit={this.onEdit}
            />
          </div>
          <div className="footer-pg custom-align">
            {!isLoading ? (
              <>
                <div className="float-left mt-2">
                  <p className="page-desc-left">
                    Total: <span className="bold-black">{totalRecords}</span>
                  </p>
                </div>
                <div className="float-right mt-2">
                  {totalRecords > limit ? (
                    <div className="float-right ">
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default Plans;
