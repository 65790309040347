import React, { Component } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  ApiRoutes,
  AppRoutes,
  DB_ACCPTABLE_FORMAT,
  websiteRegex,
} from "../../../config";
import { ApiHelper } from "../../../helpers";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { message } from "../../../common";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { permissionValidator } from "../../../validators/permission";
import { contactInfoValidator } from "../../../validators/contactInfo";
import "./manage-clients.css";
import { StoreDetailViewComponent } from "../../components/clients/StoreDetailViewComponent";
import { ContactDetailViewComponent } from "../../components/clients/ContactDetailViewComponent";
import { Link } from "react-router-dom";
import { FilterComponent } from "../../components/filters";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import { ClientAddress } from "../../components/clients/ClientAddress";
import moment from "moment";
import { editClient } from "../../../validators/editClient";
import { addressValidator } from "../../../validators/addressValidator";
import countryStateData from "../../../common/countryState.json";
import AddAddressModal from "../../components/clients/AddAddressModal";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";

class ViewClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      clientData: {},
      storeData: {},
      batchData: {},
      assignData: {},
      idFormatData: {},
      // Permission module related fields
      permission: [],
      address: [],
      selectedModule: null,
      subModules: [],
      permissionErrors: null,
      deletingModule: "", // to apply remove loader on particular index
      isContactEditable: false,
      isStoreEditable: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      isLoading: false,
      isUpdating: false,
      updatingSection: "contact",
      isUploading: false,
      uploadPercentage: 0,
      printModal: false,
      deleteBatchError: false,
      sortBy: "action",
      addressErrors: null,
      isAddressEdit: false,
      statesOption: [],
      isAddressUpdating: false,
      modalOpen: false,
      addressData: {},
      subscriptionData: {},
      isCertificateUpdating: false,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        clientId: id,
      },
      () => this.viewClient()
    );
  };

  genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  viewClient = async () => {
    const { clientId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_CLIENT.service,
      ApiRoutes.VIEW_CLIENT.url.replace(":id", clientId),
      ApiRoutes.VIEW_CLIENT.method,
      ApiRoutes.VIEW_CLIENT.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      const { subscriptionData = {}, user = {} } = data || {};
      const {
        first_name = "",
        last_name = "",
        email = "",
        phone = "",
        profile_picture,
        gender = "",
        other_info = {},
        idFormat = {},
        store = {},
        permission = [],
        emailList = [],
        phoneList = [],
        clientId = "",
        birth_date = "",
        address = [],
        block = "",
      } = user || {};
      const {
        address1 = "",
        address2 = "",
        state = "",
        country = "",
        postCode = "",
      } = other_info || {};
      this.setState({
        clientData: {
          first_name,
          last_name,
          email,
          phone,
          profile_picture,
          address1,
          address2,
          postCode,
          block,
          country,
          gender: {
            label: gender,
            value: gender,
          },
          birth_date: birth_date ? new Date(birth_date) : null,
          emailList,
          phoneList,
        },
        storeData: {
          ...store,
          clientId,
          website: store.website,
          companyName: store.companyName,
          // email: store.email,
          businessRegNo: store.businessRegNo || "",
          VATNumber: store.VATNumber || "",
          TradeMarkCertificateNo: store.TradeMarkCertificateNo || "",
          insideUSACanada: {
            label: store.insideUSACanada ? "Yes" : "No",
            value: store.insideUSACanada,
          },
        },
        idFormatData: {
          ...idFormat,
        },
        permission,
        address,
        subscriptionData: subscriptionData || {},
      });
    }
  };
  updateClientInfo = async () => {
    const { clientId, clientData } = this.state;
    this.setState({
      isUpdating: true,
      updatingSection: "contact",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_INFO.service,
      ApiRoutes.UPDATE_CLIENT_INFO.url.replace(":id", clientId),
      ApiRoutes.UPDATE_CLIENT_INFO.method,
      ApiRoutes.UPDATE_CLIENT_INFO.authenticate,
      undefined,
      {
        ...clientData,
        gender:
          clientData.gender && clientData.gender.value
            ? clientData.gender.value
            : "",
        birth_date: moment(clientData.birth_date).toISOString(true),
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isContactEditable: false,
      });
      toast.success(response.messages[0]);
      this.viewClient();
    }
  };
  updateStoreInfo = async () => {
    const { clientId, storeData } = this.state;
    this.setState({
      isUpdating: true,
      updatingSection: "store",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STORE_INFO.service,
      ApiRoutes.UPDATE_STORE_INFO.url.replace(":id", clientId),
      ApiRoutes.UPDATE_STORE_INFO.method,
      ApiRoutes.UPDATE_STORE_INFO.authenticate,
      undefined,
      {
        storeData: {
          ...storeData,
          insideUSACanada: storeData.insideUSACanada
            ? storeData.insideUSACanada.value
            : true,
          country:
            storeData.country && storeData.country.value
              ? storeData.country.value
              : "",
          state:
            storeData.state && storeData.state.value
              ? storeData.state.value
              : "",
        },
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isStoreEditable: false,
      });
      toast.success(response.messages[0]);
      this.viewClient();
    }
  };
  submitStoreDetails = () => {
    const { storeData } = this.state;
    if (
      storeData &&
      storeData.website &&
      !websiteRegex.test(storeData.website)
    ) {
      this.setState({
        storeData: {
          ...this.state.storeData,
          errors: {
            ...this.state.storeData.errors,
            website: message.InvalidWebite,
          },
        },
      });
      return;
    }
    const { isValid, errors } = editClient(storeData);
    if (isValid) {
      this.updateStoreInfo();
    } else {
      this.setState({
        storeData: {
          ...this.state.storeData,
          errors: errors,
        },
      });
    }
  };
  submitContactInfoDetails = () => {
    const { clientData } = this.state;
    const { isValid, errors } = contactInfoValidator(clientData);
    if (isValid) {
      this.updateClientInfo();
    } else {
      this.setState({
        clientData: {
          ...this.state.clientData,
          errors,
        },
      });
    }
  };
  updatePermission = async (payload, loading = true) => {
    const { clientId } = this.state;
    this.setState({
      isUpdating: loading,
      updatingSection: "permission",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_PERMISSION.service,
      ApiRoutes.UPDATE_CLIENT_PERMISSION.url.replace(":id", clientId),
      ApiRoutes.UPDATE_CLIENT_PERMISSION.method,
      ApiRoutes.UPDATE_CLIENT_PERMISSION.authenticate,
      undefined,
      payload
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      return false;
    } else {
      toast.success(response.messages[0]);
      return true;
    }
  };
  // Handle change on add permission
  handlePermissionSelection = (selectedOption, name) => {
    this.setState({
      [name]: selectedOption,
      permissionErrors: {
        ...this.state.permissionErrors,
        [name]: "",
      },
    });
  };
  // To add permission for the client
  addPermission = async (event) => {
    event.preventDefault();
    const { selectedModule, subModules } = this.state;
    let data = {
      module:
        selectedModule && selectedModule.value ? selectedModule.value : "",
      subModules: subModules.map((item) => item.value),
    };
    // To validates field
    const { isValid, errors } = permissionValidator({
      selectedModule: data.module,
      subModules: data.subModules.length,
    });
    if (isValid) {
      const success = await this.updatePermission({
        newModule: data,
      });
      if (success) {
        this.setState((prevState) => {
          return {
            permission: [...prevState.permission, data],
            selectedModule: null,
            subModules: [],
          };
        });
      }
    } else {
      this.setState({
        permissionErrors: errors,
      });
      return;
    }
  };
  // To remove access of particular module
  removePermission = async (moduleName) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `re you sure, Do you want to remove ${moduleName} access`,
    });
    if (!value) {
      return;
    }
    this.setState({
      deletingModule: moduleName,
    });
    const success = await this.updatePermission(
      {
        removedModule: moduleName,
      },
      false
    );
    if (success) {
      this.setState((prevState) => {
        return {
          permission: prevState.permission.filter(
            (ele) => ele.module !== moduleName
          ),
        };
      });
    }
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      if (
        name === "storeLogo" &&
        name === "businessRegNo_image" &&
        name === "VATNumber_image" &&
        name === "TradeMarkCertificateNo_image"
      ) {
        this.setState({
          storeData: {
            ...this.state.storeData,
            errors: {
              ...this.state.storeData.errors,
              [name]: message.InvalidImages,
            },
          },
        });
      } else {
        this.setState({
          clientData: {
            ...this.state.clientData,
            errors: {
              ...this.state.storeData.errors,
              [name]: message.InvalidImages,
            },
          },
        });
      }
      return;
    }
    this.setState({
      clientData: {
        ...this.state.clientData,
        errors: {
          ...this.state.clientData.errors,
          [name]: "",
        },
      },
      storeData: {
        ...this.state.storeData,
        errors: {
          ...this.state.storeData.errors,
          [name]: "",
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = "";
  };
  onDeleteFile = (fieldName) => {
    if (fieldName === "storeLogo") {
      this.setState({
        storeData: {
          ...this.state.storeData,
          [fieldName]: "",
          errors: {
            ...this.state.storeData.errors,
            [fieldName]: "",
          },
        },
      });
    } else {
      this.setState({
        clientData: {
          ...this.state.clientData,
          [fieldName]: "",
          errors: {
            ...this.state.clientData.errors,
            [fieldName]: "",
          },
        },
      });
    }
  };
  uploadPicture = async (data, name) => {
    this.setState({
      isUploading: true,
      isCertificateUpdating: name,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      if (
        name === "storeLogo" ||
        name === "businessRegNo_image" ||
        name === "VATNumber_image" ||
        name === "TradeMarkCertificateNo_image"
      ) {
        this.setState({
          storeData: { ...this.state.storeData, [name]: path },
        });
      } else {
        this.setState({
          clientData: { ...this.state.clientData, [name]: path },
        });
      }
    }
    this.setState({
      isCertificateUpdating: false,
      isUploading: false,
      uploadPercentage: 0,
      updatingSection: name === "storeLogo" ? "store" : "contact",
    });
  };
  handleContactInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      clientData: {
        ...this.state.clientData,
        [name]: value,
        errors: {
          ...this.state.clientData.errors,
          [name]: "",
        },
      },
    });
  };
  handleStoreInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "businessRegNo" && isNaN(value)) {
      return;
    }
    if (name === "VATNumber" && isNaN(value)) {
      return;
    }
    if (name === "TradeMarkCertificateNo" && isNaN(value)) {
      return;
    }

    this.setState({
      storeData: {
        ...this.state.storeData,
        [name]: value.trimLeft(),
        errors: {
          ...this.state.storeData.errors,
          [name]: "",
        },
      },
    });
  };

  onSelect = (selectedOption) => {
    this.setState({
      storeData: {
        ...this.state.storeData,
        insideUSACanada: selectedOption,
        country:
          selectedOption.label === this.state.storeData.insideUSACanada.label
            ? this.state.storeData.country
            : "",
        state:
          selectedOption.label === this.state.storeData.insideUSACanada.label
            ? this.state.storeData.state
            : "",
      },
    });
  };

  onSelectOption = (options, name) => {
    // TO reset states on country selection
    if (name === "country") {
      this.setState({
        addressData: {
          ...this.state.addressData,
          [name]: options.label,
          state: "",
        },
      });
      if (options && options.value) {
        this.handleStatesOption(options.value);
      }
    } else {
      this.setState({
        addressData: {
          ...this.state.addressData,
          [name]: options.label,
        },
      });
    }
  };

  handleDateChange = (date) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        birth_date: date,
      },
    });
  };
  onSelectGender = (selectedOption) => {
    if (selectedOption) {
      this.setState({
        clientData: {
          ...this.state.clientData,
          gender: selectedOption,
        },
      });
    }
  };

  customBreadCrumb = () => {
    const {
      isLoading,
      clientData: { first_name = "", last_name = "" },
    } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      [first_name, last_name].filter(Boolean).join(" ") || "View user"
    );
  };

  handleClose = () => {
    this.setState({
      printModal: false,
    });
  };

  onDelete = async (batchData) => {
    this.setState({
      deleteBatchError: true,
    });
  };

  // To close delete batch error modal
  handleCloseDelete = () => {
    this.setState({
      deleteBatchError: false,
    });
  };

  updateAddress = async (data) => {
    const { clientId } = this.state;
    this.setState({
      isAddressUpdating: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_ADDRESS.service,
      ApiRoutes.UPDATE_CLIENT_ADDRESS.url.replace(":id", clientId),
      ApiRoutes.UPDATE_CLIENT_ADDRESS.method,
      ApiRoutes.UPDATE_CLIENT_ADDRESS.authenticate,
      undefined,
      data
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isAddressUpdating: false,
        isAddressEdit: false,
        addressErrors: null,
        addressData: null,
        // statesOption: "",
      });
      toast.success(response.messages[0]);
      this.setState({
        modalOpen: false,
      });
      this.viewClient();
    }
  };
  submitAddress = async (event) => {
    event && event.preventDefault();

    const { addressData, statesOption } = this.state;
    let data = {
      addressType: addressData?.addressType,
      addressLine1: addressData?.addressLine1,
      addressLine2: addressData?.addressLine2,
      country: addressData?.country,
      postCode: addressData?.postCode,
    };

    // To made states required only if selected origins have states
    if ((statesOption && statesOption.length) || !data.country) {
      data = {
        ...data,
        state: addressData?.state,
      };
    }
    // To validates field
    const { isValid, errors } = addressValidator(data);
    if (isValid) {
      this.updateAddress({
        addresModule: data,
      });
    } else {
      this.setState({
        addressErrors: errors,
        isAddressUpdating: false,
      });
      return;
    }
  };

  onAddressEdit = (data) => {
    this.setState({
      modalOpen: true,
      isAddressEdit: true,
      addressData: data,
    });
    this.handleStatesOption(data?.country);
  };
  onAddressCancel = () => {
    this.setState({
      isAddressEdit: false,
    });
  };

  onAddressChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "postCode" && isNaN(value)) {
      return;
    }
    this.setState({
      addressData: {
        ...this.state.addressData,
        [name]: value.trimLeft(),
      },
    });
  };

  handleStatesOption = (value) => {
    let temp = [];
    let selectedCountryIndex = countryStateData.findIndex(
      (element) => element.name === value
    );
    if (selectedCountryIndex > -1) {
      countryStateData[selectedCountryIndex].states
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((state) => {
          temp.push({
            label: state.name,
            value: state.name,
          });
        });
      this.setState({
        statesOption: temp,
      });
    }
  };

  handleAddressModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleAddressClose = () => {
    this.setState({
      modalOpen: false,
      isAddressEdit: false,
      addressErrors: null,
      statesOption: "",
      addressData: null,
    });
  };

  blockClient = async () => {
    const { clientId } = this.state;
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to block this client?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BLOCK_USER.service,
      ApiRoutes.BLOCK_USER.url.replace(":id", clientId),
      ApiRoutes.BLOCK_USER.method,
      ApiRoutes.BLOCK_USER.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.props.history.push(AppRoutes.MANAGE_CLIENTS);
    }
  };
  unBlockClient = async () => {
    const { clientId } = this.state;

    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to unblock this client?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNBLOCK_USER.service,
      ApiRoutes.UNBLOCK_USER.url.replace(":id", clientId),
      ApiRoutes.UNBLOCK_USER.method,
      ApiRoutes.UNBLOCK_USER.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.props.history.push(AppRoutes.MANAGE_CLIENTS);
    }
  };

  onSelectCountryOption = (options, name) => {
    this.setState({
      clientData: {
        ...this.state.clientData,
        [name]: options.label,
        state: "",
      },
    });
  };

  deleteClient = async () => {
    const { clientId } = this.state;
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to delete this client?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_Client_ACCOUNT.service,
      ApiRoutes.DELETE_Client_ACCOUNT.url.replace(":id", clientId),
      ApiRoutes.DELETE_Client_ACCOUNT.method,
      ApiRoutes.DELETE_Client_ACCOUNT.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.props.history.push(AppRoutes.MANAGE_CLIENTS);
    }
  };
  render() {
    const {
      isLoading,
      isContactEditable,
      isStoreEditable,
      clientId,
      clientData,
      storeData,
      isUpdating,
      updatingSection,
      isUploading,
      uploadPercentage,
      sortBy,
      idFormatData,
      address,
      addressErrors,
      isAddressEdit,
      isAddressUpdating,
      statesOption,
      modalOpen,
      addressData,
      subscriptionData,
      isCertificateUpdating,
    } = this.state;

    console.log("clientData", clientData);
    const {
      props: {
        location: { pathname, state },
      },
    } = this;

    let allCountry = [];
    countryStateData.forEach((element) => {
      allCountry.push({ label: element.name, value: element.name });
    });
    return (
      <div className="container-fluid fluid-pd-2 clientNewView">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
          search={state ? state.search : ""}
        />

        <div className="row">
          <div className="col-md-12 d-flex justify-content-between">
            <h3>Company Details ({subscriptionData?.subscriptionId?.name})</h3>

            <div className="d-flex align-items-center">
              <Link
                to={AppRoutes.CLIENT_SUBSCRIPTION.replace(":id", clientId)}
                className="btn btn-primary add-btn add-wd-1024"
              >
                {" "}
                Subscription
              </Link>
              <Link
                to={AppRoutes.MANAGE_CLIENTS_PERMISSION.replace(
                  ":id",
                  clientId
                )}
                className="btn btn-primary add-btn add-wd-1024"
              >
                {" "}
                Permissions
              </Link>

              <Dropdown className="customDropdownBox no-arrow">
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className={
                    "sortBy-custom d-flex align-items-center justify-content-between"
                  }
                  style={{ width: "130px" }}
                >
                  Action
                  <img src={filterarrowIcon} className="m-0 ml-2" alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                  <Dropdown.Item
                    as="div"
                    onClick={() => {
                      window.open(
                        `mailto:${
                          clientData.email ? clientData.email : storeData.email
                        }`
                      );
                    }}
                  >
                    Contact Client
                  </Dropdown.Item>
                  {!clientData.block ? (
                    <Dropdown.Item as="div" onClick={this.blockClient}>
                      Block
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item as="div" onClick={this.unBlockClient}>
                      Unblock
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item as="div" onClick={this.deleteClient}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {!isStoreEditable ? (
                <div className="side-col dp-right">
                  <Button
                    onClick={() =>
                      this.setState({
                        isStoreEditable: true,
                      })
                    }
                    className="btn btn-secondary th-btn edit-tb action-block-btn  bg-white"
                  >
                    <>
                      <span>
                        <img src={editIcon} alt="" className={"img-main"} />
                        <img
                          src={editIconWhite}
                          alt=""
                          className={"img-hover"}
                        />
                      </span>
                      Edit
                    </>
                  </Button>
                </div>
              ) : (
                <>
                  <div className="">
                    <button
                      className="btn btn-secondary can-btn-table cn-tp"
                      onClick={() =>
                        this.setState({
                          isStoreEditable: false,
                        })
                      }
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                  </div>
                  <div className="">
                    <button
                      className="btn action-cl-btn"
                      onClick={this.submitStoreDetails}
                      disabled={isUpdating}
                    >
                      {isUpdating ? (
                        <>
                          <i className="fa fa-spinner fa-spin"></i> Save
                        </>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-md-12">
            <StoreDetailViewComponent
              isCertificateUpdating={isCertificateUpdating}
              onChange={this.handleStoreInfoChange}
              onSelect={this.onSelect}
              onFileChange={this.onFileChange}
              storeData={storeData}
              isLoading={isLoading}
              isEditable={isStoreEditable}
            />
            {/* )} */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between">
            <h3>Preferences</h3>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="row">
                <div className="col-md-6">
                  <h4>Scan ID</h4>
                  <h5>{idFormatData?.scan || "N/A"}</h5>
                </div>
                <div className="col-md-6">
                  <h4>Counterfeit ID</h4>
                  <h5>{idFormatData?.counterfeit || "N/A"}</h5>
                </div>
                <div className="col-md-6">
                  <h4>Staff ID</h4>
                  <h5>{idFormatData?.employee || "N/A"}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-between">
            <h3>Owner Information</h3>

            {isContactEditable ? (
              <div className="float-right">
                <button
                  className="btn action-cl-btn"
                  onClick={this.submitContactInfoDetails}
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <>
                      <i className="fa fa-spinner fa-spin"></i> Save
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
                <button
                  className="btn btn-secondary can-btn-table cn-tp"
                  onClick={() =>
                    this.setState({
                      isContactEditable: false,
                    })
                  }
                >
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            ) : (
              <Button
                className="btn btn-secondary th-btn edit-tb action-block-btn  bg-white"
                onClick={() =>
                  this.setState({
                    isContactEditable: true,
                  })
                }
              >
                <>
                  <span>
                    <img src={editIcon} alt="" className={"img-main"} />
                    <img src={editIconWhite} alt="" className={"img-hover"} />
                  </span>
                  Edit
                </>
              </Button>
            )}
          </div>

          <div className="col-md-12">
            <ContactDetailViewComponent
              clientData={clientData}
              isLoading={isLoading}
              isEditable={isContactEditable}
              isUpdating={isUpdating && updatingSection === "contact"}
              onChange={this.handleContactInfoChange}
              onSubmit={this.submitContactInfoDetails}
              isUploading={isUploading && updatingSection === "contact"}
              uploadPercentage={uploadPercentage}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              onSelectOption={this.onSelectGender}
              onDateChange={this.handleDateChange}
              genderOptions={this.genderOptions}
              allCountry={allCountry}
              onSelectCountryOption={this.onSelectCountryOption}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-between">
            <h3>Contact Details</h3>
          </div>

          <div className="col-md-12">
            <div className="card contactDetailCard">
              <div className="row">
                <div className="col-md-12">
                  <h5 style={{ marginBottom: "30px" }}>Email Addess</h5>
                </div>
                <div className="col-md-12">
                  <h6>Primary Email</h6>
                  {/* <p>{clientData.email || "N/A"}</p> */}
                  <p className="mb-2">{clientData.email || "N/A"}</p>
                </div>
                {clientData?.emailList?.map((info, index) => (
                  <>
                    <div className="col-md-12">
                      <h6>{`Email ${index + 2}`}</h6>
                      <p className="mb-2">{info}</p>
                    </div>
                  </>
                ))}
                <div className="col-md-12">
                  <hr />
                </div>

                <div className="col-md-12">
                  <h5 style={{ marginBottom: "30px" }}>Phone Number</h5>
                </div>
                <div className="col-md-12">
                  <h6>Primary Phone Number</h6>
                  <p>
                    {storeData.countryCode} {storeData.phone || "N/A"}
                  </p>
                </div>
                {clientData?.phoneList?.map((info, index) => (
                  <>
                    <div className="col-md-12">
                      <h6>{`Phone Number ${index + 2}`}</h6>
                      <p className="mb-2">
                        {info?.countryCode} {info?.phone}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>

        <ClientAddress
          address={address}
          onAddressEdit={this.onAddressEdit}
          handleShow={this.handleAddressModal}
        />

        <AddAddressModal
          isAddressEdit={isAddressEdit}
          address={addressData}
          addressErrors={addressErrors}
          isAddressUpdating={isAddressUpdating}
          submitAddress={this.submitAddress}
          allCountry={allCountry}
          onSelectOption={this.onSelectOption}
          onChange={this.onAddressChange}
          statesOption={statesOption}
          modalOpen={modalOpen}
          handleClose={this.handleAddressClose}
        />

        {/* <div className="row">
          <ClientBatch clientId={clientId} {...this.props} />
        </div> */}
      </div>
    );
  }
}

export default ViewClient;
