import { AppRoutes, CMS_USER } from "../../../config";
import Shipping from "../../../assets/img/shipping-and-delivery.svg";
import Clarity from "../../../assets/img/group.svg";
import cms from "../../../assets/figma/cms.svg";
import reports from "../../../assets/figma/reports.svg";
import MapPin from "../../../assets/img/map-pin-1.svg";
import Shopping from "../../../assets/img/shopping.svg";
import Group from "../../../assets/img/group-10.svg";
import Settings from "../../../assets/img/settings-6.svg";
import Subscription from "../../../assets/img/subscription.svg";
import Enquiries from "../../../assets/figma/enquiries.svg";

export const NavConfig = [
  {
    name: "Dashboard",
    path: AppRoutes.DASHBOARD,
  },
  {
    name: "Manage Clarity",
    imgUrl: Clarity,
    path: AppRoutes.MANAGE_CLARITY,
    tabName: AppRoutes.MANAGE_CLARITY,
    childrens: [
      {
        name: "Counterfeit Requests",
        path: AppRoutes.COUNTERFEIT,
        tabName: AppRoutes.MANAGE_CLARITY,
      },
      {
        name: "Scans",
        path: AppRoutes.SCANS,
        tabName: AppRoutes.MANAGE_CLARITY,
      },
      // {
      //   name: "Scan Rules",
      //   path: AppRoutes.SCAN_RULES,
      //   tabName: AppRoutes.MANAGE_CLARITY,
      // },
      // {
      //   name: "Alerts",
      //   path: AppRoutes.ALERTS,
      //   tabName: AppRoutes.MANAGE_CLARITY,
      // },
      {
        name: "Manage User",
        path: AppRoutes.MANAGE_USER,
        tabName: AppRoutes.MANAGE_CLARITY,
      },
      {
        name: "Manage Video",
        path: AppRoutes.MANAGE_VIDEO_CATEGORY,
        tabName: AppRoutes.MANAGE_CLARITY,
      },
    ],
  },
  {
    name: "Manage Product",
    path: AppRoutes.MANAGE_PRODUCT,
    imgUrl: Shipping,
    tabName: AppRoutes.MANAGE_PRODUCT,
  },
  {
    name: "Origin",
    path: AppRoutes.MANAGE_ORIGIN,
    imgUrl: MapPin,
    tabName: AppRoutes.MANAGE_ORIGIN,
  },
  {
    name: "Batches",
    path: AppRoutes.BATCHES,
    imgUrl: Shopping,
    tabName: AppRoutes.BATCHES,
  },
  {
    name: "Manage Clients",
    path: AppRoutes.MANAGE_CLIENTS,
    imgUrl: Group,
    tabName: AppRoutes.MANAGE_CLIENTS,
  },
  {
    name: "Subscription",
    imgUrl: Subscription,
    path: AppRoutes.MANAGE_SUBSCRIPTION,
    tabName: AppRoutes.MANAGE_SUBSCRIPTION,
    childrens: [
      {
        name: "Plans",
        path: AppRoutes.PLANS,
        tabName: AppRoutes.MANAGE_SUBSCRIPTION,
      },
      {
        name: "Transactions",
        path: AppRoutes.TRANSACTIONS,
        tabName: AppRoutes.MANAGE_SUBSCRIPTION,
      },
      {
        name: "Subscribed Clients",
        path: AppRoutes.SUBSCRIBED_CLIENTS,
        tabName: AppRoutes.MANAGE_SUBSCRIPTION,
      },
      {
        name: "Discounts",
        path: AppRoutes.DISCOUNT,
        tabName: AppRoutes.MANAGE_SUBSCRIPTION,
      },
    ],
  },
  {
    name: "Manage Staff",
    path: AppRoutes.MANAGE_STAFF,
    imgUrl: Group,
    tabName: AppRoutes.MANAGE_STAFF,
    childrens: [
      {
        name: "Staff Listing",
        path: AppRoutes.MANAGE_STAFF_LIST,
        tabName: AppRoutes.MANAGE_STAFF,
      },
      {
        name: "Manage Roles",
        path: AppRoutes.MANAGE_STAFF_ROLE,
        tabName: AppRoutes.MANAGE_STAFF,
      },
    ],
  },
  // {
  //   name: "Manage Staff",
  //   path: AppRoutes.MANAGE_STAFF,
  //   imgUrl: Group,
  //   tabName: AppRoutes.MANAGE_STAFF,
  // },
  // {
  //   name: "Manage Roles",
  //   path: AppRoutes.ROLE,
  //   imgUrl: Group,
  //   tabName: AppRoutes.ROLE,
  // },

  // {
  //   name: "CMS",
  //   imgUrl: cms,
  //   path: AppRoutes.CMS,
  //   tabName: AppRoutes.CMS,
  //   childrens: [
  //     {
  //       name: "Push Notification",
  //       path: AppRoutes.PUSH_NOTIFICATIONS,
  //       tabName: AppRoutes.CMS,
  //     },
  //     {
  //       name: "FAQ",
  //       path: AppRoutes.FAQS,
  //       tabName: AppRoutes.CMS,
  //       subChildren: [
  //         {
  //           name: "App Users",
  //           path: AppRoutes.FAQS_USERS.replace(":user", CMS_USER.App.VALUE),
  //           tabName: AppRoutes.CMS,
  //         },
  //         {
  //           name: "Client Users",
  //           path: AppRoutes.FAQS_USERS.replace(":user", CMS_USER.Client.VALUE),
  //           tabName: AppRoutes.CMS,
  //         },
  //         {
  //           name: "Staff Users",
  //           path: AppRoutes.FAQS_USERS.replace(":user", CMS_USER.Staff.VALUE),
  //           tabName: AppRoutes.CMS,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Contact",
  //       path: AppRoutes.CONTACT,
  //       tabName: AppRoutes.CMS,
  //       subChildren: [
  //         {
  //           name: "App Users",
  //           path: AppRoutes.CONTACT_USER.replace(":user", CMS_USER.App.VALUE),
  //           tabName: AppRoutes.CMS,
  //         },
  //         {
  //           name: "Client Users",
  //           path: AppRoutes.CONTACT_USER.replace(
  //             ":user",
  //             CMS_USER.Client.VALUE
  //           ),
  //           tabName: AppRoutes.CONTACT,
  //         },
  //         {
  //           name: "Staff Users",
  //           path: AppRoutes.CONTACT_USER.replace(":user", CMS_USER.Staff.VALUE),
  //           tabName: AppRoutes.CONTACT,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Terms and Conditions",
  //       path: AppRoutes.TERMS_AND_CONDITIONN,
  //       tabName: AppRoutes.CMS,
  //       subChildren: [
  //         {
  //           name: "App Users",
  //           path: AppRoutes.TERMS_AND_CONDITION_USER.replace(
  //             ":user",
  //             CMS_USER.App.VALUE
  //           ),
  //           tabName: AppRoutes.CMS,
  //         },
  //         {
  //           name: "Client Users",
  //           path: AppRoutes.TERMS_AND_CONDITION_USER.replace(
  //             ":user",
  //             CMS_USER.Client.VALUE
  //           ),
  //           tabName: AppRoutes.CMS,
  //         },
  //         {
  //           name: "Staff Users",
  //           path: AppRoutes.TERMS_AND_CONDITION_USER.replace(
  //             ":user",
  //             CMS_USER.Staff.VALUE
  //           ),
  //           tabName: AppRoutes.CMS,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Privacy Policy",
  //       path: AppRoutes.CMS_PRIVACY_POLICYY,
  //       tabName: AppRoutes.CMS,
  //       subChildren: [
  //         {
  //           name: "App Users",
  //           path: AppRoutes.CMS_PRIVACY_POLICY_USER.replace(
  //             ":user",
  //             CMS_USER.App.VALUE
  //           ),
  //           tabName: AppRoutes.CMS,
  //         },
  //         {
  //           name: "Client Users",
  //           path: AppRoutes.CMS_PRIVACY_POLICY_USER.replace(
  //             ":user",
  //             CMS_USER.Client.VALUE
  //           ),
  //           tabName: AppRoutes.CMS,
  //         },
  //         {
  //           name: "Staff Users",
  //           path: AppRoutes.CMS_PRIVACY_POLICY_USER.replace(
  //             ":user",
  //             CMS_USER.Staff.VALUE
  //           ),
  //           tabName: AppRoutes.CMS,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Video Tutorials",
  //       path: AppRoutes.CMS_VIDEO_TUTORIAL,
  //       tabName: AppRoutes.CMS,
  //     },
  //   ],
  // },

  // ------------------new

  {
    name: "CMS",
    imgUrl: cms,
    path: AppRoutes.CMS,
    tabName: AppRoutes.CMS,
    childrens: [
      {
        name: "Push Notification",
        path: AppRoutes.PUSH_NOTIFICATIONS,
        tabName: AppRoutes.CMS,
      },
      {
        name: "App Users",
        path: AppRoutes.CMS,
        tabName: AppRoutes.CMS,
        subChildren: [
          {
            name: "FAQ",
            path: AppRoutes.FAQS_USERS.replace(":user", CMS_USER.App.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Contact",
            path: AppRoutes.CONTACT_USER.replace(":user", CMS_USER.App.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Terms and Conditions",
            path: AppRoutes.TERMS_AND_CONDITION_USER.replace(
              ":user",
              CMS_USER.App.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Privacy Policy",
            path: AppRoutes.CMS_PRIVACY_POLICY_USER.replace(
              ":user",
              CMS_USER.App.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
        ],
      },
      {
        name: "Client Users",
        path: AppRoutes.CMS,
        tabName: AppRoutes.CMS,
        subChildren: [
          {
            name: "FAQ",
            path: AppRoutes.FAQS_USERS.replace(":user", CMS_USER.Client.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Contact",
            path: AppRoutes.CONTACT_USER.replace(
              ":user",
              CMS_USER.Client.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Terms and Conditions",
            path: AppRoutes.TERMS_AND_CONDITION_USER.replace(
              ":user",
              CMS_USER.Client.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Privacy Policy",
            path: AppRoutes.CMS_PRIVACY_POLICY_USER.replace(
              ":user",
              CMS_USER.Client.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
          // {
          //   name: "Landing Page",
          //   path: AppRoutes.LANDING_PAGE_CLIENT.replace(
          //     ":user",
          //     CMS_USER.Client.VALUE
          //   ),
          //   tabName: AppRoutes.CMS,
          // },
        ],
      },
      {
        name: "Staff Users",
        path: AppRoutes.CMS,
        tabName: AppRoutes.CMS,
        subChildren: [
          {
            name: "FAQ",
            path: AppRoutes.FAQS_USERS.replace(":user", CMS_USER.Staff.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Contact",
            path: AppRoutes.CONTACT_USER.replace(":user", CMS_USER.Staff.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Terms and Conditions",
            path: AppRoutes.TERMS_AND_CONDITION_USER.replace(
              ":user",
              CMS_USER.Staff.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Privacy Policy",
            path: AppRoutes.CMS_PRIVACY_POLICY_USER.replace(
              ":user",
              CMS_USER.Staff.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
        ],
      },

      {
        name: "Marketing Website",
        path: AppRoutes.CMS,
        tabName: AppRoutes.CMS,
        subChildren: [
          {
            name: "Landing Page",
            path: AppRoutes.LANDING_PAGE_CLIENT.replace(
              ":user",
              CMS_USER.Web.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
          {
            name: "About us",
            path: AppRoutes.ABOUT_US.replace(":user", CMS_USER.Web.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Careers",
            path: AppRoutes.CAREERS.replace(":user", CMS_USER.Web.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Account Management",
            path: AppRoutes.ACCOUNT_MANAGEMENT.replace(
              ":user",
              CMS_USER.Web.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
          {
            name: "FAQ",
            path: AppRoutes.FAQS_USERS.replace(":user", CMS_USER.Web.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Contact",
            path: AppRoutes.CONTACT_USER.replace(":user", CMS_USER.Web.VALUE),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Terms & Conditions",
            path: AppRoutes.TERMS_AND_CONDITION_USER.replace(
              ":user",
              CMS_USER.Web.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
          {
            name: "Privacy Policy",
            path: AppRoutes.CMS_PRIVACY_POLICY_USER.replace(
              ":user",
              CMS_USER.Web.VALUE
            ),
            tabName: AppRoutes.CMS,
          },

          {
            name: "Video Tutorials",
            path: AppRoutes.CMS_VIDEO_TUTORIAL.replace(
              ":user",
              CMS_USER.Web.VALUE
            ),
            tabName: AppRoutes.CMS,
          },
        ],
      },
    ],
  },

  {
    name: "Enquiries",
    imgUrl: Enquiries,
    path: AppRoutes.ENQUIRIES,
    tabName: AppRoutes.ENQUIRIES,
    childrens: [
      {
        name: "Sales",
        path: AppRoutes.ENQUIRIES_SALES,
        tabName: AppRoutes.ENQUIRIES,
      },
      {
        name: "General",
        path: AppRoutes.ENQUIRIES_GENERAL,
        tabName: AppRoutes.ENQUIRIES,
      },
      {
        name: "Careers",
        path: AppRoutes.ENQUIRIES_CAREERS,
        tabName: AppRoutes.ENQUIRIES,
      },
    ],
  },

  {
    name: "Reports",
    imgUrl: reports,
    path: AppRoutes.REPORTS,
    tabName: AppRoutes.REPORTS,
    childrens: [
      {
        name: "Batches",
        path: AppRoutes.BATCHES_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
      {
        name: "Product",
        path: AppRoutes.PRODUCT_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
      {
        name: "Users",
        path: AppRoutes.USERS_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
      {
        name: "Scans",
        path: AppRoutes.SCAN_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
      {
        name: "Counterfeit",
        path: AppRoutes.COUNTERFEIT_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
    ],
  },

  // {
  //   name: "Test",
  //   imgUrl: reports,
  //   path: AppRoutes.TEST,
  //   tabName: AppRoutes.TEST,
  //   childrens: [
  //     {
  //       name: "Test 1",
  //       path: AppRoutes.TEST_ONE,
  //       tabName: AppRoutes.TEST,
  //     },
  //     {
  //       name: "Test 2",
  //       path: AppRoutes.TEST_TWO,
  //       tabName: AppRoutes.TEST,
  //     },
  //   ],
  // },
  {
    name: "Settings",
    path: AppRoutes.SETTING,
    imgUrl: Settings,
    tabName: AppRoutes.SETTING,
  },
];
