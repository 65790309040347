import Validator from "js-object-validation";
import { message } from "../common";
import { replaceAll } from "../helpers";

export const productValidator = (data) => {
  const validations = {
    productName: {
      required: true,
      maxlength: 30,
    },
    productNumber: {
      required: true,
      maxlength: 12,
    },
    productDescription: {
      required: true,
    },
    // productImage: {
    //   required: true,
    // },
  };
  // Error messages
  const messages = {
    productName: {
      required: message.Required.replace(":item", "Product name"),
      maxlength: replaceAll(message.MaxLengthError, {
        ":item": "Product name",
        ":length": 30,
      }),
    },
    productNumber: {
      required: message.Required.replace(":item", "Product number"),
      maxlength: replaceAll(message.MaxLengthError, {
        ":item": "Product number",
        ":length": 12,
      }),
    },
    productDescription: {
      required: message.Required.replace(":item", "Product Description"),
    },
    productImage: {
      required: message.RequiredImage.replace(":item", "Product Pic"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
