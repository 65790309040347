import React, { useState, useEffect } from "react";
import { InputTextField, SubmitButton } from "../forms";
import { faqsValidator } from "../../../validators";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes, AppConfig, AppRoutes } from "../../../config";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import faqCatgry_attach from "../../../assets/img/faqCatgry_attach.svg";
import { message } from "../../../common";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

export const AddFormComponent = ({
  faq,
  handleAddCancel,
  loadOriginOptions,
  BrandAndCertificate,
  onSelect,
}) => {
  const [priority, setPriority] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryImg, setCategoryImg] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "priority") {
      setPriority(value);
    }
    if (name === "categoryName") {
      setCategoryName(value.trimLeft());
    }
    if (name === "categoryImg") {
      setCategoryImg(value);
    }
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    let payload = { categoryName: categoryName, categoryImg: categoryImg };

    const { isValid, errors } = faqsValidator(payload);
    if (isValid) {
      setErrors({});
      if (faq.id) {
        updateEntry({ ...payload });
      } else {
        addEntry({ ...payload });
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
      return;
    }
  };

  const addEntry = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_FAQ_CAT.service,
      ApiRoutes.ADD_FAQ_CAT.url,
      ApiRoutes.ADD_FAQ_CAT.method,
      ApiRoutes.ADD_FAQ_CAT.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  const updateEntry = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_FAQ_CAT.service,
      ApiRoutes.UPDATE_FAQ_CAT.url.replace(":id", faq.id),
      ApiRoutes.UPDATE_FAQ_CAT.method,
      ApiRoutes.UPDATE_FAQ_CAT.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  const onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    console.log(files, name);
    if (files[0] && !files[0].type.match("image.*")) {
      setErrors({
        [name]: message.InvalidImages,
      });

      return;
    }
    setCategoryImg(categoryImg);
    await uploadPicture(files[0], name);
    event.target.value = "";
  };

  const uploadPicture = async (data, name) => {
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};

      setCategoryImg(path);
    }
  };

  useEffect(() => {
    console.log(faq);
    if (faq.id) {
      setIsEditMode(true);
      setCategoryName(faq.categoryName);
      setCategoryImg(faq.categoryImg);
    } else {
      setIsEditMode(false);
    }
  }, []);

  const brandOptions = [
    { value: 1, label: "Category 1" },
    { value: 2, label: "Category 2" },
  ];

  return (
    <form className="product_add proAdd">
      <div className="row">
        <div className="col-md-6">
          <InputTextField
            type="text"
            id="categoryname"
            placeholder="Enter Category Name"
            label={"Category Name"}
            className={"form-control-product"}
            name="categoryName"
            value={categoryName}
            onChange={onChange}
            min="1"
            errorMessage={
              errors && errors.categoryName ? "Category name is required" : ""
            }
          />
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className={`form-control-label`}>Icon</label>

            <div className="d-flex align-items-center iconAdd">
              <label className="input-label">
                <input
                  className="imgUpload img_up "
                  type="file"
                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                  onChange={onFileChange}
                />
                Upload Image
              </label>

              <div className="chooseFile_icon">
                <input
                  className="imgUpload img_up "
                  type="file"
                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                  onChange={onFileChange}
                />
                <img
                  className={categoryImg ? "w-100" : ""}
                  src={
                    categoryImg
                      ? categoryImg.startsWith("https://")
                        ? categoryImg
                        : `${AppConfig.FILES_ENDPOINT}${categoryImg}`
                      : faqCatgry_attach
                  }
                />
              </div>
            </div>

            <div className={"text-danger"}>
              {errors && errors.categoryImg ? errors.categoryImg : ""}
            </div>
          </div>
        </div>
        <div className="col-md-12 footer-formCZ ">
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary cancel-btn"
              onClick={handleAddCancel}
            >
              {" "}
              Cancel
            </button>

            <SubmitButton
              className={"submit-btn mr-0"}
              text={isEditMode ? "Update" : "Add"}
              isLoading={isLoading}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
