import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { scanRuleValidator } from "../../../validators/addScanRule";
import { AddScanRulesComponent } from "../../components/AddScanRules";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import "./add-rules.css";
import countryStateData from "../../../common/countryState.json";
import { message } from "../../../common";

class AddScanRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      noOfUsers: "",
      sendAlert: "",
      alertType: "",
      country: "",
      postCode: "",
      state: "",
      phone: "",
      countryCode: "",
      originId: "",
      profile_picture: "",
      originOptions: [],
      stateOptions: [],
      errors: null,
      isUploading: false,
      isAdding: false,
      uploadPercentage: 0,
    };
  }

  componentDidMount = () => {
    this.fetchOriginsOption();
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "noOfUsers" && isNaN(value)) {
      return;
    }
    this.setState({
      [name]: value.trimLeft(),
      errors: null,
    });
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      this.setState({
        errors: {
          imageFile: "Only image files are allowed",
        },
      });
      return;
    }
    // Update the state
    this.setState({
      errors: {
        imageFile: "",
      },
    });
    console.log(event);
    await this.uploadPicture(files[0]);
    event.target.value = "";
  };
  onDeleteFile = () => {
    this.setState({
      profile_picture: "",
    });
  };
  uploadPicture = async (data) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      this.setState({
        profile_picture: path,
      });
    }
  };

  addScanRule = async (payload) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SCAN_RULE.service,
      ApiRoutes.ADD_SCAN_RULE.url,
      ApiRoutes.ADD_SCAN_RULE.method,
      ApiRoutes.ADD_SCAN_RULE.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success("Scan Rule Added Successfully.");
      this.props.history.push(AppRoutes.SCAN_RULES);
    }
  };

  fetchOriginsOption = async (search = "") => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ORIGINS_OPTION_LIST.service,
      ApiRoutes.ORIGINS_OPTION_LIST.url,
      ApiRoutes.ORIGINS_OPTION_LIST.method,
      ApiRoutes.ORIGINS_OPTION_LIST.authenticate,
      { search },
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      this.setState({
        originOptions: data,
      });
      return data;
    }
  };

  onSelectOption = (options, name) => {
    if (name === "country") {
      this.setState({
        [name]: options,
        state: "",
        errors: {
          ...this.state.errors,
          [name]: "",
        },
      });
      if (options && options.value) {
        this.handleStatesOption(options?.value);
      }
    } else {
      this.setState({
        [name]: options,
        errors: {
          ...this.state.errors,
          [name]: "",
        },
      });
    }
  };

  onSelect = (options, name) => {
    this.setState({
      [name]: options,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
    // TO reset states on country selection
    if (name === "country") {
      this.setState({
        stateOptions: options.states.map((state) => ({
          label: state,
          value: state,
        })),
        state: "",
        errors: {
          ...this.state.errors,
          state: "",
          originId: "",
        },
      });
    }
  };
  handleStatesOption = (value) => {
    let temp = [];
    console.log(temp);
    let selectedCountryIndex = countryStateData.findIndex(
      (element) => element.name === value
    );
    if (selectedCountryIndex > -1) {
      countryStateData[selectedCountryIndex].states
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((state) => {
          temp.push({
            label: state.name,
            value: state.name,
          });
        });
      this.setState({
        stateOptions: temp,
      });
    }
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const { name, noOfUsers, sendAlert, alertType, country, countryCode } =
      this.state;
    let payload = {
      name,
      noOfUsers,
      sendAlert: sendAlert && sendAlert?.value ? sendAlert?.label : "",
      alertType: alertType && alertType?.value ? alertType?.label : "",
      country: country && country?.value ? country?.label : "",
    };

    // if ((stateOptions && stateOptions.length) || !payload.other_info.country) {
    //   payload.other_info.state = state && state.value ? state.value : "";
    // }

    const { isValid, errors } = scanRuleValidator(payload);
    console.log("payload", payload);
    console.log("errors", errors);
    if (isValid) {
      // var regexp = /^\S*$/;
      // console.log(regexp.test(first_name))
      this.addScanRule(payload);
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };

  handleCancel = () => {
    // this.setState({
    //   email: '',
    //   first_name: '',
    //   last_name: '',
    //   address1: '',
    //   address2: '',
    //   country: '',
    //   postCode: '',
    //   state: '',
    //   phone: '',
    //   profile_picture: '',
    // });
    this.props.history.push(AppRoutes.SCAN_RULES);
  };

  render() {
    const {
      name,
      noOfUsers,
      sendAlert,
      alertType,
      country,
      errors,
      isUploading,
      isAdding,
      uploadPercentage,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Add Scan Rule</h1>
        <Breadcrumbs pathname={pathname} />
        {/* <p className='breadcrums-top'>
          {routesArray.map(route => (
            <>
              {route.path ? (
                <Link to={route.path}>{route.label}</Link>
              ) : (
                route.label
              )}
              <img src={arrowIcon} className='breadcrum-seperator' alt='' />
            </>yload = {
                //     ...payload,
                // };
          ))}
          {currentPage}
        </p> */}
        <div className="card mb-4 card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book ">Add Scan Rule</h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            <AddScanRulesComponent
              name={name}
              noOfUsers={noOfUsers}
              sendAlert={sendAlert}
              alertType={alertType}
              country={country}
              errors={errors}
              isUploading={isUploading}
              isAdding={isAdding}
              uploadPercentage={uploadPercentage}
              onChange={this.handleChange}
              onSelect={this.onSelectOption}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              handleCancel={this.handleCancel}
              handleSubmit={this.handleSubmit}
              loadOriginOptions={this.loadOriginOptions}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AddScanRules;
