import React, { useState } from "react";
import { ScanRuleRow } from "./ScanRuleRow";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";
import { AppRoutes } from "../../../config";
export const ListComponent = ({
  scans,
  isFilterApplied,
  isLoading,
  batchViewHandler,
  userViewHandler,
  setFilter,
  sortBy,
  order,
  filtersOption,
  printModal,
  onDelete,
  onEdit,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0" className="alert_table">
            <thead className="pad-top">
              <tr>
                {filtersOption.map(({ label, value }) => (
                  <th key={value}>
                    {label}{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === value ? "active-ac" : ""
                        }`}
                        onClick={() => setFilter(value, "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === value
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter(value, "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>
                ))}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {scans && scans.length ? (
                scans.map((scan, index) => (
                  <ScanRuleRow
                    key={index}
                    scanData={scan}
                    onEdit={onEdit}
                    batchViewHandler={() =>
                      batchViewHandler(scan.batch ? scan.batch._id : "")
                    }
                    userViewHandler={() =>
                      userViewHandler(scan.user ? scan.user._id : "")
                    }
                    onDelete={() => {
                      onDelete(
                        scan?._id,
                        scan?.name
                        // product.totalBatches
                      );
                    }}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={5} className={"text-center image-no-data-xs"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
