import React from 'react';
import socketIOClient from 'socket.io-client';
import { getToken } from './helpers';
import { AppConfig } from './config';
import PHLContext from './PHLContext';

let socket = socketIOClient(AppConfig.SOCKET_ENDPOINT, {
  query: {
    token: getToken(),
  },
  secure: true,
});

const PHLProvider = props => {
  // useEffect(() => {
  //   console.log('inside provider useeffect', socket);

  //   if (!socket) {
  //     console.log('inside if');
  //     socket = socketIOClient(AppConfig.SOCKET_ENDPOINT, {
  //       query: {
  //         token: getToken(),
  //       },
  //       secure: true,
  //     });
  //   }
  // }, []);
  return (
    <PHLContext.Provider
      value={{
        socket,
      }}
    >
      {props.children}
    </PHLContext.Provider>
  );
};

export default PHLProvider;
