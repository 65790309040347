import React from "react";
import Skeleton from "react-skeleton-loader";

export const ProductInputLoader = () => (
  <>
    {" "}
    <form className='product_add'>
      <div className='row'>
        <div className='col-md-3 mob-show'>
        <div className='img-col-product'>
                <Skeleton color ="#e5e9ef" height={"150px"} width={"100%"}/>
                </div>
               
                <div className='file-upload-btn '>
                <Skeleton color ="#e5e9ef" height={"25px"} width={"100%"}/>
                </div>
        </div>
        <div className='col-md-9'>
          <div className='row'>
            <div className='col-md-6 skeleton-bottom'>
            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
            <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
              
            </div>
            <div className='col-md-6 skeleton-bottom'>

            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
              <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
              
            </div>
            <div className='col-md-12 skeleton-bottom'>
            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                  <Skeleton color ="#e5e9ef" height={"100px"} width={"100%"}/>
              
              
            </div>
          </div>
        </div>
        <div className='col-md-3 mob-hide'>
                <div className='img-col-product'>
                <Skeleton color ="#e5e9ef" height={"150px"} width={"100%"}/>
                </div>
               
                <div className='file-upload-btn '>
                <Skeleton color ="#e5e9ef" height={"25px"} width={"100%"}/>
                </div>
         </div>
        

        <div className='col-md-12 footer-form'>
              <Skeleton  color ="#e5e9ef" height={"40px"} />

               <Skeleton color ="#e5e9ef" height={"40px"} />
              </div>
      </div>
    </form>
  </>
);
