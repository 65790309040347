import message from "../../../../assets/img/contact-message.svg";
import id from "../../../../assets/img/generalView/id.svg";
import name from "../../../../assets/img/generalView/name.svg";
import phone from "../../../../assets/img/generalView/phone.svg";
import address from "../../../../assets/img/generalView/address.svg";
import companySize from "../../../../assets/img/generalView/companySize.svg";
import details from "../../../../assets/img/generalView/details.svg";
import calendar from "../../../../assets/img/generalView/calendar.svg";
import status from "../../../../assets/img/generalView/status.svg";
import arrow from "../../../../assets/img/generalView/downArrow.svg";

import title from "../../../../assets/img/generalView/title.svg";
import location from "../../../../assets/img/generalView/location.svg";
import type from "../../../../assets/img/generalView/type.svg";
import category from "../../../../assets/img/generalView/category.svg";
import phone_call from "../../../../assets/img/generalView/phone-call.svg";

import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import * as qs from "query-string";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import Pagination from "../../../components/pagination";
import { FilterComponent } from "../../../components/filters";
import PHLContext from "../../../../PHLContext";
import { SearchFilter } from "../../../components/filters/searchFilter";
import filterarrowIcon from "../../../../assets/img/filter-arrow.svg";
import pageDropdownIcon from "../../../../assets/img/tag-bd.svg";
import mobSearchIcon from "../../../../assets/img/tag-search-bd.svg";
import "../../../containers/enquiries/general/general.css";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import {
  ViewDetailsShimmer,
  ViewDetailsSecondShimmer,
} from "../../loader/Resusable/viewGeneralDetail";

let lat, long;

class ViewCareersDetail extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      careerDetail: {},
      additionalDataViewJobDetails: {},
      requestId: "",
      IndividualCareerDetail: {},
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      activeId: "",
      sortBy: "createdAt",
      order: "desc",
      search: "",
      newRecord: false,
      isFilterApplied: false,
      showAddForm: false,
      catOption: [],
      userDetail: "",
      isViewModal: false,
      isUpdateTicket: false,
      selectedStatus: "",
      statusList: [
        "Completed",
        "Contacted",
        "Rejected",
        "Applied",
        "Shortlisted",
      ],
      // [
      //   "pending",
      //   "completed",
      //   "acknowledged",
      //   "reopened",
      //   "canceled",
      //   "process",
      // ],
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    const pathNamesDetails = this.props.location.pathname.split("/");
    this.setState(
      {
        userDetail: this.props.location.pathname.split("/")[2], //[3],
        requestId:
          this.props.location.pathname.split("/")[pathNamesDetails?.length - 1],
      },
      () => this.handleQueryParams()
    );

    this.handleQueryParams();
  };
  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    if (query && query.sort === "location") {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      lat = position.coords.latitude;
      long = position.coords.longitude;
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "createdAt",
        order: query.order
          ? query.order
          : // : query.sort === 'scan_id' || query.sort === 'created_at'
            // ? 'desc'
            "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
      },
      () => this.fetchCareersRecords()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }, prevState) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
    const splitValue = this.props.location.pathname.split("/")[2]; //[3];
    if (prevState.userDetail !== splitValue) {
      this.setState({
        userDetail: splitValue,
      });
      setTimeout(() => {
        this.handleQueryParams();
      }, 500);
    }
  };

  fetchCareersRecords = async () => {
    const { currentPage, sortBy, search, limit, newRecord, order, userDetail } =
      this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
    };
    // Function to check if a value is empty (undefined, null, or empty string)
    const isEmpty = (value) =>
      value === undefined || value === null || value === "";

    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      if (!isEmpty(value)) {
        acc[key] = value;
      }
      return acc;
    }, {});
    this.setState({
      isLoading: true,
    });
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }

    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.GET_JOB_APPLICATION_NEW.service,
      ApiRoutes.GET_JOB_APPLICATION_NEW.url,
      ApiRoutes.GET_JOB_APPLICATION_NEW.method,
      ApiRoutes.GET_JOB_APPLICATION_NEW.authenticate,
      // { ...filteredData, order: data?.order == "desc" ? "DESC" : "ASC" },
      { _id: this.state.requestId },
      undefined,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isLoading: false,
      });
    } else {
      const datas = response?.data.data.data;
      const additionalData = response.data.data.jobDetails;
      this.setState({
        careerDetail: datas, //{ ...datas, updatedAt: formatedDate },
        additionalDataViewJobDetails: additionalData,
        // datas?.data?.sort((a, b) => {
        //   if (data?.sortBy === "name") {
        //     // Use localeCompare for string comparison
        //     return data?.order === "asc"
        //       ? a.name.localeCompare(b.name)
        //       : b.name.localeCompare(a.name);
        //   } else if (data?.sortBy === "email") {
        //     // Use localeCompare for string comparison
        //     return data?.order === "asc"
        //       ? a.email.localeCompare(b.email)
        //       : b.email.localeCompare(a.email);
        //   } else {
        //     return 0; // No sorting for other cases
        //   }
        // }),
        newRecord: false,
        totalRecords: datas?.length,
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  DateFormatChange = (val) => {
    const originalDate = new Date(val);
    console.log(val, ":::::");

    // Format the date as MM/DD/YYYY
    const formattedDate = originalDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  componentWillUnmount = () => {
    this.emitSearch.cancel();
  };

  onEdit = (IndividualCareerDetail) => {
    // this.fetchCategoryList();
    this.setState({
      IndividualCareerDetail: IndividualCareerDetail,
      showAddForm: true,
    });
  };

  handleUpdateTicket = async (status) => {
    this.setState({
      selectedStatus: status,
      isLoading: true,
    });
    const payload = {
      _id: this.state.requestId,
      status: status,
    };

    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.CHANGE_STATUS_JOB_APPLICATION_NEW.service,
      ApiRoutes.CHANGE_STATUS_JOB_APPLICATION_NEW.url,
      ApiRoutes.CHANGE_STATUS_JOB_APPLICATION_NEW.method,
      ApiRoutes.CHANGE_STATUS_JOB_APPLICATION_NEW.authenticate,
      undefined,
      payload,
      undefined
    );
    if (response && response.isError) {
      this.setState({
        isLoading: false,
      });
      toast.error(response.messages[0]);
    } else {
      const datas = response?.data?.data;
      const formatedDate = this.DateFormatChange(datas?.updatedAt);
      this.setState({
        careerDetail: { ...datas, updatedAt: formatedDate },
        isLoading: false,
      });
      toast.success(response.data.message);
    }
  };

  handleTickets = (value) => {
    this.setState({
      isUpdateTicket: value,
    });
  };

  render() {
    const {
      careerDetail,
      IndividualCareerDetail,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      order,
      search,
      catOption,
      userDetail,
      isViewModal,
      isUpdateTicket,
      statusList,
      additionalDataViewJobDetails,
    } = this.state;

    let allOptions = [];
    // catOption &&
    //   catOption.forEach((element) => {
    //     allOptions.push({ label: element.categoryName, value: element.id });
    //   });

    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <>
        <div className="container-fluid fluid-pd-2">
          <h1 className="h3-head-mob d-md-none">Enquiries</h1>
          <Breadcrumbs pathname={pathname} />

          <div
            className="card card-pagination card-main"
            style={{ paddingBottom: "20px" }}
          >
            <div
              className="card-header py-7550 align-items-center justify-content-between"
              style={{ borderBottom: "0px" }}
            >
              {isLoading ? (
                <ViewDetailsShimmer />
              ) : (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#0A1F44",
                      }}
                    >
                      Request Details - {careerDetail?.name}
                    </span>
                    <Dropdown className="no-arrow dp-right">
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        className={"filter-btn newArrowcz"}
                      >
                        {"Update Status"}{" "}
                        <span className="staff-value">
                          {
                            // (
                            //   options.filter((filter) => filter.value === selectedFilter)[0] ||
                            //   {}
                            // ).label
                          }
                        </span>{" "}
                        {"showImage" ? (
                          <img src={filterarrowIcon} alt="" />
                        ) : (
                          ""
                        )}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                        {statusList?.map((status, index) => {
                          return (
                            <>
                              <Dropdown.Item
                                as="div"
                                key={index}
                                onClick={() => {
                                  this.handleUpdateTicket(status);
                                }}
                              >
                                {status}
                              </Dropdown.Item>
                            </>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div
                      className="contact-button"
                      onClick={() => {
                        if (careerDetail?.candidatePhone) {
                          window.open(`tel:${careerDetail?.candidatePhone}`);
                        } else if (careerDetail?.email) {
                          window.open(`mailto:${careerDetail?.candidateEmail}`);
                        } else {
                          toast.info("There is no any contact details.");
                        }
                      }}
                    >
                      <span style={{ marginRight: "5px", marginBottom: "2px" }}>
                        <img src={phone_call} alt="" className={"img-main"} />
                      </span>
                      <span>Contact</span>
                    </div>
                  </div>

                  <div className="container-user-details">
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={companySize}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div>Candidate Name</div>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            color: "#4A4A4A",
                            paddingLeft: "24px",
                          }}
                        >
                          {careerDetail?.candidateName
                            ? careerDetail?.candidateName
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={name}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div>Candidate Email</div>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            color: "#4A4A4A",
                            paddingLeft: "24px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              `mailto:${
                                careerDetail?.candidateEmail
                                  ? careerDetail?.candidateEmaill
                                  : careerDetail?.candidateEmail
                              }`
                            );
                          }}
                        >
                          {careerDetail?.candidateEmail
                            ? careerDetail?.candidateEmail
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={phone}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div>Phone Number</div>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            color: "#4A4A4A",
                            paddingLeft: "24px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              `tel:${
                                careerDetail?.candidatePhone
                                  ? careerDetail?.candidatePhone
                                  : careerDetail?.candidatePhone
                              }`
                            );
                          }}
                        >
                          {careerDetail?.candidatePhone
                            ? careerDetail?.candidatePhone
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={details}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div>Resume</div>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            color: "#4A4A4A",
                            paddingLeft: "24px",
                          }}
                        >
                          <a
                            href={careerDetail?.candidateResume}
                            target="_blank"
                          >
                            {careerDetail?.candidateResume
                              ? careerDetail?.candidateResume
                              : "N/A"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div
            className="card card-pagination card-main"
            style={{ marginTop: "24px" }}
          >
            {isLoading ? (
              <ViewDetailsSecondShimmer />
            ) : (
              <>
                <div className="card-header py-7550 align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between">
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#0A1F44",
                      }}
                    >
                      Job Details
                    </span>
                    <div
                      className="button-container"
                      // style={{
                      //   display: "flex",
                      //   alignItems: "center",
                      //   flexWrap: "wrap",
                      // }}
                    >
                      <div
                        style={{
                          marginRight: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "29px",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          borderRadius: "7px",
                          border: "1px #0F51A1 solid",
                          color: "#74798c",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // window.open(
                          //   ` https://webstage.clarity.codes/careers-detail/${careerDetail._id}`
                          // );
                          console.log(careerDetail);
                          this.props.history.push({
                            pathname: `/cms/Web/careers/edit-job/${careerDetail._id}`,
                            state: { data: additionalDataViewJobDetails },
                          });
                        }}
                      >
                        View Job
                      </div>
                    </div>
                  </div>
                  <div
                    className="container-user-details"
                    // style={{
                    //   width: "100%",
                    //   gridTemplateColumns: "auto auto auto auto",
                    //   display: "grid",
                    //   paddingTop: "35px",
                    //   paddingBottom: "35px",
                    // }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "10px 0px 0px 0px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={id}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div> Job ID</div>
                        </div>
                      </div>
                      <div
                        style={{
                          fontWeight: 600,
                          color: "#4A4A4A",
                          paddingLeft: "24px",
                        }}
                      >
                        {careerDetail?.jobTitleId?.jobId}
                      </div>
                    </div>

                    <div>
                      <div
                        className="status-field"
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        //   margin: "10px",
                        // }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={title}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div> Job Title</div>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            color: "#4A4A4A",
                            paddingLeft: "24px",
                          }}
                        >
                          {careerDetail?.jobTitleId?.jobTitle}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="status-field"
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        //   margin: "10px",
                        // }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={location}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div> Location</div>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            color: "#4A4A4A",
                            paddingLeft: "24px",
                          }}
                        >
                          {careerDetail?.jobId?.address}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="status-field"
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        //   margin: "10px",
                        // }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={type}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div> Job Type</div>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            color: "#4A4A4A",
                            paddingLeft: "24px",
                          }}
                        >
                          {careerDetail?.jobTitleId?.jobType}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className="status-field"
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        //   margin: "10px",
                        // }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: "14px",
                              marginRight: "11px",
                            }}
                          >
                            <img
                              src={category}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <div> Job Category</div>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            color: "#4A4A4A",
                            paddingLeft: "24px",
                          }}
                        >
                          {careerDetail?.jobTitleId?.jobCategory}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ViewCareersDetail;
