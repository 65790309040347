import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FilterComponent } from "../../components/filters";
import { SearchFilter } from "../../components/filters/searchFilter";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import debounce from "lodash.debounce";
import * as qs from "query-string";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { TransactionsListComponent } from "../../components/TransactionsListComponent/TransactionsListComponent";
import { SubscribedListComponent } from "../../components/SubscribedListComponent/SubscribedListComponent";

class SubscribedClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribed: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      sortBy: "createdAt",
      search: "",
      order: "desc",
      sortByStatus: "all",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }

  componentDidMount = () => {
    this.handleQueryParams();
  };

  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sortBy || "all",
        sortByStatus: query.status || "all",
        isFilterApplied: query.status || query.search ? true : false,
        order: query.order || "desc",
      },
      () => this.getTransaction()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  getTransaction = async () => {
    const { currentPage, limit, sortBy, search, order, sortByStatus } =
      this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
      sortByStatus,
    };
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_BILLING.service,
      ApiRoutes.GET_BILLING.url,
      ApiRoutes.GET_BILLING.method,
      ApiRoutes.GET_BILLING.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    console.log(response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        subscribed: response.data.data,
      });
      this.setState({
        totalRecords: response.data.count,
      });
    }
  };

  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  setFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };
  setSortFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sortBy = sortBy;
    }

    if (order) {
      params.order = order;
    }

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  viewSubscribed = (value) => {
    this.props.history.push(
      AppRoutes.SUBSCRIBEDVIEW.replace(":id", value.clientId)
    );
  };

  render() {
    const {
      sortBy,
      search,
      subscribed,
      isLoading,
      order,
      currentPage,
      limit,
      totalRecords,
      sortByStatus,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const filterOption = [
      { label: "ALL", value: "all" },
      { label: "Client Name", value: "companyName" },
      { label: "Plan Name", value: "name" },
      { label: "Plan Duration", value: "planDuration" },
      { label: "Date & Time", value: "purchaseDate" },
    ];
    const filtersOptionStatus = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Successful",
        value: "Successful",
      },
      {
        label: "Failed",
        value: "Failed",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return (
      <>
        <div className="container-fluid fluid-pd-2">
          <h1 className="h3-head-mob d-md-none">Subscribed</h1>
          <Breadcrumbs pathname={pathname} />
          <div className="card card-main card-pagination">
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-3 head-cat-col-lg">
                  <h6 className="font-book">Subscribed</h6>
                </div>
                <div className="col-md-9 action-col-lg ">
                  <div className="dp-right d-flex align-items-center customWidthSearchbar">
                    <SearchFilter
                      id={"sm-search-filter"}
                      placeholder={"Search by Plan Name, Client Name"}
                      search={search}
                      onChange={this.handleSearch}
                    />
                    <FilterComponent
                      label={"Sort By"}
                      options={filterOption}
                      selectedFilter={sortBy}
                      onFilterChange={(selectedValue) =>
                        this.setFilter(selectedValue, "sortBy")
                      }
                    />
                    <FilterComponent
                      label={"Status"}
                      options={filtersOptionStatus}
                      selectedFilter={sortByStatus}
                      onFilterChange={(selectedValue) =>
                        this.setFilter(selectedValue, "status")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <SubscribedListComponent
              setFilter={this.setSortFilter}
              subscribed={subscribed}
              isLoading={isLoading}
              order={order}
              sortBy={sortBy}
              viewSubscribed={this.viewSubscribed}
            />
          </div>
          <div className="footer-pg custom-align">
            {!isLoading ? (
              <>
                <div className="float-left mt-2">
                  <p className="page-desc-left">
                    Total: <span className="bold-black">{totalRecords}</span>
                  </p>
                </div>
                <div className="float-right mt-2">
                  {totalRecords > limit ? (
                    <div className="float-right ">
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default SubscribedClients;
