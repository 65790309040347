import React, { Component } from 'react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const FIRST_PAGE = 'FIRST_PAGE';
const LAST_PAGE = 'LAST_PAGE';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    const { totalRecords, currentPage, pageLimit, pageNeighbours = 1 } = props;
    // PAGE LIMIT AND RECORDS
    this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 10;
    this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

    // PAGE NEIGHBOURS CAN BE 0, 1 OR 2
    this.pageNeighbours =
      typeof pageNeighbours === 'number'
        ? Math.max(0, Math.min(pageNeighbours, 2))
        : 0;
    this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

    // STATE
    this.state = {
      currentPage: currentPage ? currentPage : 1,
      totalRecords: typeof totalRecords === 'number' ? totalRecords : 0,
      totalPages: Math.ceil(this.totalRecords / this.pageLimit),
    };
  }
  componentDidUpdate = prevProps => {
    if (prevProps.totalRecords !== this.props.totalRecords) {
      const { totalRecords, pageLimit } = this.props;
      let totPages = Math.ceil(totalRecords / pageLimit);
      this.setState({
        totalRecords,
        totalPages: totPages,
      });
    }
    if (prevProps.pageLimit !== this.props.pageLimit) {
      const { totalRecords, pageLimit } = this.props;
      let totPages = Math.ceil(totalRecords / pageLimit);
      this.setState({
        totalPages: totPages,
      });
    }
    if (prevProps.currentPage !== this.props.currentPage) {
      const { currentPage } = this.props;
      this.setState({
        currentPage,
      });
    }
  };
  componentDidMount() {
    this.gotoPage(this.state.currentPage);
  }
  gotoPage = page => {
    const { onPageChanged = f => f } = this.props;
    const currentPage = Math.max(0, Math.min(page, this.state.totalPages));
    this.setState({ currentPage }, () => onPageChanged(currentPage));
  };

  handleClick = page => evt => {
    evt.preventDefault();
    this.gotoPage(page);
  };

  handleMoveLeft = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - this.pageNeighbours /* * 2 - 1 */);
  };

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + this.pageNeighbours /* * 2 + 1 */);
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const { totalPages, currentPage } = this.state;
    const pageNeighbours = this.pageNeighbours;

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = this.pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [FIRST_PAGE, LEFT_PAGE, ...extraPages, ...pages, totalPages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);

          pages = [1, ...pages, ...extraPages, RIGHT_PAGE, LAST_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [FIRST_PAGE, LEFT_PAGE, ...pages, RIGHT_PAGE, LAST_PAGE];
          break;
        }
      }

      return pages;
    }

    return range(1, totalPages);
  };

  render() {
    if (!this.state.totalRecords || this.state.totalPages === 1) {
      return null;
    }

    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <>
        <div className='right mb-2'>
          <div className='pagination'>
            {pages.map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <a
                    key={index}
                    href='true'
                    aria-label='Previous'
                    onClick={this.handleMoveLeft}
                  >
                    <img src='/img/left-arr.svg' alt='' />
                  </a>
                );

              if (page === RIGHT_PAGE)
                return (
                  <a
                    key={index}
                    href='true'
                    aria-label='Next'
                    onClick={this.handleMoveRight}
                  >
                    <img src='/img/right-arr.svg' alt='' />
                  </a>
                );
              if (page === FIRST_PAGE)
                return (
                  <a
                    key={index}
                    href='true'
                    aria-label='First'
                    onClick={this.handleClick(1)}
                  >
                    First <span aria-hidden='true'>«</span>
                  </a>
                );
              if (page === LAST_PAGE)
                return (
                  <a
                    key={index}
                    href='true'
                    aria-label='Last'
                    onClick={this.handleClick(this.totalPages)}
                  >
                    Last <span aria-hidden='true'>»</span>
                  </a>
                );
              return (
                <a
                  className={`${currentPage === page ? ' active' : ''}`}
                  key={index}
                  href='true'
                  onClick={this.handleClick(page)}
                >
                  {page}
                </a>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Pagination;
