import React from "react";
import Skeleton from "react-skeleton-loader";

// import { DashboardWidgetLoader } from "../Resusable/DashboardWidget";
import { TableLoader } from "../Resusable/Table";
export const ScanLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>Scans</h1>
      <p className='breadcrums-top'>
        <Skeleton color ="#fff"/>
      </p>
      <div className='card mb-4 card-main'>
        <div className='card-header py-75  align-items-center justify-content-between'>

        <div className='row'>
        <div className='col-md-2  head-col'>
                <h6 className='font-book '>
                <Skeleton color ="#e5e9ef" height={"25px"}/>
                </h6>
              </div>
              <div className='col-md-10 filter-col'>
                <div className='dp-right mr-2' >
                  
                    <Skeleton color ="#e5e9ef" height={"25px"}/>
                  
                </div>
                <div className='dp-right mr-2'>
                  
                    <Skeleton color ="#e5e9ef" height={"25px"}/>
                  
                </div>
                
              </div>
            </div>
          
        </div>
        <div className='card-body card-table'>
          <div className='table-responsive'>
            <TableLoader columns={6} rows={10} />
          </div>
        </div>
      </div>
    </div>
  </>
);
