/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { categoryValidator } from "../../../validators";
import {
  AddCategoryComponent,
  ListCategoryComponent,
} from "../../components/category";
import { CategoryCard } from "../../components/loader/Resusable/CategoryCard";
import Pagination from "../../components/pagination";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import noData from "../../../assets/img/no-data.png";
import "./manage-video-category.css";

class ManageVideoCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryName: "",
      errors: null,
      isLoading: false,
      isAdding: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 11,
      isFormOpen: false,
      isUpdating: false,
      activeId: "",
    };
  }
  componentDidMount() {
    this.handleQueryParams();
  }
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
      },
      () => this.fetchCategories()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchCategories = async (loading = true) => {
    const { currentPage, limit } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
    };
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CATEGORIES_LIST.service,
      ApiRoutes.CATEGORIES_LIST.url,
      ApiRoutes.CATEGORIES_LIST.method,
      ApiRoutes.CATEGORIES_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isUpdating: false,
      isAdding: false,
      categoryName: "",
      activeId: "",
      isLoading: false,
      isFormOpen: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { categories, totalRecords },
      } = response.data;
      this.setState({ categories, totalRecords });
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  onAddBtnClick = () => {
    this.setState((prevState) => ({
      isFormOpen: !prevState.isFormOpen,
      errors: null,
      categoryName: "",
    }));
  };
  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      [name]: value,
      errors: { ...this.state.errors, [name]: "" },
    });
  };
  addCategory = async (categoryName) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_CATEGORY.service,
      ApiRoutes.ADD_CATEGORY.url,
      ApiRoutes.ADD_CATEGORY.method,
      ApiRoutes.ADD_CATEGORY.authenticate,
      undefined,
      { cat_name: categoryName }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isAdding: false,
        errors: {
          categoryName:
            response.validationErrors && response.validationErrors.cat_name,
        },
      });
    } else {
      this.setState({
        errors: null,
      });
      await this.fetchCategories(false);
      toast.success(response.messages[0]);
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { categoryName } = this.state;
    const { isValid, errors } = categoryValidator({ categoryName });
    if (isValid) {
      this.addCategory(categoryName);
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };
  onCancel = () => {
    this.setState({
      errors: null,
      categoryName: "",
      isFormOpen: false,
    });
  };

  setFilter = (event, id, cat_name) => {
    console.log(event);
  };

  deleteCategory = async (id, name) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to delete this category ?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isUpdating: true,
      activeId: id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_CATEGORY.service,
      ApiRoutes.DELETE_CATEGORY.url.replace(":id", id),
      ApiRoutes.DELETE_CATEGORY.method,
      ApiRoutes.DELETE_CATEGORY.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchCategories(false);
      toast.success(response.messages[0]);
    }
  };
  render() {
    const {
      categories,
      categoryName,
      errors,
      isAdding,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      isFormOpen,
      isUpdating,
      activeId,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Manage Video</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card card-pagination card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-cat-col-lg">
                <h6 className="font-book ">Manage Video Category</h6>
              </div>
              <div className="col-md-9 action-col-lg ">
                <div className="dp-right">
                  <button
                    type="button"
                    className="btn btn-primary add-btn"
                    id="add-cat-btn"
                    onClick={this.onAddBtnClick}
                  >
                    {" "}
                    Add Category
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body card-grid row-grid-fixed manageVideos_section">
            <div className="row">
              {isFormOpen ? (
                <div className="col-md-3 col-vd">
                  <AddCategoryComponent
                    categoryName={categoryName}
                    errors={errors}
                    isLoading={isAdding}
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                    onCancel={this.onCancel}
                  />
                </div>
              ) : null}
              {isLoading ? (
                <CategoryCard />
              ) : categories && categories.length ? (
                <ListCategoryComponent
                  statusText={true}
                  categories={categories}
                  deleteCategory={this.deleteCategory}
                  isUpdating={isUpdating}
                  setFilter={this.setFilter}
                  activeId={activeId}
                  {...this.props}
                />
              ) : (
                <div className="col-md-12 col-vd-nodata">
                  <img src={noData} alt="" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ManageVideoCategory;
