import React from "react";
import plusIcon from "../../../assets/img/plusIcon.svg";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import noData from "../../../assets/img/no-data.png";

export const ClientAddress = ({ address, onAddressEdit, handleShow }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Addresses</h3>
          </div>
        </div>

        <div className="col-md-12">
          <div className="card contactDetailCard">
            {address && address.length > 0 ? (
              address.map((info, index) => (
                <div className="row" key={index}>
                  <div className="col-md-12">
                    <div className="editRow">
                      <h5 style={{ marginBottom: "30px" }}>
                        {info.addressType === "Head Quarter"
                          ? `${info.addressType}`
                          : `Address Type - ${info.addressType}`}
                      </h5>
                      <Button
                        className="btn btn-secondary th-btn edit-tb action-block-btn  bg-white"
                        onClick={() => onAddressEdit(info)}
                      >
                        <>
                          <span>
                            <img src={editIcon} alt="" className={"img-main"} />
                            <img
                              src={editIconWhite}
                              alt=""
                              className={"img-hover"}
                            />
                          </span>
                          Edit
                        </>
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <h6>Address Line 1</h6>
                    <p>{info.addressLine1}</p>
                  </div>
                  <div className="col-md-3">
                    <h6>Address Line 2</h6>
                    <p>{info.addressLine2}</p>
                  </div>
                  <div className="col-md-3">
                    <h6>State</h6>
                    <p>{info.state || "N/A"}</p>
                  </div>
                  <div className="col-md-3"></div>
                  <div className="col-md-3">
                    <h6>Country</h6>
                    <p>{info.country}</p>
                  </div>
                  <div className="col-md-3">
                    <h6>Post Code</h6>
                    <p>{info.postCode}</p>
                  </div>
                  <div className="col-md-12">
                    <hr />
                  </div>
                </div>
              ))
            ) : (
              <div className="no-tr">
                <div colSpan={8} className={"text-center image-no-data-sm"}>
                  <img src={noData} alt="" />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-12 text-center">
                <button className="modalButton" onClick={handleShow}>
                  <img src={plusIcon} /> Add Addresses
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// export default ClientAddress;
