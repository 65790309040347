import Swal from 'sweetalert2';
import './Sweetalert.css';

const SweetAlertComponent = async obj => {
  let {
    title,
    text,
    type,
    showCancelButton = true,
    confirmButtonColor,
    cancelButtonColor,
    cancelButtonText,
    confirmButtonText,
  } = obj || {};
  return await Swal.fire({
    title: title || '',
    text: text || 'You will not be able to revert this!',
    icon: type === 'error' ? 'error' : undefined,
    showCancelButton: showCancelButton,
    confirmButtonColor: confirmButtonColor || '#0f51a1',
    cancelButtonColor: cancelButtonColor || '#bfbec3',
    confirmButtonText: confirmButtonText || 'Yes',
    cancelButtonText: cancelButtonText || 'Cancel',
  });
};

export default SweetAlertComponent;
