/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, pagesOption } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { ListStaffComponent } from "../../components/staffs";
import { GridStaffComponent } from "../../components/staffs";
import { CategoryCard } from "../../components/loader/Resusable/CategoryCard";
import Pagination from "../../components/pagination";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { FilterComponent } from "../../components/filters";
import { SearchFilter } from "../../components/filters/searchFilter";
import pageDropdownIcon from "../../../assets/img/tag-bd.svg";
import gridActive from "../../../assets/img/grid-active.svg";
import ListNoActive from "../../../assets/img/list-noactive.svg";
import gridNoActive from "../../../assets/img/grid-noactive.svg";
import ListActive from "../../../assets/img/list-active.svg";
import noData from "../../../assets/img/no-data.png";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import "./reports.css";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: [],
      errors: null,
      isLoading: false,
      isAdding: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 11,
      sortBy: "all",
      search: "",
      isFormOpen: false,
      isUpdating: false,
      activeId: "",
      viewMode: "grid",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sort || query.status || "all",
        viewMode: query.view || "grid",
      },
      () => this.fetchStaffReq()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  fetchStaffReq = async (loading = true) => {
    const { currentPage, limit, sortBy, search } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      sortByStatus: sortBy !== "name" ? sortBy : "",
      search,
      order: sortBy === "createdAt" ? "desc" : "asc",
    };
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF.service,
      ApiRoutes.GET_STAFF.url,
      ApiRoutes.GET_STAFF.method,
      ApiRoutes.GET_STAFF.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { staff, totalRecords },
      } = response.data;
      this.setState({
        staff,
        totalRecords,
      });
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  viewHandler = (id) => {
    const {
      props: {
        location: { search },
      },
    } = this;
    this.props.history.push({
      pathname: AppRoutes.VIEW_STAFF.replace(":id", id),
      state: {
        search,
      },
    });
  };
  setFilter = (sortBy) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      if (sortBy === "name") {
        params.sort = sortBy;
        params.order = "asc";
      } else {
        delete query.sort;
        params.status = sortBy;
      }
    }

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  render() {
    const {
      staff,
      viewMode,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      search,
      isUpdating,
      // activeId,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const filtersOption = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Staff Name",
        value: "name",
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Suspended",
        value: "Suspended",
      },
      {
        label: "Terminated",
        value: "Terminated",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Manage Staff</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card card-pagination card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book ">Staff Details</h6>
              </div>

              <div className="col-md-9  mob-filter-data">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn-mob"}
                  >
                    {" "}
                    <span className="staff-value">
                      {
                        (
                          filtersOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{" "}
                    <img src={pageDropdownIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                    {filtersOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.setFilter(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dp-right mob-ic">
                  <Link
                    className=" icon-btn"
                    to={`${AppRoutes.MANAGE_STAFF}?view=list`}
                  >
                    <img
                      src={viewMode === "list" ? ListActive : ListNoActive}
                      alt=""
                    />
                  </Link>
                  <Link
                    className=" icon-btn"
                    to={`${AppRoutes.MANAGE_STAFF}?view=grid`}
                  >
                    <img
                      src={viewMode === "grid" ? gridActive : gridNoActive}
                      alt=""
                    />
                  </Link>
                </div>
              </div>

              <div className="col-md-9 filter-col-lg">
                <div className="dp-right">
                  <Link
                    className="btn btn-primary add-btn add-wd-1024"
                    to={AppRoutes.MANAGE_STAFF_REQUESTS}
                  >
                    {" "}
                    Staff Onboarding
                  </Link>
                </div>
                <FilterComponent
                  options={filtersOption}
                  selectedFilter={sortBy}
                  onFilterChange={this.setFilter}
                />

                <SearchFilter
                  id={"sm-search-filter"}
                  placeholder={"Search by Name and Employee ID"}
                  search={search}
                  onChange={this.handleSearch}
                />

                <div className="dp-right">
                  <Link
                    className=" icon-btn"
                    to={`${AppRoutes.MANAGE_STAFF}?view=list`}
                  >
                    <img
                      src={viewMode === "list" ? ListActive : ListNoActive}
                      alt=""
                    />
                  </Link>
                  <Link
                    className=" icon-btn"
                    to={`${AppRoutes.MANAGE_STAFF}?view=grid`}
                  >
                    <img
                      src={viewMode === "grid" ? gridActive : gridNoActive}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {viewMode === "grid" ? (
            <div className="card-body card-grid row-grid-fixed">
              <div className="row ">
                {isLoading ? (
                  <CategoryCard />
                ) : staff && staff.length ? (
                  <GridStaffComponent
                    staff={staff}
                    isUpdating={isUpdating}
                    viewHandler={this.viewHandler}
                  />
                ) : (
                  <div className="col-md-12 col-vd-nodata">
                    <img src={noData} alt="" />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ListStaffComponent
              staff={staff}
              isLoading={isLoading}
              isUpdating={isUpdating}
              viewHandler={this.viewHandler}
            />
          )}
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Reports;
