import React from "react";
import { TableLoader } from "../../../components/loader/Resusable/Table"; //"../loader/Resusable/Table";
import noData from "../../../../assets/img/no-data.png";
import { CareersRow } from "./careersRow";

export const CareersListComponent = ({
  careerDetail,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
  fetchCareerRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  viewUser,
  handleUpdateStatus,
}) => {
  console.log(careerDetail, ":::details");
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={7} rows={10} />
        ) : (
          <>
            <table width="100%" cellSpacing="0">
              <thead className="pad-top">
                <tr>
                  <th style={{ Width: "350px" }}>
                    Candidate{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "name"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("name", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "name"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("name", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>
                  <th style={{ Width: "350px" }}>
                    Job ID{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "jobId"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("jobId", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "jobId"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("jobId", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>

                  <th style={{ Width: "350px" }}>
                    Job Title{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "jobTitle"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("jobTitle", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "jobTitle"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("jobTitle", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>
                  <th style={{ Width: "420px" }}>
                    Location{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "location"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("location", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "location"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("location", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>
                  <th>
                    Phone{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "phone"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("phone", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "phone"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("phone", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>

                  <th>
                    Status{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "status"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("status", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "status"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("status", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>

                  <th
                    style={{
                      // width: "160px",
                      // paddingRight: "20%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        // width: "160px",
                        // paddingRight: "20%",
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "38px",
                      }}
                    >
                      Action
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {careerDetail && careerDetail.length ? (
                  careerDetail.map((IndividualCareerDetail, index) => (
                    <CareersRow
                      key={index}
                      IndividualCareerDetail={IndividualCareerDetail}
                      fetchCareerRecords={fetchCareerRecords}
                      onEdit={() => onEdit(IndividualCareerDetail)}
                      onDelete={() => onDelete(IndividualCareerDetail._id)}
                      viewUser={() => viewUser(IndividualCareerDetail)}
                      handleUpdateStatus={(status, val) =>
                        handleUpdateStatus(status, val)
                      }
                    />
                  ))
                ) : (
                  <tr className="no-tr">
                    <td colSpan={5} className={"text-center image-no-data-xs"}>
                      {isFilterApplied ? (
                        <img src={noData} alt="" />
                      ) : (
                        <img src={noData} alt="" />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
