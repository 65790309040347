/** @format */

import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Select from "react-select";
import { AppConfig, customReactSelectStyles } from "../../../../config";
import { InputTextField } from "../../forms";
import countryStateData from "../../../../common/countryState.json";
import defaultImg from "../../../../assets/img/group-12.png";
import trashIcon from "../../../../assets/img/trash-btn.svg";

export const StoreInfoFormComponent = ({
  storeData,
  isUploading,
  uploadPercentage,
  onChange,
  onFileChange,
  onDeleteFile,
  onSelectOption,
}) => {
  const [statesOption, setStatesOption] = useState([]);
  const {
    companyName = "",
    storeName = "",
    website = "",
    brandName = "",
    insideUSACanada = "",
    businessRegNo = "",
    VATNumber = "",
    businessRegCertificateNo = "",
    stateOfIssuingCertificate = "",
    address1 = "",
    address2 = "",
    country = "",
    postCode = "",
    state = "",
    storeLogo = "",
    errors = {},
  } = storeData;
  const countriesOption = [];
  countryStateData.forEach((element) => {
    if (
      element.name === "United States" ||
      element.name === "Canada" ||
      element.name === "Mexico" ||
      element.name === "The Bahamas" ||
      element.name === "Puerto Rico"
    ) {
      if (insideUSACanada === "yes") {
        countriesOption.push({
          label: element.name,
          value: element.name,
        });
      }
    } else {
      if (insideUSACanada === "no") {
        countriesOption.push({
          label: element.name,
          value: element.name,
        });
      }
    }
  });
  const handleStatesOption = (value) => {
    let temp = [];
    let selectedCountryIndex = countryStateData.findIndex(
      (element) => element.name === value
    );
    if (selectedCountryIndex > -1) {
      countryStateData[selectedCountryIndex].states
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((state) => {
          temp.push({
            label: state.name,
            value: state.name,
          });
        });
      setStatesOption(temp);
    }
  };
  useEffect(() => {
    if (country && country.value) {
      handleStatesOption(country.value);
    }
  }, [country]);
  return (
    <div className="row row-mt-4">
      <div className="col-md-12">
        <form className="user_add mt-2">
          <div className="row">
            {window.innerWidth > 768 ? (
              <div className="col-md-12 dp-align-center">
                <input
                  type="radio"
                  id="yes"
                  name="insideUSACanada"
                  value="yes"
                  checked={insideUSACanada === "yes"}
                  onChange={onChange}
                />
                <label className="input-radio" htmlFor="yes">
                  Inside USA/ Canada
                </label>
                <input
                  type="radio"
                  id="no"
                  name="insideUSACanada"
                  value="no"
                  checked={insideUSACanada === "no"}
                  onChange={onChange}
                />
                <label className="input-radio" htmlFor="no">
                  Outside USA/ Canada
                </label>
              </div>
            ) : null}
            <br />
            <div className="col-md-3 mob-show">
              <div className="img-col-user">
                <div className="img-col-user">
                  <img
                    className="user-img"
                    // src={
                    //   storeLogo
                    //     ? `${AppConfig.FILES_ENDPOINT}${storeLogo}`
                    //     : defaultImg
                    // }
                    src={
                      storeLogo
                        ? storeLogo.startsWith("https://")
                          ? storeLogo
                          : `${AppConfig.FILES_ENDPOINT}${storeLogo}`
                        : defaultImg
                    }
                    alt=""
                  />
                </div>
                {isUploading ? (
                  <ProgressBar now={uploadPercentage} className={"mt-2"} />
                ) : null}
                <div className="file-upload-btn ">
                  <label className="file-label">
                    {" "}
                    {storeLogo ? "Change Image" : "Choose Image"}
                    <input
                      className="imgUpload img_up"
                      name="storeLogo"
                      type="file"
                      accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                      onChange={onFileChange}
                    />
                  </label>
                  {storeLogo ? (
                    <span
                      onClick={() => onDeleteFile("storeLogo")}
                      className={"cursor-pointer"}
                    >
                      <img src={trashIcon} alt="" />
                    </span>
                  ) : null}
                </div>
                <div className={"text-danger"}>
                  {errors && errors.storeLogo ? errors.storeLogo : ""}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row">
                {window.innerWidth <= 768 ? (
                  <div className="col-md-12 dp-align-center">
                    <input
                      type="radio"
                      id="yes"
                      name="insideUSACanada"
                      value="yes"
                      checked={insideUSACanada === "yes"}
                      onChange={onChange}
                    />
                    <label className="input-radio" htmlFor="yes">
                      Inside USA/ Canada
                    </label>
                    <input
                      type="radio"
                      id="no"
                      name="insideUSACanada"
                      value="no"
                      checked={insideUSACanada === "no"}
                      onChange={onChange}
                    />
                    <label className="input-radio" htmlFor="no">
                      Outside USA/ Canada
                    </label>
                  </div>
                ) : null}
                <br />

                <div className="col-md-4">
                  <InputTextField
                    id="companyName"
                    label={"Company Name"}
                    className={"form-control-user"}
                    name="companyName"
                    value={companyName}
                    onChange={onChange}
                    maxLength={30}
                    errorMessage={
                      errors && errors.companyName ? errors.companyName : ""
                    }
                  />
                </div>
                <div className="col-md-4">
                  <InputTextField
                    id="storeName"
                    label={"Store Name"}
                    className={"form-control-user"}
                    name="storeName"
                    value={storeName}
                    maxLength={30}
                    onChange={onChange}
                    errorMessage={
                      errors && errors.storeName ? errors.storeName : ""
                    }
                  />
                </div>
                <div className="col-md-4">
                  <InputTextField
                    id="website"
                    label={"Website"}
                    className={"form-control-user"}
                    name="website"
                    value={website}
                    onChange={onChange}
                    maxLength={30}
                    errorMessage={
                      errors && errors.website ? errors.website : ""
                    }
                  />
                </div>
                <div className="col-md-4">
                  <InputTextField
                    id="brandName"
                    label={"Brand Name"}
                    className={"form-control-user"}
                    name="brandName"
                    value={brandName}
                    onChange={onChange}
                    maxLength={14}
                  />
                </div>
                {/* <div className='col-md-4'>
                  <div className='form-group'>
                    <label className={`form-control-label`}>
                      Inside USA/ Canada?
                    </label>
                    <Select
                      placeholder={''}
                      options={[
                        {
                          label: 'Yes',
                          value: true,
                        },
                        {
                          label: 'No',
                          value: false,
                        },
                      ]}
                      styles={customStyles}
                      value={insideUSACanada}
                      onChange={selectedOption => onSelect(selectedOption)}
                    />
                  </div>
                </div> */}
                {insideUSACanada === "yes" ? (
                  <>
                    <div className="col-md-4">
                      <InputTextField
                        id="businessRegNo"
                        label={"Business Reg. No."}
                        className={"form-control-user"}
                        name="businessRegNo"
                        value={businessRegNo}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.businessRegNo
                            ? errors.businessRegNo
                            : ""
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        id="VATNumber"
                        label={"VAT Number"}
                        className={"form-control-user"}
                        name="VATNumber"
                        value={VATNumber}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.VATNumber ? errors.VATNumber : ""
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-4">
                      <InputTextField
                        id="businessRegCertificateNo"
                        label={"Business Reg. Certificate"}
                        className={"form-control-user"}
                        name="businessRegCertificateNo"
                        value={businessRegCertificateNo}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.businessRegCertificateNo
                            ? errors.businessRegCertificateNo
                            : ""
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        id="stateOfIssuingCertificate"
                        label={"State of issuing Certificate"}
                        className={"form-control-user"}
                        name="stateOfIssuingCertificate"
                        value={stateOfIssuingCertificate}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.stateOfIssuingCertificate
                            ? errors.stateOfIssuingCertificate
                            : ""
                        }
                      />
                    </div>
                  </>
                )}
                <div className="col-md-4">
                  <InputTextField
                    id="store_address_line_1"
                    label={"Address Line 1"}
                    className={"form-control-user"}
                    name="address1"
                    value={address1}
                    onChange={onChange}
                    errorMessage={
                      errors && errors.address1 ? errors.address1 : ""
                    }
                  />
                </div>
                <div className="col-md-4">
                  <InputTextField
                    id="store_address_line_2"
                    label={"Address Line 2"}
                    className={"form-control-user"}
                    name="address2"
                    value={address2}
                    onChange={onChange}
                  />
                </div>
                <div className="col-md-4">
                  {/* <InputTextField
                  id="country"
                  label={"Country"}
                  className={"form-control-user"}
                  name="country"
                  value={country}
                  onChange={onChange}
                /> */}
                  <label
                    for="country"
                    class="form-control-label form-control-user"
                  >
                    Country
                  </label>
                  <Select
                    placeholder={"Select country"}
                    label={"Country"}
                    className={"form-control-user"}
                    id={"country"}
                    styles={customReactSelectStyles}
                    options={countriesOption}
                    value={country}
                    onChange={(selectedOptions) =>
                      onSelectOption(selectedOptions, "country", "storeData")
                    }
                    // name="country"
                    menuPortalTarget={document.querySelector("body")}
                  />
                </div>
                <div className="col-md-4">
                  {/* <InputTextField
                  id="state"
                  label={"State"}
                  className={"form-control-user"}
                  name="state"
                  value={state}
                  onChange={onChange}
                /> */}
                  <label
                    for="state"
                    class="form-control-label form-control-user"
                  >
                    State
                  </label>
                  <Select
                    options={statesOption}
                    placeholder={"Select state"}
                    id={"state"}
                    styles={customReactSelectStyles}
                    menuPortalTarget={document.querySelector("body")}
                    value={state}
                    noOptionsMessage={() => {
                      return country && country.value
                        ? "No states found"
                        : "First select country";
                    }}
                    onChange={(selectedOptions) =>
                      onSelectOption(selectedOptions, "state", "storeData")
                    }
                  />
                </div>
                <div className="col-md-4">
                  <InputTextField
                    id="store_post_code"
                    label={"Post Code"}
                    className={"form-control-user"}
                    name="postCode"
                    value={postCode}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 mob-hide">
              <div className="img-col-user">
                <img
                  className="user-img"
                  // src={
                  //   storeLogo
                  //     ? `${AppConfig.FILES_ENDPOINT}${storeLogo}`
                  //     : defaultImg
                  // }
                  src={
                    storeLogo
                      ? storeLogo.startsWith("https://")
                        ? storeLogo
                        : `${AppConfig.FILES_ENDPOINT}${storeLogo}`
                      : defaultImg
                  }
                  alt=""
                />
              </div>
              {isUploading ? (
                <ProgressBar now={uploadPercentage} className={"mt-2"} />
              ) : null}
              <div className="file-upload-btn ">
                <label className="file-label">
                  {" "}
                  {storeLogo ? "Change Image" : "Choose Image"}
                  <input
                    className="imgUpload img_up"
                    name="storeLogo"
                    type="file"
                    accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                    onChange={onFileChange}
                  />
                </label>
                {storeLogo ? (
                  <span
                    onClick={() => onDeleteFile("storeLogo")}
                    className={"cursor-pointer"}
                  >
                    <img src={trashIcon} alt="" />
                  </span>
                ) : null}
              </div>
              <div className={"text-danger"}>
                {errors && errors.storeLogo ? errors.storeLogo : ""}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
