import React from "react";
import Skeleton from "react-skeleton-loader";

// import { DashboardWidgetLoader } from "../Resusable/DashboardWidget";
import { BatchInputLoader } from "../Resusable/BatchInput";
export const BatchAddLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>Batches</h1>
      <p className='breadcrums-top'>
        <Skeleton color ="#fff" />
      </p>
      <div className='card mb-4 card-main'>
        <div className='card-header py-75  align-items-center justify-content-between'>

        <div className='row'>
        <div className='col-md-12'>
                <h6 className='font-book '>
                <Skeleton color ="#e5e9ef" height={"25px"}/>
                </h6>
              </div>
            </div>
          
        </div>
        <div className='card-body card-table'>
          <div className='table-responsive'>
            <BatchInputLoader />
          </div>
        </div>
      </div>
    </div>
  </>
);
