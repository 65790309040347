/** @format */

import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  ApiRoutes,
  genderOptions,
  AppRoutes,
  DB_ACCPTABLE_FORMAT,
} from "../../../config";
import { ApiHelper } from "../../../helpers";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { InfoDetailComponent2 } from "../../components/staffs";
import { message } from "../../../common";
import "./manage-staff.css";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import moment from "moment";
import { editStaff } from "../../../validators/editStaff";
class ViewStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: "",
      staffData: {},
      // Permission module related fields
      permission: [],
      selectedModule: null,
      subModules: [],
      permissionErrors: null,
      isInfoEditable: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      isLoading: false,
      isUpdating: false,
      isUploading: false,
      uploadPercentage: 0,
      dateError: false,
    };
  }

  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        staffId: id,
      },
      () => this.viewStaff()
    );
  };

  viewStaff = async () => {
    const { staffId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_STAFF.service,
      ApiRoutes.VIEW_STAFF.url.replace(":id", staffId),
      ApiRoutes.VIEW_STAFF.method,
      ApiRoutes.VIEW_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    console.log("response", response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response?.data;
      const [
        {
          first_name = "",
          last_name = "",
          employee_id = "",
          email = "",
          phone = "",
          profile_picture,
          other_info = {},
          birth_date = "",
          joining_date = "",
          personal_email = "",
          office_location = "",
          office_phone = "",
          gender = "",
          permission = "",
          portal_access = "",
          status = "",
          role_id = "",
          roleName = "",
          bio = "",
        },
      ] = data || {};
      const {
        address1 = "",
        address2 = "",
        state = "",
        country = "",
        postCode = "",
      } = other_info || {};
      this.setState({
        staffData: {
          first_name,
          last_name,
          employee_id: employee_id || "",
          email,
          phone: phone || "",
          profile_picture,
          gender: gender || "",
          address1: address1 || "",
          address2,
          state,
          country,
          postCode,
          birth_date: birth_date ? new Date(birth_date) : null || "",
          joining_date: joining_date ? new Date(joining_date) : null,
          personal_email: personal_email || "",
          office_location: office_location || "",
          office_phone: office_phone || "",
          permission,
          portal_access,
          status,
          roleName,
          temp_role_id: role_id,
          role_id: role_id || "",
          bio,
        },
      });
    }
  };
  updateStaffInfo = async (data) => {
    this.setState({
      isUpdating: true,
      updatingSection: "contact",
    });
    const { staffId } = this.state;

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_INFO.service,
      ApiRoutes.UPDATE_STAFF_INFO.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_INFO.method,
      ApiRoutes.UPDATE_STAFF_INFO.authenticate,
      undefined,
      {
        ...data,
        // gender: data.gender ? data.gender.value : "",
        role_id: data.role_id ? data.role_id.value : "",
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isInfoEditable: false,
      });
      toast.success(response.messages[0]);
      this.viewStaff();
    }
  };
  onCancel = () => {
    this.setState({
      isInfoEditable: false,
    });
  };
  onEdit = () => {
    this.setState({
      isInfoEditable: true,
    });
  };
  handleInfoChange = (event) => {
    let {
      target: { name, value, type, checked },
    } = event;
    if (name === "isPermission") {
      value = checked ? "assign" : "unAssign";
    }
    if (name === "phone" && isNaN(value)) {
      return;
    }
    if (name === "office_phone" && isNaN(value)) {
      return;
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        [name]: value.trimLeft(),
        errors: {
          ...this.state.staffData.errors,
          [name]: "",
        },
      },
    });
  };
  portalAccessSwitcher = async (val) => {
    if (val === this.state.staffData.portal_access) return;
    const { value } = await SweetAlertComponent({
      title: "Are you sure?",
      text: val
        ? "Do you want to enable portal access."
        : "Do you want to disable portal access",
    });
    if (!value) {
      return;
    }
    const { staffId, staffData } = this.state;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_INFO.service,
      ApiRoutes.UPDATE_STAFF_INFO.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_INFO.method,
      ApiRoutes.UPDATE_STAFF_INFO.authenticate,
      undefined,
      { portal_access: val }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        staffData: {
          ...this.state.staffData,
          portal_access: val,
        },
      });
      toast.success(response.messages[0]);
    }
  };
  onSelect = (data) => {
    console.log("here", data);
    if (data.value) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          gender: data,
        },
      });
    }
  };
  handleDateChange = (date) => {
    if (date) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          birth_date: date,
        },
      });
    }
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          errors: {
            ...this.state.staffData.errors,
            [name]: message.InvalidImages,
          },
        },
      });
      return;
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        errors: {
          ...this.state.staffData.errors,
          [name]: "",
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = "";
  };
  onDeleteFile = () => {
    this.setState({
      staffData: {
        ...this.state.staffData,
        profile_picture: "",
        errors: {
          ...this.state.staffData.errors,
          profile_picture: "",
        },
      },
    });
  };
  uploadPicture = async (data, name) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      this.setState({
        staffData: { ...this.state.staffData, [name]: path },
      });
    }
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
    });
  };
  // submitDetails = () => {
  //   this.updateStaffInfo();
  // };

  submitDetails = (event) => {
    event && event.preventDefault();
    const { staffData, dateError } = this.state;
    if (!staffData.birth_date) {
      this.setState({
        dateError: true,
      });
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        errors: {
          ...this.state.staffData.errors,
        },
      },
    });
    const { isValid, errors } = editStaff(staffData);
    if (
      isValid &&
      staffData.birth_date &&
      staffData.address1 &&
      staffData.role_id &&
      staffData.bio &&
      staffData.gender
    ) {
      this.updateStaffInfo(staffData);
    } else {
      console.log("staffData", staffData);

      this.setState({
        dateError: true,
        staffData: {
          ...this.state.staffData,
          errors: errors,
        },
      });
    }
  };
  changeStaffStatus = async (status) => {
    const { staffId } = this.state;
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to change status of staff?`,
    });
    if (!value) {
      return;
    }
    /* this.setState({
        isLoading: true,
    }); */
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_STATUS.service,
      ApiRoutes.UPDATE_STAFF_STATUS.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_STATUS.method,
      ApiRoutes.UPDATE_STAFF_STATUS.authenticate,
      undefined,
      { status }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        staffData: {
          ...this.state.staffData,
          status: status,
        },
      });
      toast.success(response.messages[0]);
    }
  };
  viewPermission = () => {
    const { staffId } = this.state;

    this.props.history.push(
      AppRoutes.MANAGE_STAFF_PERMISSION.replace(":id", staffId)
    );
  };
  customBreadCrumb = () => {
    const {
      isLoading,
      staffData: { first_name = "", last_name = "" },
    } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      [first_name, last_name].filter(Boolean).join(" ") || "View user"
    );
  };
  onRoleSelect = (data) => {
    console.log("here", data);
    if (data && data.value) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          role_id: data,
          temp_role_id: "",
        },
      });
    }
  };

  handleGenderChange = (event) => {
    const {
      target: { value, name },
    } = event;

    console.log("here", event);
    this.setState({
      staffData: {
        ...this.state.staffData,
        [name]: value && value === "Male" ? "male" : "female",
      },
    });
  };
  render() {
    const {
      isLoading,
      isInfoEditable,
      uploadPercentage,
      staffData,
      staffId,
      isUpdating,
      isUploading,
      dateError,
    } = this.state;

    console.log("here is staffData", staffData);
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
        />
        <div className="row">
          <div className="col-md-12 d-flex flex-column">
            <InfoDetailComponent2
              staffData={staffData}
              staffId={staffId}
              isLoading={isLoading}
              isEditable={isInfoEditable}
              isUpdating={isUpdating}
              uploadPercentage={uploadPercentage}
              isUploading={isUploading}
              onEdit={this.onEdit}
              onCancel={this.onCancel}
              onChange={this.handleInfoChange}
              onDateChange={this.handleDateChange}
              onSelect={this.onSelect}
              onSubmit={this.submitDetails}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              viewPermission={this.viewPermission}
              changeStaffStatus={this.changeStaffStatus}
              portalAccessSwitcher={this.portalAccessSwitcher}
              portalAccessDiv={true}
              dateError={dateError}
              onRoleSelect={this.onRoleSelect}
              handleGenderChange={this.handleGenderChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ViewStaff;
