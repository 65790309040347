import React from "react";
import Skeleton from "react-skeleton-loader";
import { TableLoader } from "./Table";

export const BatchHistoryLoader = () => (
  <>
    {" "}

    <div className='card mb-4 card-main flex-fill'>
      <div className='card-header  py-7550  align-items-center justify-content-between'>
            <div className='row'>
              <div className='col-md-12'>
                <h6 className='m-25 font-book '><Skeleton color ="#e5e9ef" height={"25px"}/></h6>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='table-responsive '>
            <TableLoader columns={4} rows={5} />
            </div>
          </div>
        </div>
            


    
  </>
);
