import Validator from "js-object-validation";
import { message } from "../common";

export const requestStore = (data) => {
  const validations = {
    companyName: {
      required: true,
    },
    website: {
      required: true,
    },
    businessRegNo: {
      required: true,
    },
    address1: {
      required: true,
    },
    address2: {
      required: true,
    },
    state: {
      required: true,
    },
    country: {
      required: true,
    },
    postCode: {
      required: true,
    },
    phone: {
      required: true,
      numeric: true,
    },
    // email: {
    //   required: true,
    //   email: true,
    // },
    businessRegNo: {
      required: true,
      numeric: true,
    },
    VATNumber: {
      required: true,
    },
    TradeMarkCertificateNo: {
      required: true,
    },
    businessRegNo_image: {
      required: true,
    },
    VATNumber_image: {
      required: true,
    },
    TradeMarkCertificateNo_image: {
      required: true,
    },
  };
  // if (data.insideUSACanada === "yes") {
  //   validations.businessRegNo = { required: true };
  //   validations.VATNumber = { required: true };
  // }
  // if (data.insideUSACanada === "no") {
  //   validations.businessRegCertificateNo = { required: true };
  //   validations.stateOfIssuingCertificate = { required: true };
  // }
  // Error messages
  const messages = {
    companyName: {
      required: message.Required.replace(":item", "company name"),
    },
    website: {
      required: message.Required.replace(":item", "website"),
    },
    businessRegNo: {
      required: message.Required.replace(
        ":item",
        "business registration number."
      ),
      numeric: message.InvalidCertificate.replace(
        ":item",
        "business registration number"
      ),
    },
    address1: {
      required: message.Required.replace(":item", "address line 1"),
    },
    address2: {
      required: message.Required.replace(":item", "address line 2"),
    },
    state: {
      required: message.Required.replace(":item", "state"),
    },
    country: {
      required: message.Required.replace(":item", "country"),
    },
    postCode: {
      required: message.Required.replace(":item", "post code"),
    },
    phone: {
      required: message.Required.replace(":item", "phone number"),
      numeric: message.InvalidPhone,
    },
    email: {
      required: message.Required.replace(":item", "email"),
      email: message.InvalidEmail,
    },
    VATNumber: {
      required: message.Required.replace(":item", "vat number"),
    },
    TradeMarkCertificateNo: {
      required: message.Required.replace(
        ":item",
        "trade mark certificate number"
      ),
    },
    TradeMarkCertificateNo_image: {
      required: message.Required.replace(
        ":item",
        "trade mark certificate number image"
      ),
    },
    businessRegNo_image: {
      required: message.Required.replace(
        ":item",
        "business registration number image"
      ),
    },
    VATNumber_image: {
      required: message.Required.replace(":item", "vat number image"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
