/** @format */
import React from "react";
import "./batches.css";
import { CardLoader } from "../../components/loader/Resusable/Card";

export const ReportCard = ({
  title,
  size,
  loading,
  total,
  prevString,
  perc,
}) => {
  return (
    <div className={size}>
      {loading ? (
        <CardLoader />
      ) : (
        <div className="graphDataCard">
          <div>
            <p>{title}</p>
            <h5>{total}</h5>
            {prevString !== "" ? (
              <div className="arrowBox">
                {perc > 0 ? (
                  <img
                    src="/img/up-icon.svg"
                    className="card-image"
                    id="card-img-warning"
                    alt=""
                  />
                ) : (
                  <img
                    src="/img/down-icon.svg"
                    className="card-image"
                    id="card-img-warning"
                    alt=""
                  />
                )}
                <span>
                  {Math.abs(perc).toFixed(0)}
                  {prevString}
                </span>
              </div>
            ) : (
              <div className="arrowBox">&nbsp;</div>
            )}
          </div>
          {prevString !== "" ? (
            <div className="chartIcon">
              {perc > 0 ? (
                <img
                  src="/img/Path 1.svg"
                  className="card-image"
                  id="card-img-warning"
                  alt=""
                />
              ) : (
                <img
                  src="/img/Path 2.svg"
                  className="card-image"
                  id="card-img-warning"
                  alt=""
                />
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
