import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { TableLoader } from "../../components/loader/Resusable/Table";
import { CardLoader } from "../../components/loader/Resusable/Card";
import { ScanRow } from "../../components/scans";
import PHLContext from "../../../PHLContext";
import { ScanGraphComponent } from "./ScanGraphComponent";
import { CounterfeitGraphComponent } from "./CounterfeitGraphComponent";
import { TopScannedProductGraphComponent } from "./TopScannedProductGraphComponent";
import { GraphLoader } from "../../components/loader/Dashboard/GraphLoader";
import { LocationChartComponent } from "./LocationChartComponent";
import upIcon from "../../../assets/img/up-icon.svg";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import "./home.css";

class Home extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      scans: [],
      scanData: {},
      counterfeitData: {},
      topScannedProductDataSet: {},
      scanStatsBy: { label: "This month", value: "month" },
      activeLocations: [],
      allActiveLocations: [],
      maximumActiveUser: 0,
      totalUsers: 0,
      userStatisticsIntermsOfLastWeek: 0,
      totalStaff: 0,
      staffStatisticsIntermsOfLastWeek: 0,
      totalClients: 0,
      clientStatisticsIntermsOfLastWeek: 0,
      totalActiveLocations: 0,
      isScanLoading: false,
      isMemberStatisticLoading: false,
      isScanStatisticLoading: false,
      isCounterfeitStatisticLoading: false,
      isTopScannedProductsLoading: false,
      isActiveUserLocationsLoading: false,
    };
  }
  componentDidMount = () => {
    let { socket } = this.context || {};
    if (socket) {
      socket.on(SocketEvents.NEW_SCAN_ADDED, (data) => {
        console.log("event emitted", data);
        let newData = [...this.state.scans];
        const alreadyExistIndex = newData.findIndex(
          (item) => item.scanId === data[0].scanId
        );
        if (alreadyExistIndex > -1) {
          newData.splice(alreadyExistIndex, 1);
          newData.unshift(...data);
          this.setState({
            scans: newData,
          });
        } else {
          this.setState((prevState) => {
            return {
              scans: [...data, ...prevState.scans],
            };
          });
        }
      });
      socket.on(SocketEvents.MEMBER_STATISTICS_UPDATED, (data) => {
        const {
          totalUsers,
          userStatisticsIntermsOfLastWeek,
          totalStaff,
          staffStatisticsIntermsOfLastWeek,
          totalClients,
          clientStatisticsIntermsOfLastWeek,
        } = data;
        this.setState({
          totalUsers,
          userStatisticsIntermsOfLastWeek,
          totalStaff,
          staffStatisticsIntermsOfLastWeek,
          totalClients,
          clientStatisticsIntermsOfLastWeek,
        });
        console.log("event emitted", data);
      });
      socket.on(SocketEvents.SCAN_STATISTICS_UPDATED, (data) => {
        const {
          scanStatisticsData,
          topScannedProductsStatisticsData: { dataset },
        } = data;
        this.setState({
          scanData: scanStatisticsData,
          topScannedProductDataSet: {
            labels: dataset.labels ? dataset.labels.reverse() : [],
            value: dataset.value ? dataset.value.reverse() : [],
          },
        });
        console.log("event emitted", data);
      });
      socket.on(SocketEvents.COUNTERFEIT_STATISTICS_UPDATED, (data) => {
        this.setState({
          counterfeitData: data,
        });
        console.log("event emitted", data);
      });
      socket.on(SocketEvents.ACTIVE_LOCATIONS_UPDATED, (data) => {
        const { activeLocations, totalActiveLocations, allActiveLocations } =
          data;
        this.setState({
          activeLocations,
          totalActiveLocations,
          allActiveLocations,
        });
        console.log("event emitted", data);
      });
    }
    this.fetchScanRecords();
    this.getMemberStatistics();
    this.getScanStatistics();
    this.getCounterfeitStatistics();
    this.topScannedProducts();
    this.activeUserLocations();
  };
  fetchScanRecords = async (isLoading = true) => {
    let data = {
      skip: 0,
      limit: 10,
    };
    this.setState({
      isScanLoading: isLoading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SCANS_LIST.service,
      ApiRoutes.SCANS_LIST.url,
      ApiRoutes.SCANS_LIST.method,
      ApiRoutes.SCANS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isScanLoading: false,
    });
    if (response && response.isError) {
      // toast.error(response.messages[0]);
    } else {
      const {
        data: { scans, totalRecords },
      } = response.data;
      this.setState({ scans, totalRecords });
    }
  };
  getMemberStatistics = async () => {
    this.setState({
      isMemberStatisticLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_MEMEBR_STATISTICS.service,
      ApiRoutes.DASHBOARD_MEMEBR_STATISTICS.url,
      ApiRoutes.DASHBOARD_MEMEBR_STATISTICS.method,
      ApiRoutes.DASHBOARD_MEMEBR_STATISTICS.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isMemberStatisticLoading: false,
    });
    if (response && response.isError) {
      // toast.error(response.messages[0]);
    } else {
      const {
        data: {
          totalUsers,
          userStatisticsIntermsOfLastWeek,
          totalStaff,
          staffStatisticsIntermsOfLastWeek,
          totalClients,
          clientStatisticsIntermsOfLastWeek,
        },
      } = response;
      this.setState({
        totalUsers,
        userStatisticsIntermsOfLastWeek,
        totalStaff,
        staffStatisticsIntermsOfLastWeek,
        totalClients,
        clientStatisticsIntermsOfLastWeek,
      });
    }
  };
  getScanStatistics = async () => {
    const { scanStatsBy } = this.state;
    this.setState({
      isScanStatisticLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_SCAN_STATISTICS.service,
      ApiRoutes.DASHBOARD_SCAN_STATISTICS.url,
      ApiRoutes.DASHBOARD_SCAN_STATISTICS.method,
      ApiRoutes.DASHBOARD_SCAN_STATISTICS.authenticate,
      { type: scanStatsBy ? scanStatsBy.value : "week" },
      undefined
    );
    this.setState({
      isScanStatisticLoading: false,
    });
    if (response && response.isError) {
      // toast.error(response.messages[0]);
    } else {
      const {
        data: { currentScanDataSet, todayScans, totalScans, prevScanDataSet },
      } = response;
      this.setState({
        scanData: {
          currentScanDataSet,
          todayScans,
          totalScans,
          prevScanDataSet,
        },
      });
    }
  };
  getCounterfeitStatistics = async () => {
    this.setState({
      isCounterfeitStatisticLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_Counterfeits_STATISTICS.service,
      ApiRoutes.DASHBOARD_Counterfeits_STATISTICS.url,
      ApiRoutes.DASHBOARD_Counterfeits_STATISTICS.method,
      ApiRoutes.DASHBOARD_Counterfeits_STATISTICS.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isCounterfeitStatisticLoading: false,
    });
    if (response && response.isError) {
      // toast.error(response.messages[0]);
    } else {
      const {
        data: {
          totalCounterfeits,
          todayCounterfeits,
          totalCompleted,
          totalAcknowldeged,
          totalUnderProcess,
          totalUnderReview,
        },
      } = response;
      this.setState({
        counterfeitData: {
          totalCounterfeits,
          todayCounterfeits,
          totalCompleted,
          totalAcknowldeged,
          totalUnderProcess,
          totalUnderReview,
        },
      });
    }
  };
  topScannedProducts = async () => {
    this.setState({
      isTopScannedProductsLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_TOP_SCANNED_PRODUCT.service,
      ApiRoutes.DASHBOARD_TOP_SCANNED_PRODUCT.url,
      ApiRoutes.DASHBOARD_TOP_SCANNED_PRODUCT.method,
      ApiRoutes.DASHBOARD_TOP_SCANNED_PRODUCT.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isTopScannedProductsLoading: false,
    });
    if (response && response.isError) {
      // toast.error(response.messages[0]);
    } else {
      const {
        data: { dataset },
      } = response;

      this.setState({
        topScannedProductDataSet: {
          labels: dataset.labels ? dataset.labels.reverse() : [],
          value: dataset.value ? dataset.value.reverse() : [],
        },
      });
    }
  };
  activeUserLocations = async () => {
    this.setState({
      isActiveUserLocationsLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_ACTIVE_USER_LOCATION.service,
      ApiRoutes.DASHBOARD_ACTIVE_USER_LOCATION.url,
      ApiRoutes.DASHBOARD_ACTIVE_USER_LOCATION.method,
      ApiRoutes.DASHBOARD_ACTIVE_USER_LOCATION.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
      this.setState({
        isActiveUserLocationsLoading: false,
      });
    } else {
      const {
        data: {
          activeLocations,
          totalActiveLocations,
          allActiveLocations,
          maximumActiveUser,
        },
      } = response;
      this.setState({
        activeLocations,
        totalActiveLocations,
        allActiveLocations,
        maximumActiveUser,
        isActiveUserLocationsLoading: false,
      });
    }
  };

  onChangeScanStatsBy = (selectedOption) => {
    this.setState(
      {
        scanStatsBy: selectedOption,
      },
      () => this.getScanStatistics()
    );
  };
  render() {
    const {
      scans,
      isScanLoading,
      isMemberStatisticLoading,
      isCounterfeitStatisticLoading,
      isScanStatisticLoading,
      isTopScannedProductsLoading,
      isActiveUserLocationsLoading,
      totalUsers,
      userStatisticsIntermsOfLastWeek,
      totalStaff,
      staffStatisticsIntermsOfLastWeek,
      totalClients,
      clientStatisticsIntermsOfLastWeek,
      totalActiveLocations,
      allActiveLocations,
      activeLocations,
      maximumActiveUser,
      scanData,
      counterfeitData,
      topScannedProductDataSet,
      scanStatsBy,
    } = this.state;
    const { currentScanDataSet, todayScans, totalScans, prevScanDataSet } =
      scanData;
    const {
      totalCounterfeits = 0,
      todayCounterfeits = 0,
      totalCompleted = 0,
      totalAcknowldeged = 0,
      totalUnderProcess = 0,
      totalUnderReview = 0,
    } = counterfeitData;
    let noDataClass;
    if (
      totalCompleted === 0 &&
      totalAcknowldeged === 0 &&
      totalUnderProcess === 0 &&
      totalUnderReview === 0
    ) {
      noDataClass = "no-data";
    } else {
      noDataClass = "";
    }
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Dashboard</h1>
        <p className="breadcrums-top">
          <a href="index.html">Dashboard</a>{" "}
          <img src="img/icon-br.svg" className="breadcrum-seperator" alt="" />{" "}
          Marks Collins
        </p>

        <div className="row">
          <div className="col-xl-4 col-md-6 mb-3">
            {isMemberStatisticLoading ? (
              <CardLoader />
            ) : (
              <div className="card shadow-border h-100 card-py-2">
                <div className="card-body">
                  <div className="row no-gutters lf-mg align-items-center">
                    <div className="col-auto">
                      <img
                        src="/img/card-1.svg"
                        className="card-image"
                        id="card-img-primary"
                        alt=""
                      />
                    </div>
                    <div className="col ml-100">
                      <div className="h5 mb-1 mt-1 card-number">
                        {totalUsers}
                      </div>
                      <div className="text-xs  text-secondary card-subhead mb-0">
                        Total Users
                      </div>
                    </div>
                    <div className="col-auto mtc-0 mr-2">
                      {userStatisticsIntermsOfLastWeek > 0 ? (
                        <div>
                          <img
                            src="/img/Path 1.svg"
                            className="card-image"
                            id="card-img-warning"
                            alt=""
                          />
                          <h4 className="plus-success">
                            <img
                              src="/img/up-icon.svg"
                              className="card-image"
                              id="card-img-warning"
                              alt=""
                            />
                            +{userStatisticsIntermsOfLastWeek}
                          </h4>
                        </div>
                      ) : (
                        <div>
                          <img
                            src="/img/Path 2.svg"
                            className="card-image"
                            id="card-img-warning"
                            alt=""
                          />
                          <h4 className="minus-danger">
                            <img
                              src="/img/down-icon.svg"
                              className="card-image"
                              id="card-img-warning"
                              alt=""
                            />
                            {userStatisticsIntermsOfLastWeek}
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-4 col-md-6 mb-3">
            {isMemberStatisticLoading ? (
              <CardLoader />
            ) : (
              <div className="card shadow-border h-100 card-py-2">
                <div className="card-body">
                  <div className="row no-gutters lf-mg align-items-center">
                    <div className="col-auto">
                      <img
                        src="/img/card-2.svg"
                        className="card-image"
                        id="card-img-info"
                        alt=""
                      />
                    </div>
                    <div className="col ml-100">
                      <div className="h5 mb-1 mt-1 card-number">
                        {" "}
                        {totalClients}
                      </div>
                      <div className="text-xs text-secondary card-subhead mb-0">
                        Total Client
                      </div>
                    </div>
                    <div className="col-auto mtc-0 mr-2">
                      {clientStatisticsIntermsOfLastWeek > 0 ? (
                        <div>
                          <img
                            src="/img/Path 1.svg"
                            className="card-image"
                            id="card-img-warning"
                            alt=""
                          />
                          <h4 className="plus-success">
                            <img
                              src="/img/up-icon.svg"
                              className="card-image"
                              id="card-img-warning"
                              alt=""
                            />
                            +{clientStatisticsIntermsOfLastWeek}
                          </h4>
                        </div>
                      ) : (
                        <div>
                          <img
                            src="/img/Path 2.svg"
                            className="card-image"
                            id="card-img-warning"
                            alt=""
                          />
                          <h4 className="minus-danger">
                            <img
                              src="/img/down-icon.svg"
                              className="card-image"
                              id="card-img-warning"
                              alt=""
                            />
                            {clientStatisticsIntermsOfLastWeek}
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-4 col-md-6 mb-3">
            {isMemberStatisticLoading ? (
              <CardLoader />
            ) : (
              <div className="card shadow-border h-100 card-py-2">
                <div className="card-body">
                  <div className="row no-gutters lf-mg align-items-center">
                    <div className="col-auto">
                      <img
                        src="/img/card-3.svg"
                        className="card-image"
                        id="card-img-warning"
                        alt=""
                      />
                    </div>
                    <div className="col ml-100">
                      <div className="h5 mb-1 mt-1 card-number">
                        {totalStaff}
                      </div>
                      <div className="text-xs  text-secondary card-subhead  mb-0">
                        Total Staff
                      </div>
                    </div>
                    <div className="col-auto mtc-0 mr-2">
                      {staffStatisticsIntermsOfLastWeek > 0 ? (
                        <div>
                          <img
                            src="/img/Path 1.svg"
                            className="card-image"
                            id="card-img-warning"
                            alt=""
                          />
                          <h4 className="plus-success">
                            <img
                              src="/img/up-icon.svg"
                              className="card-image"
                              id="card-img-warning"
                              alt=""
                            />
                            +{staffStatisticsIntermsOfLastWeek}
                          </h4>
                        </div>
                      ) : (
                        <div>
                          <img
                            src="/img/Path 2.svg"
                            className="card-image"
                            id="card-img-warning"
                            alt=""
                          />
                          <h4 className="minus-danger">
                            <img
                              src="/img/down-icon.svg"
                              className="card-image"
                              id="card-img-warning"
                              alt=""
                            />
                            {staffStatisticsIntermsOfLastWeek}
                          </h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card mb-4 shadow-border graph-card flex-fill TotalScansCard">
              <div className="card-header py-4550  align-items-center justify-content-between">
                <div className="row">
                  <div className="col-6">
                    <h6 className=" mt-2 font-book ">Total Scans</h6>
                  </div>
                  <div className="col-6 dp-end-right">
                    <Dropdown className="no-arrow dp-right">
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        className={"dropdwn-dash"}
                      >
                        {" "}
                        <span className="staff-value">
                          {scanStatsBy ? scanStatsBy.label : ""}
                        </span>{" "}
                        <img src={filterarrowIcon} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="shadow dropdown-menu-right dropdwn-dash-menu">
                        {[
                          {
                            label: "This week",
                            value: "week",
                          },
                          {
                            label: "This month",
                            value: "month",
                          },
                        ].map(({ label, value }, index) => (
                          <Dropdown.Item
                            as="div"
                            key={index}
                            onClick={() =>
                              this.onChangeScanStatsBy({ label, value })
                            }
                          >
                            {label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <Select
                      placeholder={''}
                      isSearchable={false}
                      options={[
                        {
                          label: 'This week',
                          value: 'week',
                        },
                        {
                          label: 'This month',
                          value: 'month',
                        },
                      ]}
                      styles={customStyles}
                      value={scanStatsBy}
                      onChange={this.onChangeScanStatsBy}
                    /> */}
                  </div>
                </div>
              </div>

              <div className="card-body area-graph">
                {isScanStatisticLoading ? (
                  <GraphLoader />
                ) : (
                  <>
                    <div className="description">
                      <div className="head">{totalScans}</div>
                      <div className={"mb-2 today-count"}>
                        Today {todayScans} scanned <img src={upIcon} alt="" />
                      </div>
                    </div>
                    <div>
                      <ScanGraphComponent
                        type={
                          scanStatsBy && scanStatsBy.value === "week"
                            ? "Week"
                            : "Month"
                        }
                        scanDataSet={currentScanDataSet}
                        prevScanDataSet={prevScanDataSet}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card mb-4 shadow-border graph-card flex-fill">
              <div className="card-header py-4550  align-items-center justify-content-between">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="mt-2 font-book">Total Counterfeits</h6>
                  </div>
                </div>
              </div>

              <div className="card-body">
                {isCounterfeitStatisticLoading ? (
                  <GraphLoader />
                ) : (
                  <>
                    <div className="description">
                      <div className="head">{totalCounterfeits}</div>
                      <div className={"mb-2 today-count"}>
                        Today {todayCounterfeits} products reported
                        <img src={upIcon} alt="" />
                      </div>
                    </div>
                    <div className={noDataClass}>
                      <CounterfeitGraphComponent
                        totalCompleted={totalCompleted}
                        totalAcknowldeged={totalAcknowldeged}
                        totalUnderProcess={totalUnderProcess}
                        totalUnderReview={totalUnderReview}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-xl-4 col-md-6 d-flex">
            <div className="card mb-4 shadow-border graph-card flex-fill">
              <div className="card-header py-4550  align-items-center justify-content-between">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="mt-2 font-book ">Top Scanned Products</h6>
                  </div>
                </div>
              </div>

              <div className="card-body">
                {isTopScannedProductsLoading ? (
                  <GraphLoader />
                ) : (
                  <>
                    <div className="description">
                      <div className="head">{totalScans}</div>
                      <div></div>
                    </div>
                    <div className="TopScannedProducts-graph">
                      <TopScannedProductGraphComponent
                        dataset={topScannedProductDataSet}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card shadow-border mb-3">
          <div className="card-header py-4550  align-items-center justify-content-between">
            <div className="row">
              <h6 className="m-25 font-book ">Active Locations</h6>
            </div>
          </div>
          <div className="card-body ActiveLocations-mainbox">
            {isActiveUserLocationsLoading ? (
              <GraphLoader />
            ) : (
              <>
                <div className="description">
                  <div className="head">{totalActiveLocations}</div>
                </div>
                <LocationChartComponent
                  activeLocations={activeLocations}
                  allActiveLocations={allActiveLocations}
                  maximumActiveUser={maximumActiveUser}
                />
              </>
            )}
          </div>
        </div>

        <div className="card mb-4 shadow-border">
          <div className="card-header py-75  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-6 head-col">
                <h6 className="m-25 font-book ">Recent Scans</h6>
              </div>
              <div className="col-md-6 side-col dropdown">
                <Link to={AppRoutes.SCANS} className="btn btn-primary side-btn">
                  {" "}
                  See More
                </Link>
              </div>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive fixed-height-table">
              {isScanLoading ? (
                <TableLoader columns={6} />
              ) : (
                <table className="scroll" width="100%" cellSpacing="0">
                  <thead className="thead-dash">
                    <tr>
                      <th>Scan ID</th>
                      <th>Batch Number</th>
                      <th>Product Name</th>
                      <th>User Name</th>
                      <th>Location</th>
                      <th>Scan Time</th>
                    </tr>
                  </thead>

                  <tbody className="tbody-dash">
                    {scans && scans.length ? (
                      scans.map((scan, index) => (
                        <ScanRow key={index} scanData={scan} />
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8} className={"text-center"}>
                          User hasn't scanned any product yet.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
