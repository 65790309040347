import React, { useState } from "react";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import {
  DEFAULT_DATE_FORMAT,
  AppConfig,
  DEFAULT_TIME_FORMAT,
} from "../../../config";
import { CounterfeitRequest } from "../../components/loader/Resusable/CounterfeitRequest";
import { ImageView } from "./ImageView";
import calenderIcon from "../../../assets/img/calender.svg";
import locationIcon from "../../../assets/img/location.svg";
import filetickIcon from "../../../assets/img/file-sub.svg";
import filequestionIcon from "../../../assets/img/file-type.svg";
import filedescIcon from "../../../assets/img/file-desc.svg";
import scanIcon from "../../../assets/img/rect-minus.svg";
import tagIcon from "../../../assets/img/shape-copy.svg";
import arrowIcon from "../../../assets/img/link-arr.svg";
import dummyImage from "../../../assets/img/noimage.jpeg";
import "react-image-lightbox/style.css";

export const RequestDetails = ({ requestDetails, isLoading, mapHandler }) => {
  const [isOpen, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(false);
  const {
    isManual = false,
    reportId = "",
    requestSubject = "",
    requestType = "",
    requestDescription = "",
    requestDate = "",
    storeName = "",
    storeLocation = "",
    images = [],
    scan = {},
    latitude = "",
    longitude = "",
  } = requestDetails || {};
  const { scanId } = scan || {};
  // Trigger on image click
  // const onImageClick = index => {
  //   setOpen(true);
  //   setPhotoIndex(index);
  // };

  const openGallery = () => {
    setOpen(true);
  };

  const closeGallery = () => {
    setOpen(false);
  };
  return (
    <div className="card mb-4 card-main flex-fill">
      <div className="card-header  py-7550  align-items-center justify-content-between">
        <div className="row">
          <div className="col-md-12">
            <h6 className="font-book ">Request Details</h6>
          </div>
        </div>
      </div>
      <div className="card-body card-table">
        {/* {latitude && longitude ? (
          <SimpleMap latitude={latitude} longitude={longitude} />
        ) : null} */}
        {isLoading ? (
          <CounterfeitRequest />
        ) : (
          <div className="row">
            <div className="col-md-4">
              <div className="row mtblf-5">
                <div className="col-md-12 mtb-5">
                  <div className="heading-col">
                    <img src={scanIcon} alt="" />
                    <h5>{isManual ? "Report ID" : "Scan ID"}</h5>
                  </div>
                  <div className="description-col">
                    <h5>{isManual ? reportId : scanId}</h5>
                  </div>
                </div>
                <div className="col-md-12 mtb-5">
                  <div className="heading-col">
                    <img src={tagIcon} alt="" />
                    <h5>Seller Name</h5>
                  </div>
                  <div className="description-col">
                    <h5>{storeName}</h5>
                  </div>
                </div>
                <div className="col-md-12 mtb-5">
                  <div className="heading-col">
                    <img src={locationIcon} alt="" />
                    <h5>Purchase Location</h5>
                    {latitude && longitude ? (
                      <span
                        className="a-link cursor-pointer"
                        onClick={
                          () => mapHandler(latitude, longitude, storeLocation)
                          //   () => {

                          //   let left = window.screen.width / 2 - 500 / 2;
                          //   let top = window.screen.height / 2 - 500 / 2;
                          //   window.open(
                          //     `https://maps.google.com/?q=${latitude},${longitude}`,
                          //     'popUpWindow',
                          //     `height=500,width=500,left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`,
                          //   );
                          // }
                        }
                      >
                        Open Map <img src={arrowIcon} alt="" />
                      </span>
                    ) : null}
                  </div>
                  <div className="description-col">
                    <h5>{storeLocation}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row mtblf-5">
                <div className="col-md-12 mtb-5">
                  <div className="heading-col">
                    <img src={filetickIcon} alt="" />
                    <h5>Request Subjects</h5>
                  </div>
                  <div className="description-col">
                    <h5>{requestSubject}</h5>
                  </div>
                </div>
                <div className="col-md-12 mtb-5">
                  <div className="heading-col">
                    <img src={filequestionIcon} alt="" />
                    <h5>Request Type</h5>
                  </div>
                  <div className="description-col">
                    <h5>{requestType}</h5>
                  </div>
                </div>
                <div className="col-md-12 mtb-5">
                  <div className="heading-col">
                    <img src={filedescIcon} alt="" />
                    <h5>Request Description</h5>
                  </div>
                  <div className="description-col">
                    <h5>{requestDescription || "-"}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row mtblf-5">
                <div className="col-md-12 mtb-5">
                  <div className="heading-col">
                    <img src={calenderIcon} alt="" />
                    <h5>Request Time</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {requestDate
                        ? [
                            moment(requestDate).format(DEFAULT_TIME_FORMAT),
                            moment(requestDate).format(DEFAULT_DATE_FORMAT),
                          ].join(" Hrs | ")
                        : "-"}
                    </h5>
                  </div>
                </div>
                <div className="col-md-12 mtb-5">
                  <div className="heading-col">
                    <img src={tagIcon} alt="" />
                    <h5>Product Images</h5>
                  </div>
                  <div className="description-col img-col">
                    {images.length ? (
                      images.map((image, index) => (
                        <img
                          key={index}
                          className="pr-img"
                          height={80}
                          width={80}
                          src={
                            image
                              ? image.startsWith("https://")
                                ? image
                                : `${AppConfig.FILES_ENDPOINT}${image}`
                              : dummyImage
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = dummyImage;
                          }}
                          alt=""
                          // onClick={openGallery}
                          onClick={openGallery}
                        />
                      ))
                    ) : (
                      <span>No images added</span>
                    )}
                    {false && (
                      <div>
                        <Lightbox
                          mainSrc={`${AppConfig.FILES_ENDPOINT}${images[photoIndex]}`}
                          nextSrc={`${AppConfig.FILES_ENDPOINT}${
                            images[(photoIndex + 1) % images.length]
                          }`}
                          prevSrc={`${AppConfig.FILES_ENDPOINT}${
                            images[
                              (photoIndex + images.length - 1) % images.length
                            ]
                          }`}
                          onCloseRequest={() => setOpen(false)}
                          onMovePrevRequest={() =>
                            setPhotoIndex(
                              (photoIndex + images.length - 1) % images.length
                            )
                          }
                          onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                          }
                          enableZoom={false}
                          reactModalStyle={{
                            content: {
                              top: "50%",
                              left: "50%",
                              right: "10%",
                              // bottom: 'auto',
                              // marginRight: '-50%',
                              transform: "translate(-50%, -50%)",
                              // backgroundColor: 'red',
                            },
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <ImageView open={isOpen} handleClose={closeGallery} images={images} />
      </div>
    </div>
  );
};
