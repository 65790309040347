import Validator from 'js-object-validation';
import { message } from '../common';

export const companyDetailsValidator = data => {
  const validations = {
    companyName: {
      required: true,
    },
    storeName: {
      required: true,
    },
    /* website: {
              required: true,
        }, */
    address1: {
      required: true,
    },
  };
  if (data.insideUSACanada === 'yes') {
    validations.businessRegNo = { required: true };
    validations.VATNumber = { required: true };
  }
  if (data.insideUSACanada === 'no') {
    validations.businessRegCertificateNo = { required: true };
    validations.stateOfIssuingCertificate = { required: true };
  }
  // Error messages
  const messages = {
    companyName: {
      required: message.Required.replace(':item', 'Company name'),
    },
    storeName: {
      required: message.Required.replace(':item', 'Store name'),
    },
    // website: {
    //     required: message.Required.replace(':item', 'Website'),
    // },
    address1: {
      required: message.Required.replace(':item', 'Address line 1'),
    },
    businessRegNo: {
      required: message.Required.replace(':item', 'Business reg. no.'),
    },
    VATNumber: {
      required: message.Required.replace(':item', 'VAT number'),
    },
    businessRegCertificateNo: {
      required: message.Required.replace(':item', 'Business reg. certificate'),
    },
    stateOfIssuingCertificate: {
      required: message.Required.replace(
        ':item',
        'State of issuing certificate',
      ),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
