/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes, websiteRegex } from "../../../config";
import { message } from "../../../common";
import { ClientInfoFormComponent } from "../../components/clients/Invitation";
import { StoreInfoFormComponent } from "../../components/clients/Invitation";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { BatchViewLoader } from "../../components/loader/Resusable/BatchView";
import { SubmissionSuccess } from "../../components/clients/Invitation/SubmissionSuccess";
import {
  ownerDetailsValidator,
  companyDetailsValidator,
} from "../../../validators";
import whiteLogo from "../../../assets/img/logo-white.webp";
import "./invitation-client.css";

class ClientInvitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      clientData: {},
      storeData: {},
      isVerified: false,
      isSubmitted: false,
      isLoading: true,
      isSubmitting: false,
      errorMessage: "",
      insideUSACanada: "yes",
      activeTab: 1,
      country: null,
    };
  }
  componentDidMount = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    this.verifyInvitationLink(query.user);
  };
  verifyInvitationLink = async (encryptedUserId) => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_CLIENT_INVITATION.service,
      ApiRoutes.VERIFY_CLIENT_INVITATION.url,
      ApiRoutes.VERIFY_CLIENT_INVITATION.method,
      ApiRoutes.VERIFY_CLIENT_INVITATION.authenticate,
      undefined,
      { userId: encryptedUserId }
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      this.setState({
        errorMessage: response.messages[0],
      });
    } else {
      const { data } = response.data;
      const {
        _id = "",
        first_name = "",
        last_name = "",
        email = "",
        phone = "",
        profile_picture,
        other_info = {},
        store = {},
      } = data || {};
      const {
        address1 = "",
        address2 = "",
        state = "",
        country = "",
        postCode = "",
      } = other_info || {};
      this.setState({
        clientId: _id,
        clientData: {
          first_name,
          last_name,
          email,
          phone,
          profile_picture,
          address1,
          address2,
          state,
          country,
          postCode,
        },
        storeData: {
          ...store,
          insideUSACanada: store.insideUSACanada ? "yes" : "no",
        },
        isVerified: true,
      });
    }
  };
  submitRequest = async () => {
    const { clientId, clientData, storeData } = this.state;
    this.setState({
      isSubmitting: true,
    });
    let payload = {
      ...clientData,
      storeData,
    };
    delete payload.errors;
    delete payload.storeData.errors;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUBMIT_CLIENT_FORM.service,
      ApiRoutes.SUBMIT_CLIENT_FORM.url.replace(":id", clientId),
      ApiRoutes.SUBMIT_CLIENT_FORM.method,
      ApiRoutes.SUBMIT_CLIENT_FORM.authenticate,
      undefined,
      {
        ...clientData,
        country:
          clientData.country && clientData.country.value
            ? clientData.country.value
            : "",
        state:
          clientData.state && clientData.state.value
            ? clientData.state.value
            : "",
        storeData: {
          ...storeData,
          insideUSACanada: storeData.insideUSACanada === "yes" ? true : false,
          country:
            storeData.country && storeData.country.value
              ? storeData.country.value
              : "",
          state:
            storeData.state && storeData.state.value
              ? storeData.state.value
              : "",
        },
      }
    );
    this.setState({
      isSubmitting: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isSubmitted: true,
      });
      this.setState((preState) => ({
        activeTab: preState.activeTab + 1,
      }));
    }
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      if (name === "storeLogo") {
        this.setState({
          storeData: {
            ...this.state.storeData,
            errors: {
              ...this.state.storeData.errors,
              [name]: message.InvalidImages,
            },
          },
        });
      } else {
        this.setState({
          clientData: {
            ...this.state.clientData,
            errors: {
              ...this.state.storeData.errors,
              [name]: message.InvalidImages,
            },
          },
        });
      }
      return;
    }
    this.setState({
      clientData: {
        ...this.state.clientData,
        errors: {
          ...this.state.clientData.errors,
          [name]: "",
        },
      },
      storeData: {
        ...this.state.storeData,
        errors: {
          ...this.state.storeData.errors,
          [name]: "",
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = "";
  };
  onDeleteFile = (fieldName) => {
    if (fieldName === "storeLogo") {
      this.setState({
        storeData: {
          ...this.state.storeData,
          [fieldName]: "",
          errors: {
            ...this.state.storeData.errors,
            [fieldName]: "",
          },
        },
      });
    } else {
      this.setState({
        clientData: {
          ...this.state.clientData,
          [fieldName]: "",
          errors: {
            ...this.state.clientData.errors,
            [fieldName]: "",
          },
        },
      });
    }
  };
  uploadPicture = async (data, name) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      if (name === "storeLogo") {
        this.setState({
          storeData: { ...this.state.storeData, [name]: path },
        });
      } else {
        this.setState({
          clientData: { ...this.state.clientData, [name]: path },
        });
      }
    }
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
      updatingSection: name === "storeLogo" ? "store" : "contact",
    });
  };
  handleContactInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      clientData: {
        ...this.state.clientData,
        [name]: value,
        errors: {
          ...this.state.clientData.errors,
          [name]: "",
        },
      },
    });
  };
  handleStoreInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      storeData: {
        ...this.state.storeData,
        [name]: value,
        country: name === "insideUSACanada" ? "" : this.state.storeData.country,
        state: name === "insideUSACanada" ? "" : this.state.storeData.state,
        errors: {
          ...this.state.storeData.errors,
          [name]: "",
        },
      },
    });
  };
  onSelect = (selectedOption) => {
    this.setState({
      storeData: {
        ...this.state.storeData,
        insideUSACanada: selectedOption,
      },
    });
  };
  onSelectOption = (options, name, moduleName) => {
    // TO reset states on country selection
    if (name === "country") {
      this.setState({
        [moduleName]: {
          ...this.state[moduleName],
          [name]: options,
          state: "",
          errors: {
            ...this.state[moduleName].errors,
            state: "",
            country: "",
          },
        },
        // states: [],
        // errors: {
        //   ...this.state.errors,
        //   states: '',
        //   country: '',
        // },
      });
    } else {
      this.setState({
        [moduleName]: {
          ...this.state[moduleName],
          [name]: options,
          errors: {
            ...this.state[moduleName].errors,
            [name]: "",
          },
        },
        // [name]: options,
        // errors: {
        //   ...this.state.errors,
        //   [name]: '',
        // },
      });
    }
  };
  /* handleSubmit = async event => {
          event.preventDefault();
          const storeData = this.state.storeData;
          let payload = {
              companyName: storeData.companyName ? storeData.companyName : '',
              businessRegCertificateNo: storeData.businessRegCertificateNo
                  ? storeData.businessRegCertificateNo
                  : '',
              address1: storeData.address1 ? storeData.address1 : '',
          };
          const { isValid, errors } = addClientValidator(payload);
          if (isValid) {
              const { value } = await SweetAlertComponent({
                  title: 'Are you sure?',
                  text: 'You want to submit the details.',
              });
              if (!value) {
                  return;
              }
              this.submitRequest();
          } else {
              this.setState({
                  errors,
              });
          }
      }; */
  handlePrevious = () => {
    this.setState((preState) => ({
      activeTab: preState.activeTab - 1,
    }));
  };
  currentTabPrevious = (currentTabid) => {
    const { clientData, isSubmitted } = this.state;

    if (isSubmitted || (currentTabid === 2 && !clientData.isValid)) {
      return;
    } else {
      this.setState({
        activeTab: currentTabid,
      });
    }
    // var activeTab = this.state.activeTab;
    // if (activeTab === 2) {
    //   this.setState(preState => ({
    //     activeTab: currentTabid,
    //   }));
    // }
  };
  handleNext = async () => {
    if (this.state.activeTab === 1) {
      const clientData = this.state.clientData;
      let payload = {
        first_name: clientData.first_name ? clientData.first_name : "",
        last_name: clientData.last_name ? clientData.last_name : "",
        phone: clientData.phone ? clientData.phone : "",
        email: clientData.email ? clientData.email : "",
        address1: clientData.address1 ? clientData.address1 : "",
      };
      const { isValid, errors } = ownerDetailsValidator(payload);
      if (isValid) {
        this.setState((preState) => ({
          activeTab: preState.activeTab + 1,
          clientData: {
            ...this.state.clientData,
            isValid,
          },
        }));
      } else {
        this.setState({
          clientData: {
            ...this.state.clientData,
            errors: errors,
            isValid,
          },
        });
      }
    }
    if (this.state.activeTab === 2) {
      const storeData = this.state.storeData;
      let payload = {
        companyName: storeData.companyName ? storeData.companyName : "",
        storeName: storeData.storeName ? storeData.storeName : "",
        website: storeData.website ? storeData.website : "",
        address1: storeData.address1 ? storeData.address1 : "",
        insideUSACanada: storeData.insideUSACanada
          ? storeData.insideUSACanada
          : "",
        businessRegNo: storeData.businessRegNo ? storeData.businessRegNo : "",
        VATNumber: storeData.VATNumber ? storeData.VATNumber : "",
        businessRegCertificateNo: storeData.businessRegCertificateNo
          ? storeData.businessRegCertificateNo
          : "",
        stateOfIssuingCertificate: storeData.stateOfIssuingCertificate
          ? storeData.stateOfIssuingCertificate
          : "",
      };
      const { isValid, errors } = companyDetailsValidator(payload);
      if (isValid) {
        if (
          storeData &&
          storeData.website &&
          !websiteRegex.test(storeData.website)
        ) {
          this.setState({
            storeData: {
              ...this.state.storeData,
              errors: {
                ...this.state.storeData.errors,
                website: message.InvalidWebite,
              },
            },
          });
          return;
        }

        const { value } = await SweetAlertComponent({
          // title: "Are you sure?",
          text: "Are you sure, You want to submit the details.",
        });
        if (!value) {
          return;
        }
        this.submitRequest();
      } else {
        this.setState({
          storeData: {
            ...this.state.storeData,
            errors: errors,
          },
        });
      }
    }
  };
  renderStepperForm = () => {
    const {
      isLoading,
      isUploading,
      uploadPercentage,
      clientData,
      storeData,
      activeTab,
    } = this.state;
    switch (activeTab) {
      case 1:
        return (
          <div className="row row-mt-4">
            <ClientInfoFormComponent
              clientData={clientData}
              isLoading={isLoading}
              isUploading={isUploading}
              uploadPercentage={uploadPercentage}
              onChange={this.handleContactInfoChange}
              onSubmit={this.submitContactInfoDetails}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              onSelectOption={this.onSelectOption}
            />
          </div>
        );
      case 2:
        return (
          <StoreInfoFormComponent
            storeData={storeData}
            isLoading={isLoading}
            isUploading={isUploading}
            uploadPercentage={uploadPercentage}
            onChange={this.handleStoreInfoChange}
            onSelect={this.onSelect}
            onSubmit={this.submitStoreDetails}
            onFileChange={this.onFileChange}
            onDeleteFile={this.onDeleteFile}
            onSelectOption={this.onSelectOption}
          />
        );
      case 3:
        return <SubmissionSuccess />;
      default:
        break;
    }
  };
  render() {
    const {
      isLoading,
      // isSubmitted,
      isVerified,
      errorMessage,
      activeTab,
      isSubmitting,
    } = this.state;
    return (
      <div className="container-fluid-invitaion">
        <div className="row login-row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header center-header">
                <img className="center-logo" src={whiteLogo} alt="" />
              </div>
              {isLoading ? (
                <BatchViewLoader />
              ) : isVerified ? (
                <>
                  <h4 className="register-head">Register with us</h4>
                  <div className="card-body card-container-body">
                    <div className="row mt-4 step-row">
                      <div
                        className={`col-sm-4 steps-class ${
                          activeTab === 1 ? "active" : ""
                        }`}
                        id="step-class-1"
                      >
                        <h4
                          className="steps-class-title"
                          onClick={() => this.currentTabPrevious(1)}
                        >
                          {" "}
                          Owner Details{" "}
                          <i
                            className="fa fa-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </h4>
                      </div>
                      <div
                        className={`col-sm-4 steps-class ${
                          activeTab === 2 ? "active" : ""
                        }`}
                        id="step-class-2"
                      >
                        <h4
                          className="steps-class-title"
                          onClick={() => this.currentTabPrevious(2)}
                        >
                          {" "}
                          Company Details{" "}
                          <i
                            className="fa fa-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </h4>
                      </div>
                      <div
                        className={`col-sm-4 steps-class ${
                          activeTab === 3 ? "active" : ""
                        }`}
                        id="step-class-3"
                      >
                        <h4 className="steps-class-title">Requested</h4>
                      </div>
                    </div>
                    {this.renderStepperForm()}
                    {activeTab !== 3 ? (
                      <div className="row row-mt-4">
                        <div className="col-6 dp-align-left">
                          {activeTab !== 1 ? (
                            <button
                              className="btn btn-primary next-btn"
                              onClick={this.handlePrevious}
                              disabled={isSubmitting}
                            >
                              {" "}
                              Previous{" "}
                            </button>
                          ) : null}
                        </div>

                        <div className="col-6 dp-align-right">
                          <button
                            className="btn btn-primary next-btn"
                            onClick={this.handleNext}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? (
                              <>
                                <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                Next
                              </>
                            ) : (
                              "Next"
                            )}
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </>
              ) : (
                <div className={"client-invitation-form"}>
                  <div className={"message"}>{errorMessage}</div>
                </div>
              )}

              {/* <div className='row row-mt-4'>
                  <ClientInfoFormComponent
                    clientData={clientData}
                    isLoading={isLoading}
                    isUploading={isUploading}
                    uploadPercentage={uploadPercentage}
                    onChange={this.handleContactInfoChange}
                    onSubmit={this.submitContactInfoDetails}
                    onFileChange={this.onFileChange}
                    onDeleteFile={this.onDeleteFile}
                  /> */}
              {/* <div className='col-md-12'>
                    <form className='user_add mt-2'>
                      <div className='row '>
                        <div className='col-md-3 mob-show'>
                          <div className='img-col-user'>
                            <img className='user-img' src={defaultImg} alt='' />
                          </div>
                          <div className='file-upload-btn-invitaion '>
                            <label className='file-label'>
                              Choose Image
                              <input
                                className='imgUpload img_up'
                                name='profile_picture'
                                type='file'
                                accept='image/x-png,image/jpeg ,image/jpg, image/png ,image/svg'
                              />
                            </label>
                            <span className={'cursor-pointer'}>
                              <img src={trashIcon} alt='' />
                            </span>
                          </div>

                          <div className={'text-danger'}></div>
                        </div>
                        <div className='col-md-9'>
                          <div className='row'>
                            <div className='col-md-4'>
                              <InputTextField
                                id='first_name'
                                label={'First Name'}
                                className={'form-control-user'}
                                name='first_name'
                                maxLength={30}
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='last_name'
                                label={'Last Name'}
                                className={'form-control-user'}
                                name='last_name'
                                maxLength={30}
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='phone'
                                label={'Phone Number'}
                                className={'form-control-user'}
                                name='phone'
                                maxLength={14}
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='email'
                                label={'Email'}
                                className={'form-control-user'}
                                name='email'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='address_line_1'
                                label={'Address Line 1'}
                                className={'form-control-user'}
                                name='address1'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='address_line_2'
                                label={'Address Line 2'}
                                className={'form-control-user'}
                                name='address2'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='state'
                                label={'State'}
                                className={'form-control-user'}
                                name='state'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='country'
                                label={'Country'}
                                className={'form-control-user'}
                                name='country'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='post_code'
                                label={'Post Code'}
                                className={'form-control-user'}
                                name='postCode'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3 mob-hide'>
                          <div className='img-col-user'>
                            <img className='user-img' src={defaultImg} alt='' />
                          </div>

                          <div className='file-upload-btn-invitaion '>
                            <label className='file-label'>
                              Choose Image
                              <input
                                className='imgUpload img_up'
                                name='profile_picture'
                                type='file'
                                accept='image/x-png,image/jpeg ,image/jpg, image/png ,image/svg'
                              />
                            </label>
                            <span className={'cursor-pointer'}>
                              <img src={trashIcon} alt='' />
                            </span>
                          </div>

                          <div className={'text-danger'}></div>
                        </div>
                      </div>
                    </form>
                    <div className='row row-mt-4 '>
                      <div className='col-12 dp-align-right'>
                        <button
                          className='btn btn-primary next-btn'
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                 */}
              {/* </div> */}
              {/* <StoreInfoFormComponent
                  storeData={storeData}
                  isLoading={isLoading}
                  isUploading={isUploading}
                  uploadPercentage={uploadPercentage}
                  onChange={this.handleStoreInfoChange}
                  onSelect={this.onSelect}
                  onSubmit={this.submitStoreDetails}
                  onFileChange={this.onFileChange}
                  onDeleteFile={this.onDeleteFile}
                /> */}
              {/* <div className='row row-mt-4'>
                  <div className='col-md-12'>
                    <form className='user_add mt-2'>
                      <div className='row'>
                        <div className='col-md-3 mob-show'>
                          <div className='img-col-user'>
                            <img className='user-img' src={defaultImg} alt='' />
                          </div>

                          <div className='file-upload-btn-invitaion '>
                            <label className='file-label'>
                              Choose Image
                              <input
                                className='imgUpload img_up'
                                name='storeLogo'
                                type='file'
                                accept='image/x-png,image/jpeg ,image/jpg, image/png ,image/svg'
                              />
                            </label>
                            <span className={'cursor-pointer'}>
                              <img src={trashIcon} alt='' />
                            </span>
                          </div>

                          <div className={'text-danger'}></div>
                        </div>
                        <div className='col-md-9'>
                          <div className='row'>
                            <div className='col-md-12 dp-align-center'>
                              <input
                                type='radio'
                                id='yes'
                                name='insideUSACanada'
                                value='yes'
                                checked
                              />
                              <label className='input-radio' for='yes'>
                                Inside USA/ Canada
                              </label>
                              <input
                                type='radio'
                                id='no'
                                name='insideUSACanada'
                                value='no'
                              />
                              <label className='input-radio' for='no'>
                                Outside USA/ Canada
                              </label>
                            </div>
                            <br />
                            <div className='col-md-4'>
                              <InputTextField
                                id='companyName'
                                label={'Company Name'}
                                className={'form-control-user'}
                                name='companyName'
                                maxLength={30}
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='storeName'
                                label={'Store Name'}
                                className={'form-control-user'}
                                name='storeName'
                                maxLength={30}
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='website'
                                label={'Website'}
                                className={'form-control-user'}
                                name='website'
                                maxLength={30}
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='brandName'
                                label={'Brand Name'}
                                className={'form-control-user'}
                                name='brandName'
                                maxLength={14}
                              />
                            </div>

                            {insideUSACanada === 'yes' ? (
                              <>
                                <div className='col-md-4'>
                                  <InputTextField
                                    id='businessRegNo'
                                    label={'Business Reg. No.'}
                                    className={'form-control-user'}
                                    name='businessRegNo'
                                  />
                                </div>
                                <div className='col-md-4'>
                                  <InputTextField
                                    id='VATNumber'
                                    label={'VAT Number'}
                                    className={'form-control-user'}
                                    name='VATNumber'
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='col-md-4'>
                                  <InputTextField
                                    id='businessRegCertificateNo'
                                    label={'Business Reg. Certificate'}
                                    className={'form-control-user'}
                                    name='businessRegCertificateNo'
                                  />
                                </div>
                                <div className='col-md-4'>
                                  <InputTextField
                                    id='stateOfIssuingCertificate'
                                    label={'State of issuing Certificate'}
                                    className={'form-control-user'}
                                    name='stateOfIssuingCertificate'
                                  />
                                </div>
                              </>
                            )}

                            <div className='col-md-4'>
                              <InputTextField
                                id='store_address_line_1'
                                label={'Address Line 1'}
                                className={'form-control-user'}
                                name='address1'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='store_address_line_2'
                                label={'Address Line 2'}
                                className={'form-control-user'}
                                name='address2'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='store_state'
                                label={'State'}
                                className={'form-control-user'}
                                name='state'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='store_country'
                                label={'Country'}
                                className={'form-control-user'}
                                name='country'
                              />
                            </div>
                            <div className='col-md-4'>
                              <InputTextField
                                id='store_post_code'
                                label={'Post Code'}
                                className={'form-control-user'}
                                name='postCode'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-3 mob-hide'>
                          <div className='img-col-user'>
                            <img className='user-img' src={defaultImg} alt='' />
                          </div>

                          <div className='file-upload-btn-invitaion '>
                            <label className='file-label'>
                              Choose Image
                              <input
                                className='imgUpload img_up'
                                name='storeLogo'
                                type='file'
                                accept='image/x-png,image/jpeg ,image/jpg, image/png ,image/svg'
                              />
                            </label>
                            <span className={'cursor-pointer'}>
                              <img src={trashIcon} alt='' />
                            </span>
                          </div>

                          <div className={'text-danger'}></div>
                        </div>
                      </div>
                    </form>
                    <div className='row row-mt-4 '>
                      <div className='col-6 dp-align-left'>
                        <button
                          className='btn btn-primary next-btn'
                        >
                          Previous
                        </button>
                      </div>
                      <div className='col-6 dp-align-right'>
                        <button
                          className='btn btn-primary next-btn'
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}

              {/* <SubmissionSuccess /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientInvitation;
