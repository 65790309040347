import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import { permissionValidator } from "../../../../validators/permission";
import AssignedPermissionsListComponent from "../../../components/staffs/Permission";
import { PermissionFormComponent } from "../../../components/staffs/Permission/PermissionFormComponent";
import "../manage-staff.css";

class ManageStaffPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: "",
      // Permission module related fields
      permission: [],
      subModules: [],
      selectedModule: null,
      accessType: null,
      permissionErrors: null,
      alreadyAdded: false,
      deletingModule: "", // to apply remove loader on particular index
      name: "",
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        staffId: id,
      },
      () => this.fetchStaffPermission()
    );
  };
  fetchStaffPermission = async (loading = true) => {
    this.setState({
      isLoading: loading,
    });
    const { staffId } = this.state;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF_PERMISSION.service,
      ApiRoutes.GET_STAFF_PERMISSION.url.replace(":id", staffId),
      ApiRoutes.GET_STAFF_PERMISSION.method,
      ApiRoutes.GET_STAFF_PERMISSION.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    console.log("response", response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      const [{ first_name = "", last_name = "", permission = [] }] = data || {};
      this.setState({
        name: [first_name, last_name].filter(Boolean).join(" "),
        permission,
      });
    }
  };
  updatePermission = async (payload, loading = true) => {
    const { staffId } = this.state;
    this.setState({
      isUpdating: loading,
      updatingSection: "permission",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_PERMISSION.service,
      ApiRoutes.UPDATE_STAFF_PERMISSION.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_PERMISSION.method,
      ApiRoutes.UPDATE_STAFF_PERMISSION.authenticate,
      undefined,
      payload
    );
    if (response && response.isError) {
      this.setState({
        isUpdating: false,
        updatingSection: "",
        deletingModule: "",
      });
      toast.error(response.messages[0]);
      return false;
    } else {
      await this.fetchStaffPermission(false);
      this.setState({
        isUpdating: false,
        updatingSection: "",
        selectedModule: null,
        subModules: [],
        accessType: null,
        alreadyAdded: false,
        permissionErrors: null,
      });
      toast.success(response.messages[0]);
      return true;
    }
  };
  // Handle change on add permission
  handlePermissionSelection = (selectedOption, name) => {
    const { permission } = this.state;
    if (name === "selectedModule" && selectedOption) {
      let Added = permission.filter(
        (m) => m.module === selectedOption.value
      )[0];
      if (Added) {
        this.setState({
          alreadyAdded: Added,
          accessType: {
            label: Added.accessType,
            value: Added.accessType,
          },
          subModules: Added.subModules.map((item) => ({
            label: item,
            value: item,
          })),
        });
      } else {
        this.setState({
          alreadyAdded: false,
          subModules: [],
        });
      }
    }
    this.setState({
      [name]: selectedOption,
      permissionErrors: {
        ...this.state.permissionErrors,
        [name]: "",
      },
    });
  };
  // To add permission for the client
  addPermission = async (event) => {
    event.preventDefault();
    const { selectedModule, accessType, subModules } = this.state;
    let data = {
      module:
        selectedModule && selectedModule.value ? selectedModule.value : "",
      subModules: subModules.map((item) => item.value),
      accessType: accessType && accessType.value ? accessType.value : "",
    };
    // To validates field
    const { isValid, errors } = permissionValidator({
      selectedModule: data.module,
      subModules: data.subModules.length,
      accessType: data.accessType,
    });
    if (isValid) {
      await this.updatePermission({
        newModule: data,
      });
    } else {
      this.setState({
        permissionErrors: errors,
      });
      return;
    }
  };
  // To remove access of particular module
  removePermission = async (moduleName) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to remove ${moduleName.toLowerCase()} access?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      deletingModule: moduleName,
    });
    await this.updatePermission(
      {
        removedModule: moduleName,
      },
      false
    );
  };

  customBreadCrumb = () => {
    const { isLoading, name } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      name
    );
  };

  render() {
    const {
      isLoading,
      selectedModule,
      accessType,
      permissionErrors,
      deletingModule,
      permission,
      isUpdating,
      updatingSection,
      staffId,
      alreadyAdded,
      subModules,
    } = this.state;

    console.log("permission", permission);
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View Client</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
        />

        <div className="row">
          <div className="col-md-12 d-flex">
            <PermissionFormComponent
              isLoading={isLoading}
              selectedModule={selectedModule}
              staffId={staffId}
              accessType={accessType}
              errors={permissionErrors}
              isAdding={isUpdating && updatingSection === "permission"}
              handlePermissionSelection={this.handlePermissionSelection}
              addPermission={this.addPermission}
              alreadyAdded={alreadyAdded}
              subModules={subModules}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <AssignedPermissionsListComponent
              isLoading={isLoading}
              permission={permission}
              removePermission={this.removePermission}
              deletingModule={deletingModule}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ManageStaffPermission;
