/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../config";
import { ApiHelper } from "../../../helpers";
import Pagination from "../../components/pagination";
import { ListComponent } from "../../components/scans";
import { FilterComponent } from "../../components/filters";
import PHLContext from "../../../PHLContext";
import { SearchFilter } from "../../components/filters/searchFilter";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import pageDropdownIcon from "../../../assets/img/tag-bd.svg";
import mobSearchIcon from "../../../assets/img/tag-search-bd.svg";
import AsyncSelect from "react-select/async";
import "./scans.css";

let lat, long;
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

class Scans extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      scans: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      activeId: "",
      sortBy: "created_at",
      order: "desc",
      search: "",
      newRecord: false,
      clientName: { label: "PHL", value: "PHL" },
      clientList: [],
      isFilterApplied: false,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    let { socket } = this.context;
    if (socket) {
      socket.on(SocketEvents.NEW_SCAN_ADDED, (data) => {
        console.log("event emitted", data);
        // this.props.history.push(AppRoutes.SCANS_LIST);
        let newData = [...this.state.scans];
        const alreadyExistIndex = newData.findIndex(
          (item) => item.scanId === data[0].scanId
        );
        if (alreadyExistIndex > -1) {
          newData.splice(alreadyExistIndex, 1);
          newData.unshift(...data);
        } else {
          newData.splice(-1, 1);
          newData.unshift(...data);
        }
        this.setState({ scans: newData });
      });
    }
    this.handleQueryParams();
  };
  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    console.log("query", query);
    if (query && query.sort === "location") {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      lat = position.coords.latitude;
      long = position.coords.longitude;
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "created_at",
        order: query.order
          ? query.order
          : // : query.sort === 'scan_id' || query.sort === 'created_at'
            // ? 'desc'
            "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
        clientName: this.state.clientName,
      },
      () => {
        this.fetchScanRecords();
        this.fetchClientList();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchScanRecords = async () => {
    const { currentPage, sortBy, search, limit, newRecord, order, clientName } =
      this.state;
    let skip = limit * (currentPage - 1);
    let clientId = this.state.clientName.value;
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
      lat,
      long,
      clientId,
    };
    this.setState({
      isLoading: newRecord ? false : true,
    });
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SCANS_LIST.service,
      ApiRoutes.SCANS_LIST.url,
      ApiRoutes.SCANS_LIST.method,
      ApiRoutes.SCANS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { scans, totalRecords },
      } = response.data;
      this.setState({ scans, totalRecords, newRecord: false });
    }
  };
  loadClientOptions = async (inputValue, callback) => {
    //new ApiHelper().cancelRequest("cancel");
    //const result = await this.fetchProductsOption(inputValue);
    const result = this.state.clientList.filter((p) =>
      p.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(result);
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  onSelect = (options, name) => {
    this.setState({
      [name]: options,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (options) {
      params["clientId"] = options.value;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  setClientFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };
  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  componentWillUnmount = () => {
    this.emitSearch.cancel();
  };

  batchViewHandler = (id) => {
    this.props.history.push(AppRoutes.VIEW_BATCH.replace(":id", id));
  };
  userViewHandler = (id) => {
    this.props.history.push(AppRoutes.VIEW_USER.replace(":id", id));
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      console.log(data);
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "PHL",
            value: "PHL",
          },
          ...data,
        ],
      });
    }
  };

  render() {
    const {
      scans,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      order,
      search,
      clientList,
      clientName,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filtersOption = [
      {
        label: "Scan ID",
        value: "scan_id",
      },
      {
        label: "QR Code ID",
        value: "code_id",
      },
      {
        label: "Batch Number",
        value: "batch_number",
      },
      {
        label: "Product Name",
        value: "product_name",
      },
      {
        label: "User Name",
        value: "user_name",
      },
      {
        label: "Location",
        value: "location",
      },
      {
        label: "Purchased",
        value: "purchased",
      },
      {
        label: "Scan Date & Time",
        value: "created_at",
      },
    ];

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Scans</h1>
        <Breadcrumbs pathname={pathname} />

        <div className="card card-pagination card-main">
          <div className="card-header py-7550 align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3">
                <h6 className="font-book ">Scan Listing Details</h6>
              </div>
              <div className="col-md-9 mob-filter-data">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn-mob"}
                  >
                    <img src={pageDropdownIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                    {filtersOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.setFilter(value, "asc")}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dropdown no-arrow dp-right">
                  <button
                    type="button"
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={mobSearchIcon} alt="" />
                  </button>
                </div>
              </div>

              <div className="col-md-9">
                {/* <FilterComponent
                  label={"Client"}
                  options={clientList}
                  selectedFilter={clientId}
                  onFilterChange={(selectedValue) =>
                    this.setClientFilter(selectedValue, "clientId")
                  }
                />*/}
                <div className="custom_header_filters">
                  <SearchFilter
                    // styled={"customInput"}
                    placeholder={"Search by Scan ID, Batch Num, Product Name…"}
                    search={search}
                    onChange={this.handleChange}
                  />
                  <AsyncSelect
                    loadOptions={this.loadClientOptions}
                    placeholder={"Client"}
                    maxMenuHeight={200}
                    styles={customStyles}
                    defaultOptions={clientList}
                    value={clientName}
                    onChange={(selectedOption) =>
                      this.onSelect(selectedOption, "clientName")
                    }
                    noOptionsMessage={() => "No Option"}
                  />
                  <FilterComponent
                    options={filtersOption}
                    selectedFilter={sortBy}
                    onFilterChange={this.setFilter}
                  />
                </div>
              </div>
            </div>
          </div>
          <ListComponent
            scans={scans}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            totalRecords={totalRecords}
            limit={limit}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            batchViewHandler={this.batchViewHandler}
            userViewHandler={this.userViewHandler}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
            filtersOption={filtersOption}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Scans;
