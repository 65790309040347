/** @format */
import React from "react";
import "./batches.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import calendarIcon3 from "../../../assets/img/calenderDate.svg";
import moment from "moment";
import { FilterComponent } from "../../components/filters";
import { useLocation } from "react-router";

export const ReportHeader = ({
  selectedTime,
  dateRangeCallback,
  changeSelectedTime,
  onExport,
  customStartDate,
  customEndDate,
  clientList,
  clientName,
  setFilter,
}) => {
  const location = useLocation();
  console.log(location);
  return (
    <div className="col-md-12">
      <div className="exportRow clientDropdown">
        <div className="d-flex align-items-center">
          <div className="button_grp">
            <button
              className={selectedTime === "Today" ? "active" : ""}
              onClick={() => changeSelectedTime("Today")}
            >
              Today
            </button>
            <button
              className={selectedTime === "Yesterday" ? "active" : ""}
              onClick={() => changeSelectedTime("Yesterday")}
            >
              Yesterday
            </button>
            <button
              className={selectedTime === "Week" ? "active" : ""}
              onClick={() => changeSelectedTime("Week")}
            >
              Week
            </button>
            <button
              className={selectedTime === "Month" ? "active" : ""}
              onClick={() => changeSelectedTime("Month")}
            >
              Month
            </button>
            <button
              className={selectedTime === "Year" ? "active" : ""}
              onClick={() => changeSelectedTime("Year")}
            >
              Year
            </button>
          </div>
          <div className="datePickerBox position-relative">
            <DateRangePicker
              initialSettings={{ maxDate: new Date() }}
              onCallback={dateRangeCallback}
              autoUpdateInput={false}
              // startDate={new Date(moment().subtract(2,'day'))}
              // endDate={customEndDate}
            >
              <input
                type="text"
                className="form-control"
                value={
                  selectedTime === "Custom"
                    ? moment(customStartDate).format("L") +
                      " - " +
                      moment(customEndDate).format("L")
                    : " Select date"
                }
              />
            </DateRangePicker>
            <img src={calendarIcon3} className="calendarIcon" alt="" />
          </div>
        </div>
        <div className="filterRow">
          <button className="exportbtn" onClick={onExport}>
            Export
          </button>

          {location.pathname !== "/reports/batches" &&
            location.pathname !== "/reports/products" &&
            location.pathname !== "/reports" && (
              <FilterComponent
                label={"Client"}
                options={clientList}
                selectedFilter={clientName}
                onFilterChange={(selectedValue) =>
                  setFilter(selectedValue, "clientName")
                }
              />
            )}
        </div>
      </div>
    </div>
  );
};
