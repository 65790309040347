import React from "react";

export const SearchFilter = ({
  placeholder,
  search,
  onChange,
  onSearch,
  styled,
  id,
}) => {
  return (
    <form
      id={id}
      className="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 table-search dp-right serach-wd-1024"
      // onSubmit={onSearch}
    >
      <div className={`input-group  ${styled ? "customInput" : ""}`}>
        <input
          type="search"
          style={{
            height: styled ? "36px" : "auto",
            backgroundPosition: styled ? "7px 12px" : "auto",
          }}
          className="form-control bg-white border-0 small "
          placeholder={placeholder}
          id="table-bar-icon"
          aria-label="Search"
          aria-describedby="basic-addon2"
          name={"search"}
          value={search}
          onChange={onChange}
        />
      </div>
    </form>
  );
};
