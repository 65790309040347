import React from "react";
import moment from "moment";
import { AppRoutes, DEFAULT_DATE_FORMAT } from "../../../config";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import { Button, Dropdown } from "react-bootstrap";
import { BatchHistoryLoader } from "../../components/loader/Resusable/BatchHistory";
import SweetAlertComponent from "../sweetAlertComponent";
import printIcon from "../../../assets/img/printer.svg";
import deleteIcon from "../../../assets/img/deleteIcon.svg";

export const BatchHistoryComponent = ({
  batchHistory,
  isLoading,
  activeBatchGroup,
  onExport,
  onBatchHistoryDelete,
  inactiveBatch,
  isBatchInactive,
}) => {
  console.log(batchHistory);
  const filtersOption = [
    {
      label: "CSV",
      value: "csv",
    },
    {
      label: "PDF",
      value: "pdf",
    },
    {
      label: "Excel",
      value: "excel",
    },
  ];
  const batchHistoryPrint = async (
    batchId,
    batchGroup,
    unitCount,
    first_name
  ) => {
    let mapObj = {
      ":id": batchId,
      ":unit": unitCount,
    };
    const { value } = await SweetAlertComponent({
      title: "Are you Sure?",
      text: `This will be a duplicated code and may already have been used.`,
      confirmButtonText: "Print",
      reverseButtons: true,
    });
    if (!value) {
      return;
    }
    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    window.open(
      `${AppRoutes.PRINT_BATCH.replace(re, function (matched) {
        return mapObj[matched.toLowerCase()];
      })}?group=${batchGroup}`,
      "_blank"
    );
  };
  return (
    <div className="card mb-12 card-main flex-fill">
      {isLoading ? (
        <BatchHistoryLoader />
      ) : (
        <>
          {" "}
          <div className="card-header py-7550 align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book ">Batch History</h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table" id={"batch-history"}>
            <div className="table-responsive">
              <table width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Exported By</th>
                    <th>Ordered Unit</th>
                    <th>Ordered Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {batchHistory && batchHistory.length ? (
                    batchHistory.map((batch, index) => (
                      <tr key={index}>
                        <td>
                          {batch.client
                            ? batch.client.username === "phl@clarity.com"
                              ? "Professional Hair Labs"
                              : batch.storeName
                            : "-"}
                        </td>
                        <td>{batch.count}</td>
                        <td>
                          {batch.orderDate
                            ? moment(batch.orderDate).format(
                                DEFAULT_DATE_FORMAT
                              )
                            : "-"}
                        </td>
                        <td>
                          <div className="d-flex align-items-center batchHistory_table">
                            <Dropdown className="no-arrow exportBtn">
                              <Dropdown.Toggle
                                variant=""
                                id="dropdown-basic"
                                className={"filter-btn color-secondary-border"}
                                data-boundary="window"
                                disabled={
                                  activeBatchGroup === batch.batchGroup ||
                                  batch.isInactive
                                }
                              >
                                {activeBatchGroup === batch.batchGroup ? (
                                  <>
                                    <i className="fa fa-spinner fa-spin test-spin"></i>
                                    &nbsp;Exporting
                                  </>
                                ) : (
                                  <>
                                    Export as
                                    <img src={filterarrowIcon} alt="" />
                                  </>
                                )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="shadow dropdown-menu ">
                                {filtersOption.map(
                                  ({ label, value }, index) => (
                                    <Dropdown.Item
                                      as="div"
                                      key={index}
                                      onClick={() =>
                                        onExport(
                                          value,
                                          batch.client ? batch.client._id : "",
                                          batch.orderDate,
                                          batch.batchGroup
                                        )
                                      }
                                    >
                                      {label}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            <Button
                              variant=""
                              className="printButton d-flex align-items-center justify-content-center"
                              onClick={() =>
                                batchHistoryPrint(
                                  batch.batchId,
                                  batch.batchGroup,
                                  batch.count,
                                  batch.first_name
                                )
                              }
                              disabled={activeBatchGroup || batch.isInactive}
                            >
                              <img
                                src={printIcon}
                                alt=""
                                className={"img-main mr-2"}
                              />
                              Print
                            </Button>
                            <Button
                              variant=""
                              className={
                                batch.isInactive
                                  ? "inactiveBatch inactiveButton d-flex align-items-center justify-content-center"
                                  : "inactiveButton d-flex align-items-center justify-content-center"
                              }
                              disabled={batch.isInactive}
                              onClick={() =>
                                inactiveBatch(batch.batchId, batch.batchGroup)
                              }
                            >
                              {batch.isInactive ? (
                                "Inactive"
                              ) : isBatchInactive === batch.batchGroup ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin"></i>
                                  &nbsp;Mark as Inactive
                                </>
                              ) : (
                                "Mark as Inactive"
                              )}
                            </Button>
                            <Button
                              disabled={batch.isInactive}
                              variant=""
                              className="printButton d-flex align-items-center justify-content-center"
                              onClick={() =>
                                onBatchHistoryDelete(
                                  batch.batchId,
                                  batch.batchGroup
                                )
                              }
                            >
                              <img
                                src={deleteIcon}
                                alt=""
                                className={"img-main mr-2"}
                              />
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8} className={"text-center"}>
                        {"Batch is not assigned to anyone yet."}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
