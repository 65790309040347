import Validator from "js-object-validation";
import { message } from "../common";

export const scanRuleValidator = (data) => {
  const validations = {
    name: {
      required: true,
    },
    noOfUsers: {
      required: true,
    },
    sendAlert: {
      required: true,
    },
    alertType: {
      required: true,
    },
    country: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    name: {
      required: message.Required.replace(":item", "Rule name"),
    },
    noOfUsers: {
      required: message.Required.replace(":item", "no Of Users"),
    },
    sendAlert: {
      required: message.Required.replace(":item", "sendAlert"),
    },
    alertType: {
      required: message.Required.replace(":item", "alert Type"),
    },
    country: {
      required: message.Required.replace(":item", "Country"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
