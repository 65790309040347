import axios from 'axios';
import { AppConfig } from '../config';
import { getToken } from './userData';

const instance = axios.create({
  baseURL: AppConfig.API_ENDPOINT,
  responseType: 'json',
});

instance.interceptors.request.use(
  config => {
    // Do something before request is sent
    config.headers['Authorization'] = 'bearer ' + getToken();
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

const userLogin = data => {
  const { email = '', password = '' } = data;
  let payload = {
    email: email,
    password: password,
  };
  return instance.post('/auth/login', payload).then(resp => {
    return resp.data;
  });
};

const userList = data => {
  return instance.get('/users/list').then(resp => {
    return resp.data;
  });
};

const userBlock = payload => {
  return instance.post('/admin/blockUser', payload).then(resp => {
    return resp.data;
  });
};

const viewUser = id => {
  return instance.post('/admin/viewUser', { id: id }).then(resp => {
    return resp.data;
  });
};

const addUser = payload => {
  return instance.post('/users', payload).then(resp => {
    return resp.data;
  });
};

export { userLogin, userList, userBlock, viewUser, addUser };
