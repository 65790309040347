import React from "react";
import Skeleton from "react-skeleton-loader";
const getColumns = (count) => {
  const columns = [];
  for (let i = 0; i < count; i++) {
    columns.push(
      <div className="col-md-3 col-vd" key={i}>
        <div className="cat_head" style={{ minHeight: "405px" }}>
          <h4 className="head_name">
            <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
          </h4>
          <div className="cat_desc">
            <div className="num_head">
              <h4 className="head_name">
                <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
              </h4>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
            </div>
            <div className="date_head">
              <h4 className="head_name rt-mg">
                <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
              </h4>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
            </div>
          </div>
          <div className="cat_desc text-center">
            <div className="w-100" style={{ marginBottom: "1em" }}>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100px"} />
            </div>
            <div className="w-100" style={{ marginBottom: ".5em" }}>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
            </div>
            <div className="w-100" style={{ marginBottom: ".5em" }}>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
            </div>
            <div className="w-100" style={{ marginBottom: ".5em" }}>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
            </div>
            <div className="w-100" style={{ marginBottom: ".5em" }}>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return columns;
};
export const CategoryCard = ({ columns = 8 }) => <>{getColumns(columns)}</>;
