import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import Pagination from "../../../components/pagination";
import { PlanListComponent } from "../../../components/PlanListComponent/PlanListComponent.jsx";
import { ApiRoutes, AppRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { addTopic } from "../../../../validators/addTopic.js";
import * as qs from "query-string";
import "./addTopic.css";
import { AddTopicFormComponent } from "../../../components/accountManagement/addTopicFormComponent.jsx";

class AddTopicPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planData: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      deleteError: false,
      sortBy: "createdAt",
      order: "desc",
      planType: "",
      planDuration: "",
      name: "",
      description: "",
      price: "",
      users: "",
      BrandAndCertificate: null,
      printCodes: "",
      domainName: null,
      email: "",
      errors: null,
      isEditMode: false,
      planId: "",
      clientList: "",
      clientId: "",
      clientName: "",
    };
  }

  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ planId: id, isEditMode: true }, () => {
        this.getPlansById();
      });
    }
    this.fetchClientList();
  };

  addTopic = async (payload) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.ADD_TOPIC_NEW.service,
      ApiRoutes.ADD_TOPIC_NEW.url,
      ApiRoutes.ADD_TOPIC_NEW.method,
      ApiRoutes.ADD_TOPIC_NEW.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.data.message);
      this.props.history.push("/cms/Web/account-management/");
    }
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.GET_ALL_MODULE_LISTING_NEW.service,
      ApiRoutes.GET_ALL_MODULE_LISTING_NEW.url,
      ApiRoutes.GET_ALL_MODULE_LISTING_NEW.method,
      ApiRoutes.GET_ALL_MODULE_LISTING_NEW.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        clientList: data.modules,
      });
    }
  };

  getPlansById = async () => {
    const { planId, clientName } = this.state;

    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTION_ID.service,
      ApiRoutes.GET_SUBSCRIPTION_ID.url.replace(":id", planId),
      ApiRoutes.GET_SUBSCRIPTION_ID.method,
      ApiRoutes.GET_SUBSCRIPTION_ID.authenticate,
      // data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data: planData } = response.data;
      const {
        planType,
        name,
        description,
        price,
        users,
        BrandAndCertificate,
        printCodes,
        domainName,
        email,
        clientId,
        clientName,
      } = planData;
      this.setState({
        planType:
          {
            label: planType,
            value: planType,
          } || "",
        name: name || "",
        description: description || "",
        price: price ? price : "" || "",
        users: users ? parseInt(users) : "" || "",
        BrandAndCertificate:
          {
            label: BrandAndCertificate ? "Yes" : "No",
            value: BrandAndCertificate ? 1 : 2,
          } || "",
        printCodes: printCodes ? parseInt(printCodes) : "" || "",
        domainName:
          {
            label: domainName ? "Yes" : "No",
            value: domainName ? 1 : 2,
          } || "",
        email: email || "",
        clientId: { label: clientId?.first_name, value: clientId?.id },
      });
    }
  };

  updatePlan = async (payload) => {
    const { planId } = this.state;

    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EDIT_SUBSCRIPTION.service,
      ApiRoutes.EDIT_SUBSCRIPTION.url.replace(":id", planId),
      ApiRoutes.EDIT_SUBSCRIPTION.method,
      ApiRoutes.EDIT_SUBSCRIPTION.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.data.message);
      this.props.history.push(AppRoutes.PLANS);
    }
  };

  handleSubmit = async (event) => {
    event && event.preventDefault();
    const { name, description, planType } = this.state;
    let payload = {};
    payload = {
      topic: name || "",
      description: description || "",
      moduleId: planType.value || "",
    };
    let finalData = payload;
    const { isValid, errors } = addTopic(finalData);
    if (isValid) {
      // finalData.BrandAndCertificate =
      //   finalData.BrandAndCertificate === 1 ? true : false;
      // finalData.domainName = finalData.domainName === 1 ? true : false;
      // if (planId) {
      //   this.updatePlan(finalData);
      // } else {
      //   this.addPlan(finalData);
      // }
      this.addTopic(finalData);
    } else {
      this.setState({ errors: errors });
      return;
    }
  };
  onSelect = (label, name) => {
    if (label) {
      this.setState({
        [name]: label,
      });
    }
  };
  handleChange = (event) => {
    var regex = /^\d+\.\d{0,2}$/;
    const {
      target: { type, value, name, checked },
    } = event;

    if (name === "topic" && !isNaN(value)) {
      return;
    }

    if (name === "description" && !isNaN(value)) {
      return;
    }

    this.setState({
      [name]: type === "checkbox" ? checked : value.trimLeft(),
      // errors: { ...planData.errors, [name]: "" },
    });
  };

  render() {
    const {
      errors,
      name,
      price,
      email,
      description,
      users,
      printCodes,
      domainName,
      BrandAndCertificate,
      planType,
      isAdding,
      isLoading,
      isEditMode,
      clientList,
      clientId,
    } = this.state;

    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <>
        <div className="container-fluid fluid-pd-2">
          <h1 className="h3-head-mob d-md-none">
            {isEditMode ? "Edit Topic" : "Add Topic"}
          </h1>
          <Breadcrumbs pathname={pathname} />
          <div className="card card-main card-pagination addplanCard">
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-3 head-cat-col-lg">
                  <h6 className="font-book">
                    {isEditMode ? "Edit Topic" : "Add Topic"}
                  </h6>
                </div>
              </div>
            </div>
            <AddTopicFormComponent
              isLoading={isLoading}
              handleSubmit={this.handleSubmit}
              isEditMode={isEditMode}
              name={name}
              price={price}
              email={email}
              description={description}
              domainName={domainName}
              BrandAndCertificate={BrandAndCertificate}
              users={users}
              planType={planType}
              printCodes={printCodes}
              onSelect={this.onSelect}
              errors={errors}
              onChange={this.handleChange}
              isAdding={isAdding}
              clientList={clientList}
              clientId={clientId}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AddTopicPage;
