import React from "react";
import shieldLogo from "../../../../assets/img/shield-tick.png";

export const SubmissionSuccess = () => {
  return (
    <div className="row row-mt-4">
      <div className="col-md-12">
        <form className="user_add mt-2">
          <div className="row">
            <div className="col-md-12 dp-align-center">
              <img className="done-logo" src={shieldLogo} alt="" />
              <h4 className="pr-bold">Almost Done ! </h4>
              <p className="pr-light">
                “Thank you for your application submission. One of our customer
                success team will be in touch shortly to send you are customer
                trade agreement. Once this document has been digitally signed,
                we will proceed to approve your application and open your
                account. If you have any questions, please email{" "}
                <b className="primary-link">info@prohairlabs.com</b>"
              </p>
              <a href="" className="btn btn-primary next-btn">
                OK
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
