import React from 'react';
import moment from 'moment';
// import '../login.css';
const Footer = () => {
  return (
    <footer className='sticky-footer ft'>
      <div className='container my-auto footer-container'>
        <div className='copyright text-center my-auto'>
          <span className='footer-text'>
            {moment().format('Y')} © Professional Hair Labs. All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
