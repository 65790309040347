import React from "react";
import RequestRow from "./RequestRow";
import { TableLoader } from "../../loader/Resusable/Table";
import { AddStaffFormComponent } from "../AddStaffFormComponent";
import noData from "../../../../assets/img/no-data.png";

const StaffListRequestComponent = ({
  requests,
  isLoading,
  isFormOpen,
  isUpdating,
  activeId,
  viewHandler,
  onCancel,
  updateStatus,
  resendInvitation,
  setSorting,
  order,
  sortBy,
}) => {
  console.log("requests", requests);
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-sm">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead>
              <tr>
                <th>
                  Name
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "name" ? "active-ac" : ""
                      }`}
                      onClick={() => setSorting("name", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "name" ? "active-ac" : ""
                      }`}
                      onClick={() => setSorting("name", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Employee ID
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "employee_id"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setSorting("employee_id", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "employee_id"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setSorting("employee_id", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>Role</th>
                <th>Email</th>
                <th>
                  Status
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "status"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setSorting("status", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "status"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setSorting("status", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {/* {isFormOpen ? (
                <AddStaffFormComponent onCancel={onCancel} />
              ) : null} */}
              {requests && requests.length ? (
                requests.map((request, index) => (
                  <RequestRow
                    key={index}
                    requestData={request}
                    isUpdating={isUpdating && activeId === request.id}
                    viewUser={() => viewHandler(request.id)}
                    updateStatus={(status, message) =>
                      updateStatus(request.id, status, message)
                    }
                    resendInvitation={() => resendInvitation(request.id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={8} className={"text-center image-no-data-sm"}>
                    <img src={noData} alt="" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default StaffListRequestComponent;
