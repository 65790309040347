import React from "react";
import { ProgressBar } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { InputTextField, SubmitButton } from "../forms";
import { AppConfig } from "../../../config";
import uploadImage from "../../../assets/img/upload-image.png";
import trashIcon from "../../../assets/img/trash-btn.svg";
import "./user.css";
import countryStateData from "../../../common/countryState.json";
import { ApiHelper } from "../../../helpers";
import { components, DropdownIndicatorProps } from "react-select";
import { areaCode } from "../../../common/phoneCode";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

export const AddUserFormComponent = ({
  first_name,
  last_name,
  email,
  phone,
  countryCode,
  address1,
  address2,
  state,
  originId,
  originOptions,
  stateOptions,
  postCode,
  profile_picture,
  errors,
  isUploading,
  isAdding,
  uploadPercentage,
  onChange,
  onSelect,
  onFileChange,
  onDeleteFile,
  handleCancel,
  handleSubmit,
  onSelectOption,
  // loadOriginOptions,
  country,
}) => {
  let allCountry = [];
  countryStateData.forEach((element) => {
    allCountry.push({ label: element.name, value: element.name });
  });

  const loadOriginOptions = async (inputValue, callback) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="d-none" />
      </components.DropdownIndicator>
    );
  };
  const customStylesAsync = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "unset",
        border: "0px",
      },
      border: "0px",
      padding: "0px",
      height: "100%",
      backgroundColor: "transparent",
      boxShadow: "unset",
      minWidth: "50px",
      // display: "block",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: "0px",
      maxWidth: "65px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
    }),
    container: (provided, state) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    menu: (provided, state) => ({
      ...provided,
      minWidth: "100px",
    }),
  };

  let codeData = [];
  areaCode.forEach((element) => {
    codeData.push({ label: element.dial_code, value: element.dial_code });
  });
  const loadAreaCodeData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = codeData.filter((code) =>
        code.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  return (
    <form className="user_add" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-3 mob-show">
          {/* <div className='img-col-user'>
            <img
              className='user-img'
              src={
                profile_picture
                  ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                  : uploadImage
              }
              alt=''
            />
          </div>
          {isUploading ? (
            <ProgressBar now={uploadPercentage} className={'mt-2'} />
          ) : null} */}
          {/* <div className='file-upload-btn'>
            <label className='file-label'>
              {' '}
              {profile_picture ? 'Change Photo' : 'Choose Photo'}
              <input
                type='file'
                name='profile_picture'
                accept='image/x-png,image/jpeg ,image/jpg, image/png ,image/svg'
                onChange={onFileChange}
              />
            </label>
            <span onClick={onDeleteFile} className={'cursor-pointer'}>
              <img src={trashIcon} alt='' />
            </span>
          </div> */}
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-4">
              <InputTextField
                id="first_name"
                label={"First Name *"}
                className={"form-control-user"}
                name="first_name"
                value={first_name}
                onChange={onChange}
                errorMessage={
                  errors && errors.first_name ? errors.first_name : ""
                }
                maxLength={30}
              />
            </div>
            <div className="col-md-4">
              <InputTextField
                id="last_name"
                label={"Last Name"}
                className={"form-control-user"}
                name="last_name"
                value={last_name}
                onChange={onChange}
                errorMessage={
                  errors && errors.last_name ? errors.last_name : ""
                }
                maxLength={30}
              />
            </div>
            <div className="col-md-4">
              <label className={`form-control-label form-control-user`}>
                Phone Number *
              </label>
              <div className="description-col">
                <div className="col-md-12 pb-2 pl-0">
                  <div className="d-flex contact-holder">
                    <div className="col-md-3 p-0 before">
                      <AsyncSelect
                        cacheOptions
                        components={{
                          IndicatorsContainer: () => null,
                          DropdownIndicator,
                          Input: (props) => (
                            <components.Input {...props} maxLength={6} />
                          ),
                        }}
                        loadOptions={loadAreaCodeData}
                        styles={customStylesAsync}
                        placeholder={"00"}
                        className="areaCode"
                        maxMenuHeight={200}
                        max={6}
                        width="200px"
                        defaultOptions={codeData}
                        value={countryCode}
                        name="countryCode"
                        onChange={(selectedOption) => {
                          onSelect(selectedOption, "countryCode");
                        }}
                        isSearchable={true}
                      />
                    </div>

                    <div className="col-md-10 p-0">
                      <InputTextField
                        id="phone"
                        className={"form-control-user border-0"}
                        name="phone"
                        value={phone}
                        onChange={onChange}
                        maxLength={14}
                      />
                    </div>
                  </div>
                  <div className="text-danger">{errors?.phone}</div>
                  <div className="text-danger">{errors?.countryCode}</div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <InputTextField
                id="email"
                label={"Email *"}
                className={"form-control-user"}
                name="email"
                value={email}
                onChange={onChange}
                errorMessage={errors && errors.email ? errors.email : ""}
              />
            </div>
            <div className="col-md-4">
              <InputTextField
                id="address_line_1"
                label={"Address Line 1"}
                className={"form-control-user"}
                name="address1"
                value={address1}
                onChange={onChange}
                postCode
                errorMessage={errors && errors.address1 ? errors.address1 : ""}
              />
            </div>
            <div className="col-md-4">
              <InputTextField
                id="address_line_2"
                label={"Address Line 2"}
                className={"form-control-user"}
                name="address2"
                value={address2}
                onChange={onChange}
                errorMessage={errors && errors.address2 ? errors.address2 : ""}
              />
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>Country</label>
                <AsyncSelect
                  loadOptions={loadOriginOptions}
                  placeholder={"Select Country"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={allCountry}
                  value={country}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "country")
                  }
                  noOptionsMessage={() =>
                    "No country found related to your search"
                  }
                />
                <div className={"text-danger"}>
                  {errors && errors.country ? errors.country : ""}
                </div>
              </div>
            </div>
            {/* {stateOptions?.length > 0 && ()} */}
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>State</label>
                <Select
                  placeholder={"Select State"}
                  options={stateOptions}
                  styles={customStyles}
                  value={state}
                  maxMenuHeight={200}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "state")
                  }
                  noOptionsMessage={() => {
                    return country && country.value
                      ? "No states found"
                      : "Please select country first";
                  }}
                />
                <div className={"text-danger"}>
                  {errors && errors.state ? errors.state : ""}
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <InputTextField
                type="number"
                id="post_code"
                label={"Post Code"}
                className={"form-control-user"}
                name="postCode"
                value={postCode}
                onChange={onChange}
                errorMessage={errors && errors.postCode ? errors.postCode : ""}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3 mob-hide">
          <div className="img-col-user">
            <img
              className="user-img"
              // src={
              //   profile_picture
              //     ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
              //     : uploadImage
              // }
              src={
                profile_picture
                  ? profile_picture.startsWith("https://")
                    ? profile_picture
                    : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                  : uploadImage
              }
              alt=""
            />
          </div>
          {isUploading ? (
            <ProgressBar now={uploadPercentage} className={"mt-2"} />
          ) : null}
          <div className="file-upload-btn ">
            <label className="file-label">
              {" "}
              {profile_picture ? "Change Image" : "Choose Image"}
              <input
                className="imgUpload img_up"
                name="profile_picture"
                type="file"
                accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                onChange={onFileChange}
              />
            </label>
            <span onClick={onDeleteFile} className={"cursor-pointer"}>
              <img src={trashIcon} alt="" />
            </span>
          </div>
        </div>

        <div className="col-md-9 footer-form">
          <button
            type="button"
            className="btn btn-primary cancel-btn"
            onClick={handleCancel}
          >
            {" "}
            CANCEL
          </button>

          <SubmitButton
            className={"submit-btn"}
            text={"INVITE"}
            isLoading={isAdding}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </form>
  );
};
