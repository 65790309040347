import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { Breadcrumbs } from '../../../components/partial/Breadcrumbs';
import {
  BatchHistoryComponent,
  BatchHistoryDetailComponent,
} from '../../../components/clients';
import PrintClientBatchModal from './PrintClientBatch';
import { ApiHelper } from '../../../../helpers';
import { ApiRoutes, DB_ACCPTABLE_FORMAT, AppRoutes } from '../../../../config';
import SweetAlertComponent from '../../../components/sweetAlertComponent';
import { DeleteErrorModal } from '../../../components/sweetAlertComponent/DeleteError';
import '../manage-clients.css';

class BatchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: '',
      clientName: '',
      productId: '',
      batchHistory: [],
      selectedBatchData: {},
      assignData: {},
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      isLoading: false,
      isClientDetailsLoading: false,
      updatingSection: 'export',
      isUploading: false,
      uploadPercentage: 0,
      printModal: false,
      deleteBatchError: false,
      isInactivating: '',
      activeBatchGroup: '',
      errorMessage: 'This batch has already been printed or exported.',
    };
  }

  componentDidMount = () => {
    const {
      match: {
        params: { id, productId },
      },
    } = this.props;
    this.setState(
      {
        clientId: id,
        productId,
      },
      () => {
        this.fetchClientInfo();
        this.batchHistoryByProduct();
      }
    );
  };
  fetchClientInfo = async () => {
    const { clientId } = this.state;
    this.setState({
      isClientDetailsLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_CLIENT.service,
      ApiRoutes.VIEW_CLIENT.url.replace(':id', clientId),
      ApiRoutes.VIEW_CLIENT.method,
      ApiRoutes.VIEW_CLIENT.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isClientDetailsLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      const { first_name = '', last_name = '' } = data || {};
      this.setState({
        clientName: [first_name, last_name].filter(Boolean).join(' '),
      });
    }
  };
  batchHistoryByProduct = async (loading = true) => {
    const { clientId, productId } = this.state;
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BATCH_HISTORY_BY_PRODUCT.service,
      ApiRoutes.BATCH_HISTORY_BY_PRODUCT.url.replace(':id', clientId),
      ApiRoutes.BATCH_HISTORY_BY_PRODUCT.method,
      ApiRoutes.BATCH_HISTORY_BY_PRODUCT.authenticate,
      { clientId, productId },
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        batchHistory: data,
      });
    }
  };
  onPrintBatch = async (selectedBatchData) => {
    const { clientId } = this.state;
    let itemCount = selectedBatchData.assignedUnits;
    let batchGroup = selectedBatchData.batchGroup;
    let orderDate = selectedBatchData.orderDate;
    let id = selectedBatchData.id;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.MARK_CLIENT_BATCH_PRINTED.service,
      ApiRoutes.MARK_CLIENT_BATCH_PRINTED.url.replace(':id', id),
      ApiRoutes.MARK_CLIENT_BATCH_PRINTED.method,
      ApiRoutes.MARK_CLIENT_BATCH_PRINTED.authenticate,
      undefined,
      { unit: itemCount, clientId, orderDate, batchGroup }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.batchHistoryByProduct();
      let mapObj = {
        ':id': id,
        ':unit': itemCount,
      };
      var re = new RegExp(Object.keys(mapObj).join('|'), 'gi');
      window.open(
        `${AppRoutes.PRINT_BATCH.replace(re, function (matched) {
          return mapObj[matched.toLowerCase()];
        })}?assignee=${clientId}&group=${batchGroup}`,
        '_blank'
      );
    }
    /* this.setState({
      printModal: true,
      selectedBatchData,
    }); */
  };
  onBulkDelete = async () => {
    const { batchHistory, clientId } = this.state;
    const temp = batchHistory.map((item) => ({
      batchId: item.batchId,
      orderDate: item.orderDate,
      isPrinted: item.isPrinted,
    }));
    const batchData = temp.filter(
      (element) => !element.isPrinted.includes(true)
    );
    if (batchData.length < 1) {
      this.setState({
        errorMessage: 'All batches has already been printed or exported.',
        deleteBatchError: true,
      });
      return;
    }
    console.log(batchData, 'batchDatabatchData');
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to delete batch?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      updatingSection: 'deleteAll',
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNASSIGNED_ALL_BATCH_FROM_CLIENT.service,
      ApiRoutes.UNASSIGNED_ALL_BATCH_FROM_CLIENT.url,
      ApiRoutes.UNASSIGNED_ALL_BATCH_FROM_CLIENT.method,
      ApiRoutes.UNASSIGNED_ALL_BATCH_FROM_CLIENT.authenticate,
      undefined,
      {
        clientId,
        batchData,
      }
    );
    if (response && response.isError) {
      this.setState({
        updatingSection: 'delete',
      });
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.VIEW_CLIENT.replace(':id', clientId));
    }
  };
  onDelteBatch = async ({ id, isPrinted, orderDate, activeBatchGroup }) => {
    const isBatchPrinted = isPrinted.includes(true);
    console.log(
      'inside delete batch view isPrinted',
      isBatchPrinted,
      isPrinted
    );
    if (isBatchPrinted) {
      this.setState({
        errorMessage: 'This batch has already been printed or exported.',
        deleteBatchError: true,
      });
    } else {
      const { value } = await SweetAlertComponent({
        // title: 'Are you sure?',
        text: `Are you sure, you want to delete batch?`,
      });
      if (!value) {
        return;
      }
      this.setState({
        activeBatchGroup,
        updatingSection: 'delete',
      });
      const { clientId } = this.state;
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.UNASSIGNED_BATCH_FROM_CLIENT.service,
        ApiRoutes.UNASSIGNED_BATCH_FROM_CLIENT.url.replace(':id', id),
        ApiRoutes.UNASSIGNED_BATCH_FROM_CLIENT.method,
        ApiRoutes.UNASSIGNED_BATCH_FROM_CLIENT.authenticate,
        undefined,
        {
          clientId,
          orderDate: orderDate
            ? moment(orderDate).format(DB_ACCPTABLE_FORMAT)
            : undefined,
        }
      );
      if (response && response.isError) {
        this.setState({
          activeBatchGroup,
          updatingSection: 'delete',
        });
        toast.error(response.messages[0]);
      } else {
        await this.batchHistoryByProduct(false);
        this.setState({
          activeBatchGroup: '',
          updatingSection: '',
        });
        toast.success(response.messages[0]);
      }
    }
  };
  // To close print batch modal
  handleClose = () => {
    this.setState({
      printModal: false,
    });
  };

  customBreadCrumb = () => {
    const { isClientDetailsLoading, clientName } = this.state;
    return isClientDetailsLoading ? (
      <Spinner animation="border" size="sm" as={'span'} />
    ) : (
      clientName
    );
  };

  // To close delete batch error modal
  handleCloseDelete = () => {
    this.setState({
      errorMessage: 'This batch has already been printed or exported.',
      deleteBatchError: false,
    });
  };

  onExport = async (type, clientId, orderDate, batchId, activeBatchGroup) => {
    let blobType, extension;
    let data = { type, orderDate, batchGroup: activeBatchGroup };
    if (clientId) {
      this.setState({
        activeBatchGroup,
        updatingSection: 'export',
      });
      data = {
        ...data,
        clientId,
      };
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_BATCH.service,
      ApiRoutes.EXPORT_BATCH.url.replace(':id', batchId),
      ApiRoutes.EXPORT_BATCH.method,
      ApiRoutes.EXPORT_BATCH.authenticate,
      data,
      undefined,
      'blob'
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: '',
      updatingSection: '',
    });
    if (response && response.isError) {
    } else {
      switch (type) {
        case 'pdf':
          blobType = 'application/pdf';
          extension = 'pdf';
          break;
        case 'excel':
          blobType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          extension = 'xlsx';
          break;
        case 'csv':
          blobType = 'application/csv';
          extension = 'csv';
          break;
        default:
          break;
      }
      let blob = new Blob([response.data], {
        type: blobType,
      });
      FileSaver.saveAs(blob, `Batch.${extension}`);
    }
  };

  markAsInactive = async (data) => {
    // console.log('data', data);
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to mark as inactive?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isInactivating: data.batchGroup,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.MARK_INACTIVE_CLIENT_BATCH.service,
      ApiRoutes.MARK_INACTIVE_CLIENT_BATCH.url,
      ApiRoutes.MARK_INACTIVE_CLIENT_BATCH.method,
      ApiRoutes.MARK_INACTIVE_CLIENT_BATCH.authenticate,
      undefined,
      data
    );
    this.setState({
      isInactivating: '',
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
    }
  };

  render() {
    const {
      activeBatchGroup,
      updatingSection,
      clientId,
      isLoading,
      printModal,
      deleteBatchError,
      selectedBatchData,
      batchData,
      batchHistory,
      errorMessage,
      isInactivating,
    } = this.state;
    const productDetails =
      batchHistory && batchHistory.length
        ? {
            ...batchHistory[0].product,
            customProductName: batchHistory[0].customProductName,
          }
        : {};
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
        />

        <div className="row">
          <div className="col-md-12 d-flex">
            <BatchHistoryDetailComponent
              productDetails={productDetails}
              batchData={batchData}
              batchHistory={batchHistory}
              isLoading={isLoading}
              onEdit={this.onEdit}
              onBulkDelete={this.onBulkDelete}
              isDeleting={updatingSection === 'deleteAll'}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <BatchHistoryComponent
              clientId={clientId}
              batchData={batchData}
              batchHistory={batchHistory}
              isLoading={isLoading}
              activeBatchGroup={activeBatchGroup}
              updatingSection={updatingSection}
              onEdit={this.onEdit}
              onPrintBatch={this.onPrintBatch}
              onDelteBatch={this.onDelteBatch}
              onExport={this.onExport}
              isInactivating={isInactivating}
              markAsInactive={this.markAsInactive}
            />
          </div>
        </div>
        <PrintClientBatchModal
          open={printModal}
          clientId={clientId}
          batchData={selectedBatchData}
          handleClose={this.handleClose}
        />
        <DeleteErrorModal
          open={deleteBatchError}
          handleClose={this.handleCloseDelete}
          message={errorMessage}
          moduleName={'Batch'}
        />
      </div>
    );
  }
}

export default BatchView;
