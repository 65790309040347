/** @format */

import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import * as qs from "query-string";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import Pagination from "../../../components/pagination";
import PHLContext from "../../../../PHLContext";
import { SearchFilter } from "../../../components/filters/searchFilter";
import filterarrowIcon from "../../../../assets/img/filter-arrow.svg";
import pageDropdownIcon from "../../../../assets/img/tag-bd.svg";
import mobSearchIcon from "../../../../assets/img/tag-search-bd.svg";
import "./moduleLisitng.css";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import { InputTextField } from "../../../components/forms";
import { FilterComponent } from "../../../components/filters";
import add_job from "../../../../assets/img/add_job.svg";
import { ModuleListComponent } from "../../../components/accountManagement/moduleListing/moduleListComponent";
import { ModulesValidator } from "../../../../validators/modules";
import addIcon from "../../../../assets/img/addIcon.svg";
let lat, long;

class ModuleListingPage extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      moduleDetail: [],
      IndividualModuleDetail: {},
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      activeId: "",
      sortBy: "createdAt",
      order: "desc",
      search: "",
      newRecord: false,
      isFilterApplied: false,
      showAddForm: false,
      catOption: [],
      userDetail: "",
      isViewModal: false,
      isAdding: false,
      name: "",
      errors: {},
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.setState(
      {
        userDetail: this.props.location.pathname.split("/")[2], //[3],
      },
      () => this.handleQueryParams()
    );
    //this.handleQueryParams();
  };
  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    if (query && query.sort === "location") {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      lat = position.coords.latitude;
      long = position.coords.longitude;
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "createdAt",
        order: query.order
          ? query.order
          : // : query.sort === 'scan_id' || query.sort === 'created_at'
            // ? 'desc'
            "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
      },
      () => this.fetchModuleRecords()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }, prevState) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
    const splitValue = this.props.location.pathname.split("/")[2]; //[3];
    if (prevState.userDetail !== splitValue) {
      this.setState({
        userDetail: splitValue,
      });
      // setTimeout(() => {
      //   this.handleQueryParams();
      // }, 500);
    }
  };

  fetchModuleRecords = async () => {
    this.setState({
      moduleDetail: [],
      isLoading: true,
    });
    const { currentPage, sortBy, search, limit, newRecord, order, userDetail } =
      this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
    };
    // Function to check if a value is empty (undefined, null, or empty string)
    const isEmpty = (value) =>
      value === undefined || value === null || value === "";

    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      if (!isEmpty(value)) {
        acc[key] = value;
      }
      return acc;
    }, {});

    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }

    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.GET_ALL_MODULE_LISTING_NEW.service,
      ApiRoutes.GET_ALL_MODULE_LISTING_NEW.url,
      ApiRoutes.GET_ALL_MODULE_LISTING_NEW.method,
      ApiRoutes.GET_ALL_MODULE_LISTING_NEW.authenticate,
      { ...filteredData, order: data?.order == "desc" ? "DESC" : "ASC" },
      undefined,
      undefined,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isLoading: false,
      });
    } else {
      const datas = response?.data?.data;
      this.setState({
        moduleDetail: datas.modules,
        // datas?.data?.sort((a, b) => {
        //   if (data?.sortBy === "name") {
        //     // Use localeCompare for string comparison
        //     return data?.order === "asc"
        //       ? a.name.localeCompare(b.name)
        //       : b.name.localeCompare(a.name);
        //   } else if (data?.sortBy === "email") {
        //     // Use localeCompare for string comparison
        //     return data?.order === "asc"
        //       ? a.email.localeCompare(b.email)
        //       : b.email.localeCompare(a.email);
        //   } else {
        //     return 0; // No sorting for other cases
        //   }
        // }),
        newRecord: false,
        totalRecords: datas?.totalRecords,
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onChange = (event) => {
    console.log(event.target.value);
    const {
      target: { name, value },
    } = event;
    if (name === "name") {
      this.setState({ name: value.trimLeft() });
    }
  };

  handleAddCancel = (event) => {
    console.log(event, "::event");
    this.setState({
      IndividualModuleDetail: {},
      showAddForm: false,
      isViewModal: false,
    });
    if (event) event.preventDefault();
    else {
      // this.setState({ moduleDetail: [] });
      this.fetchModuleRecords();
    }
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  componentWillUnmount = () => {
    this.emitSearch.cancel();
  };

  openAddTitleList = () => {
    this.props.history.push(`/cms/Web/careers/job-title`);
  };

  openAddTopic = () => {
    this.props.history.push(`/cms/Web/account-management/add-topic`);
  };

  openAddModal = () => {
    this.setState({
      showAddForm: true,
      // moduleDetail: this.state.moduleDetail,
    });
  };

  closeAddModal = () => {
    this.setState({
      showAddForm: false,
    });
  };

  handleSubmit = (event) => {
    const formData = new FormData();

    this.setState({
      isLoading: true,
    });
    event.preventDefault();
    let payload = {};
    if (this.state.userDetail === "Web") {
      payload = {
        name: this.state.name,
      };
      formData.append("name", this.state.name);
    }
    const { isValid, errors } = ModulesValidator(payload);

    let filterData = "";
    // if (userDetail !== "App") {
    //   filterData = partners?.filter(
    //     (x) =>
    //       parseInt(x.priority) === parseInt(priority) &&
    //       x.categoryName === categoryId.label
    //   );
    // }
    if (isValid) {
      this.setState({ errors: {} });
      if (filterData.length === 0) {
        if (this.state.IndividualModuleDetail._id) {
          formData.append("_id", this.state.IndividualModuleDetail._id);

          this.updateEntry(formData);
        } else {
          this.addEntry(formData);
        }
      } else {
        this.setState({
          isLoading: false,
        });
        // toast.error("Priority already exist under this category");
      }
    } else {
      this.setState({
        isLoading: false,
      });
      this.setState({ errors: errors });
      return;
    }
  };

  addEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.ADD_MODULE_NEW.service,
      ApiRoutes.ADD_MODULE_NEW.url,
      ApiRoutes.ADD_MODULE_NEW.method,
      ApiRoutes.ADD_MODULE_NEW.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      // setIsLoading(false);
      this.setState({
        isLoading: false,
      });
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        // setErrors(response.validationErrors);
        this.setState({
          errors: response.validationErrors,
        });
      }
    } else {
      toast.success(response.data.message);
      this.handleAddCancel();
    }
  };

  updateEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.UPDATE_MODULE_NEW.service,
      ApiRoutes.UPDATE_MODULE_NEW.url,
      ApiRoutes.UPDATE_MODULE_NEW.method,
      ApiRoutes.UPDATE_MODULE_NEW.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      // setIsLoading(false);
      this.setState({
        isLoading: false,
      });
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      this.handleAddCancel();
    }
  };

  viewUser = (details) => {
    console.log(details);
    this.setState({
      IndividualModuleDetail: details,
      isViewModal: true,
    });
    this.props.history.push(`/enquiries/general/details/${details?._id}`);
  };

  onEdit = (IndividualModuleDetail) => {
    this.setState({
      IndividualModuleDetail: IndividualModuleDetail,
      showAddForm: true,
    });
  };
  onDelete = async (id) => {
    console.log(id, ":::id");
    const { value } = await SweetAlertComponent({
      title: "",
      text: `Are you sure, you want to delete?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.DELETE_MODULE_NEW.service,
      ApiRoutes.DELETE_MODULE_NEW.url,
      ApiRoutes.DELETE_MODULE_NEW.method,
      ApiRoutes.DELETE_MODULE_NEW.authenticate,
      { _id: id },
      undefined,
      undefined
    );
    this.setState({
      id: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
      toast.success(response.messages[0]);
      await this.fetchModuleRecords();
    }
  };

  handleUpdateStatus = async (status, requestId) => {
    this.setState({
      isLoading: true,
    });
    const payload = {
      enquiryId: requestId,
      status: status,
    };

    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.CHANGE_STATUS_GENERAL_ENQUIRIES_NEW.service,
      ApiRoutes.CHANGE_STATUS_GENERAL_ENQUIRIES_NEW.url,
      ApiRoutes.CHANGE_STATUS_GENERAL_ENQUIRIES_NEW.method,
      ApiRoutes.CHANGE_STATUS_GENERAL_ENQUIRIES_NEW.authenticate,
      undefined,
      payload,
      undefined
    );
    if (response && response.isError) {
      this.setState({
        isLoading: false,
      });
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      this.handleQueryParams();
    }
  };

  render() {
    const {
      moduleDetail,
      IndividualModuleDetail,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      order,
      search,
      catOption,
      userDetail,
      isViewModal,
      showAddForm,
      isAdding,
      errors,
      name,
    } = this.state;

    let allOptions = [];
    // catOption &&
    //   catOption.forEach((element) => {
    //     allOptions.push({ label: element.categoryName, value: element.id });
    //   });

    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filtersOption = [
      {
        label: "Name",
        value: "name",
      },
    ];

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">CMS</h1>
        <Breadcrumbs pathname={pathname} />

        <div className="card card-pagination card-main">
          <div className="card-header py-7550 align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book ">Module Listing </h6>
              </div>
              <div className="col-md-10 mob-filter-data">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn-mob"}
                  >
                    <img src={pageDropdownIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                    {filtersOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.setFilter(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dropdown no-arrow dp-right">
                  <button
                    type="button"
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={mobSearchIcon} alt="" />
                  </button>
                </div>
              </div>

              <div className="col-md-9 filter-col-lg">
                <div className="no-arrow dp-right dropdown">
                  <Button
                    className="pushButtonCZ"
                    onClick={this.openAddModal}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "1px",
                      }}
                    >
                      <img src={addIcon} alt="" srcset="" />
                    </div>
                    Add Module
                  </Button>
                </div>

                <FilterComponent
                  options={filtersOption}
                  selectedFilter={sortBy}
                  onFilterChange={this.setFilter}
                />
                <SearchFilter
                  placeholder={"Search"}
                  search={search}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <ModuleListComponent
            moduleDetail={moduleDetail}
            IndividualModuleDetail={IndividualModuleDetail}
            isAdding={isAdding}
            isFilterApplied={isFilterApplied}
            showAddForm={showAddForm}
            isMainLoading={isLoading}
            totalRecords={totalRecords}
            limit={limit}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            fetchModuleRecords={this.fetchModuleRecords}
            userDetail={userDetail}
            viewUser={this.viewUser}
            handleUpdateStatus={this.handleUpdateStatus}
            handleAddCancel={this.handleAddCancel}
            closeAddModal={this.closeAddModal}
            SaveSubmit={this.handleSubmit}
            onChange={this.onChange}
            errors={errors}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ModuleListingPage;
