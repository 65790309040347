/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { ScanGraphComponent } from "./ScanGraphComponent";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import "./batches.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import { ReportHeader } from "./ReportHeader";
import { ReportCard } from "./ReportCard";
import { ScanTable } from "./ScanTable";
import { TopScannedProductGraphComponent } from "./TopScannedProductGraphComponent";
import { GraphLoader } from "../../components/loader/Dashboard/GraphLoader";
import FileSaver from "file-saver";
import upIcon from "../../../assets/img/up-icon.svg";
import moment from "moment";
import * as qs from "query-string";

class ReportScan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: "Today",
      customStartDate: new Date(),
      customEndDate: new Date(),
      cardsLoading: true,
      tableLoading: true,
      cardsData: null,
      tableData: null,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      productsGraphLoading: true,
      productsGraphData: null,
      scanData: {},
      scanStatsBy: { label: "This week", value: "Week" },
      isScanStatisticLoading: false,
      clientName: "PHL",
      clientList: [],
    };
  }

  dateRangeCallback = async (start, end, label) => {
    await this.setState({
      selectedTime: "Custom",
      customStartDate: new Date(start),
      customEndDate: new Date(end),
    });
    this.fetchData();
  };

  onPageChanged = (page) => {
    const { selectedTime, customStartDate, customEndDate, limit, currentPage } =
      this.state;
    if (page != currentPage) {
      this.setState({
        currentPage: page,
      });
      let skip = limit * (page - 1);
      const payload = {
        selectedTime,
        customStartDate,
        customEndDate,
        skip,
        limit,
      };
      this.fetchTable(payload);
    }
  };

  changeSelectedTime = async (val) => {
    await this.setState({
      selectedTime: val,
      scanStatsBy: { value: val },
    });

    switch (val) {
      case "Today":
        this.setState({
          customStartDate: new Date(),
          customEndDate: new Date(),
        });
        break;
      case "Yesterday":
        this.setState({
          customStartDate: new Date(moment().subtract(1, "day")),
          customEndDate: new Date(moment().subtract(1, "day")),
        });
        break;
      case "Week":
        this.setState({
          customStartDate: new Date(moment().startOf("week")),
          customEndDate: new Date(moment().endOf("week")),
        });
        break;
      case "Month":
        this.setState({
          customStartDate: new Date(moment().startOf("month")),
          customEndDate: new Date(moment().endOf("month")),
        });
        break;
      case "Year":
        this.setState({
          customStartDate: new Date(moment().startOf("year")),
          customEndDate: new Date(moment().endOf("year")),
        });
        break;
      default:
    }

    this.fetchData();
  };

  componentDidMount = () => {
    this.handleQueryParams();
  };

  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    console.log("query", query);
    this.setState(
      {
        isFilterApplied: query.sort || query.search ? true : false,
        clientName: query.clientName || "PHL",
      },
      () => {
        this.fetchData();
        this.fetchClientList();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  setFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };

  fetchData = async () => {
    const {
      selectedTime,
      customStartDate,
      customEndDate,
      limit,
      currentPage,
      clientName,
    } = this.state;
    let skip = limit * (currentPage - 1);
    const payload = {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      limit,
      clientName,
    };
    this.fetchCards(payload);
    this.fetchTable(payload);
    this.fetchProductsGraph(payload);
    // this.getScanStatistics();
  };

  fetchCards = async (data) => {
    this.setState({
      cardsLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_SCAN_CARDS.service,
      ApiRoutes.REPORTS_SCAN_CARDS.url,
      ApiRoutes.REPORTS_SCAN_CARDS.method,
      ApiRoutes.REPORTS_SCAN_CARDS.authenticate,
      data,
      undefined
    );

    this.setState({
      cardsLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      let scanData = response.data.data.scanGraph;
      delete response.data.data.scanGraph;
      this.setState({
        cardsData: response.data.data,
        scanData: scanData,
      });
    }
  };

  fetchTable = async (data) => {
    this.setState({
      tableLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_SCAN_TABLE.service,
      ApiRoutes.REPORTS_SCAN_TABLE.url,
      ApiRoutes.REPORTS_SCAN_TABLE.method,
      ApiRoutes.REPORTS_SCAN_TABLE.authenticate,
      data,
      undefined
    );

    this.setState({
      tableLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        tableData: response.data.data.scans,
        totalRecords: response.data.data.totalRecords,
      });
    }
  };

  fetchProductsGraph = async (data) => {
    this.setState({
      productsGraphLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_SCAN_GRAPH_PRODUCTS.service,
      ApiRoutes.REPORTS_SCAN_GRAPH_PRODUCTS.url,
      ApiRoutes.REPORTS_SCAN_GRAPH_PRODUCTS.method,
      ApiRoutes.REPORTS_SCAN_GRAPH_PRODUCTS.authenticate,
      data,
      undefined
    );

    this.setState({
      productsGraphLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { dataset },
      } = response;

      this.setState({
        productsGraphData: {
          labels: dataset.labels ? dataset.labels.reverse() : [],
          value: dataset.value ? dataset.value.reverse() : [],
        },
      });
    }
  };

  // getScanStatistics = async () => {
  //   const { scanStatsBy } = this.state;
  //   this.setState({
  //     isScanStatisticLoading: true,
  //   });
  //   const response = await new ApiHelper().FetchFromServer(
  //     ApiRoutes.DASHBOARD_SCAN_STATISTICS.service,
  //     ApiRoutes.DASHBOARD_SCAN_STATISTICS.url,
  //     ApiRoutes.DASHBOARD_SCAN_STATISTICS.method,
  //     ApiRoutes.DASHBOARD_SCAN_STATISTICS.authenticate,
  //     { type: scanStatsBy ? scanStatsBy.value : 'week' },
  //     undefined
  //   );
  //   this.setState({
  //     isScanStatisticLoading: false,
  //   });
  //   if (response && response.isError) {
  //     // toast.error(response.messages[0]);
  //   } else {
  //     const {
  //       data: { currentScanDataSet, todayScans, totalScans, prevScanDataSet },
  //     } = response;
  //     this.setState({
  //       scanData: {
  //         currentScanDataSet,
  //         todayScans,
  //         totalScans,
  //         prevScanDataSet,
  //       },
  //     });
  //   }
  // };

  //  10/06/22

  onExport = async () => {
    const {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      limit,
      clientName,
    } = this.state;
    let data = {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      limit,
      clientName,
    };
    let type = "csv";
    // if (selectedTime === 'Custom') data = { selectedTime, customStartDate, customEndDate }
    // if (selectedTime === 'Custom') data = { selectedTime, customStartDate, customEndDate }

    let blobType, extension;
    if (data) {
      this.setState({
        isBatchExporting: true,
      });
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_REPORTS_SCAN_TABLE.service,
      ApiRoutes.EXPORT_REPORTS_SCAN_TABLE.url,
      ApiRoutes.EXPORT_REPORTS_SCAN_TABLE.method,
      ApiRoutes.EXPORT_REPORTS_SCAN_TABLE.authenticate,
      data,
      undefined,
      "blob"
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: "",
    });
    if (response && response.isError) {
    } else {
      switch (type) {
        case "pdf":
          blobType = "application/pdf";
          extension = "pdf";
          break;
        case "excel":
          blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          extension = "xlsx";
          break;
        case "csv":
          blobType = "application/csv";
          extension = "csv";
          break;
        default:
          break;
      }
      let blob = new Blob([response.data], {
        type: blobType,
      });
      FileSaver.saveAs(
        blob,
        `scan.${extension}`
        // `${moment().format('YYYY_MM_DD')}_${batchId}.${extension}`,
      );
    }
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "PHL",
            value: "PHL",
          },
          ...data,
        ],
      });
    }
  };

  render() {
    const { clientList, clientName } = this.state;

    const {
      props: {
        location: { pathname },
      },
    } = this;

    const thead = ["Scan ID", "Product Name", "Scan Location", "Scanned On"];

    const { isScanStatisticLoading, scanData, scanStatsBy } = this.state;

    const { currentScanDataSet, todayScans, totalScans, prevScanDataSet } =
      scanData;

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Reports</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="row">
          <ReportHeader
            clientName={clientName}
            clientList={clientList}
            setFilter={this.setFilter}
            selectedTime={this.state.selectedTime}
            dateRangeCallback={this.dateRangeCallback}
            changeSelectedTime={this.changeSelectedTime}
            onExport={this.onExport}
            customStartDate={this.state.customStartDate}
            customEndDate={this.state.customEndDate}
          />

          <div className="col-md-12">
            <div className="row">
              <ReportCard
                loading={this.state.cardsLoading}
                title="Total Scans"
                total={this.state.cardsData?.total}
                perc={this.state.cardsData?.addedCalculation}
                prevString={this.state.cardsData?.dateString}
                size="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6 mt-md-0"
              />

              <ReportCard
                loading={this.state.cardsLoading}
                title="Scans Increased"
                total={this.state.cardsData?.added}
                perc={this.state.cardsData?.addedCalculation}
                prevString={this.state.cardsData?.dateString}
                size="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6 mt-md-0"
              />
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-4 mt-md-0">
            <div className="row">
              <div className="col-md-12 mt-2">
                <div className="overflow-auto border-radius_4">
                  <ScanTable
                    loading={this.state.tableLoading}
                    thead={thead}
                    tbody={this.state.tableData}
                  />
                </div>
              </div>
              {!this.state.tableLoading ? (
                <div className="w-100">
                  <div className="float-right mt-2">
                    {this.state.totalRecords > this.state.limit ? (
                      <div className="float-right">
                        <Pagination
                          totalRecords={this.state.totalRecords}
                          currentPage={this.state.currentPage}
                          pageLimit={this.state.limit}
                          onPageChanged={this.onPageChanged}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-4 mt-md-0">
            <div className="">
              <div className="card mb-4 w-100 shadow-border graph-card flex-fill mt-2">
                {/* <div className="card-header py-4550  align-items-center justify-content-between">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="mt-2 font-book ">Scans</h6>
                    </div>
                  </div>
                </div> */}
                <div className="card-body">
                  <div
                    className="card-header py-0 px-0 align-items-center justify-content-between border-0 mt-3"
                    style={{ height: "auto", marginBottom: ".5em" }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="mt-0 font-book mb-0">Scans</h6>
                      </div>
                    </div>
                  </div>
                  <div id="chart">
                    <div className="card-body area-graph px-0">
                      {isScanStatisticLoading ? (
                        <GraphLoader />
                      ) : (
                        <>
                          <div className="description">
                            <div className="head">{totalScans}</div>
                            <div className={"mb-2 today-count"}>
                              Today {todayScans} scanned{" "}
                              <img src={upIcon} alt="" />
                            </div>
                          </div>
                          <div>
                            <ScanGraphComponent
                              type={
                                scanStatsBy && scanStatsBy.value === "Week"
                                  ? "Week"
                                  : scanStatsBy.value === "Month"
                                  ? "Month"
                                  : "Year"
                              }
                              scanDataSet={currentScanDataSet}
                              prevScanDataSet={prevScanDataSet}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {/* <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} /> */}
                  </div>
                </div>
                <div className="TopScannedProducts_card">
                  <div
                    className="card-header py-0  align-items-center justify-content-between border-0"
                    style={{ height: "auto", marginBottom: ".5em" }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="mt-0 font-book mb-0">
                          Top Scanned Products
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.state.productsGraphLoading ? (
                      <GraphLoader />
                    ) : (
                      <div>
                        {this.state.productsGraphData?.labels?.length ? (
                          <TopScannedProductGraphComponent
                            dataset={this.state.productsGraphData}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="card mb-4 w-100 shadow-border graph-card flex-fill">
                <div className="card-header py-4550  align-items-center justify-content-between">
                  <div className="row">
                    <div className="col-md-12">
                      <h6 className="mt-2 font-book ">Top Scanned Products</h6>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {this.state.productsGraphLoading ? (
                    <GraphLoader />
                  ) : (
                    <div>
                      {this.state.productsGraphData?.labels?.length ? (
                        <TopScannedProductGraphComponent
                          dataset={this.state.productsGraphData}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportScan;
