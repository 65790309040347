/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import "./batches.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import { ReportHeader } from "./ReportHeader";
import { ReportCard } from "./ReportCard";
import { ProductTable } from "./ProductTable";
import * as qs from "query-string";
import FileSaver from "file-saver";
import moment from "moment";
class ReportProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: "Today",
      customStartDate: new Date(),
      customEndDate: new Date(),
      cardsLoading: true,
      tableLoading: true,
      cardsData: null,
      tableData: null,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      clientName: "all",
      clientList: [],
    };
  }

  dateRangeCallback = async (start, end, label) => {
    await this.setState({
      selectedTime: "Custom",
      customStartDate: new Date(start),
      customEndDate: new Date(end),
    });
    this.fetchData();
  };

  onPageChanged = (page) => {
    console.log(page);
    const { selectedTime, customStartDate, customEndDate, limit, currentPage } =
      this.state;
    if (page !== currentPage) {
      this.setState({
        currentPage: page,
      });
      let skip = limit * (page - 1);
      const payload = {
        selectedTime,
        customStartDate,
        customEndDate,
        skip,
        limit,
      };
      this.fetchTable(payload);
    }
  };

  changeSelectedTime = async (val) => {
    await this.setState({
      selectedTime: val,
    });

    switch (val) {
      case "Today":
        this.setState({
          customStartDate: new Date(),
          customEndDate: new Date(),
        });
        break;
      case "Yesterday":
        this.setState({
          customStartDate: new Date(moment().subtract(1, "day")),
          customEndDate: new Date(moment().subtract(1, "day")),
        });
        break;
      case "Week":
        this.setState({
          customStartDate: new Date(moment().startOf("week")),
          customEndDate: new Date(moment().endOf("week")),
        });
        break;
      case "Month":
        this.setState({
          customStartDate: new Date(moment().startOf("month")),
          customEndDate: new Date(moment().endOf("month")),
        });
        break;
      case "Year":
        this.setState({
          customStartDate: new Date(moment().startOf("year")),
          customEndDate: new Date(moment().endOf("year")),
        });
        break;
      default:
    }

    this.fetchData();
  };

  componentDidMount = () => {
    this.handleQueryParams();
  };

  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    console.log("query", query);
    this.setState(
      {
        isFilterApplied: query.sort || query.search ? true : false,
        clientName: query.clientName || "all",
      },
      () => {
        this.fetchData();
        this.fetchClientList();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  setFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };

  fetchData = async () => {
    const {
      selectedTime,
      customStartDate,
      customEndDate,
      limit,
      currentPage,
      clientName,
    } = this.state;
    let skip = limit * (currentPage - 1);
    const payload = {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      clientName,
      limit,
    };
    this.fetchCards(payload);
    this.fetchTable(payload);
  };

  fetchCards = async (data) => {
    this.setState({
      cardsLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_PRODUCT_CARDS.service,
      ApiRoutes.REPORTS_PRODUCT_CARDS.url,
      ApiRoutes.REPORTS_PRODUCT_CARDS.method,
      ApiRoutes.REPORTS_PRODUCT_CARDS.authenticate,
      data,
      undefined
    );

    this.setState({
      cardsLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        cardsData: response.data.data,
      });
    }
  };

  fetchTable = async (data) => {
    this.setState({
      tableLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_PRODUCT_TABLE.service,
      ApiRoutes.REPORTS_PRODUCT_TABLE.url,
      ApiRoutes.REPORTS_PRODUCT_TABLE.method,
      ApiRoutes.REPORTS_PRODUCT_TABLE.authenticate,
      data,
      undefined
    );

    this.setState({
      tableLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        tableData: response.data.data.products,
        totalRecords: response.data.data.totalRecords,
      });
    }
  };

  //  10/06/22
  onExport = async () => {
    const { selectedTime, customStartDate, customEndDate, clientName } =
      this.state;
    let data = {
      selectedTime,
      customStartDate,
      clientName,
      customEndDate,
    };
    let type = "csv";

    let blobType, extension;
    if (data) {
      this.setState({
        isBatchExporting: true,
      });
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_REPORTS_PRODUCT_TABLE.service,
      ApiRoutes.EXPORT_REPORTS_PRODUCT_TABLE.url,
      ApiRoutes.EXPORT_REPORTS_PRODUCT_TABLE.method,
      ApiRoutes.EXPORT_REPORTS_PRODUCT_TABLE.authenticate,
      data,
      undefined,
      "blob"
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: "",
    });
    if (response && response.isError) {
    } else {
      switch (type) {
        case "pdf":
          blobType = "application/pdf";
          extension = "pdf";
          break;
        case "excel":
          blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          extension = "xlsx";
          break;
        case "csv":
          blobType = "application/csv";
          extension = "csv";
          break;
        default:
          break;
      }
      let blob = new Blob([response.data], {
        type: blobType,
      });
      FileSaver.saveAs(
        blob,
        `Product.${extension}`
        // `${moment().format('YYYY_MM_DD')}_${batchId}.${extension}`,
      );
    }
  };
  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "PHL",
            value: "PHL",
          },
          ...data,
        ],
      });
    }
  };

  render() {
    const { clientList, clientName } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const thead = ["Product Number", "Product Name", "Created On"];

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Reports</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="row">
          <ReportHeader
            selectedTime={this.state.selectedTime}
            dateRangeCallback={this.dateRangeCallback}
            changeSelectedTime={this.changeSelectedTime}
            onExport={this.onExport}
            customStartDate={this.state.customStartDate}
            customEndDate={this.state.customEndDate}
            clientName={clientName}
            setFilter={this.setFilter}
            clientList={clientList}
          />

          <ReportCard
            loading={this.state.cardsLoading}
            title="Total Products"
            total={this.state.cardsData?.total}
            // perc={0}
            // prevString={''}
            perc={this.state.cardsData?.addedCalculation}
            prevString={this.state.cardsData?.dateString}
            size="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6 mt-md-0"
          />

          <ReportCard
            loading={this.state.cardsLoading}
            title="Products Added"
            total={this.state.cardsData?.added}
            perc={this.state.cardsData?.addedCalculation}
            prevString={this.state.cardsData?.dateString}
            size="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6 mt-md-0"
          />

          <div className="col-md-12 overflow-auto mt-2">
            <ProductTable
              loading={this.state.tableLoading}
              thead={thead}
              tbody={this.state.tableData}
            />
          </div>
          {!this.state.tableLoading ? (
            <div className="w-100">
              <div className="float-right mt-2">
                {this.state.totalRecords > this.state.limit ? (
                  <div className="float-right">
                    <Pagination
                      totalRecords={this.state.totalRecords}
                      currentPage={this.state.currentPage}
                      pageLimit={this.state.limit}
                      onPageChanged={this.onPageChanged}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ReportProduct;
