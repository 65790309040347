import React, { useState, useEffect } from "react";
import { InputTextField, SubmitButton } from "../forms";
import { faqsValidator } from "../../../validators";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes, AppRoutes } from "../../../config";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

export const AddFormComponent = ({
  faq,
  handleAddCancel,
  loadOriginOptions,
  BrandAndCertificate,
  onSelect,
}) => {
  const [priority, setPriority] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "priority") {
      setPriority(value);
    }
    if (name === "question") {
      setQuestion(value);
    }
    if (name === "answer") {
      setAnswer(value);
    }
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    let payload = {
      priority: priority,
      question: question.trim(),
      answer: answer.trim(),
    };

    const { isValid, errors } = faqsValidator(payload);
    if (isValid) {
      setErrors({});
      if (faq.id) {
        updateEntry({ ...payload });
      } else {
        addEntry({ ...payload });
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
      return;
    }
  };

  const addEntry = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_FAQ.service,
      ApiRoutes.ADD_FAQ.url,
      ApiRoutes.ADD_FAQ.method,
      ApiRoutes.ADD_FAQ.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  const updateEntry = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_FAQ.service,
      ApiRoutes.UPDATE_FAQ.url.replace(":id", faq.id),
      ApiRoutes.UPDATE_FAQ.method,
      ApiRoutes.UPDATE_FAQ.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  useEffect(() => {
    console.log(faq);
    if (faq.id) {
      setIsEditMode(true);
      setPriority(faq.priority);
      setQuestion(faq.question);
      setAnswer(faq.answer);
    } else {
      setIsEditMode(false);
    }
  }, []);

  const brandOptions = [
    { value: 1, label: "Category 1" },
    { value: 2, label: "Category 2" },
  ];

  return (
    <form className="product_add proAdd">
      <div className="row">
        <div className="row">
          <div className="col-md-12">
            <label className="faqbottomclass">
              Please enter the FAQ’s to be listed in the mobile app here
            </label>
          </div>
          <div className="col-md-12">
            <InputTextField
              type="number"
              id="priority"
              label={"Priority"}
              className={"form-control-product"}
              name="priority"
              value={priority}
              onChange={onChange}
              min="1"
              errorMessage={errors && errors.priority ? errors.priority : ""}
            />
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className={`form-control-label`}>Category</label>
              <AsyncSelect
                loadOptions={loadOriginOptions}
                placeholder={"Select "}
                styles={customStyles}
                maxMenuHeight={200}
                defaultOptions={brandOptions}
                value={BrandAndCertificate}
                onChange={(selectedOption) =>
                  onSelect(selectedOption, "BrandAndCertificate")
                }
                noOptionsMessage={() =>
                  "No origin found related to your search"
                }
              />

              <div className={"text-danger"}>
                {errors && errors.BrandAndCertificate
                  ? errors.BrandAndCertificate
                  : ""}
              </div>
            </div>
          </div>
          <div className="col-md-12" style={{ paddingTop: "25px" }}>
            <InputTextField
              placeholder="Enter Question Here"
              id="question"
              label={"Question"}
              className={"form-control-product"}
              name="question"
              value={question}
              onChange={onChange}
              errorMessage={errors && errors.question ? errors.question : ""}
            />
          </div>
          <div className="col-md-12" style={{ paddingTop: "25px" }}>
            <label htmlFor="product_description" className="form-control-label">
              Answer
            </label>
            <textarea
              placeholder="Enter Answer of the Question Here"
              className="form-control form-control-textarea wt-resize"
              cols="50"
              id="answer"
              aria-describedby="category_nameHelp"
              rows="8"
              name={"answer"}
              value={answer}
              onChange={onChange}
            />
            <div className={"text-danger"}>
              {errors && errors.answer ? errors.answer : ""}
            </div>
          </div>
        </div>

        <div className="col-md-12 footer-formCZ">
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary cancel-btn"
              onClick={handleAddCancel}
            >
              {" "}
              Cancel
            </button>

            <SubmitButton
              className={"submit-btn"}
              text={isEditMode ? "Update" : "Add"}
              isLoading={isLoading}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
