import React from 'react';
import { CounterfeitUser } from '../../components/loader/Resusable/CounterfeitUser';
import userIcon from '../../../assets/img/user-4.svg';
import phoneIcon from '../../../assets/img/phone.svg';
import emailIcon from '../../../assets/img/mail-1.svg';
import locationIcon from '../../../assets/img/location.svg';
import arrowIcon from '../../../assets/img/link-arr.svg';

export const UserDetails = ({ userDetails, isLoading, mapHandler }) => {
  const { first_name = '', phone = '', email = '', other_info = {} } =
    userDetails || {};
  const { latitude = '', longitude = '', address1 } = other_info || {};
  return (
    <div className='card mb-4 card-main flex-fill'>
      <div className='card-header py-7550 align-items-center justify-content-between'>
        <div className='row'>
          <div className='col-md-12'>
            <h6 className='font-book '>User Details</h6>
          </div>
        </div>
      </div>
      <div className='card-body card-table'>
        {isLoading ? (
          <CounterfeitUser />
        ) : (
          <>
            <div className='row mtblf-5'>
              <div className='col-md-6'>
                <div className='heading-col'>
                  <img src={userIcon} alt='' />
                  <h5>User Name</h5>
                </div>
                <div className='description-col'>
                  <h5>{first_name || '-'}</h5>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='heading-col'>
                  <img src={phoneIcon} alt='' />
                  <h5>User Phone</h5>
                </div>
                <div className='description-col'>
                  <h5>{phone || '-'}</h5>
                </div>
              </div>
            </div>
            <div className='row mtblf-5'>
              <div className='col-md-6'>
                <div className='heading-col'>
                  <img src={emailIcon} alt='' />
                  <h5>User Email</h5>
                </div>
                <div className='description-col'>
                  <h5>{email || '-'}</h5>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='heading-col'>
                  <img src={locationIcon} alt='' />
                  <h5>User Location</h5>
                  {latitude && longitude ? (
                    <span
                      className='a-link cursor-pointer'
                      onClick={
                        () => mapHandler(latitude, longitude, address1)
                        //   () => {
                        //   let left = window.screen.width / 2 - 500 / 2;
                        //   let top = window.screen.height / 2 - 500 / 2;
                        //   window.open(
                        //     `https://maps.google.com/?q=${latitude},${longitude}`,
                        //     'popUpWindow',
                        //     `height=500,width=500,left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`,
                        //   );
                        // }
                      }
                    >
                      Open Map <img src={arrowIcon} alt='' />
                    </span>
                  ) : null}
                </div>
                <div className='description-col'>
                  <h5>{address1 || '-'}</h5>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
