import React from "react";
import { TableLoader } from "../loader/Resusable/Table";
import editIcon from "../../../assets/img/edit-3.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import { Button } from "react-bootstrap";
import debitcard from "../../../assets/img/debitcard.svg";
import { CounterfeitProduct } from "../loader/Resusable/CounterfeitProduct";

export const SubscriptionDetails = ({ subscriptionDetails, isLoading }) => {
  return (
    <>
      <div className="card card-main flex-fill clientDetailsCard">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="font-book ">Subscription Details</h6>
                </div>
              </div>
            </div>
            <div className="card-body card-table">
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Plan Name</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {subscriptionDetails && subscriptionDetails.planName}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Auto Renewal</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {subscriptionDetails && subscriptionDetails.autoRenewal
                        ? "Yes"
                        : "No"}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Plan Duration</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {" "}
                      {subscriptionDetails && subscriptionDetails.planDuration}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Charges</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      ${subscriptionDetails && subscriptionDetails.charges}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
