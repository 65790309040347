import React from "react";
import { ProgressBar } from "react-bootstrap";
import { InputTextField, SubmitButton } from "../forms";
import { AppConfig } from "../../../config";
import uploadProductImage from "../../../assets/img/ph_product.jpg";
import trashIcon from "../../../assets/img/trash-btn.svg";
import "./product.css";
import moreImageIcon from "../../../assets/img/moreImageIcon.svg";

export const AddProductFormComponent = ({
  productNumber,
  productName,
  productDescription,
  productImage,
  otherImages,
  isEditMode,
  errors,
  isUploading,
  isAdding,
  uploadPercentage,
  onChange,
  onFileChange,
  onDeleteFile,
  handleCancel,
  handleSubmit,
  openGallery,
  totalBatches,
}) => {
  console.log("totalBatches", totalBatches);
  return (
    <form className="product_add addproduct_form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-4 mob-show">
          <div className="img-col-product row">
            <div className="col-8">
              <img
                className="product-img"
                // src={
                //   productImage
                //     ? `${AppConfig.FILES_ENDPOINT}${productImage}`
                //     : uploadProductImage
                // }
                src={
                  productImage
                    ? productImage.startsWith("https://")
                      ? productImage
                      : `${AppConfig.FILES_ENDPOINT}${productImage}`
                    : uploadProductImage
                }
                onClick={openGallery}
                alt=""
              />
            </div>
            {otherImages && otherImages.length ? (
              <div className="col-4 col-sm-img">
                <img
                  className="product-img-sm"
                  // src={
                  //   productImage
                  //     ? `${AppConfig.FILES_ENDPOINT}${productImage}`
                  //     : uploadProductImage
                  // }
                  src={
                    productImage
                      ? productImage.startsWith("https://")
                        ? productImage
                        : `${AppConfig.FILES_ENDPOINT}${productImage}`
                      : uploadProductImage
                  }
                  onClick={openGallery}
                  alt=""
                />
                {otherImages > 1 ? (
                  <div className="product-div-sm" onClick={openGallery}>
                    <span className="img-count">{otherImages - 1}+</span>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {isUploading ? (
            <ProgressBar now={uploadPercentage} className={"mt-2"} />
          ) : null}
          <div className="file-upload-btn-product">
            <label className="file-label">
              {" "}
              {productImage ? "Choose More" : "Choose Image"}
              <input
                type="file"
                name="product_image"
                accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                onChange={onFileChange}
              />
            </label>
            {productImage ? (
              <span onClick={onDeleteFile} className={"cursor-pointer"}>
                <img src={trashIcon} alt="" />
              </span>
            ) : null}
          </div>
        </div>
        <div className="col-md-8">
          <div className="row">
            <div className="col-md-6">
              <InputTextField
                id="product_number"
                label={"Enter Product Number"}
                className={"form-control-product"}
                name="productNumber"
                value={productNumber}
                onChange={onChange}
                disabled={totalBatches === 0 ? false : isEditMode}
                errorMessage={
                  errors && errors.productNumber ? errors.productNumber : ""
                }
                maxLength={12}
              />
            </div>
            <div className="col-md-6">
              <InputTextField
                id="product_name"
                label={"Enter Product Name"}
                className={"form-control-product"}
                name="productName"
                value={productName}
                onChange={onChange}
                disabled={totalBatches === 0 ? false : isEditMode}
                errorMessage={
                  errors && errors.productName ? errors.productName : ""
                }
                maxLength={30}
              />
            </div>
            <div className="col-md-12">
              <label
                htmlFor="product_description"
                className="form-control-label"
              >
                Enter Product Description
              </label>
              <textarea
                className="form-control form-control-textarea wt-resize"
                cols="50"
                id="product_description"
                aria-describedby="category_nameHelp"
                rows="4"
                name={"productDescription"}
                value={productDescription}
                onChange={onChange}
                maxLength={200}
              />
              <div className={"text-danger"}>
                {errors && errors.productDescription
                  ? errors.productDescription
                  : ""}
              </div>
            </div>
          </div>
          <div className="row productSubmit">
            <div className="col-md-12 footer-form">
              <button
                className="btn btn-primary cancel-btn"
                onClick={handleCancel}
              >
                {" "}
                CANCEL
              </button>

              <SubmitButton
                className={"submit-btn"}
                text={isEditMode ? "UPDATE" : "ADD"}
                isLoading={isAdding}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 mob-hide">
          <div className="img-col-product m-0 row">
            <div className="imageBoxHolder">
              {productImage ? (
                <img
                  className="product-img"
                  // src={
                  //   productImage
                  //     ? `${AppConfig.FILES_ENDPOINT}${productImage}`
                  //     : ""
                  // }
                  src={
                    productImage
                      ? productImage.startsWith("https://")
                        ? productImage
                        : `${AppConfig.FILES_ENDPOINT}${productImage}`
                      : uploadProductImage
                  }
                  onClick={openGallery}
                  alt=""
                />
              ) : (
                <>
                  {productName
                    ? productName
                        .split(" ")
                        .slice(0, 2)
                        .map((el) => el[0]?.toUpperCase())
                    : "No Image"}
                </>
              )}
            </div>

            {otherImages && otherImages.length ? (
              <div className="otherImageHolder">
                <img
                  className="product-img-sm"
                  // src={
                  //   otherImages[0]
                  //     ? `${AppConfig.FILES_ENDPOINT}${otherImages[0]}`
                  //     : uploadProductImage
                  // }
                  src={
                    otherImages[0]
                      ? otherImages[0].startsWith("https://")
                        ? otherImages[0]
                        : `${AppConfig.FILES_ENDPOINT}${otherImages[0]}`
                      : uploadProductImage
                  }
                  onClick={openGallery}
                  alt=""
                />
                {otherImages.length > 1 ? (
                  <div className="product-div-sm" onClick={openGallery}>
                    <span className="img-count">+{otherImages.length - 1}</span>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {isUploading ? (
            <ProgressBar now={uploadPercentage} className={"mt-2"} />
          ) : null}
          <div className="file-upload-btn-product uploadImageHolder">
            <label className="file-label">
              {" "}
              {productImage ? "Upload More" : "Upload Image"}
              <input
                className="imgUpload img_up"
                name="productImage"
                type="file"
                multiple
                accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                onChange={onFileChange}
              />
            </label>
            {productImage ? (
              <span
                onClick={() => onDeleteFile(productImage, "profile_picture")}
                className={"cursor-pointer"}
              >
                <img src={trashIcon} alt="" style={{ width: "45px" }} />
              </span>
            ) : null}
            {!productImage && (
              <label className="moreImage">
                <input
                  className="imgUpload img_up"
                  name="productImage"
                  type="file"
                  multiple
                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                  onChange={onFileChange}
                />
                <img src={moreImageIcon} alt="" />
              </label>
            )}
          </div>
          <div className={"text-danger"}>
            {errors && errors.productImage ? errors.productImage : ""}
          </div>
        </div>
      </div>
    </form>
  );
};
