const setUserData = data => {
  try {
    const userData = JSON.stringify(data);
    localStorage.setItem('userData', userData);
  } catch (error) {}
};

const getUserData = () => {
  try {
    const data = JSON.parse(localStorage.getItem('userData'));
    return data;
  } catch (error) {}
};

const getToken = () => {
  try {
    const userData = JSON.parse(localStorage.getItem('userData'));
    let token = userData ? userData.token : null;
    return token;
  } catch (error) {}
};

const isLogin = () => {
  try {
    const data = JSON.parse(localStorage.getItem('userData'));
    return data ? true : false;
  } catch (error) {}
};

const setCheck = (email, pass) => {
  try {
    let obj = { email: email, password: pass };
    const data = JSON.stringify(obj);
    localStorage.setItem('staylog', data);
  } catch (error) {}
};
const getCheck = () => {
  try {
    const data = JSON.parse(localStorage.getItem('staylog'));
    return data;
  } catch (error) {}
};
const clearCheck = () => {
  try {
    localStorage.removeItem('staylog');
  } catch (error) {}
};

const Logout = () => {
  try {
    localStorage.removeItem('userData');
    window.location.href = '/';
  } catch (error) {}
};

export {
  setUserData,
  getUserData,
  isLogin,
  Logout,
  getCheck,
  setCheck,
  clearCheck,
  getToken,
};
