import Validator from 'js-object-validation';
import { message } from '../common';

export const ownerDetailsValidator = data => {
  const validations = {
    first_name: {
      required: true,
      alpha: true,
    },
    last_name: {
      required: true,
      alpha: true,
    },
    email: {
      required: true,
      email: true,
    },
    phone: {
      required: true,
      numeric: true,
    },
    address1: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    first_name: {
      required: message.Required.replace(':item', 'First name'),
      alpha: message.InvalidName.replace(':item', 'First name'),
    },
    last_name: {
      required: message.Required.replace(':item', 'Last name'),
      alpha: message.InvalidName.replace(':item', 'Last name'),
    },
    email: {
      required: message.Required.replace(':item', 'Email address'),
      email: message.InvalidEmail,
    },
    phone: {
      required: message.Required.replace(':item', 'Phone number'),
      numeric: message.InvalidPhone,
    },
    address1: {
      required: message.Required.replace(':item', 'Address line 1'),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
