import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../../assets/img/edit-3.svg";
import editIconWhite from "../../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../../assets/img/trash-2-white.svg";
import Switch from "react-switch";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import listIcon from "../../../../assets/img/list.svg";
import reopen from "../../../../assets/img/reopen.svg";
import { ReactComponent as Reopen } from "../../../../assets/img/corner-up-right_new.svg";
import contact from "../../../../assets/img/contact.svg";
import minusCircleRightIcon from "../../../../assets/img/minus-circle-light.svg";
import SweetAlertComponent from "../../../components/sweetAlertComponent";

export const CareersRow = ({
  IndividualCareerDetail,
  onEdit,
  onDelete,
  fetchCareerRecords,
  viewUser,
  handleUpdateStatus,
}) => {
  const statusBackground = {
    completed: "#EBFBE8",
    contacted: "#FBF1E8",
    applied: "#E7EEF6",
    rejected: "#FFE9E9",
    shortlisted: "#FFFDE5",
  };
  const statusTextColor = {
    completed: "#3AD219",
    contacted: "#D27119",
    applied: "#0F51A1",
    rejected: "#FF2323",
    shortlisted: "#E2D100",
  };
  const [careerEdit, setCareerEdit] = useState(IndividualCareerDetail);
  // const careerEdit = IndividualCareerDetail;

  const status = careerEdit?.status?.toLocaleLowerCase();

  return (
    <tr>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          <div style={{ color: "#000" }}>{careerEdit?.candidateName}</div>
          <div>{careerEdit?.candidateEmail}</div>
        </div>
      </td>
      <td>
        <div className="icon_faqsBox" style={{ paddingLeft: "28px" }}>
          {careerEdit?.jobTitleId?.jobId}
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          <div>{careerEdit?.jobTitleId?.jobTitle}</div>
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          {careerEdit?.jobId?.address}
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          {careerEdit?.candidatePhone}
        </div>
      </td>

      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
            background: statusBackground[status],
            color: statusTextColor[status],
            padding: "5px",
            borderRadius: "6px",
            textAlign: "center",
          }}
        >
          {careerEdit?.status}
        </div>
      </td>

      <td className="switchBox">
        <div
          className="d-flex align-items-start justify-content-end"
          style={{
            width: "100%",
          }}
        >
          <button className="btn btn-secondary th-btn" onClick={viewUser}>
            {" "}
            <img src={listIcon} alt="" /> View{" "}
          </button>
        </div>
      </td>
    </tr>
  );
};
