import React from "react";
import Chart from "react-apexcharts";
// import { Chart, HorizontalBar } from 'react-chartjs-2';

export const TopScannedProductGraphComponent = ({ dataset }) => {
  const { labels = [], value = [] } = dataset || {};
  // const colors = value.map(v => (v > 30 ? '#4ab8ff' : '#0054fe'));
  const options = {
    chart: {
      id: "apexchart-example",
      toolbar: {
        show: false,
      },
    },
    colors: [
      function ({ value, seriesIndex, w }) {
        if (value < 50) {
          return "#0054fe";
        } else {
          return "#4ab8ff";
        }
      },
    ],

    grid: {
      show: false,
      padding: {
        top: value.length < 5 && value.length > 0 ? (5 - value.length) * 40 : 0,
        bottom: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "50%",
        barHeight: "20%",
        endingShape: "rounded",
        colors: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: "#0054FE",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#333"],
      },
      offsetX: 500,
    },
    xaxis: {
      categories: labels,
      // [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      lines: {
        show: false,
      },
      labels: {
        show: false,
        align: "left",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#8a94a6"],
        },
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "red",
      },
      labels: {
        show: true,
        align: "left",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#1D2524"],
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "15px",
        },
      },
    },
  };
  if (Math.max(...value) < 5) {
    options.xaxis.max = 10;
  }
  const series = [
    {
      name: "scanned",
      data: value,
    },
  ];

  return <Chart options={options} series={series} type="bar" width="100%" />;
  // <HorizontalBar data={data} options={graphOptions} height={250} />;
};
