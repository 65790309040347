import React from 'react';
import { InputTextField, InputCheckbox, SubmitButton } from '../forms';
import arrowBtnImg from '../../../assets/img/button-arrow.svg';

const LoginFormComponent = ({
  onChange,
  onSubmit,
  email,
  password,
  errors,
  islogged,
  isLoading,
}) => {
  return (
    <form className='user' onSubmit={onSubmit}>
      <InputTextField
        label='Enter Email'
        id='exampleInputEmail'
        name='email'
        value={email}
        onChange={onChange}
        errorMessage={errors && errors.email ? errors.email : ''}
      />
      <InputTextField
        label='Enter Password'
        id='exampleInputPassword'
        type={'password'}
        name='password'
        value={password}
        onChange={onChange}
        errorMessage={errors && errors.password ? errors.password : ''}
      />
      <InputCheckbox
        label='&nbsp;&nbsp;&nbsp;Stay logged in'
        id='customCheck'
        name='islogged'
        value={islogged}
        onChange={onChange}
      />
      <SubmitButton
        text={'LOGIN'}
        isLoading={isLoading}
        onSubmit={onSubmit}
        className={'btn-user btn-block'}
      >
        <img className='btn-img' src={arrowBtnImg} alt='' />
      </SubmitButton>
    </form>
  );
};

export default LoginFormComponent;
