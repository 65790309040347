import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const GraphLoader = () => (
  <div className='description'>
    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
    <Skeleton
      color='#e5e9ef'
      className={'mt-2'}
      height={'25px'}
      width={'100%'}
    />
  </div>
);
