import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { discountValidator } from "../../../validators/discountValidator";
import { InputTextField, SubmitButton } from "../../components/forms";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import edit_icon from "../../../assets/img/discountEdit.svg";
import { DiscountLoader } from "../../components/loader/Resusable/DiscountLoader";

class Discounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdating: false,
      discount: 0,
      errors: null,
      isLoading: false,
      isEditMode: false,
    };
  }
  componentDidMount = () => {
    this.getDiscount();
  };
  getDiscount = async () => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DISCOUNT.service,
      ApiRoutes.GET_DISCOUNT.url,
      ApiRoutes.GET_DISCOUNT.method,
      ApiRoutes.GET_DISCOUNT.authenticate,
      undefined
    );
    this.setState({
      isLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data.product;
      this.setState({
        discount: data[0].value ? data[0].value : 0,
      });
    }
  };

  addDiscount = async (data) => {
    this.setState({ isUpdating: true });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_DISCOUNT.service,
      ApiRoutes.ADD_DISCOUNT.url,
      ApiRoutes.ADD_DISCOUNT.method,
      ApiRoutes.ADD_DISCOUNT.authenticate,
      undefined,
      data
    );
    this.setState({ isUpdating: false });
    console.log(response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.getDiscount();
      this.setState({ isEditMode: false });
    }
  };

  handleSubmit = async (event) => {
    event && event.preventDefault();
    const { discount } = this.state;
    let data = {
      discount: discount ? parseInt(discount) : "" || "",
    };
    const { isValid, errors } = discountValidator(data);
    if (isValid) {
      this.addDiscount(data);
      // this.getDiscount();
    } else {
      this.setState({ errors: errors });
    }
  };
  handleChange = (event) => {
    var regex = /^\d+\.\d{0,2}$/;
    const {
      target: { type, value, name, checked },
    } = event;
    if (isNaN(value) || regex.test(value)) {
      return;
    }
    console.log(value);
    this.setState({
      discount: value,
    });
  };
  render() {
    const { isEditMode, errors, isUpdating, discount, isLoading } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2 subscribedviewPage discountPage">
        <h1 className="h3-head-mob d-md-none">Discounts</h1>

        <Breadcrumbs pathname={pathname} />

        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4 card-main flex-fill">
              {isLoading ? (
                <>
                  <DiscountLoader />
                </>
              ) : (
                <>
                  <div className="card-header  py-7550  align-items-center justify-content-between">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="font-book ">Discounts</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body card-table">
                    <div className="row">
                      <div className="col-md-4">
                        <form
                          onSubmit={this.handleSubmit}
                          className="product_add mr-0"
                        >
                          <div className="position-relative">
                            <InputTextField
                              id="product_number"
                              label={"Discount Percentage*"}
                              className={"form-control-product"}
                              name="discount"
                              value={discount}
                              onChange={this.handleChange}
                              disabled={!isEditMode}
                              errorMessage={
                                errors && errors.discount ? errors.discount : ""
                              }
                              maxLength={5}
                              placeholder={"Enter Discount Percent"}
                            />
                            <span className="numIcon">%</span>
                          </div>
                          <p>
                            Discount Percentage will be applicable on the annual
                            plans
                          </p>

                          <Button
                            className={isEditMode ? "d-none " : "submit-btn"}
                            onClick={() =>
                              this.setState({
                                isEditMode: true,
                              })
                            }
                          >
                            {discount ? (
                              <>
                                <img src={edit_icon} alt="" />
                                EDIT
                              </>
                            ) : (
                              "Add"
                            )}
                          </Button>

                          {isEditMode && (
                            <div className="footer-form text-left d-flex">
                              <Button
                                to={AppRoutes.PLANS}
                                className="btn btn-primary cancel-btn"
                                onClick={() =>
                                  this.setState({
                                    isEditMode: false,
                                  })
                                }
                              >
                                {" "}
                                CANCEL
                              </Button>
                              <SubmitButton
                                className={"submit-btn"}
                                text={discount ? "UPDATE" : "ADD"}
                                // text={"ADD"}
                                isLoading={isUpdating}
                              />
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Discounts;
