import React from "react";
import { Dropdown } from "react-bootstrap";
import { BatchViewLoader } from "../../loader/Resusable/BatchView";
import defaultImg from "../../../../assets/img/ph_product.jpg";
import fileMinusIcon from "../../../../assets/img/file-minus.svg";
import windowMinusIcon from "../../../../assets/img/full-minus.svg";
import { AppConfig } from "../../../../config";

export const BatchHistoryDetailComponent = ({
  isLoading,
  productDetails,
  isDeleting,
  onBulkDelete,
}) => {
  const {
    productName = "",
    productNumber = "",
    productImage = "",
    customProductName = "",
  } = productDetails || {};
  return (
    <div className="card mb-4 card-main flex-fill">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <>
          {" "}
          <div className="card-header py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book ">Batch History Details</h6>
              </div>

              <div className="col-md-9 filter-col-lg ">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className={"action-btn"}
                    disabled={isDeleting}
                  >
                    {isDeleting ? (
                      <>
                        <i className="fa fa-spinner fa-spin test-spin"></i>
                        &nbsp;Action
                      </>
                    ) : (
                      "Action"
                    )}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                    <Dropdown.Item onClick={onBulkDelete}>
                      {" "}
                      Delete Batch
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            <div className="row mtblf-5">
              <div className="col-md-4">
                <div className="row mtblf-5">
                  <div className="col-md-12 mtb-5">
                    <div className="heading-col">
                      <img src={fileMinusIcon} alt="" />
                      <h5>Main Product Name</h5>
                    </div>
                    <div className="description-col">
                      <h5>{productName || "-"}</h5>
                    </div>
                  </div>
                  <div className="col-md-12 mtb-5">
                    <div className="heading-col">
                      <img src={fileMinusIcon} alt="" />
                      <h5>Main Product Number</h5>
                    </div>
                    <div className="description-col">
                      <h5>{productNumber || ""}</h5>
                    </div>
                  </div>
                  <div className="col-md-12 mtb-5">
                    <div className="heading-col">
                      <img src={windowMinusIcon} alt="" />
                      <h5>Custom Product Name</h5>
                    </div>
                    <div className="description-col">
                      <h5>{customProductName || "-"}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <img
                  className="batch-cl-img"
                  // src={
                  //   productImage
                  //     ? `${AppConfig.FILES_ENDPOINT}${productImage}`
                  //     : defaultImg
                  // }
                  src={
                    productImage
                      ? productImage.startsWith("https://")
                        ? productImage
                        : `${AppConfig.FILES_ENDPOINT}${productImage}`
                      : defaultImg
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
