import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { AppConfig, customReactSelectStyles } from "../../../config";
import demoImg from "../../../assets/img/group-12.png";
import starIcon from "../../../assets/img/star.svg";
import globeIcon from "../../../assets/img/globe_small.svg";
import brandname from "../../../assets/img/brandname.svg";
import insideUSA from "../../../assets/img/insideUSA.svg";
import businessReg from "../../../assets/img/businessReg.svg";
import location from "../../../assets/img/location.svg";
import poseCode from "../../../assets/img/poseCode.svg";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import mailIcon from "../../../assets/img/mail-1.svg";
import downloadBlack from "../../../assets/img/downloadBlack.svg";
import Select from "react-select";
import { BatchViewLoader } from "../loader/Resusable/BatchView";
import { InputTextField } from "../forms";
import trashIcon from "../../../assets/img/trash-btn.svg";
import AsyncSelect from "react-select/async";
import { ApiHelper } from "../../../helpers";
import countryStateData from "../../../common/countryState.json";
import { components, DropdownIndicatorProps } from "react-select";

import { saveAs } from "file-saver";
import { areaCode } from "../../../common/phoneCode";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
};

const customStylesAsync = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "Poppins",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "Poppins",
  }),
  control: (provided, state) => ({
    ...provided,
    "&:hover": {
      borderColor: "unset",
      border: "0px",
    },
    border: "0px",
    padding: "0px",
    height: "100%",
    backgroundColor: "transparent",
    boxShadow: "unset",
    minWidth: "50px",
    // display: "block",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0px",
    backgroundColor: "transparent",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: "0px",
    maxWidth: "65px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
  }),
  container: (provided, state) => ({
    ...provided,
    padding: "0px",
    backgroundColor: "transparent",
  }),
  menu: (provided, state) => ({
    ...provided,
    minWidth: "100px",
  }),
};
export const StoreDetailComponent = ({
  storeData,
  isStoreEditable,
  isLoading,
  isUpdating,
  onEdit,
  onSubmit,
  onCancel,
  onChange,
  onSelectOption,
  statesOption,
  onSelect,
  onFileChange,
  onDeleteFile,
  uploadPercentage,
  isUploading,
  codeError,
  isCertificateUpdating,
  onDeleteImage,
  clientData,
  handleContactInfoChange,
  emailError,
}) => {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="d-none" />
      </components.DropdownIndicator>
    );
  };

  const loadStateData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = statesOption.filter(
        (state) =>
          state.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  const loadCountryData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  let codeData = [];
  areaCode.forEach((element) => {
    codeData.push({ label: element.dial_code, value: element.dial_code });
  });
  const loadAreaCodeData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = codeData.filter((code) =>
        code.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };
  let allCountry = [];
  countryStateData.forEach((element) => {
    if (
      element.name === "United States" ||
      element.name === "Canada" ||
      element.name === "Mexico" ||
      element.name === "The Bahamas"
    ) {
      if (storeData?.insideUSACanada === "Yes") {
        allCountry.push({
          label: element.name,
          value: element.name,
        });
      }
    } else {
      if (storeData?.insideUSACanada === "No") {
        allCountry.push({
          label: element.name,
          value: element.name,
        });
      }
    }
  });
  return (
    <div className="card mb-4 card-main flex-fill customEditFeilds">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <>
          <div className="card-header py-7550 align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="font-book">Company Details</h6>
                  {!isStoreEditable ? (
                    <Button
                      className="btn btn-secondary th-btn edit-tb action-block-btn  bg-white"
                      onClick={onEdit}
                    >
                      <>
                        <span>
                          <img src={editIcon} alt="" className={"img-main"} />
                          <img
                            src={editIconWhite}
                            alt=""
                            className={"img-hover"}
                          />
                        </span>
                        Edit
                      </>
                    </Button>
                  ) : (
                    <>
                      <div className="float-right">
                        <button
                          className="btn action-cl-btn"
                          onClick={onSubmit}
                          disabled={isUpdating}
                        >
                          {isUpdating ? (
                            <>
                              <i className="fa fa-spinner fa-spin"></i> Save
                            </>
                          ) : (
                            "Save"
                          )}
                        </button>
                        <button
                          className="btn btn-secondary can-btn-table cn-tp"
                          onClick={onCancel}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            <div className="row mtblf-5">
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={starIcon} alt="" />

                      <h5>Company Name</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <InputTextField
                            id="companyName"
                            className={"form-control-user"}
                            name="companyName"
                            value={storeData.companyName}
                            onChange={onChange}
                            maxLength={30}
                            errorMessage={
                              storeData.errors && storeData.errors.companyName
                                ? storeData.errors.companyName
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{storeData.companyName || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={globeIcon} alt="" />
                      <h5>Website</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <InputTextField
                            id="website"
                            className={"form-control-user"}
                            name="website"
                            value={storeData.website}
                            onChange={onChange}
                            maxLength={30}
                            errorMessage={
                              storeData.errors && storeData.errors.website
                                ? storeData.errors.website
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{storeData.website || "N/A"}</h5>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={globeIcon} alt="" />
                      <h5>Phone Number</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <div className="d-flex contact-holder">
                            <div className="col-md-3 p-0 before">
                              <AsyncSelect
                                cacheOptions
                                components={{
                                  IndicatorsContainer: () => null,
                                  DropdownIndicator,
                                  Input: (props) => (
                                    <components.Input
                                      {...props}
                                      maxLength={6}
                                    />
                                  ),
                                }}
                                // components={{ DropdownIndicator }}
                                loadOptions={loadAreaCodeData}
                                styles={customStylesAsync}
                                placeholder={"00"}
                                className="areaCode"
                                maxMenuHeight={200}
                                max={6}
                                width="200px"
                                defaultOptions={codeData}
                                value={{
                                  label: storeData.countryCode || "00",
                                  value: storeData.countryCode || "00",
                                }}
                                name="countryCode"
                                onChange={(selectedOption) => {
                                  onSelectOption(
                                    selectedOption,
                                    "countryCode",
                                    "storeData"
                                  );
                                }}
                                isSearchable={true}
                              />
                            </div>

                            <div className="col-md-10 p-0">
                              <InputTextField
                                id="website"
                                className={"form-control-user border-0 pl-3"}
                                name="phone"
                                value={storeData.phone}
                                onChange={onChange}
                                maxLength={12}
                              />
                            </div>
                          </div>
                          <div className="text-danger">
                            {storeData.errors && storeData.errors.phone
                              ? storeData.errors.phone
                              : ""}
                          </div>
                          {codeError && (
                            <div className="text-danger">
                              Please enter country code
                            </div>
                          )}
                        </div>
                      ) : (
                        <h5>
                          {storeData.countryCode || ""}{" "}
                          {storeData.phone || "N/A"}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={mailIcon} alt="" />
                      <h5>Email</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <InputTextField
                            id="website"
                            className={"form-control-user"}
                            name="email"
                            value={clientData.email}
                            onChange={handleContactInfoChange}
                            maxLength={30}
                            errorMessage={
                              clientData.errors && clientData.errors.email
                                ? clientData.errors.email
                                : ""
                            }
                          />
                          {emailError && (
                            <div className="text-danger">
                              Please enter email
                            </div>
                          )}
                        </div>
                      ) : (
                        <h5>{clientData.email || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={insideUSA} alt="" />
                      <h5>Inside USA/ Canada?</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <AsyncSelect
                            // loadOptions={loadCountryData}
                            placeholder={"Select"}
                            styles={customStyles}
                            maxMenuHeight={200}
                            defaultOptions={[
                              {
                                label: "Yes",
                                value: true,
                              },
                              {
                                label: "No",
                                value: false,
                              },
                            ]}
                            value={{
                              label: storeData.insideUSACanada,
                              value: storeData.insideUSACanada,
                            }}
                            onChange={(selectedOption) =>
                              onSelectOption(
                                selectedOption,
                                "insideUSACanada",
                                "storeData"
                              )
                            }
                            noOptionsMessage={() =>
                              "No option found related to your search"
                            }
                          />
                        </div>
                      ) : (
                        <h5>{storeData?.insideUSACanada || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={businessReg} alt="" />
                      <h5>Business Registration Number</h5>
                    </div>
                    <div className="description-col storeImages">
                      {isStoreEditable ? (
                        <div className="col-md-12 pl-0 pb-2">
                          <div className="uploadPicBox w-100">
                            <div
                              className={
                                isCertificateUpdating === "businessRegNo_image"
                                  ? "uploading chooseFile"
                                  : "chooseFile"
                              }
                            >
                              <button
                                disabled={
                                  isCertificateUpdating ===
                                  "businessRegNo_image"
                                }
                                style={{
                                  background: "transparent",
                                  border: "0",
                                }}
                              >
                                <input
                                  className="imgUpload img_up "
                                  name="businessRegNo_image"
                                  type="file"
                                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                                  onChange={onFileChange}
                                />
                                {isCertificateUpdating ===
                                "businessRegNo_image" ? (
                                  <>
                                    {" "}
                                    <i className="fa fa-spinner fa-spin"></i>{" "}
                                    Choose
                                  </>
                                ) : (
                                  "Choose"
                                )}
                              </button>
                            </div>
                            <InputTextField
                              id="website"
                              className={"form-control-user border-0"}
                              name="businessRegNo"
                              value={storeData?.businessRegNo}
                              onChange={onChange}
                              maxLength={20}
                            />
                            {storeData?.businessRegNo_image && (
                              <button
                                onClick={
                                  storeData?.businessRegNo_image
                                    ? () =>
                                        saveAs(
                                          `${storeData?.businessRegNo_image}`,
                                          "Business Registration Number"
                                        )
                                    : ""
                                }
                                className="btn p-0 d-flex align-items-center justify-content-center"
                                style={{ width: "25px", height: "25px" }}
                              >
                                <img src={downloadBlack} />
                              </button>
                            )}
                          </div>
                          <div className="text-danger">
                            {storeData?.errors?.businessRegNo}
                          </div>
                          {!storeData?.errors?.businessRegNo && (
                            <div className="text-danger">
                              {storeData?.errors?.businessRegNo_image}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <h5 className="d-flex ">
                            <span className="pr-3">
                              {storeData.businessRegNo || "N/A"}{" "}
                            </span>
                            {storeData?.businessRegNo_image && (
                              <span
                                onClick={
                                  storeData?.businessRegNo_image
                                    ? () =>
                                        saveAs(
                                          `${storeData?.businessRegNo_image}`,
                                          "Business Registration Number"
                                        )
                                    : ""
                                }
                                className="cursor-pointer"
                                style={{ width: "25px", height: "25px" }}
                              >
                                <img src={downloadBlack} />
                              </span>
                            )}
                          </h5>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={businessReg} alt="" />
                      <h5>VAT or EIN Number</h5>
                    </div>
                    <div className="description-col storeImages">
                      {isStoreEditable ? (
                        <div className="col-md-12 pl-0 pb-2">
                          <div className="uploadPicBox w-100">
                            <div
                              className={
                                isCertificateUpdating === "VATNumber_image"
                                  ? "uploading chooseFile"
                                  : "chooseFile"
                              }
                            >
                              <button
                                disabled={
                                  isCertificateUpdating === "VATNumber_image"
                                }
                                style={{
                                  background: "transparent",
                                  border: "0",
                                }}
                              >
                                <input
                                  className="imgUpload img_up"
                                  name="VATNumber_image"
                                  type="file"
                                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                                  onChange={onFileChange}
                                />
                                {isCertificateUpdating === "VATNumber_image" ? (
                                  <>
                                    {" "}
                                    <i className="fa fa-spinner fa-spin"></i>{" "}
                                    Choose
                                  </>
                                ) : (
                                  "Choose"
                                )}
                              </button>
                            </div>
                            <InputTextField
                              id="website"
                              className={"form-control-user border-0"}
                              name="VATNumber"
                              value={storeData?.VATNumber}
                              onChange={onChange}
                              maxLength={20}
                            />
                            {storeData?.VATNumber_image && (
                              <button
                                onClick={
                                  storeData?.VATNumber_image
                                    ? () =>
                                        saveAs(
                                          `${storeData?.VATNumber_image}`,
                                          "VAT Number"
                                        )
                                    : ""
                                }
                                className="btn p-0 d-flex align-items-center justify-content-center"
                                style={{ width: "25px", height: "25px" }}
                              >
                                <img src={downloadBlack} />
                              </button>
                            )}
                          </div>
                          <div className="text-danger">
                            {storeData?.errors?.VATNumber}
                          </div>
                          {!storeData?.errors?.VATNumber && (
                            <div className="text-danger">
                              {storeData?.errors?.VATNumber_image}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <h5 className="d-flex ">
                            <span className="pr-3">
                              {storeData?.VATNumber || "N/A"}{" "}
                            </span>
                            {storeData?.VATNumber_image && (
                              <span
                                onClick={
                                  storeData?.VATNumber_image
                                    ? () =>
                                        saveAs(
                                          `${storeData?.VATNumber_image}`,
                                          "VAT Number"
                                        )
                                    : ""
                                }
                                className="cursor-pointer"
                                style={{ width: "25px", height: "25px" }}
                              >
                                <img src={downloadBlack} />
                              </span>
                            )}
                          </h5>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={businessReg} alt="" />
                      <h5>Trade Mark Certification Number</h5>
                    </div>
                    <div className="description-col storeImages">
                      {isStoreEditable ? (
                        <div className="col-md-12 pl-0 pb-2">
                          <div className="uploadPicBox w-100">
                            <div
                              className={
                                isCertificateUpdating ===
                                "TradeMarkCertificateNo_image"
                                  ? "uploading chooseFile"
                                  : "chooseFile"
                              }
                            >
                              <button
                                disabled={
                                  isCertificateUpdating ===
                                  "TradeMarkCertificateNo_image"
                                }
                                style={{
                                  background: "transparent",
                                  border: "0",
                                }}
                              >
                                <input
                                  className="imgUpload img_up"
                                  name="TradeMarkCertificateNo_image"
                                  type="file"
                                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                                  onChange={onFileChange}
                                />
                                {isCertificateUpdating ===
                                "TradeMarkCertificateNo_image" ? (
                                  <>
                                    {" "}
                                    <i className="fa fa-spinner fa-spin"></i>{" "}
                                    Choose
                                  </>
                                ) : (
                                  "Choose"
                                )}
                              </button>
                            </div>
                            <InputTextField
                              id="website"
                              className={"form-control-user border-0"}
                              name="TradeMarkCertificateNo"
                              value={storeData?.TradeMarkCertificateNo}
                              onChange={onChange}
                              maxLength={20}
                            />
                            {storeData?.TradeMarkCertificateNo_image && (
                              <button
                                onClick={
                                  storeData?.TradeMarkCertificateNo_image
                                    ? () =>
                                        saveAs(
                                          `${storeData?.TradeMarkCertificateNo_image}`,
                                          "Trade Mark Certificate Number"
                                        )
                                    : ""
                                }
                                className="btn p-0 d-flex align-items-center justify-content-center"
                                style={{ width: "25px", height: "25px" }}
                              >
                                <img src={downloadBlack} />
                              </button>
                            )}
                          </div>
                          <div className="text-danger">
                            {storeData?.errors?.TradeMarkCertificateNo}
                          </div>
                          {!storeData?.errors?.TradeMarkCertificateNo && (
                            <div className="text-danger">
                              {storeData?.errors?.TradeMarkCertificateNo_image}
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <h5 className="d-flex ">
                            <span className="pr-3">
                              {storeData?.TradeMarkCertificateNo || "N/A"}{" "}
                            </span>
                            {storeData?.TradeMarkCertificateNo_image && (
                              <span
                                onClick={
                                  storeData?.TradeMarkCertificateNo_image
                                    ? () =>
                                        saveAs(
                                          `${storeData?.TradeMarkCertificateNo_image}`,
                                          "Trade Mark Certificate Number"
                                        )
                                    : ""
                                }
                                className="cursor-pointer"
                                style={{ width: "25px", height: "25px" }}
                              >
                                <img src={downloadBlack} />
                              </span>
                            )}
                          </h5>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={globeIcon} alt="" />
                      <h5>Country</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <AsyncSelect
                            loadOptions={loadCountryData}
                            placeholder={"Select"}
                            styles={customStyles}
                            maxMenuHeight={200}
                            defaultOptions={allCountry}
                            value={{
                              label: storeData.country,
                              value: storeData.country,
                            }}
                            onChange={(selectedOption) =>
                              onSelectOption(
                                selectedOption,
                                "country",
                                "storeData"
                              )
                            }
                            noOptionsMessage={() =>
                              "No option found related to your search"
                            }
                          />
                          <div className="text-danger">
                            {storeData.errors && storeData.errors.country
                              ? storeData.errors.country
                              : ""}
                          </div>
                        </div>
                      ) : (
                        <h5>{storeData?.country || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={location} alt="" />
                      <h5>State</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <AsyncSelect
                            loadOptions={loadStateData}
                            placeholder={"Select"}
                            styles={customStyles}
                            maxMenuHeight={200}
                            defaultOptions={statesOption}
                            value={{
                              label: storeData.state,
                              value: storeData.state,
                            }}
                            onChange={(selectedOption) =>
                              onSelectOption(
                                selectedOption,
                                "state",
                                "storeData"
                              )
                            }
                            noOptionsMessage={() => {
                              return storeData?.country
                                ? "No states found"
                                : "First select country";
                            }}
                          />
                          <div className="text-danger">
                            {storeData.errors && storeData.errors.state
                              ? storeData.errors.state
                              : ""}
                          </div>
                        </div>
                      ) : (
                        <h5>{storeData?.state || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={location} alt="" />
                      <h5>Address Line 1</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <InputTextField
                            id="companyName"
                            className={"form-control-user"}
                            name="address1"
                            value={storeData.address1}
                            onChange={onChange}
                            maxLength={30}
                            errorMessage={
                              storeData.errors && storeData.errors.address1
                                ? storeData.errors.address1
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{storeData.address1 || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={location} alt="" />
                      <h5>Address Line 2</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <InputTextField
                            id="companyName"
                            className={"form-control-user"}
                            name="address2"
                            value={storeData.address2}
                            onChange={onChange}
                            maxLength={30}
                            errorMessage={
                              storeData.errors && storeData.errors.address2
                                ? storeData.errors.address2
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{storeData.address2 || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={poseCode} alt="" />
                      <h5>Post Code</h5>
                    </div>
                    <div className="description-col">
                      {isStoreEditable ? (
                        <div className="col-md-8 pb-2 pl-0">
                          <InputTextField
                            id="store_post_code"
                            className={"form-control-user"}
                            name="postCode"
                            value={storeData.postCode}
                            onChange={onChange}
                            errorMessage={
                              storeData.errors && storeData.errors.postCode
                                ? storeData.errors.postCode
                                : ""
                            }
                          />
                        </div>
                      ) : (
                        <h5>{storeData.postCode || "N/A"}</h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="img_box">
                  <img
                    src={
                      storeData.storeLogo
                        ? storeData.storeLogo.startsWith("https://")
                          ? storeData.storeLogo
                          : `${AppConfig.FILES_ENDPOINT}${storeData.storeLogo}`
                        : demoImg
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = demoImg;
                    }}
                    alt=""
                  />
                  {isUploading ? (
                    <ProgressBar now={uploadPercentage} className={"mt-2"} />
                  ) : null}
                  {isStoreEditable ? (
                    <div className="file-upload-btn ml-0">
                      <label className="file-label">
                        {" "}
                        {storeData.storeLogo ? "Change Image" : "Choose Image"}
                        <input
                          className="imgUpload img_up"
                          name="storeLogo"
                          type="file"
                          accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                          onChange={onFileChange}
                        />
                      </label>
                      <button
                        disabled={!storeData.storeLogo}
                        onClick={() =>
                          onDeleteImage(storeData?.storeLogo, "storeLogo")
                        }
                        className={"cursor-pointer dlt-btn"}
                      >
                        <img src={trashIcon} alt="" />
                      </button>

                      <div className={"text-danger"}>
                        {storeData.errors && storeData.errors.storeLogo
                          ? storeData.errors.storeLogo
                          : ""}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
