import React from "react";
import Skeleton from "react-skeleton-loader";
import { CategoryCard } from "../Resusable/CategoryCard";

// import { DashboardWidgetLoader } from "../Resusable/DashboardWidget";

export const CategoryLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <p className='breadcrums-top'>
        <Skeleton color='#fff' />
      </p>
      <div className='card mb-4 card-main'>
        <div className='card-header py-75  align-items-center justify-content-between'>
          <div className='row'>
            <div className='col-md-3 head-cat-col-lg'>
              <h6 className='font-book '>
                <Skeleton color='#e5e9ef' height={"25px"} />
              </h6>
            </div>
            <div className='col-md-9 action-col-lg '>
              <div className='dp-right'>
                <Skeleton color='#e5e9ef' height={"25px"} />
              </div>
            </div>
          </div>
        </div>

        <div className='card-body card-table'>
          <div className='row'>
          <CategoryCard />
          </div>
        </div>
      </div>
    </div>
  </>
);
