/** @format */

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { AppConfig } from "../../../config";
import prevIcon from "../../../assets/img/slider-left.svg";
import nextIcon from "../../../assets/img/slider-right.svg";
import OrderByComponent from "../../components/products/OrderComponent";
import "./image-gallery.css";
import dummyImage from "../../../assets/img/noimage.jpeg";

export const ImageGallery = ({
  images,
  open,
  handleClose,
  markImageAsFeatured,
  isDeletingImage,
  deletingImageIndex,
  deleteImage,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handlePrevious = () => {
    setActiveIndex((prevIndex) => prevIndex - 1);
  };
  const handleNext = () => {
    setActiveIndex((prevIndex) => prevIndex + 1);
  };
  const onImageClick = (index) => {
    setActiveIndex(index);
  };
  useEffect(() => {
    if (images.length && activeIndex > images.length - 1) {
      setActiveIndex(images.length - 1);
    }
  }, [images]);
  return (
    <Modal
      show={open}
      onHide={handleClose}
      dialogClassName="modal-40w"
      restoreFocus={false}
      className={"product-image-gallery"}
    >
      <Modal.Body className="modal-full-body">
        <button className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div className={"text-center"}>
          {activeIndex > 0 ? (
            <span className={"cursor-pointer left-ar"} onClick={handlePrevious}>
              <img src={prevIcon} alt="prev" />
            </span>
          ) : null}
          <img
            // src={`${AppConfig.FILES_ENDPOINT}${images[activeIndex]}`}
            src={
              images[activeIndex]
                ? images[activeIndex].startsWith("https://")
                  ? images[activeIndex]
                  : `${AppConfig.FILES_ENDPOINT}${images[activeIndex]}`
                : dummyImage
            }
            alt=""
            className="image-modal-view"
          />
          {activeIndex < images.length - 1 ? (
            <span className={"cursor-pointer right-ar"} onClick={handleNext}>
              <img src={nextIcon} alt="next" />
            </span>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer className="center-item">
        {/* <div className={'gallery-footer'}> */}
        <OrderByComponent
          data={images}
          markImageAsFeatured={markImageAsFeatured}
          isDeletingImage={isDeletingImage}
          deletingImageIndex={deletingImageIndex}
          deleteImage={deleteImage}
          onImageClick={onImageClick}
          // data={[
          //   { label: 'dsfsd', value: '1' },
          //   { label: '4535', value: '2' },
          //   { label: 'opoipp', value: '3' },
          // ]}
        />
        {/* {images.map((item, index) => (
            <Fragment key={index}>
              <img
                src={`${AppConfig.FILES_ENDPOINT}${item}`}
                alt=''
                className={'mr-2 footer-img'}
                onClick={() => onImageClick(index)}
              />
              <span
                onClick={() => deleteImage(index)} // To exclude cover image
                className={'cursor-pointer gallery-trash'}
              >
                {isDeletingImage && deletingImageIndex === index ? (
                  <i className='fa fa-spinner fa-spin test-spin'></i>
                ) : (
                  <img src={trashGallery} alt='' />
                )}
              </span>
            </Fragment>
          ))} */}
        {/* </div> */}
      </Modal.Footer>
    </Modal>
  );
};
