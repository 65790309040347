/** @format */

import * as qs from "query-string";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Skeleton from "react-skeleton-loader";
import { Dropdown } from "react-bootstrap";
import { message } from "../../../common";
import {
  ApiRoutes,
  AppRoutes,
  youTubeVideoRegex,
  videoNameRegex,
} from "../../../config";
import Pagination from "../../components/pagination";
import { ApiHelper } from "../../../helpers";
import { videoValidator } from "../../../validators";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { ListVideoComponent } from "../../components/video";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import "./manage-video.css";

class ManageVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      id: "",
      name: "",
      link: "",
      // isPublished: false,
      categoryId: "",
      categoryName: "",
      errors: null,
      isLoading: false,
      isAdding: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      isFormOpen: false,
      categoryLoading: true,
    };
  }
  componentDidMount() {
    this.handleQueryParams();
    this.getCategoryDetails();
  }
  getCategoryDetails = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { data, isError } = await new ApiHelper().FetchFromServer(
      "/category",
      `/${id}`,
      "GET",
      true
    );
    if (!isError) {
      this.setState({
        categoryName: data.data.cat_name,
        categoryLoading: false,
      });
    }
  };
  handleQueryParams = () => {
    const {
      location: { search },
      match: {
        params: { id },
      },
    } = this.props;

    const query = qs.parse(search);
    if (!id) {
      this.props.history.push(AppRoutes.MANAGE_VIDEO_CATEGORY);
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        categoryId: id,
      },
      () => this.fetchVideos()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchVideos = async () => {
    const { currentPage, categoryId, limit } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      cat_id: categoryId,
    };
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIDEOS_LIST.service,
      ApiRoutes.VIDEOS_LIST.url,
      ApiRoutes.VIDEOS_LIST.method,
      ApiRoutes.VIDEOS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { videos, totalRecords },
      } = response.data;
      this.setState({
        videos,
        totalRecords,
      });
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };
  onAddBtnClick = () => {
    this.setState((prevState) => ({
      isFormOpen: !prevState.isFormOpen,
      errors: null,
      name: "",
      link: "",
    }));
  };
  handleChange = async (event) => {
    const {
      target: { name, value, id },
    } = event;
    if (name === "isPublished" && value === true) {
      const { value: demo } = await SweetAlertComponent({
        text: `Are you sure, you want to publish this video ?`,
      });
      console.log(demo);
      if (demo) {
        const isPublished = value;
        this.updateVideoStatus({ isPublished }, id);
      } else {
        return;
      }
    } else {
      const { value: demo } = await SweetAlertComponent({
        text: `Are you sure, you want to unpublish this video ?`,
      });
      console.log(demo);

      if (demo) {
        const isPublished = value;
        this.updateVideoStatus({ isPublished }, id);
      } else {
        return;
      }
    }
  };

  handleAdd = (event) => {
    const {
      target: { name, value, id },
    } = event;
    this.setState({
      [name]: value,
      errors: { ...this.state.errors, [name]: "" },
    });
  };
  addVideo = async (data) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_VIDEO.service,
      ApiRoutes.ADD_VIDEO.url,
      ApiRoutes.ADD_VIDEO.method,
      ApiRoutes.ADD_VIDEO.authenticate,
      undefined,
      data
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        this.setState({
          errors: response.validationErrors,
        });
      }
    } else {
      toast.success(response.messages[0]);
      this.setState({
        errors: null,
        name: "",
        link: "",
      });
      this.fetchVideos();
    }
  };

  updateVideo = async (data, id) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_VIDEO.service,
      ApiRoutes.UPDATE_VIDEO.url.replace(":id", id),
      ApiRoutes.UPDATE_VIDEO.method,
      ApiRoutes.UPDATE_VIDEO.authenticate,
      undefined,
      data
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        this.setState({
          errors: response.validationErrors,
        });
      }
    } else {
      toast.success(response.messages[0]);
      this.setState({
        errors: null,
        name: "",
        link: "",
        // isPublished: false,
        isFormOpen: false,
        id: "",
      });
      this.fetchVideos();
    }
  };

  updateVideoStatus = async (data, id) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_VIDEO_STATUS.service,
      ApiRoutes.UPDATE_VIDEO_STATUS.url.replace(":id", id),
      ApiRoutes.UPDATE_VIDEO_STATUS.method,
      ApiRoutes.UPDATE_VIDEO_STATUS.authenticate,
      undefined,
      data
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        this.setState({
          errors: response.validationErrors,
        });
      }
    } else {
      toast.success(response.messages[0]);
      this.setState({
        errors: null,
        name: "",
        link: "",
        // isPublished: !this.state.isPublished,
        isFormOpen: false,
        id: "",
      });
      this.fetchVideos();
    }
  };
  handleSubmit = () => {
    const { name, link, categoryId, id } = this.state;
    const data = { name, link, categoryId };
    let { isValid, errors } = videoValidator(data);
    // To validate youtube video link
    if (link && !youTubeVideoRegex.test(link.trim())) {
      isValid = false;
      errors = {
        ...errors,
        link: message.ValidLink,
      };
    }
    if (!videoNameRegex.test(name.trim())) {
      isValid = false;
      errors = {
        ...errors,
        name: message.InvalidVideoName,
      };
    }
    if (isValid) {
      if (id) {
        this.updateVideo({ name, link }, id);
      } else {
        this.addVideo({ name, link, cat_id: categoryId });
      }
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };
  onCancel = () => {
    this.setState({
      errors: null,
      name: "",
      link: "",
      isFormOpen: false,
    });
  };

  onEdit = async (data) => {
    console.log("onEdit", data);

    const { name = "", link = "", id = "" } = data || {};
    this.setState({ name, link, id, isFormOpen: true });
  };

  deleteVideo = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to delete this video ?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_VIDEO.service,
      ApiRoutes.DELETE_VIDEO.url.replace(":id", id),
      ApiRoutes.DELETE_VIDEO.method,
      ApiRoutes.DELETE_VIDEO.authenticate,
      undefined,
      undefined
    );
    this.setState({
      id: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchVideos();
      toast.success(response.messages[0]);
    }
  };

  customBreadCrumb = () => {
    const { categoryLoading, categoryName } = this.state;
    return categoryLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      categoryName
    );
  };
  render() {
    const {
      videos,
      totalRecords,
      currentPage,
      limit,
      name,
      link,
      isPublished,
      errors,
      categoryName,
      isFormOpen,
      isLoading,
      isAdding,
      categoryLoading,
    } = this.state;
    const {
      props: {
        location: { pathname, state },
      },
    } = this;

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Manage Video</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
          search={state ? state.search : ""}
        />

        <div className="card card-main card-pagination">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-cat-col-lg">
                <h6 className="font-book ">
                  {categoryLoading ? (
                    <Skeleton color="#e5e9ef" />
                  ) : (
                    categoryName
                  )}
                </h6>
              </div>
              <div className="col-md-9 action-col-lg ">
                <div className="dp-right">
                  <button
                    type="button"
                    className="btn btn-primary add-btn"
                    id="add-cat-btn"
                    onClick={this.onAddBtnClick}
                  >
                    {" "}
                    Add Video
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ListVideoComponent
            videos={videos}
            totalRecords={totalRecords}
            limit={limit}
            errors={errors}
            isLoading={isLoading}
            isAdding={isAdding}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            handleAdd={this.handleAdd}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onCancel={this.onCancel}
            name={name}
            link={link}
            isPublished={isPublished}
            isFormOpen={isFormOpen}
            deleteVideo={this.deleteVideo}
            onEdit={this.onEdit}
            updateVideoStatus={this.updateVideoStatus}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ManageVideo;
