export const SocketEvents = {
  NEW_USER_ADDED: 'newUserAdded',
  NEW_SCAN_ADDED: 'newscanAdded',
  NEW_COUNTERFEIT_ADDED: 'newCounterfeitAdded',
  NOTIFICATION: 'notification',
  COUNTERFEIT_STATISTICS_UPDATED: 'counterfeitStatisticsUpdated',
  MEMBER_STATISTICS_UPDATED: 'memberStatisticsUpdated',
  SCAN_STATISTICS_UPDATED: 'scanStatisticsUpdated',
  ACTIVE_LOCATIONS_UPDATED: 'activeLocationsUpdated',
};
