/** @format */

import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-skeleton-loader";
import { AppRoutes, ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import calenderIcon from "../../../assets/img/calender.svg";
import userIcon from "../../../assets/img/user-4.svg";
import filetickIcon from "../../../assets/img/file-sub.svg";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../config";
import { toast } from "react-toastify";
import CancelModal from "./CancelModal";
import { cancelValidate } from "../../../validators/cancelValidate";
import ResolutionModal from "./ResolutionModal";

export const AssigneeDetails = ({
  assigneeDetails,
  status,
  dateOfAction,
  conterfeitId,
  viewCounterfeitRequest,
  changeRequestRequest,
}) => {
  const { name, role, id } = assigneeDetails;
  const [loading, setLoading] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);
  const [cancelShow, setCancelShow] = useState(false);
  const [resolutionShow, setResolutionShow] = useState(false);
  const [errorData, setError] = useState({
    error: null,
  });
  const [cancelData, setCancelData] = useState();
  const [cancelDesc, setCancelDesc] = useState();

  const fetchActiveStaffList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF_OPTION_LIST.service,
      ApiRoutes.GET_STAFF_OPTION_LIST.url,
      ApiRoutes.GET_STAFF_OPTION_LIST.method,
      ApiRoutes.GET_STAFF_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      setStaffOptions(data);
    }
  };
  const changeAssignee = async (newAssignee) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ.service,
      ApiRoutes.CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ.url.replace(
        ":id",
        conterfeitId
      ),
      ApiRoutes.CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ.method,
      ApiRoutes.CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ.authenticate,
      undefined,
      { oldAssignee: id, newAssignee }
    );
    if (response && response.isError) {
    } else {
      toast.success(response.messages[0]);
      await viewCounterfeitRequest(false);
      setLoading(false);
    }
  };
  const unAssign = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNASSIGN_STAFF_FROM_REQ.service,
      ApiRoutes.UNASSIGN_STAFF_FROM_REQ.url.replace(":id", conterfeitId),
      ApiRoutes.UNASSIGN_STAFF_FROM_REQ.method,
      ApiRoutes.UNASSIGN_STAFF_FROM_REQ.authenticate,
      undefined,
      { assigneeId: id }
    );
    if (response && response.isError) {
    } else {
      toast.success(response.messages[0]);
      await viewCounterfeitRequest(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (role === "S") {
      fetchActiveStaffList();
    }
  }, [role]);

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setCancelData(value.trimLeft());
  };

  const handleDescChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setCancelDesc({ ...cancelDesc, [name]: value.trimLeft() });
  };

  const handleCancelSubmit = (event) => {
    event && event.preventDefault();
    let data = {
      status: "Cancelled",
      cancellationReason: cancelData,
    };
    if (data.cancellationReason === "Other") {
      data = {
        status: "Cancelled",
        cancellationReason: cancelData || "",
        statusDescription: cancelDesc?.resolution || "",
      };
    }

    let { isValid, errors } = cancelValidate(data);
    if (isValid) {
      changeCancelRequest(data);
    } else {
      console.log("errors", errors);
      setError({
        error: errors,
      });
    }
  };

  const handleCompleteSubmit = (event) => {
    event && event.preventDefault();
    let data = {
      status: "Completed",
      statusDescription: cancelDesc?.resolution || "",
    };

    let { isValid, errors } = cancelValidate(data);
    if (isValid) {
      changeCancelRequest(data);
    } else {
      console.log("errors", errors);
      setError({
        error: errors,
      });
    }
  };

  const changeCancelRequest = async (data) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.service,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.url.replace(
        ":id",
        conterfeitId
      ),
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.method,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.authenticate,
      undefined,
      data
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      viewCounterfeitRequest();
    }
  };

  return (
    <>
      <div className="col-md-12 d-flex">
        <div className="card dropdownCard  mb-4 card-main flex-fill">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book ">
                  {role === "S" ? "Assignee Details" : "Client Details"}
                </h6>
              </div>

              <div className="col-md-9  filter-col-lg">
                {role === "S" ? (
                  <>
                    <div className="assigneRow">
                      <Link
                        className="btn btn-primary sidemenu-btn"
                        to={AppRoutes.VIEW_STAFF.replace(":id", id)}
                      >
                        {" "}
                        View Staff
                      </Link>
                      <button
                        className="btn btn-primary sidemenu-btn"
                        onClick={unAssign}
                      >
                        {" "}
                        Un-assign
                      </button>
                      <Dropdown className="no-arrow">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                          className={"filter-btn color-primary"}
                        >
                          Change Assignee
                          <img src={filterarrowIcon} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="shadow dropdown-menu-right dropdown-staff"
                          style={{ padding: "2px 0" }}
                        >
                          {staffOptions.map(({ label, value }, index) => (
                            <Dropdown.Item
                              as="div"
                              key={index}
                              onClick={() => changeAssignee(value)}
                            >
                              {label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>

                      <Dropdown className="no-arrow">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                          className={"filter-btn color-primary"}
                        >
                          Update Ticket
                          <img src={filterarrowIcon} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          className="shadow dropdown-menu-right dropdown-staff"
                          style={{ padding: "2px 0" }}
                        >
                          <Dropdown.Item
                            as="div"
                            onClick={() => changeRequestRequest("In Review")}
                          >
                            In Review
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="div"
                            onClick={() => setResolutionShow(true)}
                          >
                            Completed
                          </Dropdown.Item>

                          <Dropdown.Item
                            as="div"
                            onClick={() => changeRequestRequest("Reopened")}
                          >
                            Re-open
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="div"
                            onClick={() => setCancelShow(true)}
                          >
                            Cancel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                ) : (
                  id && (
                    <div className="side-col dp-right">
                      <Link
                        className="btn btn-primary sidemenu-btn"
                        to={AppRoutes.VIEW_CLIENT.replace(":id", id)}
                      >
                        {" "}
                        View Client
                      </Link>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            {loading ? (
              <div className="row mtblf-5">
                <div className="col dv-5 skeleton-bottom">
                  <Skeleton color="#e5e9ef" height={"15px"} width={"60%"} />
                  <Skeleton color="#e5e9ef" height={"35px"} width={"100%"} />
                </div>
                <div className="col dv-5 skeleton-bottom">
                  <Skeleton color="#e5e9ef" height={"15px"} width={"60%"} />
                  <Skeleton color="#e5e9ef" height={"35px"} width={"100%"} />
                </div>
                <div className="col dv-5 skeleton-bottom">
                  <Skeleton color="#e5e9ef" height={"15px"} width={"60%"} />
                  <Skeleton color="#e5e9ef" height={"35px"} width={"100%"} />
                </div>
              </div>
            ) : (
              <div className="row mtblf-5">
                <div className="col dv-5 ">
                  <div className="heading-col">
                    <img src={userIcon} alt="" />
                    <h5>User Name</h5>
                  </div>
                  <div className="description-col">
                    <h5>{name || "-"}</h5>
                  </div>
                </div>
                <div className="col dv-5 ">
                  <div className="heading-col">
                    <img src={calenderIcon} alt="" />
                    <h5>Date of Action</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {dateOfAction
                        ? moment(dateOfAction).format(DEFAULT_DATE_FORMAT)
                        : "-"}
                    </h5>
                  </div>
                </div>
                <div className="col dv-5 ">
                  <div className="heading-col">
                    <img src={filetickIcon} alt="" />
                    <h5>Request Status</h5>
                  </div>
                  <div className="description-col">
                    <h5>{status}</h5>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CancelModal
        cancelShow={cancelShow}
        cancelClose={() => setCancelShow(false)}
        handleChange={handleChange}
        handleDescChange={handleDescChange}
        handleCancelSubmit={handleCancelSubmit}
        loading={loading}
        cancelDesc={cancelDesc}
        error={errorData}
      />
      <ResolutionModal
        loading={loading}
        error={errorData}
        cancelDesc={cancelDesc}
        resolutionShow={resolutionShow}
        handleDescChange={handleDescChange}
        resolutionclose={() => setResolutionShow(false)}
        handleCompleteSubmit={handleCompleteSubmit}
      />
    </>
  );
};
