import Validator from "js-object-validation";
import { message } from "../common";

export const contactValidator = (data) => {
  const validations = {
    email: {
      required: true,
      email: true,
    },
    phone: {
      required: true,
    },
    address: {
      required: true,
    },
    whatsappLink: {
      required: true,
    },
    telegramLink: {
      required: true,
    },
    facebook: {
      required: true,
    },
    linkedIn: {
      required: true,
    },
    twitter: {
      required: true,
    },
    instagram: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    email: {
      required: message.Required.replace(":item", "Email"),
      email: message.InvalidEmail,
    },
    phone: {
      required: message.Required.replace(":item", "Phone"),
    },
    address: {
      required: message.Required.replace(":item", "Address"),
    },
    whatsappLink: {
      required: message.Required.replace(":item", "Whatsapp link"),
    },
    telegramLink: {
      required: message.Required.replace(":item", "Telegram link"),
    },
    facebook: {
      required: message.Required.replace(":item", "Facebook"),
    },
    linkedIn: {
      required: message.Required.replace(":item", "Linkdin"),
    },
    twitter: {
      required: message.Required.replace(":item", "Twitter"),
    },
    instagram: {
      required: message.Required.replace(":item", "Instagram"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
