import React from "react";
import moment from "moment";
import {
  AppConfig,
  DEFAULT_DATE_FORMAT,
  DB_ACCPTABLE_FORMAT,
} from "../../../config";
import dummyclient from "../../../assets/img/noimage.jpeg";
import phoneIcon from "../../../assets/img/phone.svg";
import emailIcon from "../../../assets/img/mail-1.svg";
// import minusCircleIcon from '../../../assets/img/minus-circle.svg';
import calenderIcon from "../../../assets/img/calender.svg";
import newTagIcon from "../../../assets/img/new-tag.svg";
import listIcon from "../../../assets/img/list.svg";

export const GridStaffComponent = ({ staff, viewHandler }) => {
  return staff.map((staffData) => {
    const {
      first_name = "",
      last_name = "",
      employee_id = "",
      email = "",
      phone = "",
      birth_date = "",
      joining_date = "",
      profile_picture = "",
      _id = "",
    } = staffData;
    let dateDifference = moment(new Date(), DB_ACCPTABLE_FORMAT).diff(
      moment(joining_date, DB_ACCPTABLE_FORMAT),
      "days"
    );

    return (
      <div className="col-md-3 col-vdsf col-sf" key={_id}>
        {dateDifference < 15 ? (
          <div className="topright">
            <img src={newTagIcon} alt="" />
          </div>
        ) : null}
        <div className="staff_head">
          <div className="row">
            <div className="col-12 pd-col center">
              {profile_picture ? (
                <img
                  className="grid-view-img-staff"
                  src={
                    profile_picture
                      ? profile_picture.startsWith("https://")
                        ? profile_picture
                        : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                      : ""
                  }
                  alt=""
                />
              ) : (
                <div className="img_placeholder">
                  {first_name ? first_name.substring(0, 1) : ""}
                  {last_name ? last_name.substring(0, 1) : ""}
                </div>
              )}
            </div>
            <div className="col-12 pd-col center">
              <h4 className="sf_name">
                {" "}
                {[first_name, last_name].filter(Boolean).join(" ") || "-"}
              </h4>
            </div>

            <div className="col-12 pd-col center mtb-2">
              <h4 className="sf_desc">{employee_id || "-"}</h4>
            </div>
            <div className="col-12 pd-col-2 mtb-1">
              <div className="row">
                <div className="col-2 tp-4">
                  <img src={emailIcon} alt="" />
                </div>
                <div className="col-10">
                  <h4 className="sf_details ">
                    {email ? (
                      <a className="mailto" href={`mailto:${email}`}>
                        {email}
                      </a>
                    ) : (
                      "-"
                    )}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 pd-col-2  mtb-1">
              <div className="row">
                <div className="col-2 tp-4">
                  <img src={phoneIcon} alt="" />
                </div>
                <div
                  className="col-10"
                  onClick={() => window.open(`tel:${phone}`)}
                >
                  <h4 className="sf_details">{phone || "-"}</h4>
                </div>
              </div>
            </div>
            <div className="col-12 pd-col-2  mtb-1">
              <div className="row">
                <div className="col-2 tp-4">
                  <img src={calenderIcon} alt="" />
                </div>
                <div className="col-10">
                  <h4 className="sf_details">
                    {birth_date
                      ? moment(joining_date).format(DEFAULT_DATE_FORMAT)
                      : "-"}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 pd-col-sm">
              <button
                onClick={() => viewHandler(_id)}
                className="btn cl-btn-md"
              >
                {" "}
                <img src={listIcon} alt="" /> View{" "}
              </button>

              <button
                className="btn cl-btn-md"
                disabled={!phone}
                onClick={() => window.open(`tel:${phone}`)}
              >
                {" "}
                <img src={phoneIcon} alt="" /> Contact{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  });
};
