import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { SubmitButton } from "../../components/forms";
//import { userValidator } from '../../../validators';
import "./manage-privacy.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";

class ManagePrivacyClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      loading: false,
      errors: null,
    };
  }

  componentDidMount = () => {
    this.fetchDetails("privacy");
  };

  fetchDetails = async (key) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SETTINGS.service,
      ApiRoutes.GET_SETTINGS.url.replace(":id", "content_" + key),
      ApiRoutes.GET_SETTINGS.method,
      ApiRoutes.GET_SETTINGS.authenticate,
      undefined,
      undefined
    );

    if (response && response.isError) {
    } else {
      if (response.data.data.product.length) {
        this.handleChange(window.atob(response.data.data.product[0].value));
      }
    }
  };

  saveDetails = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SAVE_SETTINGS.service,
      ApiRoutes.SAVE_SETTINGS.url,
      ApiRoutes.SAVE_SETTINGS.method,
      ApiRoutes.SAVE_SETTINGS.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
    }
  };

  handleChange = (value) => {
    this.setState({
      content: value,
      errors: "",
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.state.content === "" || this.state.content === "<p><br></p>") {
      this.setState({
        errors: "Privacy Policy can't be empty",
      });
      return;
    }
    this.setState({
      loading: true,
    });
    await this.saveDetails({
      key: "content_privacy",
      value: window.btoa(this.state.content),
    });

    this.setState({
      loading: false,
    });

    toast.success("Privacy saved Successfully.");
  };

  render() {
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2 textareaPrivacyTerms">
        <h1 className="h3-head-mob d-md-none">Privacy Policy</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card mb-4 card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book ">
                  Please enter the Privacy Policy applicable to your site below
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            <form className="" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <ReactQuill
                    value={this.state.content}
                    onChange={this.handleChange}
                    placeholder="Type something here..."
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ align: [] }],
                        ["link", "image"],
                        ["clean"],
                        [{ color: [] }],
                      ],
                    }}
                  />
                  <div className={"text-danger"}>{this.state.errors || ""}</div>
                </div>
              </div>
              <div className="col-md-12 footer-form">
                <button
                  className="btn btn-primary cancel-btn"
                  // onClick={handleAddCancel}
                  style={{ marginRight: "30px" }}
                >
                  {" "}
                  CANCEL
                </button>
                <SubmitButton
                  className={"submit-btn mr-0"}
                  text="ADD"
                  isLoading={this.state.loading}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ManagePrivacyClient;
