import React from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { AppRoutes } from "../config";
import Login from "../view/containers/login";
import { QRCodeComponent } from "../view/containers/manage-batches/QRCode";
import PrivateRoute from "./PrivateRouter";
import ClientInvitation from "../view/containers/manage-clients/Invitation";
import StaffInvitation from "../view/containers/manage-staff/Invitation";
import Terms from "../view/containers/terms";
import PrivacyPolicy from "../view/containers/privacy_policy";
import UserDataDeletion from "../view/containers/user_data_deletion";

const AppRouter = () => {
  const { pathname } = useLocation();
  return (
    <div id={pathname === AppRoutes.LOGIN ? "" : "wrapper"}>
      <Switch>
        <Route exact path={AppRoutes.LOGIN} component={Login} />
        <Route
          exact
          path={AppRoutes.MANAGE_CLIENTS_INVITATION}
          component={ClientInvitation}
        />
        <Route
          exact
          path={AppRoutes.MANAGE_STAFF_INVITATION}
          component={StaffInvitation}
        />
        <Route exact path={AppRoutes.TERMS} component={Terms} />
        <Route
          exact
          path={AppRoutes.PRIVACY_POLICY}
          component={PrivacyPolicy}
        />
        <Route
          exact
          path={AppRoutes.USER_DATA_DELETION}
          component={UserDataDeletion}
        />
        <Route exact path={AppRoutes.PRINT_BATCH} component={QRCodeComponent} />
        <Route path={AppRoutes.HOME} component={PrivateRoute} />
      </Switch>
    </div>
  );
};

export default AppRouter;
