import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FilterComponent } from "../../components/filters";
import { SearchFilter } from "../../components/filters/searchFilter";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import debounce from "lodash.debounce";
import * as qs from "query-string";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { TransactionsListComponent } from "../../components/TransactionsListComponent/TransactionsListComponent";
import { ClientDetails } from "../../components/transactionsView/ClientDetails";
import { TransactionDetails } from "../../components/transactionsView/TransactionDetails";
import { SubscriptionDetails } from "../../components/transactionsView/SubscriptionDetails";
import { Button } from "react-bootstrap";
import { PlanDetail } from "../../components/transactionsView/PlanDetail";

class TransactionView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailId: "",
      subscriptionDetails: "",
      transactionDetail: "",
      clientDetails: "",
      invoiceId: "",
      isUpdating: "",
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ detailId: id }, () => {
        this.getTransactionDetail();
      });
    }
  };

  // checkInvoice = () => {
  //   const { detailId } = this.state;
  //   console.log(detailId);

  //   this.props.history.push(AppRoutes.INVOICE.replace(":id", detailId));
  // };

  getInvoice = async (id) => {
    this.setState({
      isUpdating: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_BILLING_INVOICE.service,
      ApiRoutes.GET_BILLING_INVOICE.url.replace(":id", id),
      ApiRoutes.GET_BILLING_INVOICE.method,
      ApiRoutes.GET_BILLING_INVOICE.authenticate,
      undefined
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      toast.success(response.messages[0]);
      window.location.href = data;
    }
  };

  getTransactionDetail = async () => {
    const { detailId } = this.state;

    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TRANSACTION_BY_ID.service,
      ApiRoutes.GET_TRANSACTION_BY_ID.url.replace(":id", detailId),
      ApiRoutes.GET_TRANSACTION_BY_ID.method,
      ApiRoutes.GET_TRANSACTION_BY_ID.authenticate,
      // data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    console.log(response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const [planData] = response.data.data;
      this.setState({
        transactionDetail: planData,
        invoiceId: planData.invoiceId,
        subscriptionDetails: planData.subscriptionDetails,
        clientDetails: planData.clientDetails,
      });
    }
  };
  render() {
    const {
      transactionDetail,
      subscriptionDetails,
      clientDetails,
      isLoading,
      invoiceId,
      isUpdating,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <>
        <div className="container-fluid fluid-pd-2 transactionViewCard">
          <h1 className="h3-head-mob d-md-none">Plan Listing</h1>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginBottom: "10px" }}
          >
            <Breadcrumbs pathname={pathname} />
            <Button
              onClick={() => this.getInvoice(invoiceId)}
              className="btn btn-primary add-btn mr-0 downloadInvoice"
            >
              {isUpdating ? (
                <>
                  <i className="fa fa-spinner fa-spin test-spin"></i> Invoice
                </>
              ) : (
                <>Invoice</>
              )}
            </Button>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ClientDetails
                clientDetails={clientDetails}
                isLoading={isLoading}
              />
            </div>
            <div className="col-md-12">
              <TransactionDetails
                transactionDetail={transactionDetail}
                isLoading={isLoading}
              />
            </div>
            <div className="col-md-12">
              <SubscriptionDetails
                subscriptionDetails={subscriptionDetails}
                isLoading={isLoading}
              />
            </div>
            <div className="col-md-12">
              <PlanDetail
                subscriptionDetails={subscriptionDetails}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TransactionView;
