import React from "react";
import { PushRow } from "./PushRow";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";

export const ListComponent = ({
  pushs,
  isFilterApplied,
  isLoading,
  setFilter,
  sortBy,
  order,
  onEdit,
  onDelete,
  viewUser,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th>
                  Title{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "title" ? "active-ac" : ""
                      }`}
                      onClick={() => setFilter("title", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "title"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("title", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>{" "}
                </th>
                <th>
                  Subject{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "subject"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("subject", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "subject"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("subject", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Send To
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "sendTo"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("sendTo", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "sendTo"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("sendTo", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Date Scheduled
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "dateScheduled"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("dateScheduled", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "dateScheduled"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("dateScheduled", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {pushs && pushs.length ? (
                pushs.map((push, index) => (
                  <PushRow
                    key={index}
                    pushData={push}
                    onEdit={() => onEdit(push)}
                    onDelete={() => onDelete(push.id)}
                    viewUser={() => viewUser(push)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={5} className={"text-center image-no-data-xs"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
