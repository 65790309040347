import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { NavConfig } from "./NavConfig";
import { AppRoutes, MobileWidth } from "../../../config";
import AppsActive from "../../../assets/img/apps-active.svg";
import whiteImg from "../../../assets/img/white.png";
import white2xImg from "../../../assets/img/white@2x.png";
import white3xImg from "../../../assets/img/white@3x.png";
import downArrowIcon from "../../../assets/img/Down.svg";
import upArrowIcon from "../../../assets/img/Up.svg";
import subChild from "../../../assets/img/subChild.svg";
import subChildHide from "../../../assets/img/subChildHide.svg";

import "./nav.css";

const Nav = ({ sidebarOpen }) => {
  let history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const [open, setOpen] = useState("");
  const [subOpen, setSubOpen] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  // handle window resizing
  const handleResizing = () => {
    if (window.innerWidth < MobileWidth) {
      document.body.classList.remove("sidebar-toggled");
      setIsMobile(true);
    } else {
      setIsMobile(false);
      document.body.classList.remove("sidebar-toggled");
    }
  };
  useEffect(() => {
    handleResizing();
    window.addEventListener("resize", handleResizing);
    return () => window.removeEventListener("resize", () => null);
  }, []);

  useEffect(() => {
    NavConfig.filter((nav) => nav.childrens && nav.childrens.length).map(
      (element, index) => {
        const initialPath = `/${pathname.split("/")[2]}`;

        const submenu = element.childrens?.find(
          (sub) => sub.path.search(initialPath) > 0
        );
        const isSubMenuActive = submenu?.tabName;
        if (isSubMenuActive) {
          setOpen(isSubMenuActive);
        }
        return true;
      }
    );
  }, [pathname]);

  useEffect(() => {
    NavConfig.filter((nav) => nav?.childrens?.subChildren?.length).map(
      (element, index) => {
        const initialSubPath = `/${pathname.split("/")[3]}`;

        const subChildMenu = element.childrens?.find(
          (sub) => sub.path.search(initialSubPath) > 0
        );
        const isSubChildMenuActive = subChildMenu?.tabName;
        if (isSubChildMenuActive) {
          setSubOpen(isSubChildMenuActive);
        }
        return true;
      }
    );
  }, [pathname]);

  return (
    <ul
      className={`navbar-nav bg-solid-primary sidebar sidebar-dark accordion ${
        isMobile ? "toggled" : ""
      }`}
      id="accordionSidebar"
    >
      <Link
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to={AppRoutes.DASHBOARD}
      >
        <div className="sidebar-brand-text mx-3">
          <img
            src={whiteImg}
            srcSet={`${white2xImg} 2x,
             ${white3xImg} 3x`}
            className="White"
            alt=""
          />
        </div>
      </Link>
      {NavConfig.map((element, index) => {
        const initialPath = `/${pathname.split("/")[2]}`;
        const submenu = element.childrens?.findIndex(
          (sub) => sub.path.search(initialPath) > 0
        );
        const isSubMenuActive = submenu?.tabName;

        const initialMenu = `/${pathname.split("/")[1]}`;

        const initialSubPath = `/${pathname.split("/")[3]}`;
        const subChildMenu = element?.childrens?.subChildren?.findIndex(
          (subChild) => subChild.path.search(initialSubPath) > 0
        );
        const isSubChildMenuActive = subChildMenu?.tabName;

        const initialChildMenu = `/${pathname.split("/")[1]}`;

        return (
          <li
            className={`nav-item ${
              pathname === element.path ||
              (element.path &&
                element.path.search(`/${pathname.split("/")[1]}`) >= 0 &&
                !submenu &&
                // initialPath === "/undefined" &&
                !isSubMenuActive)
                ? "active "
                : ""
            }`}
            // className={`nav-item ${
            //   pathname === element.path ||
            //   (element.path && element.path === pathname.split("/")[1]) ||
            //   (element.path &&
            //     element.path.split("/")[1] === pathname.split("/")[1])
            //     ? "active active-show"
            //     : ""
            // }`}
            key={index}
          >
            {element.childrens && element.childrens.length ? (
              <>
                <span
                  className={`nav-link  nav-collapse-link sub-link cursor-pointer ${
                    isSubMenuActive == "" ? "collapsed" : ""
                  }`}
                  id={`collapse${index}`}
                  onClick={() => {
                    setOpen(open !== element.tabName ? element.tabName : "");
                  }}
                >
                  <img
                    src={element.imgUrl || AppsActive}
                    className="Group"
                    alt=""
                  />
                  <span>{element.name}</span>
                  <img
                    className="arr-icon-side"
                    src={open === element.tabName ? upArrowIcon : downArrowIcon}
                    alt=""
                  />
                </span>
                <Collapse in={open == element.tabName}>
                  <div>
                    <div className="py-1 collapse-inner">
                      {element.childrens.map((subMenu, subIndex) => {
                        let myPath = undefined;
                        if (initialPath === "/Web") {
                          myPath = initialSubPath;
                        } else {
                          myPath = initialPath;
                        }
                        return subMenu.subChildren &&
                          subMenu.subChildren.length ? (
                          <>
                            <span
                              id={`collapse${subIndex}`}
                              className={`collapse-item subchild cursor-pointer`}
                              onClick={() => {
                                setSubOpen(
                                  subOpen !== subMenu.name ? subMenu.name : ""
                                );
                              }}
                              key={subIndex}
                              to={subMenu.path}
                            >
                              <span className="sub-li">{subMenu.name}</span>
                              <img
                                src={
                                  subOpen === subMenu.name
                                    ? subChildHide
                                    : subChild
                                }
                                alt=""
                              />
                            </span>

                            <Collapse in={subOpen == subMenu.name}>
                              <div id={`collapse${subIndex}`}>
                                <div className="py-1 collapse-inner ">
                                  {subMenu.subChildren.map(
                                    (subChildElement, childIndex) => (
                                      <Link
                                        className={`collapse-item ${
                                          subChildElement.path.search(
                                            initialSubPath
                                          ) >= 0
                                            ? "active"
                                            : ""
                                        }`}
                                        key={childIndex}
                                        to={subChildElement.path}
                                      >
                                        <span className="sub-li">
                                          {subChildElement.name}
                                        </span>
                                      </Link>
                                    )
                                  )}
                                </div>
                              </div>
                            </Collapse>
                          </>
                        ) : (
                          <Link
                            className={`collapse-item  ${
                              element &&
                              element.path &&
                              element.path.search(initialMenu) >= 0 &&
                              initialPath === "/undefined" &&
                              subIndex === 0
                                ? "active"
                                : ""
                            }  ${
                              subMenu.path.search(myPath) >= 0 ? "active" : ""
                            }`}
                            key={subIndex}
                            to={subMenu.path}
                          >
                            <span className="sub-li">{subMenu.name}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </Collapse>
              </>
            ) : (
              <Link className="nav-link collapsed sub-link" to={element.path}>
                <img
                  src={element.imgUrl || AppsActive}
                  className="apps"
                  alt=""
                />
                <span>{element.name}</span>
              </Link>
            )}
          </li>
        );
      })}
      <div className="text-center d-none d-md-inline">
        <button
          className="rounded-circle border-0"
          id="sidebarToggle"
          style={{ display: "none" }}
        ></button>
      </div>
    </ul>
  );
};
export default Nav;
