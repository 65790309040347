/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import Pagination from "../../components/pagination";
import { ListComponent } from "../../components/scan-rules";
import PHLContext from "../../../PHLContext";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import pageDropdownIcon from "../../../assets/img/tag-bd.svg";
import mobSearchIcon from "../../../assets/img/tag-search-bd.svg";
import addrules from "../../../assets/img/addrules.svg";
import "./scanrules.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SweetAlertComponent from "../../components/sweetAlertComponent";

let lat, long;

class ScanRules extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      scans: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      sortBy: "created_at",
      order: "desc",
    };
  }
  componentDidMount = () => {
    this.handleQueryParams();
  };

  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    console.log("query", query);
    if (query && query.sort === "location") {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      lat = position.coords.latitude;
      long = position.coords.longitude;
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "created_at",
        order: query.order
          ? query.order
          : // : query.sort === 'scan_id' || query.sort === 'created_at'
            // ? 'desc'
            "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
        clientId: query.clientId || "PHL",
      },
      () => {
        this.fetchScanRecords();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchScanRecords = async () => {
    const { currentPage, sortBy, search, limit, newRecord, order, clientId } =
      this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
      lat,
      long,
      clientId,
    };
    this.setState({
      isLoading: newRecord ? false : true,
    });
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SCAN_RULES_LIST.service,
      ApiRoutes.SCAN_RULES_LIST.url,
      ApiRoutes.SCAN_RULES_LIST.method,
      ApiRoutes.SCAN_RULES_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { rules, totalRecords },
      } = response.data;
      this.setState({ scans: rules, totalRecords, newRecord: false });
    }
  };

  onDelete = async (id, name, disabled = false) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to delete the ${name} Scan Rule ?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SCAN_RULE.service,
      ApiRoutes.DELETE_SCAN_RULE.url.replace(":id", id),
      ApiRoutes.DELETE_SCAN_RULE.method,
      ApiRoutes.DELETE_SCAN_RULE.authenticate,
      undefined,
      undefined
    );
    this.setState({
      id: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchScanRecords();
      toast.success(response.messages[0]);
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  setClientFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };
  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  batchViewHandler = (id) => {
    this.props.history.push(AppRoutes.VIEW_BATCH.replace(":id", id));
  };
  userViewHandler = (id) => {
    this.props.history.push(AppRoutes.VIEW_USER.replace(":id", id));
  };

  render() {
    const {
      scans,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      order,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filtersOption = [
      {
        label: "Rule Name",
        value: "name",
      },
      {
        label: "Number of Users",
        value: "noOfUsers",
      },
      {
        label: "Alert Type",
        value: "alertType",
      },
      {
        label: "Give Alert to",
        value: "sendAlert",
      },
      {
        label: "Country",
        value: "country",
      },
      // {
      //   label: "Actions",
      //   value: "action",
      // },
    ];

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Scan Rules</h1>
        <Breadcrumbs pathname={pathname} />

        <div className="card card-pagination card-main">
          <div className="card-header py-7550 align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-6 d-flex">
                <h6 className="font-book ">Scan Rules</h6>
                <div className="d-flex align-items-center scan_rules_filter">
                  <Link to={AppRoutes.SCAN_RULES} className="button active">
                    {" "}
                    {/* <img
                    src={addrules}
                    style={{ marginTop: "-2px", marginRight: "4px" }}
                  /> */}
                    Set Rules
                  </Link>
                  <Link to={AppRoutes.COUNTERFEIT_ID} className="button">
                    {" "}
                    Counterfeit ID
                  </Link>
                </div>
              </div>
              <div className="col-md-10 mob-filter-data">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn-mob"}
                  >
                    <img src={pageDropdownIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                    {filtersOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.setFilter(value, "asc")}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dropdown no-arrow dp-right">
                  <button
                    type="button"
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={mobSearchIcon} alt="" />
                  </button>
                </div>
              </div>

              <div className="col-md-6 text-right">
                <Link
                  to={AppRoutes.Add_SCAN_RULES}
                  className="btn btn-primary add-btn"
                >
                  {" "}
                  <img
                    src={addrules}
                    style={{ marginTop: "-2px", marginRight: "4px" }}
                  />
                  Add Rules
                </Link>
                {/* <FilterComponent
                  options={filtersOption}
                  selectedFilter={sortBy}
                  onFilterChange={this.setFilter}
                />
                <FilterComponent
                  label={"Client"}
                  options={clientList}
                  selectedFilter={clientId}
                  onFilterChange={(selectedValue) =>
                    this.setClientFilter(selectedValue, "clientId")
                  }
                />
                <SearchFilter
                  placeholder={"Search by Scan ID, Batch Num, Product Name…"}
                  search={search}
                  onChange={this.handleChange}
                /> */}
                {/* <button onClick={handleShow}>popup</button> */}
              </div>
            </div>
          </div>
          <ListComponent
            scans={scans}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            totalRecords={totalRecords}
            limit={limit}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            batchViewHandler={this.batchViewHandler}
            userViewHandler={this.userViewHandler}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
            onEdit={(id) => {
              this.props.history.push(
                AppRoutes.Edit_SCAN_RULES.replace(":id", id)
              );
            }}
            filtersOption={filtersOption}
            onDelete={this.onDelete}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        </Modal> */}
      </div>
    );
  }
}

export default ScanRules;
