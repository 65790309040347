import React from "react";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";
import { VideoRow } from "../video-tutorial/VideoRow";

export const ListComponent = ({
  videos,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
  setFilter,
  order,
  sortBy,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th style={{ width: "auto" }}>
                  Video Name{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "videoName"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("videoName", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "videoName"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("videoName", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>{" "}
                </th>
                <th style={{ width: "auto" }}>
                  Video Description{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "videoDescription"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("videoDescription", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "videoDescription"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("videoDescription", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>

                <th style={{ width: "auto" }}>
                  Video Link{" "}
                  {/*<span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "videoLink"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("videoLink", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "videoLink"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("videoLink", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                    </span>{" "}*/}
                </th>
                <th style={{ width: "auto" }}>
                  Updated On{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "updatedAt"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("updatedAt", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "updatedAt"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("updatedAt", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>{" "}
                </th>

                <th
                  style={{
                    width: "190px",
                    // width: "160px",
                    // paddingRight: "20%",
                    // display: "flex",
                    // justifyContent: "flex-start",
                  }}
                >
                  <div
                    style={{
                      // width: "302px",
                      // paddingRight: "20%",
                      display: "flex",
                      justifyContent: "flex-start",
                      // paddingRight: "20px",
                    }}
                  >
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {videos && videos.length ? (
                videos.map((video, index) => (
                  <VideoRow
                    key={index}
                    videosData={video}
                    onEdit={() => onEdit(video)}
                    onDelete={() => onDelete(video._id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={5} className={"text-center image-no-data-xs"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
