import React from "react";
import { CounterfeitProduct } from "../loader/Resusable/CounterfeitProduct";

export const ClientDetails = ({ clientDetails, isLoading }) => {
  return (
    <>
      <div className="card card-main flex-fill clientDetailsCard">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className="card-header  py-7550  align-items-center justify-content-between ">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="font-book ">Client Details</h6>
                </div>
              </div>
            </div>
            <div className="card-body card-table">
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Company Name</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {clientDetails && clientDetails.companyName}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Client ID</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {(clientDetails && clientDetails.clientId) || "-"}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Email</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {clientDetails && clientDetails.email}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mtblf-5 mt-3">
                <div className="col-md-4">
                  <div className="heading-col">
                    <h5 className="ml-0">Phone Number</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {(clientDetails && clientDetails.phoneNumber) || "-"}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
