import Validator from "js-object-validation";
import { message } from "../common";

export const partnersValidator = (data) => {
  const validations = {
    name: {
      required: true,
      alpha: true,
    },
    email: {
      required: true,
      email: true,
    },
    country: {
      required: true,
    },
    state: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    name: {
      required: message.Required.replace(":item", "Name"),
      alpha: message.InvalidName.replace(":item", "Name"),
    },
    email: {
      required: message.Required.replace(":item", "Email"),
      email: message.InvalidEmail,
    },
    country: {
      required: message.Required.replace(":item", "Country"),
    },
    state: {
      required: message.Required.replace(":item", "State"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
