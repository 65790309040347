import React from "react";
import { Dropdown } from "react-bootstrap";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";

export const FilterComponent = ({
  options,
  label = "Sort By",
  selectedFilter,
  onFilterChange,
  showImage = true,
}) => {
  return (
    <Dropdown className="no-arrow dp-right">
      <Dropdown.Toggle
        variant="secondary"
        id="dropdown-basic"
        className={"filter-btn newArrowcz"}
        style={{ height: "100%" }}
      >
        {label}{" "}
        <span className="staff-value">
          {
            (
              options.filter((filter) => filter.value === selectedFilter)[0] ||
              {}
            ).label
          }
        </span>{" "}
        {showImage ? <img src={filterarrowIcon} alt="" /> : ""}
      </Dropdown.Toggle>

      <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
        {options.map(({ label, value, order }, index) => (
          <Dropdown.Item
            as="div"
            key={index}
            onClick={() => onFilterChange(value, "asc")}
          >
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
