import React, { useState } from "react";
import { InputTextField, SubmitButton } from "../forms";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../config";
import { BatchInputLoader } from "../loader/Resusable/BatchInput";
import { ApiHelper } from "../../../helpers";
import ReactQuill from "react-quill";

export const AddJobFormComponent = ({
  loadOriginOptions,
  handleSubmit,
  onChange,
  errors,
  name,
  planType,
  onSelect,
  price,
  email,
  description,
  users,
  printCodes,
  domainName,
  BrandAndCertificate,
  isEditMode,
  isAdding,
  isLoading,
  clientList,
  clientId,
  countryList,
  stateList,
  country,
  state,
  jobId,
  jobTitle,
  jobType,
  jobCategory,
  address,
  linkedin,
  basic,
  jobDescription,
  preQualifications,
  additonalHeading,
  additonalContent,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
  };

  const loadClient = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allJOBID.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  const loadCountryFilter = async (inputValue) => {
    let result;
    return await new Promise(async (resolve, reject) => {
      result = countryListUpdated.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };
  const loadStateFilter = async (inputValue) => {
    let result;
    return await new Promise(async (resolve, reject) => {
      result = stateListUpdated.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  const loadJobTitle = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allJOBTitle.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  let allJOBID = [];
  let allJOBTitle = [];
  if (clientList) {
    clientList.forEach((element) => {
      allJOBID.push({ label: element.jobId, value: element._id });
      allJOBTitle.push({ label: element.jobTitle, value: element._id });
    });
  }
  let countryListUpdated = [];
  if (countryList) {
    countryList.forEach((element) => {
      countryListUpdated.push({
        label: element.name,
        value: element.name,
      });
    });
  }
  let stateListUpdated = [];
  if (stateList) {
    stateList.forEach((element) => {
      stateListUpdated.push({
        label: element,
        value: element,
      });
    });
  }

  const planOptions = [
    { value: "Contact", label: "Contact" },
    { value: "Paid", label: "Paid" },
    { value: "Custom", label: "Custom" },
  ];
  const brandOptions = [
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
  ];

  return (
    <div style={{ padding: "14px 21px" }}>
      {isLoading ? (
        <BatchInputLoader />
      ) : (
        <form
          className="user_add m-0 addPlanform product_add"
          onSubmit={handleSubmit}
        >
          <div className="rows">
            <div className="cols-md-3">
              <div className="form-group">
                <label className={`form-control-label`}>Job ID</label>
                <AsyncSelect
                  loadOptions={loadClient}
                  placeholder={"Select"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={allJOBID}
                  value={jobId}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "jobId")
                  }
                  noOptionsMessage={() =>
                    "No job id found related to your search"
                  }
                />

                <div className={"text-danger"}>
                  {errors && errors.jobTitleId ? errors.jobTitleId : ""}
                </div>
              </div>
            </div>
            <div className="cols-md-3">
              <div className="form-group">
                <label className={`form-control-label`}>Job Title</label>
                <AsyncSelect
                  loadOptions={loadJobTitle}
                  placeholder={"Select"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={allJOBTitle}
                  value={jobTitle}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "jobTitle")
                  }
                  noOptionsMessage={() =>
                    "No job title found related to your search"
                  }
                />

                <div className={"text-danger"}>
                  {errors && errors.jobTitle ? errors.jobTitle : ""}
                </div>
              </div>
            </div>
            <div className="cols-md-3">
              <InputTextField
                id="plan_type"
                label={"Job Type"}
                className={"form-control-user"}
                name="name"
                value={jobType}
                onChange={onChange}
                disabled
                errorMessage={errors && errors.name ? errors.name : ""}
                maxLength={30}
                placeholder={"Will be autfilled"}
              />
            </div>
            <div className="cols-md-3">
              <InputTextField
                id="plan_category"
                style={{ color: "black" }}
                label={"Job Category"}
                className={"form-control-user"}
                name="name"
                value={jobCategory}
                disabled
                onChange={onChange}
                errorMessage={errors && errors.name ? errors.name : ""}
                maxLength={30}
                placeholder={"Will be autfilled"}
              />
            </div>
            <div className="cols-md-3">
              <div className="form-group">
                <label className={`form-control-label`}>Country</label>
                <AsyncSelect
                  loadOptions={loadCountryFilter}
                  placeholder={"Select"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={countryListUpdated}
                  name="country"
                  id="country"
                  value={country}
                  // onChange={(e) => onChange(e)}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "country")
                  }
                  noOptionsMessage={() => "No Country"}
                />
                <div className={"text-danger"}>
                  {errors && errors.country ? errors.country : ""}
                </div>
              </div>
            </div>
            <div className="cols-md-3">
              <div className="form-group">
                <label className={`form-control-label`}>City</label>
                <AsyncSelect
                  loadOptions={loadStateFilter}
                  placeholder={"Select"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={stateListUpdated}
                  name="state"
                  id="state"
                  value={state}
                  // onChange={(e) => onChange(e)}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "state")
                  }
                  noOptionsMessage={() => "No City"}
                />
                <div className={"text-danger"}>
                  {errors && errors.city ? errors.city : ""}
                </div>
              </div>
            </div>
          </div>

          <div className="rows row_AddJobListing" style={{ marginTop: "20px" }}>
            <div className="cols-md-3">
              <InputTextField
                id="address"
                label={"Address"}
                className={"form-control-user"}
                name="address"
                value={address}
                onChange={onChange}
                errorMessage={errors && errors.address ? errors.address : ""}
                maxLength={30}
                placeholder={"Address"}
              />
            </div>
            <div className="cols-md-3">
              <InputTextField
                id="linkedin"
                label={"Linkedin"}
                className={"form-control-user"}
                name="linkedin"
                value={linkedin}
                onChange={onChange}
                errorMessage={errors && errors.linkedin ? errors.linkedin : ""}
                maxLength={100}
                placeholder={"Linkedin"}
              />
            </div>
          </div>
          <div className="text-area">
            <div className="form-group">
              <label className="text-area-label">Job Description</label>
              <ReactQuill
                name="jobDescription"
                id="jobDescription"
                value={jobDescription}
                onChange={(value) => {
                  onChange({
                    target: {
                      type: "textarea",
                      value,
                      name: "jobDescription",
                      checked: false,
                    },
                  });
                }}
                placeholder="Type something here..."
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{ color: [] }],
                  ],
                }}
              />
              <div className={"text-danger"}>
                {errors && errors.jobDescription ? errors.jobDescription : ""}
              </div>
            </div>
          </div>
          <div className="text-area">
            <div className="form-group">
              <label className="text-area-label">Basic Qualifications</label>
              <ReactQuill
                name="basic"
                id="basic"
                value={basic}
                onChange={(value) => {
                  onChange({
                    target: {
                      type: "textarea",
                      value,
                      name: "basic",
                      checked: false,
                    },
                  });
                }}
                placeholder="Type something here..."
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{ color: [] }],
                  ],
                }}
              />
              <div className={"text-danger"}>
                {errors && errors.basicQualifications
                  ? errors.basicQualifications
                  : ""}
              </div>
            </div>
          </div>
          <div className="text-area">
            <div className="form-group">
              <label className="text-area-label">
                Preferred Qualifications
              </label>
              <ReactQuill
                name="preQualifications"
                id="preQualifications"
                value={preQualifications}
                onChange={(value) => {
                  onChange({
                    target: {
                      type: "textarea",
                      value,
                      name: "preQualifications",
                      checked: false,
                    },
                  });
                }}
                placeholder="Type something here..."
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{ color: [] }],
                  ],
                }}
              />
              <div className={"text-danger"}>
                {errors && errors.preferredQualifications
                  ? errors.preferredQualifications
                  : ""}
              </div>
            </div>
          </div>

          <div
            className="cols-xl-12"
            style={{ paddingTop: "20px", paddingRight: "10px" }}
          >
            <InputTextField
              id="additonalHeading"
              label={"Additional Info"}
              className={"form-control-user"}
              name="additonalHeading"
              value={additonalHeading}
              onChange={onChange}
              errorMessage={
                errors && errors.additionalInfoTitle
                  ? errors.additionalInfoTitle
                  : ""
              }
              maxLength={30}
              placeholder={"Enter additional heading"}
            />
          </div>
          <div className="text-area">
            <div className="form-group">
              <ReactQuill
                name="additonalContent"
                id="additonalContent"
                value={additonalContent}
                onChange={(value) => {
                  onChange({
                    target: {
                      type: "textarea",
                      value,
                      name: "additonalContent",
                      checked: false,
                    },
                  });
                }}
                placeholder="Type something here..."
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{ color: [] }],
                  ],
                }}
              />
              <div className={"text-danger"}>
                {errors && errors.additionalInfoDescription
                  ? errors.additionalInfoDescription
                  : ""}
              </div>
            </div>
          </div>

          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="buttons-container"
          >
            <Link
              to={AppRoutes.CAREERS.replace(":user", "Web")}
              className="btn btn-primary cancel-btn"
              //   onClick={handleCancel}
            >
              CANCEL
            </Link>

            {/* <button className="btn btn-primary submit-btn"> ADD</button> */}

            <SubmitButton
              className={"submit-btn"}
              text={isEditMode ? "UPDATE" : "ADD"}
              isLoading={isAdding}
              onSubmit={handleSubmit}
            />
          </div>
        </form>
      )}
    </div>
  );
};
