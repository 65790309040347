import React from 'react';

export const SubmitButton = ({
  text = '',
  className = '',
  children = null,
  isLoading,
  onSubmit,
}) => {
  return (
    <button
      className={`btn btn-primary ${className}`}
      type='submit'
      disabled={isLoading}
      onClick={onSubmit}
    >
      {isLoading ? (
        <>
          <i className='fa fa-spinner fa-spin test-spin'></i> {text}
        </>
      ) : (
        <>
          {text} {children}
        </>
      )}
    </button>
  );
};
