import React, { Component } from "react";
import { Dropdown, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { TableLoader } from "../../components/loader/Resusable/Table";
import { ViewInputLoader } from "../../components/loader/Resusable/ViewInput";
import {
  ApiRoutes,
  AppConfig,
  AppRoutes,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from "../../../config";
import { ApiHelper } from "../../../helpers";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { ScanRow } from "../../components/scans";
import Pagination from "../../components/pagination";
import defaultImg from "../../../assets/img/group-8.png";
import defaultUserImg from "../../../assets/img/user-default.svg";
import userIcon from "../../../../src/assets/img/user-4.svg";
import phoneIcon from "../../../../src/assets/img/phone.svg";
import emailIcon from "../../../../src/assets/img/email.svg";
import locationIcon from "../../../../src/assets/img/location.svg";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import "./view-user.css";
import * as qs from "query-string";
import { FilterComponent } from "../../components/filters";

let scanLimit = 5;
let counterfeitLimit = 5;

class ViewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userId: "",
      isLoading: false,
      scans: [],
      totalScanRecords: 0,
      currentScanPage: 1,
      isScanLoading: false,
      counterfeits: [],
      totalCounterfeitsRecords: 0,
      currentCounterfeitsPage: 1,
      isCounterfeitLoading: false,
      sortBy: "created_at",
      clientName: "PHL",
      clientList: [],
      clientId: "PHL",
    };
  }
  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "created_at",
        order: query.order ? query.order : "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
        clientName: query.clientName || "PHL",
        clientId: query.clientId || "PHL",
        // id: query.userId || "",
      },
      () => {
        this.fetchScanRecords(id);
        this.fetchCounterfeitRecords(id);
        this.fetchClientList();
      }
    );
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      console.log(data);
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "PHL",
            value: "PHL",
          },
          ...data,
        ],
      });
    }
  };
  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  componentDidMount = () => {
    this.getuser();
    this.handleQueryParams();
  };

  getuser = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (!id) {
      this.props.history.push("/manage-users");
    }
    this.setState({
      userId: id,
    });
    this.fetchUser(id);
    this.fetchScanRecords(id);
    this.fetchCounterfeitRecords(id);
    this.fetchClientList();
  };

  fetchUser = async (id) => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_USER_INFO_BY_ID.service,
      ApiRoutes.GET_USER_INFO_BY_ID.url.replace(":id", id),
      ApiRoutes.GET_USER_INFO_BY_ID.method,
      ApiRoutes.GET_USER_INFO_BY_ID.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      this.props.history.push(AppRoutes.MANAGE_USER);
    } else {
      const { data } = response.data;
      this.setState({ userInfo: data });
    }
  };

  blockUser = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to block this user?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BLOCK_USER.service,
      ApiRoutes.BLOCK_USER.url.replace(":id", id),
      ApiRoutes.BLOCK_USER.method,
      ApiRoutes.BLOCK_USER.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchUser(id);
      toast.success(response.messages[0]);
    }
  };
  unBlockUser = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to unblock this user?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNBLOCK_USER.service,
      ApiRoutes.UNBLOCK_USER.url.replace(":id", id),
      ApiRoutes.UNBLOCK_USER.method,
      ApiRoutes.UNBLOCK_USER.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchUser(id);
      toast.success(response.messages[0]);
    }
  };
  fetchScanRecords = async (id) => {
    const { currentScanPage, clientId } = this.state;
    let skip = scanLimit * (currentScanPage - 1);
    let data = {
      skip,
      limit: scanLimit,
      userId: id,
      clientId,
    };
    this.setState({
      isScanLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SCANS_LIST.service,
      ApiRoutes.SCANS_LIST.url,
      ApiRoutes.SCANS_LIST.method,
      ApiRoutes.SCANS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isScanLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { scans, totalRecords },
      } = response.data;
      this.setState({ scans, totalScanRecords: totalRecords });
    }
  };
  fetchCounterfeitRecords = async (id) => {
    const { currentCounterfeitsPage, clientName } = this.state;
    let skip = counterfeitLimit * (currentCounterfeitsPage - 1);
    let data = {
      skip,
      limit: counterfeitLimit,
      userId: id,
      clientName,
    };
    this.setState({
      isCounterfeitLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.COUNTERFEIT_LIST.service,
      ApiRoutes.COUNTERFEIT_LIST.url,
      ApiRoutes.COUNTERFEIT_LIST.method,
      ApiRoutes.COUNTERFEIT_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isCounterfeitLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { counterfeits, totalRecords },
      } = response.data;
      this.setState({ counterfeits, totalCounterfeitsRecords: totalRecords });
    }
  };
  onPageChanged = (page) => {
    const { currentScanPage, userId } = this.state;
    if (page !== currentScanPage) {
      this.setState(
        {
          currentScanPage: page,
        },
        () => this.fetchScanRecords(userId)
      );
    }
  };
  onCounterfeitPageChanged = (page) => {
    const { currentCounterfeitsPage, userId } = this.state;
    if (page !== currentCounterfeitsPage) {
      this.setState(
        {
          currentCounterfeitsPage: page,
        },
        () => this.fetchCounterfeitRecords(userId)
      );
    }
  };
  customBreadCrumb = () => {
    const {
      isLoading,
      userInfo: { first_name = "", last_name = "" },
    } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      [first_name, last_name].filter(Boolean).join(" ") || "View user"
    );
  };
  setClientFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    console.log(params);
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };
  render() {
    const filtersOption = [
      {
        label: "Scan ID",
        value: "scan_id",
      },
      {
        label: "Batch Number",
        value: "batch_number",
      },
      {
        label: "Product Name",
        value: "product_name",
      },
      {
        label: "Scan Time",
        value: "created_at",
      },
    ];

    const {
      userInfo: user,
      isLoading,
      scans,
      isScanLoading,
      currentScanPage,
      totalScanRecords,
      counterfeits,
      totalCounterfeitsRecords,
      currentCounterfeitsPage,
      isCounterfeitLoading,
      sortBy,
      clientList,
      clientName,
      clientId,
    } = this.state;
    const {
      props: {
        location: { pathname, state },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
          search={state ? state.search : ""}
        />
        <div className="card mb-4 card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col">
                <h6 className="font-book ">User Details</h6>
              </div>

              <div className="col-md-9 side-col ">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className={"action-btn"}
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                    {user.email ? (
                      <Dropdown.Item
                        as="div"
                        onClick={() => {
                          window.open(`mailto:${user.email}`);
                        }}
                      >
                        Contact User
                      </Dropdown.Item>
                    ) : (
                      <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(p) => (
                          <Tooltip id="button-tooltip" {...p}>
                            This user doesn't have an email set with the
                            account.
                          </Tooltip>
                        )}
                      >
                        <Dropdown.Item as="div">Contact User</Dropdown.Item>
                      </OverlayTrigger>
                    )}
                    <Dropdown.Item
                      as="div"
                      onClick={() =>
                        user.block
                          ? this.unBlockUser(user.id)
                          : this.blockUser(user.id)
                      }
                    >
                      {user.block ? "Unblock" : "Block"} User
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            {isLoading ? (
              <ViewInputLoader />
            ) : (
              <form className="user_add newui">
                <div className="row">
                  <div className="col-md-3 mob-show">
                    <div className="img-col-user">
                      <img
                        className="user-img"
                        // src={
                        //   user.profile_picture
                        //     ? `${AppConfig.FILES_ENDPOINT}${user.profile_picture}`
                        //     : defaultImg
                        // }
                        src={
                          user.profile_picture
                            ? user.profile_picture.startsWith("https://")
                              ? user.profile_picture
                              : `${AppConfig.FILES_ENDPOINT}${user.profile_picture}`
                            : defaultImg
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-9 pt-3">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group d-flex flex-column">
                          <label
                            htmlFor="first_name"
                            className="form-control-label"
                          >
                            <img src={userIcon} className="" alt="" />
                            First Name
                          </label>
                          <span>{user.first_name || ""}</span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group d-flex flex-column">
                          <label
                            htmlFor="last_name"
                            className="form-control-label"
                          >
                            <img src={userIcon} className="" alt="" />
                            Last Name
                          </label>
                          <span>{user.last_name || ""}</span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group d-flex flex-column">
                          <label htmlFor="phon" className="form-control-label">
                            <img src={phoneIcon} className="" alt="" />
                            Phone Number
                          </label>
                          <span>
                            {user?.other_info?.countryCode || "-"}{" "}
                            {user.phone || ""}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group d-flex flex-column">
                          <label htmlFor="email" className="form-control-label">
                            <img src={emailIcon} className="" alt="" />
                            Email
                          </label>
                          <span>{user.email || ""}</span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group d-flex flex-column">
                          <label
                            htmlFor="address_line_1"
                            className="form-control-label"
                          >
                            <img src={locationIcon} className="" alt="" />
                            Address Line 1
                          </label>
                          <span>
                            {user.other_info &&
                              (user.other_info.address1 || "")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mob-hide">
                    <div className="img-col-user2">
                      <img
                        className="user-img"
                        src={
                          user.profile_picture
                            ? user.profile_picture.startsWith("https://")
                              ? user.profile_picture
                              : `${AppConfig.FILES_ENDPOINT}${user.profile_picture}`
                            : defaultUserImg
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultUserImg;
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>

        <div className="card dropdownCard mb-4">
          <div className="card-header py-75  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex">
                  <h6 className="m-25 font-book ">Scan Details</h6>
                  <FilterComponent
                    label={"Client"}
                    options={clientList}
                    selectedFilter={clientId}
                    onFilterChange={(selectedValue) =>
                      this.setClientFilter(selectedValue, "clientId")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive ">
              {isScanLoading ? (
                <TableLoader columns={5} rows={5} />
              ) : (
                <table className="scroll" width="100%" cellSpacing="0">
                  <thead className="thead-dash pad-top">
                    <tr>
                      <th>Scan ID</th>
                      <th>Batch Number</th>
                      <th>Product Name</th>
                      <th>Store Name</th>
                      <th>Location</th>
                      <th>Scan Time</th>
                    </tr>
                  </thead>

                  <tbody className="tbody-dash">
                    {scans && scans.length ? (
                      scans.map((scan, index) => (
                        <ScanRow
                          key={index}
                          scanData={scan}
                          userColumn={false}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className={"text-center"}>
                          User hasn't scanned any product yet.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        {!isLoading && totalScanRecords > scanLimit ? (
          <div className="row">
            <div className="col-12">
              <div className="float-right mb-2">
                <Pagination
                  totalRecords={totalScanRecords}
                  currentPage={currentScanPage}
                  pageLimit={scanLimit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="card dropdownCard mb-4">
          <div className="card-header py-75  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex">
                  <h6 className="m-25 font-book ">Counterfeit Details</h6>
                  <FilterComponent
                    label={"Client"}
                    options={clientList}
                    selectedFilter={clientName}
                    onFilterChange={(selectedValue) =>
                      this.setClientFilter(selectedValue, "clientName")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {isCounterfeitLoading ? (
                <TableLoader columns={5} rows={5} />
              ) : (
                <table className="scroll" width="100%" cellSpacing="0">
                  <thead className="thead-dash pad-top">
                    <tr>
                      <th>Scan/Report ID</th>
                      <th>Batch Number</th>
                      <th>Product Name</th>
                      <th>Store Name</th>
                      <th>Request Time</th>
                    </tr>
                  </thead>

                  <tbody className="tbody-dash">
                    {counterfeits && counterfeits.length ? (
                      counterfeits.map((counterfeit, index) => (
                        <tr key={index}>
                          <td>
                            {counterfeit.scan
                              ? counterfeit.scan.scanId
                              : counterfeit.reportId}
                          </td>
                          <td>
                            {counterfeit.batch
                              ? counterfeit.batch.batchNumber
                              : "-"}
                          </td>
                          <td>
                            {counterfeit.product
                              ? counterfeit.product.productName
                              : "-"}
                          </td>
                          <td>{counterfeit.storeName || "-"}</td>
                          <td>
                            {counterfeit.requestDate
                              ? [
                                  moment(counterfeit.requestDate).format(
                                    DEFAULT_TIME_FORMAT
                                  ),
                                  moment(counterfeit.requestDate).format(
                                    DEFAULT_DATE_FORMAT
                                  ),
                                ].join(" Hrs | ")
                              : "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5} className={"text-center"}>
                          User hasn't reported any product yet.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {!isLoading && totalCounterfeitsRecords > counterfeitLimit ? (
          <div className="row">
            <div className="col-12">
              <div className="float-right mb-4">
                <Pagination
                  totalRecords={totalCounterfeitsRecords}
                  currentPage={currentCounterfeitsPage}
                  pageLimit={counterfeitLimit}
                  onPageChanged={this.onCounterfeitPageChanged}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ViewUser;
