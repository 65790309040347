import React from "react";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import printIcon from "../../../assets/img/printer.svg";
import printIconWhite from "../../../assets/img/printer-white.svg";
import { Button } from "react-bootstrap";

const BatchRow = ({ batchData, onDelete, onPrint, onView }) => {
  const { batchNumber = "", product = {}, availableUnits = "" } = batchData;
  const { productNumber = "", productName = "" } = product || {};
  return (
    <tr>
      <td className={"cursor-pointer"} onClick={onView}>
        {batchNumber}
      </td>
      <td>{productNumber}</td>
      <td>{productName}</td>
      <td>{availableUnits}</td>
      <td>
        <Button
          className="btn btn-secondary th-btn edit-tb action-block-btn"
          onClick={onView}
        >
          <span>
            <img src={editIcon} alt="" className={"img-main"} />
            <img src={editIconWhite} alt="" className={"img-hover"} />
          </span>
          View
        </Button>
        <Button
          className="btn btn-secondary th-btn edit-tb action-block-btn"
          onClick={onPrint}
        >
          <span>
            <img src={printIcon} alt="" className={"img-main"} />
            <img src={printIconWhite} alt="" className={"img-hover"} />
          </span>
          Print
        </Button>
        <Button
          variant="danger"
          className="btn th-btn delete-tb action-block-btn"
          onClick={onDelete}
        >
          <span>
            <img src={trashIcon} alt="" className={"img-main"} />
            <img src={trashIconWhite} alt="" className={"img-hover"} />
          </span>
          Delete
        </Button>

        {/* <div className='btn btn-secondary th-btn edit-tb' onClick={onView}>
          <img src={listIcon} alt='' /> View
        </div>
        <div className='btn btn-secondary th-btn edit-tb' onClick={onPrint}>
          <img src={printIcon} alt='' /> Print
        </div>
        <div className='btn btn-secondary th-btn delete-tb' onClick={onDelete}>
          <img src={trashIcon} alt='' /> Delete
        </div> */}
      </td>
    </tr>
  );
};

export default BatchRow;
