import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const CounterfeitRequest = () => (
  <>
    {' '}
    <div className='row'>
      <div className='col-md-4'>
        <div className='row mtblf-5'>
          <div className='col-md-12 mtb-5'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
          <div className='col-md-12 mtb-5'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
          <div className='col-md-12 mtb-5'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='row mtblf-5'>
          <div className='col-md-12 mtb-5'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
          <div className='col-md-12 mtb-5'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
          <div className='col-md-12 mtb-5'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='row mtblf-5'>
          <div className='col-md-12 mtb-5'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
          <div className='col-md-12 mtb-5'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
        </div>
      </div>
    </div>
  </>
);
