import React from "react";
import { TableLoader } from "../../components/loader/Resusable/Table"; //"../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";
import { CareersRow } from "./careersRow";

export const CareersListComponent = ({
  careersDetail,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
  fetchCareersRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  viewUser,
  handleUpdateStatus,
}) => {
  console.log(careersDetail, ":::details");
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={5} rows={10} />
        ) : (
          <>
            {userDetail && userDetail == "Web" ? (
              <table width="100%" cellSpacing="0">
                <thead className="pad-top">
                  <tr>
                    <th style={{ width: "200px" }}>
                      Job ID{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "jobId"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobId", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "jobId"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobId", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th style={{ width: "270px" }}>
                      Job Title{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "jobTitle"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobTitle", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "jobTitle"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobTitle", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    <th style={{ width: "auto" }}>
                      Location{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "address"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("address", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "address"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("address", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    <th style={{ width: "260px" }}>
                      Posted on{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "updatedAt"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("updatedAt", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "updatedAt"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("updatedAt", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th style={{ width: "200px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {careersDetail && careersDetail.length ? (
                    careersDetail.map((IndividualCareersDetail, index) => (
                      <CareersRow
                        key={index}
                        IndividualCareersDetail={IndividualCareersDetail}
                        fetchCareersRecords={fetchCareersRecords}
                        onEdit={() => onEdit(IndividualCareersDetail)}
                        onDelete={() => onDelete(IndividualCareersDetail._id)}
                        viewUser={() => viewUser(IndividualCareersDetail)}
                        handleUpdateStatus={(status, val) =>
                          handleUpdateStatus(status, val)
                        }
                      />
                    ))
                  ) : (
                    <tr className="no-tr">
                      <td
                        colSpan={5}
                        className={"text-center image-no-data-xs"}
                      >
                        {isFilterApplied ? (
                          <img src={noData} alt="" />
                        ) : (
                          <img src={noData} alt="" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
