import React, { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { matchPath } from "react-router";
import arrowIcon from "../../../assets/img/icon-br.svg";
import { routesArray } from "../../../router/routes";
import { AppRoutes } from "../../../config";

const BreadcrumbsComponent = ({ pathname, search = "", customBreadCrumb }) => {
  const { id } = useParams();

  const matches = [
    // {
    //   breadcrumb: 'Dashboard',
    //   path: AppRoutes.DASHBOARD,
    // },
  ];
  const renderer = ({ breadcrumb, match }) => {
    if (breadcrumb === "custom") {
      return customBreadCrumb();
    }
    return breadcrumb;
  };
  pathname
    .replace(/\/$/, "")
    .split("/")
    .reduce((previous, current) => {
      const pathSection = `${previous}/${current}`;
      let breadcrumbMatch;
      routesArray.some(({ breadcrumb, path }) => {
        const match = matchPath(pathSection, { exact: true, path });
        if (match) {
          breadcrumbMatch = {
            breadcrumb: renderer({ breadcrumb, match }),
            path,
          };
          return true;
        }
        return false;
      });
      if (breadcrumbMatch) {
        matches.push(breadcrumbMatch);
      }
      return pathSection;
    });

  return (
    <p className="breadcrums-top">
      {matches.map((route, index) => (
        <Fragment key={index}>
          {route.path !== AppRoutes.CLARITY &&
          route.path !== AppRoutes.CMS &&
          route.path !== AppRoutes.ENQUIRIES &&
          index !== matches.length - 1 ? (
            <Link
              to={`${route.path
                .replace(":user", "Web")
                .replace(":id", id)}${search}`}
            >
              {route.breadcrumb}
            </Link>
          ) : (
            route.breadcrumb
          )}
          {index !== matches.length - 1 ? (
            <img src={arrowIcon} className="breadcrum-seperator" alt="" />
          ) : null}
        </Fragment>
      ))}
    </p>
  );
};

export const Breadcrumbs = BreadcrumbsComponent;
