import React from "react";
import moment from "moment";
import {
  AppConfig,
  DB_ACCPTABLE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "../../../config";
import dummyclient from "../../../assets/img/noimage.jpeg";
import listIcon from "../../../assets/img/list.svg";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import phoneIcon from "../../../assets/img/phone.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import { useState } from "react";
import AddRole from "./AddRole";
const RoleRow = ({ roleData, onDelete, refreshAPI }) => {
  const [modalShow, setModalShow] = useState(false);
  const [editRoleData, SeteditRoleDate] = useState(false);

  const handleShow = () => {
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const editRole = (value) => {
    SeteditRoleDate(value);
    handleShow();
  };
  return (
    <>
      <tr>
        <td>{roleData.roleName}</td>
        <td>{roleData.onBordedStaff}</td>
        <td>
          {" "}
          {roleData.updatedAt
            ? moment(roleData.updatedAt).format(DEFAULT_DATE_FORMAT)
            : "-"}
        </td>
        <td>
          <button
            //   onClick={onEdit}
            className="btn btn-secondary th-btn"
            onClick={() => editRole(roleData)}
          >
            <img src={editIcon} alt="" /> Edit
          </button>

          <button
            className="btn btn-secondary th-btn"
            onClick={() => onDelete(roleData)}
          >
            <img src={trashIcon} alt="" /> Delete
          </button>
        </td>
      </tr>

      <AddRole
        modalShow={modalShow}
        modalClose={handleClose}
        placeholder={"Administration"}
        rolePrevData={editRoleData}
        refreshAPI={refreshAPI}
      />
    </>
  );
};

export default RoleRow;
