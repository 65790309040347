import React, { useEffect, useState } from "react";
import { TableLoader } from "../../../components/loader/Resusable/Table"; //"../loader/Resusable/Table";
import noData from "../../../../assets/img/no-data.png";
import { JobTitleRow } from "./jobTitleRow";
import Select from "react-select";
import { SubmitButton, InputTextField } from "../../../components/forms";
import { AddTitleValidator } from "../../../../validators/addTitle";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";

export const JobTitleListComponent = ({
  careersDetail,
  IndividualCareersDetail,
  isFilterApplied,
  showAddForm,
  isMainLoading,
  onEdit,
  onDelete,
  fetchCareersRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  viewUser,
  handleUpdateStatus,
  closeAddModal,
}) => {
  const [jobId, setJobId] = useState(IndividualCareersDetail?.jobId || "");
  const [jobTitle, setJobTitle] = useState(
    IndividualCareersDetail?.jobTitle || ""
  );
  const [jobType, setJobType] = useState(
    IndividualCareersDetail?.jobType || ""
  );
  const [jobCategory, setJobCategory] = useState(
    IndividualCareersDetail?.jobCategory || ""
  );
  const [moduleDetails, setModuleDetails] = useState(
    careersDetail.length ? careersDetail : []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    setJobId(IndividualCareersDetail?.jobId || "");
    setJobTitle(IndividualCareersDetail?.jobTitle || "");
    setJobType(IndividualCareersDetail?.jobType || "");
    setJobCategory(IndividualCareersDetail?.jobCategory || "");
  }, [IndividualCareersDetail]);

  // useEffect(() => {
  //   setModuleDetails(moduleDetail);
  // }, [moduleDetail]);

  const onChange = (event) => {
    setErrors({});
    setIsAdding(false);

    const {
      target: { name, value },
    } = event;
    if (name === "jobId") {
      setJobId(value.trimLeft());
    }
    if (name === "jobTitle") {
      setJobTitle(value.trimLeft());
    }
    if (name === "jobType") {
      setJobType(value.trimLeft());
    }
    if (name === "jobCategory") {
      setJobCategory(value.trimLeft());
    }
    // if (name == "profile") {
    //   setProfile(value);
    // }
  };

  const handleSubmit = (event) => {
    const formData = new FormData();
    // setIsLoading(true);
    // setIsAdding(true);

    event.preventDefault();
    let payload = {
      jobId: jobId,
      jobTitle: jobTitle,
      jobType: jobType,
      jobCategory: jobCategory,
    };

    const { isValid, errors } = AddTitleValidator(payload);

    let filterData = "";

    if (isValid) {
      setErrors({});
      setIsAdding(true);

      if (filterData.length === 0) {
        if (IndividualCareersDetail._id) {
          payload = { ...payload, _id: IndividualCareersDetail._id };
          updateEntry(payload);
        } else {
          addEntry(payload);
        }
      } else {
        setIsLoading(false);
        setIsAdding(false);
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
      return;
    }
  };

  const addEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.ADD_JOB_TITLE.service,
      ApiRoutes.ADD_JOB_TITLE.url,
      ApiRoutes.ADD_JOB_TITLE.method,
      ApiRoutes.ADD_JOB_TITLE.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      setIsAdding(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      setIsLoading(false);
      setIsAdding(false);
      setJobId("");
      setJobTitle("");
      setJobType("");
      setJobCategory("");
      closeAddModal();
    }
  };

  const updateEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.UPDATE_JOB_TITLE.service,
      ApiRoutes.UPDATE_JOB_TITLE.url,
      ApiRoutes.UPDATE_JOB_TITLE.method,
      ApiRoutes.UPDATE_JOB_TITLE.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      setIsAdding(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      setIsLoading(false);
      setIsAdding(false);
      setJobId("");
      setJobTitle("");
      setJobType("");
      setJobCategory("");
      closeAddModal();
    }
  };

  console.log(careersDetail, ":::details");
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading || isMainLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <>
            {userDetail && userDetail == "Web" ? (
              <table width="100%" cellSpacing="0">
                <thead className="pad-top">
                  <tr>
                    <th style={{ width: "190px" }}>
                      Job ID{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "jobId"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobId", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "jobId"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobId", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th style={{ width: "420px" }}>
                      Job Title{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "jobTitle"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobTitle", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "jobTitle"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobTitle", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    <th style={{ width: "420px" }}>
                      Job Type{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "jobType"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobType", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "jobType"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobType", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    <th style={{ width: "420px" }}>
                      Category{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "jobCategory"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobCategory", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "jobCategory"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("jobCategory", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th style={{ width: "200px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {showAddForm ? (
                    <tr className="form-row-table lw-space add-table-row">
                      <td
                        className={` ${
                          errors && (errors?.states || errors?.origin)
                            ? "has-error"
                            : ""
                        }`}
                      >
                        <InputTextField
                          id="jobId"
                          className={"form-control-user"}
                          name="jobId"
                          value={jobId}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.jobId ? errors.jobId : ""
                          }
                          maxLength={30}
                          placeholder={"Enter Job Id"}
                        />
                        {errors && errors?.origin ? (
                          <div className={"text-danger error-text"}>
                            {errors?.origin}
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                      </td>
                      <td
                        className={` ${
                          errors && (errors?.states || errors?.origin)
                            ? "has-error"
                            : ""
                        }`}
                      >
                        <InputTextField
                          id="jobTitle"
                          className={"form-control-user"}
                          name="jobTitle"
                          value={jobTitle}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.jobTitle ? errors.jobTitle : ""
                          }
                          maxLength={30}
                          placeholder={"Enter Job Title"}
                        />

                        {errors && errors?.states ? (
                          <div className={"text-danger error-text"}>
                            {errors?.states}
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                      </td>
                      <td
                        className={` ${
                          errors && (errors?.states || errors?.origin)
                            ? "has-error"
                            : ""
                        }`}
                      >
                        <InputTextField
                          id="jobType"
                          className={"form-control-user"}
                          name="jobType"
                          value={jobType}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.jobType ? errors.jobType : ""
                          }
                          maxLength={30}
                          placeholder={"Enter Job Type"}
                        />

                        {errors && errors?.states ? (
                          <div className={"text-danger error-text"}>
                            {errors?.states}
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                      </td>
                      <td
                        className={` ${
                          errors && (errors?.states || errors?.origin)
                            ? "has-error"
                            : ""
                        }`}
                      >
                        <InputTextField
                          id="jobCategory"
                          className={"form-control-user"}
                          name="jobCategory"
                          value={jobCategory}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.jobCategory
                              ? errors.jobCategory
                              : ""
                          }
                          maxLength={30}
                          placeholder={"Enter Job Category"}
                        />

                        {errors && errors?.states ? (
                          <div className={"text-danger error-text"}>
                            {errors?.states}
                          </div>
                        ) : (
                          ""
                        )}
                        <br />
                      </td>
                      <td>
                        <button
                          className={`btn btn-secondary can-btn-table ${
                            errors && (errors?.states || errors?.origin)
                              ? "btn-org"
                              : ""
                          }`}
                          onClick={closeAddModal}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <SubmitButton
                          className={`save-btn-table add ${
                            errors && (errors?.states || errors?.origin)
                              ? "btn-org"
                              : ""
                          }`}
                          text={
                            IndividualCareersDetail?.jobId
                              ? "Edit Title"
                              : "Add Title"
                          }
                          isLoading={isAdding}
                          onSubmit={handleSubmit}
                        />
                        <br />
                      </td>
                    </tr>
                  ) : null}

                  {careersDetail && careersDetail.length ? (
                    careersDetail.map((IndividualCareersDetail, index) => (
                      <JobTitleRow
                        key={index}
                        IndividualCareersDetail={IndividualCareersDetail}
                        fetchCareersRecords={fetchCareersRecords}
                        onEdit={() => onEdit(IndividualCareersDetail)}
                        onDelete={() => onDelete(IndividualCareersDetail._id)}
                        viewUser={() => viewUser(IndividualCareersDetail)}
                        handleUpdateStatus={(status, val) =>
                          handleUpdateStatus(status, val)
                        }
                      />
                    ))
                  ) : (
                    <tr className="no-tr">
                      <td
                        colSpan={5}
                        className={"text-center image-no-data-xs"}
                      >
                        {isFilterApplied ? (
                          <img src={noData} alt="" />
                        ) : (
                          <img src={noData} alt="" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
