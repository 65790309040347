import React from "react";
import { Modal } from "react-bootstrap";

export default function ResolutionModal({
  resolutionShow,
  resolutionclose,
  handleDescChange,
  handleCompleteSubmit,
  loading,
  error,
  cancelDesc,
}) {
  return (
    <Modal
      show={resolutionShow}
      onHide={resolutionclose}
      className="modal fade counterfeitModeltwo popuptwo markAsResolveModal"
    >
      <div className="modal-body">
        <h3>Mark as Resolve</h3>
        <p>Please enter resolution note</p>
        <form onSubmit={handleCompleteSubmit}>
          <textarea
            placeholder="Resolution note......"
            onChange={handleDescChange}
            value={cancelDesc?.resolution}
            name="resolution"
          ></textarea>
          <div className="text-danger">
            {error && error.error && error.error.statusDescription
              ? error.error.statusDescription
              : ""}
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "37px" }}
          >
            <button
              type="button"
              className="customCancelBtn mt-0 btn"
              onClick={resolutionclose}
            >
              Cancel
            </button>
            <button
              disabled={loading}
              className="btn customSubmitBtn mt-0"
              type="submit"
            >
              {loading ? (
                <>
                  <i className="fa fa-spinner fa-spin test-spin"></i>
                  Submit
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
