import Validator from "js-object-validation";
import { message } from "../common";

export const aboutUsValidator = (data) => {
  const validations = {
    firstName: {
      required: true,
      alpha: true,
    },
    lastName: {
      required: true,
      alpha: true,
    },
    designation: {
      required: true,
    },
    linkedInUrl: {
      url: true,
    },
    intagramUrl: {
      url: true,
    },
    facebookUrl: {
      url: true,
    },
    twitterUrl: {
      url: true,
    },
  };
  // Error messages
  const messages = {
    firstName: {
      required: message.Required.replace(":item", "First Name"),
      alpha: message.InvalidName.replace(":item", "First Name"),
    },
    lastName: {
      required: message.Required.replace(":item", "Last Name"),
      alpha: message.InvalidName.replace(":item", "Last Name"),
    },
    designation: {
      required: message.Required.replace(":item", "designation"),
    },
    linkedInUrl: {
      url: message.ValidUrlLink.replace(":item", "linkedIn"),
    },
    intagramUrl: {
      url: message.ValidUrlLink.replace(":item", "intagram"),
    },
    facebookUrl: {
      url: message.ValidUrlLink.replace(":item", "facebook"),
    },
    twitterUrl: {
      url: message.ValidUrlLink.replace(":item", "twitter"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
