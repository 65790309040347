/** @format */

import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ProgressBar } from "react-bootstrap";
import { InputTextField } from "../../forms";
import { AppConfig, customReactSelectStyles } from "../../../../config";
import countryStateData from "../../../../common/countryState.json";
import defaultImg from "../../../../assets/img/group-12.png";
import trashIcon from "../../../../assets/img/trash-2.svg";

export const ClientInfoFormComponent = ({
  clientData,
  isUploading,
  uploadPercentage,
  onChange,
  onFileChange,
  onDeleteFile,
  onSelectOption,
}) => {
  const {
    email = "",
    first_name = "",
    last_name = "",
    address1 = "",
    address2 = "",
    country = "",
    postCode = "",
    state = "",
    phone = "",
    profile_picture = "",
    errors = {},
  } = clientData;
  const [statesOption, setStatesOption] = useState([]);
  const countriesOption = [];
  countryStateData.forEach((element) => {
    countriesOption.push({
      label: element.name,
      value: element.name,
    });
  });
  const handleStatesOption = (value) => {
    let temp = [];
    let selectedCountryIndex = countryStateData.findIndex(
      (element) => element.name === value
    );
    if (selectedCountryIndex > -1) {
      countryStateData[selectedCountryIndex].states
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((state) => {
          temp.push({
            label: state.name,
            value: state.name,
          });
        });
      setStatesOption(temp);
    }
  };
  useEffect(() => {
    if (country && country.value) {
      handleStatesOption(country.value);
    }
  }, [country]);

  return (
    <div className="col-md-12">
      <form className="user_add mt-2">
        <div className="row">
          <div className="col-md-3 mob-show">
            <div className="img-col-user">
              <img
                className="user-img"
                // src={
                //   profile_picture
                //     ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                //     : defaultImg
                // }
                src={
                  profile_picture
                    ? profile_picture.startsWith("https://")
                      ? profile_picture
                      : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                    : defaultImg
                }
                alt=""
              />
            </div>
            {isUploading ? (
              <ProgressBar now={uploadPercentage} className={"mt-2"} />
            ) : null}
            <div className="file-upload-btn ">
              <label className="file-label">
                {" "}
                {profile_picture ? "Change Image" : "Choose Image"}
                <input
                  className="imgUpload img_up"
                  name="profile_picture"
                  type="file"
                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                  onChange={onFileChange}
                />
              </label>
              {profile_picture ? (
                <span onClick={onDeleteFile} className={"cursor-pointer"}>
                  <img src={trashIcon} alt="" />
                </span>
              ) : null}
            </div>
            <div className={"text-danger"}>
              {errors && errors.profile_picture ? errors.profile_picture : ""}
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <InputTextField
                  id="first_name"
                  label={"First Name"}
                  className={"form-control-user"}
                  name="first_name"
                  value={first_name}
                  maxLength={30}
                  onChange={onChange}
                  errorMessage={
                    errors && errors.first_name ? errors.first_name : ""
                  }
                />
              </div>
              <div className="col-md-4">
                <InputTextField
                  id="last_name"
                  label={"Last Name"}
                  className={"form-control-user"}
                  name="last_name"
                  value={last_name}
                  maxLength={30}
                  onChange={onChange}
                  errorMessage={
                    errors && errors.last_name ? errors.last_name : ""
                  }
                />
              </div>
              <div className="col-md-4">
                <InputTextField
                  id="phone"
                  label={"Phone Number"}
                  className={"form-control-user"}
                  name="phone"
                  value={phone}
                  maxLength={14}
                  onChange={onChange}
                  errorMessage={errors && errors.phone ? errors.phone : ""}
                />
              </div>
              <div className="col-md-4">
                <InputTextField
                  id="email"
                  label={"Email"}
                  className={"form-control-user"}
                  name="email"
                  value={email}
                  readOnly={true}
                  // onChange={onChange}
                  errorMessage={errors && errors.email ? errors.email : ""}
                />
              </div>
              <div className="col-md-4">
                <InputTextField
                  id="address_line_1"
                  label={"Address Line 1"}
                  className={"form-control-user"}
                  name="address1"
                  value={address1}
                  onChange={onChange}
                  errorMessage={
                    errors && errors.address1 ? errors.address1 : ""
                  }
                />
              </div>
              <div className="col-md-4">
                <InputTextField
                  id="address_line_2"
                  label={"Address Line 2"}
                  className={"form-control-user"}
                  name="address2"
                  value={address2}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-4">
                {/* <InputTextField
                  id="country"
                  label={"Country"}
                  className={"form-control-user"}
                  name="country"
                  value={country}
                  onChange={onChange}
                /> */}
                <label
                  for="country"
                  class="form-control-label form-control-user"
                >
                  Country
                </label>
                <Select
                  placeholder={"Select country"}
                  label={"Country"}
                  className={"form-control-user"}
                  id={"country"}
                  styles={customReactSelectStyles}
                  options={countriesOption}
                  value={country}
                  onChange={(selectedOptions) =>
                    onSelectOption(selectedOptions, "country", "clientData")
                  }
                  // name="country"
                  menuPortalTarget={document.querySelector("body")}
                />
              </div>
              <div className="col-md-4">
                {/* <InputTextField
                  id="state"
                  label={"State"}
                  className={"form-control-user"}
                  name="state"
                  value={state}
                  onChange={onChange}
                /> */}
                <label for="state" class="form-control-label form-control-user">
                  State
                </label>
                <Select
                  options={statesOption}
                  placeholder={"Select state"}
                  id={"state"}
                  styles={customReactSelectStyles}
                  menuPortalTarget={document.querySelector("body")}
                  value={state}
                  noOptionsMessage={() => {
                    return country && country.value
                      ? "No states found"
                      : "First select country";
                  }}
                  onChange={(selectedOptions) =>
                    onSelectOption(selectedOptions, "state", "clientData")
                  }
                />
              </div>
              <div className="col-md-4">
                <InputTextField
                  id="post_code"
                  label={"Post Code"}
                  className={"form-control-user"}
                  name="postCode"
                  value={postCode}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3 mob-hide">
            <div className="img-col-user">
              <img
                className="user-img"
                // src={
                //   profile_picture
                //     ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                //     : defaultImg
                // }
                src={
                  profile_picture
                    ? profile_picture.startsWith("https://")
                      ? profile_picture
                      : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                    : defaultImg
                }
                alt=""
              />
            </div>
            {isUploading ? (
              <ProgressBar now={uploadPercentage} className={"mt-2"} />
            ) : null}
            <div className="file-upload-btn ">
              <label className="file-label">
                {" "}
                {profile_picture ? "Change Image" : "Choose Image"}
                <input
                  className="imgUpload img_up"
                  name="profile_picture"
                  type="file"
                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                  onChange={onFileChange}
                />
              </label>
              {profile_picture ? (
                <span
                  onClick={() => onDeleteFile("profile_picture")}
                  className={"cursor-pointer"}
                >
                  <img src={trashIcon} alt="" />
                </span>
              ) : null}
            </div>
            <div className={"text-danger"}>
              {errors && errors.profile_picture ? errors.profile_picture : ""}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
