import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes, genderOptions } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import { InfoDetailComponent } from "../../../components/staffs";
import { SubmitButton } from "../../../components/forms";
import { message } from "../../../../common";
import "../manage-staff.css";
import { editStaff } from "../../../../validators/editStaff";

class ViewStaffRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: "",
      staffData: {},
      isLoading: false,
      isUpdating: false,
      updatingStatus: "",
      dateError: false,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        staffId: id,
      },
      () => this.viewStaff()
    );
  };
  viewStaff = async () => {
    const { staffId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_STAFF.service,
      ApiRoutes.VIEW_STAFF.url.replace(":id", staffId),
      ApiRoutes.VIEW_STAFF.method,
      ApiRoutes.VIEW_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    console.log("response", response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response?.data;
      const [
        {
          first_name = "",
          last_name = "",
          employee_id = "",
          email = "",
          phone = "",
          profile_picture,
          other_info = {},
          birth_date = "",
          joining_date = "",
          personal_email = "",
          office_location = "",
          office_phone = "",
          gender = "",
          permission = "",
          portal_access = "",
          status = "",
        },
      ] = data || {};
      const {
        address1 = "",
        address2 = "",
        state = "",
        country = "",
        postCode = "",
      } = other_info || {};
      this.setState({
        staffData: {
          first_name,
          last_name,
          employee_id,
          email,
          phone: phone || "",
          profile_picture,
          gender: gender
            ? genderOptions.filter((e) => e.value === gender)[0]
            : "",
          address1: address1 || "",
          address2,
          state,
          country,
          postCode,
          birth_date: birth_date ? new Date(birth_date) : null || "",
          joining_date: joining_date ? new Date(joining_date) : null,
          personal_email,
          office_location,
          office_phone,
          permission,
          portal_access,
          status,
        },
      });
    }
  };
  handleInfoChange = (event) => {
    let {
      target: { name, value, type, checked },
    } = event;

    if (name === "phone" && isNaN(value)) {
      return;
    }
    if (name === "office_phone" && isNaN(value)) {
      return;
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        [name]: value.trimLeft(),
        errors: {
          ...this.state.staffData.errors,
          [name]: "",
        },
      },
    });
  };
  onSelect = (data) => {
    console.log("here", data);
    if (data.value) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          gender: data,
        },
      });
    }
  };
  handleDateChange = (date) => {
    if (date) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          birth_date: date,
        },
      });
    }
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          errors: {
            ...this.state.staffData.errors,
            [name]: message.InvalidImages,
          },
        },
      });
      return;
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        errors: {
          ...this.state.staffData.errors,
          [name]: "",
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = "";
  };
  onDeleteFile = () => {
    this.setState({
      staffData: {
        ...this.state.staffData,
        profile_picture: "",
        errors: {
          ...this.state.staffData.errors,
          profile_picture: "",
        },
      },
    });
  };
  uploadPicture = async (data, name) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      this.setState({
        staffData: { ...this.state.staffData, [name]: path },
      });
    }
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
    });
  };
  updateStaffStatus = async (status) => {
    const { staffId } = this.state;
    this.setState({
      isUpdating: true,
      updatingStatus: status,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_STATUS.service,
      ApiRoutes.UPDATE_STAFF_STATUS.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_STATUS.method,
      ApiRoutes.UPDATE_STAFF_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.MANAGE_STAFF_REQUESTS);
    }
  };
  updateStaffInfo = async (data) => {
    const { staffId, staffData } = this.state;
    this.setState({
      isUpdating: true,
      updatingSection: "contact",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_INFO.service,
      ApiRoutes.UPDATE_STAFF_INFO.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_INFO.method,
      ApiRoutes.UPDATE_STAFF_INFO.authenticate,
      undefined,
      {
        ...data,
        gender: data.gender ? data.gender.value : "",
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.setState({
        isEditable: false,
      });
    }
  };

  submitDetails = (event) => {
    event && event.preventDefault();
    const { staffData, dateError } = this.state;
    if (!staffData.birth_date) {
      this.setState({
        dateError: true,
      });
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        errors: {
          ...this.state.staffData.errors,
        },
      },
    });
    const { isValid, errors } = editStaff(staffData);
    if (isValid && staffData.birth_date) {
      this.updateStaffInfo(staffData);
    } else {
      console.log("staffData", staffData);

      this.setState({
        dateError: true,
        staffData: {
          ...this.state.staffData,
          errors: errors,
        },
      });
    }
  };
  requestForModification = async (status) => {
    const { staffId } = this.state;
    this.setState({
      isUpdating: true,
      updatingStatus: status,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REQUEST_FOR_MODIFICATION_TO_STAFF.service,
      ApiRoutes.REQUEST_FOR_MODIFICATION_TO_STAFF.url.replace(":id", staffId),
      ApiRoutes.REQUEST_FOR_MODIFICATION_TO_STAFF.method,
      ApiRoutes.REQUEST_FOR_MODIFICATION_TO_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.MANAGE_STAFF_REQUESTS);
    }
  };
  resendInvitation = async (status) => {
    const { staffId } = this.state;
    this.setState({
      isUpdating: true,
      updatingStatus: status,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESEND_INVITATION_TO_STAFF.service,
      ApiRoutes.RESEND_INVITATION_TO_STAFF.url.replace(":id", staffId),
      ApiRoutes.RESEND_INVITATION_TO_STAFF.method,
      ApiRoutes.RESEND_INVITATION_TO_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.MANAGE_STAFF_REQUESTS);
    }
  };
  onEdit = () => {
    this.setState({
      isEditable: true,
    });
  };
  onCancel = () => {
    this.setState({
      isEditable: false,
    });
  };
  customBreadCrumb = () => {
    const {
      isLoading,
      staffData: { first_name = "", last_name = "" },
    } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      [first_name, last_name].filter(Boolean).join(" ") || "View user"
    );
  };
  render() {
    const {
      isLoading,
      isUpdating,
      staffData,
      updatingStatus,
      isEditable,
      dateError,
    } = this.state;
    const { status = "" } = staffData;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
        />
        <div className="row">
          <InfoDetailComponent
            staffData={staffData}
            isLoading={isLoading}
            actionBtn={false}
            isEditable={isEditable}
            onEdit={this.onEdit}
            onChange={this.handleInfoChange}
            onSelect={this.onSelect}
            onFileChange={this.onFileChange}
            onDeleteFile={this.onDeleteFile}
            dateError={dateError}
            onDateChange={this.handleDateChange}
          />
        </div>
        {!isLoading ? (
          <div className="row">
            <div className="col-md-12 d-flex">
              <div className="card card-main flex-fill">
                {isEditable ? (
                  <div className="row">
                    <div className="col-md-12 d-flex">
                      <div className="card card-main flex-fill">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12 footer-form-card">
                              <button
                                type="button"
                                className="btn btn-primary cancel-btn"
                                onClick={this.onCancel}
                              >
                                CANCEL
                              </button>
                              <SubmitButton
                                className={"submit-btn"}
                                text={"SAVE"}
                                onSubmit={this.submitDetails}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card-body">
                    <div className="row">
                      {" "}
                      <div className="col-md-12 footer-form-card">
                        {status === "Pending" && (
                          <div>
                            <button
                              type="button"
                              className="btn approve-btn request-for-modification-btn"
                              onClick={() =>
                                this.requestForModification("Pending")
                              }
                              disabled={
                                isUpdating && updatingStatus === "Pending"
                              }
                            >
                              {isUpdating && updatingStatus === "Pending" ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                  REQUEST FOR MODIFICATION
                                </>
                              ) : (
                                "REQUEST FOR MODIFICATION"
                              )}
                            </button>
                            <button
                              className="btn reject-btn"
                              onClick={() => this.updateStaffStatus("Accepted")}
                              disabled={
                                isUpdating && updatingStatus === "Accepted"
                              }
                            >
                              {isUpdating && updatingStatus === "Accepted" ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                  ACCEPT INVITATION
                                </>
                              ) : (
                                "ACCEPT INVITATION"
                              )}
                            </button>
                          </div>
                        )}
                        {status === "Invited" && (
                          <div>
                            <button
                              className="btn reject-btn"
                              onClick={() =>
                                this.updateStaffStatus("Cancelled")
                              }
                              disabled={
                                isUpdating && updatingStatus === "Cancelled"
                              }
                            >
                              {isUpdating && updatingStatus === "Cancelled" ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                  CANCEL
                                </>
                              ) : (
                                "CANCEL"
                              )}
                            </button>
                          </div>
                        )}
                        {status === "Accepted" && (
                          <div>
                            <button
                              type="button"
                              className="btn approve-btn"
                              onClick={() => this.updateStaffStatus("Active")}
                              disabled={
                                isUpdating && updatingStatus === "Active"
                              }
                            >
                              {isUpdating && updatingStatus === "Active" ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                  CONVERT TO EMPLOYEE
                                </>
                              ) : (
                                "CONVERT TO EMPLOYEE"
                              )}
                            </button>
                          </div>
                        )}
                        {status === "Cancelled" && (
                          <div>
                            <button
                              className="btn approve-btn"
                              onClick={() => this.resendInvitation("Invited")}
                              disabled={
                                isUpdating && updatingStatus === "Invited"
                              }
                            >
                              {isUpdating && updatingStatus === "Invited" ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                  RESEND INVITATION
                                </>
                              ) : (
                                "RESEND INVITATION"
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default ViewStaffRequest;
