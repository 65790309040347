import React from "react";
import { TableLoader } from "../../components/loader/Resusable/Table"; //"../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";
import { AccountRow } from "./accountRow";

export const AccountListComponent = ({
  topicDetail,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
  fetchTopicRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  viewUser,
  handleUpdateStatus,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={4} rows={10} />
        ) : (
          <>
            {userDetail && userDetail == "Web" ? (
              <table width="100%" cellSpacing="0">
                <thead className="pad-top">
                  <tr>
                    <th style={{ width: "180px" }}>
                      Module{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "question"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("moduleId", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "question"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("moduleId", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th style={{ width: "350px" }}>
                      Topic{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "answer"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("topic", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "answer"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("topic", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    <th style={{ width: "auto" }}>
                      Description{" "}
                      {/*<span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "answer"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("description", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "answer"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("description", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                        </span>*/}
                    </th>
                    <th style={{ width: "200px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {topicDetail && topicDetail.length ? (
                    topicDetail.map((IndividualTopicDetail, index) => (
                      <AccountRow
                        key={index}
                        IndividualTopicDetail={IndividualTopicDetail}
                        fetchTopicRecords={fetchTopicRecords}
                        onEdit={() => onEdit(IndividualTopicDetail)}
                        onDelete={() => onDelete(IndividualTopicDetail._id)}
                        viewUser={() => viewUser(IndividualTopicDetail)}
                        handleUpdateStatus={(status, val) =>
                          handleUpdateStatus(status, val)
                        }
                      />
                    ))
                  ) : (
                    <tr className="no-tr">
                      <td
                        colSpan={5}
                        className={"text-center image-no-data-xs"}
                      >
                        {isFilterApplied ? (
                          <img src={noData} alt="" />
                        ) : (
                          <img src={noData} alt="" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
