/** @format */

export const message = {
  postCodeLength: ":item should have min 4 and max 6 numbers",
  Required: "Please enter the :item",
  SpaceNotAllowed: ":item cannot start with space",
  RequiredImage: "Please upload  :item",
  InvalidEmail: "Please enter valid email address",
  InvalidName: ":item must contain alphabets only",
  InvalidCertificate: ":item must be in numeric digit only",
  InvalidPhone: "Phone Number must be in numeric digit only",
  InvalidNumber: "Price must be in numeric digit only",
  ValidLink: "Please enter valid youtube video link",
  InvalidImages: "Only images of type jpg, jpeg and png are allowed",
  InvalidItemCount: "Item count must be a whole number",
  MinItemCount: "Item count should be greater than 0",
  MinPhoneCount: ":item count should be greater than :length character",
  InvalidItemCountNumber: "Item count number too big max 10 digit are allowed",
  PrintBatchError:
    "There is only :count available units remaining for this batch. Please enter an amount equal to or lower than the available count.",
  MaxLengthError: ":item length must not exceed :length character",
  SubModuleRequired: "Please select area of access",
  InvalidWebite: "Please enter valid website link",
  InvalidVideoName: "Only alphanumeric characters are allowed",
  RequiredReason: "Please choose the reason",
  ValidUrlLink: "Please enter valid :item url.",
};
