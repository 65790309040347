import React from 'react';

export const InputCheckbox = ({
  id = '',
  label = '',
  className,
  name = '',
  value,
  onChange,
}) => {
  return (
    <div className='form-group'>
      <div className='custom-control custom-checkbox small'>
        <input
          type='checkbox'
          className={`custom-control-input ${className}`}
          id={id}
          name={name}
          checked={value}
          onChange={onChange}
        />
        <label htmlFor={id} className={'custom-control-label'}>
          {label}
        </label>
      </div>
    </div>
  );
};
