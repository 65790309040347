import React from "react";
import noData from "../../../assets/img/no-data.png";
import RoleRow from "./RoleRow";
import { TableLoader } from "../../components/loader/Resusable/Table";

export const ListComponent = ({
  isLoading,
  role,
  isFilterApplied,
  setFilter,
  sortBy,
  order,
  onDelete,
  refreshAPI,
  handleModal,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-lg">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th>Role</th>
                <th>Onboarded Staff</th>
                <th>Updated On</th>

                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {role && role.length ? (
                role.map((element, index) => (
                  <RoleRow
                    key={index}
                    roleData={element}
                    onDelete={onDelete}
                    refreshAPI={refreshAPI}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={6} className={"text-center image-no-data-sm"}>
                    <img src={noData} alt="" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
