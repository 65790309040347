import React from "react";
import { TableLoader } from "../../components/loader/Resusable/Table"; //"../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";
import { AboutUsRow } from "./aboutUsRow";

export const AboutUsListComponent = ({
  aboutUsDetail,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
  fetchAboutUsRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  viewUser,
  onExpand,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <>
            {userDetail && userDetail == "Web" ? (
              <table width="100%" cellSpacing="0">
                <thead className="pad-top">
                  <tr>
                    <th>Picture </th>
                    <th style={{ width: "auto" }}>
                      Name{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "firstName"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("firstName", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "firstName"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("firstName", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th style={{ width: "auto" }}>
                      Designation{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "designation"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("designation", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "designation"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("designation", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    <th style={{ width: "auto" }}>
                      Bio{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "bio"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("bio", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "bio"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("bio", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>

                    <th
                      style={{
                        width: "190px",
                        // width: "160px",
                        // paddingRight: "20%",
                        // display: "flex",
                        // justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          width: "302px",
                          // paddingRight: "20%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {aboutUsDetail && aboutUsDetail.length ? (
                    aboutUsDetail.map((IndividualAboutusDetail, index) => (
                      <AboutUsRow
                        key={index}
                        IndividualAboutusDetail={IndividualAboutusDetail}
                        fetchAboutUsRecords={fetchAboutUsRecords}
                        onEdit={() => onEdit(IndividualAboutusDetail)}
                        onDelete={() => onDelete(IndividualAboutusDetail._id)}
                        viewUser={() => viewUser(IndividualAboutusDetail)}
                        onExpand={() => onExpand(index)}
                      />
                    ))
                  ) : (
                    <tr className="no-tr">
                      <td
                        colSpan={5}
                        className={"text-center image-no-data-xs"}
                      >
                        {isFilterApplied ? (
                          <img src={noData} alt="" />
                        ) : (
                          <img src={noData} alt="" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              ""
            )}
          </>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
