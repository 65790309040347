import React from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { AppConfig, DEFAULT_DATE_FORMAT } from "../../../config";
import { BatchViewLoader } from "../../components/loader/Resusable/BatchView";
import calenderIcon from "../../../assets/img/calender.svg";
import fileMinusIcon from "../../../assets/img/file-minus.svg";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import locationIcon from "../../../assets/img/location.svg";
import tagIcon from "../../../assets/img/shape-copy.svg";
import windowMinusIcon from "../../../assets/img/full-minus.svg";
import dummyImage from "../../../assets/img/noimage.jpeg";

export const BatchDetailComponent = ({
  batchData,
  isLoading,
  isExporting,
  onEdit,
  onPrint,
  onExport,
}) => {
  const {
    batchNumber = "",
    state = "",
    productId = {},
    originId = {},
    itemCount = 0,
    availableUnits = 0,
    expiryDate = "",
  } = batchData;
  const {
    productName = "-",
    productNumber = "-",
    productImage = "",
  } = productId || {};
  const { originName = "-" } = originId || {};
  const filtersOption = [
    {
      label: "CSV",
      value: "csv",
    },
    {
      label: "PDF",
      value: "pdf",
    },
    {
      label: "Excel",
      value: "excel",
    },
  ];
  return (
    <div className="card mb-4 card-main flex-fill">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <>
          {" "}
          <div className="card-header py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book ">Batch Details View</h6>
              </div>
              <div className="col-md-9 filter-col-lg">
                <div className="side-col dp-right">
                  <Dropdown className="no-arrow dp-right">
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                      className={"filter-btn color-primary"}
                      disabled={isExporting || itemCount === availableUnits}
                    >
                      {isExporting ? (
                        <>
                          <i className="fa fa-spinner fa-spin test-spin"></i>
                          Exporting
                        </>
                      ) : (
                        <>
                          Export as
                          <img src={filterarrowIcon} alt="" />
                        </>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow dropdown-menu-right">
                      {filtersOption.map(({ label, value }, index) => (
                        <Dropdown.Item
                          as="div"
                          key={index}
                          onClick={() => onExport(value)}
                        >
                          {label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="side-col dp-right">
                  <button
                    className="btn btn-primary side-btn-transparent"
                    onClick={onPrint}
                    // disabled={!availableUnits}
                  >
                    {" "}
                    Print
                  </button>
                </div>
                <div className="side-col dp-right">
                  <button
                    className="btn btn-primary side-btn-transparent"
                    onClick={onEdit}
                  >
                    {" "}
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body card-table product_add">
            <div className="row">
              <div className="col-md-9">
                <div className="row mtblf-5">
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={tagIcon} alt="" />
                      <h5>Batch Number</h5>
                    </div>
                    <div className="description-col">
                      <h5>{batchNumber}</h5>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={fileMinusIcon} alt="" />
                      <h5>Product Number</h5>
                    </div>
                    <div className="description-col">
                      <h5>{productNumber}</h5>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={windowMinusIcon} alt="" />
                      <h5>Product Name</h5>
                    </div>
                    <div className="description-col">
                      <h5>{productName}</h5>
                    </div>
                  </div>
                </div>
                <div className="row mtblf-5">
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={calenderIcon} alt="" />
                      <h5>Unit Count</h5>
                    </div>
                    <div className="description-col">
                      <h5>{itemCount}</h5>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={calenderIcon} alt="" />
                      <h5>Available Count</h5>
                    </div>
                    <div className="description-col">
                      <h5>{availableUnits}</h5>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={calenderIcon} alt="" />
                      <h5>Expiry Date</h5>
                    </div>
                    <div className="description-col">
                      <h5>
                        {expiryDate
                          ? moment(expiryDate).format(DEFAULT_DATE_FORMAT)
                          : "-"}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="row mtblf-5">
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={locationIcon} alt="" />
                      <h5>Country</h5>
                    </div>
                    <div className="description-col">
                      <h5>{originName}</h5>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="heading-col">
                      <img src={locationIcon} alt="" />
                      <h5>State</h5>
                    </div>
                    <div className="description-col">
                      <h5>{state || "-"}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <img
                  className="batch-pr-img"
                  src={
                    productImage
                      ? productImage.startsWith("https://")
                        ? productImage
                        : `${AppConfig.FILES_ENDPOINT}${productImage}`
                      : dummyImage
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = dummyImage;
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
