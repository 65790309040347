/** @format */

import React from "react";
import { Dropdown } from "react-bootstrap";
import { AppConfig } from "../../../config";
import dummyclient from "../../../assets/img/noimage.jpeg";
import dropDots from "../../../assets/img/dropdown-dots.svg";
import listIcon from "../../../assets/img/list.svg";
import phoneIcon from "../../../assets/img/phone.svg";
import minusCircleIcon from "../../../assets/img/minus-circle.svg";
import minusCircleRightIcon from "../../../assets/img/minus-circle-light.svg";

const ClientRow = ({ clientData, blockClient, unBlockClient, viewClient }) => {
  const {
    store = {},
    phone = "",
    email = "",
    block = false,
    clientId = "",
  } = clientData;
  const {
    companyName = "",
    storeName = "",
    // brandName = "",
    website = "",
    country = "",
    storeLogo = "",
  } = store || {};
  return (
    <tr>
      <td
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        {storeLogo ? (
          <img
            className="list-view-img"
            src={
              storeLogo
                ? storeLogo.startsWith("https://")
                  ? storeLogo
                  : `${AppConfig.FILES_ENDPOINT}${storeLogo}`
                : dummyclient
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = dummyclient;
            }}
            alt=""
          />
        ) : (
          <div
            className="img_placeholder"
            style={{
              fontSize: "14px",
              marginLeft: "0",
              marginRight: "10px",
              width: "45px",
              height: "45px",
            }}
          >
            {companyName ? companyName.substring(0, 1) : ""}
          </div>
        )}
        {companyName || "N/A"}
      </td>
      {/* <td> {companyName || '-'} </td> */}
      <td> {clientId || "N/A"}</td>
      <td>
        {website ? (
          <a
            className="mailto"
            href={
              website.startsWith("http://") || website.startsWith("https://")
                ? website
                : `http://${website}`
            }
            target={"_blank"}
            rel="noopener noreferrer"
          >
            {website}
          </a>
        ) : (
          "N/A"
        )}
      </td>
      <td> {country || "N/A"} </td>
      <td>
        {email ? (
          <a className="mailto" href={`mailto:${email}`}>
            {email}
          </a>
        ) : (
          "N/A"
        )}
      </td>
      {/* <td> {phone || '-'} </td> */}
      <td>
        <Dropdown className="no-arrow-list">
          <Dropdown.Toggle className={"drop-btn"}>
            <img src={dropDots} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow dropdown-menu-right dropdown-dots-client">
            <Dropdown.Item as={"div"} onClick={viewClient}>
              <img className="img-lt" src={listIcon} alt="" /> View{" "}
            </Dropdown.Item>
            <Dropdown.Item
              disabled={!phone && !store?.phone}
              onClick={() => window.open(`tel:${phone ? phone : store?.phone}`)}
            >
              <img className="img-lt" src={phoneIcon} alt="" /> Contact{" "}
            </Dropdown.Item>
            {block ? (
              <Dropdown.Item onClick={unBlockClient}>
                <img className="img-lt" src={minusCircleIcon} alt="" /> Blocked{" "}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={blockClient}>
                <img className="img-lt" src={minusCircleRightIcon} alt="" />{" "}
                Block{" "}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {/* <img src={dropDots} alt='' /> */}
      </td>
    </tr>
  );
};

export default ClientRow;
