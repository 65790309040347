import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import countryStateData from "../../../common/countryState.json";
import { AppConfig } from "../../../config";
import ReactCountryFlag from "react-country-flag";
import globeIcon from "../../../assets/img/globeIcon.svg";
mapboxgl.accessToken = AppConfig.MAPBOX_ACCESS_TOKEN;

export const LocationChartComponent = ({
  activeLocations,
  allActiveLocations,
  maximumActiveUser,
}) => {
  let mapContainer = useRef(null);
  let features = allActiveLocations.map((ele) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: ele.coordinates,
    },
    properties: {
      title: "Mapbox",
      activeUsers: 1,
      // ele.count,
      iconSize: [60, 60],
    },
  }));
  let geojson = {
    type: "FeatureCollection",
    features,
  };

  useEffect(() => {
    // const map = new mapboxgl.Map({
    //   container: mapContainer,
    //   style: 'mapbox://styles/mapbox/streets-v11',
    //   zoom: 1,
    // });
    // let legend = document.getElementById('legend');
    // var layers = [
    //   '0-10',
    //   '10-20',
    //   '20-50',
    //   '50-100',
    //   '100-200',
    //   '200-500',
    //   '500-1000',
    //   '1000+',
    // ];
    // var colors = [
    //   '#FFEDA0',
    //   '#FED976',
    //   '#FEB24C',
    //   '#FD8D3C',
    //   '#FC4E2A',
    //   '#E31A1C',
    //   '#BD0026',
    //   '#800026',
    // ];
    // for (let i = 0; i < layers.length; i++) {
    //   var layer = layers[i];
    //   var color = colors[i];
    //   var item = document.createElement('div');
    //   var key = document.createElement('span');
    //   key.className = 'legend-key';
    //   key.style.backgroundColor = color;

    //   var value = document.createElement('span');
    //   value.innerHTML = layer;
    //   item.appendChild(key);
    //   item.appendChild(value);
    //   legend.appendChild(item);
    // }

    // new mapboxgl.featureLayer().setGeoJSON(geojson).addTo(map);
    // map.on('load', function() {
    //   map.resize();
    //   map.loadImage(
    //     'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
    //     // Add an image to use as a custom marker
    //     function(error, image) {
    //       if (error) throw error;
    //       map.addImage('custom-marker', image);

    //       map.addSource('places', {
    //         type: 'geojson',
    //         data: geojson,
    //       });

    //       // Add a layer showing the places.
    //       map.addLayer({
    //         id: 'places',
    //         type: 'symbol',
    //         source: 'places',
    //         layout: {
    //           'icon-image': 'custom-marker',
    //           'icon-allow-overlap': true,
    //         },
    //       });
    //     },
    //   );
    // });
    // if (allActiveLocations && allActiveLocations.length) {
    // mapContainer.current = null;
    var map = new mapboxgl.Map({
      container: mapContainer,
      zoom: 0.6,
      center: [20, 20],
      style: "mapbox://styles/testchapter/ckh68w3570l5919mlivoz6x23",
      // 'mapbox://styles/testchapter/ckgxoje2n0w2319o5lr5ekz9q',
      // 'mapbox://styles/testchapter/ckgxh9n6k2r6o19lhoe8h7575',
      // 'mapbox://styles/mapbox/light-v10',
    });
    // map.addControl(new mapboxgl.NavigationControl());

    // add markers to map
    let max = maximumActiveUser;

    geojson.features.forEach(function (marker) {
      let min = marker.properties.activeUsers;
      let percentage = (min / max) * 100;
      let heightWidth = Math.round((percentage / 100) * 30 + 10);

      // create a HTML element for each feature
      var el = document.createElement("div");
      el.className = "custom-marker";
      el.style.backgroundColor =
        marker.properties.activeUsers > 50 ? "#0f51a1" : "#f89c41";
      // 'url(https://placekitten.com/g/' +
      // marker.properties.iconSize.join('/') +
      // '/)';

      el.style.width = `${heightWidth}px`;
      // el.style.boxShadow =
      //   marker.properties.activeUsers > 50
      //     ? '0px 0px 9px 4px #0f51a1'
      //     : '0px 0px 9px 4px #f89c41';
      // `${Math.sqrt(marker.properties.activeUsers) + 20}px`;
      // marker.properties.iconSize[0] + 'px';
      el.style.height = `${heightWidth}px`;
      // `${Math.sqrt(marker.properties.activeUsers) + 20}px`;
      //  marker.properties.iconSize[1] + 'px';
      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(map);
    });
    // eslint-disable-next-line
  }, []);
  const DEFAULT_CDN_URL =
    "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/";
  const DEFAULT_CDN_SUFFIX = "svg";

  const getCountryFlag = (countryCode) => {
    var result = countryStateData.filter((x) => x.code3 === countryCode);
    return result[0]?.code2;
  };
  return (
    <div className={"row mb-2"}>
      <div className={"col-md-9"}>
        <div
          ref={(el) => (mapContainer = el)}
          className="mapContainer geo-chart"
        />
        {/* <div className='map-overlay' id={'legend'}></div> */}
      </div>
      <div className={"col-md-3 align-self-center"}>
        <div id="legend" className="mapss-overlay">
          {activeLocations.map(({ country, code, percentage }, index) => {
            return (
              <div
                className={"mb-1 active-country d-flex align-items-center"}
                key={index}
              >
                <span
                  className={"legend-key d-flex align-items-center mr-auto"}
                >
                  {code ? (
                    <ReactCountryFlag
                      countryCode={getCountryFlag(code)}
                      svg
                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                      cdnSuffix="svg"
                      style={{
                        width: "26px",
                        height: "26px",
                        borderRadius: "50px",
                        marginRight: "14px",
                      }}
                    />
                  ) : (
                    <div className="globeHolder">
                      <img src={globeIcon} alt="" />
                    </div>
                  )}
                  {country ? country : "Other"}
                </span>
                <span className={"legend-value"}>
                  {Math.round(percentage).toFixed(0)}%
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* <div className={'col-md-3'}>
        <ul>
          <li>Coffee</li>
          <li>Tea</li>
          <li>Milk</li>
        </ul>
      </div> */}
    </div>
    // <Chart
    //   width={'900px'}
    //   height={'500px'}
    //   chartType='GeoChart'
    //   data={[
    //     ['Country', 'Users'],
    //     ['Japan', 100],
    //     ['Australia', 32],
    //     ['India', 12],
    //     ['Germany', 15],
    //     ['Ireland', 25],
    //   ]}
    //   options={{
    //     displayMode: 'markers',
    //     colorAxis: { colors: ['#2740bf', 'black', '#f38d42'] },
    //   }}
    //   // Note: you will need to get a mapsApiKey for your project.
    //   // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
    //   mapsApiKey='AIzaSyCGOKZr1IFG0XwzjCfu59h9LAxynt-IPbg'
    //   rootProps={{ 'data-testid': '1' }}
    // />
  );
};
