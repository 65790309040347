import React from "react";
import { Button, Modal } from "react-bootstrap";
import { InputTextField, SubmitButton } from "../forms";
import AsyncSelect from "react-select/async";
import closeButton from "../../../assets/img/closeButton.svg";
import { ApiHelper } from "../../../helpers";

export default function AddAddressModal({
  modalOpen,
  handleClose,
  address,
  addressErrors,
  onChange,
  onSelectOption,
  allCountry,
  statesOption,
  submitAddress,
  isAddressUpdating,
  isAddressEdit,
}) {
  console.log(address);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
  };
  const loadStateData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = statesOption.filter(
        (state) =>
          state.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  const loadCountryData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  return (
    <Modal className="customClientModal" show={modalOpen} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>{isAddressEdit ? "Edit" : "Add"} Address</Modal.Title>
        <Button className="p-0" variant="" onClick={handleClose}>
          <img src={closeButton} />
        </Button>
      </Modal.Header>
      <Modal.Body className="pb-0">
        <form className="user_add m-0 addPlanform product_add">
          <div className="row">
            <div className="col-md-12">
              <InputTextField
                id="address"
                label={"Address Label*"}
                className={"form-control-user"}
                name="addressType"
                value={address?.addressType}
                onChange={onChange}
                errorMessage={
                  addressErrors && addressErrors.addressType
                    ? addressErrors.addressType
                    : ""
                }
                maxLength={30}
                placeholder={
                  "Enter address label (will be used to identify the address type"
                }
                disabled={isAddressEdit}
              />
            </div>
            <div className="col-md-4">
              <InputTextField
                id="address"
                label={"Address Line 1*"}
                className={"form-control-user"}
                name="addressLine1"
                value={address?.addressLine1}
                onChange={onChange}
                errorMessage={
                  addressErrors && addressErrors.addressLine1
                    ? addressErrors.addressLine1
                    : ""
                }
                maxLength={30}
                placeholder={"Enter address"}
              />
            </div>
            <div className="col-md-4">
              <InputTextField
                id="address"
                label={"Address Line 2*"}
                className={"form-control-user"}
                name="addressLine2"
                value={address?.addressLine2}
                onChange={onChange}
                errorMessage={
                  addressErrors && addressErrors.addressLine2
                    ? addressErrors.addressLine2
                    : ""
                }
                maxLength={30}
                placeholder={"Enter address"}
              />
            </div>
            <div className="col-md-4">
              <label
                htmlFor=""
                className="form-control-label form-control-user"
              >
                State*
              </label>
              <AsyncSelect
                loadOptions={loadStateData}
                placeholder={"Select"}
                styles={customStyles}
                maxMenuHeight={200}
                value={{ label: address?.state, value: address?.state }}
                defaultOptions={statesOption}
                onChange={(selectedOption) =>
                  onSelectOption(selectedOption, "state")
                }
                noOptionsMessage={() => {
                  return address?.country
                    ? "No states found"
                    : "First select country";
                }}
              />
              <div className="text-danger">
                {addressErrors && addressErrors.state}
              </div>
            </div>
            <div className="col-md-4">
              <label
                htmlFor=""
                className="form-control-label form-control-user"
              >
                Country*
              </label>
              <AsyncSelect
                loadOptions={loadCountryData}
                placeholder={"Select"}
                styles={customStyles}
                maxMenuHeight={200}
                defaultOptions={allCountry}
                value={{ label: address?.country, value: address?.country }}
                onChange={(selectedOption) =>
                  onSelectOption(selectedOption, "country")
                }
                noOptionsMessage={() =>
                  "No option found related to your search"
                }
              />
              <div className="text-danger">
                {addressErrors && addressErrors.country}
              </div>
            </div>
            <div className="col-md-4">
              <InputTextField
                id="address"
                label={"Post Code*"}
                className={"form-control-user"}
                name="postCode"
                value={address?.postCode}
                onChange={onChange}
                errorMessage={
                  addressErrors && addressErrors.postCode
                    ? addressErrors.postCode
                    : ""
                }
                maxLength={6}
                placeholder={"Enter Post Code"}
              />
            </div>
            <div className="col-md-12 text-center mb-0">
              <SubmitButton
                className={"submit-btn"}
                text={isAddressEdit ? "Update" : "Add"}
                isLoading={isAddressUpdating}
                onSubmit={submitAddress}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
