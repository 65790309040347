/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import "./batches.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import { ReportHeader } from "./ReportHeader";
import { ReportCard } from "./ReportCard";
import { UsersTable } from "./UsersTable";
import { GraphLoader } from "../../components/loader/Dashboard/GraphLoader";
import { UsersGraphOSType } from "./UsersGraphOSType";
import { UsersGraphLocation } from "./UsersGraphLocation";
import ReactApexChart from "react-apexcharts";
import FileSaver from "file-saver";
import moment from "moment";
import * as qs from "query-string";

class ReportUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: "Today",
      customStartDate: new Date(),
      customEndDate: new Date(),
      cardsLoading: true,
      tableLoading: true,
      osGraphLoading: true,
      locationGraphLoading: true,
      osGraphData: null,
      locationGraphData: null,
      cardsData: null,
      tableData: null,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      topScannedProductDataSet: {},
      clientName: "PHL",
      clientList: [],

      series: [13, 7, 10, 5],
      options: {
        labels: ["iPhone ", "Samsung ", "Google Pixel", "Other"],
        colors: ["#0F51A1", "#F79C41", "#D80027", "#00CD98"],
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
          fontSize: "14px",
          fontWeight: 400,
        },
        markers: {
          width: 4,
          height: 4,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0,
        },
      },
    };
  }

  dateRangeCallback = async (start, end, label) => {
    await this.setState({
      selectedTime: "Custom",
      customStartDate: new Date(start),
      customEndDate: new Date(end),
    });
    this.fetchData();
  };

  onPageChanged = (page) => {
    console.log(page);
    const { selectedTime, customStartDate, customEndDate, limit, currentPage } =
      this.state;
    if (page !== currentPage) {
      this.setState({
        currentPage: page,
      });
      let skip = limit * (page - 1);
      const payload = {
        selectedTime,
        customStartDate,
        customEndDate,
        skip,
        limit,
      };
      this.fetchTable(payload);
    }
  };

  changeSelectedTime = async (val) => {
    await this.setState({
      selectedTime: val,
    });

    switch (val) {
      case "Today":
        this.setState({
          customStartDate: new Date(),
          customEndDate: new Date(),
        });
        break;
      case "Yesterday":
        this.setState({
          customStartDate: new Date(moment().subtract(1, "day")),
          customEndDate: new Date(moment().subtract(1, "day")),
        });
        break;
      case "Week":
        this.setState({
          customStartDate: new Date(moment().startOf("week")),
          customEndDate: new Date(moment().endOf("week")),
        });
        break;
      case "Month":
        this.setState({
          customStartDate: new Date(moment().startOf("month")),
          customEndDate: new Date(moment().endOf("month")),
        });
        break;
      case "Year":
        this.setState({
          customStartDate: new Date(moment().startOf("year")),
          customEndDate: new Date(moment().endOf("year")),
        });
        break;
      default:
    }

    this.fetchData();
  };

  componentDidMount = () => {
    this.handleQueryParams();
  };

  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    console.log("query", query);
    this.setState(
      {
        isFilterApplied: query.sort || query.search ? true : false,
        clientName: query.clientName || "PHL",
      },
      () => {
        this.fetchData();
        this.fetchClientList();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  setFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };
  fetchData = async () => {
    const {
      selectedTime,
      customStartDate,
      customEndDate,
      limit,
      currentPage,
      clientName,
    } = this.state;
    let skip = limit * (currentPage - 1);
    const payload = {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      limit,
      clientName,
    };
    this.fetchCards(payload);
    this.fetchTable(payload);
    this.fetchOSGraph(payload);
    this.fetchLocationGraph(payload);
  };

  fetchCards = async (data) => {
    this.setState({
      cardsLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_USERS_CARDS.service,
      ApiRoutes.REPORTS_USERS_CARDS.url,
      ApiRoutes.REPORTS_USERS_CARDS.method,
      ApiRoutes.REPORTS_USERS_CARDS.authenticate,
      data,
      undefined
    );

    this.setState({
      cardsLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        cardsData: response.data.data,
      });
    }
  };

  fetchTable = async (data) => {
    this.setState({
      tableLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_USERS_TABLE.service,
      ApiRoutes.REPORTS_USERS_TABLE.url,
      ApiRoutes.REPORTS_USERS_TABLE.method,
      ApiRoutes.REPORTS_USERS_TABLE.authenticate,
      data,
      undefined
    );

    this.setState({
      tableLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        tableData: response.data.data.users,
        totalRecords: response.data.data.totalRecords,
      });
    }
  };

  fetchOSGraph = async (data) => {
    this.setState({
      osGraphLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_USERS_GRAPH_OS.service,
      ApiRoutes.REPORTS_USERS_GRAPH_OS.url,
      ApiRoutes.REPORTS_USERS_GRAPH_OS.method,
      ApiRoutes.REPORTS_USERS_GRAPH_OS.authenticate,
      data,
      undefined
    );

    this.setState({
      osGraphLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        osGraphData: response.data.data,
      });
    }
  };

  fetchLocationGraph = async (data) => {
    this.setState({
      locationGraphLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_USERS_GRAPH_LOCATION.service,
      ApiRoutes.REPORTS_USERS_GRAPH_LOCATION.url,
      ApiRoutes.REPORTS_USERS_GRAPH_LOCATION.method,
      ApiRoutes.REPORTS_USERS_GRAPH_LOCATION.authenticate,
      data,
      undefined
    );

    this.setState({
      locationGraphLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        locationGraphData: response.data.data,
      });
    }
  };

  //  10/06/22
  onExport = async () => {
    const {
      selectedTime,
      customStartDate,
      customEndDate,
      clientName,
      skip,
      limit,
    } = this.state;
    let data = {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      limit,
      clientName,
    };
    let type = "csv";
    // if (selectedTime === 'Custom') data = { selectedTime, customStartDate, customEndDate }
    // if (selectedTime === 'Custom') data = { selectedTime, customStartDate, customEndDate }

    let blobType, extension;
    if (data) {
      this.setState({
        isBatchExporting: true,
      });
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_REPORTS_USERS_TABLE.service,
      ApiRoutes.EXPORT_REPORTS_USERS_TABLE.url,
      ApiRoutes.EXPORT_REPORTS_USERS_TABLE.method,
      ApiRoutes.EXPORT_REPORTS_USERS_TABLE.authenticate,
      data,
      undefined,
      "blob"
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: "",
    });
    if (response && response.isError) {
    } else {
      switch (type) {
        case "pdf":
          blobType = "application/pdf";
          extension = "pdf";
          break;
        case "excel":
          blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          extension = "xlsx";
          break;
        case "csv":
          blobType = "application/csv";
          extension = "csv";
          break;
        default:
          break;
      }
      let blob = new Blob([response.data], {
        type: blobType,
      });
      FileSaver.saveAs(
        blob,
        `Users.${extension}`
        // `${moment().format('YYYY_MM_DD')}_${batchId}.${extension}`,
      );
    }
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "PHL",
            value: "PHL",
          },
          ...data,
        ],
      });
    }
  };
  render() {
    const { clientList, clientName } = this.state;

    const {
      topScannedProductDataSet,
      props: {
        location: { pathname },
      },
    } = this;

    const thead = ["User Name", "User Email", "Created On"];

    const activePerc = parseInt(this.state.cardsData?.activePerc).toFixed(0);
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Reports</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="row">
          <ReportHeader
            clientName={clientName}
            clientList={clientList}
            setFilter={this.setFilter}
            selectedTime={this.state.selectedTime}
            dateRangeCallback={this.dateRangeCallback}
            changeSelectedTime={this.changeSelectedTime}
            onExport={this.onExport}
            customStartDate={this.state.customStartDate}
            customEndDate={this.state.customEndDate}
          />

          <ReportCard
            loading={this.state.cardsLoading}
            title="Total Users"
            total={this.state.cardsData?.total}
            perc={this.state.cardsData?.addedCalculation}
            // prevString = {''}
            prevString={this.state.cardsData?.dateString}
            size="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4 mt-md-0"
          />

          <ReportCard
            loading={this.state.cardsLoading}
            title="Active Users"
            total={activePerc + "%"}
            perc={this.state.cardsData?.addedCalculation}
            prevString={this.state.cardsData?.dateString}
            size="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4 mt-md-0"
          />

          <ReportCard
            loading={this.state.cardsLoading}
            title="Users Increased"
            total={this.state.cardsData?.added}
            perc={this.state.cardsData?.addedCalculation}
            prevString={this.state.cardsData?.dateString}
            size="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4 mt-md-0"
          />

          <div className="col-md-12 mt-2">
            <div className="row">
              <div className="col-md-8 mt-2">
                <div className="overflow-auto">
                  <UsersTable
                    loading={this.state.tableLoading}
                    thead={thead}
                    tbody={this.state.tableData}
                  />
                </div>
                {!this.state.tableLoading ? (
                  <div className="w-100">
                    <div className="float-right mt-2">
                      {this.state.totalRecords > this.state.limit ? (
                        <div className="float-right">
                          <Pagination
                            totalRecords={this.state.totalRecords}
                            currentPage={this.state.currentPage}
                            pageLimit={this.state.limit}
                            onPageChanged={this.onPageChanged}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="col-md-4 mt-2">
                <div className="card mt-2 shadow-border graph-card flex-fill deviceMetricsCard">
                  <div
                    className="card-header py-0 align-items-center justify-content-between border-0 mt-3"
                    style={{ height: "auto", marginBottom: ".5em" }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="mt-0 font-book mb-0">
                          Devices and OS metrics
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body py-3 overflow-auto">
                    {this.state.osGraphLoading ? (
                      <GraphLoader />
                    ) : (
                      <div>
                        {this.state.locationGraphData?.labels?.length ? (
                          <UsersGraphOSType dataset={this.state.osGraphData} />
                        ) : (
                          ""
                        )}
                        {/* <div class="chart-wrap">
                          <div id="chart">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series}
                              legend={this.state.legend}
                              markers={this.state.markers}
                              labelsText={this.state.labelsText}
                              type="donut"
                              width={380}
                            />
                          </div>
                        </div> */}
                      </div>
                    )}
                  </div>
                  <div>
                    <div
                      className="card-header py-0  align-items-center justify-content-between border-0"
                      style={{ height: "auto", marginBottom: ".5em" }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <h6 className="mt-0 font-book mb-0">Places</h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-body overflow-auto">
                      {this.state.locationGraphLoading ? (
                        <GraphLoader />
                      ) : (
                        <div>
                          {this.state.locationGraphData?.labels?.length ? (
                            <UsersGraphLocation
                              dataset={this.state.locationGraphData}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      {/* <UsersGraphLocation
                      dataset={this.state.locationGraphData}
                    /> */}
                    </div>
                  </div>
                </div>
                {/* <div className="card mb-4 shadow-border graph-card flex-fill mt-4">
                  <div className="card-header py-4550  align-items-center justify-content-between">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="mt-2 font-book ">Places</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body overflow-auto">
                    {this.state.locationGraphLoading ? (
                      <GraphLoader />
                    ) : (
                      <div>
                        {this.state.locationGraphData?.labels?.length ? (
                          <UsersGraphLocation
                            dataset={this.state.locationGraphData}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    <UsersGraphLocation
                      dataset={this.state.locationGraphData}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportUsers;
