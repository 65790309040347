import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs.jsx";
import Pagination from "../../components/pagination/index.jsx";
import { PlanListComponent } from "../../components/PlanListComponent/PlanListComponent.jsx";
import { AddPlanFormComponent } from "../../components/addPlanFormComponent/AddPlanFormComponent.jsx";
import { ApiRoutes, AppRoutes } from "../../../config/index.js";
import { ApiHelper } from "../../../helpers/index.js";
import { toast } from "react-toastify";
import { addPlan } from "../../../validators/addPlan.js";
import * as qs from "query-string";
import { AddCustomFormComponent } from "../../components/addCustomFormComponent/AddCustomFormComponent.jsx";

class AddCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planData: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      deleteError: false,
      sortBy: "createdAt",
      order: "desc",
      planType: "",
      planDuration: "",
      name: "",
      description: "",
      price: "",
      users: "",
      BrandAndCertificate: null,
      printCodes: "",
      domainName: null,
      email: "",
      errors: null,
      isEditMode: false,
      planId: "",
      clientList: "",
      clientId: "",
      clientName: "",
    };
  }

  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ planId: id, isEditMode: true }, () => {
        this.getPlansById();
      });
    }
    this.fetchClientList();
  };

  addPlan = async (payload) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SUBSCRIPTION.service,
      ApiRoutes.ADD_SUBSCRIPTION.url,
      ApiRoutes.ADD_SUBSCRIPTION.method,
      ApiRoutes.ADD_SUBSCRIPTION.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.data.message);
      this.props.history.push(AppRoutes.PLANS);
    }
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        clientList: data,
      });
    }
  };

  getPlansById = async () => {
    const { planId, clientName } = this.state;

    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTION_ID.service,
      ApiRoutes.GET_SUBSCRIPTION_ID.url.replace(":id", planId),
      ApiRoutes.GET_SUBSCRIPTION_ID.method,
      ApiRoutes.GET_SUBSCRIPTION_ID.authenticate,
      // data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data: planData } = response.data;
      const {
        planType,
        name,
        description,
        price,
        users,
        BrandAndCertificate,
        printCodes,
        domainName,
        email,
        clientId,
        clientName,
      } = planData;
      this.setState({
        planType:
          {
            label: planType,
            value: planType,
          } || "",
        name: name || "",
        description: description || "",
        price: price ? price : "" || "",
        users: users ? parseInt(users) : "" || "",
        BrandAndCertificate:
          {
            label: BrandAndCertificate ? "Yes" : "No",
            value: BrandAndCertificate ? 1 : 2,
          } || "",
        printCodes: printCodes ? parseInt(printCodes) : "" || "",
        domainName:
          {
            label: domainName ? "Yes" : "No",
            value: domainName ? 1 : 2,
          } || "",
        email: email || "",
        clientId: { label: clientId?.first_name, value: clientId?.id },
      });
    }
  };

  updatePlan = async (payload) => {
    const { planId } = this.state;

    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EDIT_SUBSCRIPTION.service,
      ApiRoutes.EDIT_SUBSCRIPTION.url.replace(":id", planId),
      ApiRoutes.EDIT_SUBSCRIPTION.method,
      ApiRoutes.EDIT_SUBSCRIPTION.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.data.message);
      this.props.history.push(AppRoutes.PLANS);
    }
  };

  handleSubmit = async (event) => {
    event && event.preventDefault();
    const {
      planType,
      // planDuration,
      name,
      description,
      price,
      users,
      BrandAndCertificate,
      printCodes,
      domainName,
      email,
      planId,
      clientId,
    } = this.state;
    let payload = {};
    if (planType.value === "Custom") {
      payload = {
        planType: (planType && planType.value) || "",
        name: name || "",
        description: description || "",
        price: price ? price : "" || "",
        users: users ? parseInt(users) : "" || "",
        BrandAndCertificate:
          (BrandAndCertificate && BrandAndCertificate.value) || "",
        printCodes: printCodes ? parseInt(printCodes) : "" || "",
        domainName: (domainName && domainName.value) || "",
        clientId: (clientId && clientId.value) || "",
      };
    } else {
      payload = {
        planType: (planType && planType.value) || "",
        name: name || "",
        description: description || "",
        price: price ? price : "" || "",
        users: users ? parseInt(users) : "" || "",
        BrandAndCertificate:
          (BrandAndCertificate && BrandAndCertificate.value) || "",
        printCodes: printCodes ? parseInt(printCodes) : "" || "",
        domainName: (domainName && domainName.value) || "",
      };
    }

    let data = {
      planType: (planType && planType.value) || "",
      // planDuration: planDuration,
      name: name || "",
      description: description || "",
      email: email || "",
    };
    let finalData = planType.value === "Contact" ? data : payload;
    const { isValid, errors } = addPlan(finalData);
    if (isValid) {
      finalData.BrandAndCertificate =
        finalData.BrandAndCertificate === 1 ? true : false;
      finalData.domainName = finalData.domainName === 1 ? true : false;
      if (planId) {
        this.updatePlan(finalData);
      } else {
        this.addPlan(finalData);
      }
    } else {
      this.setState({ errors: errors });
      return;
    }
  };
  onSelect = (label, name) => {
    if (label) {
      this.setState({
        [name]: label,
      });
    }
  };
  handleChange = (event) => {
    var regex = /^\d+\.\d{0,2}$/;
    const {
      target: { type, value, name, checked },
    } = event;

    if (name === "price" && isNaN(value)) {
      return;
    }
    if (
      (name === "users" && isNaN(value)) ||
      (name === "users" && regex.test(value))
    ) {
      return;
    }
    if (
      (name === "printCodes" && isNaN(value)) ||
      (name === "printCodes" && regex.test(value))
    ) {
      return;
    }
    this.setState({
      [name]: type === "checkbox" ? checked : value.trimLeft(),
      // errors: { ...planData.errors, [name]: "" },
    });
  };

  render() {
    const {
      errors,
      name,
      price,
      email,
      description,
      users,
      printCodes,
      domainName,
      BrandAndCertificate,
      planType,
      isAdding,
      isLoading,
      isEditMode,
      clientList,
      clientId,
    } = this.state;

    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <>
        <div className="container-fluid fluid-pd-2">
          <h1 className="h3-head-mob d-md-none">
            {isEditMode ? "Edit Plan" : "Add Plan"}
          </h1>
          <Breadcrumbs pathname={pathname} />
          <div className="card card-main card-pagination addplanCard">
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-3 head-cat-col-lg">
                  <h6 className="font-book">
                    {isEditMode ? "Edit Plan" : "Add Plan"}
                  </h6>
                </div>
              </div>
            </div>
            <AddCustomFormComponent
              isLoading={isLoading}
              handleSubmit={this.handleSubmit}
              isEditMode={isEditMode}
              name={name}
              price={price}
              email={email}
              description={description}
              domainName={domainName}
              BrandAndCertificate={BrandAndCertificate}
              users={users}
              planType={planType}
              printCodes={printCodes}
              onSelect={this.onSelect}
              errors={errors}
              onChange={this.handleChange}
              isAdding={isAdding}
              clientList={clientList}
              clientId={clientId}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AddCustom;
