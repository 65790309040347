import Validator from 'js-object-validation';
import { message } from '../common';
import { replaceAll } from '../helpers';

export const videoValidator = data => {
  const validations = {
    name: {
      required: true,
      maxlength: 30,
    },
    link: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    name: {
      required: message.Required.replace(':item', 'Name'),
      maxlength: replaceAll(message.MaxLengthError, {
        ':item': 'Name',
        ':length': 30,
      }),
    },
    link: {
      required: message.Required.replace(':item', 'Link'),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
