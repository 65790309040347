import React from "react";
import Skeleton from "react-skeleton-loader";

export const BillingLoader = () => (
  <>
    <div className={"innerCard"}>
      <div
        className="innerCard-header d-flex align-items-center justify-content-between"
        style={{ padding: "15px 20px 12px" }}
      >
        <h3>
          <Skeleton height={"24px"} width={"200px"} color="#e5e9ef" />
        </h3>
        <div className="d-flex align-items-center justify-content-between">
          <Skeleton height={"30px"} width={"106px"} color="#e5e9ef" />
        </div>
      </div>
      <div className="innerCard-body">
        <div className="row" style={{ marginBottom: "30px" }}>
          <div className="col-md-5 detailsCard">
            <div className="d-flex align-items-center">
              <div style={{ marginRight: "12px" }}>
                <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
              </div>
              <div>
                <h4 className="mb-0">
                  <Skeleton height={"20px"} width={"80px"} color="#e5e9ef" />
                </h4>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "150px" }}
                >
                  <h3>
                    <Skeleton height={"20px"} width={"110px"} color="#e5e9ef" />
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 detailsCard">
            <div className="d-flex align-items-center">
              <div style={{ marginRight: "12px" }}>
                <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
              </div>
              <div>
                <h4 className="mb-0">
                  <Skeleton height={"20px"} width={"80px"} color="#e5e9ef" />
                </h4>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "150px" }}
                >
                  <h3>
                    <Skeleton height={"20px"} width={"110px"} color="#e5e9ef" />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ marginBottom: "30px" }}>
          <div className="col-md-5 detailsCard">
            <div className="d-flex align-items-center">
              <div style={{ marginRight: "12px" }}>
                <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
              </div>
              <div>
                <h4 className="mb-0">
                  <Skeleton height={"20px"} width={"80px"} color="#e5e9ef" />
                </h4>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "150px" }}
                >
                  <h3>
                    <Skeleton height={"20px"} width={"110px"} color="#e5e9ef" />
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 detailsCard">
            <div className="d-flex align-items-center">
              <div style={{ marginRight: "12px" }}>
                <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
              </div>
              <div>
                <h4 className="mb-0">
                  <Skeleton height={"20px"} width={"80px"} color="#e5e9ef" />
                </h4>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "150px" }}
                >
                  <h3>
                    <Skeleton height={"20px"} width={"110px"} color="#e5e9ef" />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-5 detailsCard">
            <div className="d-flex align-items-center">
              <div style={{ marginRight: "12px" }}>
                <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
              </div>
              <div>
                <h4 className="mb-0">
                  <Skeleton height={"20px"} width={"80px"} color="#e5e9ef" />
                </h4>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "150px" }}
                >
                  <h3>
                    <Skeleton height={"20px"} width={"110px"} color="#e5e9ef" />
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 detailsCard">
            <div className="d-flex align-items-center">
              <div style={{ marginRight: "12px" }}>
                <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
              </div>
              <div>
                <h4 className="mb-0">
                  <Skeleton height={"20px"} width={"80px"} color="#e5e9ef" />
                </h4>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ width: "150px" }}
                >
                  <h3>
                    <Skeleton height={"20px"} width={"110px"} color="#e5e9ef" />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>

  //   <>
  //     <div className="col-md-12">
  //       <div className="innerHolder">
  //         <div className="billingInviceCard active">
  //           <div className="CardHeader">
  //             <h4>
  //               <Skeleton height={"24px"} width={"200px"} color="#e5e9ef" />
  //             </h4>
  //             <div className="rightSection">
  //               <div className="buttonHolder">
  //                 <Skeleton height={"40px"} width={"120px"} color="#e5e9ef" />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="CardBody">
  //             <div className="row">
  //               <div className="col-md-5">
  //                 <ul className="invoice_list">
  //                   <li>
  //                     <div className="image_holder">
  //                       <Skeleton
  //                         height={"40px"}
  //                         width={"40px"}
  //                         color="#e5e9ef"
  //                       />
  //                     </div>
  //                     <div className="detail text-capitalize">
  //                       <span>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"80px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </span>
  //                       <p>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"110px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </p>
  //                     </div>
  //                   </li>
  //                   <li>
  //                     <div className="image_holder">
  //                       <Skeleton
  //                         height={"40px"}
  //                         width={"40px"}
  //                         color="#e5e9ef"
  //                       />
  //                     </div>
  //                     <div className="detail text-capitalize">
  //                       <span>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"80px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </span>
  //                       <p>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"110px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </p>
  //                     </div>
  //                   </li>
  //                   <li>
  //                     <div className="image_holder">
  //                       <Skeleton
  //                         height={"40px"}
  //                         width={"40px"}
  //                         color="#e5e9ef"
  //                       />
  //                     </div>
  //                     <div className="detail text-capitalize">
  //                       <span>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"80px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </span>
  //                       <p>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"110px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </p>
  //                     </div>
  //                   </li>
  //                 </ul>
  //               </div>
  //               <div className="col-md-7">
  //                 <ul className="invoice_list">
  //                   <li>
  //                     <div className="image_holder">
  //                       <Skeleton
  //                         height={"40px"}
  //                         width={"40px"}
  //                         color="#e5e9ef"
  //                       />
  //                     </div>
  //                     <div className="detail text-capitalize">
  //                       <span>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"80px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </span>
  //                       <p>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"110px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </p>
  //                     </div>
  //                   </li>
  //                   <li>
  //                     <div className="image_holder">
  //                       <Skeleton
  //                         height={"40px"}
  //                         width={"40px"}
  //                         color="#e5e9ef"
  //                       />
  //                     </div>
  //                     <div className="detail text-capitalize">
  //                       <span>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"80px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </span>
  //                       <p>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"110px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </p>
  //                     </div>
  //                   </li>
  //                   <li>
  //                     <div className="image_holder">
  //                       <Skeleton
  //                         height={"40px"}
  //                         width={"40px"}
  //                         color="#e5e9ef"
  //                       />
  //                     </div>
  //                     <div className="detail text-capitalize">
  //                       <span>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"80px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </span>
  //                       <p>
  //                         <Skeleton
  //                           height={"20px"}
  //                           width={"110px"}
  //                           color="#e5e9ef"
  //                         />
  //                       </p>
  //                     </div>
  //                   </li>
  //                 </ul>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
);
