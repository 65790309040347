import React from "react";
import Skeleton from "react-skeleton-loader";

export const CardLoader = () => (
  <>
    {" "}
    <div className='card shadow-border h-100 card-py-2'>
      <div className='card-body'>
        <div className='row no-gutters lf-mg align-items-center'>
          <div className='col-auto'>
            <Skeleton height={"60px"} width={"70px"} color ="#e5e9ef" />
          </div>
          <div className='col ml-100'>
            <div className='h5 mb-1 mt-1 card-number '></div>
            <div className='text-xs  text-secondary card-subhead mb-0'></div>
          </div>
        </div>
      </div>
    </div>
  </>
);
