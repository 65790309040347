/** @format */

import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import * as qs from "query-string";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../config";
import { ApiHelper } from "../../../helpers";
import Pagination from "../../components/pagination";
import { FilterComponent } from "../../components/filters";
import PHLContext from "../../../PHLContext";
import { SearchFilter } from "../../components/filters/searchFilter";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import pageDropdownIcon from "../../../assets/img/tag-bd.svg";
import mobSearchIcon from "../../../assets/img/tag-search-bd.svg";
import "./landing-page.css";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { InputTextField } from "../../components/forms";
import { AddLandingPageComponent } from "./addLandingPageComponent";
import { LandingPageListComponent } from "./landingPageListComponent";

let lat, long;

class LandingPage extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      partners: [],
      partner: {},
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      activeId: "",
      sortBy: "createdAt",
      order: "desc",
      search: "",
      newRecord: false,
      isFilterApplied: false,
      showAddForm: false,
      catOption: [],
      userDetail: "",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.setState(
      {
        userDetail: this.props.location.pathname.split("/")[2], //[3],
      },
      () => this.handleQueryParams()
    );

    this.handleQueryParams();
  };
  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    if (query && query.sort === "location") {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      lat = position.coords.latitude;
      long = position.coords.longitude;
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "createdAt",
        order: query.order
          ? query.order
          : // : query.sort === 'scan_id' || query.sort === 'created_at'
            // ? 'desc'
            "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
      },
      () => this.fetchPartnersRecords()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }, prevState) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
    const splitValue = this.props.location.pathname.split("/")[2]; //[3];
    if (prevState.userDetail !== splitValue) {
      this.setState({
        userDetail: splitValue,
      });
      setTimeout(() => {
        this.handleQueryParams();
      }, 500);
    }
  };

  fetchPartnersRecords = async () => {
    const { currentPage, sortBy, search, limit, newRecord, order, userDetail } =
      this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
    };
    // Function to check if a value is empty (undefined, null, or empty string)
    const isEmpty = (value) =>
      value === undefined || value === null || value === "";

    const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
      if (!isEmpty(value)) {
        acc[key] = value;
      }
      return acc;
    }, {});
    this.setState({
      isLoading: true,
    });
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }

    // const url = ApiRoutes.PARTNER_LIST.url + "?users=" + userDetail;
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.PARTNER_LIST.service,
      ApiRoutes.PARTNER_LIST.url,
      ApiRoutes.PARTNER_LIST.method,
      ApiRoutes.PARTNER_LIST.authenticate,
      { ...filteredData, order: data?.order == "desc" ? "DESC" : "ASC" },
      undefined,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isLoading: false,
      });
    } else {
      const datas = response.data;
      this.setState({
        partners: datas?.data?.sort((a, b) => {
          if (data?.sortBy === "name") {
            // Use localeCompare for string comparison
            return data?.order === "asc"
              ? a.name.localeCompare(b.name)
              : b.name.localeCompare(a.name);
          } else if (data?.sortBy === "email") {
            // Use localeCompare for string comparison
            return data?.order === "asc"
              ? a.email.localeCompare(b.email)
              : b.email.localeCompare(a.email);
          } else {
            return 0; // No sorting for other cases
          }
        }),
        newRecord: false,
        totalRecords: datas?.data?.length,
      });
      this.setState({
        isLoading: false,
      });
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  handleAddCancel = (event) => {
    this.setState({
      partner: {},
      showAddForm: false,
    });
    if (event) event.preventDefault();
    else this.fetchPartnersRecords();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  componentWillUnmount = () => {
    this.emitSearch.cancel();
  };

  openAddModal = () => {
    this.setState({
      showAddForm: true,
      // partners: this.state.partners,
    });
    // this.fetchCategoryList();
  };

  fetchCategoryList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FAQ_CAT.service,
      ApiRoutes.GET_FAQ_CAT.url,
      ApiRoutes.GET_FAQ_CAT.method,
      ApiRoutes.GET_FAQ_CAT.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      this.setState({
        catOption: data,
      });
      // setCatOptions(data);
    }
  };

  onEdit = (partner) => {
    // this.fetchCategoryList();
    this.setState({
      partner: partner,
      showAddForm: true,
    });
  };
  onDelete = async (id) => {
    const { value } = await SweetAlertComponent({
      title: "",
      text: `Are you sure, you want to delete?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.DELETE_PARTNER.service,
      ApiRoutes.DELETE_PARTNER.url,
      ApiRoutes.DELETE_PARTNER.method,
      ApiRoutes.DELETE_PARTNER.authenticate,
      undefined,
      { id: id }
    );
    this.setState({
      id: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
      });
      toast.success(response.messages[0]);
      await this.fetchPartnersRecords();
    }
  };

  render() {
    const {
      partners,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      order,
      search,
      catOption,
      userDetail,
    } = this.state;

    let allOptions = [];
    // catOption &&
    //   catOption.forEach((element) => {
    //     allOptions.push({ label: element.categoryName, value: element.id });
    //   });

    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filtersOption = [
      {
        label: "Name",
        value: "name",
      },
      {
        label: "Email",
        value: "email",
      },
      {
        label: "Country",
        value: "country",
      },
    ];

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Partner</h1>
        <Breadcrumbs pathname={pathname} />

        <div className="card card-pagination card-main">
          <div className="card-header py-7550 align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book "></h6>
              </div>
              <div className="col-md-10 mob-filter-data">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn-mob"}
                  >
                    <img src={pageDropdownIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                    {filtersOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.setFilter(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dropdown no-arrow dp-right">
                  <button
                    type="button"
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={mobSearchIcon} alt="" />
                  </button>
                </div>
              </div>

              <div className="col-md-9 filter-col-lg">
                <div className="no-arrow dp-right dropdown">
                  <Button className="pushButtonCZ" onClick={this.openAddModal}>
                    Add Partner
                  </Button>
                </div>
                <FilterComponent
                  options={filtersOption}
                  selectedFilter={sortBy}
                  onFilterChange={this.setFilter}
                />
                <SearchFilter
                  placeholder={"Search"}
                  search={search}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <LandingPageListComponent
            partners={partners}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            totalRecords={totalRecords}
            limit={limit}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            fetchPartnersRecords={this.fetchPartnersRecords}
            userDetail={userDetail}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
        <Modal
          show={this.state.showAddForm}
          onHide={this.handleAddCancel.bind(this)}
          size="lg"
          className="proAddCZ addFaq_modal"
          centered
        >
          <Modal.Header>
            <Modal.Title>Add Partner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddLandingPageComponent
              userDetail={userDetail}
              allOptions={allOptions}
              partner={this.state.partner}
              partners={this.state.partners}
              handleAddCancel={this.handleAddCancel}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default LandingPage;
