import Validator from "js-object-validation";
import { message } from "../common";

export const faqsValidator = (data) => {
  const validations = {
    priority: {
      required: true,
      numeric: true,
      minnumbers: 1,
    },
    question: {
      required: true,
    },
    answer: {
      required: true,
    },
    categoryId: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    priority: {
      required: message.Required.replace(":item", "Priority"),
      numeric: message.InvalidItemCount,
      minlength: message.MinItemCount.replace("Item count", "Priority"),
    },
    question: {
      required: message.Required.replace(":item", "Question"),
    },
    answer: {
      required: message.Required.replace(":item", "Answer"),
    },
    categoryId: {
      required: message.Required.replace(":item", "Category"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
