import React from "react";
import { SubmitButton } from "../forms";

export const AddCategoryComponent = ({
  categoryName,
  errors,
  isLoading,
  onChange,
  onSubmit,
  onCancel,
}) => {
  return (
    <form className="cat_add" onSubmit={onSubmit}>
      <div className="cat_head h-100">
        <div className="form-group">
          <label htmlFor="category_name" className="form-control-label">
            Enter Category Name
          </label>
          <textarea
            className="form-control form-control-user wt-resize"
            cols="50"
            id="category_name"
            aria-describedby="category_nameHelp"
            rows="4"
            name={"categoryName"}
            value={categoryName}
            onChange={onChange}
            maxLength={20}
          />
          {errors && errors.categoryName ? (
            <div className={"text-danger error-text-block_nouse mt-0"}>
              {errors.categoryName}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="cat_desc_btn">
          <div className="num_head">
            <button className="btn can-btn" onClick={onCancel}>
              {" "}
              Cancel{" "}
            </button>
          </div>
          <div className="date_head">
            <SubmitButton
              className={"save-btn addcatogorybtn mt-3"}
              text={"Save"}
              isLoading={isLoading}
              onSubmit={onSubmit}
            />

            {/* <button type='button' className='btn save-btn'>
              {' '}
              Save{' '}
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
};
