import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import Switch from "react-switch";

export const FaqRow = ({ faqData, onEdit, onDelete }) => {
  const { question = "", answer = "", priority = "" } = faqData;

  return (
    <tr>
      <td>{question}</td>
      <td>{answer}</td>
      <td>[ category name ]</td>
      <td>{priority}</td>
      <td>
        <div className="d-flex align-items-start justify-content-start">
          <label className="d-flex align-items-center justify-content-between flex-column mr-3 mb-0 switchLabel">
            <Switch
              // onChange={this.handleChange}
              // checked={this.state.checked}
              onColor="#0F51A1"
              uncheckedIcon={false}
              checkedIcon={false}
              width={48}
              height={28}
            />
            <span
              className="ml-0 mt-1"
              style={{ lineHeight: "1", fontSize: "12px" }}
            >
              Disable
            </span>
          </label>
          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={onEdit}
          >
            <span>
              <img src={editIcon} alt="" className={"img-main"} />
              <img src={editIconWhite} alt="" className={"img-hover"} />
            </span>
            Edit
          </Button>
          <Button
            variant="danger"
            className="btn th-btn delete-tb action-block-btn"
            onClick={onDelete}
          >
            <span>
              <img src={trashIcon} alt="" className={"img-main"} />
              <img src={trashIconWhite} alt="" className={"img-hover"} />
            </span>
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};
