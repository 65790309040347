/** @format */

import * as qs from "query-string";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { ListOriginComponent } from "../../components/origin";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { originValidator } from "../../../validators/origin";
import countryStateData from "../../../common/countryState.json";
import Pagination from "../../components/pagination";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { DeleteErrorModal } from "../../components/sweetAlertComponent/DeleteError";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import "./manage-origin.css";

class ManageOrigin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      origins: [],
      id: "",
      origin: null,
      states: [],
      totalBatches: 0, // To use at the time of edit
      existingStates: [], // To use at the time of edit
      assignedStates: [],
      errors: null,
      isLoading: false,
      isAdding: false,
      isViewUploading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      sortBy: "createdAt",
      order: "desc",
      isFormOpen: false,
      categoryLoading: true,
      statesOption: [],
      deleteError: false,
    };
  }
  componentDidMount() {
    this.handleQueryParams();
  }
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "createdAt",
        order: query.order ? query.order : "desc",
      },
      () => this.fetchOrigins()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  fetchOrigins = async () => {
    const { currentPage, limit, order, sortBy } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      order,
      sortBy,
    };
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ORIGINS_LIST.service,
      ApiRoutes.ORIGINS_LIST.url,
      ApiRoutes.ORIGINS_LIST.method,
      ApiRoutes.ORIGINS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { origins, totalRecords },
      } = response.data;
      this.setState({
        origins,
        totalRecords,
      });
    }
  };

  onAddBtnClick = () => {
    this.setState((prevState) => ({
      isFormOpen: !prevState.isFormOpen,
      errors: null,
      origin: null,
      states: [],
      existingStates: [],
      id: "",
    }));
  };
  onSelect = (options, name) => {
    const { assignedStates, id, totalBatches } = this.state;
    // Check in case of edit origin that has some active batches
    if (
      id &&
      name === "states" &&
      totalBatches &&
      (!options ||
        !assignedStates.every((v) =>
          options.map((option) => option.value).includes(v)
        ))
    ) {
      this.setState({
        errors: {
          ...this.state.errors,
          [name]: "This state has some active batches",
        },
      });
      return;
    }
    this.setState({
      [name]: options,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
    // TO reset states on country selection
    if (name === "origin") {
      this.handleStatesOption(options && options.value ? options.value : "");
      this.setState({
        states: [],
        errors: {
          ...this.state.errors,
          states: "",
          origin: "",
        },
      });
    }
  };
  handleStatesOption = (value) => {
    let temp = [];
    let selectedCountryIndex = countryStateData.findIndex(
      (element) => element.name === value
    );
    if (selectedCountryIndex > -1) {
      countryStateData[selectedCountryIndex].states
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((state) => {
          temp.push({
            label: state.name,
            value: state.name,
          });
        });
      this.setState({
        statesOption: temp,
      });
    }
  };
  addOrigin = async (data) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_ORIGIN.service,
      ApiRoutes.ADD_ORIGIN.url,
      ApiRoutes.ADD_ORIGIN.method,
      ApiRoutes.ADD_ORIGIN.authenticate,
      undefined,
      data
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.messages[0]);
      this.setState({
        isFormOpen: false,
        errors: null,
        origin: null,
        states: [],
        statesOption: [],
      });
      this.fetchOrigins();
    }
  };
  updateOrigin = async (data, id) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_ORIGIN.service,
      ApiRoutes.UPDATE_ORIGIN.url.replace(":id", id),
      ApiRoutes.UPDATE_ORIGIN.method,
      ApiRoutes.UPDATE_ORIGIN.authenticate,
      undefined,
      data
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.setState({
        errors: null,
        origin: null,
        states: [],
        statesOption: [],
        existingStates: [],
        isFormOpen: false,
        id: "",
      });
      this.fetchOrigins();
    }
  };
  handleSubmit = () => {
    const { origin, states, id, statesOption } = this.state;
    let objToValidate = {
      origin: origin && origin.value ? origin.value : "",
    };
    // To made states required only if selected origins have states
    if ((statesOption && statesOption.length) || !objToValidate.origin) {
      objToValidate = {
        ...objToValidate,
        states: states ? states.length : 0,
      };
    }
    const { isValid, errors } = originValidator(objToValidate);
    if (isValid) {
      const data = {
        originName: origin && origin.value ? origin.value : "",
        states: states ? states.map((state) => state.value) : 0,
      };
      if (id) {
        this.updateOrigin(data, id);
      } else {
        this.addOrigin(data);
      }
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };
  onCancel = () => {
    this.setState({
      errors: null,
      origin: null,
      states: [],
      statesOption: [],
      existingStates: [],
      isFormOpen: false,
      id: "",
    });
  };
  onEdit = async (data, disabled) => {
    this.setState({
      id: data.id,
      isViewUploading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_ORIGIN.service,
      ApiRoutes.VIEW_ORIGIN.url.replace(":id", data.id),
      ApiRoutes.VIEW_ORIGIN.method,
      ApiRoutes.VIEW_ORIGIN.authenticate,
      undefined,
      data
    );
    this.setState({
      isViewUploading: false,
    });
    if (response && response.isError) {
      return;
    }
    const { data: originDetails } = response.data;
    // Scroll to edit row
    const cardSection = document.getElementById("origin-card");
    cardSection.scrollTop = 0;
    // window.scrollTo(0, 0);
    const {
      originName = null,
      states = [],
      id = "",
      totalBatches = 0,
    } = data || {};
    this.setState({
      origin: originName ? { label: originName, value: originName } : null,
      existingStates: states,
      assignedStates: originDetails ? originDetails.assignedStates : [],
      totalBatches,
      states:
        states && states.length
          ? states.map((state) => ({
              label: state,
              value: state,
              // To disable remove on already added states if it's origin has some acitive batches
              isFixed: disabled,
            }))
          : [],
      id,
      isFormOpen: true,
    });
    this.handleStatesOption(originName);
  };
  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };
  deleteOrigin = async (id, name, disabled = false) => {
    if (disabled) {
      this.setState({
        deleteError: true,
      });
      // await SweetAlertComponent({
      //   title: "Can't Delete Origin",
      //   text: `${name} origin has some active batches.`,
      //   confirmButtonText:'Ok',
      //   type:'error',
      //   showCancelButton:false
      // });
    } else {
      const { value } = await SweetAlertComponent({
        // title: 'Are you sure?',
        text: `Are you sure, you want to delete ${name} origin?`,
      });
      if (!value) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_ORIGIN.service,
        ApiRoutes.DELETE_ORIGIN.url.replace(":id", id),
        ApiRoutes.DELETE_ORIGIN.method,
        ApiRoutes.DELETE_ORIGIN.authenticate,
        undefined,
        undefined
      );
      this.setState({
        id: "",
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        await this.fetchOrigins();
        toast.success(response.messages[0]);
      }
    }
  };
  // Function to manage read more, read less
  onExpand = (index) => {
    const { origins } = this.state;
    const temp = [...origins];
    temp[index] = {
      ...temp[index],
      isExpand: !temp[index].isExpand,
    };

    this.setState({
      origins: temp,
    });
  };
  // To close delete error modal
  handleClose = () => {
    this.setState({
      deleteError: false,
    });
  };
  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  render() {
    const {
      origins,
      totalRecords,
      currentPage,
      limit,
      order,
      sortBy,
      origin,
      states,
      statesOption,
      errors,
      isFormOpen,
      isLoading,
      isAdding,
      isViewUploading,
      id,
      deleteError,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Origin</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card card-pagination card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-cat-col-lg">
                <h6 className="font-book ">Origin Listing</h6>
              </div>
              <div className="col-md-9 action-col-lg ">
                <div className="dp-right">
                  <button
                    className="btn btn-primary add-btn"
                    id="add-cat-btn"
                    onClick={this.onAddBtnClick}
                  >
                    {" "}
                    Add Origin
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ListOriginComponent
            origins={origins}
            totalRecords={totalRecords}
            limit={limit}
            errors={errors}
            isLoading={isLoading}
            isAdding={isAdding}
            id={id}
            isViewUploading={isViewUploading}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            onSubmit={this.handleSubmit}
            onCancel={this.onCancel}
            toggleOpen={this.toggleOpen}
            origin={origin}
            states={states}
            onSelect={this.onSelect}
            isFormOpen={isFormOpen}
            deleteOrigin={this.deleteOrigin}
            onEdit={this.onEdit}
            onExpand={this.onExpand}
            countryStateData={countryStateData}
            statesOption={statesOption}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
        <DeleteErrorModal
          open={deleteError}
          handleClose={this.handleClose}
          message={"This origin has current active batches."}
          moduleName={"Origin"}
        />
      </div>
    );
  }
}

export default ManageOrigin;
