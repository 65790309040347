import Validator from 'js-object-validation';
import { message } from '../common';
import { replaceAll } from '../helpers';

export const batchValidator = (data) => {
  const validations = {
    batchNumber: {
      required: true,
      maxlength: 15,
    },
    productId: {
      required: true,
    },
    originId: {
      required: true,
    },
    state: {
      required: true,
    },
    expiryDate: {
      required: true,
    },
    itemCount: {
      required: true,
      numeric: true,
      minnumbers: 1,
    },
  };
  // Error messages
  const messages = {
    batchNumber: {
      required: message.Required.replace(':item', 'Batch number'),
      maxlength: replaceAll(message.MaxLengthError, {
        ':item': 'Batch number',
        ':length': 15,
      }),
    },
    productId: {
      required: message.Required.replace(':item', 'Product'),
    },
    originId: {
      required: message.Required.replace(':item', 'Origin'),
    },
    state: {
      required: message.Required.replace(':item', 'State'),
    },
    expiryDate: {
      required: message.Required.replace(':item', 'Expiry date'),
    },
    itemCount: {
      required: message.Required.replace(':item', 'Item count'),
      numeric: message.InvalidItemCount,
      minnumbers: message.MinItemCount,
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
