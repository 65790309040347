import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import Switch from "react-switch";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import SweetAlertComponent from "../sweetAlertComponent";

export const FaqRow = ({
  faqData,
  onEdit,
  onDelete,
  fetchFaqRecords,
  userDetail,
  onExpand = function () {},
}) => {
  const { isExpand = false } = faqData;
  const [faqEdit, setFaqEdit] = useState(faqData);
  // const faqEdit = faqData;
  const changeStatus = async (data) => {
    let response = undefined;
    if (userDetail == "Web") {
      response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.UPDATE_FAQ_NEW.service,
        ApiRoutes.UPDATE_FAQ_NEW.url, //.replace(":id", faqEdit?._id)
        ApiRoutes.UPDATE_FAQ_NEW.method,
        ApiRoutes.UPDATE_FAQ_NEW.authenticate,
        undefined,
        {
          ...data,
          id: faqEdit?._id,
          answer: faqEdit?.answer,
          question: faqEdit?.question,
          priority: faqEdit?.priority,
        }
      );
    } else {
      response = await new ApiHelper().FetchFromServer(
        ApiRoutes.UPDATE_FAQ_STATUS.service,
        ApiRoutes.UPDATE_FAQ_STATUS.url.replace(":id", faqEdit?._id),
        ApiRoutes.UPDATE_FAQ_STATUS.method,
        ApiRoutes.UPDATE_FAQ_STATUS.authenticate,
        undefined,
        data
      );
    }

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.data?.message);
      // fetchFaqRecords();
    }
  };

  // console.log("faqEdit", faqEdit?.status);
  const handleChange = async (event) => {
    // console.log("event", event.target.checked);
    const {
      target: { checked },
    } = event;

    const { value } = await SweetAlertComponent({
      title: "",
      text: `Are you sure, you want to ${checked ? "enable" : "disable"}  faq?`,
    });
    if (!value) {
      return;
    }
    setFaqEdit({
      ...faqEdit,
      status: checked,
    });
    let data = {
      status: checked,
    };

    changeStatus(data);
  };

  return (
    <tr>
      <td>
        <div
          style={{
            width: "300px",
            minWidth: "300px",
            maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          {faqEdit?.question}
        </div>
      </td>
      <td className="pre-desc">
        {faqEdit?.answer && faqEdit?.answer?.length <= 100 ? (
          faqEdit?.answer
        ) : (
          <p
            className="mb-0"
            style={{
              width: "300px",
              minWidth: "300px",
              maxWidth: "300px",
              whiteSpace: "normal",
            }}
          >
            {isExpand ? faqEdit?.answer : faqEdit?.answer?.substr(0, 100)}
            <span className="bold-desc cursor-pointer" onClick={onExpand}>
              {!isExpand ? "...Read More" : "Read less"}
            </span>
          </p>
        )}
      </td>

      {faqEdit?.users !== "App" && userDetail !== "Web" && (
        <td>{faqEdit?.categoryName}</td>
      )}
      <td>{faqEdit?.priority}</td>
      <td className="switchBox">
        <div className="d-flex align-items-start justify-content-start">
          <div className="switchHolder">
            <label className="switch">
              <input
                type="checkbox"
                name="status"
                onChange={handleChange}
                checked={faqEdit?.status}
              />
              <span className="slider round"></span>
            </label>
            <span className="disable">
              {!faqEdit?.status ? "Enable" : "Disable"}
            </span>
          </div>

          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={onEdit}
          >
            <span>
              <img src={editIcon} alt="" className={"img-main"} />
              <img src={editIconWhite} alt="" className={"img-hover"} />
            </span>
            Edit
          </Button>
          <Button
            variant="danger"
            className="btn th-btn delete-tb action-block-btn"
            onClick={onDelete}
          >
            <span>
              <img src={trashIcon} alt="" className={"img-main"} />
              <img src={trashIconWhite} alt="" className={"img-hover"} />
            </span>
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};
