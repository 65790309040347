import React from "react";
import ProductRow from "./ProductRow";
import noData from "../../../assets/img/no-data.png";
import { TableLoader } from "../../components/loader/Resusable/Table";
import "./product.css";

export const ProductListComponent = ({
  products,
  isLoading,
  onExpand,
  onEdit,
  onDelete,
  setFilter,
  sortBy,
  order,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-lg">
        {isLoading ? (
          <TableLoader columns={5} rows={10} />
        ) : (
            <table width="100%" cellSpacing="0">
              <thead className="pad-top">
                <tr>
                  {[
                    { label: "Product Number", value: "productNumber" },
                    { label: "Product Name", value: "productName" },
                  ].map(({ label, value }) => (
                    <th key={value}>
                      {label}{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === value ? "active-ac" : ""
                            }`}
                          onClick={() => setFilter(value, "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === value
                              ? "active-ac"
                              : ""
                            }`}
                          onClick={() => setFilter(value, "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                  ))}
                  <th>Product Description</th>
                  <th className="product-img-width-th">Product Image</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {products && products.length ? (
                  products.map((product, index) => (
                    <ProductRow
                      key={index}
                      productData={product}
                      onExpand={() => onExpand(index)}
                      onEdit={() => onEdit(product.id)}
                      onDelete={() =>
                        onDelete(
                          product.id,
                          product.productName,
                          product.totalBatches
                        )
                      }
                    />
                  ))
                ) : (
                    <tr className="no-tr">
                      <td colSpan={8} className={"text-center image-no-data-xs"}>
                        <img src={noData} alt="" />
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          )}
        {/* {totalRecords > limit ? (
          <div className='float-right mt-2'>
          <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
           /> 
          </div>
        ) : null}*/}
      </div>
    </div>
  );
};
