import React from "react";
import Select from "react-select";
import { BatchViewLoader } from "../../loader/Resusable/BatchView";
import {
  staffPermissionModules,
  accessTypesOption,
  AppRoutes,
  permissionModules,
} from "../../../../config";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import ReactMultiselectCheckboxes from "react-multiselect-checkboxes/lib/ReactMultiselectCheckboxes";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
};

export const PermissionFormComponent = ({
  isLoading,
  isAdding,
  selectedModule,
  accessType,
  errors,
  handlePermissionSelection,
  addPermission,
  staffId,
  alreadyAdded,
  subModules,
}) => {
  const history = useHistory();

  const onCancel = () => {
    history.push({
      pathname: AppRoutes.VIEW_STAFF.replace(":id", staffId),
    });
  };
  const moduleOptions = permissionModules.map((item) => item.module);
  return (
    <div className="card mb-4 card-main flex-fill">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <>
          {" "}
          <div className="card-header py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book ">Manage Permissions</h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            <form className="user_add mt-2 pb-4" onSubmit={addPermission}>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Select Module
                        </label>
                        <Select
                          placeholder={"Select Module"}
                          styles={customStyles}
                          options={moduleOptions}
                          maxMenuHeight={200}
                          noOptionsMessage={() =>
                            "No module found related to your search"
                          }
                          value={selectedModule}
                          onChange={(selectedOption) =>
                            handlePermissionSelection(
                              selectedOption,
                              "selectedModule"
                            )
                          }
                        />
                        <div className={"text-danger error-text"}>
                          {errors && errors.selectedModule
                            ? errors.selectedModule
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Sub Module
                        </label>
                        <ReactMultiselectCheckboxes
                          placeholder={"Select Sub Module"}
                          placeholderButtonLabel="Select Sub Module"
                          styles={customStyles}
                          maxMenuHeight={200}
                          options={
                            selectedModule && selectedModule.value
                              ? permissionModules.filter(
                                  (item) =>
                                    item.module.value === selectedModule.value
                                )[0].subModules
                              : []
                          }
                          isMulti={true}
                          noOptionsMessage={() =>
                            "No area found related to your search"
                          }
                          value={subModules}
                          onChange={(selectedOption) =>
                            handlePermissionSelection(
                              selectedOption,
                              "subModules"
                            )
                          }
                        />
                        <div className={"text-danger error-text"}>
                          {errors && errors.subModules ? errors.subModules : ""}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Select Access
                        </label>
                        <Select
                          placeholder={"Select Access"}
                          styles={customStyles}
                          options={accessTypesOption}
                          value={accessType}
                          onChange={(selectedOption) =>
                            handlePermissionSelection(
                              selectedOption,
                              "accessType"
                            )
                          }
                        />
                        <div className={"text-danger error-text"}>
                          {errors && errors.accessType ? errors.accessType : ""}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="d-flex">
                        <Button
                          variant="danger"
                          className="btn th-btn action-block-btn px-2"
                          style={{
                            width: "100px",
                            height: "39px",
                            marginTop: "1.75rem",
                          }}
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                        <button
                          className="btn action-cl-btn-lg px-2"
                          onClick={addPermission}
                          disabled={isAdding}
                          style={{ width: "100px" }}
                        >
                          {" "}
                          {isAdding ? (
                            <>
                              <i className="fa fa-spinner fa-spin"></i>{" "}
                              {alreadyAdded ? "Updating" : "Adding"}
                            </>
                          ) : (
                            `${alreadyAdded ? "Update" : "Add"}`
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
