/** @format */

import React from "react";
import { Dropdown, ProgressBar } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { InputTextField } from "../forms";
import {
  AppConfig,
  genderOptions,
  customReactSelectStyles,
} from "../../../config";
import { BatchViewLoader } from "../../components/loader/Resusable/BatchView";
import defaultImg from "../../../assets/img/group-12.png";
import editIcon from "../../../assets/img/edit-3.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import birthDateCalender from "../../../assets/img/birthDateCalender.svg";
export const InfoDetailComponent = ({
  staffData,
  staffId,
  isEditable,
  actionBtn = true,
  isLoading,
  isUpdating,
  isUploading,
  uploadPercentage,
  onEdit,
  onChange,
  onSubmit,
  onSelect,
  onFileChange,
  sortByAction,
  onDateChange,
  onDeleteFile,
  onCancel,
  viewPermission,
  changeStaffStatus,
  portalAccessSwitcher,
  portalAccessDiv,
  dateError,
}) => {
  const {
    email = "",
    employee_id = "",
    first_name = "",
    last_name = "",
    gender = "",
    office_location = "",
    office_phone = "",
    phone = "",
    profile_picture = "",
    joining_date = "",
    birth_date = "",
    personal_email = "",
    address1 = "",
    portal_access = "",
    status = "",
    errors = {},
  } = staffData;
  console.log("errors", errors);
  return (
    <div className="card mb-3 card-main flex-fill">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <>
          {" "}
          <div className="card-header py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3">
                <h6 className="font-book ">
                  Staff Details -{" "}
                  {[first_name, last_name].filter(Boolean).join(" ") || "-"}{" "}
                </h6>
              </div>
              {actionBtn ? (
                <div className="col-md-9">
                  {!isEditable ? (
                    <div className="side-col dp-right">
                      <button className="btn ic-btn" onClick={onEdit}>
                        {" "}
                        <img src={editIcon} alt="" /> Edit
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="side-col dp-right">
                        <button
                          className="btn action-cl-btn"
                          onClick={onSubmit}
                          disabled={isUpdating}
                        >
                          {isUpdating ? (
                            <>
                              <i className="fa fa-spinner fa-spin"></i> Save
                            </>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                      <div className="side-col dp-right">
                        <button
                          className="btn btn-secondary can-btn-table cn-tp"
                          onClick={onCancel}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                      </div>
                    </>
                  )}
                  {!isEditable && (
                    <Dropdown className="no-arrow dp-right drp-tp">
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        className={"filter-btn"}
                      >
                        Action <img src={filterarrowIcon} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                        <Dropdown.Item
                          as="div"
                          onClick={() => {
                            window.open(`mailto:${email}`);
                          }}
                        >
                          Contact User
                        </Dropdown.Item>
                        {status === "Suspended" ? (
                          <Dropdown.Item
                            as="div"
                            onClick={() => changeStaffStatus("Revoke")}
                          >
                            Revoke
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            as="div"
                            onClick={() => changeStaffStatus("Suspended")}
                          >
                            Suspend
                          </Dropdown.Item>
                        )}
                        {status === "Terminated" ? (
                          <Dropdown.Item
                            as="div"
                            onClick={() => changeStaffStatus("Rejoin")}
                          >
                            Rejoin
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            as="div"
                            onClick={() => changeStaffStatus("Terminated")}
                          >
                            Terminate
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                  {!isEditable && (
                    <div className="side-col dp-right">
                      <button
                        className="btn action-cl-btn"
                        onClick={viewPermission}
                      >
                        {" "}
                        Permissions
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="col-md-9">
                  <div className="side-col dp-right">
                    <button className="btn ic-btn" onClick={onEdit}>
                      {" "}
                      <img src={editIcon} alt="" /> Edit
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card-body card-table">
            <form className="user_add mt-2 mb-4">
              <div className="row">
                <div className="col-md-2 col-img-sf">
                  <div className="img-col-staff">
                    <img
                      className="user-img"
                      // src={
                      //   profile_picture
                      //     ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                      //     : defaultImg
                      // }
                      src={
                        profile_picture
                          ? profile_picture.startsWith("https://")
                            ? profile_picture
                            : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                          : defaultImg
                      }
                      alt=""
                    />
                  </div>
                  {isUploading ? (
                    <ProgressBar now={uploadPercentage} className={"mt-2"} />
                  ) : null}
                  {isEditable ? (
                    <div className="file-upload-btn-sf ">
                      <label className="file-label">
                        {" "}
                        {profile_picture ? "Change Image" : "Choose Image"}
                        <input
                          className="imgUpload img_up"
                          name="profile_picture"
                          type="file"
                          accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                          onChange={onFileChange}
                        />
                      </label>
                      <span onClick={onDeleteFile} className={"cursor-pointer"}>
                        <img src={trashIcon} alt="" />
                      </span>
                    </div>
                  ) : null}
                  <div className={"text-danger"}>
                    {errors && errors.profile_picture
                      ? errors.profile_picture
                      : ""}
                  </div>

                  <div className="st-name-head center">
                    <h4 className="sf_name">
                      {" "}
                      {[first_name, last_name].filter(Boolean).join(" ") ||
                        "-"}{" "}
                    </h4>
                    <h4 className="sf_desc">{employee_id}</h4>
                  </div>
                </div>
                <div className="col-md-9 col-det-sf">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="form-head">Basic Information</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <InputTextField
                        id="employee_id"
                        label={"Employee ID"}
                        className={"form-control-user disable-write"}
                        name="employee_id"
                        value={employee_id}
                        maxLength={30}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        id="email"
                        label={"Email"}
                        className={"form-control-user disable-write"}
                        name="email"
                        value={email}
                        readOnly={true}
                        // onChange={onChange}
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        id="phone"
                        label={"Phone Number"}
                        className={"form-control-user"}
                        name="office_phone"
                        value={office_phone}
                        readOnly={!isEditable}
                        onChange={onChange}
                        maxLength={14}
                        errorMessage={
                          errors && errors.office_phone
                            ? errors.office_phone
                            : ""
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className={`form-control-label`}>Gender</label>
                        <AsyncSelect
                          placeholder={""}
                          defaultOptions={genderOptions}
                          styles={customReactSelectStyles}
                          value={gender}
                          onChange={(selectedOptions) =>
                            onSelect(selectedOptions)
                          }
                        />

                        {!gender && dateError && (
                          <div className="text-danger">
                            Please enter the gender
                          </div>
                        )}
                      </div>
                      {/* <InputTextField
                        id='gender'
                        label={'Gender'}
                        className={'form-control-user'}
                        name='gender'
                        value={gender}
                        readOnly={!isEditable}
                      /> */}
                    </div>
                    <div className="col-md-4">
                      <label className={`form-control-label`}>
                        Date of Joining
                      </label>
                      <DatePicker
                        className={"form-control-input"}
                        placeholder={`Date of Joining(DD/MM/YYYY)`}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        disabled={true}
                        selected={joining_date}
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        id="office_location"
                        label={"Working Location"}
                        className={"form-control-user"}
                        name="office_location"
                        value={office_location}
                        onChange={onChange}
                        readOnly={!isEditable}
                        errorMessage={
                          errors && errors.office_location
                            ? errors.office_location
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="form-head">Personal Information</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group datePicker viewDetail">
                        <label className={`form-control-label`}>
                          Date of Birth
                        </label>
                        <DatePicker
                          className={"form-control-input"}
                          placeholder={`Date of Birth(DD/MM/YYYY)`}
                          selected={birth_date}
                          maxDate={new Date()}
                          onChange={onDateChange}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          disabled={!isEditable}
                        />
                        <div className="icon">
                          <img src={birthDateCalender} alt="" />
                        </div>
                      </div>
                      {!birth_date && dateError && (
                        <div className="text-danger">
                          Please enter the birth date
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        id="per_phone"
                        label={"Phone Number"}
                        className={"form-control-user"}
                        name="phone"
                        value={phone}
                        readOnly={!isEditable}
                        maxLength={14}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.phone ? errors.phone : ""
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        id="personal_email"
                        label={"E-Mail"}
                        className={"form-control-user"}
                        name="personal_email"
                        value={personal_email}
                        readOnly={!isEditable}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.personal_email
                            ? errors.personal_email
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-8">
                      <InputTextField
                        id="address1"
                        label={"Address"}
                        className={"form-control-user"}
                        name="address1"
                        value={address1}
                        readOnly={!isEditable}
                        onChange={onChange}
                      />
                      {!address1 && dateError && (
                        <div className="text-danger">
                          Please enter the address
                        </div>
                      )}
                    </div>
                    {/* <div className="col-md-12 isPermissionDiv">
                      <input
                        id="isPermission"
                        name="isPermission"
                        type="checkbox"
                        disabled={!isEditable}
                        onChange={onChange}
                        className={"form-control-user"}
                        checked={isPermission === "assign" ? true : false}
                      />
                      <label
                        for="isPermission"
                        class="form-control-label form-control-user"
                      >
                        {permissionText}
                      </label>
                    </div> */}
                  </div>
                  {portalAccessDiv && !isEditable && (
                    <div>
                      <div className="row">
                        <div className="col-md-4">
                          <h4 className="form-head">Portal Access</h4>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="input-group-prepend">
                              <label className="switch">
                                <label className={`form-control-label`}>
                                  Invite Sent
                                </label>
                                <span className="slider round slide-yes-no-wrap">
                                  <span
                                    className={`yes-field ${
                                      portal_access ? "active" : ""
                                    }`}
                                    onClick={() => portalAccessSwitcher(true)}
                                  >
                                    Enable
                                  </span>
                                  <span className="separator-field">|</span>
                                  <span
                                    className={`no-field ${
                                      portal_access ? "" : "active"
                                    }`}
                                    onClick={() => portalAccessSwitcher(false)}
                                  >
                                    Disable
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
