import React from "react";
import listIcon from "../../../../assets/img/list.svg";
import reopenIcon from "../../../../assets/img/corner-up-right_new.svg";
import minusCircleIcon from "../../../../assets/img/minus-circle.svg";

const RequestRow = ({
  requestData,
  viewUser,
  isUpdating,
  updateStatus,
  resendInvitation,
}) => {
  const {
    first_name = "",
    last_name = "",
    email = "",
    employee_id = "",
    status = "",
    roleName = "",
  } = requestData;

  const renderStatus = () => {
    switch (status) {
      case "Accepted":
        return <span className="success-span">Accepted</span>;
      case "Rejected":
        return <span className="secondary-span">Rejected</span>;
      case "Cancelled":
        return <span className="danger-span">Cancelled</span>;
      case "Invited":
        return <span className="primary-span">Invited</span>;
      case "Pending":
        return <span className="warning-span">Pending</span>;
      default:
        return <span className="primary-span">Invited</span>;
    }
  };
  const buttonLabel = () => {
    switch (status) {
      case "Invited":
        return (
          <button
            className="btn btn-secondary th-btn"
            disabled={isUpdating}
            onClick={() =>
              updateStatus(
                "Cancelled",
                "Are you sure, you want to cancel this invitation"
              )
            }
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Cancel
              </>
            ) : (
              <>
                <img src={minusCircleIcon} alt="" /> Cancel{" "}
              </>
            )}
          </button>
        );
      case "Accepted":
        return (
          <button
            className="btn btn-secondary th-btn"
            disabled={isUpdating}
            onClick={() =>
              updateStatus(
                "Active",
                "Do you want to convert this to your employee"
              )
            }
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Convert to an employee
              </>
            ) : (
              <>
                <img src={minusCircleIcon} alt="" /> Convert to an employee{" "}
              </>
            )}
          </button>
        );
      case "Cancelled":
        return (
          <button
            className="btn btn-secondary th-btn"
            disabled={isUpdating}
            onClick={resendInvitation}
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Resend Invitation
              </>
            ) : (
              <>
                <img src={reopenIcon} alt="" /> Resend Invitation{" "}
              </>
            )}
          </button>
        );
      case "Pending":
        return (
          <button
            className="btn btn-secondary th-btn"
            disabled={isUpdating}
            onClick={() =>
              updateStatus("Accepted", "Do you want to accept this invitation")
            }
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Accept
              </>
            ) : (
              <>
                <img src={minusCircleIcon} alt="" /> Accept{" "}
              </>
            )}
          </button>
        );
      default:
        return (
          <button
            className="btn btn-secondary th-btn"
            disabled={isUpdating}
            onClick={() =>
              updateStatus(
                "Active",
                "Do you want to Convert this to your employee"
              )
            }
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Convert to an employee
              </>
            ) : (
              <>
                <img src={minusCircleIcon} alt="" /> Convert to an employee{" "}
              </>
            )}
          </button>
        );
    }
  };
  return (
    <tr>
      <td> {[first_name, last_name].filter(Boolean).join(" ") || "N/A"}</td>
      <td> {employee_id || "N/A"} </td>
      <td> {roleName || "N/A"} </td>
      <td> {email || "N/A"} </td>
      <td> {renderStatus()} </td>

      <td>
        <button className="btn btn-secondary th-btn" onClick={viewUser}>
          {" "}
          <img src={listIcon} alt="" /> View{" "}
        </button>
        {buttonLabel()}
      </td>
    </tr>
  );
};

export default RequestRow;
