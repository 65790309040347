import React from 'react';
import Skeleton from 'react-skeleton-loader';
import { CounterfeitProduct } from '../Resusable/CounterfeitProduct';
import { CounterfeitRequest } from '../Resusable/CounterfeitRequest';
import { CounterfeitUser } from '../Resusable/CounterfeitUser';
import { CounterfeitClient } from '../Resusable/CounterfeitClient';
export const ViewCounterfeitLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>View</h1>
      <p className='breadcrums-top'>
        <Skeleton color='#fff' />
      </p>

      <div className='row'>
        <div className='col-md-6 d-flex'>
          <div className='card mb-4 card-main flex-fill'>
            <div className='card-header  py-7550  align-items-center justify-content-between'>
              <div className='row'>
                <div className='col-md-12'>
                  <h6 className='font-book '>
                    <Skeleton color='#e5e9ef' height={'25px'} />
                  </h6>
                </div>
              </div>
            </div>
            <div className='card-body card-table'>
              <CounterfeitProduct />
            </div>
          </div>
        </div>
        <div className='col-md-6 d-flex'>
          <div className='card mb-4 card-main flex-fill'>
            <div className='card-header  py-7550  align-items-center justify-content-between'>
              <div className='row'>
                <div className='col-md-12'>
                  <h6 className='font-book '>
                    <Skeleton color='#e5e9ef' height={'25px'} />
                  </h6>
                </div>
              </div>
            </div>
            <div className='card-body card-table'>
              <CounterfeitUser />
            </div>
          </div>
        </div>
        <div className='col-md-12 d-flex'>
          <div className='card mb-4 card-main flex-fill'>
            <div className='card-header  py-7550  align-items-center justify-content-between'>
              <div className='row'>
                <div className='col-md-12'>
                  <h6 className='font-book '>
                    <Skeleton color='#e5e9ef' height={'25px'} />
                  </h6>
                </div>
              </div>
            </div>
            <div className='card-body card-table'>
              <CounterfeitRequest />
            </div>
          </div>
        </div>
        <div className='col-md-12 d-flex'>
          <CounterfeitClient />
        </div>
      </div>
    </div>
  </>
);
