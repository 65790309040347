import React, { useState } from "react";
import { SubmitButton } from "../forms";
import { addClientValidator } from "../../../validators";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import { toast } from "react-toastify";

export const AddClientFormComponent = ({ onCancel }) => {
  const [isAdding, setAdding] = useState(false);
  const [clientData, setClientData] = useState({
    name: "",
    email: "",
    companyName: "",
    // storeName: "",
    // brandName: "",
  });
  const [errors, setErrors] = useState(null);
  // storeName, brandName,
  const { name, email, companyName } = clientData;
  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setClientData({
      ...clientData,
      [name]: value.trimLeft(),
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const addClient = async (data) => {
    setAdding(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_CLIENTS.service,
      ApiRoutes.ADD_CLIENTS.url,
      ApiRoutes.ADD_CLIENTS.method,
      ApiRoutes.ADD_CLIENTS.authenticate,
      undefined,
      data
    );
    console.log("data", data);
    console.log("response", response);
    if (response && response.isError) {
      setAdding(false);
      toast.error(response.messages[0]);
    } else {
      await onCancel(true);
      toast.success(response.messages[0]);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      companyName,
      // storeName,
      // brandName,
    };
    const { isValid, errors } = addClientValidator(data);
    if (isValid) {
      addClient(data);
    } else {
      setErrors(errors);
      return;
    }
  };
  return (
    <tr className="form-row-table">
      <td></td>
      <td>
        <input
          type="text"
          className="form-control form-link mb-2"
          placeholder="Enter Name"
          name="name"
          value={name}
          onChange={handleChange}
          maxLength={30}
          disabled={isAdding}
        />
        <div className={"text-danger error-text"}>
          {errors && errors.name ? errors.name : ""}
        </div>
      </td>
      <td>
        <input
          type="text"
          placeholder="Enter Email"
          className="form-control form-link mb-2"
          name="email"
          value={email}
          onChange={handleChange}
          disabled={isAdding}
        />
        <div className={"text-danger error-text"}>
          {errors && errors.email ? errors.email : ""}
        </div>
      </td>
      <td className="cl-comp">
        <input
          type="text"
          placeholder="Enter Company Name"
          className="form-control form-link mb-2"
          name="companyName"
          value={companyName}
          onChange={handleChange}
          disabled={isAdding}
        />
        <div className={"text-danger error-text"}>
          {errors && errors.companyName ? errors.companyName : ""}
        </div>
      </td>
      {/* <td className="cl-brand">
        <input
          type="text"
          placeholder="Enter Brand"
          className="form-control form-link mb-2"
          name="brandName"
          value={brandName}
          onChange={handleChange}
          disabled={isAdding}
        />
        <div className={"text-danger error-text"}>
          {errors && errors.brandName ? errors.brandName : ""}
        </div>
      </td> */}
      <td></td>
      <td>
        <button
          className="btn btn-secondary can-btn-table"
          onClick={() => onCancel(false)}
        >
          {" "}
          Cancel{" "}
        </button>
        <SubmitButton
          className={"save-btn-table add"}
          text={"Invite"}
          isLoading={isAdding}
          onSubmit={handleSubmit}
        />
      </td>
    </tr>
  );
};
