import Validator from "js-object-validation";
import { message } from "../common";

export const cancelValidate = (data) => {
  const validations = {
    cancellationReason: {
      required: true,
    },
    statusDescription: {
      required: true,
    },
  };
  const messages = {
    cancellationReason: {
      required: message.RequiredReason.replace(":item"),
    },
    statusDescription: {
      required: message.Required.replace(":item", "reason"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
