import React, { Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AppConfig } from "../../../config";
import trashGallery from "../../../assets/img/del-gallery.svg";
import featureGallery from "../../../assets/img/feature-img.svg";
import dummyImage from "../../../assets/img/noimage.jpeg";

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e6e6e6" : "#fff",
});

const OrderByComponent = ({
  data,
  markImageAsFeatured,
  isDeletingImage,
  deletingImageIndex,
  deleteImage,
  onImageClick,
}) => {
  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (
      !destination ||
      (destination && destination.index !== 0) ||
      source.index === destination.index
    ) {
      return;
    }
    markImageAsFeatured(source.index - 1);
  };

  return (
    <>
      {data && data.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className="gallery-footer"
              >
                {data.map((item, index) => (
                  <Draggable
                    key={item}
                    draggableId={item}
                    index={index}
                    isDragDisabled={index === 0}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={"product-images-list"}
                      >
                        <Fragment key={index}>
                          <img
                            // src={`${AppConfig.FILES_ENDPOINT}${item}`}
                            src={
                              item
                                ? item.startsWith("https://")
                                  ? item
                                  : `${AppConfig.FILES_ENDPOINT}${item}`
                                : dummyImage
                            }
                            alt=""
                            className={"mr-2 footer-img"}
                            onClick={() => onImageClick(index)}
                          />
                          <span className={"featured-image"}>
                            {index === 0 ? (
                              <img src={featureGallery} alt="" />
                            ) : null}
                          </span>
                          <span
                            onClick={() => deleteImage(index)} // To exclude cover image
                            className={"gallery-trash"}
                            id="hide-del"
                          >
                            {isDeletingImage && deletingImageIndex === index ? (
                              <i className="fa fa-spinner fa-spin test-spin"></i>
                            ) : (
                              <img src={trashGallery} alt="" />
                            )}
                          </span>
                        </Fragment>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </>
  );
};

export default OrderByComponent;
