import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { InputTextField, SubmitButton } from "../../components/forms";
//import { userValidator } from '../../../validators';
import "./manage-terms.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { TermLoader } from "../../components/loader/Cms/TermLoader";

class ManageTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      loading: false,
      errors: null,
      userDetail: "",
      pageLoader: false,
    };
  }

  // componentDidMount = () => {
  //   this.fetchDetails("terms");
  // };

  componentDidMount = () => {
    this.setState(
      {
        userDetail: this.props.location.pathname.split("/")[2], //[3],
      },
      () => this.handleQueryParams()
    );

    this.handleQueryParams();
  };
  handleQueryParams = async () => {
    const { userDetail } = this.state;
    this.fetchDetails("TermsAndCondition", userDetail);
  };

  componentDidUpdate = ({ location: { search: prevSearch } }, prevState) => {
    // const splitValue = this.props.location.pathname
    //   .split("/")[3]
    //   ?.toLowerCase();
    const splitValue = this.props.location.pathname
      .split("/")[2]
      ?.toLowerCase();
    if (prevState.userDetail !== splitValue) {
      this.setState(
        {
          userDetail: splitValue,
        },
        () => this.handleQueryParams()
      );
    }
  };

  fetchDetails = async (key, type) => {
    this.setState({
      pageLoader: true,
    });
    const { userDetail } = this.state;
    let response = undefined;
    if (type && type?.toLocaleLowerCase() === "web") {
      response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.GET_TERMS_AND_CONDITION_NEW.service,
        ApiRoutes.GET_TERMS_AND_CONDITION_NEW.url,
        ApiRoutes.GET_TERMS_AND_CONDITION_NEW.method,
        ApiRoutes.GET_TERMS_AND_CONDITION_NEW.authenticate,
        undefined,
        undefined,
        undefined
      );
    } else if (type && type?.toLocaleLowerCase() !== "web") {
      response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_SETTINGS.service,
        ApiRoutes.GET_SETTINGS.url.replace(":id", userDetail + key),
        ApiRoutes.GET_SETTINGS.method,
        ApiRoutes.GET_SETTINGS.authenticate,
        undefined,
        undefined
      );
    }
    this.setState({
      pageLoader: false,
    });
    if (response && response.isError) {
    } else {
      if (type && type?.toLocaleLowerCase() !== "web") {
        if (response?.data?.data?.product?.length) {
          // this.handleChange(window.atob(response.data.data.product[0].value));
          this.handleChange(
            decodeURIComponent(
              escape(window.atob(response.data.data.product[0].value))
            )
          );
        } else {
        }
      } else if (type && type?.toLocaleLowerCase() === "web") {
        if (response?.data?.data?.value) {
          this.handleChange(
            decodeURIComponent(escape(window.atob(response?.data?.data?.value)))
          );
          // this.handleChange(response?.data?.data?.value);
        }
      }
    }
  };

  saveDetails = async (payload, type) => {
    if (this.state?.userDetail?.toLocaleLowerCase() !== "web") {
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.SAVE_SETTINGS.service,
        ApiRoutes.SAVE_SETTINGS.url,
        ApiRoutes.SAVE_SETTINGS.method,
        ApiRoutes.SAVE_SETTINGS.authenticate,
        undefined,
        payload
      );
      if (response && response?.isError) {
        toast.error(response?.messages[0]);
        this.setState({
          loading: false,
        });
        const { userDetail } = this.state;
        this.fetchDetails("TermsAndCondition", type);
        this.setState({
          errors: response?.validationErrors,
        });
      } else {
      }
    } else {
      const response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.UPDATE_TERMS_AND_CONDITION_NEW.service,
        ApiRoutes.UPDATE_TERMS_AND_CONDITION_NEW.url,
        ApiRoutes.UPDATE_TERMS_AND_CONDITION_NEW.method,
        ApiRoutes.UPDATE_TERMS_AND_CONDITION_NEW.authenticate,
        undefined,
        payload,
        undefined
      );
      if (response && response?.isError) {
        toast.error(response?.messages[0]);
        this.setState({
          loading: false,
        });
        this.fetchDetails("TermsAndCondition", type);
        this.setState({
          errors: response?.validationErrors,
        });
      } else {
      }
    }
  };

  handleChange = (value) => {
    this.setState({
      content: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { userDetail } = this.state;
    this.setState({
      loading: true,
    });

    await this.saveDetails(
      {
        key: userDetail + "TermsAndCondition",
        // value: window.btoa(this.state.content),
        value: window.btoa(unescape(encodeURIComponent(this.state.content))),
      },
      userDetail
    );
    this.setState({
      loading: false,
    });
    toast.success("Terms saved Successfully.");
    this.fetchDetails("TermsAndCondition", userDetail);
  };

  handleCancel = () => {
    const { userDetail } = this.state;
    this.fetchDetails("TermsAndCondition", userDetail);
  };

  render() {
    const { userDetail, pageLoader } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2 textareaPrivacyTerms">
        <h1 className="h3-head-mob d-md-none">Terms and Conditions</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card mb-4 card-main">
          {pageLoader ? (
            <TermLoader />
          ) : (
            <>
              <div className="card-header  py-7550  align-items-center justify-content-between">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="font-book ">
                      Please enter the Terms and Conditions applicable to your
                      site below
                    </h6>
                  </div>
                </div>
              </div>
              <div className="card-body card-table">
                <form className="" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <ReactQuill
                        placeholder="Type something here..."
                        value={this.state.content}
                        onChange={this.handleChange}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            ["bold", "italic", "underline"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ align: [] }],
                            ["link", "image"],
                            ["clean"],
                            [{ color: [] }],
                          ],
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 footer-form">
                    <div className="d-flex align-items-center justify-content-center contactForm_admin">
                      <button
                        type="button"
                        className="btn btn-primary cancel-btn"
                        style={{
                          height: "45px",
                          width: "190px",
                          marginRight: "30px",
                        }}
                        onClick={this.handleCancel}
                      >
                        {" "}
                        CANCEL
                      </button>

                      <SubmitButton
                        className={"submit-btn mr-0"}
                        text="ADD"
                        isLoading={this.state.loading}
                        onSubmit={this.handleSubmit}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ManageTerms;
