/** @format */
import React from "react";
import moment from "moment";
import "./batches.css";
import { TableLoader } from "../../components/loader/Resusable/Table";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../../../config";

export const CounterfeitTable = ({ thead, tbody, loading }) => {
  return loading ? (
    <TableLoader columns={thead.length} />
  ) : (
    <table width="100%" cellSpacing="0" className="tableCustom ">
      <thead className="pad-topp">
        <tr>
          {thead && thead.length ? thead.map((th, index) => <th>{th}</th>) : ""}
        </tr>
      </thead>
      <tbody>
        {tbody && tbody.length ? (
          tbody.map((th, index) => (
            <tr>
              <td>{th.scanId || th.reportId}</td>
              <td>{th?.batch?.batchNumber}</td>
              <td>{th?.product?.productName}</td>
              <td>{th.assigneeName}</td>
              <td>{th.status}</td>
              <td>
                {th.createdAt
                  ? [
                      moment(th.createdAt).format(DEFAULT_DATE_FORMAT),
                      moment(th.createdAt).format(DEFAULT_TIME_FORMAT),
                    ].join("  ")
                  : ""}
              </td>
            </tr>
          ))
        ) : (
          <tr className="dark_text">
            <td colSpan={thead.length} className="text-center">
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
