import Validator from "js-object-validation";
import { message } from "../common";
import { replaceAll } from "../helpers";

export const profileValidator = (data) => {
  const validations = {
    first_name: {
      required: true,
      alpha: true,
    },
    last_name: {
      required: true,
      alpha: true,
    },
    phone: {
      required: true,
      minlength: 8,
      numeric: true,
    },
  };
  // Error messages
  const messages = {
    first_name: {
      required: message.Required.replace(":item", "first Name"),
      alpha: message.InvalidName.replace(":item", "first name"),
    },
    last_name: {
      required: message.Required.replace(":item", "last Name"),
      alpha: message.InvalidName.replace(":item", "last name"),
    },
    phone: {
      required: message.Required.replace(":item", "phone"),
      minlength: replaceAll(message.MinPhoneCount, {
        ":item": "Phone number",
        ":length": 8,
      }),
      numeric: message.InvalidPhone,
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
