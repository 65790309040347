import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const CounterfeitClient = () => (
  <>
    {' '}
    <div className='card mb-4 card-main flex-fill'>
      <div className='card-header  py-7550  align-items-center justify-content-between'>
        <div className='row'>
          <div className='col-md-3 head-col-lg'>
            <h6 className='font-book '>
              <Skeleton color='#e5e9ef' height={'25px'} />
            </h6>
          </div>

          <div className='col-md-9 filter-col-lg'>
            <div className='dp-right mr-2'>
              <Skeleton color='#e5e9ef' height={'25px'} />
            </div>
            <div className='dp-right mr-2'>
              <Skeleton color='#e5e9ef' height={'25px'} />
            </div>
            <div className='dp-right mr-2'>
              <Skeleton color='#e5e9ef' height={'25px'} />
            </div>
          </div>
        </div>
      </div>
      <div className='card-body card-table'>
        <div className='row mtblf-5'>
          <div className='col dv-5 skeleton-bottom'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
          <div className='col dv-5 skeleton-bottom'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
          <div className='col dv-5 skeleton-bottom'>
            <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
            <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
          </div>
        </div>
      </div>
    </div>
  </>
);
