import Validator from "js-object-validation";
import { message } from "../common";

export const addressValidator = (data) => {
  const validations = {
    addressType: {
      required: true,
    },
    addressLine1: {
      required: true,
    },
    addressLine2: {
      required: true,
    },
    state: {
      required: true,
    },
    country: {
      required: true,
    },
    postCode: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    addressType: {
      required: message.Required.replace(":item", "address type"),
    },
    addressLine1: {
      required: message.Required.replace(":item", "address line1"),
    },
    addressLine2: {
      required: message.Required.replace(":item", "address line2"),
    },
    state: {
      required: message.Required.replace(":item", "state"),
    },
    country: {
      required: message.Required.replace(":item", "country"),
    },
    postCode: {
      required: message.Required.replace(":item", "postCode"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
