import React from "react";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";
import { FaqCategoryList } from "../faqs-category/FaqCategoryList";

export const ListComponent = ({
  faqs,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th style={{ width: "300px" }}>Category Name </th>
                <th style={{ width: "300px" }}>Icon </th>
                <th style={{ width: "300px" }}>Updated On </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {faqs && faqs.length ? (
                faqs.map((faq, index) => (
                  <FaqCategoryList
                    key={index}
                    faqData={faq}
                    onEdit={() => onEdit(faq)}
                    onDelete={() => onDelete(faq.id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={5} className={"text-center image-no-data-xs"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
