import React from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../config";
import listIcon from "../../../assets/img/list.svg";
import phoneIcon from "../../../assets/img/phone.svg";
import minusCircleIcon from "../../../assets/img/minus-circle.svg";
import minusCircleRightIcon from "../../../assets/img/minus-circle-light.svg";
import "./user.css";

const UserRow = ({
  userData,
  isUpdating,
  viewUser,
  blockUser,
  unBlockUser,
}) => {
  const {
    first_name = "-",
    last_name = "",
    email = "",
    total_scans = 0,
    total_counterfeits = 0,
    created_at,
    block = false,
    phone = "",
  } = userData;
  console.log("userData", userData);
  return (
    <tr>
      <td className={"cursor-pointer"} onClick={viewUser}>
        {[first_name, last_name].filter(Boolean).join(" ") || "-"}
      </td>
      <td
        className={"cursor-pointer"}
        onClick={() => {
          if (email) {
            window.open(`mailto:${email}`);
          }
        }}
      >
        {email || "-"}
      </td>
      <td>{total_scans}</td>
      <td>{total_counterfeits}</td>
      <td>
        {created_at ? moment(created_at).format(DEFAULT_DATE_FORMAT) : "-"}
      </td>
      <td>
        <button onClick={viewUser} className="btn btn-secondary th-btn">
          {" "}
          <img src={listIcon} alt="" /> View{" "}
        </button>
        <button
          className="btn btn-secondary th-btn"
          disabled={!phone}
          onClick={() => window.open(`tel:${phone}`)}
        >
          {" "}
          <img src={phoneIcon} alt="" /> Contact{" "}
        </button>
        {/* {!email ? (
          <OverlayTrigger
            placement='auto'
            delay={{ show: 250, hide: 400 }}
            overlay={p => (
              <Tooltip id='button-tooltip' {...p}>
                This user doesn't have an email set with the account.
              </Tooltip>
            )}
          >
            <button
              
              onClick={contactUser}
              className='btn btn-secondary th-btn'
            >
              {' '}
              <img src={phoneIcon} alt='' /> Contact{' '}
            </button>
          </OverlayTrigger>
        ) : (
          <button
            
            onClick={contactUser}
            className='btn btn-secondary th-btn'
            onClick={() => window.open(`mailto:${email}`)}
          >
            <img src={phoneIcon} alt='' /> Contact{' '}
          </button>
        )} */}
        {block ? (
          <button
            onClick={unBlockUser}
            disabled={isUpdating}
            className="btn btn-secondary th-btn"
          >
            {" "}
            {isUpdating ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              <img src={minusCircleIcon} alt="" />
            )}{" "}
            Unblock
          </button>
        ) : (
          <button
            onClick={blockUser}
            disabled={isUpdating}
            className="btn btn-secondary th-btn block-wd"
          >
            {" "}
            {isUpdating ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              <img src={minusCircleRightIcon} alt="" />
            )}{" "}
            Block{" "}
          </button>
        )}
      </td>
    </tr>
  );
};

export default UserRow;
