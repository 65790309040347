/** @format */

import React from "react";
import listIcon from "../../../assets/img/list.svg";
import listIconWhite from "../../../assets/img/list-white.svg";
import { AppConfig } from "../../../config";
import dummyclient from "../../../assets/img/noimage.jpeg";
import phoneIconWhite from "../../../assets/img/phone-white.svg";
import minusCircleIcon from "../../../assets/img/minus-circle.svg";
import minusCircleIconWhite from "../../../assets/img/minus-circle-white.svg";
import mailIcon from "../../../assets/img/mailIcon.svg";
import phoneIcon from "../../../assets/img/phoneIcon.svg";
import profileIcon from "../../../assets/img/profileIcon.svg";
import locIcon from "../../../assets/img/locIcon.svg";

import "./index.css";

export const GridClientComponent = ({
  clients,
  isUpdating,
  blockClient,
  unBlockClient,
  viewHandler,
  activeId,
}) => {
  return clients.map((clientData, index) => {
    const {
      _id,
      first_name = "",
      last_name = "",
      store = {},
      subscriptionPlan = {},
      phone = "",
      email = "",
      block = false,
      clientId = "",
      isDeleted = false,
    } = clientData;
    const {
      companyName = "",
      // brandName = "",
      // storeName = "",
      website = "",
      country = "",
      storeLogo = "",
    } = store || {};
    const { name = "" } = subscriptionPlan || {};
    return (
      <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 d-flex" key={index}>
        <div className="client_head clientInfo flex-fill">
          {/* DeleteCorner */}
          <div className={isDeleted ? "DeleteCorner" : "newCorner"}>
            <p>{!isDeleted ? `${name}` : "Deleted"}</p>
          </div>

          <div className="padding-Row">
            <div className="text-center">
              {storeLogo ? (
                <img
                  className="grid-view-img"
                  src={
                    storeLogo
                      ? storeLogo.startsWith("https://")
                        ? storeLogo
                        : `${AppConfig.FILES_ENDPOINT}${storeLogo}`
                      : ""
                  }
                  alt=""
                />
              ) : (
                <div className="img_placeholder">
                  {companyName ? companyName.substring(0, 1) : ""}
                </div>
              )}
            </div>
            <div className="text-center">
              <h4 className="cl_name">{companyName || "N/A"}</h4>
              <h4 className="cl_desc row m-0">
                <span className="col-12 overflow-hidden">
                  {website ? (
                    <a
                      className="mailto"
                      href={
                        website.startsWith("http://") ||
                        website.startsWith("https://")
                          ? website
                          : `http://${website}`
                      }
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      {website}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </span>
              </h4>
              <div className="card_id">{clientId || "N/A"}</div>
            </div>
          </div>

          <div className="ownerInfo mt-2">
            <div className="holder">
              <img src={mailIcon} className="mr-2" alt="" />
              <p className="emailInfo">
                {email ? (
                  <a className="mailto" href={`mailto:${email}`} title={email}>
                    {email}
                  </a>
                ) : (
                  "N/A"
                )}
              </p>
            </div>
            <div
              className="holder cursor-pointer"
              onClick={() => window.open(`tel:${phone ? phone : store?.phone}`)}
            >
              <img src={phoneIcon} className="mr-2" alt="" />
              <p className="otherInfo">
                {phone ? phone : store?.phone || "N/A"}
              </p>
            </div>
            <div className="holder">
              <img src={profileIcon} className="mr-2" alt="" />
              <p className="otherInfo">
                {[first_name, last_name].filter(Boolean).join(" ") || "N/A"}
              </p>
            </div>
            <div className="holder">
              <img src={locIcon} className="mr-2" alt="" />
              <p className="otherInfo">{country ? country : "N/A"}</p>
            </div>
          </div>

          <div className="clientFooter">
            <button
              className="btn viewBtn cl-btn-lg btn-img-hover btn-primary"
              onClick={() => viewHandler(_id)}
            >
              <span>
                <img src={listIconWhite} alt="" />
              </span>
              View
            </button>

            <button
              className="btn cl-btn-sm-1 btn-img-hover btn-sm-img-hover btn-primary"
              disabled={!phone && !store?.phone}
              onClick={() => window.open(`tel:${phone ? phone : store?.phone}`)}
            >
              <span>
                <img src={phoneIcon} alt="" className={"img-main"} />
                <img src={phoneIconWhite} alt="" className={"img-hover"} />
              </span>
            </button>
            {block ? (
              <button
                onClick={() => unBlockClient(_id)}
                disabled={isUpdating && activeId === _id ? true : false}
                className="btn cl-btn-sm-2 btn-img-hover btn-sm-img-hover btn-danger"
              >
                {" "}
                {isUpdating && activeId === _id ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <span>
                    <img src={minusCircleIcon} alt="" className={"img-main"} />
                    <img
                      src={minusCircleIconWhite}
                      alt=""
                      className={"img-hover"}
                    />
                  </span>
                )}
              </button>
            ) : (
              <button
                onClick={() => blockClient(_id)}
                disabled={isUpdating && activeId === _id ? true : false}
                className="btn cl-btn-sm-2 btn-img-hover btn-sm-img-hover btn-danger"
              >
                {" "}
                {isUpdating && activeId === _id ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <span>
                    <img src={minusCircleIcon} alt="" className={"img-main"} />
                    <img
                      src={minusCircleIconWhite}
                      alt=""
                      className={"img-hover"}
                    />
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  });
};
