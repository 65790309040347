import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { addStaffValidator } from "../../../../validators";
import { InputTextField, SubmitButton } from "../../../components/forms";
import AsyncSelect from "react-select/async";

export default function AddStaff({
  modalShow,
  modalClose,
  roleOptions,
  reloadAPI,
}) {
  let roleOptionFilter = [];
  roleOptions &&
    roleOptions.forEach((element) => {
      roleOptionFilter.push({
        label: element.roleName,
        value: element.id,
      });
    });

  console.log("roleOptionFilter", roleOptionFilter);

  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(null);
  const [inviteData, setInviteData] = useState({
    errors: null,
    first_name: "",
    last_name: "",
    role_id: "",
    email: "",
    name: "",
  });

  const handleChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;

    setInviteData({
      ...inviteData,
      [name]:
        type === "checkbox"
          ? checked
          : value.charAt(0).toUpperCase() + value.slice(1) && value.trimLeft(),
      errors: { ...inviteData.errors, [name]: "" },
    });
  };

  const onSelectOption = (options, name) => {
    if (options) {
      setInviteData({
        ...inviteData,
        [name]: options,
      });
    }
  };

  console.log("inviteData", inviteData);
  const handleEmailChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;

    setInviteData({
      ...inviteData,
      [name]: type === "checkbox" ? checked : value.trimLeft(),
      errors: { ...inviteData.errors, [name]: "" },
    });
  };

  const addClient = async (data) => {
    setIsAdding(true);

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_STAFF.service,
      ApiRoutes.ADD_STAFF.url,
      ApiRoutes.ADD_STAFF.method,
      ApiRoutes.ADD_STAFF.authenticate,
      undefined,
      data
    );
    if (response && response.isError) {
      setIsAdding(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      reloadAPI();
      setIsAdding(false);
      setInviteData({
        first_name: "",
        last_name: "",
        role_id: "",
        email: "",
        name: "",
      });
    }
  };
  const handleSubmit = (event) => {
    event && event.preventDefault();
    var payload = {
      email: inviteData.email,
      name: inviteData.first_name,
      role_id: inviteData.role_id ? inviteData.role_id.value : "",
    };
    const { isValid, errors } = addStaffValidator(payload);
    payload.name = inviteData.first_name + " " + inviteData.last_name;
    if (isValid) {
      addClient(payload);
    } else {
      setError(errors);
      return;
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
  };
  return (
    <>
      <Modal
        show={modalShow}
        onHide={modalClose}
        className="modal fade invitestaffmodal"
      >
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <label className={`form-control-label`}>First name</label>

                <InputTextField
                  placeholder="Enter First Name"
                  className={"form-control-user disable-write"}
                  name="first_name"
                  value={inviteData?.first_name}
                  onChange={handleChange}
                  maxLength={30}
                  errorMessage={error && error.name ? error.name : ""}
                />
              </div>

              <div className="col-md-6">
                <label className={`form-control-label`}>Last name</label>

                <InputTextField
                  placeholder="Enter Last Name"
                  className={"form-control-user disable-write"}
                  name="last_name"
                  value={inviteData?.last_name}
                  onChange={handleEmailChange}
                  maxLength={30}
                  // errorMessage={error && error.name ? error.name : ""}
                />
              </div>

              <div className="col-md-6">
                <label className={`form-control-label`}>Email</label>

                <InputTextField
                  placeholder="Enter Email"
                  className={"form-control-user disable-write"}
                  name="email"
                  type="email"
                  value={inviteData?.email}
                  onChange={handleEmailChange}
                  maxLength={30}
                  errorMessage={error && error.email ? error.email : ""}
                />
              </div>

              <div className="col-md-6">
                <label className={`form-control-label`}>Role</label>

                <AsyncSelect
                  //   loadOptions={loadStateData}
                  placeholder={"Select"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  value={inviteData?.role_id}
                  defaultOptions={roleOptionFilter}
                  onChange={(selectedOption) =>
                    onSelectOption(selectedOption, "role_id")
                  }
                  noOptionsMessage={() => {
                    "No states found";
                  }}
                />
                <div className="text-danger">
                  {roleOptions?.length > 0
                    ? ""
                    : "Please add role from the manage role module"}
                </div>
                {error && (
                  <div className="text-danger">
                    {error && error.role_id ? error.role_id : ""}
                  </div>
                )}
              </div>

              <div className="col-md-12">
                <div
                  className="row flex-column-reverse flex-md-row"
                  style={{ marginTop: "14px" }}
                >
                  <div className="col-md-6 mt-2 mt-md-0">
                    <span className="customCancelBtn m-0" onClick={modalClose}>
                      Cancel
                    </span>
                  </div>
                  <div className="col-md-6">
                    <SubmitButton
                      className={"customSubmitBtn"}
                      text={"Send Invitation"}
                      isLoading={isAdding}
                      onSubmit={handleSubmit}
                      // disabled={!inviteData.first_name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
