/** @format */

import React from "react";
import Select from "react-select";
import { InputTextField } from "../forms";
import { BatchViewLoader } from "../../components/loader/Resusable/BatchView";
import user from "../../../assets/img/group-12.png";
import penEdit from "../../../assets/img/penEdit.svg";
import moment from "moment";
import DatePicker from "react-datepicker";
import { AppConfig, customReactSelectStyles } from "../../../config";
import AsyncSelect from "react-select/async";
import { ApiHelper } from "../../../helpers";

export const ContactDetailViewComponent = ({
  clientData,
  isEditable,
  isLoading,
  onSelectOption,
  onChange,
  onDateChange,
  onFileChange,
  genderOptions,
  onSelectCountryOption,
  allCountry,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
  };
  const loadCountryData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  const {
    first_name = "",
    last_name = "",
    profile_picture = "",
    errors = {},
    gender = "",
    birth_date = "",
    country = "",
  } = clientData;
  return (
    <div className="card">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <div className="row">
          <div className="col-md-4">
            <div style={{ maxWidth: "264px" }}>
              <div className="imgViewBox">
                <img
                  style={{ height: "264px" }}
                  src={
                    profile_picture
                      ? profile_picture.startsWith("https://")
                        ? profile_picture
                        : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                      : user
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = user;
                  }}
                  alt=""
                />
                <label className="">
                  <img className="penEdit" src={penEdit} />
                  <input
                    name="profile_picture"
                    type="file"
                    accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                    hidden
                    disabled={!isEditable}
                    onChange={onFileChange}
                  />
                </label>
              </div>
              <h6 className="userName">
                {[first_name, last_name].filter(Boolean).join(" ") || "N/A"}
              </h6>
            </div>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-6">
                <h4>First Name</h4>
                {isEditable ? (
                  <div className="col-md-12 pl-0 pb-2">
                    <InputTextField
                      id="first_name"
                      className={"form-control-user"}
                      name="first_name"
                      value={first_name}
                      maxLength={30}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.first_name ? errors.first_name : ""
                      }
                    />
                  </div>
                ) : (
                  <h5>{first_name}</h5>
                )}
              </div>
              <div className="col-md-6">
                <h4>Last Name</h4>
                {isEditable ? (
                  <div className="col-md-12 pl-0 pb-2">
                    <InputTextField
                      id="last_name"
                      className={"form-control-user"}
                      name="last_name"
                      value={last_name}
                      maxLength={30}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.last_name ? errors.last_name : ""
                      }
                    />
                  </div>
                ) : (
                  <h5>{last_name}</h5>
                )}
              </div>
              <div className="col-md-6">
                <h4>Gender </h4>
                {isEditable ? (
                  <div className="col-md-12 pl-0 pb-2">
                    <div className="form-group">
                      <AsyncSelect
                        placeholder={""}
                        defaultOptions={genderOptions}
                        styles={customReactSelectStyles}
                        value={gender}
                        onChange={(selectedOptions) =>
                          onSelectOption(selectedOptions)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <h5>
                    {gender && gender.label
                      ? gender.label
                      : gender.value
                      ? gender
                      : "N/A"}
                  </h5>
                )}
                {/* <h5>
                  {gender && gender.label
                    ? gender.label
                    : gender.value
                    ? gender
                    : "N/A"}
                </h5> */}
              </div>
              <div className="col-md-6">
                <h4>Date of Birth</h4>
                {isEditable ? (
                  <div className="col-md-12 pl-0 pb-2">
                    <DatePicker
                      className={"form-control-input"}
                      placeholder={`Date of Birth(DD/MM/YYYY)`}
                      selected={birth_date}
                      maxDate={new Date()}
                      onChange={onDateChange}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                ) : (
                  <h5>{moment(birth_date).format("DD/MM/YYYY")}</h5>
                )}
                {/* <h5>{moment(birth_date).format("DD/MM/YYYY")}</h5> */}
              </div>
              <div className="col-md-6">
                <h4>Nationality</h4>
                {isEditable ? (
                  <div className="col-md-12 pl-0 pb-2">
                    <AsyncSelect
                      loadOptions={loadCountryData}
                      placeholder={"Select"}
                      styles={customStyles}
                      maxMenuHeight={200}
                      defaultOptions={allCountry}
                      value={{
                        label: country,
                        value: country,
                      }}
                      onChange={(selectedOption) =>
                        onSelectCountryOption(selectedOption, "country")
                      }
                      noOptionsMessage={() =>
                        "No option found related to your search"
                      }
                    />
                  </div>
                ) : (
                  <h5>{country}</h5>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
