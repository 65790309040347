/** @format */

import React from "react";
import { ProgressBar } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { InputTextField } from "../forms";
import {
  AppConfig,
  genderOptions,
  customReactSelectStyles,
} from "../../../config";
import defaultImg from "../../../assets/img/group-12.png";
import trashIcon from "../../../assets/img/trash-2.svg";
import "react-datepicker/dist/react-datepicker.css";

export const InvitationFormComponent = ({
  staffData,
  isLoading,
  isUploading,
  uploadPercentage,
  onChange,
  onSelect,
  onFileChange,
  onDateChange,
  onDeleteFile,
}) => {
  const {
    email = "",
    employee_id = "",
    first_name = "",
    last_name = "",
    gender = "",
    office_location = "",
    phone = "",
    profile_picture = "",
    birth_date = "",
    office_phone = "",
    personal_email = "",
    address1 = "",
    errors = {},
  } = staffData;

  return (
    <form className="user_add mt-2">
      <div className="row">
        <div className="col-md-2 col-img-sf stf-img">
          <div className="text-center">
            <div className="img-col-staff">
              <img
                className="user-img"
                // src={
                //   profile_picture
                //     ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                //     : defaultImg
                // }
                src={
                  profile_picture
                    ? profile_picture.startsWith("https://")
                      ? profile_picture
                      : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                    : defaultImg
                }
                alt=""
              />
            </div>
          </div>
          {isUploading ? (
            <ProgressBar now={uploadPercentage} className={"mt-2"} />
          ) : null}
          <div className="file-upload-btn-sf stf-invitaion ">
            <label className="file-label">
              {" "}
              {profile_picture ? "Change Image" : "Choose Image"}
              <input
                className="imgUpload img_up"
                name="profile_picture"
                type="file"
                accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                onChange={onFileChange}
              />
            </label>
            {profile_picture ? (
              <span onClick={onDeleteFile} className={"cursor-pointer"}>
                <img src={trashIcon} alt="" />
              </span>
            ) : null}
          </div>
          <div className={"text-danger"}>
            {errors && errors.profile_picture ? errors.profile_picture : ""}
          </div>

          <div className="st-name-head center">
            <h4 className="sf_name">
              {" "}
              {[first_name, last_name].filter(Boolean).join(" ") || "-"}{" "}
            </h4>
            <h4 className="sf_desc">{employee_id}</h4>
          </div>
        </div>
        <div className="col-md-9 col-det-sf">
          <div className="row">
            <div className="col-md-4">
              <h4 className="form-head">Basic Information</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <InputTextField
                id="employee_id"
                label={"Employee ID"}
                className={"form-control-user"}
                name="employee_id"
                value={employee_id}
                maxLength={30}
                readOnly={true}
              />
            </div>
            <div className="col-md-4">
              <InputTextField
                id="email"
                label={"Email"}
                className={"form-control-user"}
                name="email"
                value={email}
                readOnly={true}
                // onChange={onChange}
              />
            </div>
            <div className="col-md-4">
              <InputTextField
                id="phone"
                label={"Phone Number"}
                className={"form-control-user"}
                name="office_phone"
                value={office_phone}
                onChange={onChange}
                maxLength={14}
                errorMessage={
                  errors && errors.office_phone ? errors.office_phone : ""
                }
              />
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>Gender</label>
                <Select
                  placeholder={""}
                  options={genderOptions}
                  styles={customReactSelectStyles}
                  value={gender}
                  onChange={(selectedOption) => onSelect(selectedOption)}
                />
              </div>
              {/* <InputTextField
                        id='gender'
                        label={'Gender'}
                        className={'form-control-user'}
                        name='gender'
                        value={gender}
                      /> */}
            </div>
            {/* <div className='col-md-4'>
                      <InputTextField
                        id='date_of_joining'
                        label={'Date of Joining'}
                        className={'form-control-user'}
                        name='date_of_joining'
                        value={date_of_joining}
                      />
                    </div> */}
            <div className="col-md-4">
              <InputTextField
                id="office_location"
                label={"Working Location"}
                className={"form-control-user"}
                name="office_location"
                value={office_location}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <h4 className="form-head">Personal Information</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>Date of Birth</label>
                <DatePicker
                  className={"form-control-input"}
                  placeholder={`Expiry Date (DD/MM/YYYY)`}
                  selected={birth_date}
                  maxDate={new Date()}
                  onChange={onDateChange}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              {/* <InputTextField
                        id='date_of_birth'
                        label={'Date of Birth'}
                        className={'form-control-user'}
                        name='date_of_birth'
                        value={date_of_birth}
                        maxLength={30}
                        readOnly={true}
                      /> */}
            </div>
            <div className="col-md-4">
              <InputTextField
                id="per_phone"
                label={"Phone Number"}
                className={"form-control-user"}
                name="phone"
                value={phone}
                onChange={onChange}
                maxLength={14}
                errorMessage={errors && errors.phone ? errors.phone : ""}
              />
            </div>
            <div className="col-md-4">
              <InputTextField
                id="personal_email"
                label={"E-Mail"}
                className={"form-control-user"}
                name="personal_email"
                value={personal_email}
                onChange={onChange}
                errorMessage={
                  errors && errors.personal_email ? errors.personal_email : ""
                }
              />
            </div>

            <div className="col-md-8">
              <InputTextField
                id="address1"
                label={"Address"}
                className={"form-control-user"}
                name="address1"
                value={address1}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
