import Validator from "js-object-validation";
import { message } from "../common";

export const permissionValidator = (data) => {
  const validations = {
    selectedModule: {
      required: true,
    },
    // subModules: {
    //   required: true,
    // },
    accessType: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    selectedModule: {
      required: message.Required.replace(":item", "module"),
    },
    subModules: {
      required: message.Required.replace(":item", "sub module"),
    },
    accessType: {
      required: message.Required.replace(":item", "access type"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
