import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import { DEFAULT_DATE_FORMAT } from "../../../config";
import moment from "moment";

export const VideoRow = ({ videosData, onEdit, onDelete }) => {
  const {
    videoName = "",
    videoDescription = "",
    videoLink = "",
    updatedAt = "",
  } = videosData;

  return (
    <tr>
      <td>{videoName}</td>
      <td>
        <div
          style={{
            width: "300px",
            maxWidth: "300px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            overflowWrap: "break-word",
          }}
        >
          {videoDescription}
        </div>
      </td>
      <td>
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            overflowWrap: "break-word",
            maxWidth: "200px",
          }}
        >
          <a
            style={{
              fontSize: "14px",
              fontWeight: "normal",
              fontFamily: "CircularStd-Book",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "1.71",
              letterSpacing: "normal",
              color: "#8a94a6",
              textDecoration: "none",
            }}
            href={videoLink}
            target="_blank"
          >
            {videoLink}
          </a>
        </div>
      </td>
      <td>{updatedAt ? moment(updatedAt).format(DEFAULT_DATE_FORMAT) : "-"}</td>
      <td>
        <div
          className="d-flex align-items-start justify-content-end"
          style={{
            width: "100%",
          }}
        >
          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={onEdit}
          >
            <span>
              <img src={editIcon} alt="" className={"img-main"} />
              <img src={editIconWhite} alt="" className={"img-hover"} />
            </span>
            Edit
          </Button>
          <Button
            variant="danger"
            className="btn th-btn delete-tb action-block-btn"
            onClick={onDelete}
          >
            <span>
              <img src={trashIcon} alt="" className={"img-main"} />
              <img src={trashIconWhite} alt="" className={"img-hover"} />
            </span>
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};
