import React from 'react';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '../../../config';
import { CounterfeitProduct } from '../../components/loader/Resusable/CounterfeitProduct';
import fileMinusIcon from '../../../assets/img/file-minus.svg';
import windowMinusIcon from '../../../assets/img/full-minus.svg';
import tagIcon from '../../../assets/img/shape-copy.svg';
import calenderIcon from '../../../assets/img/calender.svg';

export const ProductDetails = ({ productDetails, batchDetails, isLoading }) => {
  const { batchNumber = '-', expiryDate = '' } = batchDetails || {};
  const { productName = '', productNumber = '' } = productDetails || {};
  return (
    <div className='card mb-4 card-main flex-fill'>
      <div className='card-header  py-7550  align-items-center justify-content-between'>
        <div className='row'>
          <div className='col-md-12'>
            <h6 className='font-book '>Product Details</h6>
          </div>
        </div>
      </div>
      <div className='card-body card-table'>
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className='row mtblf-5'>
              <div className='col-md-6'>
                <div className='heading-col'>
                  <img src={fileMinusIcon} alt='' />
                  <h5>Product Number</h5>
                </div>
                <div className='description-col'>
                  <h5>{productNumber || '-'}</h5>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='heading-col'>
                  <img src={windowMinusIcon} alt='' />
                  <h5>Product Name</h5>
                </div>
                <div className='description-col'>
                  <h5>{productName || '-'}</h5>
                </div>
              </div>
            </div>
            <div className='row mtblf-5'>
              <div className='col-md-6'>
                <div className='heading-col'>
                  <img src={tagIcon} alt='' />
                  <h5>Batch Number</h5>
                </div>
                <div className='description-col'>
                  <h5>{batchNumber || '-'}</h5>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='heading-col'>
                  <img src={calenderIcon} alt='' />
                  <h5>Expiry Date</h5>
                </div>
                <div className='description-col'>
                  <h5>
                    {expiryDate
                      ? moment(expiryDate).format(DEFAULT_DATE_FORMAT)
                      : '-'}
                  </h5>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
