import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import icon_faqs from "../../../assets/img/icon_faqs.svg";
import Switch from "react-switch";
import { AppConfig, DEFAULT_DATE_FORMAT } from "../../../config";
import moment from "moment";
export const FaqCategoryList = ({ faqData, onEdit, onDelete }) => {
  const { updatedAt = "", categoryImg = "", categoryName = "" } = faqData;

  return (
    <tr>
      <td>{categoryName}</td>
      <td>
        <div className="icon_faqsBox">
          <img
            src={
              categoryImg
                ? categoryImg.startsWith("https://")
                  ? categoryImg
                  : `${AppConfig.FILES_ENDPOINT}${categoryImg}`
                : ""
            }
            alt=""
          />
        </div>
      </td>
      <td>{updatedAt ? moment(updatedAt).format(DEFAULT_DATE_FORMAT) : "-"}</td>
      <td>
        <Button
          className="btn btn-secondary th-btn edit-tb action-block-btn"
          onClick={onEdit}
        >
          <span>
            <img src={editIcon} alt="" className={"img-main"} />
            <img src={editIconWhite} alt="" className={"img-hover"} />
          </span>
          Edit
        </Button>
        <Button
          variant="danger"
          className="btn th-btn delete-tb action-block-btn"
          onClick={onDelete}
        >
          <span>
            <img src={trashIcon} alt="" className={"img-main"} />
            <img src={trashIconWhite} alt="" className={"img-hover"} />
          </span>
          Delete
        </Button>
      </td>
    </tr>
  );
};
