import React from "react";
import listIcon from "../../../assets/img/list.svg";
import phoneIcon from "../../../assets/img/phone.svg";
import reopenIcon from "../../../assets/img/corner-up-right_new.svg";
// import minusCircleIcon from '../../../assets/img/minus-circle.svg';

export const CounterfeitRow = ({
  counterfeitData,
  viewHandler,
  changeRequestRequest,
}) => {
  const {
    scan = {},
    batch = {},
    product = {},
    assigneeName = "",
    user = {},
    status = "",
    reportId = "",
    _id = "",
    // isManual = '',
    // manualProduct = {}, // In case of manual report
  } = counterfeitData;
  console.log(counterfeitData);
  const { scanId } = scan || {};
  const { batchNumber = "" } = batch || {};
  const { productName = "" } = product || {};
  const { email = "" } = user || {};
  const renderStatus = () => {
    switch (status) {
      case "Acknowledged":
        return <span className="warning-span">Acknowledged</span>;
      case "Assigned":
        return <span className="warning-light-span">Assigned</span>;
      case "Cancelled":
        return <span className="danger-span">Cancelled</span>;
      case "Completed":
        return <span className="success-span">Completed</span>;
      case "In Process":
        return <span className="danger-span">Cancelled</span>;
      case "Reopened":
        return <span className="primary-span">Reopened</span>;
      case "In Review":
        return <span className="primary-span">In Review</span>;
      default:
        return <span className="warning-span">Acknowledged</span>;
    }
  };
  return (
    <tr>
      <td>{scanId || reportId}</td>
      <td>{batchNumber || "-"}</td>
      <td>{productName || "-"}</td>
      <td>{assigneeName || "-"}</td>
      <td>{renderStatus()}</td>
      <td>
        <button className="btn btn-secondary th-btn" onClick={viewHandler}>
          {" "}
          <img src={listIcon} alt="" /> View{" "}
        </button>
        <button
          className="btn btn-secondary th-btn"
          onClick={() => window.open(`mailto:${email}`)}
        >
          {" "}
          <img src={phoneIcon} alt="" /> Contact{" "}
        </button>
        <button
          onClick={() => changeRequestRequest("Reopened", _id)}
          className="btn btn-secondary th-btn"
        >
          {" "}
          <img src={reopenIcon} alt="" /> Reopen{" "}
        </button>
      </td>
    </tr>
  );
};
