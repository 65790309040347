import Validator from 'js-object-validation';
import { message } from '../common';
import { replaceAll } from '../helpers';


export const pushValidator = data => {
  const validations = {
    title: {
      required: true,
      maxlength: 30,
    },
    subject: {
      required: true,
      maxlength: 100,
    },
    sendTo: {
      required: true,
    },
    dateScheduled: {
      required: true,
    },
    timeScheduled: {
      required: true,
    }
  };
  // Error messages
  const messages = {
    title: {
      required: message.Required.replace(':item', 'Title'),
      maxlength: replaceAll(message.MaxLengthError, {
        ':item': 'Title',
        ':length': 30,
      }),
    },
    subject: {
      required: message.Required.replace(':item', 'Subject'),
      maxlength: replaceAll(message.MaxLengthError, {
        ':item': 'Subject',
        ':length': 100,
      }),
    },
    sendTo: {
      required: message.Required.replace(':item', 'Send to'),
    },
    dateScheduled: {
      required: message.Required.replace(':item', 'Date'),
    },    
    timeScheduled: {
      required: message.Required.replace(':item', 'Time'),
    }
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
