import React from "react";

export const InputTextField = ({
  type = "text",
  id = "",
  placeholder = "",
  label = "",
  className,
  name,
  value,
  maxLength,
  readOnly = false,
  disabled = false,
  errorMessage = "",
  onChange,
  min,
  autoComplete,
  autoFill,
}) => {
  let otherAttributes = {};
  if (maxLength) {
    otherAttributes = {
      ...otherAttributes,
      maxLength,
    };
  }
  return (
    <div className="form-group">
      {label ? (
        <label htmlFor={id} className={`form-control-label ${className}`}>
          {label}
        </label>
      ) : null}
      <input
        type={type}
        className={`form-control form-control-input ${
          errorMessage ? "has-error" : ""
        } ${className}`}
        id={id}
        placeholder={placeholder}
        name={name}
        value={value || ""}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        autoComplete={autoComplete}
        autoFill={autoFill}
        min={min || ""}
        {...otherAttributes}
      />{" "}
      <div className={"text-danger"}>{errorMessage}</div>
    </div>
  );
};
