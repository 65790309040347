import React from "react";
import Skeleton from "react-skeleton-loader";

export const TermLoader = () => (
  <>
    {" "}
    <div className="card-header  py-7550  align-items-center justify-content-between">
      <div className="row">
        <div className="col-md-12">
          <h6 className="font-book ">
            <Skeleton height={"20px"} width={"70%"} color="#e5e9ef" />
          </h6>
        </div>
      </div>
    </div>
    <div className="card-body card-table">
      <form className="">
        <div className="row mb-3">
          <div className="col-md-12">
            <Skeleton height={"283px"} width={"100%"} color="#e5e9ef" />
          </div>
          <div className="col-md-12 footer-form">
            <div className="d-flex align-items-center justify-content-center contactForm_admin">
              <div className="mr-3">
                <Skeleton height={"45px"} width={"190px"} color="#e5e9ef" />
              </div>
              <Skeleton height={"45px"} width={"190px"} color="#e5e9ef" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </>
);
