import React from "react";
import defaultImg from "../../../assets/img/group-12.png";
import trashIcon from "../../../assets/img/trash-2.svg";
import { InputTextField } from "../forms";
import DatePicker from "react-datepicker";
import birthDateCalender from "../../../assets/img/birthDateCalender.svg";
import { BatchViewLoader } from "../../components/loader/Resusable/BatchView";
import eye_password from "../../../assets/img/eye_password.svg";
import moment from "moment";
import { useState } from "react";
import { AppConfig } from "../../../config";

export const SettingsForm = ({
  handleDateChange,
  profileData,
  handleChange,
  handleSubmit,
  loading,
  isUpdating,
  handleGenderChange,
  errors,
  onFileChange,
  onDeleteFile,
  onCancel,
  onShow,
  changePass,
}) => {
  const [showPass, setShowPass] = useState(true);
  const [showPass2, setShowPass2] = useState(true);

  return (
    <>
      <div className="card mb-3 card-main flex-fill viewStaffDetailNew settingsProfile">
        <div className="card-header py-7550  align-items-center justify-content-between">
          <div className="row">
            <div className="col-md-3">
              <h6 className="font-book ">Edit Profile</h6>
            </div>
          </div>
        </div>
        <div className="card-body card-table">
          {loading ? (
            <BatchViewLoader />
          ) : (
            <form
              className="user_add mt-2 mb-4"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <div className="row">
                <div className="col-md-2 col-img-sf">
                  <div className="text-center">
                    <div className="img-col-staff">
                      <img
                        className="user-img"
                        // src={
                        //   profileData?.profile_picture
                        //     ? `${AppConfig.FILES_ENDPOINT}${profileData?.profile_picture}`
                        //     : defaultImg
                        // }
                        src={
                          profileData?.profile_picture
                            ? profileData?.profile_picture.startsWith(
                                "https://"
                              )
                              ? profileData?.profile_picture
                              : `${AppConfig.FILES_ENDPOINT}${profileData?.profile_picture}`
                            : defaultImg
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="file-upload-btn-sf  d-flex justify-content-center">
                    <label className="file-label">
                      Upload Image
                      <input
                        className="imgUpload img_up"
                        name="profile_picture"
                        type="file"
                        accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                        onChange={onFileChange}
                      />
                    </label>
                    <span onClick={onDeleteFile} className={"cursor-pointer"}>
                      <img src={trashIcon} alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-md-9 col-det-sf">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="form-head">Basic Information</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <InputTextField
                        label={"First Name"}
                        value={profileData?.first_name}
                        name="first_name"
                        onChange={handleChange}
                        className={"form-control-user"}
                        errorMessage={errors?.first_name}
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        label={"Last Name"}
                        className={"form-control-user"}
                        value={profileData?.last_name}
                        name="last_name"
                        onChange={handleChange}
                        errorMessage={errors?.last_name}
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        label={"Phone Number"}
                        className={"form-control-user"}
                        value={profileData?.phone}
                        name="phone"
                        maxLength={14}
                        onChange={handleChange}
                        errorMessage={errors?.phone}
                      />
                    </div>
                    <div className="col-md-4">
                      <InputTextField
                        label={"Email"}
                        className={"form-control-user"}
                        value={profileData?.email}
                        name="email"
                        // onChange={handleChange}
                        disabled={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="form-group datePicker viewDetail">
                        <label className={`form-control-label`}>
                          Date of Birth
                        </label>
                        <DatePicker
                          className={"form-control-input"}
                          placeholder={`Date of Birth(DD/MM/YYYY)`}
                          selected={profileData?.birth_date}
                          maxDate={moment().subtract(18, "y")._d}
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          // disabled={!isEditable}
                        />
                        <div className="icon">
                          <img src={birthDateCalender} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <label
                        class="form-control-label"
                        style={{ marginBottom: "14px" }}
                      >
                        Gender
                      </label>
                      <div className="d-flex align-items-center">
                        <label className="container-checkbox">
                          Male
                          <input
                            type="radio"
                            checked={profileData?.gender === "Male"}
                            name="gender"
                            value="Male"
                            onChange={handleGenderChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="container-checkbox">
                          Female
                          <input
                            type="radio"
                            name="gender"
                            value="Female"
                            onChange={handleGenderChange}
                            checked={profileData?.gender === "Female"}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="datePicker viewDetail">
                        <div className="passwordGrid">
                          {changePass ? (
                            <InputTextField
                              type={showPass ? "password" : "text"}
                              label={"Password"}
                              className={"form-control-user"}
                              value={profileData?.password}
                              name="password"
                              onChange={handleChange}
                              autoComplete="off"
                              autoFill="off"
                            />
                          ) : (
                            <InputTextField
                              type={showPass ? "password" : "text"}
                              label={"Password"}
                              className={"form-control-user disable-write"}
                              // value={profileData?.password}
                              name="password"
                              // onChange={handleChange}
                              disabled={true}
                            />
                          )}
                          {!changePass && (
                            <span onClick={onShow}>Change Password</span>
                          )}
                        </div>
                        {changePass && (
                          <button
                            type="button"
                            className="icon btn p-0 d-flex align-items-center justify-content-center"
                            onClick={() => setShowPass(!showPass)}
                            style={{ height: "22px", top: "34px" }}
                          >
                            <img src={eye_password} alt="" />
                          </button>
                        )}
                      </div>
                    </div>
                    {changePass && (
                      <div className="col-md-4 ">
                        <div className="position-relative">
                          <div className="datePicker viewDetail">
                            <div className="passwordGrid">
                              <InputTextField
                                label={"Confirm Password"}
                                className={"form-control-user"}
                                name="cnfirmPassword"
                                type={showPass2 ? "password" : "text"}
                                placeholder="Confirm your new password"
                                value={profileData?.cnfirmPassword}
                                onChange={handleChange}
                              />
                            </div>
                            <button
                              type="button"
                              className="icon btn p-0 d-flex align-items-center justify-content-center"
                              onClick={() => setShowPass2(!showPass2)}
                              style={{ height: "22px", top: "34px" }}
                            >
                              <img src={eye_password} alt="" />
                            </button>
                          </div>
                          {errors && (
                            <div className="text-danger passError">
                              {errors?.cnfirmPassword}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex">
          <div class="card card-main flex-fill">
            <div class="row">
              <div class="col-md-12 d-flex">
                <div class="card card-main flex-fill">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 footer-form-card">
                        {changePass && (
                          <button
                            type="button"
                            onClick={onCancel}
                            class="btn btn-primary cancel-btn"
                          >
                            CANCEL
                          </button>
                        )}

                        <button
                          class="btn btn-primary submit-btn"
                          type="button"
                          disabled={isUpdating}
                          onClick={handleSubmit}
                        >
                          {isUpdating ? (
                            <>
                              <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                              SAVE
                            </>
                          ) : (
                            <>SAVE</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
