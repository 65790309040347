import React from "react";
// import { Line } from 'react-chartjs-2';
import Chart from "react-apexcharts";

export const ScanGraphComponent = ({ scanDataSet, prevScanDataSet, type }) => {
  let chartCategoryArray = [];
  let xaxisType = "datetime";
  if (scanDataSet) {
    if (type !== "Year") {
      for (let x in scanDataSet.label) {
        chartCategoryArray.push(new Date(scanDataSet.label[x] + "T00:00:00.025385Z").getTime());
      }
    }
    else {
      xaxisType = "year"
      for (let x in scanDataSet.label) {
        chartCategoryArray.push(scanDataSet.label[x]);
      }
    }
  }
  const series = [
    {
      name: `Current ${type}`,
      data: scanDataSet ? scanDataSet.data : [],
    },
    {
      name: `Last ${type}`,
      data: prevScanDataSet ? prevScanDataSet.data : [],
    },
  ],
    options = {
      chart: {
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 3,
      },
      title: {
        text: "",
        align: "left",
      },
      colors: ["#0f51a1", "#f89c41"],
      grid: {
        // show: false,
        // row: {
        //   colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //   opacity: 0.5,
        // },
      },
      xaxis: {
        type: xaxisType,
        categories: chartCategoryArray,
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          style: {
            colors: "#8a94a6",
          },
        },
        hideOverlappingLabels: true,
      },
      yaxis: {
        labels: {
          align: "left",
          style: {
            colors: ["#8a94a6"],
          },
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        floating: false,
        fontSize: "10px",
        // fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        itemMargin: {
          horizontal: 5,
          vertical: 5,
        },
        markers: {
          width: 10,
          height: 10,
        },
        offsetY: 5,
      },
    };

  return <Chart options={options} series={series} type="line" height={250} />;
  // return <Line data={data} options={graphOptions} width={400} height={250} />;
};
