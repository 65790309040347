/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppRoutes, AppConfig } from "../../../config";
import { BatchViewLoader } from "../../components/loader/Resusable/BatchView";
import defaultImg from "../../../assets/img/group-12.png";
import editIcon from "../../../assets/img/edit-3.svg";
import mailIcon from "../../../assets/img/mail-1.svg";
import locationIcon from "../../../assets/img/location.svg";
import hashtagIcon from "../../../assets/img/hashtag.svg";
import globeIcon from "../../../assets/img/globe.svg";
import fileDescIcon from "../../../assets/img/file-desc.svg";
import fileMinusIcon from "../../../assets/img/file-minus.svg";
import starIcon from "../../../assets/img/star.svg";
import topBottomIcon from "../../../assets/img/top-bottom.svg";
import download from "../../../assets/img/download.svg";
import downloadWhite from "../../../assets/img/downloadWhite.svg";
import hairLogo from "../../../assets/img/group-12.png";
import { InputTextField } from "../forms";
import Select from "react-select";
import { saveAs } from "file-saver";

export const StoreDetailViewComponent = ({
  // clientId,
  storeData,
  isLoading,
  onFileChange,
  isEditable = false,
  onChange,
  onSelect,
  isCertificateUpdating,
}) => {
  const {
    companyName = "",
    website = "",
    insideUSACanada = "",
    businessRegNo = "",
    VATNumber = "",
    storeLogo = "",
    clientId = "",
    errors = {},
    TradeMarkCertificateNo_image = "",
    VATNumber_image = "",
    businessRegNo_image = "",
    TradeMarkCertificateNo = "",
  } = storeData;
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
  };
  return (
    <div className="card">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <div className="row">
          <div className="col-md-6">
            <h4>Company Name</h4>
            {isEditable ? (
              <div className="col-md-6 pl-0 pb-2">
                <InputTextField
                  id="companyName"
                  className={"form-control-user"}
                  name="companyName"
                  value={companyName}
                  // readOnly={!isEditable}
                  onChange={onChange}
                  maxLength={30}
                  errorMessage={
                    errors && errors.companyName ? errors.companyName : ""
                  }
                />
              </div>
            ) : (
              <h5>{companyName}</h5>
            )}
          </div>
          <div className="col-md-6">
            <h4>Company ID</h4>
            {isEditable ? (
              <div className="col-md-6 pl-0 pb-2">
                <InputTextField
                  id="companyName"
                  className={"form-control-user"}
                  name="clientId"
                  value={clientId}
                  onChange={onChange}
                  maxLength={30}
                  errorMessage={
                    errors && errors.clientId ? errors.clientId : ""
                  }
                />
              </div>
            ) : (
              <h5>{clientId}</h5>
            )}
          </div>
          <div className="col-md-6">
            <h4>Website</h4>
            {isEditable ? (
              <div className="col-md-6 pl-0 pb-2">
                <InputTextField
                  id="website"
                  className={"form-control-user"}
                  name="website"
                  value={website}
                  onChange={onChange}
                  maxLength={30}
                  errorMessage={errors && errors.website ? errors.website : ""}
                />
              </div>
            ) : (
              <h5>
                {" "}
                {website ? (
                  <a
                    className="mailto"
                    href={
                      website.startsWith("http://") ||
                      website.startsWith("https://")
                        ? website
                        : `http://${website}`
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    {website}
                  </a>
                ) : (
                  "N/A"
                )}
              </h5>
            )}
          </div>
          <div className="col-md-6">
            <h4>Inside USA & Canada</h4>
            {isEditable ? (
              <div className="col-md-6 pl-0 pb-2">
                <Select
                  placeholder={""}
                  options={[
                    {
                      label: "Yes",
                      value: true,
                    },
                    {
                      label: "No",
                      value: false,
                    },
                  ]}
                  styles={customStyles}
                  value={insideUSACanada}
                  isDisabled={!isEditable}
                  onChange={(selectedOption) => onSelect(selectedOption)}
                />
              </div>
            ) : (
              <h5>
                {insideUSACanada && insideUSACanada.label
                  ? insideUSACanada.label
                  : "N/A"}
              </h5>
            )}
          </div>
          <div className="col-md-6">
            <h4>Business Registration Number</h4>
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: "30px" }}
            >
              {isEditable ? (
                <div className="col-md-6 pl-0 pb-2">
                  <div className="uploadPicBox w-100 uploadedFileBox">
                    <div
                      className={
                        isCertificateUpdating === "businessRegNo_image"
                          ? "uploading chooseFile"
                          : "chooseFile"
                      }
                    >
                      <button
                        disabled={
                          isCertificateUpdating === "businessRegNo_image"
                        }
                        style={{
                          background: "transparent",
                          border: "0",
                          display: "flex",
                        }}
                      >
                        <input
                          className="imgUpload img_up"
                          name="businessRegNo_image"
                          type="file"
                          accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                          onChange={onFileChange}
                        />
                        {isCertificateUpdating === "businessRegNo_image" ? (
                          <>
                            <i className="fa fa-spinner fa-spin"></i> Choose
                          </>
                        ) : (
                          "Choose"
                        )}
                      </button>
                    </div>
                    <InputTextField
                      id="website"
                      className={"form-control-user border-0"}
                      name="businessRegNo"
                      value={businessRegNo}
                      onChange={onChange}
                      maxLength={15}
                      errorMessage={
                        errors && errors.businessRegNo
                          ? errors.businessRegNo
                          : ""
                      }
                    />
                    <button
                      disabled={!businessRegNo_image}
                      className="btn btn-primary side-btn-transparent action-block-btn"
                      onClick={() =>
                        saveAs(
                          `${AppConfig.FILES_ENDPOINT}${businessRegNo_image}`,
                          "Business Registration Number"
                        )
                      }
                    >
                      {" "}
                      {/* <img src={download} /> */}
                      <span>
                        <img src={download} alt="" className={"img-main"} />
                        <img
                          src={downloadWhite}
                          alt=""
                          style={{ top: "5px" }}
                          className={"img-hover"}
                        />
                      </span>
                      <div className="ml-3">Download</div>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h5 className="mb-0 mr-3">{businessRegNo}</h5>
                  <button
                    disabled={!businessRegNo_image}
                    className="btn btn-primary side-btn-transparent action-block-btn"
                    onClick={() =>
                      saveAs(
                        `${AppConfig.FILES_ENDPOINT}${businessRegNo_image}`,
                        "Business Registration Number"
                      )
                    }
                  >
                    {" "}
                    {/* <img src={download} /> */}
                    <span>
                      <img src={download} alt="" className={"img-main"} />
                      <img src={downloadWhite} alt="" className={"img-hover"} />
                    </span>
                    <div className="ml-3">Download</div>
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <h4>VAT or EIN Number</h4>
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: "30px" }}
            >
              {isEditable ? (
                <div className="col-md-6 pl-0 pb-2">
                  <div className="uploadPicBox w-100 uploadedFileBox">
                    <div
                      className={
                        isCertificateUpdating === "VATNumber_image"
                          ? "uploading chooseFile"
                          : "chooseFile"
                      }
                    >
                      <button
                        disabled={isCertificateUpdating === "VATNumber_image"}
                        style={{
                          background: "transparent",
                          border: "0",
                          display: "flex",
                        }}
                      >
                        <input
                          className="imgUpload img_up"
                          name="VATNumber_image"
                          type="file"
                          accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                          onChange={onFileChange}
                        />
                        {isCertificateUpdating === "VATNumber_image" ? (
                          <>
                            <i className="fa fa-spinner fa-spin"></i> Choose
                          </>
                        ) : (
                          "Choose"
                        )}
                      </button>
                    </div>
                    <InputTextField
                      id="website"
                      className={"form-control-user border-0"}
                      name="VATNumber"
                      value={VATNumber}
                      onChange={onChange}
                      maxLength={15}
                      errorMessage={
                        errors && errors.VATNumber ? errors.VATNumber : ""
                      }
                    />
                    <button
                      disabled={!VATNumber_image}
                      className="btn btn-primary side-btn-transparent action-block-btn"
                      onClick={() =>
                        saveAs(
                          `${AppConfig.FILES_ENDPOINT}${VATNumber_image}`,
                          "VAT or EIN Number"
                        )
                      }
                    >
                      {" "}
                      <span>
                        <img src={download} alt="" className={"img-main"} />
                        <img
                          src={downloadWhite}
                          alt=""
                          className={"img-hover"}
                        />
                      </span>
                      <div className="ml-3">Download</div>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h5 className="mb-0 mr-3">{VATNumber}</h5>
                  <button
                    disabled={!VATNumber_image}
                    className="btn btn-primary side-btn-transparent action-block-btn"
                    onClick={() =>
                      saveAs(
                        `${AppConfig.FILES_ENDPOINT}${VATNumber_image}`,
                        "VAT or EIN Number"
                      )
                    }
                  >
                    {" "}
                    <span>
                      <img src={download} alt="" className={"img-main"} />
                      <img src={downloadWhite} alt="" className={"img-hover"} />
                    </span>
                    <div className="ml-3">Download</div>
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <h4>Trade Mark Certification Number</h4>
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: "30px" }}
            >
              {isEditable ? (
                <div className="col-md-6 pl-0 pb-2">
                  <div className="uploadPicBox w-100 uploadedFileBox">
                    <div
                      className={
                        isCertificateUpdating === "TradeMarkCertificateNo_image"
                          ? "uploading chooseFile"
                          : "chooseFile"
                      }
                    >
                      <button
                        disabled={
                          isCertificateUpdating ===
                          "TradeMarkCertificateNo_image"
                        }
                        style={{
                          background: "transparent",
                          border: "0",
                          display: "flex",
                        }}
                      >
                        <input
                          className="imgUpload img_up"
                          name="TradeMarkCertificateNo_image"
                          type="file"
                          accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                          onChange={onFileChange}
                        />
                        {isCertificateUpdating ===
                        "TradeMarkCertificateNo_image" ? (
                          <>
                            <i className="fa fa-spinner fa-spin"></i> Choose
                          </>
                        ) : (
                          "Choose"
                        )}
                      </button>
                    </div>
                    <InputTextField
                      id="website"
                      className={"form-control-user border-0"}
                      name="TradeMarkCertificateNo"
                      value={TradeMarkCertificateNo}
                      onChange={onChange}
                      maxLength={15}
                      errorMessage={
                        errors && errors.TradeMarkCertificateNo
                          ? errors.TradeMarkCertificateNo
                          : ""
                      }
                    />
                    <button
                      disabled={!TradeMarkCertificateNo_image}
                      className="btn btn-primary side-btn-transparent action-block-btn"
                      onClick={() =>
                        saveAs(
                          `${AppConfig.FILES_ENDPOINT}${TradeMarkCertificateNo_image}`,
                          "Trade Mark Certification Number"
                        )
                      }
                    >
                      {" "}
                      <span>
                        <img src={download} alt="" className={"img-main"} />
                        <img
                          src={downloadWhite}
                          alt=""
                          className={"img-hover"}
                        />
                      </span>
                      <div className="ml-3">Download</div>
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <h5 className="mb-0 mr-3">{TradeMarkCertificateNo}</h5>
                  <button
                    disabled={!TradeMarkCertificateNo_image}
                    className="btn btn-primary side-btn-transparent action-block-btn"
                    onClick={() =>
                      saveAs(
                        `${AppConfig.FILES_ENDPOINT}${TradeMarkCertificateNo_image}`,
                        "Trade Mark Certification Number"
                      )
                    }
                  >
                    {" "}
                    <span>
                      <img src={download} alt="" className={"img-main"} />
                      <img src={downloadWhite} alt="" className={"img-hover"} />
                    </span>
                    <div className="ml-3">Download</div>
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <h4>Company Logo</h4>
            <div className="d-flex">
              <div className="uploadPicBox">
                <div className="chooseFile">
                  <input
                    className="imgUpload img_up"
                    name="storeLogo"
                    type="file"
                    accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                    onChange={onFileChange}
                    disabled={!isEditable}
                  />
                  Choose
                </div>
                {!storeLogo && <span>No File Choosen</span>}
              </div>
              <div className="ml-4">
                <img
                  style={{ maxWidth: "126px" }}
                  // {storeLogo ? storeLogo : hairLogo}
                  src={
                    storeLogo
                      ? storeLogo.startsWith("https://")
                        ? storeLogo
                        : `${AppConfig.FILES_ENDPOINT}${storeLogo}`
                      : hairLogo
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = hairLogo;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    // <div className="card card-main flex-fill">
    //   {isLoading ? (
    //     <BatchViewLoader />
    //   ) : (
    //     <>
    //       {" "}
    //       <div className="card-header py-7550  align-items-center justify-content-between">
    //         <div className="row">
    //           <div className="col-md-3 head-col-lg">
    //             <h6 className="font-book ">Store Detaildds</h6>
    //           </div>
    //           {actionBtn ? (
    //             <div className="col-md-9 filter-col-lg">
    //               {!isEditable ? (
    //                 <div className="side-col dp-right">
    //                   <button className="btn ic-btn" onClick={onEdit}>
    //                     {" "}
    //                     <img src={editIcon} alt="" /> Edit
    //                   </button>
    //                 </div>
    //               ) : (
    //                 <>
    //                   <div className="side-col dp-right">
    //                     <button
    //                       className="btn action-cl-btn"
    //                       onClick={onSubmit}
    //                       disabled={isUpdating}
    //                     >
    //                       {isUpdating ? (
    //                         <>
    //                           <i className="fa fa-spinner fa-spin"></i> Save
    //                         </>
    //                       ) : (
    //                         "Save"
    //                       )}
    //                     </button>
    //                   </div>
    //                   <div className="side-col dp-right">
    //                     <button
    //                       className="btn btn-secondary can-btn-table cn-tp"
    //                       onClick={onCancel}
    //                     >
    //                       {" "}
    //                       Cancel{" "}
    //                     </button>
    //                   </div>
    //                 </>
    //               )}

    //               {permissionBtn === true && (
    //                 <div className="side-col dp-right">
    //                   <Link
    //                     className="btn action-cl-btn"
    //                     to={`${AppRoutes.MANAGE_CLIENTS_PERMISSION.replace(
    //                       ":id",
    //                       clientId
    //                     )}`}
    //                   >
    //                     {" "}
    //                     Permissions
    //                   </Link>
    //                 </div>
    //               )}
    //             </div>
    //           ) : null}
    //         </div>
    //       </div>
    //       <div className="card-body card-table">
    //         <form className="user_add mt-2">
    //           <div className="row">
    //             <div className="col-md-3 mob-show">
    //               <div className="img-col-user">
    //                 <div className="user-img-tile">
    //                   <img className="user-img" src={defaultImg} alt="" />
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-md-9">
    //               {/* Start view Design */}
    //               <div className="row">
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={starIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">Company Name</div>
    //                       <div className="input-text">{companyName}</div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={globeIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">Website</div>
    //                       <div className="input-text">{website || "N/A"}</div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={fileMinusIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">Brand name</div>
    //                       <div className="input-text">{brandName}</div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={topBottomIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">Inside USA/ Canada?</div>
    //                       <div className="input-text">
    //                         {insideUSACanada && insideUSACanada.label
    //                           ? insideUSACanada.label
    //                           : "N/A"}
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={fileDescIcon} alt="" />
    //                     </span>
    //                     {insideUSACanada && insideUSACanada.value ? (
    //                       <div className="text-tile">
    //                         <div className="label-text">Business Reg. No.</div>
    //                         <div className="input-text">
    //                           {businessRegNo || "N/A"}
    //                         </div>
    //                       </div>
    //                     ) : (
    //                       <div className="text-tile">
    //                         <div className="label-text">
    //                           Business Reg. Certificate
    //                         </div>
    //                         <div className="input-text">
    //                           {businessRegCertificateNo || "N/A"}
    //                         </div>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={fileDescIcon} alt="" />
    //                     </span>
    //                     {insideUSACanada && insideUSACanada.value ? (
    //                       <div className="text-tile">
    //                         <div className="label-text">VAT Number</div>
    //                         <div className="input-text">{VATNumber || "N/A"}</div>
    //                       </div>
    //                     ) : (
    //                       <div className="text-tile">
    //                         <div className="label-text">
    //                           State of Issusing Certificate
    //                         </div>
    //                         <div className="input-text">
    //                           {stateOfIssuingCertificate || "N/A"}
    //                         </div>
    //                       </div>
    //                     )}
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={locationIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">Address Line 1</div>
    //                       <div className="input-text">{address1}</div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={locationIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">Address Line 2</div>
    //                       <div className="input-text">{address2}</div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={locationIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">State</div>
    //                       <div className="input-text">
    //                         {state && state.label ? state.label : "N/A"}
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={globeIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">Country</div>
    //                       <div className="input-text">
    //                         {country && country.label ? country.label : "N/A"}
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-6">
    //                   <div className="view-detail-icon-block">
    //                     <span className="view-ico">
    //                       <img src={hashtagIcon} alt="" />
    //                     </span>
    //                     <div className="text-tile">
    //                       <div className="label-text">Post Code</div>
    //                       <div className="input-text">{postCode}</div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>

    //               {/* End view Design */}
    //             </div>
    //             <div className="col-md-3 mob-hide">
    //               <div
    //                 className={`img-col-user ${
    //                   isEditable ? "" : " view-img-user"
    //                 }`}
    //               >
    //                 <div className="user-img-tile">
    //                   <img
    //                     className="user-img"
    //                     src={
    //                       storeLogo
    //                         ? storeLogo.startsWith("https://")
    //                           ? storeLogo
    //                           : `${AppConfig.FILES_ENDPOINT}${storeLogo}`
    //                         : defaultImg
    //                     }
    //                     onError={(e) => {
    //                       e.target.onerror = null;
    //                       e.target.src = defaultImg;
    //                     }}
    //                     alt=""
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </form>
    //       </div>
    //     </>
    //   )}
    // </div>
  );
};
