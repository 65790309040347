import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { InputTextField, SubmitButton } from "../../../components/forms";
import { ApiRoutes, AppRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { message } from "../../../../common";

const PrintClientBatchModal = ({
  batchData = {},
  clientId,
  open,
  handleClose,
  fetchData,
}) => {
  // Item count admin wants to print
  const [itemCount, setItemCount] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { id = "", assignedUnits = 0, orderDate, batchGroup } = batchData;
  useEffect(() => {
    setItemCount("");
    setError("");
  }, [open]);
  const markBatchAsPrinted = async () => {
    console.log("here", id);
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.MARK_CLIENT_BATCH_PRINTED.service,
      ApiRoutes.MARK_CLIENT_BATCH_PRINTED.url.replace(":id", id),
      ApiRoutes.MARK_CLIENT_BATCH_PRINTED.method,
      ApiRoutes.MARK_CLIENT_BATCH_PRINTED.authenticate,
      undefined,
      { unit: itemCount, clientId, orderDate, batchGroup }
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      fetchData();
      handleClose();
      let mapObj = {
        ":id": id,
        ":unit": itemCount,
      };
      var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
      window.open(
        `${AppRoutes.PRINT_BATCH.replace(re, function (matched) {
          return mapObj[matched.toLowerCase()];
        })}?assignee=${clientId}&group=${batchGroup}`,
        "_blank"
      );
      //&orderDate=${moment(orderDate).format(
      //   DB_ACCPTABLE_FORMAT,
      // )
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setItemCount(value);
    setError("");
  };
  const handlePrint = async () => {
    let errorMsg = "";
    // To validate item count
    if (!itemCount) {
      errorMsg = message.Required.replace(":item", "Item count");
    } else if (!Number.isInteger(Number(itemCount))) {
      errorMsg = message.InvalidItemCount;
    } else if (Number(itemCount) <= 0) {
      errorMsg = message.MinItemCount;
    } else if (Number(itemCount) > assignedUnits) {
      errorMsg = message.PrintBatchError.replace(":count", assignedUnits);
    }
    if (errorMsg) {
      setError(errorMsg);
    } else {
      await markBatchAsPrinted();
    }
  };
  return (
    <Modal
      show={open}
      onHide={handleClose}
      dialogClassName="modal-30w"
      restoreFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Print Batch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <div className={"text-danger"}></div>
          <InputTextField
            id="item_count"
            type={"number"}
            placeholder={"Enter Item Count"}
            className={"form-control-input-modal"}
            name="itemCount"
            value={itemCount}
            onChange={handleChange}
            errorMessage={error}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="col-md-12 footer-form">
          <button
            className="btn btn-primary cancel-btn modal-btn-ft"
            onClick={handleClose}
          >
            {" "}
            CANCEL
          </button>

          <SubmitButton
            className={"submit-btn modal-btn-ft"}
            text={"PRINT"}
            isLoading={loading}
            onSubmit={handlePrint}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PrintClientBatchModal;
