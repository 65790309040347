import React, { useEffect, useState } from "react";
import { FaqRow } from "./FaqRow";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";

export const ListComponent = ({
  faqs,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
  fetchFaqRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  onExpand,
}) => {
  // const [products, setProducts] = useState(faqs);

  // useEffect(() => {
  //   console.log(faqs, "\\\\\\\\\\");
  //   setProducts([...faqs]);
  // }, []);

  // // Function to manage read more, read less
  // const onExpand = (index) => {
  //   console.log(products, "::0000");
  //   const temp = [...products];
  //   temp[index] = {
  //     ...temp[index],
  //     isExpand: !temp[index]?.isExpand,
  //   };
  //   console.log(temp, "::dayata");

  //   setProducts(temp);
  // };
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th style={{ Width: "350px" }}>
                  Question{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "question"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("question", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "question"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("question", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>{" "}
                </th>
                <th style={{ Width: "420px" }}>
                  Answer{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "answer"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("answer", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "answer"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("answer", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                {userDetail !== "App" && userDetail !== "Web" && (
                  <th>
                    Category{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "categoryId"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("categoryId", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "categoryId"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("categoryId", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>{" "}
                  </th>
                )}
                <th>
                  Priority{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "priority"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("priority", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "priority"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("priority", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {faqs && faqs.length ? (
                faqs.map((faq, index) => (
                  <FaqRow
                    key={index}
                    faqData={faq}
                    fetchFaqRecords={fetchFaqRecords}
                    onEdit={() => onEdit(faq)}
                    onDelete={() => onDelete(faq._id)}
                    userDetail={userDetail}
                    onExpand={() => onExpand(index)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={5} className={"text-center image-no-data-xs"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
