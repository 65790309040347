import React from "react";
import Skeleton from "react-skeleton-loader";
export const ViewDetailsShimmer = () => (
  <>
    <div>
      <div className="" style={{ paddingBottom: "28px", paddingLeft: "11px" }}>
        <Skeleton color="#e5e9ef" width={"250px"} />
      </div>
      <div className="card-body">
        <div
          className="row no-gutters align-items-center"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="col-auto"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Skeleton color="#e5e9ef" />
            <Skeleton color="#e5e9ef" />
          </div>
          <div
            className="col-auto"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Skeleton color="#e5e9ef" />
            <Skeleton color="#e5e9ef" />
          </div>
          <div
            className="col-auto"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Skeleton color="#e5e9ef" />
            <Skeleton color="#e5e9ef" />
          </div>
          <div
            className="col-auto"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Skeleton color="#e5e9ef" />
            <Skeleton color="#e5e9ef" />
          </div>
        </div>
        <div
          className="row no-gutters align-items-center"
          style={{
            justifyContent: "space-between",
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          <div
            className="col-auto"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Skeleton color="#e5e9ef" />
            <Skeleton color="#e5e9ef" />
          </div>
          <div
            className="col-auto"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Skeleton color="#e5e9ef" />
            <Skeleton color="#e5e9ef" />
          </div>
          <div
            className="col-auto"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Skeleton color="#e5e9ef" />
            <Skeleton color="#e5e9ef" />
          </div>
          <div
            className="col-auto"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Skeleton color="#e5e9ef" />
            <Skeleton color="#e5e9ef" />
          </div>
        </div>
        <div className="" style={{ display: "flex", flexDirection: "column" }}>
          <Skeleton color="#e5e9ef" />

          <Skeleton color="#e5e9ef" width={"80%"} />
          <Skeleton color="#e5e9ef" width={"40%"} />
        </div>
      </div>
    </div>
  </>
);

export const ViewDetailsSecondShimmer = () => {
  return (
    <>
      <div className="card  shadow h-100 card-py-2">
        <div
          className=""
          style={{
            paddingBottom: "28px",
            paddingLeft: "11px",
          }}
        >
          <div
            className="col-auto"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Skeleton color="#e5e9ef" width={"250px"} height={"16px"} />
            <Skeleton color="#e5e9ef" width={"160px"} height={"16px"} />
          </div>
        </div>
        <div className="card-body">
          <div
            className="row no-gutters align-items-center"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="col-auto"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Skeleton color="#e5e9ef" />
              <Skeleton color="#e5e9ef" />
            </div>
            <div
              className="col-auto"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Skeleton color="#e5e9ef" />
              <Skeleton color="#e5e9ef" />
            </div>
            <div
              className="col-auto"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Skeleton color="#e5e9ef" />
              <Skeleton color="#e5e9ef" />
            </div>
            <div
              className="col-auto"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Skeleton color="#e5e9ef" />
              <Skeleton color="#e5e9ef" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
