import React from 'react';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { DEFAULT_DATE_FORMAT } from '../../../../config';
import { BatchHistoryLoader } from '../../loader/Resusable/BatchHistory';
import filterarrowIcon from '../../../../assets/img/filter-arrow.svg';
import printIcon from '../../../../assets/img/printer.svg';
import trashIcon from '../../../../assets/img/trash-2.svg';

export const BatchHistoryComponent = ({
  activeBatchGroup,
  updatingSection,
  clientId,
  batchHistory,
  isLoading,
  onPrintBatch,
  onDelteBatch,
  onExport,
  markAsInactive,
  isInactivating,
}) => {
  const filtersOptionExport = [
    {
      label: 'CSV',
      value: 'csv',
    },
    {
      label: 'PDF',
      value: 'pdf',
    },
    {
      label: 'Excel',
      value: 'excel',
    },
  ];
  return (
    <div className="card mb-4 card-main flex-fill">
      {isLoading ? (
        <BatchHistoryLoader />
      ) : (
        <>
          {' '}
          <div className="card-header py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book ">Batch History</h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table" id={'batch-history'}>
            <div className="table-responsive">
              <table width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th>Batch Number</th>
                    <th>Ordered Unit</th>
                    <th>Ordered Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {batchHistory && batchHistory.length ? (
                    batchHistory.map((batch, index) => {
                      const {
                        id: activeBatchGroupId,
                        batchNumber,
                        batchId,
                        isPrinted,
                        orderDate,
                        batchGroup,
                        orderUnit,
                        isInactive,
                      } = batch;
                      return (
                        <tr key={index}>
                          <td>{batchNumber || '-'}</td>
                          <td>{orderUnit || '-'}</td>
                          <td>
                            {orderDate
                              ? moment(orderDate).format(DEFAULT_DATE_FORMAT)
                              : ''}
                          </td>
                          <td>
                            <Dropdown className="no-arrow exp-drp">
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                                className={
                                  'filter-btn export-btn color-secondary-border'
                                }
                                data-boundary="window"
                                disabled={
                                  updatingSection === 'export' &&
                                  activeBatchGroup === batchGroup
                                }
                              >
                                {updatingSection === 'export' &&
                                activeBatchGroup === batchGroup ? (
                                  <>
                                    <i className="fa fa-spinner fa-spin test-spin"></i>
                                    &nbsp;Exporting
                                  </>
                                ) : (
                                  <>
                                    Export as
                                    <img src={filterarrowIcon} alt="" />
                                  </>
                                )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="shadow dropdown-menu dropdown-staff">
                                {filtersOptionExport.map(
                                  ({ label, value }, index) => (
                                    <Dropdown.Item
                                      as="div"
                                      key={index}
                                      onClick={() =>
                                        onExport(
                                          value,
                                          clientId,
                                          orderDate,
                                          batchId,
                                          batchGroup
                                        )
                                      }
                                    >
                                      {label}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* {isInactive === true ? (
                              <button
                                className="btn cl-btn-sm-2 btn-danger"
                                disabled={true}
                              >
                                Inactive
                              </button>
                            ) : (
                              <button
                                className="btn btn-secondary th-btn"
                                onClick={() =>
                                  markAsInactive({
                                    batchId,
                                    batchGroup,
                                  })
                                }
                                disabled={
                                  isInactivating === batchGroup ? true : false
                                }
                              >
                                Mark as inactive
                              </button>
                            )} */}

                            <button
                              className="btn btn-secondary th-btn exp-margin"
                              onClick={() =>
                                onPrintBatch({
                                  id: batchId,
                                  assignedUnits: orderUnit,
                                  orderDate,
                                  batchGroup,
                                })
                              }
                            >
                              {' '}
                              <img src={printIcon} alt="" /> Print{' '}
                            </button>
                            <button
                              className="btn btn-secondary th-btn"
                              disabled={
                                updatingSection === 'delete' &&
                                activeBatchGroup === activeBatchGroupId
                              }
                              onClick={() =>
                                onDelteBatch({
                                  id: batchId,
                                  isPrinted: isPrinted,
                                  orderDate,
                                  activeBatchGroupId,
                                })
                              }
                            >
                              {' '}
                              {updatingSection === 'delete' &&
                              activeBatchGroup === activeBatchGroupId ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin"></i>
                                  &nbsp;Deleting
                                </>
                              ) : (
                                <>
                                  <img src={trashIcon} alt="" /> Delete
                                </>
                              )}{' '}
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className={'text-center'}>
                        {'Batch is not assigned to client yet.'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
