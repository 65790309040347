import React from "react";
import Select from "react-select";
import { SubmitButton } from "../forms";
import { TableLoader } from "../../components/loader/Resusable/Table";
import OriginRow from "./OriginRow";
import noData from "../../../assets/img/no-data.png";

const customStylesState = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    background: "#fff",
    display: "none",
  }),
  // multiValue: (base, state) => {
  //   return state.data.isFixed ? { ...base, backgroundColor: '#eaecf4' } : base;
  // },
  // multiValueLabel: (base, state) => {
  //   return state.data.isFixed ? { ...base, color: '#0f51a1' } : base;
  // },
  // multiValueRemove: (base, state) => {
  //   return state.data.isFixed ? { ...base, display: 'none' } : base;
  // },
};

const customStylesOrigin = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};
export const ListOriginComponent = ({
  origins,
  origin,
  states,
  countryStateData,
  statesOption,
  errors,
  isFormOpen,
  isAdding,
  isLoading,
  id,
  isViewUploading,
  onSubmit,
  onCancel,
  onSelect,
  deleteOrigin,
  onEdit,
  onExpand,
  setFilter,
  order,
  sortBy,
}) => {
  const countriesOption = [];
  countryStateData.forEach((element) => {
    countriesOption.push({
      label: element.name,
      value: element.name,
    });
  });
  return (
    <div className="card-body card-table">
      <div
        className="table-responsive fixed-height-table-10"
        id={"origin-card"}
      >
        {isLoading ? (
          <TableLoader columns={3} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="th-origin pad-top">
              <tr>
                <th>
                  Country
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "originName"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("originName", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "originName"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("originName", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>{" "}
                </th>
                <th>States</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isFormOpen ? (
                <tr className="form-row-table lw-space">
                  <td
                    className={`origin-country-add ${
                      errors && (errors.states || errors.origin)
                        ? "has-error"
                        : ""
                    }`}
                  >
                    <Select
                      placeholder={"Select country"}
                      // id={'origin-select'}
                      styles={customStylesOrigin}
                      options={countriesOption}
                      value={origin}
                      onChange={(selectedOptions) =>
                        onSelect(selectedOptions, "origin")
                      }
                      menuPortalTarget={document.querySelector("body")}
                    />
                    {errors && errors.origin ? (
                      <div className={"text-danger error-text"}>
                        {errors.origin}
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                  </td>
                  <td
                    className={`origin-desc-add ${
                      errors && (errors.states || errors.origin)
                        ? "has-error"
                        : ""
                    }`}
                  >
                    <Select
                      options={statesOption}
                      placeholder={"Enter states"}
                      isMulti={true}
                      // id={'state-select'}
                      styles={customStylesState}
                      menuPortalTarget={document.querySelector("body")}
                      value={states}
                      noOptionsMessage={() => {
                        return origin && origin.value
                          ? "No states found"
                          : "First select an origin";
                      }}
                      onChange={(selectedOptions) =>
                        onSelect(selectedOptions, "states")
                      }
                    />
                    {/* <input
                    type='text'
                    className='select-form-control form-control'
                    name='states'
                    placeholder='Enter States'
                    onChange={onChange}
                  /> 
                    <div className={'text-danger error-text'}>
                      {errors && errors.states ? errors.states : ''}
                    </div> */}

                    {errors && errors.states ? (
                      <div className={"text-danger error-text"}>
                        {errors.states}
                      </div>
                    ) : (
                      ""
                    )}
                    <br />
                  </td>
                  <td>
                    <button
                      className={`btn btn-secondary can-btn-table ${
                        errors && (errors.states || errors.origin)
                          ? "btn-org"
                          : ""
                      }`}
                      onClick={onCancel}
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                    <SubmitButton
                      className={`save-btn-table add ${
                        errors && (errors.states || errors.origin)
                          ? "btn-org"
                          : ""
                      }`}
                      text={"Save"}
                      isLoading={isAdding}
                      onSubmit={onSubmit}
                    />
                    <br />
                  </td>
                </tr>
              ) : null}
              {origins && origins.length ? (
                origins.map((e, index) => (
                  <OriginRow
                    originData={e}
                    key={e.id}
                    onExpand={() => onExpand(index)}
                    deleteOrigin={() =>
                      deleteOrigin(e.id, e.originName, e.totalBatches)
                    }
                    editOrigin={() => onEdit(e, e.totalBatches)}
                    selectedCountryData={
                      countryStateData.filter(
                        (country) => country.name === e.originName
                      )[0]
                    }
                    isViewUploading={id === e.id && isViewUploading}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={4} className={"text-center image-no-data"}>
                    <img src={noData} alt="" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {/*{!isLoading && totalRecords > limit ? (
          <Pagination
            totalRecords={totalRecords}
            currentPage={currentPage}
            pageLimit={limit}
            onPageChanged={onPageChanged}
          />
        ) : null} */}
      </div>
    </div>
  );
};
