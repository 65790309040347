import React, { useState, useEffect } from "react";
import { InputTextField, SubmitButton } from "../../components/forms";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes, AppRoutes } from "../../../config";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import vector from "../../../assets/img/Vector.svg";
import { aboutUsValidator } from "../../../validators/aboutUs";
// import defaultPartnerImg from "../../../assets/img/default_partner_img.png";
import defaultPartnerImg from "../../../assets/img/clarity_logo.svg";
import { ColorRing } from "react-loader-spinner";
import { groupItemStyles } from "@atlaskit/button/dist/cjs/components/ButtonGroup";

export const AddAboutUsComponent = ({
  IndividualAboutusDetail,
  handleAddCancel,
  allOptions,
  userDetail,
  aboutUsDetail,
}) => {
  const [firstName, setFirstName] = useState(
    IndividualAboutusDetail?.firstName || ""
  );
  const [lastName, setLastName] = useState(
    IndividualAboutusDetail?.lastName || ""
  );
  const [designation, setDesignation] = useState(
    IndividualAboutusDetail?.designation || ""
  );
  const [Country, setCountry] = useState(
    IndividualAboutusDetail?.country || ""
  );
  const [linkedInUrl, SetLinkedInUrl] = useState(
    IndividualAboutusDetail?.linkedInUrl || ""
  );
  const [intagramUrl, SetIntagramUrl] = useState(
    IndividualAboutusDetail?.intagramUrl || ""
  );
  const [facebookUrl, SetFacebookUrl] = useState(
    IndividualAboutusDetail?.facebookUrl || ""
  );
  const [twitterUrl, SetTwitterUrl] = useState(
    IndividualAboutusDetail?.twitterUrl || ""
  );
  const [bio, SetBio] = useState(IndividualAboutusDetail?.bio || "");

  const [Profile, setProfile] = useState(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [categoryId, setCatId] = useState();
  const [selectedImage, setSelectImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [logoUpload, setLogoUpload] = useState(false);
  const onChange = (event) => {
    let payload = {};
    const {
      target: { name, value },
    } = event;
    if (name === "firstName") {
      setFirstName(value.trimLeft());
      payload.firstName = value.trimLeft();
    }
    if (name === "lastName") {
      setLastName(value.trimLeft());
      payload.lastName = value.trimLeft();
    }
    if (name === "designation") {
      setDesignation(value.trimLeft());
      payload.designation = value.trimLeft();

      // getStateByCountry(value);
    }
    if (name === "linkedInUrl") {
      SetLinkedInUrl(value.trimLeft());
      payload.linkedInUrl = value.trimLeft();
    }
    if (name === "intagramUrl") {
      SetIntagramUrl(value.trimLeft());
      payload.intagramUrl = value.trimLeft();
    }
    if (name === "facebookUrl") {
      SetFacebookUrl(value.trimLeft());
      payload.facebookUrl = value.trimLeft();
    }
    if (name === "twitterUrl") {
      SetTwitterUrl(value.trimLeft());
      payload.twitterUrl = value.trimLeft();
    }
    if (name === "bio") {
      SetBio(value.trimLeft());
      payload.bio = value.trimLeft();
    }
    if (name == "profile") {
      setProfile(value);
      payload.profile = value.trimLeft();
    }

    if (userDetail === "Web") {
      const { isValid, errors } = aboutUsValidator(payload);
      setErrors(errors);
    }
  };

  const onFileChange = (event) => {
    validateForm(event) && setProfile(event.target.files[0]);
  };

  const validateForm = (event) => {
    setLogoUpload(true);
    const fileInput = event.target;
    let errorMessage = "";

    // Check if a file is selected
    if (!fileInput.value) {
      errorMessage = "Please select an image.";
      return false;
    }

    const fileSize = fileInput.files[0].size; // in bytes
    const minSize = 100 * 1024; // 100 KB
    const maxSize = 10 * 1024 * 1024; // 10 MB

    const maxImageSize = {
      width: 2500, //1315,
      height: 2350, //1434
    };

    const minImageSize = {
      width: 500,
      height: 600,
    };

    // Check against minimum size
    if (fileSize < minSize) {
      errorMessage = "Image size is below the minimum limit (100 KB).";
      // return false;
    }

    // Check against maximum size
    if (fileSize > maxSize) {
      errorMessage = "Image size exceeds the maximum limit (10 MB).";
      // return false;
    }

    if (fileInput.files[0]) {
      // Use FileReader to read the image
      const reader = new FileReader();
      reader.onload = (e) => {
        getImageDimensions(e.target.result)
          .then((dimensions) => {
            console.log(dimensions);
            if (dimensions?.width > maxImageSize?.width) {
              errorMessage = "Image width size should be below 2500.";
            }
            if (dimensions?.width < minImageSize?.width) {
              errorMessage = "Image width size should be greater then 500.";
            }
            if (dimensions?.height > maxImageSize?.height) {
              errorMessage = "Image height size should be below 2350.";
            }
            if (dimensions?.height < minImageSize?.height) {
              errorMessage = "Image hieght size should be greater then 600.";
            }

            // Set the image and its dimensions in state
            // setImage(fileInput.files[0]);
            // setImageDimensions(dimensions);
          })
          .catch((error) => {
            console.error(error);
          });
      };
      // Read the image as a data URL
      reader.readAsDataURL(fileInput.files[0]);
    }
    // Clear any previous error messages
    if (errorMessage?.length) {
      toast.error(errorMessage);
      setLogoUpload(false);

      return false;
    } else {
      return true;
    }

    // Continue with form submission or other logic
    // For example, you can handle the file upload here or call another function.

    // return true;
  };

  // Function to get image dimensions
  const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (event) => {
    const formData = new FormData();
    setIsLoading(true);
    event.preventDefault();
    let payload = {};
    if (userDetail === "Web") {
      payload = {
        firstName: firstName,
        lastName: lastName,
        designation: designation,
        linkedInUrl: linkedInUrl,
        intagramUrl: intagramUrl,
        facebookUrl: facebookUrl,
        twitterUrl: twitterUrl,
        bio: bio,
        profile: Profile,
      };
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("designation", designation);
      linkedInUrl && formData.append("linkedInUrl", linkedInUrl);
      intagramUrl && formData.append("intagramUrl", intagramUrl);
      facebookUrl && formData.append("facebookUrl", facebookUrl);
      twitterUrl && formData.append("twitterUrl", twitterUrl);
      bio && formData.append("bio", bio);

      Profile && formData.append("profile", Profile);
    }
    const { isValid, errors } = aboutUsValidator(payload);

    let filterData = "";
    // if (userDetail !== "App") {
    //   filterData = aboutUsDetail?.filter(
    //     (x) =>
    //       parseInt(x.priority) === parseInt(priority) &&
    //       x.categoryName === categoryId.label
    //   );
    // }
    if (isValid) {
      setErrors({});
      if (filterData.length === 0) {
        if (IndividualAboutusDetail._id) {
          formData.append("id", IndividualAboutusDetail._id);

          updateEntry(formData);
        } else {
          addEntry(formData);
        }
      } else {
        setIsLoading(false);
        // toast.error("Priority already exist under this category");
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
      return;
    }
  };

  const addEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.ADD_ABOUT_US_DETAIL_NEW.service,
      ApiRoutes.ADD_ABOUT_US_DETAIL_NEW.url,
      ApiRoutes.ADD_ABOUT_US_DETAIL_NEW.method,
      ApiRoutes.ADD_ABOUT_US_DETAIL_NEW.authenticate,
      undefined,
      payload,
      undefined
    );
    if (response && response.isError) {
      setIsLoading(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  const updateEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.UPDATE_ABOUT_US_DETAIL_NEW.service,
      ApiRoutes.UPDATE_ABOUT_US_DETAIL_NEW.url,
      ApiRoutes.UPDATE_ABOUT_US_DETAIL_NEW.method,
      ApiRoutes.UPDATE_ABOUT_US_DETAIL_NEW.authenticate,
      undefined,
      payload,
      undefined
    );
    if (response && response.isError) {
      setIsLoading(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  useEffect(() => {
    if (IndividualAboutusDetail?._id) {
      setIsEditMode(true);
      // setPartnerName(IndividualAboutusDetail?.name);
      // setPartnerEmail(IndividualAboutusDetail?.email);
      // setCountry(IndividualAboutusDetail?.country);
      // setState(IndividualAboutusDetail?.city);
      // getStateByCountry(IndividualAboutusDetail?.country);
      // // setProfile(IndividualAboutusDetail?.logo);
      setSelectImage(IndividualAboutusDetail?.profile);
    } else {
      setIsEditMode(false);
    }
  }, []);

  const onSelect = (label) => {
    if (label) {
      setCatId(label);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <form className="product_add proAddAbout">
      <div className="row">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor={"firstName"} className={`form-control-label_About`}>
              First Name
            </label>
            <InputTextField
              type="text"
              id="firstName"
              placeholder="Enter First Name"
              // label={"First Name"}
              className={""}
              name="firstName"
              value={firstName}
              onChange={onChange}
              errorMessage={
                errors && errors?.firstName ? errors?.firstName : ""
              }
            />
          </div>
          {userDetail !== "App" && (
            <div className="col-md-6">
              <label
                htmlFor={"lastName"}
                className={`form-control-label_About`}
              >
                Last Name
              </label>
              <InputTextField
                type="text"
                id="lastName"
                placeholder="Enter Last Name"
                // label={"Last Name"}
                className={"form-control-product"}
                name="lastName"
                value={lastName}
                onChange={onChange}
                errorMessage={
                  errors && errors?.lastName ? errors?.lastName : ""
                }
              />
            </div>
          )}
          <div className="col-md-12">
            <label
              htmlFor={"designation"}
              className={`form-control-label_About`}
            >
              Designation
            </label>
            <InputTextField
              type="text"
              id="designation"
              placeholder="Enter Designation "
              // label={"Designation"}
              className={"form-control-product"}
              name="designation"
              value={designation}
              onChange={onChange}
              errorMessage={
                errors && errors?.designation ? errors?.designation : ""
              }
            />
          </div>

          <div className="col-md-6" style={{ paddingTop: "12px" }}>
            <label
              htmlFor={"linkedInUrl"}
              className={`form-control-label_About`}
            >
              Linkedin Profile
            </label>
            <InputTextField
              type="text"
              id="linkedInUrl"
              placeholder="Enter link here"
              // label={"Linkedin Profile"}
              className={"form-control-product"}
              name="linkedInUrl"
              value={linkedInUrl}
              onChange={onChange}
              errorMessage={
                errors && errors?.linkedInUrl ? errors?.linkedInUrl : ""
              }
            />
          </div>
          <div className="col-md-6" style={{ paddingTop: "12px" }}>
            <label
              htmlFor={"intagramUrl"}
              className={`form-control-label_About`}
            >
              Instagram Profile
            </label>
            <InputTextField
              type="text"
              id="intagramUrl"
              placeholder="Enter link here"
              // label={"Instagram Profile"}
              className={"form-control-product"}
              name="intagramUrl"
              value={intagramUrl}
              onChange={onChange}
              errorMessage={
                errors && errors?.intagramUrl ? errors?.intagramUrl : ""
              }
            />
          </div>
          <div className="col-md-6" style={{ paddingTop: "12px" }}>
            <label
              htmlFor={"facebookUrl"}
              className={`form-control-label_About`}
            >
              Facebook Profile
            </label>
            <InputTextField
              type="text"
              id="facebookUrl"
              placeholder="Enter link here"
              // label={"Facebook Profile"}
              className={"form-control-product"}
              name="facebookUrl"
              value={facebookUrl}
              onChange={onChange}
              errorMessage={
                errors && errors?.facebookUrl ? errors?.facebookUrl : ""
              }
            />
          </div>
          <div className="col-md-6" style={{ paddingTop: "12px" }}>
            <label
              htmlFor={"twitterUrl"}
              className={`form-control-label_About`}
            >
              Twitter Profile
            </label>
            <InputTextField
              type="text"
              id="twitterUrl"
              placeholder="Enter link here"
              // label={"Twitter Profile"}
              className={"form-control-product"}
              name="twitterUrl"
              value={twitterUrl}
              onChange={onChange}
              errorMessage={
                errors && errors?.twitterUrl ? errors?.twitterUrl : ""
              }
            />
          </div>
          <div className="col-md-12">
            <label
              htmlFor="product_description"
              className="form-control-label_About"
            >
              Bio
            </label>
            <textarea
              placeholder="Enter Bio (max 100 characters)"
              className="form-control form-control-textarea wt-resize"
              cols="50"
              id="bio"
              aria-describedby="category_nameHelp"
              rows="8"
              name={"bio"}
              maxLength={100}
              value={bio}
              onChange={onChange}
            />
            <div className={"text-danger"}></div>
          </div>

          <div className="col-md-12">
            <label
              htmlFor="product_description"
              className="form-control-label_About"
            >
              Profile
            </label>
            {isEditMode ? (
              <>
                <div className="uploading-section-edit">
                  <div
                    className="uploaded-img"
                    style={{ position: "relative" }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        display: `${selectedImage ? "block" : "none"}`,
                        filter: logoUpload ? "grayscale(0.8)" : "",
                        opacity: logoUpload ? 0.8 : 1,
                      }}
                      src={selectedImage && selectedImage}
                    />
                    <div
                      style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        backgroundColor: "transparent",
                        display: logoUpload ? "block" : "none",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <ColorRing
                          visible={true}
                          height="100"
                          width="100"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <p>{selectedImageName}</p>
                    </div>
                    <div className="vector-file-upload-edit">
                      <div className="text-vector">
                        <span
                          style={{
                            fontSize: "13px",
                            lineHeight: "10px",
                            fontWeight: 700,
                            color: "#0e50a0",
                          }}
                        >
                          {" "}
                          Change Image{" "}
                        </span>
                      </div>
                      <input
                        type="file"
                        name="profile"
                        id="profile"
                        style={{
                          position: "absolute",
                          height: "40px",
                          width: "140px",
                          top: 0,
                          left: 0,
                          cursor: "pointer",
                          display: "block",
                          opacity: 0,
                        }}
                        accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                        onChange={(e) => {
                          if (validateForm(e)) {
                            const file = e.target.files[0];
                            setLogoUpload(true);
                            setIsLoading(true);
                            setTimeout(() => {
                              setProfile(file);
                              setSelectImage(URL.createObjectURL(file));
                              setSelectedImageName(file?.name);
                              setLogoUpload(false);
                              setIsLoading(false);
                            }, 2000);
                          }
                        }}
                        className="imgUpload img_up "
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="uploading-section">
                  <div className="vector-file-upload">
                    <img src={vector} />
                    <div className="text-vector-main">
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: 700,
                          lineHeight: "10px",
                        }}
                      >
                        {" "}
                        <u>Upload</u> or Drag & Drop Logo{" "}
                      </span>
                      <div
                        style={{
                          fontSize: "10px",
                          marginTop: "8px",
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                      >
                        Square 2500 x 2350 pixels (10mb Max) Formats supported
                        PNG, Jpeg, JPG
                      </div>
                    </div>
                    <input
                      type="file"
                      name="profile"
                      id="profile"
                      style={{
                        position: "absolute",
                        height: "95px",
                        width: "275px",
                        top: 0,
                        left: 0,
                        cursor: "pointer",
                        display: "block",
                        opacity: 0,
                      }}
                      onDragOver={handleDragOver}
                      onDrop={(e) => {
                        if (validateForm(e)) {
                          setIsLoading(true);
                          const file = e.target.files[0];
                          setSelectImage("");
                          setTimeout(() => {
                            setProfile(file);
                            setSelectImage(URL.createObjectURL(file));
                            setSelectedImageName(file?.name);
                            setLogoUpload(false);
                            setIsLoading(false);
                          }, 2000);
                        }
                      }}
                      accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                      onChange={(e) => {
                        if (validateForm(e)) {
                          setIsLoading(true);
                          const file = e.target.files[0];
                          setSelectImage("");
                          setTimeout(() => {
                            setProfile(file);
                            setSelectImage(URL.createObjectURL(file));
                            setSelectedImageName(file?.name);
                            setLogoUpload(false);
                            setIsLoading(false);
                          }, 2000);
                        }
                      }}
                      className="imgUpload img_up "
                    />
                  </div>
                  <div
                    className="image_preview_container"
                    style={{ position: "relative" }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: -20,
                        fontSize: "12px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "120px",
                      }}
                    >
                      {selectedImageName ? selectedImageName : "*Sample Image"}
                    </div>
                    <div className="uploaded-img">
                      {selectedImage ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            display: `${selectedImage ? "block" : "none"}`,
                          }}
                          src={selectedImage && selectedImage}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "relative",
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              filter: logoUpload ? "grayscale(0.8)" : "",
                              opacity: logoUpload ? 0.8 : 1,
                              display: `${
                                defaultPartnerImg ? "block" : "none"
                              }`,
                            }}
                            src={defaultPartnerImg && defaultPartnerImg}
                          />
                          <div
                            style={{
                              borderRadius: "10px",
                              overflow: "hidden",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              backgroundColor: "transparent",
                              display: logoUpload ? "block" : "none",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              <ColorRing
                                visible={true}
                                height="100"
                                width="100"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={[
                                  "#fff",
                                  "#fff",
                                  "#fff",
                                  "#fff",
                                  "#fff",
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className={"text-danger"}></div>
          </div>
        </div>

        <div className="col-md-12 footer-formCZ">
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-primary cancel-btn"
              onClick={handleAddCancel}
            >
              {" "}
              Cancel
            </button>

            <SubmitButton
              disabled={isLoading}
              className={"submit-btn"}
              text={isEditMode ? "Update" : "Add"}
              isLoading={isLoading}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
