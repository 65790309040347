import React from "react";
import logov2 from "../../../assets/img/logo-v2.svg";
import debitCard from "../../../assets/img/debitcard.svg";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useState } from "react";
import moment from "moment";
import { ScanLoader } from "../../components/loader/Scan";

function Invoice(props) {
  useEffect(() => {
    getInvoice();
  }, []);

  const params = useParams();
  const [invoice, setInvoice] = useState();
  const [loading, setLoading] = useState();

  const getInvoice = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_INVOICE.service,
      ApiRoutes.GET_INVOICE.url.replace(":id", params.id),
      ApiRoutes.GET_INVOICE.method,
      ApiRoutes.GET_INVOICE.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      setInvoice(data);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="invoiceTable"
    >
      <table
        style={{ background: "#FFFFFF", width: "615px", maxWidth: "615px" }}
      >
        {loading ? (
          <ScanLoader />
        ) : (
          <>
            {invoice &&
              invoice.map((info, index) => (
                <tr key={index}>
                  <td style={{ padding: "30px" }}>
                    <table>
                      <tr>
                        <td>
                          <img src={logov2} />
                        </td>
                        <td>
                          <h4
                            style={{
                              color: "#0A1F44",
                              fontSize: "16px",
                              fontWeight: "700",
                              fontFamily: "'CircularStd-Bold'",
                              marginBottom: "25px",
                            }}
                          >
                            Invoice/Bill of Supply
                          </h4>
                          <div style={{ display: "flex" }}>
                            <div style={{ marginRight: "45px" }}>
                              <p
                                style={{
                                  color: "#8A94A6",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  fontFamily: "'CircularStd-Book'",
                                  marginBottom: "6px",
                                }}
                              >
                                Invoice Number
                              </p>
                              <h6
                                style={{
                                  color: "#0A1F44",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  fontFamily: "'CircularStd-Bold'",
                                  marginBottom: "6px",
                                }}
                              >
                                {info.invoiceNumber || "-"}
                              </h6>
                            </div>
                            <div>
                              <p
                                style={{
                                  color: "#8A94A6",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  fontFamily: "'CircularStd-Book'",
                                  marginBottom: "6px",
                                }}
                              >
                                Date Issued
                              </p>
                              <h6
                                style={{
                                  color: "#0A1F44",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  fontFamily: "'CircularStd-Bold'",
                                  marginBottom: "6px",
                                }}
                              >
                                {moment(info.dateIssued).format("DD/MM/YYYY") ||
                                  "-"}
                              </h6>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={2}>
                          <table>
                            <tr>
                              <td>
                                <h3
                                  style={{
                                    color: "#8A94A6",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "'CircularStd-Book'",
                                    paddingBottom: "8px",
                                    borderBottom: "1px solid #F1F2F4",
                                  }}
                                >
                                  Bill From
                                </h3>
                              </td>
                              <td>
                                <h3
                                  style={{
                                    color: "#8A94A6",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "'CircularStd-Book'",
                                    paddingBottom: "8px",
                                    borderBottom: "1px solid #F1F2F4",
                                  }}
                                >
                                  Bill To
                                </h3>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table>
                                  <tr>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                          minWidth: "224px",
                                          maxWidth: "224px",
                                          whiteSpace: "break-spaces",
                                        }}
                                      >
                                        {info?.from?.name}
                                        {info?.from?.address}
                                      </h6>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td>
                                <table>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Client Name
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.billTo?.companyName}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Email
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.billTo?.email}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Location
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.billTo?.location || "-"}
                                      </h6>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <table>
                            <tr>
                              <td colSpan={2}>
                                <h3
                                  style={{
                                    color: "#8A94A6",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "'CircularStd-Book'",
                                    paddingBottom: "8px",
                                    borderBottom: "1px solid #F1F2F4",
                                  }}
                                >
                                  Transaction Detail
                                </h3>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <table>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Transaction ID
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.transactionDetails
                                          ?.transactionId || "-"}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Card Number
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        **** **** ****{" "}
                                        {info?.transactionDetails?.cardNo ||
                                          "-"}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Amount
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        $
                                        {info?.transactionDetails?.amount ||
                                          "-"}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Payment Status
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.transactionDetails
                                          ?.paymentStatus || "-"}
                                      </h6>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td>
                                <table>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Payment Method
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        <img src={debitCard} />{" "}
                                        {info?.transactionDetails
                                          ?.paymentMethod || "-"}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Cardholder’s Name
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.transactionDetails
                                          ?.cardHolderName || "-"}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Paid On
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {moment
                                          .utc(info?.transactionDetails?.paidOn)
                                          .format("  DD/MM/YYYY | HH:mm")}
                                        Hrs
                                      </h6>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <table>
                            <tr>
                              <td colSpan={2}>
                                <h3
                                  style={{
                                    color: "#8A94A6",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    fontFamily: "'CircularStd-Book'",
                                    paddingBottom: "8px",
                                    borderBottom: "1px solid #F1F2F4",
                                  }}
                                >
                                  Plan Taken
                                </h3>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ paddingBottom: "25px" }}>
                                <table>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Plan Name
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.planTaken?.planName}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Plan Duration
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.planTaken?.planDuration}
                                      </h6>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td style={{ paddingBottom: "25px" }}>
                                <table>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Auto Renewal
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {info?.planTaken?.autoRenewal
                                          ? "Yes"
                                          : "No"}
                                      </h6>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Charges
                                      </h5>
                                    </td>
                                    <td>
                                      <h6
                                        style={{
                                          color: "#4A4A4A",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        ${info?.planTaken?.charges}
                                      </h6>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr
                              style={{
                                background: "#0F51A1",
                                overflow: "hidden",
                              }}
                            >
                              <td
                                colSpan={2}
                                style={{ borderRadius: "0 0 10px 10px" }}
                              >
                                <table>
                                  <tr>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#fff",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                        }}
                                      >
                                        Amount in words
                                      </h5>
                                    </td>
                                    <td>
                                      <h5
                                        style={{
                                          color: "#fff",
                                          fontSize: "12px",
                                          fontWeight: "500",
                                          fontFamily: "'CircularStd-Book'",
                                          marginBottom: "0",
                                          textAlign: "right",
                                        }}
                                      >
                                        {info?.amountInWords}
                                      </h5>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2} style={{ padding: "60px" }}></td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              ))}
          </>
        )}
      </table>
    </div>
  );
}

export default Invoice;
