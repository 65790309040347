/** @format */

import React, { Component } from "react";
import whiteLogo from "../../../assets/img/Clarity.svg";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
    };
  }

  componentDidMount = () => {
    this.fetchDetails("terms");
  };

  fetchDetails = async (key) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SETTINGS.service,
      ApiRoutes.GET_SETTINGS.url.replace(":id", "content_" + key),
      ApiRoutes.GET_SETTINGS.method,
      ApiRoutes.GET_SETTINGS.authenticate,
      undefined,
      undefined
    );

    if (response && response.isError) {
    } else {
      if (response.data.data.product.length) {
        this.setState({
          content: window.atob(response.data.data.product[0].value),
        });
      }
    }
  };
  render() {
    return (
      <div className="container container-fluid-invitaion1">
        <div className="row login-row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header center-header">
                <img className="center-logo" src={whiteLogo} alt="" />
              </div>
              <div className={"client-invitation-form privacy"}>
                {/* <div className={'message'}>PrivacyPolicy</div> */}
                <h1 style={{ textAlign: "center" }}>PRIVACY POLICY</h1>
                <p style={{ textAlign: "center" }}>www.clarity.codes</p>
                <p style={{ textAlign: "center" }}>
                  Effective date: 1 st April 2021
                </p>
                <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
