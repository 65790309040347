import Validator from "js-object-validation";
import { message } from "../common";

export const addJob = (data) => {
  const validations = {
    jobTitleId: {
      required: true,
    },
    country: {
      required: true,
    },
    city: {
      required: true,
    },
    address: {
      required: true,
    },
    linkedin: {
      required: false,
      url: true,
    },
    jobDescription: {
      required: true,
    },
    basicQualifications: {
      required: true,
    },
    preferredQualifications: {
      required: true,
    },
    additionalInfoTitle: {
      required: false,
    },
    additionalInfoDescription: {
      required: false,
    },
  };
  // Error messages
  const messages = {
    jobTitleId: {
      required: message.Required.replace(":item", "Job Title"),
    },
    country: {
      required: message.Required.replace(":item", "Country"),
    },
    city: {
      required: message.Required.replace(":item", "City"),
    },
    address: {
      required: message.Required.replace(":item", "Address"),
    },
    linkedin: {
      required: message.Required.replace(":item", "Linkedin"),
    },
    jobDescription: {
      required: message.Required.replace(":item", "Job Description"),
    },
    basicQualifications: {
      required: message.Required.replace(":item", "Basic Qualifications"),
    },
    preferredQualifications: {
      required: message.Required.replace(":item", "Preferred Qualifications"),
    },
    additionalInfoTitle: {
      required: message.Required.replace(":item", "Additional Info"),
    },
    additionalInfoDescription: {
      required: message.Required.replace(
        ":item",
        "Additional Info Description"
      ),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
