import React from "react";
import Skeleton from "react-skeleton-loader";

// import { DashboardWidgetLoader } from "../Resusable/DashboardWidget";
import { TableLoader } from "../Resusable/Table";
import { ViewInputLoader } from "../Resusable/ViewInput";
export const ViewUserLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>Manage User</h1>
      <p className='breadcrums-top'>
        <Skeleton color ="#fff"/>
      </p>
      <div className='card mb-4 card-main'>
        <div className='card-header py-75  align-items-center justify-content-between'>

        <div className='row'>
            <div className='col-md-3 head-col'>
                <h6 className='font-book '><Skeleton color ="#e5e9ef" height={"25px"}/></h6>
              </div>
              <div className='col-md-9 side-col '>
              <div className='dp-right mr-2' >        
                  <Skeleton color ="#e5e9ef" height={"25px"}/>    
              </div>
              </div>
            </div>
          
        </div>
        <div className='card-body card-table'>
        <ViewInputLoader />
        </div>
      </div>

      <div className='card mb-2'>
          <div className='card-header py-75  align-items-center justify-content-between'>
            <div className='row'>
              <div className='col-md-12'>
                <h6 className='m-25 font-book '><Skeleton color ="#e5e9ef" height={"25px"}/></h6>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='table-responsive '>
            <TableLoader columns={5} rows={5} />
            </div>
          </div>
        </div>
        <div className='card mb-2'>
          <div className='card-header py-75  align-items-center justify-content-between'>
            <div className='row'>
              <div className='col-md-12'>
                <h6 className='m-25 font-book '><Skeleton color ="#e5e9ef" height={"25px"}/></h6>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='table-responsive '>
            <TableLoader columns={5} rows={5} />
            </div>
          </div>
        </div>
    </div>
  </>
);
