/** @format */

import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes, websiteRegex } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import {
  ContactDetailComponent,
  StoreDetailComponent,
} from "../../../components/clients";
import "../manage-clients.css";
import { message } from "../../../../common";
import countryStateData from "../../../../common/countryState.json";
import { contactInfoValidator } from "../../../../validators/contactInfo";
import { requestStore } from "../../../../validators/requestStore";
import { requestClient } from "../../../../validators/requestClient";
import moment from "moment";

class ViewClientRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      storeData: {},
      clientData: {},
      isLoading: false,
      isUpdating: false,
      updatingStatus: "",
      isStoreEditable: false,
      statesOption: [],
      countriesOption: [],
      isUpdating: false,
      updatingSection: "contact",
      isUploading: false,
      codeError: false,
      isCertificateUpdating: false,
      dateError: false,
      emailError: false,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        clientId: id,
      },
      () => this.viewClient()
    );
  };

  viewClient = async () => {
    const { clientId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_CLIENT.service,
      ApiRoutes.VIEW_CLIENT.url.replace(":id", clientId),
      ApiRoutes.VIEW_CLIENT.method,
      ApiRoutes.VIEW_CLIENT.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      const { subscriptionData = {}, user = {} } = data || {};
      const {
        first_name = "",
        last_name = "",
        profile_picture,
        gender = "",
        other_info = {},
        idFormat = {},
        store = {},
        permission = [],
        clientId = "",
        birth_date = "",
        status = "",
        email = "",
      } = user || {};
      const { country = "" } = other_info || {};
      this.setState({
        clientData: {
          email: email || "",
          status: status || "",
          first_name: first_name || "",
          last_name: last_name || "",
          profile_picture: profile_picture || "",
          country: country || "",
          gender: gender || "",
          birth_date: birth_date ? new Date(birth_date) : null || "",
        },
        storeData: {
          ...store,
          clientId,
          companyName: store.companyName || "",
          website: store.website || "",
          phone: store.phone || "",
          businessRegNo: store.businessRegNo || "",
          VATNumber: store.VATNumber || "",
          TradeMarkCertificateNo: store.TradeMarkCertificateNo || "",
          stateOfIssuingCertificate: store.stateOfIssuingCertificate || "",
          address1: store.address1 || "",
          address2: store.address2 || "",
          insideUSACanada: store.insideUSACanada ? "Yes" : "No",
          country: store.country || "",
          countryCode: store.countryCode || "",
          state: store.state || "",
          postCode: store.postCode || "",
        },
        idFormatData: {
          ...idFormat,
        },
        permission,
      });
    }
  };
  updateClientStatus = async (status) => {
    const { clientId } = this.state;
    this.setState({
      isUpdating: true,
      updatingStatus: status,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_STATUS.service,
      ApiRoutes.UPDATE_CLIENT_STATUS.url.replace(":id", clientId),
      ApiRoutes.UPDATE_CLIENT_STATUS.method,
      ApiRoutes.UPDATE_CLIENT_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.props.history.push(AppRoutes.MANAGE_CLIENTS_REQUESTS);
    }
  };
  resendInvitation = async () => {
    const { clientId } = this.state;
    // const { value } = await SweetAlertComponent({
    //   title: 'Are you sure?',
    //   text: 'Do you want to resend invitation.',
    // });
    // if (!value) {
    //   return;
    // }
    this.setState({
      isUpdating: true,
      updatingStatus: "Invited",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESEND_INVITATION_TO_CLIENT.service,
      ApiRoutes.RESEND_INVITATION_TO_CLIENT.url.replace(":id", clientId),
      ApiRoutes.RESEND_INVITATION_TO_CLIENT.method,
      ApiRoutes.RESEND_INVITATION_TO_CLIENT.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isUpdating: false,
      updatingStatus: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.MANAGE_CLIENTS_REQUESTS);
    }
  };
  customBreadCrumb = () => {
    const {
      isLoading,
      clientData: { first_name = "", last_name = "" },
    } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      [first_name, last_name].filter(Boolean).join(" ") || "View user"
    );
  };
  handleStoreInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    const { storeData, clientData } = this.state;
    if (name === "businessRegNo" && isNaN(value)) {
      return;
    }
    if (name === "VATNumber" && isNaN(value)) {
      return;
    }
    if (name === "TradeMarkCertificateNo" && isNaN(value)) {
      return;
    }
    if (name === "phone" && isNaN(value)) {
      return;
    }
    if (name === "postCode" && isNaN(value)) {
      return;
    }
    if (!storeData?.countryCode && clientData?.email) {
      this.setState({
        codeError: true,
      });
    } else {
      this.setState({
        codeError: false,
      });
    }

    if (!clientData?.email) {
      this.setState({
        emailError: true,
      });
    } else {
      this.setState({
        emailError: false,
      });
    }
    this.setState({
      storeData: {
        ...this.state.storeData,
        [name]: value,
        errors: {
          ...this.state.storeData.errors,
          [name]: "",
        },
      },
    });
  };

  updateStoreInfo = async () => {
    const { clientId, storeData } = this.state;
    this.setState({
      isUpdating: true,
      updatingSection: "store",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STORE_INFO.service,
      ApiRoutes.UPDATE_STORE_INFO.url.replace(":id", clientId),
      ApiRoutes.UPDATE_STORE_INFO.method,
      ApiRoutes.UPDATE_STORE_INFO.authenticate,
      undefined,
      {
        storeData: {
          ...storeData,
          insideUSACanada: storeData.insideUSACanada
            ? storeData.insideUSACanada.value
            : true,
        },
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isStoreEditable: false,
      });
      toast.success(response.messages[0]);
      this.viewClient();
    }
  };
  submitStoreDetails = () => {
    const { storeData } = this.state;
    if (
      storeData &&
      storeData.website &&
      !websiteRegex.test(storeData.website)
    ) {
      this.setState({
        storeData: {
          ...this.state.storeData,
          errors: {
            ...this.state.storeData.errors,
            website: message.InvalidWebite,
          },
        },
      });
      return;
    }
    console.log(storeData);
    const { isValid, errors } = requestStore(storeData);
    if (isValid) {
      this.updateStoreInfo(storeData);
    } else {
      this.setState({
        storeData: {
          ...this.state.storeData,
          errors: errors,
        },
      });
    }
  };

  updateClientInfo = async (clientData) => {
    const { clientId } = this.state;
    this.setState({
      isUpdating: true,
      updatingSection: "contact",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_INFO.service,
      ApiRoutes.UPDATE_CLIENT_INFO.url.replace(":id", clientId),
      ApiRoutes.UPDATE_CLIENT_INFO.method,
      ApiRoutes.UPDATE_CLIENT_INFO.authenticate,
      undefined,
      {
        ...clientData,
        birth_date: moment(clientData.birth_date).toISOString(true),
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        isClientEditable: false,
      });
      toast.success(response.messages[0]);
      this.viewClient();
    }
  };
  genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];
  submitContactInfoDetails = () => {
    const { clientData, dateError } = this.state;
    console.log(clientData);
    if (!clientData.birth_date) {
      this.setState({
        dateError: true,
      });
    }
    const { isValid, errors } = requestClient(clientData);
    if (isValid && clientData.birth_date) {
      this.updateClientInfo(clientData);
    } else {
      this.setState({
        dateError: true,
        clientData: {
          ...this.state.clientData,
          errors: errors,
        },
      });
    }
  };
  onSelect = (selectedOption) => {
    console.log(selectedOption);
    if (selectedOption.value) {
      this.setState({
        storeData: {
          ...this.state.storeData,
          insideUSACanada: selectedOption.label,
        },
      });
    }
  };
  onSelectOption = (options, name, moduleName) => {
    if (name === "country") {
      this.setState({
        [moduleName]: {
          ...this.state[moduleName],
          [name]: options.label,
          state: "",
          errors: {
            ...this.state.storeData.errors,
            [name]: "",
          },
        },
      });
      if (options && options.value) {
        this.handleStatesOption(options.value);
      }
    } else {
      this.setState({
        [moduleName]: {
          ...this.state[moduleName],
          [name]: options.label,
          errors: {
            ...this.state.storeData.errors,
            [name]: "",
          },
        },
      });
    }
  };
  handleStatesOption = (value) => {
    let temp = [];
    let selectedCountryIndex = countryStateData.findIndex(
      (element) => element.name === value
    );
    if (selectedCountryIndex > -1) {
      countryStateData[selectedCountryIndex].states
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((state) => {
          temp.push({
            label: state.name,
            value: state.name,
          });
        });
      this.setState({
        statesOption: temp,
      });
    }
  };

  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      if (
        name === "storeLogo" &&
        name === "businessRegNo_image" &&
        name === "VATNumber_image" &&
        name === "TradeMarkCertificateNo_image"
      ) {
        this.setState({
          storeData: {
            ...this.state.storeData,
            errors: {
              ...this.state.storeData.errors,
              [name]: message.InvalidImages,
            },
          },
        });
      } else {
        this.setState({
          clientData: {
            ...this.state.clientData,
            errors: {
              ...this.state.storeData.errors,
              [name]: message.InvalidImages,
            },
          },
        });
      }
      return;
    }
    this.setState({
      clientData: {
        ...this.state.clientData,
        errors: {
          ...this.state.clientData.errors,
          [name]: "",
        },
      },
      storeData: {
        ...this.state.storeData,
        errors: {
          ...this.state.storeData.errors,
          [name]: "",
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = "";
  };

  onDeleteFile = (fieldName) => {
    console.log(fieldName);
    if (fieldName === "storeLogo") {
      this.setState({
        storeData: {
          ...this.state.storeData,
          [fieldName]: "",
          errors: {
            ...this.state.storeData.errors,
            [fieldName]: "",
          },
        },
      });
    } else {
      this.setState({
        clientData: {
          ...this.state.clientData,
          [fieldName]: "",
          errors: {
            ...this.state.clientData.errors,
            [fieldName]: "",
          },
        },
      });
    }
  };
  uploadPicture = async (data, name) => {
    if (name === "storeLogo") {
      this.setState({
        isUploading: true,
      });
    }

    this.setState({
      isCertificateUpdating: name,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      if (
        name === "storeLogo" ||
        name === "businessRegNo_image" ||
        name === "VATNumber_image" ||
        name === "TradeMarkCertificateNo_image"
      ) {
        this.setState({
          storeData: { ...this.state.storeData, [name]: path },
        });
      } else {
        this.setState({
          clientData: { ...this.state.clientData, [name]: path },
        });
      }
    }
    this.setState({
      isUploading: false,
      isCertificateUpdating: false,
      uploadPercentage: 0,
      updatingSection: name === "storeLogo" ? "store" : "contact",
    });
  };

  onDeleteImage = async (file, name) => {
    console.log(file);
    const myArray = file.split("/");
    let fileName = myArray[3];
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_IMAGE.service,
      ApiRoutes.DELETE_IMAGE.url.replace(":file", fileName),
      ApiRoutes.DELETE_IMAGE.method,
      ApiRoutes.DELETE_IMAGE.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      if (name === "storeLogo") {
        this.setState({
          storeData: {
            ...this.state.storeData,
            [name]: "",
          },
        });
      } else {
        this.setState({
          clientData: {
            ...this.state.clientData,
            [name]: "",
          },
        });
      }
    }
  };

  handleContactInfoChange = (event) => {
    const {
      target: { name, value },
    } = event;
    // this.setState({
    //   clientData: {
    //     ...this.state.clientData,
    //     [name]: value,
    //     errors: {
    //       ...this.state.clientData.errors,
    //       [name]: "",
    //     },
    //   },
    // });
    this.setState({
      clientData: {
        ...this.state.clientData,
        [name]: value,
      },
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
  };
  onSelectGender = (selectedOption) => {
    if (selectedOption.label) {
      this.setState({
        clientData: {
          ...this.state.clientData,
          gender: selectedOption.label,
        },
      });
    }
  };
  handleDateChange = (date) => {
    this.setState({
      dateError: false,
      clientData: {
        ...this.state.clientData,
        birth_date: date,
      },
    });
    console.log(date);
  };
  render() {
    const {
      isLoading,
      isUpdating,
      updatingStatus,
      isStoreEditable,
      isClientEditable,
      clientData,
      storeData,
      statesOption,
      updatingSection,
      isUploading,
      uploadPercentage,
      codeError,
      isCertificateUpdating,
      dateError,
      emailError,
      // status,
    } = this.state;

    let allCountry = [];
    countryStateData.forEach((element) => {
      allCountry.push({ label: element.name, value: element.name });
    });

    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2 manageClientDetail">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
        />
        <div className="row">
          <div className="col-md-12 d-flex">
            <StoreDetailComponent
              emailError={emailError}
              isLoading={isLoading}
              isStoreEditable={isStoreEditable}
              storeData={storeData}
              allCountry={allCountry}
              isUpdating={isUpdating}
              onEdit={() =>
                this.setState({
                  isStoreEditable: true,
                })
              }
              onSubmit={this.submitStoreDetails}
              onCancel={() =>
                this.setState({
                  isStoreEditable: false,
                })
              }
              onChange={this.handleStoreInfoChange}
              onSelectOption={this.onSelectOption}
              statesOption={statesOption}
              onSelect={this.onSelect}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              isUploading={isUploading && updatingSection === "store"}
              uploadPercentage={uploadPercentage}
              isCertificateUpdating={isCertificateUpdating}
              codeError={codeError}
              onDeleteImage={this.onDeleteImage}
              clientData={clientData}
              handleContactInfoChange={this.handleContactInfoChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <ContactDetailComponent
              dateError={dateError}
              isLoading={isLoading}
              isUpdating={isUpdating}
              clientData={clientData}
              isEditable={isClientEditable}
              onEdit={() =>
                this.setState({
                  isClientEditable: true,
                })
              }
              onChange={this.handleContactInfoChange}
              onSubmit={this.submitContactInfoDetails}
              genderOptions={this.genderOptions}
              onCancel={() =>
                this.setState({
                  isClientEditable: false,
                })
              }
              allCountry={allCountry}
              onSelectGender={this.onSelectGender}
              onSelectOption={this.onSelectOption}
              statesOption={statesOption}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              isUploading={isUploading && updatingSection === "contact"}
              uploadPercentage={uploadPercentage}
              onDateChange={this.handleDateChange}
              onDeleteImage={this.onDeleteImage}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 footer-card-action-block">
            <div className="card card-main flex-fill">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 footer-form-card">
                    <div>
                      {clientData?.status === "Submitted" && (
                        <div>
                          <button
                            className="btn approve-btn"
                            onClick={() => this.updateClientStatus("Approved")}
                            disabled={
                              isUpdating && updatingStatus === "Approved"
                            }
                          >
                            {isUpdating && updatingStatus === "Approved" ? (
                              <>
                                <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                APPROVE
                              </>
                            ) : (
                              "APPROVE"
                            )}
                          </button>
                          <button
                            className="btn reject-btn"
                            onClick={() => this.updateClientStatus("Rejected")}
                            disabled={
                              isUpdating && updatingStatus === "Rejected"
                            }
                          >
                            {isUpdating && updatingStatus === "Rejected" ? (
                              <>
                                <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                REJECT
                              </>
                            ) : (
                              "REJECT"
                            )}
                          </button>
                        </div>
                      )}
                      {(clientData.status === "Invited" ||
                        clientData.status === "Accepted") && (
                        <div>
                          <button
                            className="btn reject-btn"
                            onClick={() => this.updateClientStatus("Cancelled")}
                            disabled={
                              isUpdating && updatingStatus === "Cancelled"
                            }
                          >
                            {isUpdating && updatingStatus === "Cancelled" ? (
                              <>
                                <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                CANCEL
                              </>
                            ) : (
                              "CANCEL"
                            )}
                          </button>
                        </div>
                      )}
                      {clientData.status === "Rejected" && (
                        <div>
                          <button
                            className="btn approve-btn"
                            onClick={() => this.updateClientStatus("Approved")}
                            disabled={
                              isUpdating && updatingStatus === "Approved"
                            }
                          >
                            {isUpdating && updatingStatus === "Approved" ? (
                              <>
                                <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                APPROVE INVITATION
                              </>
                            ) : (
                              "APPROVE"
                            )}
                          </button>
                        </div>
                      )}
                      {clientData.status === "Cancelled" && (
                        <div>
                          <button
                            className="btn approve-btn"
                            onClick={this.resendInvitation}
                            disabled={
                              isUpdating && updatingStatus === "Invited"
                            }
                          >
                            {isUpdating && updatingStatus === "Invited" ? (
                              <>
                                <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                RESEND
                              </>
                            ) : (
                              "RESEND"
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ViewClientRequest;
