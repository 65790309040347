import React, { useState } from "react";
import { TableLoader } from "../../../components/loader/Resusable/Table"; //"../loader/Resusable/Table";
import noData from "../../../../assets/img/no-data.png";
import { GeneralRow } from "./generalRow";

export const GeneralListComponent = ({
  generalDetail,
  isFilterApplied,
  isLoading,
  onEdit,
  onDelete,
  fetchGeneralRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  viewUser,
  handleUpdateStatus,
  onExpand,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <>
            {userDetail && userDetail == "Web" ? (
              <table width="100%" cellSpacing="0">
                <thead className="pad-top">
                  <tr>
                    <th>Request ID </th>
                    <th style={{ Width: "350px" }}>
                      Request name{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "name"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("name", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "name"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("name", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th style={{ Width: "420px" }}>
                      Subject{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "email"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("email", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "email"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("email", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    {userDetail !== "App" && (
                      <th>
                        Assignee{" "}
                        <span className={"cursor-pointer"}>
                          <span
                            className={`th-up ${
                              order === "asc" && sortBy === "country"
                                ? "active-ac"
                                : ""
                            }`}
                            // onClick={() => setFilter("country", "asc")}
                          >
                            <i className="fa fa-long-arrow-up"></i>
                          </span>
                          <span
                            className={`th-down ${
                              order === "desc" && sortBy === "country"
                                ? "active-ac"
                                : ""
                            }`}
                            // onClick={() => setFilter("country", "desc")}
                          >
                            <i className="fa fa-long-arrow-down"></i>
                          </span>
                        </span>{" "}
                      </th>
                    )}

                    {userDetail !== "App" && (
                      <th>
                        Status{" "}
                        <span className={"cursor-pointer"}>
                          <span
                            className={`th-up ${
                              order === "asc" && sortBy === "country"
                                ? "active-ac"
                                : ""
                            }`}
                            onClick={() => setFilter("country", "asc")}
                          >
                            <i className="fa fa-long-arrow-up"></i>
                          </span>
                          <span
                            className={`th-down ${
                              order === "desc" && sortBy === "country"
                                ? "active-ac"
                                : ""
                            }`}
                            onClick={() => setFilter("country", "desc")}
                          >
                            <i className="fa fa-long-arrow-down"></i>
                          </span>
                        </span>{" "}
                      </th>
                    )}

                    <th
                      style={{
                        // width: "160px",
                        // paddingRight: "20%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          width: "160px",
                          // paddingRight: "20%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {generalDetail && generalDetail.length ? (
                    generalDetail.map((IndividualGeneralDetail, index) => (
                      <GeneralRow
                        key={index}
                        IndividualGeneralDetail={IndividualGeneralDetail}
                        fetchGeneralRecords={fetchGeneralRecords}
                        onEdit={() => onEdit(IndividualGeneralDetail)}
                        onDelete={() => onDelete(IndividualGeneralDetail._id)}
                        viewUser={() => viewUser(IndividualGeneralDetail)}
                        handleUpdateStatus={(status, val) =>
                          handleUpdateStatus(status, val)
                        }
                        onExpand={() => onExpand(index)}
                      />
                    ))
                  ) : (
                    <tr className="no-tr">
                      <td
                        colSpan={5}
                        className={"text-center image-no-data-xs"}
                      >
                        {isFilterApplied ? (
                          <img src={noData} alt="" />
                        ) : (
                          <img src={noData} alt="" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <table width="100%" cellSpacing="0">
                <thead className="pad-top">
                  <tr>
                    <th style={{ Width: "350px" }}>
                      Request ID{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "name"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("name", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "name"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("name", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th style={{ Width: "420px" }}>
                      Request name{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "email"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("email", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "email"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("email", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    {userDetail !== "App" && (
                      <th>
                        Subject{" "}
                        <span className={"cursor-pointer"}>
                          <span
                            className={`th-up ${
                              order === "asc" && sortBy === "details"
                                ? "active-ac"
                                : ""
                            }`}
                            onClick={() => setFilter("details", "asc")}
                          >
                            <i className="fa fa-long-arrow-up"></i>
                          </span>
                          <span
                            className={`th-down ${
                              order === "desc" && sortBy === "details"
                                ? "active-ac"
                                : ""
                            }`}
                            onClick={() => setFilter("details", "desc")}
                          >
                            <i className="fa fa-long-arrow-down"></i>
                          </span>
                        </span>{" "}
                      </th>
                    )}
                    <th>
                      Assignee{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "logo"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("logo", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "logo"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("logo", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                    {userDetail !== "App" && (
                      <th>
                        Status{" "}
                        <span className={"cursor-pointer"}>
                          <span
                            className={`th-up ${
                              order === "asc" && sortBy === "status"
                                ? "active-ac"
                                : ""
                            }`}
                            onClick={() => setFilter("status", "asc")}
                          >
                            <i className="fa fa-long-arrow-up"></i>
                          </span>
                          <span
                            className={`th-down ${
                              order === "desc" && sortBy === "status"
                                ? "active-ac"
                                : ""
                            }`}
                            onClick={() => setFilter("status", "desc")}
                          >
                            <i className="fa fa-long-arrow-down"></i>
                          </span>
                        </span>{" "}
                      </th>
                    )}

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {generalDetail && generalDetail.length ? (
                    generalDetail.map((IndividualGeneralDetail, index) => (
                      <GeneralRow
                        key={index}
                        IndividualGeneralDetail={IndividualGeneralDetail}
                        fetchGeneralRecords={fetchGeneralRecords}
                        onEdit={() => onEdit(IndividualGeneralDetail)}
                        onDelete={() => onDelete(IndividualGeneralDetail._id)}
                        viewUser={() => viewUser(IndividualGeneralDetail)}
                        handleUpdateStatus={(status, val) => {
                          console.log("llooooooo");
                          handleUpdateStatus(status, val);
                        }}
                        onExpand={() => onExpand(index)}
                      />
                    ))
                  ) : (
                    <tr className="no-tr">
                      <td
                        colSpan={5}
                        className={"text-center image-no-data-xs"}
                      >
                        {isFilterApplied ? (
                          <img src={noData} alt="" />
                        ) : (
                          <img src={noData} alt="" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
