import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../../assets/img/edit-3.svg";
import editIconWhite from "../../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../../assets/img/trash-2-white.svg";
import Switch from "react-switch";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import listIcon from "../../../../assets/img/list.svg";
import reopen from "../../../../assets/img/reopen.svg";
import { ReactComponent as Reopen } from "../../../../assets/img/corner-up-right_new.svg";
import contact from "../../../../assets/img/contact.svg";
import minusCircleRightIcon from "../../../../assets/img/minus-circle-light.svg";
import SweetAlertComponent from "../../../components/sweetAlertComponent";

export const GeneralRow = ({
  IndividualGeneralDetail,
  onEdit,
  onDelete,
  fetchGeneralRecords,
  viewUser,
  handleUpdateStatus,
  onExpand,
}) => {
  const statusBackground = {
    completed: "#EBFBE8",
    acknowledged: "#FBF1E8",
    reopened: "#E7EEF6",
    canceled: "#FFE9E9",
    "in process": "#FFFDE5",
  };
  const statusTextColor = {
    completed: "#3AD219",
    acknowledged: "#D27119",
    reopened: "#0F51A1",
    canceled: "#FF2323",
    "in process": "#E2D100",
  };
  const [generalEdit, setGeneralEdit] = useState(IndividualGeneralDetail);
  // const generalEdit = IndividualGeneralDetail;

  const status = generalEdit?.status?.toLocaleLowerCase();

  return (
    <tr>
      <td>
        <div className="icon_faqsBox" style={{ paddingLeft: "28px" }}>
          {generalEdit?.request_id}
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          <div>{generalEdit?.name}</div>
          <div>{generalEdit?.email}</div>
        </div>
      </td>
      <td className="pre-desc">
        {generalEdit?.details && generalEdit?.details.length <= 100 ? (
          generalEdit?.details
        ) : (
          <p className="mb-0">
            {IndividualGeneralDetail.isExpand
              ? generalEdit?.details
              : generalEdit?.details.substr(0, 100)}
            <span className="bold-desc cursor-pointer" onClick={onExpand}>
              {!IndividualGeneralDetail.isExpand
                ? "...Read More"
                : "...Read less"}
            </span>
          </p>
        )}
      </td>
      {generalEdit?.users !== "App" && (
        <td>
          <div
            style={{
              width: "100%",
              // minWidth: "300px",
              // maxWidth: "300px",
              whiteSpace: "normal",
            }}
          >
            {`${generalEdit?.assignStaffId?.first_name} ${generalEdit?.assignStaffId?.last_name}`}
          </div>
        </td>
      )}
      {generalEdit?.users !== "App" && (
        <td>
          <div
            style={{
              width: "100%",
              // minWidth: "300px",
              // maxWidth: "300px",
              whiteSpace: "normal",
              background: statusBackground[status],
              color: statusTextColor[status],
              padding: "5px",
              borderRadius: "6px",
              textAlign: "center",
            }}
          >
            {generalEdit?.status}
          </div>
        </td>
      )}

      <td className="switchBox">
        <div
          className="d-flex align-items-start justify-content-end"
          style={{
            width: "100%",
          }}
        >
          <button className="btn btn-secondary th-btn" onClick={viewUser}>
            {" "}
            <img src={listIcon} alt="" /> View{" "}
          </button>
          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={() => {
              if (generalEdit?.phone) {
                window.open(`tel:${generalEdit?.phone}`);
              } else if (generalEdit?.email) {
                window.open(`mailto:${generalEdit?.email}`);
              } else {
                toast.info("There is no any contact details.");
              }
            }}
          >
            <span>
              <img src={contact} alt="" className={"img-main"} />
              <img src={contact} alt="" className={"img-hover"} />
            </span>
            Contact
          </Button>
          <Button
            variant={generalEdit?.status !== "Completed" ? "danger" : ""}
            className={`${
              generalEdit?.status !== "Completed" ? "delete-tb" : ""
            } btn th-btn  action-block-btn reopen-main-container`}
            onClick={() => {
              if (
                generalEdit?.status === "Completed" ||
                generalEdit?.status === "Canceled"
              ) {
                handleUpdateStatus("Reopened", generalEdit?._id);
              } else {
                handleUpdateStatus("Canceled", generalEdit?._id);
              }
            }}
          >
            <span className="child-repoen-span">
              {generalEdit?.status == "Completed" ||
              generalEdit?.status == "Canceled" ? (
                <Reopen />
              ) : (
                <img src={minusCircleRightIcon} alt="" />
              )}
            </span>
            {generalEdit?.status == "Completed" ||
            generalEdit?.status == "Canceled"
              ? "Reopen"
              : "Cancel"}
          </Button>
        </div>
      </td>
    </tr>
  );
};
