import React from "react";
import StaffRow from "./StaffRow";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";

export const ListStaffComponent = ({ staff, isLoading, viewHandler }) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-lg">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th>Name</th>
                <th>Employee ID</th>
                <th>Role</th>
                <th>Email</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {staff && staff.length ? (
                staff.map((element, index) => (
                  <StaffRow
                    key={index}
                    staffData={element}
                    viewHandler={() => viewHandler(element._id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={6} className={"text-center image-no-data-sm"}>
                    <img src={noData} alt="" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
