import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import warnImg from '../../../assets/img/warning.svg';

export const EditBatchError = ({
  open,
  handleClose,
  message = 'This batch has been assigned to some client.',
}) => {
  useEffect(() => {
    new Image().src = warnImg;
  }, []);
  return (
    <Modal
      show={open}
      onHide={handleClose}
      dialogClassName='modal-25w'
      restoreFocus={false}
    >
      <Modal.Body>
        <div className='row lf-10'>
          <div className='col-md-12 text-center '>
            <img src={warnImg} alt='' className='alert-logo' />
            <h4 className='modal-head'>Can’t Edit Batch</h4>
            <span className='modal-desc'>{message}</span>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className='col-md-12 footer-form'>
          <button
            className='btn btn-primary submit-btn modal-btn-ft-center'
            onClick={handleClose}
          >
            {' '}
            OK
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
