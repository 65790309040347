import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./view/components/scrollToTop";
import AppRouter from "./router";
import "react-toastify/dist/ReactToastify.css";

const history = createBrowserHistory();

const App = () => {
  return (
    <div className="App">
      <Router history={history}>
        {/* <Suspense fallback={""}> */}
        <ScrollToTop>
          <AppRouter />
        </ScrollToTop>
        {/* </Suspense> */}
      </Router>
      <ToastContainer autoClose={8000} />
    </div>
  );
};

export default App;
