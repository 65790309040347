import React, { useState } from "react";
import { InputTextField, SubmitButton } from "../forms";
import { addClientValidator } from "../../../validators";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import { toast } from "react-toastify";

export const AddFormComponent = ({ onCancel }) => {
  const [isAdding, setAdding] = useState(false);
  const [clientData, setClientData] = useState({
    first_name: "",
    email: "",
    companyName: "",
    last_name: "",
  });
  const [errors, setErrors] = useState(null);
  // storeName, brandName,
  const { name, email, companyName, first_name, last_name } = clientData;
  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setClientData({
      ...clientData,
      [name]: value.trimLeft(),
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const addClient = async (data) => {
    setAdding(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_CLIENTS.service,
      ApiRoutes.ADD_CLIENTS.url,
      ApiRoutes.ADD_CLIENTS.method,
      ApiRoutes.ADD_CLIENTS.authenticate,
      undefined,
      data
    );
    console.log("data", data);
    console.log("response", response);
    if (response && response.isError) {
      setAdding(false);
      toast.error(response.messages[0]);
    } else {
      await onCancel(true);
      toast.success(response.messages[0]);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      // name,
      email,
      companyName,
      name,
    };
    const { isValid, errors } = addClientValidator(data);
    data.name = name + " " + last_name;
    console.log(data);
    // return;

    if (isValid) {
      addClient(data);
    } else {
      if (last_name) {
        setErrors(errors);
      } else {
        setErrors({ ...errors, last_name: "Please enter last name" });
      }
      return;
    }
  };
  return (
    <form className="product_add proAdd">
      <div className="row">
        <div className="col-md-6 mb-2">
          <InputTextField
            type="text"
            label={"First Name"}
            className={"form-control-product"}
            placeholder="Enter Name"
            name="name"
            value={name}
            onChange={handleChange}
            maxLength={30}
            disabled={isAdding}
            errorMessage={errors && errors.name ? errors.name : ""}
          />
        </div>
        <div className="col-md-6 mb-2">
          <InputTextField
            type="text"
            id="lastname"
            label={"Last name"}
            className={"form-control-product"}
            name="last_name"
            placeholder="Enter Last Name"
            value={last_name}
            onChange={handleChange}
            maxLength={30}
            disabled={isAdding}
            errorMessage={errors && errors.last_name ? errors.last_name : ""}
          />
        </div>
        <div className="col-md-6">
          <InputTextField
            type="text"
            id="email"
            label={"Email"}
            placeholder="Enter Email"
            className={"form-control-product"}
            name="email"
            value={email}
            onChange={handleChange}
            disabled={isAdding}
            errorMessage={errors && errors.email ? errors.email : ""}
          />
        </div>
        <div className="col-md-6 pt-0">
          <InputTextField
            type="text"
            id="companyname"
            label={"Company Name "}
            className={"form-control-product"}
            placeholder="Enter Company Name"
            name="companyName"
            value={companyName}
            onChange={handleChange}
            disabled={isAdding}
            errorMessage={
              errors && errors.companyName ? errors.companyName : ""
            }
          />
        </div>
        <div className="col-md-12 footer-formCZ">
          <div className="d-flex align-items-center justify-content-center">
            <button
              type="button"
              className="btn btn-primary cancel-btn"
              onClick={() => onCancel(false)}
            >
              {" "}
              CANCEL
            </button>

            <SubmitButton
              className={"submit-btn mr-0"}
              text={"INVITE"}
              isLoading={isAdding}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
