/** @format */

import React from "react";
import ClientRow from "./ClientRow";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";

export const ListClientComponent = ({
  clients,
  isLoading,
  viewHandler,
  blockClient,
  unBlockClient,
  setFilter,
  sortBy,
  order,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-lg">
        {isLoading ? (
          <TableLoader columns={7} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="clients-listing-view-thead">
              <tr>
                {[
                  // {
                  //   label: "Store Name",
                  //   value: "storeName",
                  //   enableSorting: true,
                  // },
                  {
                    label: "Company Name",
                    value: "companyName",
                    enableSorting: true,
                  },
                  // {
                  //   label: "Website",
                  //   value: "website",
                  //   enableSorting: false,
                  // },
                  {
                    label: "Client ID",
                    value: "clientid",
                    enableSorting: true,
                  },
                  {
                    label: "Website",
                    value: "website",
                    enableSorting: true,
                  },
                  {
                    label: "Country",
                    value: "country",
                    enableSorting: true,
                  },
                  {
                    label: "Email",
                    value: "email",
                    enableSorting: true,
                  },
                  // {
                  //   label: "Action",
                  //   value: "Action",
                  //   enableSorting: false,
                  // },
                ].map(({ label, value, enableSorting }) => (
                  <th key={value}>
                    {label}{" "}
                    {enableSorting ? (
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === value
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter(value, "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === value
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter(value, "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    ) : null}
                  </th>
                ))}
                {/* <th>Store Name</th>
                <th>Company Name</th>
                <th>Website</th>
                <th>Country</th>
                <th>Email</th>
                <th>Phone</th> */}
                <th></th>
              </tr>
            </thead>

            <tbody>
              {clients && clients.length ? (
                clients.map((client, index) => (
                  <ClientRow
                    key={index}
                    clientData={client}
                    viewClient={() => viewHandler(client._id)}
                    blockClient={() => blockClient(client._id)}
                    unBlockClient={() => unBlockClient(client._id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={8} className={"text-center image-no-data-sm"}>
                    <img src={noData} alt="" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
