import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import Switch from "react-switch";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import SweetAlertComponent from "../../components/sweetAlertComponent";

export const PartnerRow = ({
  partnerData,
  onEdit,
  onDelete,
  fetchPartnersRecords,
}) => {
  const [partnerEdit, setPartnerEdit] = useState(partnerData);
  // const partnerEdit = partnerData;
  const changeStatus = async (data) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_FAQ_STATUS.service,
      ApiRoutes.UPDATE_FAQ_STATUS.url.replace(":id", partnerEdit?._id),
      ApiRoutes.UPDATE_FAQ_STATUS.method,
      ApiRoutes.UPDATE_FAQ_STATUS.authenticate,
      undefined,
      data
    );
    console.log("response", response.data.data);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.data?.message);
      // fetchPartnersRecords();
    }
  };

  // console.log("partnerEdit", partnerEdit?.status);
  const handleChange = async (event) => {
    // console.log("event", event.target.checked);
    const {
      target: { checked },
    } = event;

    const { value } = await SweetAlertComponent({
      title: "",
      text: `Are you sure, you want to ${checked ? "enable" : "disable"}  faq?`,
    });
    if (!value) {
      return;
    }
    setPartnerEdit({
      ...partnerEdit,
      status: checked,
    });
    let data = {
      status: checked,
    };

    changeStatus(data);
  };

  return (
    <tr>
      <td>
        <div className="icon_faqsBox">
          <img src={partnerEdit?.logo ? partnerEdit?.logo : ""} alt="" />
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          {partnerEdit?.name}
        </div>
      </td>
      <td>
        <div
          style={{
            width: "100%",
            // minWidth: "300px",
            // maxWidth: "300px",
            whiteSpace: "normal",
          }}
        >
          <a href={`mailto:${partnerEdit?.email}`} target="_blank">
            {partnerEdit?.email}
          </a>
        </div>
      </td>
      {partnerEdit?.users !== "App" && (
        <td>
          <div
            style={{
              width: "100%",
              // minWidth: "300px",
              // maxWidth: "300px",
              whiteSpace: "normal",
            }}
          >{`${partnerEdit?.city}, ${partnerEdit?.country}`}</div>
        </td>
      )}

      <td className="switchBox">
        <div
          className="d-flex align-items-start justify-content-end"
          style={{
            width: "100%",
          }}
        >
          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={onEdit}
          >
            <span>
              <img src={editIcon} alt="" className={"img-main"} />
              <img src={editIconWhite} alt="" className={"img-hover"} />
            </span>
            Edit
          </Button>
          <Button
            variant="danger"
            className="btn th-btn delete-tb action-block-btn"
            onClick={onDelete}
          >
            <span>
              <img src={trashIcon} alt="" className={"img-main"} />
              <img src={trashIconWhite} alt="" className={"img-hover"} />
            </span>
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};
