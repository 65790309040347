/** @format */

export const PAGE_LIMIT = 10;
export const DB_ACCPTABLE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_TIME_FORMAT = "HH:mm";
export const ACCEPTED_VIDEO_FORMATS = ["m4v", "avi", "mpg", "mp4", "webm"];
export const MobileWidth = 768;
export const youTubeVideoRegex =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const websiteRegex =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i;
export const videoNameRegex = /^[a-zA-Z0-9_. ]+$/;
export const noSpecial = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
export const pagesOption = [
  {
    label: "10 Per page",
    value: 10,
  },
  {
    label: "20 Per page",
    value: 20,
  },
  {
    label: "50 Per page",
    value: 50,
  },
];

export const sendToOptions = [
  {
    label: "App users",
    value: "1",
  },
  {
    label: "Client",
    value: "2",
  },
  {
    label: "Staff",
    value: "3",
  },
];

export const permissionModules = [
  {
    module: { label: "Dashboard", value: "Dashboard" },
    subModules: [
      // { label: "Total Users", value: "Total Users" },
      // { label: "Total Staff", value: "Total Staff" },
      // { label: "Total Scans", value: "Total Scans" },
      // { label: "Total Counterfeits", value: "Total Counterfeits" },
      // { label: "Top Scanned Products", value: "Top Scanned Products" },
      // { label: "Active Locations", value: "Active Locations" },
      // { label: "Push Notifications", value: "Push Notifications" },
      // { label: "Recent Scans", value: "Recent Scans" },
    ],
  },
  {
    module: { label: "Manage Clarity", value: "Manage Clarity" },
    subModules: [
      { label: "Scans", value: "scans" },
      { label: "Counterfeit", value: "counterfeit" },
      { label: "Manage User", value: "manage user" },
      { label: "Manage Video", value: "manage video" },
    ],
  },
  {
    module: { label: "Manage Products", value: "Manage Products" },
    subModules: [],
  },
  {
    module: { label: "Batches", value: "Batches" },
    subModules: [
      // { label: "Add Batch", value: "Add Batch" },
      // { label: "Edit Batch", value: "Edit Batch" },
      // { label: "Delete Batch", value: "Delete Batch" },
      // { label: "Assigned Batch", value: "Assigned Batch" },
    ],
  },

  {
    module: { label: "Manage Staff", value: "Manage Staff" },
    subModules: [
      { label: "Staff", value: "staff listing" },
      { label: "Role", value: "manage roles" },
    ],
  },
  {
    module: { label: "Reports", value: "Reports" },
    subModules: [
      { label: "Batches", value: "batches" },
      { label: "Product", value: "product" },
      { label: "Users", value: "users" },
      { label: "Scans", value: "scans" },
      { label: "Counterfeit", value: "counterfeit" },
    ],
  },
  // {
  //   module: { label: "Help", value: "Help" },
  //   subModules: [
  //     { label: "Faq", value: "faq" },
  //     { label: "Tutorials", value: "tutorials" },
  //   ],
  // },
  {
    module: { label: "Settings", value: "Settings" },
    subModules: [
      { label: "Id Format", value: "id formats" },
      { label: "Notification", value: "notifications" },
    ],
  },
];
export const staffPermissionModules = [
  { label: "Dashboard", value: "Dashboard" },
  { label: "Manage Clarity", value: "Manage Clarity" },
  { label: "Manage Product", value: "Manage Product" },
  { label: "Origin", value: "Origin" },
  { label: "Batches", value: "Batches" },
  { label: "Manage Client", value: "Manage Client" },
  { label: "Settings", value: "Settings" },
];

export const accessTypesOption = [{ label: "Revoke", value: "Read Only" }];

export const accessTypesOptionOld = [
  { label: "Read Only", value: "Read Only" },
  { label: "Read & Write", value: "Read & Write" },
];
export const genderOptions = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  // {
  //   label: "Other",
  //   value: "other",
  // },
];
export const customReactSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),

  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),

  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

export const CANCELLATION_REASON = {
  NOT_GENUINE: {
    KEY: 0,
    VALUE: "Not Genuine",
  },
  INADEQUATE_DESCRIPTION: {
    KEY: 1,
    VALUE: "Inadequate Description",
  },
  DUPLICATE_TICKET: {
    KEY: 2,
    VALUE: "Duplicate Ticket",
  },
  USER_CREATED_BY_MISTAKE: {
    KEY: 3,
    VALUE: "User Created by mistake ",
  },
  NO_RESOLUTION_REQUIRED: {
    KEY: 4,
    VALUE: "No resolution required",
  },
  OTHER: {
    KEY: 5,
    VALUE: "Other",
  },
};

export const CMS_USER = {
  App: {
    KEY: 1,
    VALUE: "App",
  },
  Client: {
    KEY: 1,
    VALUE: "Client",
  },
  Staff: {
    KEY: 1,
    VALUE: "Staff",
  },
  Web: {
    KEY: 1,
    VALUE: "Web",
  },
};
