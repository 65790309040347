import React, { useState, useEffect } from "react";
import { sendToOptions } from "../../../config/Constant";
import { InputTextField, SubmitButton } from "../forms";
import { pushValidator } from "../../../validators";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// import TimePicker from 'react-time-picker';

export const AddFormComponent = ({ push, handleAddCancel, isEdit }) => {
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [sendTo, setSendTo] = useState("");
  const [dateScheduled, setDateScheduled] = useState("");
  const [timeScheduled, setTimeScheduled] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const regex = /^[a-zA-Z0-9]+[^]*$/;
  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === "title") {
      if (value.trim().length <= 30) {
        if (regex.test(value.trim())) {
          setTitle(value);
          setErrors({ ...errors, title: "" });
        } else {
          // setTitle(value);
          if (value === "") {
            setTitle(value);
            setErrors({ ...errors, title: "title cannot be empty" });
          } else {
            setErrors({
              ...errors,
              title:
                "First character will not accept any kind of symbol or special character.",
            });
          }
        }
      } else {
        // setTitle(value);
        setErrors({ ...errors, title: "less than 30" });
        // setErrors(errors);
      }
    }
    if (name === "subject") {
      if (value.trim().length <= 100) {
        if (regex.test(value.trim())) {
          setSubject(value);
          setErrors({ ...errors, subject: "" });
        } else {
          // setSubject(value);
          if (value === "") {
            setSubject(value);
            setErrors({ ...errors, subject: "Subject cannot be empty" });
          } else {
            setErrors({
              ...errors,
              subject:
                "First character will not accept any kind of symbol or special character.",
            });
          }
        }
      } else {
        // setSubject(value);
        setErrors({ ...errors, subject: "less than 100" });
        // setErrors(errors);
      }
    }
    if (name === "timeScheduled") {
      setTimeScheduled(value);
    }
  };

  const onSelect = (val, type) => {
    setSendTo(val.value);
    setErrors({ ...errors, sendTo: "" });
  };
  const onDateChange = (date) => {
    setDateScheduled(date);
    setErrors({ ...errors, dateScheduled: "" });
  };
  const onTimeChange = (event) => {
    setTimeScheduled(event.target.value);
    setErrors({ ...errors, timeScheduled: "" });
  };

  const handleSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();

    let payload = {
      title: title.trim(),
      subject: subject.trim(),
      sendTo: sendTo,
      dateScheduled: dateScheduled
        ? moment(dateScheduled).format("L") + " " + timeScheduled
        : "",
    };
    const { isValid, errors } = pushValidator({ ...payload, timeScheduled });

    if (isValid) {
      if (
        new Date().toISOString() > new Date(payload.dateScheduled).toISOString()
      ) {
        setIsLoading(false);
        setErrors({
          ...errors,
          timeScheduled: "Time must be greater than current time",
        });
        return;
      }
      setErrors(errors);
      if (push.id) {
        updateEntry({ ...payload });
      } else {
        addEntry({ ...payload });
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
    }
  };

  const addEntry = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_PUSH.service,
      ApiRoutes.ADD_PUSH.url,
      ApiRoutes.ADD_PUSH.method,
      ApiRoutes.ADD_PUSH.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  const updateEntry = async (payload) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PUSH.service,
      ApiRoutes.UPDATE_PUSH.url.replace(":id", push.id),
      ApiRoutes.UPDATE_PUSH.method,
      ApiRoutes.UPDATE_PUSH.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      handleAddCancel();
    }
  };

  useEffect(() => {
    if (push.id) {
      setIsEditMode(true);
      setTitle(push.title);
      setSubject(push.subject);
      setSendTo(push.sendTo);
      setDateScheduled(
        push.dateScheduled ? new Date(push.dateScheduled) : null
      );
      setTimeScheduled(
        push.dateScheduled ? push.dateScheduled.split("T")[1].slice(0, 5) : null
      );
    } else {
      setIsEditMode(false);
    }
  }, [push.dateScheduled, push.id, push.sendTo, push.subject, push.title]);

  return (
    <form className="product_add proAdd">
      <div className="row">
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="product_description" className="form-control-label">
              Send to
            </label>
            <Select
              placeholder="User Type"
              options={sendToOptions}
              value={sendToOptions.filter((option) => option.value === sendTo)}
              maxMenuHeight={200}
              onChange={(selectedOption) => onSelect(selectedOption, "sendTo")}
            />
            <div className={"text-danger"}>
              {errors && errors.sendTo ? errors.sendTo : ""}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div className="">
                <label className={`form-control-label`}>Date</label>
                <DatePicker
                  className={"form-control-input"}
                  selected={dateScheduled}
                  minDate={new Date()}
                  onChange={onDateChange}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  // showTimeInput
                  dropdownMode="select"
                />
              </div>
            </div>
            <div className={"text-danger"}>
              {errors && errors.dateScheduled ? errors.dateScheduled : ""}
            </div>
          </div>
          <div className="col-md-4">
            <div className="">
              <div>
                <label className={`form-control-label`}>Time</label>
                <input
                  className={"form-control-input d-block timePickerInput"}
                  type="time"
                  value={timeScheduled}
                  onChange={onTimeChange}
                  pattern="[0-9]{2}:[0-9]{2}"
                />
              </div>
            </div>
            <div className={"text-danger"}>
              {errors && errors.timeScheduled ? errors.timeScheduled : ""}
            </div>
          </div>

          <div className="col-md-12">
            <InputTextField
              placeholder="Enter Notification Heading"
              id="title"
              label={"Title"}
              className={"form-control-product"}
              name="title"
              value={title}
              onChange={onChange}
              errorMessage={errors && errors.title ? errors.title : ""}
            />
          </div>
          <div className="col-md-12">
            <label htmlFor="product_description" className="form-control-label">
              Subject
            </label>
            <textarea
              className="form-control form-control-textarea wt-resize"
              cols="50"
              placeholder="Enter Notification Description..."
              id="subject"
              aria-describedby="category_nameHelp"
              rows="4"
              name={"subject"}
              value={subject}
              onChange={onChange}
            />
            <div className={"text-danger"}>
              {errors && errors.subject ? errors.subject : ""}
            </div>
          </div>
        </div>
        <div className="col-md-12 footer-form">
          <div className="d-flex align-items-center justify-content-center contactForm_admin">
            <button
              className="btn btn-primary cancel-btn"
              onClick={handleAddCancel}
            >
              {" "}
              CANCEL
            </button>
            {isEdit ? (
              <SubmitButton
                className={"submit-btn"}
                text={isEditMode ? "UPDATE" : "SCHEDULE"}
                isLoading={isLoading}
                onSubmit={handleSubmit}
              />
            ) : (
              <button disabled={!isEdit} className="btn btn-primary submit-btn">
                {isEditMode ? "UPDATE" : "SCHEDULE"}
              </button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};
