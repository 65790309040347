import React from "react";
import { TableLoader } from "../loader/Resusable/Table";
import listIcon from "../../../assets/img/list.svg";
import moment from "moment";

export const TransactionsListComponent = ({
  onEdit,
  transactions,
  setFilter,
  sortBy,
  order,
  isLoading,
  viewTransaction,
}) => {
  console.log(transactions);
  return (
    <>
      <div className="card-body card-table">
        <div className="table-responsive fixed-height-table-10-lg">
          {isLoading ? (
            <TableLoader columns={5} rows={10} />
          ) : (
            <table
              className="plansTable transactionTable"
              width="100%"
              cellSpacing="0"
            >
              <thead className="pad-top">
                <tr>
                  {[
                    { label: "Transaction ID", value: "transaction" },
                    { label: "Plan Name", value: "planName" },
                    { label: "Client Name", value: "clientName" },
                    { label: "Date & Time", value: "transactionDate" },
                  ].map(({ label, value }) => (
                    <th key={value}>
                      {label}{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === value
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter(value, "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === value
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter(value, "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                  ))}
                  <th>
                    Status{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === "status"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("status", "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === "status"
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter("status", "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {transactions &&
                  transactions.map((info, index) => (
                    <tr>
                      <td>{info.transactionId}</td>
                      <td>{info.planName}</td>
                      <td>{info.clientName}</td>
                      <td>
                        {moment
                          .utc(info.transactionDate)
                          .format("MM/DD/YYYY HH:mm")}
                      </td>
                      <td className="status_td">
                        <span className="success">{info.status}</span>
                      </td>
                      <td>
                        <div>
                          <button
                            className="btn btn-secondary th-btn"
                            onClick={() => viewTransaction(info)}
                          >
                            {" "}
                            <img src={listIcon} alt="" /> View{" "}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {/* {totalRecords > limit ? (
          <div className='float-right mt-2'>
          <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
           /> 
          </div>
        ) : null}*/}
        </div>
      </div>
    </>
  );
};
