import React from "react";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";
import { FaqRow } from "../faqs-client/FaqRow";

export const ListComponent = ({
  faqs,
  isFilterApplied,
  isLoading,
  setFilter,
  sortBy,
  order,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                <th style={{ Width: "350px" }}>
                  Question{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "scan_id"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("question", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "question"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("question", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>{" "}
                </th>
                <th style={{ Width: "420px" }}>
                  Answer{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "answer"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("answer", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "answer"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("answer", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>Category</th>
                <th>
                  Priority{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "priority"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("priority", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "priority"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("priority", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {faqs && faqs.length ? (
                faqs.map((faq, index) => (
                  <FaqRow
                    key={index}
                    faqData={faq}
                    onEdit={() => onEdit(faq)}
                    onDelete={() => onDelete(faq.id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={5} className={"text-center image-no-data-xs"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
