import React from "react";
import { BatchHistoryLoader } from "../../components/loader/Resusable/BatchHistory";
import minusCircleIcon from "../../../assets/img/removeIcon.svg";
import moment from "moment";

export const AssignedPermissionComponent = ({
  isLoading,
  permission,
  deletingModule,
  removePermission,
}) => {
  return (
    <div className="card mb-12 card-main flex-fill">
      {isLoading ? (
        <BatchHistoryLoader />
      ) : (
        <>
          {" "}
          <div className="card-header py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book ">Revoked Permissions</h6>
              </div>
            </div>
          </div>
          <div className="card-body card-table">
            <div className="table-responsive">
              <table width="100%" cellSpacing="0">
                <thead className="permission_header    ">
                  <tr>
                    <th>Permitted Module</th>
                    <th>Sub Module</th>
                    <th>Access Type</th>
                    <th>Date of Revoked</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {permission && permission.length ? (
                    permission.map((item, index) => (
                      <tr key={index}>
                        <td>{item.module}</td>
                        <td className="text-capitalize">
                          {item.subModules.join(", ")}
                        </td>

                        <td>{item.accessType || "-"}</td>
                        <td>
                          {moment(item.dateAssigned).format("DD MMM YYYY")}
                        </td>
                        <td>
                          <button
                            className="btn btn-secondary th-btn"
                            onClick={() => removePermission(item.module)}
                            disabled={deletingModule === item.module}
                          >
                            {" "}
                            {deletingModule === item.module ? (
                              <>
                                <i className="fa fa-spinner fa-spin"></i>{" "}
                                Removing
                              </>
                            ) : (
                              <>
                                <img src={minusCircleIcon} alt="" /> Remove
                              </>
                            )}{" "}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className={"text-center"}>
                        {"Permission is not assigned to client yet."}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
