import React, { Component } from 'react';
import TestCard from '../../components/testCard/TestCard';
import './test.css'

export default function TestTwo() {
    return (
        <>
            <div className='container-fluid fluid-pd-2 testingBox'>
                <h1 className='h3-head-mob d-md-none'>FAQs</h1>
                
                <div className='row'>                    
                    <TestCard 
                        grid = "col-md-4 mb-3"
                        img = "https://via.placeholder.com/400x250"
                        title = "Reacj Js - Project"
                        description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis soluta, ipsa quis optio ea illum."
                    />

<TestCard 
                        grid = "col-md-4 mb-3"
                        img = "https://via.placeholder.com/400x250"
                        title = "Reacj Js - Project"
                        description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis soluta, ipsa quis optio ea illum."
                    />

<TestCard 
                        grid = "col-md-4 mb-3"
                        img = "https://via.placeholder.com/400x250"
                        title = "Reacj Js - Project"
                        description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis soluta, ipsa quis optio ea illum."
                    />

<TestCard 
                        grid = "col-md-4 mb-3"
                        img = "https://via.placeholder.com/400x250"
                        title = "Reacj Js - Project"
                        description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis soluta, ipsa quis optio ea illum."
                    />

<TestCard 
                        grid = "col-md-4 mb-3"
                        img = "https://via.placeholder.com/400x250"
                        title = "Reacj Js - Project"
                        description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis soluta, ipsa quis optio ea illum."
                    />

<TestCard 
                        grid = "col-md-4 mb-3"
                        img = "https://via.placeholder.com/400x250"
                        title = "Reacj Js - Project"
                        description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis soluta, ipsa quis optio ea illum."
                    />


<TestCard 
                        grid = "col-md-4 mb-3"
                        img = "https://via.placeholder.com/400x250"
                        title = "Reacj Js - Project"
                        description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis soluta, ipsa quis optio ea illum."
                    />
                </div>
            </div>        
        </>
    );
}