import React from "react";
import Skeleton from "react-skeleton-loader";

export const DiscountLoader = () => (
  <>
    <div className="card-header  py-7550  align-items-center justify-content-between">
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="font-book ">Discounts</h6>
          </div>
        </div>
      </div>
    </div>
    <div className="card-body card-table">
      <div className="row">
        <div className="col-md-4">
          <form className="product_add mr-0">
            <div className="position-relative">
              <Skeleton height={"14px"} width={"200px"} color="#e5e9ef" />
            </div>
            <Skeleton height={"40px"} width={"400px"} color="#e5e9ef" />
            <div>
              <Skeleton height={"14px"} width={"300px"} color="#e5e9ef" />
              <br />
              <Skeleton height={"14px"} width={"50px"} color="#e5e9ef" />
            </div>
            <div style={{ marginTop: "29px" }}>
              <Skeleton height={"40px"} width={"130px"} color="#e5e9ef" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
);
