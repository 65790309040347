import React from 'react';
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import mapboxgl from 'mapbox-gl';
import { AppConfig } from '../../../config';
import { Breadcrumbs } from '../../components/partial/Breadcrumbs';
import SimpleMap from '../../components/map';
import './view-counterfeit.css';

mapboxgl.accessToken = AppConfig.MAPBOX_ACCESS_TOKEN;

const MapComponent = () => {
  const { pathname, search, state = {} } = useLocation();
  const query = qs.parse(search);
  const lat = query && query.lat ? Number(query.lat) : 0;
  const long = query && query.long ? Number(query.long) : 0;
  return (
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>Map</h1>
      <Breadcrumbs pathname={pathname} />
      <div className='card mb-4 card-main'>
        <div className='card-header py-7550 align-items-center justify-content-between'>
          <div className='row'>
            <div className='col-md-12 head-col'>
              <h6 className='font-book'>Map</h6>
            </div>
            <div className={'col-md-12'}>
              {lat && long ? (
                <SimpleMap
                  latitude={lat}
                  longitude={long}
                  storeLocation={state.storeLocation || ''}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
