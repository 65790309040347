/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, pagesOption } from "../../../config";
import { ApiHelper } from "../../../helpers";
import Pagination from "../../components/pagination";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { FilterComponent } from "../../components/filters";
import { SearchFilter } from "../../components/filters/searchFilter";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import { ListComponent } from "./ListComponent";
import AddRole from "./AddRole";
import "./index.css";
import addIcon from "../../../assets/img/addIcon.svg";
import SweetAlertComponent from "../../components/sweetAlertComponent";

class Managerole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: [],
      errors: null,
      isLoading: false,
      isFilterApplied: false,
      isUpdating: false,
      isAdding: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 11,
      sortBy: "roleName",
      search: "",
      modalShow: false,
      id: "",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sort || "roleName",
        order: query.order ? query.order : "desc",
      },
      () => this.fetchRoleData()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  fetchRoleData = async (loading = true) => {
    const { currentPage, limit, sortBy, search, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      // sortByStatus: sortBy !== "name" ? sortBy : "",
      search,
      order,
    };
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ROLE.service,
      ApiRoutes.GET_ROLE.url,
      ApiRoutes.GET_ROLE.method,
      ApiRoutes.GET_ROLE.authenticate,
      data,
      undefined
    );

    this.setState({
      isLoading: false,
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { role, totalRecords },
      } = response.data;
      this.setState({
        role,
        totalRecords,
      });
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    console.log("onSearch", this.state.search);
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  viewHandler = (id) => {
    const {
      props: {
        location: { search },
      },
    } = this;
    this.props.history.push({
      pathname: AppRoutes.VIEW_STAFF.replace(":id", id),
      state: {
        search,
      },
    });
  };

  onRoleModal = () => {
    this.setState({ modalShow: true });
  };

  onCloseModal = () => {
    this.setState({ modalShow: false });
  };

  setSortFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  refreshAPI = () => {
    this.onCloseModal();
    this.fetchRoleData();
  };

  onDelete = async (roleData) => {
    console.log("role", roleData.onBordedStaff);

    if (roleData.onBordedStaff > 0) {
      await SweetAlertComponent({
        text: `You can't delete this role as it's already assigned`,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      const { value } = await SweetAlertComponent({
        // title: 'Are you sure?',
        text: `Are you sure, you want to delete this role ?`,
      });
      if (!value) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_ROLE.service,
        ApiRoutes.DELETE_ROLE.url.replace(":id", roleData.id),
        ApiRoutes.DELETE_ROLE.method,
        ApiRoutes.DELETE_ROLE.authenticate,
        undefined,
        undefined
      );

      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        await this.fetchRoleData();
        toast.success(response.messages[0]);
      }
    }
  };
  render() {
    const {
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      order,
      search,
      role,
      isFilterApplied,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const filtersOption = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Role",
        value: "roleName",
      },
      {
        label: "Onboardded Staff",
        value: "onBordedStaff",
      },
      {
        label: "Updated On",
        value: "updatedAt",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Manage Staff</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card card-pagination card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book ">Role Details</h6>
              </div>

              <div className="col-md-9 filter-col-lg">
                <div className="dp-right">
                  <button
                    className="btn btn-primary add-btn add-wd-1024"
                    onClick={this.onRoleModal}

                    // to={AppRoutes.MANAGE_STAFF_REQUESTS}
                  >
                    <img src={addIcon} alt="" srcset="" /> Add New role
                  </button>
                </div>
                <FilterComponent
                  options={filtersOption}
                  selectedFilter={sortBy}
                  onFilterChange={this.setSortFilter}
                />

                <SearchFilter
                  id={"sm-search-filter"}
                  placeholder={"Search by Name and Employee ID"}
                  search={search}
                  onChange={this.handleSearch}
                />

                {/* <div className="dp-right">
                  <Link
                    className=" icon-btn"
                    to={`${AppRoutes.MANAGE_STAFF}?view=list`}
                  >
                    <img
                      src={viewMode === "list" ? ListActive : ListNoActive}
                      alt=""
                    />
                  </Link>
                  <Link
                    className=" icon-btn"
                    to={`${AppRoutes.MANAGE_STAFF}?view=grid`}
                  >
                    <img
                      src={viewMode === "grid" ? gridActive : gridNoActive}
                      alt=""
                    />
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
          {/* {viewMode === "grid" ? (
            <div className="card-body card-grid row-grid-fixed">
              <div className="row ">
                {isLoading ? (
                  <CategoryCard />
                ) : role && role.length ? (
                  <GridStaffComponent
                    role={role}
                    isUpdating={isUpdating}
                    viewHandler={this.viewHandler}
                  />
                ) : (
                  <div className="col-md-12 col-vd-nodata">
                    <img src={noData} alt="" />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ListComponent
              role={role}
              isLoading={isLoading}
              isUpdating={isUpdating}
              viewHandler={this.viewHandler}
            />
          )} */}
          <ListComponent
            role={role}
            isLoading={isLoading}
            isFilterApplied={isFilterApplied}
            setFilter={this.setSortFilter}
            onDelete={this.onDelete}
            refreshAPI={this.refreshAPI}
            order={order}
            handleModal={this.onRoleModal}
            sortBy={sortBy}
          />
        </div>
        <div className="footer-pg manageStaffFooter custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
        <AddRole
          modalShow={this.state.modalShow}
          modalClose={this.onCloseModal}
          placeholder={"Enter Role here"}
          refreshAPI={this.refreshAPI}
        />
      </div>
    );
  }
}

export default Managerole;
