import Validator from 'js-object-validation';
import { message } from '../common';

export const originValidator = (data) => {
  const validations = {
    origin: {
      required: true,
    },
    states: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    origin: {
      required: message.Required.replace(':item', 'Origin'),
    },
    states: {
      required: message.Required.replace(':item', 'State'),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
