import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import planName from "../../../assets/img/planName.svg";
import userSubscribed from "../../../assets/img/userSubscribed.svg";
import billingCycle from "../../../assets/img/billingCycle.svg";
import pricingIcon from "../../../assets/img/pricingIcon.svg";
import statusIcon from "../../../assets/img/statusIcon.svg";
import renewDateIcon from "../../../assets/img/renewDateIcon.svg";
import Switch from "react-switch";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import noData from "../../../assets/img/no-data.png";
import moment from "moment";
import { BillingLoader } from "../../components/loader/Resusable/BillingLoader";
import { Button, Spinner } from "react-bootstrap";

class ViewClientSubscription extends Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      detailId: "",
      isLoading: false,
      isUpdating: true,
      paidBilling: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ detailId: id }, () => {
        this.getSubscription();
      });
    }
  };

  getSubscription = async () => {
    const { detailId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_BILLING_BY_ID.service,
      ApiRoutes.GET_BILLING_BY_ID.url.replace(":id", detailId),
      ApiRoutes.GET_BILLING_BY_ID.method,
      ApiRoutes.GET_BILLING_BY_ID.authenticate,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    console.log("response", response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      this.setState({
        paidBilling: data,
      });
    }
  };

  getInvoice = async (value) => {
    this.setState({
      isUpdating: value._id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_BILLING_INVOICE.service,
      ApiRoutes.GET_BILLING_INVOICE.url.replace(":id", value?.stripeInvoiceId),
      ApiRoutes.GET_BILLING_INVOICE.method,
      ApiRoutes.GET_BILLING_INVOICE.authenticate,
      undefined
    );
    this.setState({
      isUpdating: false,
    });
    console.log(response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      toast.success(response.messages[0]);
      window.location.href = data;
    }
  };
  customBreadCrumb = () => {
    const { isLoading } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      "View"
    );
  };

  render() {
    const { paidBilling, isLoading } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <>
        <div className="container-fluid fluid-pd-2 subscribedviewPage clientPage">
          <h1 className="h3-head-mob d-md-none">Your Subscriptions</h1>

          <Breadcrumbs
            pathname={pathname}
            customBreadCrumb={this.customBreadCrumb}
            // search={state ? state.search : ""}
          />

          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4 card-main flex-fill clientDetailsCard">
                {/* <div className="card-header  py-7550  align-items-center justify-content-between">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex align-items-center justify-content-between">
                        <h6 className="font-book ">Your Subscriptions</h6>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="card-body card-table">
                  {/* <p className="semiTitle">
                    All your paid PHL subscription are listed below
                  </p> */}

                  {isLoading ? (
                    <>
                      <BillingLoader />
                      <BillingLoader />
                    </>
                  ) : (
                    <>
                      {paidBilling && paidBilling.length ? (
                        paidBilling.map((info, index) => (
                          <div
                            key={index}
                            className={
                              index === 0
                                ? "innerCard "
                                : "innerCard grayScaleCard"
                            }
                          >
                            <div className="innerCard-header d-flex align-items-center justify-content-between">
                              <h3>
                                Renewal Date :
                                {moment(info.purchaseDate).format(
                                  "DD MMM YYYY"
                                )}
                              </h3>
                              <div className="d-flex align-items-center justify-content-between">
                                {/* <label className="d-flex align-items-center justify-content-between mr-3 mb-0 switchLabel">
                              <Switch
                                onChange={this.handleChange}
                                checked={this.state.checked}
                                onColor="#0F51A1"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                width={48}
                                height={28}
                              />
                              <span className="ml-2">Auto Renew</span>
                            </label> */}
                                <Button
                                  // to={AppRoutes.ADDPLAN}
                                  onClick={() => this.getInvoice(info)}
                                  className="btn btn-primary add-btn mr-0 mb-0"
                                  style={{ width: "106px" }}
                                >
                                  Invoice
                                </Button>
                              </div>
                            </div>
                            <div className="innerCard-body">
                              <div
                                className="row"
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="col-md-5 detailsCard">
                                  <div className="d-flex align-items-center">
                                    <img src={planName} />
                                    <div>
                                      <h4>Plan Name</h4>
                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{ width: "150px" }}
                                      >
                                        <h3>
                                          {info.subscription &&
                                            info.subscription.name}
                                        </h3>
                                        {/* {index === 0 && <Link>Change</Link>} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 detailsCard">
                                  <div className="d-flex align-items-center">
                                    <img src={userSubscribed} />
                                    <div>
                                      <h4>Users</h4>
                                      <h3>
                                        {info.subscription &&
                                          info.subscription.users}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ marginBottom: "30px" }}
                              >
                                <div className="col-md-5 detailsCard">
                                  <div className="d-flex align-items-center">
                                    <img src={billingCycle} />
                                    <div>
                                      <h4>Billing Cycle</h4>
                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{ width: "150px" }}
                                      >
                                        <h3>{info.billingCycle}</h3>
                                        {/* {index === 0 && <Link>Change</Link>} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 detailsCard">
                                  <div className="d-flex align-items-center">
                                    <img src={pricingIcon} />
                                    <div>
                                      <h4>Pricing</h4>
                                      <h3>${info.amount} USD</h3>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-5 detailsCard">
                                  <div className="d-flex align-items-center">
                                    <img src={statusIcon} />
                                    <div>
                                      <h4>Status</h4>
                                      <div
                                        className="d-flex align-items-center justify-content-between"
                                        style={{ width: "150px" }}
                                      >
                                        <h3>{info.status}</h3>
                                        {/* {index === 0 && <Link>Change</Link>} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4 detailsCard">
                                  <div className="d-flex align-items-center">
                                    <img src={renewDateIcon} />
                                    <div>
                                      <h4>Renewal Date</h4>
                                      <h3>
                                        {moment(info.expireDate).format(
                                          "DD MMMM YYYY"
                                        )}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-tr">
                          <div
                            colSpan={8}
                            className={"text-center image-no-data-sm pb-5 pt-3"}
                          >
                            <img src={noData} alt="" />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ViewClientSubscription;
