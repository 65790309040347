import React from "react";
import moment from "moment";
import { CounterfeitProduct } from "../loader/Resusable/CounterfeitProduct";

export const TransactionDetails = ({ transactionDetail, isLoading }) => {
  return (
    <>
      <div className="card card-main flex-fill clientDetailsCard">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="font-book ">Transaction Details</h6>
                </div>
              </div>
            </div>
            <div className="card-body card-table">
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Transaction ID</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {transactionDetail && transactionDetail.transactionId}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Payment Method</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0 text-capitalize">
                      {/* <img src={debitcard} />{" "} */}
                      {transactionDetail && transactionDetail.cardType}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Card Number</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      **** **** ****{" "}
                      {transactionDetail && transactionDetail.cardNo}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mtblf-5 mt-3">
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Cardholder’s Name</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {transactionDetail && transactionDetail.cardHolderName}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Amount </h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      ${transactionDetail && transactionDetail.amount}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Paid On</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {moment
                        .utc(
                          transactionDetail && transactionDetail.transactionDate
                        )
                        .format("DD/MM/YYYY | HH:mm")}
                      Hrs
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mtblf-5 mt-3">
                <div className="col-md-3">
                  <div className="heading-col">
                    <h5 className="ml-0">Payment Status</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="ml-0">
                      {transactionDetail && transactionDetail.status}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
