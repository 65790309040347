import React from "react";
import { CounterfeitRow } from "./CounterfeitRow";
import { TableLoader } from "../../components/loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";

export const ListComponent = ({
  counterfeits,
  isFilterApplied,
  isLoading,
  viewHandler,
  setFilter,
  sortBy,
  order,
  changeRequestRequest,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-sm">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0">
            <thead className="pad-top">
              <tr>
                {[
                  { label: "Scan/Report ID", value: "scanId" },
                  { label: "Batch Number", value: "batchNumber" },
                  { label: "Product Name", value: "productName" },
                  { label: "Assignee", value: "assigneeName" },
                ].map(({ label, value }) => (
                  <th key={value}>
                    {label}{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === value ? "active-ac" : ""
                        }`}
                        onClick={() => setFilter(value, "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === value
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter(value, "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>
                ))}
                {/* <th>Scan ID</th>
                <th>Batch Number</th>
                <th>Product Name</th>
                <th>Assigned To</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {counterfeits && counterfeits.length ? (
                counterfeits.map((counterfeit, index) => (
                  <CounterfeitRow
                    key={index}
                    changeRequestRequest={changeRequestRequest}
                    counterfeitData={counterfeit}
                    viewHandler={() => viewHandler(counterfeit._id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={8} className={"text-center image-no-data-sm"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {/*{!isLoading && totalRecords > limit ? (
          <div className='float-right mt-2'>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
