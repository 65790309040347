import Validator from "js-object-validation";
import { message } from "../common";

export const AddTitleValidator = (data) => {
  const validations = {
    jobId: {
      required: true,
    },
    jobTitle: {
      required: true,
    },
    jobType: {
      required: true,
    },
    jobCategory: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    jobId: {
      required: message.Required.replace(":item", "Job Id"),
    },
    jobTitle: {
      required: message.Required.replace(":item", "Job Title"),
    },
    jobType: {
      required: message.Required.replace(":item", "Job Type"),
    },
    jobCategory: {
      required: message.Required.replace(":item", "Job Category"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
