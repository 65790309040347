import React from 'react';
import Skeleton from 'react-skeleton-loader';
export const AddUserLoader = () => (
  <>
    <div className='container-fluid fluid-pd-2'>
      <h1 className='h3-head-mob d-md-none'>Add User</h1>
      <p className='breadcrums-top'>
        <Skeleton color='#fff' />
      </p>
      <div className='card mb-4 card-main'>
        <div className='card-header py-75  align-items-center justify-content-between'>
          <div className='row'>
            <div className='col-md-12'>
              <h6 className='font-book '>
                <Skeleton color='#e5e9ef' height={'25px'} />
              </h6>
            </div>
          </div>
        </div>
        <div className='card-body card-table'>
          <form className='user_add'>
            <div className='row'>
              <div className='col-md-3 mob-show'>
                <div className='img-col-user'>
                  <Skeleton color='#e5e9ef' height={'150px'} width={'100px'} />
                </div>

                <div className='file-upload-btn'>
                  <Skeleton color='#e5e9ef' height={'25px'} />
                </div>
              </div>
              <div className='col-md-9'>
                <div className='row'>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'25px'} width={'100%'} />
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
                  </div>
                  <div className='col-md-4 skeleton-bottom '>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
                  </div>
                  <div className='col-md-4 skeleton-bottom'>
                    <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
                    <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
                  </div>
                </div>
              </div>
              <div className='col-md-3 mob-hide'>
                <div className='img-col-user'>
                  <Skeleton color='#e5e9ef' height={'150px'} width={'100%'} />
                </div>

                <div className='file-upload-btn '>
                  <Skeleton color='#e5e9ef' height={'25px'} width={'100%'} />
                </div>
              </div>

              <div className='col-md-12 footer-form'>
                <Skeleton color='#e5e9ef' height={'40px'} />

                <Skeleton color='#e5e9ef' height={'40px'} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
);
