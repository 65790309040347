import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import QRCode from 'qrcode.react';
import * as qs from 'query-string';
import { ApiHelper } from '../../../helpers';
import { ApiRoutes } from '../../../config';
import Loader from '../../components/loader/Loader';

export const QRCodeComponent = () => {
  const { search } = useLocation();
  const { id, unit } = useParams();
  const [batchUnits, setBatchUnits] = useState([]);
  const [loading, setLoading] = useState([]);
  useEffect(() => {
    document.body.classList.remove('bg-gradient-primary');
  }, []);
  useEffect(() => {
    async function fetchData() {
      const query = qs.parse(search);
      let data = {
        batchGroup: query ? query.group : undefined,
        batchId: id,
        unit,
        orderDate: query ? query.orderDate : undefined,
        batchIds: query.batchIds ? (query.batchIds):''
      };
      if (query && query.assignee) {
        data = {
          ...data,
          clientId: query.assignee,
        };
      }
      setLoading(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.FETCH_ASSIGNED_BATCH.service,
        ApiRoutes.FETCH_ASSIGNED_BATCH.url,
        ApiRoutes.FETCH_ASSIGNED_BATCH.method,
        ApiRoutes.FETCH_ASSIGNED_BATCH.authenticate,
        data,
        undefined,
      );
      setLoading(false);
      if (response && response.isError) {
      } else {
        const { data } = response.data;
        setBatchUnits(data);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [id, unit]);
  return (
    <div className='container'>
      {/* <div style={{ marginLeft: '1300px', overflowX: 'hidden' }}> */}
      <div className='row' id='#pdf'>
        {loading ? (
          <Loader />
        ) : batchUnits && batchUnits.length ? (
          batchUnits.map((unit, index) => (
            <div className={'col-md-2'} style={{ marginTop: 15 }} key={index}>
              <QRCode value={unit.id} size={100} />
            </div>
          ))
        ) : null}
      </div>
      {/* </div> */}
      {/* <button onClick={printPDF}>Print</button> */}
    </div>
  );
};
