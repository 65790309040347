/** @format */

import React from "react";
import listIcon from "../../../assets/img/list.svg";
import listIconWhite from "../../../assets/img/list-white.svg";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";

const RequestRow = ({ requestData, viewUser, onDelete, editUser }) => {
  const {
    first_name = "",
    last_name = "",
    email = "",
    store = {},
    status = "",
    applicationNumber = "",
  } = requestData;

  const renderStatus = () => {
    switch (status) {
      case "Accepted":
        return <span className="success-span">Accepted</span>;
      case "Rejected":
        return <span className="secondary-span">Rejected</span>;
      case "Cancelled":
        return <span className="danger-span">Cancelled</span>;
      case "Invited":
        return <span className="primary-span">Invited</span>;
      case "Submitted":
        return <span className="warning-span">Submitted</span>;
      default:
        return <span className="primary-span">Invited</span>;
    }
  };
  const { storeName = "", brandName = "" } = store || {};

  return (
    <tr>
      <td>{applicationNumber}</td>
      <td> {[first_name, last_name].filter(Boolean).join(" ") || "-"}</td>
      <td> {email || "-"} </td>
      <td> {store.companyName} </td>
      <td> {renderStatus()} </td>

      <td>
        <button
          className="btn btn-secondary th-btn edit-tb action-block-btn btn btn-primary"
          onClick={viewUser}
        >
          <span>
            <img src={listIcon} alt="" className="img-main" />
            <img src={listIconWhite} alt="" className="img-hover" />
          </span>
          View
        </button>

        <button
          className="btn btn-secondary th-btn edit-tb action-block-btn btn btn-primary"
          onClick={editUser}
        >
          <span>
            <img src={editIcon} alt="" className="img-main" />
            <img src={editIconWhite} alt="" className="img-hover" />
          </span>
          Edit
        </button>

        <button
          className="btn th-btn delete-tb action-block-btn btn btn-danger"
          onClick={onDelete}
        >
          <span>
            <img src={trashIcon} alt="" className="img-main" />
            <img src={trashIconWhite} alt="" className="img-hover" />
          </span>
          Delete
        </button>
        {/* button
          className='btn btn-secondary th-btn'
          disabled={
            status === "Invited" ||
            status === "Cancelled" ||
            status === "Rejected" ||
            (isUpdating && updatingStatus === "Approved")
          }
          onClick={() =>
            updateStatus("Approved", "Do you want to approve this request")
          }
        >
          {isUpdating && updatingStatus === "Approved" ? (
            <>
              <i className="fa fa-spinner fa-spin"></i> Approve
            </>
          ) : (
            <>
              {" "}
              <img src={phoneIcon} alt="" /> Approve{" "}
            </>
          )}
        </button>
        {buttonLabel()} */}
      </td>
    </tr>
  );
};

export default RequestRow;
