import * as qs from "query-string";
import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { DeleteErrorModal } from "../../components/sweetAlertComponent/DeleteError";
import { ProductListComponent } from "../../components/products";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import "./manage-product.css";
import pageDropdownIcon from "../../../assets/img/tag-bd.svg";
import { FilterComponent } from "../../components/filters";
import { SearchFilter } from "../../components/filters/searchFilter";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

class ManageProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isLoading: false,
      clientList: [],
      clientName: { label: "PHL", value: "PHL" },
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      deleteError: false,
      sortBy: "createdAt",
      order: "desc",
      search: "",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount() {
    this.handleQueryParams();
  }
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "createdAt",
        order: query.order
          ? query.order
          : query.sort === "scan_id" || query.sort === "createdAt"
          ? "desc"
          : "asc",
        clientName: query.clientName || this.state.clientName,
        search: query.search || "",
      },
      () => this.fetchProducts()
    );
    this.fetchClientList();
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchProducts = async () => {
    const { currentPage, limit, order, sortBy, search } = this.state;
    let clientName = this.state.clientName.value;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      order,
      sortBy,
      search,
      clientName,
    };
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PRODUCTS_LIST.service,
      ApiRoutes.PRODUCTS_LIST.url,
      ApiRoutes.PRODUCTS_LIST.method,
      ApiRoutes.PRODUCTS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { products, totalRecords },
      } = response.data;
      this.setState({
        products,
        totalRecords,
      });
    }
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      let { data } = response.data;
      data = response.data.data.filter((item) => {
        return item.label.trim() !== "";
      });
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "PHL",
            value: "PHL",
          },
          ...data,
        ],
      });
    }
  };

  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // Function to manage read more, read less
  onExpand = (index) => {
    const { products } = this.state;
    const temp = [...products];
    temp[index] = {
      ...temp[index],
      isExpand: !temp[index].isExpand,
    };

    this.setState({
      products: temp,
    });
  };
  onEdit = (id) => {
    const {
      props: {
        location: { search },
      },
    } = this;
    this.props.history.push({
      pathname: AppRoutes.EDIT_PRODUCT.replace(":id", id),
      state: {
        search,
      },
    });
  };
  onDelete = async (id, name, disabled = false) => {
    if (disabled) {
      this.setState({
        deleteError: true,
      });
    } else {
      const { value } = await SweetAlertComponent({
        text: `Are you sure, you want to delete the ${name} product ?`,
      });
      if (!value) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_PRODUCT.service,
        ApiRoutes.DELETE_PRODUCT.url.replace(":id", id),
        ApiRoutes.DELETE_PRODUCT.method,
        ApiRoutes.DELETE_PRODUCT.authenticate,
        undefined,
        undefined
      );
      this.setState({
        id: "",
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        await this.fetchProducts();
        toast.success(response.messages[0]);
      }
    }
  };
  // To close delete error modal
  handleClose = () => {
    this.setState({
      deleteError: false,
    });
  };
  setFilter = (sortBy, order, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  onSelect = (options, name) => {
    this.setState({
      [name]: options,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (options) {
      params["clientId"] = options.value;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  loadClientOptions = async (inputValue, callback) => {
    //new ApiHelper().cancelRequest("cancel");
    //const result = await this.fetchProductsOption(inputValue);
    const result = this.state.clientList.filter((p) =>
      p.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(result);
  };

  onSearch = () => {
    const { search } = this.state;
    console.log("onSearch", this.state.search);
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };
  render() {
    const {
      isLoading,
      products,
      totalRecords,
      currentPage,
      limit,
      deleteError,
      order,
      sortBy,
      search,
      clientName,
      clientList,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    const filtersOption = [
      {
        label: "Product Number",
        value: "productNumber",
      },
      {
        label: "Product Name",
        value: "productName",
      },
      {
        label: "Created Date",
        value: "createdAt",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Manage Product</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card card-main card-pagination">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-cat-col-lg">
                <h6 className="font-book">Product Listing Details</h6>
              </div>
              <div className="col-md-9 action-col-lg ">
                <div className="custom_header_filters">
                  <SearchFilter
                    placeholder={"Search by Product No. and Name…"}
                    search={search}
                    onChange={this.handleSearch}
                  />
                  <div
                    className=""
                    style={{
                      zIndex: "111111",
                      maxWidth: "170px",
                      minWidth: "170px",
                      marginRight: "8px",
                    }}
                  >
                    <AsyncSelect
                      loadOptions={this.loadClientOptions}
                      placeholder={"Select Client"}
                      maxMenuHeight={200}
                      styles={customStyles}
                      defaultOptions={clientList}
                      value={clientName}
                      onChange={(selectedOption) =>
                        this.onSelect(selectedOption, "clientName")
                      }
                      noOptionsMessage={() => "No Option"}
                    />
                  </div>

                  <FilterComponent
                    label={"Sort By"}
                    options={filtersOption}
                    selectedFilter={sortBy}
                    onFilterChange={(selectedValue) =>
                      this.setFilter(selectedValue, "", "sort")
                    }
                  />
                  {/*<FilterComponent
                    label={"Client"}
                    options={clientList}
                    selectedFilter={clientName}
                    onFilterChange={(selectedValue) =>
                      this.setFilter(selectedValue, "", "clientName")
                    }
                  />*/}

                  <Link
                    to={AppRoutes.ADD_PRODUCT}
                    className="btn btn-primary add-btn text-nowrap"
                  >
                    Add Product
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ProductListComponent
            products={products}
            isLoading={isLoading}
            onExpand={this.onExpand}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <>
              <div className="float-left mt-2">
                <p className="page-desc-left">
                  Total: <span className="bold-black">{totalRecords}</span>
                </p>
              </div>
              <div className="float-right mt-2">
                <p className="page-desc ml-2 float-right">Table View</p>

                <Dropdown className="no-arrow dp-right ml-2">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn"}
                  >
                    {" "}
                    <span className="staff-value">
                      {
                        (
                          pagesOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{" "}
                    <img src={filterarrowIcon} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                    {pagesOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.onLimitChange(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                {totalRecords > limit ? (
                  <div className="float-right ">
                    <Pagination
                      totalRecords={totalRecords}
                      currentPage={currentPage}
                      pageLimit={limit}
                      onPageChanged={this.onPageChanged}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        <DeleteErrorModal
          open={deleteError}
          handleClose={this.handleClose}
          message={"This product has current active batches."}
          moduleName={"Product"}
        />
      </div>
    );
  }
}

export default ManageProduct;
