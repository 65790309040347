import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import {
  BatchDetailComponent,
  EditBatchError,
  BatchHistoryComponent,
} from "../../components/batch";
import PrintBatchModal from "./PrintBatch";
import "./batches.css";
import SweetAlertComponent from "../../components/sweetAlertComponent";

class ViewBatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batchId: "",
      batchData: {},
      batchHistory: [],
      isLoading: false,
      isBatchExporting: false,
      activeBatchGroup: "",
      isHistoryLoading: false,
      editBatchError: false,
      printModal: false,
      isBatchInactive: false,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ batchId: id }, () => {
        this.viewBatch();
        this.viewBatchHistory();
      });
    }
  };

  viewBatch = async () => {
    const { batchId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_BATCH.service,
      ApiRoutes.VIEW_BATCH.url.replace(":id", batchId),
      ApiRoutes.VIEW_BATCH.method,
      ApiRoutes.VIEW_BATCH.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { batch },
      } = response.data;
      this.setState({
        batchData: batch,
      });
    }
  };
  viewBatchHistory = async () => {
    const { batchId } = this.state;
    this.setState({
      isHistoryLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BATCH_history.service,
      ApiRoutes.BATCH_history.url.replace(":id", batchId),
      ApiRoutes.BATCH_history.method,
      ApiRoutes.BATCH_history.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isHistoryLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      this.setState({
        batchHistory: data,
      });
    }
  };
  onEdit = () => {
    const { batchData } = this.state;
    if (batchData.itemCount !== batchData.availableUnits) {
      this.setState({
        editBatchError: true,
      });
    } else {
      this.props.history.push(
        AppRoutes.EDIT_BATCH.replace(":id", batchData.id)
      );
    }
  };
  onEditErrorClose = () => {
    this.setState({
      editBatchError: false,
    });
  };
  onExport = async (type, clientId, orderDate, activeBatchGroup) => {
    const { batchId } = this.state;
    let blobType, extension;
    let data = { type, orderDate, batchGroup: activeBatchGroup };
    if (clientId) {
      this.setState({
        activeBatchGroup,
      });
      data = {
        ...data,
        clientId,
      };
    } else {
      this.setState({
        isBatchExporting: true,
      });
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_BATCH.service,
      ApiRoutes.EXPORT_BATCH.url.replace(":id", batchId),
      ApiRoutes.EXPORT_BATCH.method,
      ApiRoutes.EXPORT_BATCH.authenticate,
      data,
      undefined,
      "blob"
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: "",
    });
    if (response && response.isError) {
    } else {
      switch (type) {
        case "pdf":
          blobType = "application/pdf";
          extension = "pdf";
          break;
        case "excel":
          blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          extension = "xlsx";
          break;
        case "csv":
          blobType = "application/csv";
          extension = "csv";
          break;
        default:
          break;
      }
      let blob = new Blob([response.data], {
        type: blobType,
      });
      FileSaver.saveAs(
        blob,
        `Batch.${extension}`
        // `${moment().format('YYYY_MM_DD')}_${batchId}.${extension}`,
      );
    }
  };
  onPrint = () => {
    this.setState({
      printModal: true,
    });
  };
  // To close print batch modal
  handleClose = () => {
    this.setState({
      printModal: false,
    });
  };
  openPrintModal = () => {
    const { printModal, batchData } = this.state;
    return (
      <PrintBatchModal
        open={printModal}
        batchData={{
          ...batchData,
          _id: batchData.id,
          productName:
            batchData && batchData.productId
              ? batchData.productId.productName
              : "",
        }}
        handleClose={this.handleClose}
        fetchData={this.viewBatch}
        fetchHistory={this.viewBatchHistory}
      />
    );
  };
  customBreadCrumb = () => {
    const { isLoading, batchData } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      batchData.batchNumber
    );
  };

  onBatchHistoryDelete = async (batchId, batchGroup) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to delete this Batch history?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_BATCH_HISTORY.service,
      ApiRoutes.DELETE_BATCH_HISTORY.url,
      ApiRoutes.DELETE_BATCH_HISTORY.method,
      ApiRoutes.DELETE_BATCH_HISTORY.authenticate,
      undefined,
      { batchId, batchGroup }
    );

    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      await this.viewBatchHistory();
    }
    // }
  };
  inactiveBatch = async (batchId, batchGroup) => {
    let data = {
      batchId,
      batchGroup,
    };
    this.setState({
      isBatchInactive: batchGroup,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.INACTIVE_BATCH.service,
      ApiRoutes.INACTIVE_BATCH.url,
      ApiRoutes.INACTIVE_BATCH.method,
      ApiRoutes.INACTIVE_BATCH.authenticate,
      undefined,
      data
    );
    this.setState({
      isBatchInactive: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      await this.viewBatchHistory();
    }
  };
  render() {
    const {
      isLoading,
      isHistoryLoading,
      activeBatchGroup,
      isBatchExporting,
      batchData,
      batchHistory,
      editBatchError,
      isBatchInactive,
    } = this.state;
    const {
      props: {
        location: { pathname, state },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
          search={state ? state.search : ""}
        />
        <div className="row">
          <div className="col-md-12 d-flex">
            <BatchDetailComponent
              batchData={batchData}
              isLoading={isLoading}
              isExporting={isBatchExporting}
              onEdit={this.onEdit}
              onPrint={this.onPrint}
              onExport={this.onExport}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <BatchHistoryComponent
              batchHistory={batchHistory}
              isLoading={isHistoryLoading}
              onExport={this.onExport}
              onBatchHistoryDelete={this.onBatchHistoryDelete}
              activeBatchGroup={activeBatchGroup}
              isBatchInactive={isBatchInactive}
              inactiveBatch={this.inactiveBatch}
            />
          </div>
        </div>
        {this.openPrintModal()}
        <EditBatchError
          handleClose={this.onEditErrorClose}
          open={editBatchError}
        />
      </div>
    );
  }
}

export default ViewBatch;
