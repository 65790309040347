import React from 'react';
import editIcon from '../../../assets/img/edit-3.svg';
import editIconWhite from '../../../assets/img/edit-3-white.svg';
import trashIcon from '../../../assets/img/trash-2.svg';
import trashIconWhite from '../../../assets/img/trash-2-white.svg';
import { Button } from 'react-bootstrap';

function importAll(r) {
  return r.keys().map(item => ({ name: item.split('/')[1], path: r(item) }));
}

const flags = importAll(
  require.context('../../../assets/country-flags', false, /\.(png|jpe?g|svg)$/),
);

const DEFAULT_CDN_URL =
  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/';
const DEFAULT_CDN_SUFFIX = 'svg';

export const OriginRow = ({
  originData,
  editOrigin,
  deleteOrigin,
  selectedCountryData,
  isViewUploading
}) => {
  const { originName = '-', states = [] } = originData;
  // To filter selected country flag
  let selectedFlag = selectedCountryData
    ? flags.filter(flag => flag.name === `${selectedCountryData.code2}.svg`)[0]
    : '';
  const flagUrl = `${DEFAULT_CDN_URL}${selectedCountryData.code2.toLowerCase()}.${DEFAULT_CDN_SUFFIX}`;
  return (
    <tr>
      <td>
        {selectedFlag || true ? (
          <img
            className='country-logo'
            src={
              flagUrl
              // selectedFlag.path
            }
            alt=''
          />
        ) : null}
        {originName}
      </td>
      <td className='origin-desc'>
        <ul className="states-list">
          {states && states.length
            ? states.map((state, index) => (
              <li
                className={`primary-span-td ${
                  states.length === 1 ? 'primary-span-td-single' : ''
                  }`}
                key={index}
              >
                {state}
              </li>
            ))
            : <li className='secondary-span-td-single'>NA </li>}
        </ul>
        {/* {states && states.length > 2 ? (
          <span className='bold-desc cursor-pointer' onClick={onExpand}>
            {!isExpand ? '...Read More' : '...Read less'}
          </span>
        ) : null} */}
      </td>
      <td>
        <Button className='btn btn-secondary th-btn edit-tb action-block-btn' onClick={editOrigin}>
          {isViewUploading ? <>
            <span>
              <i className='fa fa-spinner fa-spin'></i>
            </span>  Edit</> : <>
              <span>
                <img src={editIcon} alt='' className={"img-main"} />
                <img src={editIconWhite} alt='' className={"img-hover"} />
              </span>
           Edit</>}
        </Button>
        <Button variant="danger" className='btn th-btn delete-tb action-block-btn' onClick={deleteOrigin}>
          <span>
            <img src={trashIcon} alt='' className={"img-main"} />
            <img src={trashIconWhite} alt='' className={"img-hover"} />
          </span>
           Delete
        </Button>
      </td>
    </tr>
  );
};

export default OriginRow;
