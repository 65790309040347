import React, { useState, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { AppRoutes, ApiRoutes } from '../config';
import { isLogin, ApiHelper, Logout } from '../helpers';
import Loader from '../view/components/loader/Loader';
import Header from '../view/components/partial/Header';
import Nav from '../view/components/partial/Nav';
import { routesArray } from './routes';
import PHLProvider from '../PHLProvider';
const $ = window.$;

const PrivateRoute = props => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isLoggedInUser = isLogin();
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // useEffect(() => {
  //   socket = socketIOClient(AppConfig.SOCKET_ENDPOINT, {
  //     query: {
  //       token: getToken(),
  //     },
  //     secure: true,
  //   });
  // }, []);
  useEffect(() => {
    async function fetchData() {
      if (!isLoggedInUser) {
        return history.push(AppRoutes.LOGIN);
      }
      setLoading(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_PROFILE.service,
        ApiRoutes.GET_PROFILE.url,
        ApiRoutes.GET_PROFILE.method,
        ApiRoutes.GET_PROFILE.authenticate,
        undefined,
        undefined,
      );
      setLoading(false);
      if (response && response.isError) {
        Logout();
      }
    }
    fetchData();
  }, [pathname]);

  return isLoggedInUser ? (
    <>
      <Nav {...props} sidebarOpen={sidebarOpen} />
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          <PHLProvider>
            <Header
              {...props}
              toggleSidebar={() => {
                $('body').toggleClass('sidebar-toggled');
                $('.sidebar').toggleClass('toggled');
                if ($('.sidebar').hasClass('toggled')) {
                  $('.sidebar .collapse').collapse('hide');
                }
              }}
            />
          </PHLProvider>
          <Switch>
            {routesArray
              .filter(route => route.isAuthenticatedRoute)
              .map((route, index) => {
                const { path, loader: ComponentLoader = Loader } = route;
                return (
                  <Route
                    exact
                    path={path}
                    key={index}
                    render={props => (
                      <>
                        {/* <React.Suspense fallback={<ComponentLoader />}> */}
                        {false ? (
                          <ComponentLoader />
                        ) : (
                            <PHLProvider>
                              {/* <SocketContext.Provider value={'Ad'}> */}
                              <route.component {...props} />
                              {/* </SocketContext.Provider> */}
                            </PHLProvider>
                          )}
                        {/* </React.Suspense> */}
                      </>
                    )}
                  />
                );
              })}
            <Redirect from={AppRoutes.MAIN} to={AppRoutes.DASHBOARD} />
          </Switch>
        </div>
        {/* <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; Your Website 2020</span>
          </div>
        </div>
      </footer> */}
      </div>
    </>
  ) : (
      <Redirect
        to={{
          pathname: AppRoutes.LOGIN,
          state: { refer: props.location },
        }}
      />
    );
};

export default PrivateRoute;
