import React, { Component } from "react";
import * as qs from "query-string";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, pagesOption } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import Pagination from "../../../components/pagination";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import { FilterComponent } from "../../../components/filters";
import { SearchFilter } from "../../../components/filters/searchFilter";
import StaffListRequestComponent from "../../../components/staffs/Request";
import pageDropdownIcon from "../../../../assets/img/tag-bd.svg";
import filterarrowIcon from "../../../../assets/img/filter-arrow.svg";
import addIcon from "../../../../assets/img/addIcon.svg";
import "../manage-staff.css";
import AddStaff from "./AddStaff";

class ManageStaffRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      errors: null,
      isLoading: false,
      isUpdating: false,
      activeId: "",
      totalRecords: 0,
      currentPage: 1,
      limit: 11,
      sortBy: "createdAt",
      sortByStatus: "all",
      search: "",
      isFormOpen: false,
      order: "desc",
      modalShow: false,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sort || "createdAt",
        sortByStatus: query.status || "all",
        order: query.order ? query.order : "desc",
      },
      () => {
        this.fetchStaffReq();
        this.fetchRoleData();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  fetchStaffReq = async (loading = true) => {
    const { currentPage, limit, sortBy, sortByStatus, search, order } =
      this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      sortByStatus,
      // order: sortBy === "createdAt" ? "desc" : "asc",
      order: order,
    };
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF_REQUEST.service,
      ApiRoutes.GET_STAFF_REQUEST.url,
      ApiRoutes.GET_STAFF_REQUEST.method,
      ApiRoutes.GET_STAFF_REQUEST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { staff, totalRecords },
      } = response.data;
      this.setState({
        requests: staff,
        totalRecords,
      });
    }
  };
  updateStaffStatus = async (id, status, message) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: message,
    });
    if (!value) {
      return;
    }
    this.setState({
      isUpdating: true,
      activeId: id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_STATUS.service,
      ApiRoutes.UPDATE_STAFF_STATUS.url.replace(":id", id),
      ApiRoutes.UPDATE_STAFF_STATUS.method,
      ApiRoutes.UPDATE_STAFF_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchStaffReq(false);
      toast.success(response.messages[0]);
    }
  };
  resendInvitation = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: "Are you sure, you want to resend invitation?",
    });
    if (!value) {
      return;
    }
    this.setState({
      isUpdating: true,
      activeId: id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESEND_INVITATION_TO_STAFF.service,
      ApiRoutes.RESEND_INVITATION_TO_STAFF.url.replace(":id", id),
      ApiRoutes.RESEND_INVITATION_TO_STAFF.method,
      ApiRoutes.RESEND_INVITATION_TO_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchStaffReq(false);
      toast.success(response.messages[0]);
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  setFilter = (sortBy, name) => {
    const {
      props: {
        location: { pathname },
      },
    } = this;
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push([pathname, qs.stringify(params)].join("?"));
  };
  setSorting = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    // params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
  };
  onAddBtnClick = () => {
    this.setState((prevState) => ({
      isFormOpen: !prevState.isFormOpen,
    }));
  };
  onCancel = async (newAdded) => {
    // To fetch request after adding new staff
    if (newAdded) {
      await this.fetchStaffReq(false);
    }
    this.setState({
      isFormOpen: false,
    });
  };

  viewHandler = (id) => {
    this.props.history.push(AppRoutes.VIEW_STAFF.replace(":id", id));
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  fetchRoleData = async () => {
    let data = {};
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ROLE.service,
      ApiRoutes.GET_ROLE.url,
      ApiRoutes.GET_ROLE.method,
      ApiRoutes.GET_ROLE.authenticate,
      data,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { role },
      } = response.data;
      this.setState({
        role,
      });
    }
  };

  reloadAPI = () => {
    this.onCloseModal();
    this.fetchStaffReq();
  };

  onStaffModal = () => {
    this.setState({ modalShow: true });
  };

  onCloseModal = () => {
    this.setState({ modalShow: false });
  };
  render() {
    const {
      requests,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      search,
      sortByStatus,
      isFormOpen,
      isUpdating,
      activeId,
      sortBy,
      order,
      role,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const filtersStatus = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Pending",
        value: "Pending",
      },
      {
        label: "Accepted",
        value: "Accepted",
      },
      {
        label: "Invited",
        value: "Invited",
      },
      {
        label: "Cancelled",
        value: "Cancelled",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Staff Onboarding</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card card-pagination card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 head-col-lg">
                <h6 className="font-book ">Staff Details</h6>
              </div>

              <div className="col-md-10  mob-filter-data">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn-mob"}
                  >
                    {" "}
                    <span className="staff-value">
                      {
                        (
                          pagesOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{" "}
                    <img src={pageDropdownIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                    {filtersStatus.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.setFilter(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dropdown no-arrow dp-right">
                  <button
                    type="button"
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="img/tag-search-bd.svg" alt="" />
                  </button>
                </div>
              </div>

              <div className="col-md-9 filter-col-lg">
                <div className="dp-right">
                  <button
                    className="btn btn-primary add-btn add-wd-1024"
                    onClick={this.onStaffModal}
                  >
                    <img src={addIcon} alt="" srcset="" /> Add Staff
                  </button>
                </div>
                <FilterComponent
                  options={filtersStatus}
                  label={"Status"}
                  selectedFilter={sortByStatus}
                  onFilterChange={(selectedValue) =>
                    this.setFilter(selectedValue, "status")
                  }
                />
                <SearchFilter
                  id={"sm-search-filter"}
                  placeholder={"Search by Name and Employee ID"}
                  search={search}
                  onChange={this.handleSearch}
                />
              </div>
            </div>
          </div>
          <StaffListRequestComponent
            requests={requests}
            isLoading={isLoading}
            isUpdating={isUpdating}
            activeId={activeId}
            isFormOpen={isFormOpen}
            onCancel={this.onCancel}
            viewHandler={this.viewHandler}
            updateStatus={this.updateStaffStatus}
            resendInvitation={this.resendInvitation}
            setSorting={this.setSorting}
            sortBy={sortBy}
            order={order}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <div className="float-left mt-2">
              <p className="page-desc-left">
                Total: <span className="bold-black">{totalRecords}</span>
              </p>
            </div>
          ) : null}
          <div className="float-right mt-2">
            <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {!isLoading && totalRecords > limit ? (
              <div className="float-right">
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={currentPage}
                  pageLimit={limit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            ) : null}
          </div>
        </div>
        <AddStaff
          modalShow={this.state.modalShow}
          modalClose={this.onCloseModal}
          roleOptions={role}
          reloadAPI={this.reloadAPI}
          // loadRoleOptions={this.loadRoleOptions}
          onSelect={this.onSelect}
        />
      </div>
    );
  }
}

export default ManageStaffRequests;
