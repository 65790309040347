import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import {
  ManagePermissionComponent,
  AssignedPermissionComponent,
} from "../../../components/clients";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import { permissionValidator } from "../../../../validators/permission";
import "../manage-clients.css";

class ManagePermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "",
      // Permission module related fields
      permission: [],
      selectedModule: null,
      subModules: [],
      accessType: null,
      permissionErrors: null,
      deletingModule: "", // to apply remove loader on particular index
      isUpdating: false,
      alreadyAdded: false,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        clientId: id,
      },
      () => this.fetchClientPermission()
    );
  };
  fetchClientPermission = async (loading = true) => {
    this.setState({
      isLoading: loading,
    });
    const { clientId } = this.state;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_PERMISSION.service,
      ApiRoutes.GET_CLIENT_PERMISSION.url.replace(":id", clientId),
      ApiRoutes.GET_CLIENT_PERMISSION.method,
      ApiRoutes.GET_CLIENT_PERMISSION.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      const { first_name = "", last_name = "", permission = [] } = data || {};
      this.setState({
        name: [first_name, last_name].filter(Boolean).join(" "),
        permission,
      });
    }
  };
  updatePermission = async (payload, loading = true) => {
    const { clientId } = this.state;
    this.setState({
      isUpdating: loading,
      updatingSection: "permission",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_PERMISSION.service,
      ApiRoutes.UPDATE_CLIENT_PERMISSION.url.replace(":id", clientId),
      ApiRoutes.UPDATE_CLIENT_PERMISSION.method,
      ApiRoutes.UPDATE_CLIENT_PERMISSION.authenticate,
      undefined,
      payload
    );
    if (response && response.isError) {
      this.setState({
        isUpdating: false,
        updatingSection: "",
      });
      toast.error(response.messages[0]);
      return false;
    } else {
      await this.fetchClientPermission(false);
      this.setState({
        isUpdating: false,
        updatingSection: "",
        selectedModule: null,
        subModules: [],
        accessType: null,
        alreadyAdded: false,
      });
      toast.success(response.messages[0]);
      return true;
    }
  };
  // Handle change on add permission
  handlePermissionSelection = (selectedOption, name) => {
    const { permission } = this.state;

    if (name === "selectedModule" && selectedOption) {
      let Added = permission.filter(
        (m) => m.module === selectedOption.value
      )[0];
      if (Added) {
        this.setState({
          alreadyAdded: Added,
          accessType: { label: Added.accessType, value: Added.accessType },
          subModules: Added.subModules.map((item) => ({
            label: item,
            value: item,
          })),
        });
      } else {
        this.setState({
          alreadyAdded: false,
          subModules: [],
          accessType: "",
        });
      }
    }
    this.setState({
      [name]: selectedOption,
      permissionErrors: {
        ...this.state.permissionErrors,
        [name]: "",
      },
    });
  };
  // To add permission for the client
  addPermission = async (event) => {
    event.preventDefault();
    this.setState({
      isUpdating: true,
    });
    const { selectedModule, subModules, accessType } = this.state;
    let data = {
      module:
        selectedModule && selectedModule.value ? selectedModule.value : "",
      subModules: subModules.map((item) => item.value),
      accessType: accessType && accessType.value ? accessType.value : "",
    };
    // To validates field
    const { isValid, errors } = permissionValidator({
      selectedModule: data.module,
      subModules: data.subModules.length,
      accessType: data.accessType,
    });

    if (isValid) {
      this.setState({
        isUpdating: false,
      });
      await this.updatePermission({
        newModule: data,
      });
    } else {
      this.setState({
        isUpdating: false,
        permissionErrors: errors,
      });
      return;
    }
  };
  // To remove access of particular module
  removePermission = async (moduleName) => {
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to remove ${moduleName.toLowerCase()} access?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      deletingModule: moduleName,
    });
    await this.updatePermission(
      {
        removedModule: moduleName,
      },
      false
    );
    this.setState({
      deletingModule: false,
    });
  };

  customBreadCrumb = () => {
    const { isLoading, name } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      name
    );
  };

  render() {
    const {
      isLoading,
      selectedModule,
      subModules,
      permissionErrors,
      deletingModule,
      permission,
      isUpdating,
      updatingSection,
      clientId,
      accessType,
      alreadyAdded,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">View Client</h1>
        <Breadcrumbs
          pathname={pathname}
          customBreadCrumb={this.customBreadCrumb}
        />

        {/* <div className="row">
          <div className="col-md-12"> */}
        <ManagePermissionComponent
          isLoading={isLoading}
          selectedModule={selectedModule}
          subModules={subModules}
          errors={permissionErrors}
          isAdding={isUpdating}
          handlePermissionSelection={this.handlePermissionSelection}
          addPermission={this.addPermission}
          clientId={clientId}
          accessType={accessType}
          alreadyAdded={alreadyAdded}
        />
        {/* </div>
        </div> */}
        <div className="row">
          <div className="col-md-12 d-flex">
            <AssignedPermissionComponent
              isLoading={isLoading}
              permission={permission}
              removePermission={this.removePermission}
              deletingModule={deletingModule}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ManagePermission;
